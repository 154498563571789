import { connect } from 'react-redux'
import { path } from 'ramda'
import ManageVehicles from './ManageVehicles'
import {
  loadVehicles,
  loadVehicleDevices,
  setSortOrder,
  setSortedHeader,
  loadEngineHours,
  upsertEngineHours,
  loadMissingEngineHours,
} from '../../actions/manageVehiclesActions'
import { getYardTimeZone } from '../../selectors'
import { loadDeviceStatuses } from '../../actions/deviceStatusActions'

const mapStateToProps = (state, ownProps) => {
  const timezone = getYardTimeZone(state)

  return {
    timezone,
    selectedYard: state.general.selectedYard,
    vehicleList: state.manageVehicles.vehicleList,
    vehicleDevices: state.manageVehicles.vehicleDevices,
    tableSortOption: state.manageVehicles.tableSortOption,
    tableSortHeader: state.manageVehicles.tableSortHeader,
    engineHours: state.manageVehicles.engineHours,
    query: ownProps.location.query,
    userRole: path(['Role', 'name'])(state.general.userData),
  }
}

const mapDispatchToProps = {
  loadVehicles,
  loadVehicleDevices,
  loadDeviceStatuses,
  setSortOrder,
  setSortedHeader,
  loadEngineHours,
  upsertEngineHours,
  loadMissingEngineHours,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageVehicles)
