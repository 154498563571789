import { prop } from 'ramda'
import { saveAs } from 'file-saver'
import { toolkitApi } from '.'
import { API_TAG_ATTACHMENTS } from '../config'

const attachmentsApi = toolkitApi.injectEndpoints({
  tagTypes: [API_TAG_ATTACHMENTS],
  endpoints: (builder) => ({
    getAllAttachments: builder.query({
      query: ({ invoiceId }) => ({
        url: `billing/invoices/${invoiceId}/files`,
      }),
      transformResponse: prop('files'),
      providesTags: [API_TAG_ATTACHMENTS],
    }),
    deleteAttachment: builder.mutation({
      query: ({ invoiceId, filename }) => ({
        url: `billing/invoices/${invoiceId}/files/${encodeURIComponent(
          filename,
        )}`,
        method: 'DELETE',
      }),
    }),
    downloadAttachment: builder.mutation({
      query: ({ invoiceId, filename }) => ({
        url: `billing/invoices/${invoiceId}/files/${encodeURIComponent(
          filename,
        )}/download`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/download',
        },
        responseHandler: async (response) => {
          const blob = await response.blob()
          saveAs(blob, filename)
        },
        cache: 'no-cache',
      }),
    }),
  }),
})

export const {
  useGetAllAttachmentsQuery,
  useDeleteAttachmentMutation,
  useDownloadAttachmentMutation,
  useUploadAttachmentsMutation,
} = attachmentsApi
