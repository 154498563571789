import React from 'react'
import {
  __,
  prop,
  uniq,
  pipe,
  pluck,
  reject,
  includes,
  equals,
  mergeAll,
  values,
  unnest,
  defaultTo,
  map,
  path,
  filter,
  repeat,
  length,
  toPairs,
  groupBy,
  fromPairs,
} from 'ramda'
import { parseYardName, getTooltipProps } from './helpers'
import { TIERS_HEADERS as COMPOUND_TIERS_CHILDREN_HEADERS } from '../../config'
import FunctionDescription from './FunctionDescription'

export const VIEW_MODE = {
  PROD_SAVINGS: 'productivity_savings',
  SERVICES: 'services',
  CHARGES: 'charges',
  METRICS: 'metrics',
  EXPORT: 'export_order_items',
  DISPLAY_ORDER_ITEMS: 'display_order_items',
  TIERS: 'tiers',
}

const EDITABLE = 'editable'
const DERIVED = 'derived'
const CONSTANT = 'constant'
const SELECTABLE = 'selectable'

const CUSTOMER_NUMBER_HEADER = {
  text: 'Customer number',
  field: 'customer_number',
  isRequired: true,
  isEditable: true,
  placeholder: 'N/A',
  width: 160,
  tooltip: {
    description:
      'IFS Customer number/identifier for the customer being billed.',
  },
}

const TYPE_HEADER = {
  text: 'Type',
  width: 80,
  field: ['billing_item', 'type'],
  hasStartCase: true,
  tooltip: {
    description:
      'Billing element type is either Service, Productivity Saving, Charge, or Metric. Depending on the type, it shows up in different screens or screen areas in the app. IE: Metric values are located in top right corner on the “Submission” screen.',
  },
}

const FILLING_TYPE_HEADER = {
  text: 'Filling Type',
  field: 'filling_type',
  hasStartCase: true,
  tooltip: {
    description: (() => (
      <>
        <p>
          Defines the way values for the Billing element will be entered into
          the App.
        </p>
        <div>- Editable: Values are variable and can be edited by user</div>
        <div>
          - Derived: Values are derived by a formula/calculation in the App.
        </div>
        <div>
          - Constant: Values are fixed and do not change between billing periods
        </div>
        <div>
          - Productivity Savings: Special type for productivity savings Billing
          Period, similar to Derived
        </div>
      </>
    ))(),
  },
}

const BILLING_ELEMENT_HEADER = {
  text: 'Billing Element',
  width: 140,
  isSearchable: true,
  field: ['billing_item', 'code'],
  metaPath: ['billing_items_by_type', 'metric'],
  getTooltipProps,
  tooltip: {
    description:
      'Code used in the RMS app to enable billing functionality. Most are actual IFS sales parts but some are App specific codes.',
  },
}

const DISPLAY_NAME_HEADER = {
  text: 'Display Name',
  field: 'display_name',
  tooltip: {
    description:
      'Displayed name for each billing element, visible to user on Daily and submit screens in App',
  },
}

const DEFAULT_HEADERS = [BILLING_ELEMENT_HEADER, DISPLAY_NAME_HEADER]

const INPUT_METHOD_HEADER = {
  text: 'Input Method',
  field: 'is_daily',
  isDailyInput: true,
  isSelectable: true,
  tooltip: {
    description: (() => (
      <>
        <p>
          Defines the method that user will use to provide the value for the
          Billing Element: Values can be entered on daily screen (if they are
          variable day to day) or on the billing period Submission screen as 1
          value for the whole period.
        </p>
        <p>Shown in daily tables or when billing is being submitted</p>
      </>
    ))(),
  },
}

const INTERCOMPANY_YARD_HEADER = {
  text: 'Intercompany Yard',
  field: 'intercompany_yard_id',
  isIntercompanyYard: true,
  acceptNoSelection: true,
  width: 170,
  tooltip: {
    description:
      'Related Yard/PC under a different RMS company with specific billing arrangement involving both yards',
  },
  parseYardName,
}

const BILLING_FREQUENCY_HEADER = {
  text: 'Billing Frequency',
  field: 'billing_cycle',
  isBillingCycle: true,
  hasStartCase: true,
  width: 150,
  tooltip: {
    description: (() => (
      <>
        <p>
          Billing Cycle describes the timing of when a particular billing
          element is billed to customer
        </p>
        <div>EOM = End of Month</div>
        <div>MOM = Middle of Month</div>
      </>
    ))(),
  },
}

const IS_PARENT_HEADER = {
  text: 'Is Parent',
  field: 'is_parent',
  tooltip: {
    description: (() => (
      <>
        <p>
          Main yard that invoices the customer irregardless of any intercompany
          relationship.{' '}
        </p>
        <p>
          If the yard is part of an intercompany relationship and is NOT the
          parent, then yard invoices the parent yard listed.{' '}
        </p>
      </>
    ))(),
  },
}

const BILLING_ELEMENT_FOR_SERVICES = {
  ...BILLING_ELEMENT_HEADER,
  isSelectable: true,
  field: 'billing_item_id',
  getTooltipProps: null,
  metaPath: ['billing_items_by_type', 'service'],
}

const SERVICES_DEFAULT_HEADERS = [
  { ...DISPLAY_NAME_HEADER, isEditable: true },
  {
    ...BILLING_FREQUENCY_HEADER,
    isSelectable: true,
    metaPath: 'non_mom_billing_cycles',
  },
  {
    text: 'P.O.',
    field: 'po',
    isEditable: true,
    tooltip: {
      description:
        'Customer Issued numbers that are required on the billing invoice.  Separate PO numbers should result in separate invoices',
    },
  },
  {
    ...INTERCOMPANY_YARD_HEADER,
    isSelectable: true,
    metaPath: 'intercompany_yards',
  },
  {
    text: 'Is parent',
    field: 'is_parent',
    isBoolean: true,
    tooltip: {
      description: (() => (
        <>
          <p>
            Main yard that invoices the customer irregardless of any
            intercompany relationship.{' '}
          </p>
          <p>
            If the yard is part of an intercompany relationship and is NOT the
            parent, then yard invoices the parent yard listed.{' '}
          </p>
        </>
      ))(),
    },
  },
  {
    text: 'Is chargeable',
    field: 'is_chargeable',
    isBoolean: true,
    tooltip: {
      description:
        'Values for the billing element are charged/billed to customer and reflected on the IFS export template',
    },
  },
]

export const SERVICES_VIEW_MODE = {
  EDITABLE,
  DERIVED,
  CONSTANT,
  SELECTABLE,
}

export const CHARGES_VIEW_MODE = {
  EDITABLE,
  DERIVED,
}

export const PROD_SAVINGS_TABLE_HEADERS = [
  BILLING_FREQUENCY_HEADER,
  {
    text: 'Driver variables',
    field: 'driver_variables',
    frontPath: 'driver_variables',
    isArray: true,
    isSelectable: true,
    tooltip: {
      description:
        'Billing element whose variable value determines the productivity savings tier.  Example: Productivity (LPMH) number for the billing period',
    },
  },
  {
    text: 'Target variables',
    field: 'target_variables',
    metaPath: 'target_variables',
    isSelectable: true,
    isArray: true,
    tooltip: {
      description:
        'Billing element(s) whose billing period sum value(s) is/are used as the billable quantity for productivity savings.  Could be just Lifts, or Lifts+Flips+Cont.Stacking, etc',
    },
  },
  {
    text: 'Formula Name',
    field: 'formula_name',
    metaPath: 'prod_savings_function_names',
    isSelectable: true,
    tooltip: {
      description:
        'Name of the specific formula/logic used by the RMS App to calculate which productivity savings tier and quantity to bill to customer.',
    },
  },
  {
    text: 'Effective',
    field: 'effective',
    isSelectable: true,
    metaPath: 'effective_option_list',
    tooltip: {
      description:
        'Effective date of the productivity savings function for this location.',
    },
  },
]

export const METRICS_TABLE_HEADERS = [
  {
    ...BILLING_ELEMENT_HEADER,
    isSelectable: true,
    field: 'billing_item_id',
    getTooltipProps: null,
    transformOptions: ({ valueToRender, rawTemplateDetails, rawOptions }) => {
      const metricIdsToRemove = pipe(
        pluck('billing_item_id'),
        reject(equals(valueToRender)),
      )(rawTemplateDetails.metrics)

      const shouldBeRemoved = pipe(prop('id'), includes(__, metricIdsToRemove))

      return reject(shouldBeRemoved)(rawOptions)
    },
  },
  { ...DISPLAY_NAME_HEADER, isEditable: true },
  {
    ...BILLING_FREQUENCY_HEADER,
    isSelectable: true,
    metaPath: 'non_mom_billing_cycles',
  },
  {
    text: 'Fraction Digits',
    field: 'fraction_digits',
    isEditable: true,
    tooltip: {
      description: 'Number of digits after decimal place to display in the APP',
    },
  },
  { text: 'Default Value', isEditable: true, field: 'default_value' },
  { ...INPUT_METHOD_HEADER, isSelectable: true },
]

export const PROD_SAVINGS_VALUES_TABLE_HEADERS = [
  {
    text: 'Charge Type',
    field: 'rate_code',
    isEditable: true,
    tooltip: {
      description:
        'IFS charge type (similar to Sales Part) to be used for billing in IFS',
    },
  },
  {
    text: 'Charge Type Description',
    field: 'description',
    isEditable: true,
    tooltip: {
      description:
        'Description of IFS charge type (similar to Sales Part description)',
    },
  },
  {
    text: 'Value',
    field: 'driver_value',
    isEditable: true,
    tooltip: {
      description:
        'Value of [either the lower or upper range] of productivity savings tier, as defined by the charge type',
    },
  },
  {
    text: 'Charge Price/Curr',
    field: 'rate',
    isEditable: true,
    tooltip: {
      description:
        'Rate that corresponds to the specific charge type to be used during billing.  Anytime the rate changes it needs to be updated in IFS and in the APP',
    },
  },
  {
    text: 'Effective Date',
    field: 'date',
    isDate: true,
    tooltip: {
      description:
        'Effective date of the specific Charge Type and corresponding rate.',
    },
  },
]

export const VIEW_MODE_OPTIONS = [
  {
    text: 'Services',
    value: 'services',
    editable: true,
  },
  {
    text: 'Tiers',
    value: 'tiers',
    editable: true,
  },
  {
    text: 'Prod Savings',
    value: 'productivity_savings',
    editable: true,
  },
  {
    text: 'Charges',
    value: 'charges',
    editable: true,
  },
  {
    text: 'Metrics',
    value: 'metrics',
    editable: true,
  },
  {
    text: 'Display Order',
    value: 'display_order_items',
    editable: true,
  },
  {
    text: 'IFS Export',
    value: 'export_order_items',
    editable: true,
  },
]

export const SERVICES_INNER_TABS_OPTIONS = [
  {
    text: 'Editable',
    value: 'editable',
  },
  {
    text: 'Constant',
    value: 'constant',
  },
  {
    text: 'Derived',
    value: 'derived',
  },
  {
    text: 'Selectable',
    value: 'selectable',
  },
]

export const CHARGES_INNER_TABS_OPTIONS = [
  {
    text: 'Editable',
    value: 'editable',
  },
  {
    text: 'Derived',
    value: 'derived',
  },
]

const SERVICES_EDITABLE_DEFAULT_HEADERS = [
  ...SERVICES_DEFAULT_HEADERS,
  {
    text: 'Allowed values',
    field: 'allowed_values',
    isEditable: true,
    isArray: true,
    tooltip: {
      description:
        'Restricted input values. If it says “N/A”, it accepts all values.',
    },
  },
]

export const SERVICES_EDITABLE_TABLE_HEADERS = [
  BILLING_ELEMENT_FOR_SERVICES,
  ...SERVICES_EDITABLE_DEFAULT_HEADERS,
]

export const SERVICES_CONSTANT_TABLE_HEADERS = [
  BILLING_ELEMENT_FOR_SERVICES,
  ...SERVICES_DEFAULT_HEADERS,
  {
    text: 'Export Comment',
    field: 'export_comment',
    placeholder: 'N/A',
  },
  {
    text: 'Default value',
    field: 'default_value',
    isEditable: true,
    tooltip: {
      description:
        'Fixed value for billing element, used if same quantity is billed every billing period.',
    },
  },
]

const SERVICES_DERIVED_DEFAULT_HEADERS = [
  ...SERVICES_DEFAULT_HEADERS,
  {
    text: 'Formula options',
    field: 'formula_options',
    isEditable: true,
    isArray: true,
    tooltip: {
      description:
        'List of parameters used in the Formula Function. Parameters can be either billing elements or numbers',
    },
  },
  {
    text: 'Formula function',
    field: 'formula_function',
    isSelectable: true,
    metaPath: 'derived_service_function_names',
    hasStartCase: true,
    tooltip: {
      maxWidth: 1400,
      description: (
        <FunctionDescription
          widthList={[250, 600, 200]}
          text={`Applies logic to parameters passed in "Formula options" column.`}
          functionList={[
            {
              name: 'identity',
              description: 'Returns value of passed element.',
              options: 'ELEMENT',
              example: 'DISPLAY_LIF',
            },
            {
              name: 'identityTierOne',
              description: "Returns first tier's value of passed element.",
              options: 'ELEMENT',
              example: 'DISPLAY_LIF',
            },
            {
              name: 'returnFirstWhenSecondBetween',
              description:
                'Returns value of first element when second is between limits (limits included). Otherwise, 0.',
              options: 'ELEMENT ELEMENT NUMBER NUMBER',
              example: 'DISPLAY_LIF INBOUND_PERF 60 64.99',
            },
            {
              name: 'returnFirstWhenOthersGreaterThan',
              description:
                'Returns value of first element when others are greater than their respective limits. Otherwise,0.',
              options: 'ELEMENT [ELEMENT NUMBER]',
              example: 'LIF METRIC_INCTV-OB 98 LPMH 2',
            },
            {
              name: 'returnFirstWhenOthersLessThan',
              description:
                'Returns value of first element when others are less than their respective limits. Otherwise, 0.',
              options: 'ELEMENT [ELEMENT NUMBER]',
              example: 'LIF METRIC_INCTV-OB 98 LPMH 2',
            },
            {
              name: 'sum',
              description: 'Returns summed value of all passed elements.',
              options: '[ELEMENT]',
              example: 'LIF AU-UL SW-HR',
            },
            {
              name: 'sumWithFirstTier',
              description:
                'Sums all values except the last one. If the sum is greater than the last value, returns the last value. Otherwise, returns the sum.',
              options: '[ELEMENT] NUMBER',
              example: 'LIF AU-UL LPMH 100',
            },
            {
              name: 'sumWithLastTier',
              description:
                'Sums all values except the last one. If the sum is greater than or equal to the last value, returns the difference between the sum and the last value plus 1. Otherwise, returns 0.',
              options: '[ELEMENT] NUMBER',
              example: 'LIF AU-UL LPMH 100',
            },
            {
              name: 'calculatePortAllenTier1',
              description:
                'Sum all values. If the sum is greater than 3800, returns 3800. Otherwise, returns the sum.',
              options: 'ELEMENT ELEMENT',
              example: 'LOADED-VINS UNLOADED-VINS',
            },
            {
              name: 'calculatePortAllenTier2',
              description:
                'Sum all values. If the sum is greater than 3800, returns sum minus 3800. Otherwise, returns 0. ',
              options: 'ELEMENT ELEMENT',
              example: 'LOADED-VINS UNLOADED-VINS',
            },
            {
              name: 'calculateAllianceIncTv',
              description:
                'If [Train Release %: Z Train] & [Train Release %: Other] & [Train Arrival to Deramp Avg] are all True for the month then return total quantity of Lifts on days when the lift count was greater than of equal 3600 AND [Late Released Z-Trains] and [Safety Incidents] both were 0. ',
              options: 'ELEMENT ELEMENT ELEMENT',
              example:
                'TRAIN-RELEASE-PERC-Z TRAIN-RELEASE-PERC-OTHER TRAIN-ARRIVAL-DERAMP-AVG ',
            },
          ]}
        />
      ),
    },
  },
]

export const SERVICES_DERIVED_TABLE_HEADERS = [
  BILLING_ELEMENT_FOR_SERVICES,
  ...SERVICES_DERIVED_DEFAULT_HEADERS,
]

export const SERVICES_SELECTABLE_TABLE_HEADERS = [
  BILLING_ELEMENT_FOR_SERVICES,
  ...SERVICES_DEFAULT_HEADERS,
  {
    text: 'Selectable Options',
    field: 'selectable_options',
    hasModalInfo: true,
  },
]

function transformOptionsForCharges({
  valueToRender,
  rawTemplateDetails,
  rawOptions,
}) {
  const charges = pipe(values, unnest)(rawTemplateDetails.charges)
  const chargeIdsToRemove = pipe(
    pluck('billing_item_id'),
    reject(equals(valueToRender)),
  )(charges)

  const shouldBeRemoved = pipe(prop('id'), includes(__, chargeIdsToRemove))

  return reject(shouldBeRemoved)(rawOptions)
}

export const CHARGES_EDITABLE_TABLE_HEADERS = [
  {
    ...BILLING_ELEMENT_FOR_SERVICES,
    metaPath: ['billing_item_for_charges', 'editable'],
    transformOptions: transformOptionsForCharges,
  },
  ...SERVICES_EDITABLE_DEFAULT_HEADERS,
  {
    text: 'Penalty Rate',
    field: 'penalty_rate',
    isEditable: true,
    tooltip: {
      description:
        'Rate to be used when billing for the penalty charge type.  This rate is shown in the “Charge Price/Curr field” on the IFS export spreadsheet during billing.',
    },
  },
]

export const CHARGES_DERIVED_TABLE_HEADERS = [
  {
    ...BILLING_ELEMENT_FOR_SERVICES,
    metaPath: ['billing_item_for_charges', 'derived'],
    transformOptions: transformOptionsForCharges,
  },
  ...SERVICES_DEFAULT_HEADERS,
  {
    text: 'Formula options',
    field: 'formula_options',
    isEditable: true,
    isArray: true,
    tooltip: {
      description:
        'List of parameters used in the Formula Function. Parameters can be either billing elements or numbers',
    },
  },
  {
    text: 'Formula function',
    field: 'formula_function',
    isSelectable: true,
    metaPath: 'derived_charge_function_names',
    hasStartCase: true,
    tooltip: {
      maxWidth: 800,
      description: (
        <FunctionDescription
          widthList={[200, 150, 100]}
          text="Functions used for charges."
          functionList={[
            {
              name: 'getFuelSavingValueContractCpl',
              description: 'Logic for contract CPL',
              options: 'ELEMENT',
              example: 'DISPLAY_LIF',
            },
            {
              name: 'getFuelSavingValueContractGpl',
              description: 'Logic for contract GPL',
              options: 'ELEMENT',
              example: 'DISPLAY_LIF',
            },
            {
              name: 'getFuelSavingValueCurrentCpl',
              description: 'Logic for current CPL',
              options: 'ELEMENT',
              example: 'DISPLAY_LIF',
            },
          ]}
        />
      ),
    },
  },
  {
    text: 'Base Fuel Price',
    field: 'base_fuel_price',
    isEditable: true,
    tooltip: {
      description:
        'Base price of fuel as established during contract execution and stated explicitly in contract.  Used as the baseline for calculation of fuel surcharge or credit depending on future price fluctuations.',
    },
  },
  {
    text: 'Contract CPL/GPL',
    field: 'contract_cpl_gpl',
    isEditable: true,
    tooltip: {
      description:
        '“Cost Per Lift”, value that is stated in the contract as part of the fuel price calculation. Calculated as [Base Fuel Price]/[Contract GPL].  Note: not all contracts use a “Contract CPL” value in the fuel surcharge calculation.',
    },
  },
  {
    text: 'Change Tolerance',
    field: 'change_tolerance',
    isEditable: true,
    tooltip: {
      description:
        'Fuel price fluctuation range which determines if fuel surcharge/credit is to be charged to customer.  Fluctuation range is shown as a percentage above or below the Base Fuel Price (converted to decimal in the App).  Example: Change tolerance value 0.06 means: if current fuel price is greater than 6% over Base Price or 6% under Base Price then calculate the fuel surcharge/credit and charge to customer, otherwise the current fuel price is “within tolerance” and no fuel surcharge is to be billed.',
    },
  },
  {
    text: 'EIA Region',
    field: 'eia_region',
    isSelectable: true,
    metaPath: 'eia_region_list',
    tooltip: {
      description: (() => (
        <>
          <p>
            Region in which yard is located in, used to determine Base Fuel
            Price, stated explicitly in contract with customer.
          </p>
          <a
            href="https://www.eia.gov/petroleum/weekly/includes/padds.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.eia.gov/petroleum/weekly/includes/padds.php
          </a>
        </>
      ))(),
    },
  },
  {
    text: 'EIA Period',
    field: 'eia_period',
    isSelectable: true,
    metaPath: 'eia_period_list',
    tooltip: {
      description:
        'Fuel price interval to be used in fuel surcharge calculation, i.e. Weekly price or Monthly.',
    },
  },
]

export const EXPORT_TABLE_HEADERS = [
  { text: 'Nº', width: 60, field: null, isRowIndex: true },
  TYPE_HEADER,
  { ...INPUT_METHOD_HEADER, isDisabled: true },
  ...DEFAULT_HEADERS,
  FILLING_TYPE_HEADER,
  BILLING_FREQUENCY_HEADER,
  { text: 'Default Value', field: 'default_value' },
  INTERCOMPANY_YARD_HEADER,
  IS_PARENT_HEADER,
  CUSTOMER_NUMBER_HEADER,
  {
    text: 'Invoice Sequence',
    field: 'invoice_group',
    isRequired: true,
    isEditable: true,
    placeholder: 'N/A',
    tooltip: {
      description:
        'Also known as sequence number, the logic used by IFS to group/ungroup line items on the IFS Export template into 1 or multiple invoices.',
    },
  },
  {
    text: 'Billing Address',
    field: 'billing_address',
    isEditable: true,
    placeholder: 'N/A',
    tooltip: { description: 'If N/A, pass yard/site code' },
    width: 140,
  },
  {
    text: 'Export Comment',
    field: 'export_comment',
    isEditable: true,
    placeholder: 'N/A',
    width: 150,
    tooltip: {
      description: 'Comment that will be in "Comment" field when exported.',
    },
  },
  {
    text: 'Export Function',
    field: 'export_function',
    hasStartCase: true,
    isSelectable: true,
    hasEmptySelectableValue: true,
    metaPath: 'export_function_names',
    placeholder: 'N/A',
    width: 150,
    tooltip: {
      maxWidth: 700,
      description: (
        <FunctionDescription
          widthList={[200, 250, 100]}
          text={`Functions applied during IFS Export. NOTE: we can use raw_value in "Export Comment" to refer value of element without this calculation. See yard 244 as an example.`}
          functionList={[
            {
              name: 'divide',
              description: 'Divides element value by provided number.',
              options: 'NUMBER',
              example: 'LIF',
            },
            {
              name: 'nonNegativeSubtract',
              description:
                'Subtracts the provided number from element value. If result is less than 0, it returns 0.',
              options: 'ELEMENT',
              example: 'LIF',
            },
          ]}
        />
      ),
    },
  },
  {
    text: 'Export Function Options',
    isEditable: true,
    placeholder: 'N/A',
    field: 'export_function_options',
    isArray: true,
    width: 210,
    tooltip: {
      description:
        'List of parameters used in the Export Function. Parameters can be either billing elements or numbers',
    },
  },
  {
    text: 'Tiers Values',
    field: 'tiers',
    hasModalInfo: true,
    tooltip: {
      description:
        'Shows tiers related to the row. They appear in the same order.',
    },
  },
]

export const DISPLAY_ORDER_TABLE_HEADERS = [
  {
    text: 'Nº',
    width: 60,
    field: null,
    isRowIndex: true,
    tooltip: {
      description:
        'Order number that determines how Billing Elements are visually ordered on various screens and screen areas in the APP.',
    },
  },
  TYPE_HEADER,
  { ...INPUT_METHOD_HEADER, isDisabled: true },
  { text: 'Submission View', width: 150, field: null, hasSubmissionView: true },
  ...DEFAULT_HEADERS,
  FILLING_TYPE_HEADER,
  BILLING_FREQUENCY_HEADER,
  { text: 'Default Value', field: 'default_value' },
  INTERCOMPANY_YARD_HEADER,
  IS_PARENT_HEADER,
  { text: 'Export Comment', width: 150, field: 'export_comment' },
  {
    text: 'Tiers Values',
    field: 'tiers',
    hasModalInfo: true,
  },
]

export const TIERS_TABLE_HEADERS = [
  {
    ...BILLING_ELEMENT_HEADER,
    isSelectable: true,
    field: 'billing_item_id',
    getTooltipProps: null,
    getOptions: ({ rawTemplateDetails }) =>
      pipe(
        defaultTo([]),
        pluck('billing_item'),
        uniq,
      )(rawTemplateDetails.services.editable),
    transformOptions: ({ valueToRender, rawTemplateDetails, rawOptions }) => {
      const tierIdsToRemove = pipe(
        pluck('billing_item_id'),
        reject(equals(valueToRender)),
      )(rawTemplateDetails.tiers)

      const shouldBeRemoved = pipe(prop('id'), includes(__, tierIdsToRemove))

      return reject(shouldBeRemoved)(rawOptions)
    },
  },
  {
    text: 'Compound Tier Variable',
    field: 'compound_tier_variable',
    hasStartCase: true,
    isSelectable: true,
    acceptNoSelection: true,
    isTierRoot: true,
    metaPath: 'compound_variable_types',
    tooltip: {
      description:
        'If the value is LPMH it will be compound. Otherwise, it will be single tier. The compound tiers uses LPMH to create a second level of tiers.',
    },
  },
  {
    text: 'Tier type',
    field: 'tier_type',
    hasStartCase: true,
    isSelectable: true,
    metaPath: 'tier_types',
    tooltip: {
      description: (
        <>
          <p>The way the tier is calculated when generating IFS Export.</p>
          <ul>
            <li>
              select: uses tier sales part for the value with tier condition.
            </li>
            <li>spread: fills each tier progressively.</li>
            <li>
              cumulative spread: similar to spread, but accumulates values
              across multiple invoices.
            </li>
            <li>
              annual cumulative spread: similar to cumulative spread, but
              accumulates values since a specific date (Annual Start Date)
              throughout the year.
            </li>
          </ul>
        </>
      ),
    },
  },
  {
    text: 'Is chargeable',
    field: 'is_chargeable',
    isBoolean: true,
    tooltip: { description: 'If "Yes" it\'ll appear in IFS Export.' },
  },
  {
    text: 'Annual Start Date',
    field: 'annual_start_date',
    isDate: true,
    tooltip: {
      description:
        'Date when we start accumulating values to use in "annual cumulative spread" tier type.',
    },
  },
  {
    text: 'Tiers Values',
    field: 'tiers',
    hasModalInfo: true,
    tooltip: { description: 'List of tiers for selected element.' },
  },
]

export const PAGE_SECTION = {
  TABLE: 'Table',
  BILLABLE_SALESPARTS: 'Billable Salesparts',
  CALCULATED_VALUES: 'Calculated Values',
  ADDITIONAL_BILLING_DETAILS: 'Additional Billing Details',
}

export const EDITABLE_VIEWS = [
  'display_order_items',
  'export_order_items',
  'productivity_savings',
  'metrics',
  'services',
  'charges',
  'tiers',
]

export const VIEWS_WITH_REQUIRED_ENABLED = [
  'export_order_items',
  'productivity_savings',
]

export const AFFECTED_INVOICES_HEADERS = [
  { text: 'Start date', field: 'start_date' },
  { text: 'End date', field: 'end_date' },
  { text: 'Status', field: 'status' },
]

export const PROD_SAVINGS_VALUES_NEW_ROW = {
  date: null,
  description: '',
  driver_value: '',
  rate: '',
  rate_code: '',
}

export const PROD_SAVINGS_NEW_TABLE = {
  billing_cycle: 'monthly',
  customer_number: '',
  driver_variables: null,
  effective: '',
  formula_name: '',
  target_variables: null,
  values: [PROD_SAVINGS_VALUES_NEW_ROW],
}

export const METRICS_NEW_ROW = {
  billing_cycle: null,
  billing_item: {
    code: '-',
    type: '-',
    group: '-',
    price_uom: '-',
    description: '-',
    is_comment_required: false,
  },
  billing_item_id: null,
  default_value: null,
  display_name: null,
  filling_type: null,
  fraction_digits: null,
  intercompany_yard: null,
  intercompany_yard_id: null,
  is_chargeable: false,
  is_daily: false,
  is_parent: true,
}

export const VIEWS_WITH_CSV_EXPORT = ['productivity_savings']

export const FIELDS_TO_OMIT = ['customer_number']

export const BOOLEAN_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

const SERVICES_COMMONS = {
  billing_item: {
    code: '-',
    type: '-',
    group: '-',
    price_uom: '-',
    description: '-',
    is_comment_required: false,
  },
  billing_item_id: null,
  display_name: '',
  billing_cycle: null,
  customer_number: '',
  is_chargeable: null,
  is_parent: null,
  intercompany_yard_id: null,
  po: '',
}

export const SERVICES_EDITABLE_NEW_ROW = {
  ...SERVICES_COMMONS,
  allowed_values: '',
}

export const SERVICES_SELECTABLE_NEW_ROW = {
  ...SERVICES_COMMONS,
  selectable_options: [],
}

export const SERVICES_CONSTANT_NEW_ROW = {
  ...SERVICES_COMMONS,
  default_value: '',
}

export const SERVICES_DERIVED_NEW_ROW = {
  ...SERVICES_COMMONS,
  formula_options: '',
  formula_function: '',
}

export const SERVICES_SELECTABLE_OPTIONS_NEW_ROW = {
  code: '',
  description: '',
  display_name: '',
  value: '',
}

export const SELECTABLE_OPTIONS_READONLY_TABLES = [
  'display_order_items',
  'export_order_items',
]

export const CHARGES_EDITABLE_NEW_ROW = {
  ...mergeAll(
    CHARGES_EDITABLE_TABLE_HEADERS.map((item) => ({ [item.field]: '' })),
  ),
}

export const CHARGES_DERIVED_NEW_ROW = {
  ...mergeAll(
    CHARGES_DERIVED_TABLE_HEADERS.map((item) => ({ [item.field]: '' })),
  ),
}

export const TIERS_NEW_ROW = {
  ...mergeAll(TIERS_TABLE_HEADERS.map((item) => ({ [item.field]: null }))),
  tiers: [],
}

export const TIERS_INTERVAL_NEW_ROW = {
  interval: ['', ''],
  tiers: [],
}

const isFieldArray = (header) => Array.isArray(header.field)

export const TIERS_INTERVAL_CHILDREN_NEW_ROW = fromPairs([
  ...pipe(
    reject(isFieldArray),
    map((header) => [header.field, '']),
  )(COMPOUND_TIERS_CHILDREN_HEADERS),
  ...pipe(
    filter(isFieldArray),
    groupBy(path(['field', 0])),
    map(pipe(length, repeat(''))),
    toPairs,
  )(COMPOUND_TIERS_CHILDREN_HEADERS),
])

const EMPTY_INTERVAL = [null, null]

export const SINGLE_TIER_NEW_ROW = {
  code: '',
  description: '',
  interval: EMPTY_INTERVAL,
  export_comment: null,
}

export const COMPOUND_TIER_NEW_ROW = {
  interval: EMPTY_INTERVAL,
  tiers: [],
}
