import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'

import { getTimezoneName, getShortName } from '../../utils'

import * as S from './styles'

import timeIcon from '../../images/icons/icon_time.svg'

const Timestamp = ({ timezone, updated_at: updatedAt, user, advice }) => {
  const parsedDate = moment
    .tz(updatedAt, timezone)
    .format(`MM.DD.YY [at] HH:mm [${getTimezoneName(timezone)}]`)

  const parsedUser = {
    firstName: _.get(user, 'first_name'),
    lastName: _.get(user, 'last_name'),
  }

  return (
    <S.Container>
      {!_.isEmpty(advice) && (
        <S.Wrapper>
          <S.Icon src={advice.icon} />
          <S.Text>{advice.text}</S.Text>
        </S.Wrapper>
      )}

      <S.Wrapper>
        <S.Icon src={timeIcon} alt="Timestamp clock" />
        <S.Text>
          {updatedAt
            ? `Last updated by ${getShortName(parsedUser)} on ${parsedDate}`
            : 'No changes yet'}
        </S.Text>
      </S.Wrapper>
    </S.Container>
  )
}

Timestamp.propTypes = {
  updated_at: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  timezone: PropTypes.string.isRequired,
  advice: PropTypes.shape({
    icon: PropTypes.node,
    text: PropTypes.string,
  }),
}

Timestamp.defaultProps = {
  updated_at: null,
  user: {},
  advice: {},
}

export default Timestamp
