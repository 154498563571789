import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'
import {
  map,
  all,
  pipe,
  prop,
  equals,
  propEq,
  sortBy,
  includes,
  isNil,
} from 'ramda'

import { useGetTemplateDetailsQuery } from '../../services/billing'

import { BILLING_WRITE_ROLES } from '../../permissions/config'
import {
  NewContainer,
  NewHeader,
  NewToolTip,
  NewTabs,
  NewModal,
} from '../../components'

import Table from './Table'
import BillingCycleHistory from '../BillingCycleHistory'
import BillingComments from '../BillingComments'

import { history, parseDateTime } from '../../utils'

import {
  getTabs,
  getActiveTabItem,
  getTimestamp,
  getTableColumns,
  getTableRows,
} from './helpers'

import editIcon from '../../images/icons/icon_edit_dark.svg'
import commentIcon from '../../images/icons/icon_comment_dark.svg'
import templateIcon from '../../images/icons/icon_template.svg'

import * as S from './styles'

import { viewMode, editValueAndCommentMode, editCommentMode } from './constants'

const colorByBillingMode = {
  [viewMode]: '',
  [editValueAndCommentMode]: '--base-blue',
  [editCommentMode]: '--base-green',
}

const Billing = ({
  timezone,
  selectedYard,
  urlDate,
  billingTable,
  billingWeeks,
  loadBillingWeeks,
  loadBillingTable,
  upsertBillingTable,
  getFirstMissingDate,
  billingMissingDateAlert,
  setMissingDateAlert,
  showAlert,
  userRole,
  templateId,
}) => {
  const [billingMode, setBillingMode] = useState(viewMode)
  const [tableColumns, setTableColumns] = useState([])
  const [showCancelEditModal, setShowCancelEditModal] = useState(false)
  const [isEditValueLocked, setIsEditValueLocked] = useState(false)
  const [skipGetTemplateDetails, setSkipGetTemplateDetails] = useState(true)
  const hasWriteAccess = includes(userRole, BILLING_WRITE_ROLES)

  const firstWeek = _.head(_.get(billingWeeks, 'value.weeks'))

  const {
    data: templateDetails = [],
    isLoading: isLoadingTemplateDetails,
  } = useGetTemplateDetailsQuery(
    {
      templateId,
    },
    { skip: skipGetTemplateDetails },
  )

  const hasTemplate = !isNil(templateId)
  const templateName = hasTemplate
    ? _.get(templateDetails, 'template.name')
    : null

  const boundedWeekStartDate = moment
    .max(
      moment.tz(urlDate, timezone),
      moment.tz(_.get(firstWeek, 'startDate'), timezone),
    )
    .format('YYYY-MM-DD')

  useEffect(() => {
    if (!isNil(templateId)) {
      setSkipGetTemplateDetails(false)
    }
  }, [templateId])

  useEffect(() => {
    setBillingMode(viewMode)
  }, [selectedYard])

  useEffect(() => {
    loadBillingTable({
      yard: selectedYard,
      date: urlDate,
      templateId,
    })
    loadBillingWeeks({
      yard: selectedYard,
      date: moment.tz(urlDate, timezone).startOf('month').format('YYYY-MM-DD'),
    })
  }, [
    billingMode,
    urlDate,
    selectedYard,
    loadBillingTable,
    loadBillingWeeks,
    timezone,
    templateId,
  ])

  useEffect(() => {
    getFirstMissingDate({ yard: selectedYard, timezone, userRole })
  }, [selectedYard, timezone, getFirstMissingDate, userRole])

  useEffect(() => {
    const weekDays = _.get(billingTable, 'value.week_dates', [])
    setTableColumns(weekDays)
    setIsEditValueLocked(false)
  }, [billingTable])

  useEffect(
    () =>
      _.isNil(urlDate)
        ? history.push(
            `/billing/date/${moment
              .tz(timezone)
              .endOf('day')
              .format('YYYY-MM-DD')}`,
          )
        : history.changePathVariable({ name: 'date', value: urlDate }),
    [timezone, urlDate],
  )

  const weekTabs = getTabs({
    billingWeeks,
    billingMode,
    viewMode,
    timezone,
  })

  const rows = getTableRows({ rows: _.get(billingTable, 'value.rows', []) })

  const hasOnlyFinalInvoices = pipe(
    map(
      pipe(prop('yardServiceValues'), all(propEq('invoice_status', 'final'))),
    ),
    all(equals(true)),
  )(rows)

  const tableColumnsPayload = {
    columns: _.get(billingTable, 'value.week_dates', []),
    rows,
    timezone,
  }

  const columns = getTableColumns(tableColumnsPayload)

  const billingTableProps = {
    isLoading: billingTable.isLoading,
    timezone,
    billingMode,
    colorByBillingMode,
    tableTitle: 'Services and metrics',
    columns,
    rows,
  }

  function handleSaveDraft() {
    const billingRows = _.get(billingTableProps, 'rows', [])

    if (billingRows.some((row) => row.isCommentRequired)) {
      const rowsWithRequiredComments = rows.filter(
        (row) => row.isCommentRequired,
      )
      const hasMissingComments = rowsWithRequiredComments.some((row) =>
        _.get(row, 'yardServiceValues', []).some(
          (service) =>
            service.value > 0 &&
            (_.isEmpty(service.comment) || service.comment.trim().length === 0),
        ),
      )

      if (hasMissingComments) {
        return showAlert({
          type: 'failure',
          message: `Some required comments were missing`,
        })
      }
    }

    const keysToPick = ['value', 'comment', 'invoice_id']

    const parsedRows = billingRows.map((row) => ({
      billing_template_item_id: _.get(row, 'service_id'),
      columns: _.get(row, 'yardServiceValues', []).map((column) =>
        _.chain(column)
          .mapValues((value) => (value === '' ? null : value))
          .pick(keysToPick)
          .value(),
      ),
    }))

    const data = {
      rows: parsedRows,
      dates: tableColumns,
      yard: selectedYard,
    }

    return upsertBillingTable(data).finally(() => setBillingMode(viewMode))
  }

  function handleChangeTabs(value) {
    const selectedItemDate = _.chain(weekTabs)
      .find({ value })
      .get('startDate', urlDate)
      .value()

    const startDate = moment.tz(selectedItemDate, timezone).format('YYYY-MM-DD')

    history.changePathVariable({ name: 'date', value: startDate })
  }

  function handleExitEditStateModal() {
    setShowCancelEditModal(false)
    setBillingMode(viewMode)
  }

  function getHeaderTitle() {
    if (billingMode !== viewMode) {
      return `Edit ${billingMode}`
    }

    return 'Billing'
  }

  function getHeaderTitleTooltip() {
    if (hasTemplate) {
      return {
        title: 'Template details',
        description: (() => {
          const deletedAt = _.get(templateDetails, 'template.deleted_at')
          const startDate = _.get(templateDetails, 'template.start_date')
          const endDate = _.get(templateDetails, 'template.end_date')

          return (
            <>
              <div>{`Name: ${templateName}`}</div>
              <div>{`Start date: ${parseDateTime(
                moment.tz(startDate, timezone),
              )}`}</div>
              <div>{`End date: ${parseDateTime(
                moment.tz(endDate, timezone),
              )}`}</div>
              {!isNil(deletedAt) && (
                <div>{`Deleted at: ${parseDateTime(
                  moment.tz(deletedAt, timezone),
                )}`}</div>
              )}
            </>
          )
        })(),
      }
    }

    return null
  }

  const newTabsProps = {
    items: weekTabs,
    activeItem: getActiveTabItem({
      weekTabs,
      urlDate: boundedWeekStartDate,
      timezone,
    }),
    onChange: handleChangeTabs,
  }

  const dateProps = {
    timezone,
    startDate: moment.tz(urlDate, timezone),
    currentDate: moment.tz(timezone),
    onChange: ({ startDate }) =>
      history.changePathVariable({
        name: 'date',
        value: moment.tz(startDate, timezone).format('YYYY-MM-DD'),
      }),
    monthPicker: true,
    readOnly: billingMode !== viewMode,
  }

  const cancelEditModalProps = {
    visible: showCancelEditModal,
    onConfirm: () => setShowCancelEditModal(false),
    onClose: () => setShowCancelEditModal(false),
    onCancel: handleExitEditStateModal,
    title: 'Do you want to save?',
    message:
      'You are attempting to cancel without saving your changes. Would you like to save them?',
    type: 'alert',
    confirmText: 'Keep editing',
    cancelText: 'Cancel changes',
  }

  const reminderModalProps = {
    visible: billingMissingDateAlert.status === 'show',
    onConfirm: () => setBillingMode(editValueAndCommentMode),
    onClose: () => setMissingDateAlert({ status: 'hide' }),
    title: "You're at least 3 days behind.",
    message:
      "You haven't input billing numbers for at least 3 days. We’re going to take you to the edit view so you can enter your numbers.",
    confirmText: 'Take me to the edit view',
    type: 'date',
    isMandatory: true,
  }

  const isLoading = _.get(billingTable, 'isLoading') || isLoadingTemplateDetails
  const noTableToShow = _.get(billingTableProps, 'rows', []).length === 0
  billingTableProps.noTableToShow = noTableToShow

  const titleActionList = [
    {
      key: 2,
      icon: templateIcon,
      onClick: () =>
        history.push({
          pathname: `/yard/${selectedYard}/billing/templates`,
          query: { return_date: urlDate },
        }),
      disabled: billingMode !== viewMode,
      tooltip: {
        title: 'Billing Template Versions',
        action: 'Click to see billing template history',
        description:
          'View different billing template versions used during certain ranges of time.',
      },
    },
  ]

  if (hasWriteAccess && !hasOnlyFinalInvoices) {
    const isEditEnabled =
      !isLoading && !noTableToShow && billingMode === viewMode

    titleActionList.push({
      key: 0,
      icon: editIcon,
      onClick: () => setBillingMode(editValueAndCommentMode),
      disabled: isEditValueLocked || !isEditEnabled,
      tooltip: {
        title: 'Edit Billing',
        action: 'Click to edit billing values and comments',
        description:
          'Click this button to edit both values and comments in the current billing period. When you are finished, click on “save changes”',
      },
    })

    titleActionList.push({
      key: 1,
      icon: commentIcon,
      onClick: () => setBillingMode(editCommentMode),
      disabled: !isEditEnabled,
      tooltip: {
        title: 'Edit Comments',
        action: 'Click to add comments',
        description:
          'Click this button to leave comments on values. You can also edit past comments. When you are finished, click on “save changes”',
      },
    })
  }

  if (!isNil(templateId)) {
    titleActionList.push({
      key: -1,
      icon: commentIcon,
      tooltip: {
        title: `Template details`,
        description: (() => {
          const deletedAt = _.get(templateDetails, 'template.deleted_at')
          const startDate = _.get(templateDetails, 'template.start_date')
          const endDate = _.get(templateDetails, 'template.end_date')

          return (
            <>
              <div>{`Start date: ${parseDateTime(
                moment.tz(startDate, timezone),
              )}`}</div>
              <div>{`End date: ${parseDateTime(
                moment.tz(endDate, timezone),
              )}`}</div>
              {!isNil(deletedAt) && (
                <div>{`Deleted at: ${parseDateTime(
                  moment.tz(deletedAt, timezone),
                )}`}</div>
              )}
            </>
          )
        })(),
      },
    })
  }

  const titleAction = sortBy(prop('key'))(titleActionList)

  const headerProps = {
    title: getHeaderTitle(),
    titleTooltip: getHeaderTitleTooltip(),
    returnText: billingMode !== viewMode ? 'Cancel edit' : '',
    returnAction: () => setShowCancelEditModal(true),
    date: dateProps,
    titleAction,
    timestamp: getTimestamp({ billingTable, timezone }),
  }

  return (
    <>
      <NewHeader {...headerProps} />
      <NewContainer>
        <>
          <S.Wrapper>
            <BillingComments date={urlDate} templateId={templateId} />
            <BillingCycleHistory
              isSubmissionEnabled={billingMode === viewMode}
              urlDate={urlDate}
              templateId={templateId}
            />
          </S.Wrapper>

          <S.Wrapper>
            <NewTabs {...newTabsProps} />
            {billingMode !== viewMode && (
              <NewToolTip
                title="Save draft"
                action="Click to save your changes"
                description="In the edit mode, you can save a draft MANY times."
              >
                <S.Button
                  type="button"
                  onClick={handleSaveDraft}
                  editColor={colorByBillingMode[billingMode]}
                  editState
                >
                  Save
                </S.Button>
              </NewToolTip>
            )}
          </S.Wrapper>
          <S.Content>
            <Table {...billingTableProps} />
          </S.Content>
          <NewModal {...cancelEditModalProps} />
          <NewModal {...reminderModalProps} />
        </>
      </NewContainer>
    </>
  )
}

Billing.propTypes = {
  timezone: PropTypes.string.isRequired,
  loadBillingTable: PropTypes.func.isRequired,
  upsertBillingTable: PropTypes.func.isRequired,
  billingTable: PropTypes.shape({
    value: PropTypes.shape({
      end_date: PropTypes.string,
      last_updated: PropTypes.shape({
        value_updated_at: PropTypes.string,
        user: PropTypes.shape({
          first_name: PropTypes.string,
          id: PropTypes.number,
          last_name: PropTypes.string,
        }),
      }),
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          billing_cycle: PropTypes.string,
          code: PropTypes.string,
          columns: PropTypes.arrayOf(
            PropTypes.shape({
              billing_template_item_id: PropTypes.number,
              comment: PropTypes.any,
              created_at: PropTypes.string,
              date: PropTypes.string,
              deleted_at: PropTypes.any,
              id: PropTypes.number,
              invoice_id: PropTypes.number,
              updated_at: PropTypes.string,
              user_id: PropTypes.any,
              value: PropTypes.any,
            }),
          ),
          customer_number: PropTypes.string,
          customer_yard_id: PropTypes.any,
          default_value: PropTypes.any,
          description: PropTypes.string,
          display_name: PropTypes.string,
          display_order: PropTypes.number,
          filling_type: PropTypes.string,
          formula_function: PropTypes.any,
          formula_options: PropTypes.any,
          group: PropTypes.string,
          id: PropTypes.number,
          is_comment_required: PropTypes.bool,
          is_daily: PropTypes.bool,
          po: PropTypes.any,
          price_uom: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
      start_date: PropTypes.string,
      week_dates: PropTypes.arrayOf(PropTypes.string),
    }),
    isLoading: PropTypes.bool,
    billing_items: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        display_name: PropTypes.string,
        display_order: PropTypes.number,
        group: PropTypes.string,
        is_comment_required: PropTypes.bool,
        is_daily: PropTypes.bool,
        price_uom: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }).isRequired,
  selectedYard: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  billingWeeks: PropTypes.shape({
    value: PropTypes.shape({
      weeks: PropTypes.arrayOf(
        PropTypes.shape({
          startDate: PropTypes.string,
          endDate: PropTypes.string,
          count: PropTypes.number,
        }),
      ),
    }),
    isLoading: PropTypes.bool,
  }).isRequired,
  loadBillingWeeks: PropTypes.func.isRequired,
  urlDate: PropTypes.string,
  getFirstMissingDate: PropTypes.func.isRequired,
  billingMissingDateAlert: PropTypes.shape({
    status: PropTypes.oneOf(['hide', 'show']),
  }).isRequired,
  setMissingDateAlert: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  templateId: PropTypes.string,
}

Billing.defaultProps = {
  selectedYard: 0,
  urlDate: undefined,
  templateId: null,
}

export default Billing
