import { workerProfileTypes } from '../../actions/workerProfileActions'

const initialState = {
  loading: false,
  value: {
    average: 0,
    bins: [],
  },
}

const vehicleTimeMetrics = (state = initialState, { type, payload }) => {
  switch (type) {
    case workerProfileTypes.VEHICLE_TIME_METRICS_REQUESTED:
      return {
        ...state,
        loading: true,
        value: {
          average: 0,
          bins: [],
        },
      }

    case workerProfileTypes.VEHICLE_TIME_METRICS_LOADED:
      return {
        ...state,
        loading: false,
        value: payload,
      }

    case workerProfileTypes.VEHICLE_TIME_METRICS_FAILED:
      return initialState

    default:
      return state
  }
}

export default vehicleTimeMetrics
