import React from 'react'
import PropTypes from 'prop-types'

const TextAreaInput = (props) => (
  <textarea className="textAreaInput" {...props} />
)

TextAreaInput.propTypes = {
  placeholder: PropTypes.string,
}

TextAreaInput.defaultProps = {
  placeholder: '',
}

export default TextAreaInput
