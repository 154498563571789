import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import personaIcon from '../../images/icons/persona.svg'
import timeIcon from '../../images/icons/time.svg'
import { Loading, SendForm } from '../../components'

class NotesBox extends Component {
  static propTypes = {
    notes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        message: PropTypes.string.isRequired,
        userName: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
      }),
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    yardId: PropTypes.number.isRequired,
    selectedDate: PropTypes.instanceOf(moment).isRequired,
    loadYardNotes: PropTypes.func.isRequired,
    createYardNote: PropTypes.func.isRequired,
    createNote: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
    }).isRequired,
    timezone: PropTypes.string.isRequired,
    isReadOnly: PropTypes.bool,
  }

  static defaultProps = {
    isReadOnly: false,
  }

  componentDidMount() {
    this.loadYardNotes()
  }

  componentDidUpdate(prevProps) {
    const { yardId, selectedDate } = this.props

    if (
      prevProps.yardId !== yardId ||
      prevProps.selectedDate !== selectedDate
    ) {
      this.loadYardNotes()
    }
  }

  loadYardNotes = () => {
    const { loadYardNotes, yardId, selectedDate: date } = this.props
    if (yardId !== '-') {
      loadYardNotes({ yardId, date })
    }
  }

  handleSend = (message) => {
    const { createYardNote, yardId, selectedDate: date } = this.props
    return createYardNote({ yardId, date, message })
  }

  renderNotes() {
    const { notes, loading, timezone } = this.props

    if (loading) {
      return <Loading />
    }

    const list = notes.map(({ id, message, userName, createdAt }) => (
      <div key={id} className="notesBox-note">
        <div className="notesBox-message">{message}</div>
        <div className="notesBox-icon-text">
          <img src={personaIcon} alt="Persona icon" />
          <span>{userName}</span>
        </div>
        <div className="notesBox-icon-text">
          <img src={timeIcon} alt="Time icon" />
          <span>
            {moment(createdAt).tz(timezone).format('MM.DD.YYYY HH:mm')}
          </span>
        </div>
      </div>
    ))

    return <div className="notesBox-notes">{list}</div>
  }

  render() {
    const { createNote, isReadOnly } = this.props

    return (
      <div className="notesBox">
        {this.renderNotes()}
        {!isReadOnly && (
          <div className="notesBox-send-container">
            <SendForm onSubmit={this.handleSend} loading={createNote.loading} />
          </div>
        )}
      </div>
    )
  }
}

export default NotesBox
