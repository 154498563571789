import { connect } from 'react-redux'
import WorkerCharts from './WorkerCharts'
import './WorkerCharts.css'

import {
  loadVehicleTimeMetrics,
  loadBoxCount,
} from '../../../actions/workerProfileActions'

const mapStateToProps = (state) => ({
  vehicleTimeMetrics: state.workerProfile.vehicleTimeMetrics,
  boxCount: state.workerProfile.boxCount,
  flagsCount: state.workerProfile.flagsCount,
})

const mapDispatchToProps = {
  loadVehicleTimeMetrics,
  loadBoxCount,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkerCharts)
