/* eslint-disable object-shorthand */
import React from 'react'
import moment from 'moment-timezone'

import getMissingValues from './getMissingValues'

import * as S from '../styles'

function getTableColumns({ columns, rows, timezone }) {
  const parsedColumns = columns.map((column, index) => {
    const weekDate = moment(column).tz(timezone).startOf('day')

    const lastDayOfMonth = moment().tz(timezone).endOf('month').startOf('day')

    // If weekDate is the last day of a billing period, there's a chance it's recognized as a future date, due to time zones and date conversions.
    // Explicitly check if the weekDate is the same as the last day of the month. If so, futureDate === false, otherwise futureDate === whether or not weekDate is after current date
    const isFutureDate = weekDate.isSame(lastDayOfMonth)
      ? false
      : moment.tz(weekDate, timezone).isAfter(moment.tz(timezone), 'day')

    const isSecondBimonthlyPeriod = weekDate.date() > 15

    const missingValuesPayload = {
      rows,
      index,
    }

    const missingValues = getMissingValues(missingValuesPayload)

    return {
      key: index,
      isFutureDate,
      missingValues,
      isSecondBimonthlyPeriod,
      text: (
        <>
          {weekDate.format('dd')}
          <S.HeaderDay
            isValueMissing={missingValues}
            isFutureDate={isFutureDate}
          >
            {weekDate.format('MM/DD')}
          </S.HeaderDay>
        </>
      ),
    }
  })

  parsedColumns.push({ key: 'periodToDate', text: 'PTD' })
  parsedColumns.push({ key: 'monthToDate', text: 'MTD' })

  return parsedColumns
}

export default getTableColumns
