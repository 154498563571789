import { createSelector } from 'reselect'

const getYard = createSelector(
  [(state) => state.general.yardList, (state) => state.general.selectedYard],
  (yardList, selectedYardId) => {
    const selectedYard = yardList.filter(
      (yard) => yard.id === selectedYardId,
    )[0]
    return (
      selectedYard || {
        longitude: 39.5,
        latitude: -98.35,
        zoom_level: 16,
      }
    )
  },
)

export default getYard
