import { yardSummaryTypes } from '../../actions/yardSummaryActions'

const initialState = null

const selectedDate = (state = initialState, { type, payload }) => {
  switch (type) {
    case yardSummaryTypes.SELECTED_DATE_SET:
      return payload

    default:
      return state
  }
}

export default selectedDate
