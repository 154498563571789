import { connect } from 'react-redux'
import PrintAudits from './PrintAudits'

import { getYardTimeZone } from '../../selectors'

import { parseIntOrUndefined } from '../../utils'

import {
  loadAudits,
  resetSelectedUser,
} from '../../actions/safetyReportsActions'

const mapStateToProps = (state, ownProps) => ({
  timezone: getYardTimeZone(state),
  audits: state.safetyReports.audits,
  yardId: Number(ownProps.params.yardId),
  createdAtStart: parseIntOrUndefined(ownProps.location.query.created_at_start),
  createdAtEnd: parseIntOrUndefined(ownProps.location.query.created_at_end),
  workerId: Number(ownProps.location.query.worker_id),
  workerType: ownProps.location.query.worker_type,
  workerInfo: state.safetyReports.worker.value,
  yardList: state.general.yardList,
})

const mapDispatchToProps = {
  loadAudits,
  resetSelectedUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintAudits)
