import { head, isNil, toLower } from 'ramda'

export default function parseBillingCycle(cycle) {
  if (isNil(cycle)) {
    return null
  }

  const lowerCaseValue = Array.isArray(cycle)
    ? toLower(head(cycle))
    : toLower(cycle)

  switch (lowerCaseValue) {
    case 'monthly':
      return 'Monthly, EOM'
    case 'monthly_mom':
      return 'Monthly, MOM'
    case 'bimonthly':
      return 'Bimonthly'
    case 'weekly':
      return 'Weekly'

    default:
      return null
  }
}
