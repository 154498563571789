import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { tabPropType } from './propTypes'

class Tab extends Component {
  static propTypes = {
    ...tabPropType,
    index: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  handleClick = () => {
    const { index, onClick } = this.props

    onClick(index)
  }

  render() {
    const { title, icon, isActive } = this.props

    return (
      <div
        className={cx('tab', { isActive })}
        onClick={this.handleClick}
        role="button"
      >
        {icon && <img src={isActive ? icon.active : icon.inactive} alt="" />}
        <span>{title}</span>
      </div>
    )
  }
}

export default Tab
