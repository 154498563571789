import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { browserHistory } from 'react-router'

import './Button.css'

class Button extends Component {
  handleClick = () => {
    const { onClick, link } = this.props

    onClick()

    if (link !== null) {
      browserHistory.push(link)
    }
  }

  render() {
    const {
      className,
      text,
      iconUrl,
      small,
      isActive,
      type,
      isDisabled,
      children,
    } = this.props

    const customClass = cx('button', {
      [className]: className,
      small,
      active: isActive,
    })

    return (
      /* eslint-disable react/button-has-type */
      <button
        type={type}
        className={customClass}
        onClick={this.handleClick}
        disabled={isDisabled}
        data-analytics="button"
      >
        {iconUrl && <img src={iconUrl} alt="icon" />}
        {iconUrl && text && <Fragment>&nbsp;&nbsp;</Fragment>}
        {text}
        {children}
      </button>
      /* eslint-enable react/button-has-type */
    )
  }
}

Button.propTypes = {
  className: PropTypes.string,
  iconUrl: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  small: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  isActive: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
}

Button.defaultProps = {
  className: null,
  iconUrl: null,
  text: null,
  link: null,
  small: false,
  type: 'button',
  isActive: false,
  children: null,
  isDisabled: false,
  onClick: () => undefined,
}

export default Button
