import PropTypes from 'prop-types'

export const touchPropType = {
  created_at: PropTypes.string,
  id: PropTypes.number,
  image_id: PropTypes.number,
  prop_x: PropTypes.number,
  prop_y: PropTypes.number,
  updated_at: PropTypes.string,
  vcr_submission_id: PropTypes.number,
}

export const issuePropType = {
  id: PropTypes.number,
  ordinal: PropTypes.number,
  VcrTemplateIssue: PropTypes.shape({
    VcrTemplateIssueName: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
}
