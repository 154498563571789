import { createApi } from '@reduxjs/toolkit/query/react'
import { getReducerBaseQuery } from '../utils'

export const toolkitApi = createApi({
  baseQuery: getReducerBaseQuery(),
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
})

export const { usePrefetch } = toolkitApi
