import { manageVehiclesTypes } from '../../actions/manageVehiclesActions'

const initialState = {}

const vehicleData = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVehiclesTypes.VEHICLE_LOADED:
      return payload

    case manageVehiclesTypes.CHANGE_STATUS_SUCCEEDED:
      return {
        ...state,
        status: payload,
      }

    case manageVehiclesTypes.VEHICLE_RESET:
      return initialState

    case manageVehiclesTypes.CHANGE_STATUS_FAILED:
    case manageVehiclesTypes.VEHICLE_REQUESTED:
    default:
      return state
  }
}

export default vehicleData
