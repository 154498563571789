import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const BillingBadge = ({ text, amountToDisplay }) => (
  <S.Badge text={text}>
    {text === 'draft' ? `${text} ${amountToDisplay || ''}` : text}
  </S.Badge>
)

BillingBadge.propTypes = {
  text: PropTypes.oneOfType(['draft', 'success', 'accrual']).isRequired,
  amountToDisplay: PropTypes.string.isRequired,
}

export default BillingBadge
