import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import * as S from './styles'

const NewToggle = ({
  isChecked,
  onChange,
  icon,
  text,
  lightText,
  ...props
}) => (
  <S.Container>
    <S.StyledToggle
      checked={isChecked}
      onChange={onChange}
      icons={false}
      {...props}
    />
    {!_.isNil(icon) && <S.Icon src={icon} />}
    <S.Text hasLightText={lightText}>{text}</S.Text>
  </S.Container>
)

NewToggle.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  lightText: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
}

NewToggle.defaultProps = {
  lightText: false,
  icon: null,
}

export default NewToggle
