import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { path, isNil, includes } from 'ramda'
import {
  NewTable,
  NewContainer,
  NewHeader,
  NewModal,
  NewToolTip,
} from '../../components'

import * as S from './styles'

import { returnUrl } from './constants'

import { CRUD_YARD_WRITE_ROLES } from '../../permissions/config'

import { getRows, getHeaders } from './helpers'

import {
  useGetCustomersQuery,
  useDeleteCustomerMutation,
} from '../../services/customers'

import addIcon from '../../images/icons/icon_add_dark.svg'
import editIcon from '../../images/icons/icon_edit_big.svg'
import trashIcon from '../../images/icons/icon_trash_white.svg'

import { history } from '../../utils'

const ManageCustomers = ({ loggedUser, showAlert, showErrorAlert }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [searchString, setSearchString] = useState()
  const [customerToDelete, setCustomerToDelete] = useState()

  const userRole = path(['Role', 'name'])(loggedUser)
  const hasCrudYardWriteAccess = includes(userRole, CRUD_YARD_WRITE_ROLES)

  const {
    data: customers = [],
    isLoading,
    refetch: refetchCustomers,
  } = useGetCustomersQuery({})

  const [deleteCustomers] = useDeleteCustomerMutation()

  const search = {
    handleSearchQuery: (string) => setSearchString(string),
    placeholder: 'Seach customer',
    value: searchString,
  }

  const titleAction = hasCrudYardWriteAccess
    ? [
        {
          key: 0,
          icon: addIcon,
          tooltip: {
            title: 'Add new customer',
            action: 'Click to create a new customer',
          },
          onClick: () => history.push('/allYards/customers/create'),
        },
      ]
    : []

  const headerProps = {
    title: 'All Customers',
    titleAction,
    search,
    returnText: 'Back to yard list',
    returnUrl,
  }

  function handleCloseModal() {
    setShowDeleteModal(false)
    setCustomerToDelete(null)
  }

  function handleDelete() {
    deleteCustomers({ customerId: customerToDelete })
      .unwrap()
      .then(() => {
        setCustomerToDelete(null)
        refetchCustomers()

        return showAlert({
          type: 'success',
          message: 'Customer successfully deleted',
        })
      })
      .catch((error) =>
        showErrorAlert({
          error,
          defaultMessage: 'Failed to delete customer',
        }),
      )
  }

  function handleEditCustomer({ e, id }) {
    e.stopPropagation()

    if (isNil(id)) {
      return
    }

    history.push(`/allYards/customers/create/${id}`)
  }

  function renderActions({ id }) {
    const actions = [
      {
        icon: editIcon,
        action: handleEditCustomer,
        toolTip: {
          title: 'Edit Customer',
          description: 'Click to change customer details',
        },
      },
      {
        icon: trashIcon,
        action: () => setShowDeleteModal(true),
        toolTip: {
          title: 'Delete customer',
          description: 'Click to remove this customer',
        },
      },
    ]

    return (
      <S.ActionContainer>
        {actions.map((userAction, index) => (
          <NewToolTip key={index} {...userAction.toolTip}>
            <S.Action
              icon={userAction.icon}
              onClick={(e) => {
                e.stopPropagation()
                setCustomerToDelete(id)
                userAction.action({ e, id })
              }}
            >
              <S.Image src={userAction.icon} />
            </S.Action>
          </NewToolTip>
        ))}
      </S.ActionContainer>
    )
  }

  const tableProps = {
    headers: getHeaders({ hasCrudYardWriteAccess }),
    isLoading,
    searchString,
    rows: getRows({ customers, renderActions, hasCrudYardWriteAccess }),
    // sortOrder: tableSortOption,
    // sortedHeader: tableSortHeader,
    // onSortedHeaderChange: handleSortedHeader,
  }

  const deleteModalProps = {
    visible: showDeleteModal,
    onConfirm: handleDelete,
    onClose: handleCloseModal,
    onCancel: handleCloseModal,
    title: 'Are you sure?',
    message: `Do you really want to delete this customer?`,
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    type: 'warning',
  }

  return (
    <>
      <NewHeader {...headerProps} />
      <NewContainer>
        <NewTable {...tableProps} />
      </NewContainer>
      <NewModal {...deleteModalProps} />
    </>
  )
}

ManageCustomers.propTypes = {
  showAlert: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
  loggedUser: PropTypes.object.isRequired,
}

export default ManageCustomers
