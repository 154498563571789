import Promise from 'bluebird'
import { showAlert, showErrorAlert } from './alertMessageActions'
import { createTypes, onlyLatestRequest } from './utils'
import { history } from '../utils'
import api from '../api'

export const manageVehiclesTypes = createTypes('manageVehicles', [
  'VEHICLE_LIST_ORDER_SET',
  'CHANGE_STATUS_SUCCEEDED',
  'CHANGE_STATUS_FAILED',
  'VEHICLE_RESET',
  'SORT_HEADER_CHANGED',
  'LIST_ORDERING_CHANGED',
  { value: 'LIST', isAsync: true },
  { value: 'VEHICLE', isAsync: true },
  { value: 'POST_COMMENT', isAsync: true },
  { value: 'SAVE_VEHICLE', isAsync: true },
  { value: 'VEHICLE_DEVICES', isAsync: true },
  { value: 'VCRS', isAsync: true, isLazy: true },
  { value: 'ENGINE_HOURS', isAsync: true },
  { value: 'ENGINE_HOURS_EDIT', isAsync: true },
  { value: 'MISSING_ENGINE_HOURS', isAsync: true },
  { value: 'CREATE_VEHICLE', isAsync: true },
  { value: 'DELETE_VEHICLE', isAsync: true },
  { value: 'VWRS', isAsync: true, isLazy: true },
  { value: 'COMMENTS', isAsync: true, isLazy: true },
  { value: 'EVENTS', isAsync: true, isLazy: true },
])

export const loadVehicles = (yardId) => (dispatch) => {
  dispatch({ type: manageVehiclesTypes.LIST_REQUESTED })
  api.vehicles
    .getAll({ yardId })
    .then((vehicleList) => {
      dispatch({
        type: manageVehiclesTypes.LIST_LOADED,
        payload: vehicleList,
      })
    })
    .catch(() => {
      dispatch({
        type: manageVehiclesTypes.LIST_FAILED,
      })
    })
}

export const loadVehicleDevices = (yardId) => (dispatch) => {
  dispatch({
    type: manageVehiclesTypes.VEHICLE_DEVICES_REQUESTED,
  })
  return api.devices
    .getAll({ yardId })
    .then((devicesData) => {
      dispatch({
        type: manageVehiclesTypes.VEHICLE_DEVICES_LOADED,
        payload: devicesData,
      })
    })
    .catch(() =>
      dispatch({
        type: manageVehiclesTypes.VEHICLE_DEVICES_FAILED,
      }),
    )
}

export const loadVehicle = (vehicleId) => (dispatch) => {
  dispatch({ type: manageVehiclesTypes.VEHICLE_REQUESTED })
  api.vehicles.getOne(vehicleId).then((vehicle) => {
    dispatch({
      type: manageVehiclesTypes.VEHICLE_LOADED,
      payload: vehicle,
    })
  })
}

export const editVehicleStatus = (
  yardId,
  vehicleId,
  currentStatus,
  newStatus,
) => (dispatch) => {
  dispatch({
    type: manageVehiclesTypes.CHANGE_STATUS_SUCCEEDED,
    payload: newStatus,
  })
  api.vehicles
    .changeStatus({ vehicleId, newStatus })
    .then(() => {
      dispatch(loadVehicles(yardId))
    })
    .catch(() => {
      dispatch({
        type: manageVehiclesTypes.CHANGE_STATUS_FAILED,
        payload: currentStatus,
      })
    })
}

export const resetVehicle = () => (dispatch) => {
  dispatch({ type: manageVehiclesTypes.VEHICLE_RESET })
}

export const saveVehicle = ({ formData, vehicleId }) => (dispatch) => {
  dispatch({ type: manageVehiclesTypes.SAVE_VEHICLE_REQUESTED })

  return Promise.resolve({
    formData: {
      ...formData,
    },
    vehicleId,
  })
    .then(api.vehicles.save)
    .tap(() => {
      dispatch({ type: manageVehiclesTypes.SAVE_VEHICLE_LOADED })
    })
    .tapCatch(() => {
      dispatch({ type: manageVehiclesTypes.SAVE_VEHICLE_FAILED })
    })
}

export const loadVcrList = ({
  vehicleId,
  limit,
  offset,
  isLazyLoading = false,
}) => (dispatch) =>
  Promise.resolve({ vehicleId, limit, offset })
    .tap(() => dispatch({ type: manageVehiclesTypes.VCRS_REQUESTED }))
    .then(api.vcrs.list)
    .then((vcrs) => {
      if (isLazyLoading) {
        dispatch({
          type: manageVehiclesTypes.VCRS_LAZY_LOADED,
          payload: vcrs,
        })
      } else {
        dispatch({
          type: manageVehiclesTypes.VCRS_LOADED,
          payload: vcrs,
        })
      }
    })
    .tapCatch(() => dispatch({ type: manageVehiclesTypes.VCRS_FAILED }))

export const setSortedHeader = (index) => ({
  type: manageVehiclesTypes.SORT_HEADER_CHANGED,
  payload: index,
})

export const setSortOrder = (order) => ({
  type: manageVehiclesTypes.LIST_ORDERING_CHANGED,
  payload: order,
})

const getLatestsEngineHours = onlyLatestRequest(api.engineHours.getAll)

export const loadEngineHours = ({ yard, date }) => (dispatch) => {
  dispatch({ type: manageVehiclesTypes.ENGINE_HOURS_REQUESTED })

  return getLatestsEngineHours({ yard, date })
    .then((data) => {
      dispatch({
        type: manageVehiclesTypes.ENGINE_HOURS_LOADED,
        payload: data,
      })
    })
    .catch(() => {
      dispatch({ type: manageVehiclesTypes.ENGINE_HOURS_FAILED })
    })
}

const getMissingEngineHours = onlyLatestRequest(
  api.engineHours.getMissingEngineHours,
)

export const loadMissingEngineHours = ({ yard }) => (dispatch) => {
  dispatch({ type: manageVehiclesTypes.MISSING_ENGINE_HOURS_REQUESTED })

  return getMissingEngineHours({ yard })
    .then((data) => {
      dispatch({
        type: manageVehiclesTypes.MISSING_ENGINE_HOURS_LOADED,
        payload: data,
      })
    })
    .catch(() => {
      dispatch({ type: manageVehiclesTypes.MISSING_ENGINE_HOURS_FAILED })
    })
}

export const upsertEngineHours = ({ date, rows, updateEditMode }) => (
  dispatch,
) => {
  dispatch({ type: manageVehiclesTypes.ENGINE_HOURS_EDIT_REQUESTED })

  return api.engineHours
    .upsert({ date, rows })
    .then(() => {
      updateEditMode('view')
      dispatch({ type: manageVehiclesTypes.ENGINE_HOURS_EDIT_LOADED })
      dispatch(
        showAlert({
          type: 'success',
          message: `Edits to Engine Hours saved successfully`,
        }),
      )
    })
    .tapCatch((error) => {
      dispatch({ type: manageVehiclesTypes.ENGINE_HOURS_EDIT_FAILED })
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: 'Failed to edit content for Engine Hours',
        }),
      )
    })
}

export const createVehicle = ({ formData }) => (dispatch) => {
  dispatch({ type: manageVehiclesTypes.CREATE_VEHICLE_REQUESTED })

  return Promise.resolve({
    formData: {
      ...formData,
    },
  })
    .then(api.vehicles.upsertVehicle)
    .tap(() => {
      dispatch({ type: manageVehiclesTypes.CREATE_VEHICLE_LOADED })
    })
    .tapCatch(() => {
      dispatch({ type: manageVehiclesTypes.CREATE_VEHICLE_FAILED })
    })
}

const getLatestsVwrs = onlyLatestRequest(api.vwrs.getAll)

export const loadVwrs = ({
  vehicleId,
  offset,
  limit,
  isLazyLoading = false,
}) => (dispatch) => {
  dispatch({ type: manageVehiclesTypes.VWRS_REQUESTED })

  return getLatestsVwrs({ vehicleId, offset, limit })
    .then((data) => {
      if (isLazyLoading) {
        dispatch({
          type: manageVehiclesTypes.VWRS_LAZY_LOADED,
          payload: data,
        })
      } else {
        dispatch({
          type: manageVehiclesTypes.VWRS_LOADED,
          payload: data,
        })
      }
    })
    .catch((error) => {
      dispatch({ type: manageVehiclesTypes.VWRS_FAILED })
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: `Failed to load VWR's for this vehicle`,
        }),
      )
    })
}

const getLatestsComments = onlyLatestRequest(api.vehicleComments.getAll)

export const loadComments = ({
  vehicleId,
  offset,
  limit,
  isLazyLoading = false,
}) => (dispatch) => {
  dispatch({ type: manageVehiclesTypes.COMMENTS_REQUESTED })

  return getLatestsComments({ vehicleId, offset, limit })
    .then((data) => {
      if (isLazyLoading) {
        dispatch({
          type: manageVehiclesTypes.COMMENTS_LAZY_LOADED,
          payload: data,
        })
      } else {
        dispatch({
          type: manageVehiclesTypes.COMMENTS_LOADED,
          payload: data,
        })
      }
    })
    .catch((error) => {
      dispatch({ type: manageVehiclesTypes.COMMENTS_FAILED })
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: `Failed to get comments for this vehicle`,
        }),
      )
    })
}

const getLatestEvents = onlyLatestRequest(api.vehicleEvents.getAll)

export const loadEvents = ({
  vehicleId,
  offset,
  limit,
  isLazyLoading = false,
}) => (dispatch) => {
  dispatch({ type: manageVehiclesTypes.EVENTS_REQUESTED })

  return getLatestEvents({ vehicleId, offset, limit })
    .then((data) => {
      if (isLazyLoading) {
        dispatch({
          type: manageVehiclesTypes.EVENTS_LAZY_LOADED,
          payload: data,
        })
      } else {
        dispatch({
          type: manageVehiclesTypes.EVENTS_LOADED,
          payload: data,
        })
      }
    })
    .catch((error) => {
      dispatch({ type: manageVehiclesTypes.EVENTS_FAILED })
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: `Failed to get history data for this vehicle`,
        }),
      )
    })
}

export const postVehicleComment = (vehicleId, commentData) => (dispatch) => {
  dispatch({
    type: manageVehiclesTypes.POST_COMMENT_REQUESTED,
  })

  return api.vehicles
    .createComment(vehicleId, commentData.comment)
    .then(() => {
      dispatch({
        type: manageVehiclesTypes.POST_COMMENT_LOADED,
        payload: commentData,
      })
      return dispatch(
        loadComments({
          vehicleId,
          limit: 10,
          offset: 0,
        }),
      )
    })
    .catch(() => {
      dispatch({
        type: manageVehiclesTypes.POST_COMMENT_FAILED,
      })
    })
}

export const deleteVehicle = ({ vehicleId }) => (dispatch) => {
  dispatch({ type: manageVehiclesTypes.DELETE_VEHICLE_REQUESTED })

  return Promise.resolve({ vehicleId })
    .then(api.vehicles.deleteVehicle)
    .tap(() => {
      dispatch({ type: manageVehiclesTypes.DELETE_VEHICLE_LOADED })
      dispatch(
        showAlert({
          type: 'success',
          message: `Vehicle ${vehicleId} was successfully deleted`,
        }),
      )
      history.push('/vehicles')
    })
    .tapCatch(() => {
      dispatch({ type: manageVehiclesTypes.DELETE_VEHICLE_FAILED })
      showAlert({
        type: 'error',
        message: `Error: Vehicle ${vehicleId} could not be deleted`,
      })
    })
}
