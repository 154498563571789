import { createTypes } from './utils'

export const yardListTypes = createTypes('yardList', [
  'RANGE_SET',
  'SORT_HEADER_CHANGED',
  'LIST_ORDERING_CHANGED',
  { value: 'ALL_YARDS_METRICS', isAsync: true },
])

export const setRange = (range) => ({
  type: yardListTypes.RANGE_SET,
  payload: { range },
})

export const setSortedHeader = (index) => ({
  type: yardListTypes.SORT_HEADER_CHANGED,
  payload: index,
})

export const setSortOrder = (order) => ({
  type: yardListTypes.LIST_ORDERING_CHANGED,
  payload: order,
})
