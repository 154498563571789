import pathDefaultTo from './pathDefaultTo'
import multiplePathDefaultTo from './multiplePathDefaultTo'
import consecutiveDifference from './consecutiveDifference'
import defaultToIf from './defaultToIf'
import trimIfString from './trimIfString'

export {
  pathDefaultTo,
  multiplePathDefaultTo,
  consecutiveDifference,
  defaultToIf,
  trimIfString,
}
