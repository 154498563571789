import React from 'react'

const iconOverview = ({ fill }) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.24993 7.96844C9.24993 7.58611 8.92012 7.28571 8.50037 7.28571H7.15929C6.78223 7.28571 6.47656 7.59137 6.47656 7.96844V7.96844C6.47656 8.3455 6.78223 8.65117 7.15929 8.65117H7.6009C7.68369 8.65117 7.75081 8.71829 7.75081 8.80108V11.437C7.75081 11.5198 7.68369 11.5869 7.6009 11.5869H7.15929C6.78223 11.5869 6.47656 11.8926 6.47656 12.2696V12.2696C6.47656 12.6467 6.78223 12.9524 7.15929 12.9524H9.84145C10.2185 12.9524 10.5242 12.6467 10.5242 12.2696V12.2696C10.5242 11.8926 10.2185 11.5869 9.84145 11.5869H9.39984C9.31705 11.5869 9.24993 11.5198 9.24993 11.437V7.96844Z"
      fill={fill || '#000'}
    />
    <path
      d="M8.50335 6.47618C9.16871 6.47618 9.71763 5.94022 9.71763 5.27027C9.71763 4.60032 9.16871 4.04761 8.50335 4.04761C7.83799 4.04761 7.28906 4.60032 7.28906 5.27027C7.28906 5.94022 7.83799 6.47618 8.50335 6.47618Z"
      fill={fill || '#000'}
    />
    <path
      d="M8.5 0C3.81496 0 0 3.81496 0 8.5C0 13.185 3.81496 17 8.5 17C13.185 17 17 13.185 17 8.5C17 3.81496 13.185 0 8.5 0ZM8.5 15.6614C4.55118 15.6614 1.33858 12.4488 1.33858 8.5C1.33858 4.55118 4.55118 1.33858 8.5 1.33858C12.4488 1.33858 15.6614 4.55118 15.6614 8.5C15.6614 12.4488 12.4488 15.6614 8.5 15.6614Z"
      fill={fill || '#000'}
    />
  </svg>
)

export default iconOverview
