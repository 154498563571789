import styled, { css } from 'styled-components/macro'

const defaultPaddingValue = 20

const initialGrid = css`
  display: grid;
  align-items: center;
`

const tableDefaultPadding = css`
  padding: 0 ${defaultPaddingValue}px;
`

export const GeneralInfoTable = styled.div`
  height: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const InfoTable = styled.div`
  ${initialGrid}
  grid-template-columns: 1.2fr repeat(5, 1fr) 1.5fr;
  grid-template-rows: 100px 1fr;
`

export const Header = styled.div`
  ${initialGrid}
  ${tableDefaultPadding}

  font-family: 'ArticulatCF-Bold';
  background-color: var(--lighter-neutral-92);
  height: 40px;
`

export const Content = styled.div`
  ${initialGrid}
  ${tableDefaultPadding}

  position: relative;
  background-color: var(--lighter-neutral-99);
  height: 65px;
`

export const Battery = styled.img`
  width: 50px;
  position: absolute;
  left: calc(${defaultPaddingValue}px - 5px);
`

export const Gallery = styled.div`
  display: flex;
  margin: 0 auto;
  height: 75%;
  width: 60%;
`
