import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { path, isNil, includes } from 'ramda'
import {
  NewTable,
  NewContainer,
  NewHeader,
  NewModal,
  NewToolTip,
} from '../../components'

import { CRUD_YARD_WRITE_ROLES } from '../../permissions/config'

import * as S from './styles'

import { returnUrl } from './constants'

import { getRows, getHeaders } from './helpers'

import {
  useGetCompaniesQuery,
  useDeleteCompanyMutation,
} from '../../services/companies'

import addIcon from '../../images/icons/icon_add_dark.svg'
import editIcon from '../../images/icons/icon_edit_big.svg'
import trashIcon from '../../images/icons/icon_trash_white.svg'

import { history } from '../../utils'

const ManageCompanies = ({ loggedUser, showAlert, showErrorAlert }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [searchString, setSearchString] = useState()
  const [companyToDelete, setCompanyToDelete] = useState()

  const userRole = path(['Role', 'name'])(loggedUser)
  const hasCrudYardWriteAccess = includes(userRole, CRUD_YARD_WRITE_ROLES)

  const {
    data: companies = [],
    isLoading,
    refetch: refetchCompanies,
  } = useGetCompaniesQuery({})

  const [deleteCompany] = useDeleteCompanyMutation()

  const search = {
    handleSearchQuery: (string) => setSearchString(string),
    placeholder: 'Seach company',
    value: searchString,
  }

  const titleAction = hasCrudYardWriteAccess
    ? [
        {
          key: 0,
          icon: addIcon,
          tooltip: {
            title: 'Add new company',
            action: 'Click to create a new company',
          },
          onClick: () => history.push('/allYards/companies/create'),
        },
      ]
    : []

  const headerProps = {
    title: 'All Companies',
    titleAction,
    search,
    returnText: 'Back to yard list',
    returnUrl,
  }

  function handleCloseModal() {
    setShowDeleteModal(false)
    setCompanyToDelete(null)
  }

  function handleDelete() {
    deleteCompany({ companyId: companyToDelete })
      .unwrap()
      .then(() => {
        setCompanyToDelete(null)
        refetchCompanies()

        return showAlert({
          type: 'success',
          message: 'Company successfully deleted',
        })
      })
      .catch((error) =>
        showErrorAlert({
          error,
          defaultMessage: 'Failed to delete company',
        }),
      )
  }

  function handleEditCompany({ e, id }) {
    e.stopPropagation()

    if (isNil(id)) {
      return
    }

    history.push(`/allYards/companies/create/${id}`)
  }

  function renderActions({ id }) {
    const actions = [
      {
        icon: editIcon,
        action: handleEditCompany,
        toolTip: {
          title: 'Edit Company',
          description: 'Click to change company details',
        },
      },
      {
        icon: trashIcon,
        action: () => setShowDeleteModal(true),
        toolTip: {
          title: 'Delete company',
          description: 'Click to remove this company',
        },
      },
    ]

    return (
      <S.ActionContainer>
        {actions.map((userAction, index) => (
          <NewToolTip key={index} {...userAction.toolTip}>
            <S.Action
              icon={userAction.icon}
              onClick={(e) => {
                e.stopPropagation()
                setCompanyToDelete(id)
                userAction.action({ e, id })
              }}
            >
              <S.Image src={userAction.icon} />
            </S.Action>
          </NewToolTip>
        ))}
      </S.ActionContainer>
    )
  }

  const tableProps = {
    headers: getHeaders({ hasCrudYardWriteAccess }),
    isLoading,
    searchString,
    rows: getRows({ companies, renderActions, hasCrudYardWriteAccess }),
    // sortOrder: tableSortOption,
    // sortedHeader: tableSortHeader,
    // onSortedHeaderChange: handleSortedHeader,
  }

  const deleteModalProps = {
    visible: showDeleteModal,
    onConfirm: handleDelete,
    onClose: handleCloseModal,
    onCancel: handleCloseModal,
    title: 'Are you sure?',
    message: `Do you really want to delete this company?`,
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    type: 'warning',
  }

  return (
    <>
      <NewHeader {...headerProps} />
      <NewContainer>
        <NewTable {...tableProps} />
      </NewContainer>
      <NewModal {...deleteModalProps} />
    </>
  )
}

ManageCompanies.propTypes = {
  showAlert: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
  loggedUser: PropTypes.object.isRequired,
}

export default ManageCompanies
