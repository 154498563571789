import React from 'react'
import _ from 'lodash'
import Select from 'react-select'

import * as S from './styles'

const Dropdown = ({ value, options, isMulti, ...props }) => {
  const selectedValueFromOptionList = isMulti
    ? options.filter((option) =>
        _.defaultTo(value, []).map(String).includes(String(option.value)),
      )
    : options.filter((option) => String(option.value) === String(value))

  return (
    <S.Container>
      <S.StyledSelect
        className="react-select-container"
        classNamePrefix="react-select"
        value={selectedValueFromOptionList}
        options={options}
        isMulti={isMulti}
        {...props}
      />
    </S.Container>
  )
}

Dropdown.propTypes = Select.propTypes

export default Dropdown
