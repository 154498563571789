import _ from 'lodash'
import moment from 'moment-timezone'

export default function getDownloadFilename({ invoice, yardCode, timezone }) {
  const status = _.get(invoice, 'status')
  const period = `${moment
    .tz(invoice.start_date, timezone)
    .format('MM.DD')}-${moment.tz(invoice.end_date, timezone).format('MM.DD')}`

  return `${yardCode}_${period}_${status.toUpperCase()}`
}
