import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'

import * as S from './styles'

import { Dropdown } from '../../../components'

const selectOptions = [
  { label: 'Operational', value: 'operational' },
  { label: 'Repairs Needed', value: 'under_repair' },
  { label: 'Out of Service', value: 'inactive' },
]

class VehicleStatusSelector extends Component {
  handleSelection = (value, vehicleId) => {
    const { editVehicleStatus, selectedYard, vehicleData } = this.props
    const vehicleStatus = _.get(vehicleData, 'vehicle.status')

    editVehicleStatus(selectedYard, vehicleId, vehicleStatus, value.value)
  }

  formatOptions = (vehicleData) => {
    const { timezone } = this.props

    return selectOptions.map(({ value, label }) => ({
      label: moment(vehicleData.updated_at).tz(timezone).format('MMM DD HH:mm'),
      value,
      optionLabel: label,
    }))
  }

  customOption = ({ data, ...props }) => {
    const { value, optionLabel } = data

    return (
      <S.StyledOption value={value} {...props}>
        {optionLabel}
      </S.StyledOption>
    )
  }

  customControl = (props) => {
    const selectedValue = props.getValue()
    const value = _.isEmpty(selectedValue)
      ? null
      : _.get(_.head(selectedValue), 'value')

    return <S.StyledControl value={value} {...props} />
  }

  customSingleValue = (props) => (
    <S.StyledSingleValue value={_.get(props, 'data.value')} {...props} />
  )

  render() {
    const { data, isDisabled } = this.props

    return (
      <div
        role="button"
        className="statusSelector"
        onClick={(e) => e.stopPropagation()}
      >
        <Dropdown
          value={data.status}
          onChange={(value) => this.handleSelection(value, data.id)}
          isSearchable={false}
          isClearable={false}
          options={this.formatOptions(data)}
          components={{
            Option: this.customOption,
            Control: this.customControl,
            SingleValue: this.customSingleValue,
          }}
          isDisabled={isDisabled}
        />
      </div>
    )
  }
}

VehicleStatusSelector.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
      PropTypes.objectOf(PropTypes.string),
    ]),
  ),
  editVehicleStatus: PropTypes.func,
  selectedYard: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  vehicleData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
      PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        ),
      ),
    ]),
  ),
  timezone: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
}

VehicleStatusSelector.defaultProps = {
  data: {},
  editVehicleStatus: () => undefined,
  selectedYard: null,
  vehicleData: {},
  isDisabled: false,
}

export default VehicleStatusSelector
