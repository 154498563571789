import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { issuePropType, touchPropType } from './propTypes'

import {
  Gallery,
  CommentBox,
  VCRStatus,
  IssueHeader,
  IssueList,
  Loading,
  NewContainer,
  NewHeader,
  Blank,
} from '../../components'

import { getDateByDays } from '../../utils/momentExtensions'
import { history } from '../../utils'

import iconDownload from '../../images/icons/icon_export_dark.svg'
import tooltipIconDownload from '../../images/tooltip/download.svg'

import './VCR.css'

const rowHeader = {
  vehicle_name: 'VEHICLE NAME',
  full_name: 'CREATED BY',
  submitted_ts: 'CREATED ON',
  engine_hours: 'ENGINE HS.',
  status: 'STATUS',
}
class VCR extends Component {
  static propTypes = {
    yardId: PropTypes.number.isRequired,
    loadVCR: PropTypes.func,
    postCommentState: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.string),
    vcrData: PropTypes.shape({
      loading: PropTypes.bool,
      value: PropTypes.shape({
        issues: PropTypes.arrayOf(PropTypes.shape(issuePropType)),
        comment: PropTypes.string,
        engine_hours: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        submitted_at: PropTypes.string,
        vcr_template_id: PropTypes.number,
        id: PropTypes.number,
        touches: PropTypes.arrayOf(PropTypes.shape(touchPropType)),
        vehicle_name: PropTypes.string,
        status: PropTypes.string,
        vehicle_id: PropTypes.number,
        User: PropTypes.object,
      }),
    }).isRequired,
    vehicleList: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      value: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    isSidebarCollapsed: PropTypes.bool.isRequired,
    loadVehicles: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
    headerReturnOptions: PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string,
    }).isRequired,
    resetHeaderReturnOptions: PropTypes.func.isRequired,
  }

  static defaultProps = {
    loadVCR: () => undefined,
    postCommentState: '',
    params: {},
  }

  componentDidMount() {
    const { loadVCR, params, loadVehicles, yardId } = this.props

    loadVCR(params.vcr_id)
    loadVehicles(yardId)
  }

  componentWillUnmount() {
    const { resetHeaderReturnOptions } = this.props

    resetHeaderReturnOptions()
  }

  componentDidUpdate(prevProps) {
    const { postCommentState, yardId } = this.props

    if (
      prevProps.postCommentState === 'pending' &&
      postCommentState === 'success'
    ) {
      this.handleCommentChange('')
    }

    if (prevProps.yardId !== yardId) {
      history.push('/vcrs')
    }
  }

  dataFormat = (data) => ({
    ...data,
    submitted_ts: getDateByDays(data.submitted_at),
    full_name: _.isNil(data.User)
      ? '-'
      : `${data.User.first_name} ${data.User.last_name}`,
    status: <VCRStatus status={data.status} />,
  })

  render() {
    const {
      vcrData: { loading: vcrDataLoading, value: vcrDataValue },
      vehicleList: { loading: vehicleListLoading, value: vehicleListValue },
      params,
      isSidebarCollapsed,
      timezone,
      headerReturnOptions,
    } = this.props

    const vcrIssues = vcrDataValue.issues ? vcrDataValue.issues : []
    const vcrComment = [
      {
        comment: vcrDataValue.comment,
        User: vcrDataValue.User,
        created_at: vcrDataValue.submitted_at,
      },
    ]

    const isLoading = vcrDataLoading || vehicleListLoading

    const columnsData = isLoading ? null : this.dataFormat(vcrDataValue)

    const vehicle = vehicleListValue.find(
      (item) => item.vehicle_name === vcrDataValue.vehicle_name,
    )
    const vehicleTypeId = _.get(vehicle, 'vehicle_type_id', 1)

    const titleAction = !isLoading
      ? [
          {
            key: 0,
            icon: iconDownload,
            tooltip: {
              titleIcon: tooltipIconDownload,
              title: 'Preview PDF',
              action: 'Click to preview and save the PDF',
            },
            onClick: () => history.push(`/vcrs/${params.vcr_id}/print`),
          },
        ]
      : []

    return (
      <>
        <NewHeader
          title={isLoading ? 'Loading' : `VCR ${vcrDataValue.id}`}
          returnText={_.get(headerReturnOptions, 'text')}
          returnUrl={_.get(headerReturnOptions, 'path')}
          titleAction={titleAction}
        />
        <NewContainer className="vcr">
          {!isLoading &&
            (vcrDataValue.id === undefined || !vehicleListValue.length) && (
              <Blank />
            )}

          {isLoading ? (
            <Loading />
          ) : (
            <div className="individualView flex-column">
              <div className="view__info-row flex-row">
                {Object.keys(rowHeader).map((item) => (
                  <div className="row__item" key={item}>
                    <div className="row__header">{rowHeader[item]}</div>
                    <div className={`row__data ${item} flex-row`}>
                      {columnsData[item]}
                    </div>
                  </div>
                ))}
              </div>
              <div className="view__container flex-row">
                <div className="galleryView">
                  <IssueHeader
                    updatedAt={vcrDataValue.submitted_at}
                    timezone={timezone}
                  />
                  <IssueList issues={vcrIssues} />
                  <Gallery
                    data={{
                      touches: vcrDataValue.touches,
                      vehicleType_id: vehicleTypeId,
                    }}
                    isSidebarCollapsed={isSidebarCollapsed}
                  />
                </div>
                <div className="vcrComments">
                  <CommentBox
                    comments={vcrComment}
                    title="Driver Comments"
                    isReadOnly
                  />
                </div>
              </div>
            </div>
          )}
        </NewContainer>
      </>
    )
  }
}

export default VCR
