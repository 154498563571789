import { manageUsersTypes } from '../../actions/manageUsersActions'

const initialState = 1

const tableSortHeader = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageUsersTypes.SORT_HEADER_CHANGED:
      return payload

    default:
      return state
  }
}

export default tableSortHeader
