import { map, omit } from 'ramda'
import * as yup from 'yup'

export function getValidationSchema(fields) {
  const validationSchema = yup
    .object()
    .shape(
      Object.assign(
        {},
        ...fields.map((item) => ({ [item.name]: item.validation })),
      ),
    )

  return validationSchema
}

export function getInitialFormFields(fields) {
  return map(omit(['validation']))(fields)
}
