import { head, defaultTo } from 'lodash'
import clientApi from './utils/clientApi'

const getAll = ({ userId, startTime, endTime, bins, status }, options = {}) =>
  clientApi({
    ...options,
    method: 'get',
    url: `/sessions/users/${userId}`,
    params: {
      status,
      startTime,
      endTime,
      bins,
    },
  }).then(({ data }) => data)

const getLastActivity = (deviceId) =>
  clientApi({
    method: 'get',
    url: '/sessions/adjacent/latest',
    params: {
      device_id: deviceId,
    },
  }).then(({ data }) => defaultTo(head(data), null))

const getCurrent = (userId) =>
  clientApi({
    method: 'get',
    url: `/metrics/sessions/users/${userId}/current`,
  }).then(({ data }) => data)

const getActiveTime = (
  {
    yardId,
    startTime,
    endTime,
    status = ['securement', 'moving', 'parked'],
    bins = 1,
  },
  options = {},
) =>
  clientApi({
    ...options,
    method: 'get',
    url: `/sessions/yards/${yardId}`,
    params: {
      startTime,
      endTime,
      status,
      bins,
    },
  }).then(({ data }) => data)

const sessions = {
  getAll,
  getLastActivity,
  getCurrent,
  getActiveTime,
}

export default sessions
