import { connect } from 'react-redux'
import MetricsCards from './MetricsCards'
import './MetricsCards.css'

const mapStateToProps = (state) => ({
  flagsCount: state.workerProfile.flagsCount,
  vehicleTimeMetrics: state.workerProfile.vehicleTimeMetrics,
  boxCount: state.workerProfile.boxCount,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MetricsCards)
