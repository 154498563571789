import { manageUsersTypes } from '../../actions/manageUsersActions'

const INIT_DATE_RANGE = 'AVG_LAST_7_DAYS'
const initialState = {
  currentMetricsInterval: INIT_DATE_RANGE,
  previousMetricsInterval: INIT_DATE_RANGE,
}

const metricsIntervalOption = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageUsersTypes.METRICS_INTERVAL_OPTION_SET: {
      const { currentMetricsInterval } = state

      return {
        ...state,
        previousMetricsInterval: currentMetricsInterval,
        currentMetricsInterval: payload,
      }
    }

    case manageUsersTypes.METRICS_INTERVAL_OPTION_ROLLBACK: {
      const { previousMetricsInterval } = state
      return {
        ...state,
        currentMetricsInterval: previousMetricsInterval,
      }
    }

    default:
      return state
  }
}

export default metricsIntervalOption
