import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import ToolTip from '../ToolTip'
import TableRow from './TableRow'
import './Table.css'

const Table = ({
  colNum,
  tableList,
  fieldList,
  isLinkable,
  linkUrl,
  toolTipOptions,
}) => (
  <div className="tableContainer">
    <div className="tableBody">
      {tableList.map((rowData, index) => {
        const row = (
          <TableRow
            key={index}
            colNum={colNum}
            fieldList={fieldList}
            rowData={rowData}
            title={linkUrl}
            rowId={index}
          />
        )
        if (isLinkable) {
          return (
            <Link to={`${linkUrl}${rowData.id}`} key={index}>
              {row}
            </Link>
          )
        }
        if (toolTipOptions && rowData.toolTipMessage) {
          return (
            <ToolTip
              key={index}
              message={rowData.toolTipMessage}
              {...toolTipOptions}
            >
              <div>{row}</div>
            </ToolTip>
          )
        }
        return row
      })}
    </div>
  </div>
)

Table.propTypes = {
  colNum: PropTypes.number,
  tableList: PropTypes.arrayOf(PropTypes.object),
  fieldList: PropTypes.arrayOf(PropTypes.string),
  isLinkable: PropTypes.bool,
  linkUrl: PropTypes.string,
  toolTipOptions: PropTypes.shape(ToolTip.propTypes),
}

Table.defaultProps = {
  colNum: 0,
  tableList: [],
  fieldList: [],
  isLinkable: false,
  linkUrl: '',
  toolTipOptions: null,
}

export default Table
