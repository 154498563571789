import { getUserFullName } from '../utils'

const parseNote = (serverNote, user) => ({
  id: serverNote.id,
  message: serverNote.note,
  userName: getUserFullName(user),
  createdAt: serverNote.created_at,
})

export default parseNote
