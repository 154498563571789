import React, { useRef, useState, useEffect, useCallback } from 'react'
import {
  __,
  any,
  map,
  join,
  prop,
  pipe,
  find,
  count,
  pluck,
  values,
  equals,
  dissoc,
  propEq,
  filter,
  reject,
  sortBy,
  isEmpty,
  includes,
  fromPairs,
  defaultTo,
  intersection,
} from 'ramda'
import PropTypes from 'prop-types'
import prettyBytes from 'pretty-bytes'
import useFileUpload from '@osmanpontes/react-use-file-upload'
import * as S from './styles'
import { Blank, Loading } from '../../../components'
import api from '../../../api'

const FILE_STATE = {
  QUEUED: 'QUEUED',
  IS_SENT: 'IS_SENT',
  IS_LOADING: 'IS_LOADING',
  IS_REMOVING: 'IS_REMOVING',
  HAS_FAILED: 'HAS_FAILED',
}

const STATUS_TO_SEND = [FILE_STATE.QUEUED, FILE_STATE.HAS_FAILED]
const isStatusToSend = includes(__, STATUS_TO_SEND)
const getFileNames = pluck('name')

const Attachments = ({
  invoiceId,
  attachments,
  isLoadingAttachments,
  hasWriteAccess,
  onDelete,
  onClick,
  onAttachmentsChange,
  showAlert,
  setAttachments,
}) => {
  const [uploadStateByFile, setUploadStateByFile] = useState({})
  const { files, handleDragDropEvent, setFiles, removeFile } = useFileUpload()

  const isLoading = pipe(
    values,
    any(equals(FILE_STATE.IS_LOADING)),
  )(uploadStateByFile)

  const hasFileToSend = pipe(values, any(isStatusToSend))(uploadStateByFile)

  const inputRef = useRef()

  const setUploadStateForAllFiles = useCallback(
    (selectedFiles, value) =>
      setUploadStateByFile((previousUploadStateByFile) => ({
        ...previousUploadStateByFile,
        ...pipe(
          map((file) => [file.name, value]),
          fromPairs,
        )(selectedFiles),
      })),
    [setUploadStateByFile],
  )

  const setDefaultUploadStateForAllFiles = useCallback(
    (selectedFiles, value) =>
      setUploadStateByFile((previousUploadStateByFile) => ({
        ...pipe(
          map((file) => [file.name, value]),
          fromPairs,
        )(selectedFiles),
        ...previousUploadStateByFile,
      })),
    [setUploadStateByFile],
  )

  const setUploadStateForOneFile = (file, value) =>
    setUploadStateByFile((previousUploadStateByFile) => ({
      ...previousUploadStateByFile,
      [file.name]: value,
    }))

  const removeUploadStateForOneFile = (file) =>
    setUploadStateByFile(dissoc(file.name))

  useEffect(() => {
    onAttachmentsChange(
      pipe(values, count(equals(FILE_STATE.IS_SENT)))(uploadStateByFile),
    )
  }, [onAttachmentsChange, uploadStateByFile])

  useEffect(() => {
    setUploadStateForAllFiles(attachments, FILE_STATE.IS_SENT)
  }, [attachments, setUploadStateForAllFiles])

  useEffect(() => {
    setDefaultUploadStateForAllFiles(files, FILE_STATE.QUEUED)
  }, [files, setDefaultUploadStateForAllFiles])

  const handleSubmit = async (event) => {
    event.preventDefault()

    const filesToUpload = filter((file) =>
      isStatusToSend(uploadStateByFile[file.name]),
    )(files)

    setUploadStateForAllFiles(filesToUpload, FILE_STATE.IS_LOADING)
    const errors = []

    await api.invoices.uploadFileList({
      invoiceId,
      files: filesToUpload,
      onSucess: async ({ file }) => {
        setUploadStateForOneFile(file, FILE_STATE.IS_SENT)
      },
      onError: async ({ file, error }) => {
        setUploadStateForOneFile(file, FILE_STATE.HAS_FAILED)
        errors.push(error.response.data)
      },
    })

    const hasError = !isEmpty(errors)

    if (hasError) {
      const message = pipe(
        find(propEq('code', 'INVALID_FILE_NAME')),
        prop('message'),
        defaultTo('At least one file upload failed.'),
      )(errors)

      showAlert({
        type: 'failure',
        message,
      })
    }
  }

  async function handleDelete(file) {
    const isNewFile = find((newFile) => file.name === newFile.name)(files)

    if (
      isNewFile &&
      includes(uploadStateByFile[file.name], [
        FILE_STATE.QUEUED,
        FILE_STATE.HAS_FAILED,
      ])
    ) {
      removeFile(file.name)
      removeUploadStateForOneFile(file)
      return
    }

    setUploadStateForOneFile(file, FILE_STATE.IS_REMOVING)
    try {
      await onDelete(file.name)

      if (isNewFile) {
        removeFile(file.name)
      } else {
        setAttachments(reject(propEq('name', file.name)))
      }

      removeUploadStateForOneFile(file)
    } catch (error) {
      setUploadStateForOneFile(file, FILE_STATE.HAS_FAILED)
    }
  }

  function handleOnClick({ isDisabled, filename }) {
    if (isDisabled) {
      return
    }
    onClick(filename)
  }

  function renderFileState(file) {
    switch (uploadStateByFile[file.name]) {
      case FILE_STATE.QUEUED:
        return 'Queued'
      case FILE_STATE.IS_LOADING:
        return 'Loading...'
      case FILE_STATE.IS_REMOVING:
        return 'Removing...'
      case FILE_STATE.IS_SENT:
        return 'Sent'
      case FILE_STATE.HAS_FAILED:
        return 'Failed'
      default:
        return '?'
    }
  }

  function renderFiles() {
    const filesToRender = sortBy(prop('created_at'))([
      ...attachments,
      ...files,
    ]).reverse()

    if (isLoadingAttachments) {
      return (
        <S.LoadingContainer>
          <Loading />
        </S.LoadingContainer>
      )
    }

    if (filesToRender.length === 0) {
      return <Blank small title="No files to show" />
    }

    return filesToRender.map((file) => {
      const fileState = uploadStateByFile[file.name]
      const isFileQueued = fileState === FILE_STATE.QUEUED
      const isDownloadDisabled = fileState !== FILE_STATE.IS_SENT

      return (
        <S.File key={file.name} $isQueued={isFileQueued}>
          <S.FilenameWrapper
            $isDisabled={isDownloadDisabled}
            onClick={() =>
              handleOnClick({
                isDisabled: isDownloadDisabled,
                filename: file.name,
              })
            }
          >
            <S.Filename title={file.name}>
              <S.FileState>{renderFileState(file)}</S.FileState>{' '}
              {`${file.name}`}
            </S.Filename>{' '}
            ({prettyBytes(Number(file.size))})
          </S.FilenameWrapper>

          <S.Remove
            onClick={() => handleDelete(file)}
            disabled={
              !hasWriteAccess ||
              includes(fileState, [
                FILE_STATE.IS_LOADING,
                FILE_STATE.IS_REMOVING,
              ])
            }
          />
        </S.File>
      )
    })
  }

  function handleSelectFiles({ event, selectedFiles }) {
    const selectedFileNames = getFileNames(selectedFiles)
    const localFileNames = getFileNames(files)
    const serverFileNames = getFileNames(attachments)

    const attachedFileNames = [...localFileNames, ...serverFileNames]

    const repeatedFiles = intersection(attachedFileNames, selectedFileNames)

    if (isEmpty(repeatedFiles)) {
      setFiles(event, 'a')
    } else {
      const isPlural = repeatedFiles.length > 1
      const message = `The following file${
        isPlural ? 's are' : 'is'
      } already attached: "${join(', ')(repeatedFiles)}".`
      showAlert({
        type: 'failure',
        message,
      })
    }

    inputRef.current.value = null
    return null
  }

  return (
    <S.Container>
      <S.Dropzone
        onDragEnter={handleDragDropEvent}
        onDragOver={handleDragDropEvent}
        onDrop={(event) => {
          handleDragDropEvent(event)

          handleSelectFiles({
            event,
            selectedFiles: event.dataTransfer.files,
          })
        }}
      >
        <S.Text>Drag & Drop your files here</S.Text>

        <S.Button
          onClick={() => inputRef.current.click()}
          disabled={!hasWriteAccess}
        >
          Browse Files
        </S.Button>
        <S.Button
          onClick={handleSubmit}
          disabled={!hasWriteAccess || !hasFileToSend || isLoading}
          $upload
        >
          {isLoading ? 'Uploading...' : 'Upload Selected Files'}
        </S.Button>

        <input
          ref={inputRef}
          type="file"
          name="file"
          multiple
          style={{ display: 'none' }}
          onChange={(event) =>
            handleSelectFiles({
              event,
              selectedFiles: event.target.files,
            })
          }
        />
      </S.Dropzone>

      <S.Filezone>{renderFiles()}</S.Filezone>
    </S.Container>
  )
}

Attachments.propTypes = {
  invoiceId: PropTypes.number.isRequired,
  attachments: PropTypes.array,
  isLoadingAttachments: PropTypes.bool,
  hasWriteAccess: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onAttachmentsChange: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  setAttachments: PropTypes.func.isRequired,
}

Attachments.defaultProps = {
  attachments: [],
  isLoadingAttachments: false,
}

export default Attachments
