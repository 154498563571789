import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import { useSelector } from 'react-redux'

import { isEmpty } from 'ramda'
import * as S from './styles'

import { TABLE_HEADERS } from './constants'
import { getTableRows } from './helpers'

import { NewHeader, NewTable, NewContainer, NewToggle } from '../../components'
import {
  useGetBillingTemplatesQuery,
  useUpdateBillingTemplateDisplayOrderMutation,
} from '../../services/billing'
import { history } from '../../utils'
import billingIcon from '../../images/icons/icon_billing_table.svg'

const BillingTemplateVersions = ({
  selectedYard,
  timezone,
  returnDate,
  showAlert,
  showErrorAlert,
  setShowDeletedTemplates,
}) => {
  const [searchString, setSearchString] = useState('')
  const [sortedHeader, setSortedHeader] = useState(TABLE_HEADERS[0].key)
  const [sortOrder, setSortOrder] = useState('desc')

  const { showDeletedTemplates } = useSelector(
    (state) => state.billingTemplateVersions,
  )

  const [onDisplayOrderUpdate] = useUpdateBillingTemplateDisplayOrderMutation()

  const { data = [], isLoading } = useGetBillingTemplatesQuery({
    yardId: selectedYard,
  })

  const rows = getTableRows({
    data: data.templates,
    timezone,
    selectedYard,
    returnDate,
    showDeletedTemplates,
  })

  function handleSearchQuery(value) {
    setSearchString(value)
  }

  async function handleDisplayOrderUpdate({ rowsToUpdate, name, startDate }) {
    const body = {
      name,
      yard_id: selectedYard,
      start_date: moment.tz(startDate).format('YYYY-MM-DD'),
      display_order_data: rowsToUpdate,
    }

    await onDisplayOrderUpdate(body)
      .unwrap()
      .then(() =>
        showAlert({
          type: 'success',
          message: `Billing template was successfully updated`,
        }),
      )
      .catch((error) =>
        showErrorAlert({
          error,
          defaultMessage: 'Failed to update billing template',
        }),
      )

    return null
  }

  function handleSortedHeader(headerIndex) {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')
    return setSortedHeader(headerIndex)
  }

  const titleAction = [
    {
      key: 0,
      icon: billingIcon,
      tooltip: {
        title: 'General Billing Elements',
        action: 'Click to access general billing elements table',
      },
      onClick: () =>
        history.push({
          pathname: `/yard/${selectedYard}/billing/items`,
          query: { return_date: returnDate },
        }),
    },
  ]

  const headerProps = {
    title: 'Billing Template Versions',
    returnText: 'Back to Billing',
    returnUrl: `/billing/date/${returnDate}`,
    titleAction,
    search: {
      handleSearchQuery,
      placeholder: 'Search',
    },
  }

  const tableProps = {
    headers: TABLE_HEADERS,
    rows,
    searchString,
    isLoading,
    sortedHeader,
    sortOrder,
    onSortedHeaderChange: handleSortedHeader,
    onDisplayOrderUpdate: handleDisplayOrderUpdate,
    enableDragAndDrop: false,
    blankProps: {
      type: 'default',
      title: 'No Billing Templates to show',
      subtitle: 'This content is empty',
      action: {
        text: 'Create a new template',
        onClick: () =>
          history.push({
            pathname: `/yard/${selectedYard}/billing/templates/new/services`,
            query: { return_date: returnDate },
          }),
      },
    },
  }

  function handleShowDeletedTemplates() {
    return setShowDeletedTemplates(!showDeletedTemplates)
  }

  return (
    <>
      <NewHeader {...headerProps} />
      <S.ToggleContainer>
        {!isEmpty(tableProps.rows) && (
          <NewToggle
            isChecked={showDeletedTemplates}
            onChange={handleShowDeletedTemplates}
            text={`Toggle to ${
              showDeletedTemplates ? 'HIDE' : 'SHOW'
            } deleted templates`}
          />
        )}
      </S.ToggleContainer>
      <NewContainer>
        <NewTable {...tableProps} />
      </NewContainer>
    </>
  )
}

BillingTemplateVersions.propTypes = {
  selectedYard: PropTypes.number.isRequired,
  returnUrlDate: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  returnDate: PropTypes.string.isRequired,
  showAlert: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
  setShowDeletedTemplates: PropTypes.func.isRequired,
  showDeletedTemplatesFromUrl: PropTypes.bool,
}

BillingTemplateVersions.defaultProps = {
  showDeletedTemplatesFromUrl: false,
}

export default BillingTemplateVersions
