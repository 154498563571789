import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Step from './Step'
import ProgressBar from '../ProgressBar'

class Wizard extends PureComponent {
  static Step = Step

  state = {
    step: 0,
    values: {},
  }

  componentDidMount() {
    const { initialValues } = this.props

    this.updateInitialValues(initialValues)
  }

  componentDidUpdate(prevProps) {
    const { initialValues } = this.props

    if (prevProps.initialValues !== initialValues) {
      this.updateInitialValues(initialValues)
    }
  }

  updateInitialValues = (initialValues) => {
    this.setState({
      values: {
        ...initialValues,
      },
    })
  }

  handlePrevious = () => {
    const { step } = this.state

    this.setState({
      step: step - 1,
    })
  }

  handleNext = () => {
    const { step } = this.state

    this.setState({
      step: step + 1,
    })
  }

  handleSubmit = () => {
    const { onSubmit } = this.props
    const { values } = this.state

    onSubmit(values)
  }

  handleRegisterStep = (values) => {
    const { values: currentValues } = this.state

    this.setState({
      values: {
        ...currentValues,
        ...values,
      },
    })
  }

  render() {
    const { children, isSubmitting } = this.props
    const { step, values } = this.state
    const size = children.length

    return (
      <div>
        <ProgressBar step={step} size={size} />
        {React.Children.map(children, (child, index) => {
          if (index === step) {
            return React.cloneElement(child, {
              savedValues: values,
              onNext: this.handleNext,
              onPrevious: this.handlePrevious,
              onSubmit: this.handleSubmit,
              onRegisterStep: this.handleRegisterStep,
              isFirst: step === 0,
              isLast: step === size - 1,
              isSubmitting,
            })
          }

          return null
        })}
      </div>
    )
  }
}

Wizard.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.oneOfType([PropTypes.object]),
}

Wizard.defaultProps = {
  initialValues: {},
}

export default Wizard
