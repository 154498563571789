import styled from 'styled-components'

export const Button = styled.button`
  width: fit-content;
  padding: 0 15px;
  color: var(--lighter-neutral-99);
  height: 40px;
  border-radius: var(--border-radius);
  border: none;
  text-align: center;
  background: var(--base-red);
  margin-bottom: ${(props) => (props.$noMargin ? 0 : '15px')};
`
