import { equals, findIndex } from 'ramda'
import { roleIdToName } from '../../config'

const permissionOrder = [
  'SUPER_ADMIN',
  'ADMIN',
  'UPPER_MANAGER',
  'MANAGER',
  'SUPERVISOR',
  'CORPORATE_BILLER',
]

export default function getPermissionLevel(roleId) {
  const permissionLevel = findIndex(equals(roleIdToName[roleId]))(
    permissionOrder,
  )

  if (permissionLevel === -1) {
    return permissionOrder.length
  }

  return permissionLevel
}
