import { isNil } from 'lodash'
import Promise from 'bluebird'
import clientApi from './utils/clientApi'

const getAll = ({ userId, startTime, endTime, bins, yardId }, options = {}) => {
  const validate = () => {
    if (isNil(userId) && isNil(yardId)) {
      throw new Error('Missing userId or yardId')
    }
  }

  const getRequestOptions = () => {
    if (userId) {
      return {
        method: 'get',
        url: `/shifts/users/${userId}`,
        params: {
          startTime,
          endTime,
          bins,
        },
      }
    }
    return {
      method: 'get',
      url: `/shifts/yards/${yardId}`,
      params: {
        startTime,
        endTime,
        bins,
      },
    }
  }

  const addOptions = (selectedOptions) => ({
    ...options,
    ...selectedOptions,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(addOptions)
    .then(clientApi)
    .then(formatResult)
}

const getByDay = ({ yardId, date, numberOfDays }, options = {}) => {
  const addOptions = (selectedOptions) => ({
    ...options,
    ...selectedOptions,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve({
    method: 'get',
    url: '/shifts/aggregated_by_day',
    params: {
      yard_id: yardId,
      date,
      number_of_days: numberOfDays,
    },
  })
    .then(addOptions)
    .then(clientApi)
    .then(formatResult)
}

const shifts = {
  getAll,
  getByDay,
}

export default shifts
