import { alertMessageTypes } from '../../actions/alertMessageActions'

const initialState = {
  type: 'success',
  message: '',
  hidden: true,
  timeout: null,
  fullWidthStyle: false,
  redirectPath: null,
}

const alertMessage = (state = initialState, { type, payload }) => {
  switch (type) {
    case alertMessageTypes.HIDE:
      return initialState
    case alertMessageTypes.SHOW: {
      const { timeout = initialState.timeout } = payload

      return {
        ...state,
        type: payload.type,
        message: payload.message,
        hidden: false,
        timeout,
        fullWidthStyle: payload.fullWidthStyle,
        redirectPath: payload.redirectPath,
      }
    }
    default:
      return state
  }
}

export default alertMessage
