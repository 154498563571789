import styled from 'styled-components/macro'
import { animationFloat } from '../../css/sharedStyles'

export const FlagsTable = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(90vh - 225px);
  color: #fff;
  background-color: var(--darker-blue-17);
  padding: 0 15px;

  ::-webkit-scrollbar-thumb {
    background: var(--darker-neutral-45);
    border: 3px solid var(--darker-blue-17);
  }
`

export const Icon = styled.img`
  object-fit: contain;
`

export const FlagItem = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr 50px;
  grid-gap: 5px;
  align-items: center;
  border-bottom: 1px solid var(--darker-neutral-45);
  padding: 15px 0;
  font-family: 'ArticulatCF-Medium';
  cursor: pointer;
  opacity: 0.7;
  transition: 0.2s all ease;

  :hover {
    opacity: 1;

    ${Icon} {
      animation: ${animationFloat} 0.8s ease infinite;
    }
  }
`

export const Title = styled.div`
  font-family: 'ArticulatCF-Bold';
`

export const Message = styled.div`
  font-size: 14px;
  color: var(--lighter-neutral-96);
`

export const Date = styled.div`
  font-size: 14px;
  padding: 8px;
  border-radius: var(--border-radius);
  text-align: center;
  background: var(--darker-blue-20);
`
