import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { OverlayView } from '@react-google-maps/api'

import checkBeaconState from '../utils/checkBeaconState'
import Loading from '../../../components/Loading'

import VehicleIconMap from '../../../components/VehicleIconMap'
import './HistoryViewMarkers.css'
import { isPlayBackLoading } from '../../../utils'

const statusColor = {
  red: '#fd397c',
  grey: '#3b415b',
  green: '#42EAAA',
  yellow: '#F9D21E',
}

class HistoryViewMarkers extends Component {
  onIconClick = (trackData) => {
    const { setIconPinned } = this.props

    setIconPinned(trackData.vehicle_id)
  }

  getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  })

  getVehicleStatus = (element) => {
    if (element.status === 'securement') {
      return 'grey'
    }
    if (element.vcr_submission_id === null && element.user_id !== null) {
      return 'yellow'
    }
    if (element.vcr_submission_id === null || element.user_id === null) {
      return 'red'
    }
    if (element.vcr_submission_id !== null && element.user_id !== null) {
      return 'green'
    }

    return ''
  }

  checkBeaconState = (id) => {
    const { iconSelected, iconPinned } = this.props

    return checkBeaconState({ id, iconSelected, iconPinned })
  }

  renderVehicleIcon(currentTrackingData) {
    const {
      vehicleList: { value: vehicleListValue },
    } = this.props
    const position = {
      lat: currentTrackingData.latitude,
      lng: currentTrackingData.longitude,
    }

    const { vehicle_id: vehicleId } = currentTrackingData
    const vehicle = vehicleListValue.find((item) => item.id === vehicleId)

    if (!vehicle) {
      return null
    }

    const { vehicle_type_name: vehicleTypeName } = vehicle
    const vehicleStatus = this.getVehicleStatus(currentTrackingData)
    const currentColor =
      vehicleStatus === 'yellow' ? 'var(--base-yellow)' : vehicleStatus

    const vehicleIconMapElement = (
      <VehicleIconMap
        vehicleTypeName={vehicleTypeName}
        currentColor={currentColor}
        vehicleData={currentTrackingData}
        onIconClick={this.onIconClick}
      />
    )

    if (this.checkBeaconState(vehicleId)) {
      return (
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={position}
          getPixelPositionOffset={this.getPixelPositionOffset}
        >
          <div tabIndex={0} role="button">
            {vehicleIconMapElement}
            <div
              className="dot_beacon"
              style={{ borderColor: statusColor[vehicleStatus] }}
            />
          </div>
        </OverlayView>
      )
    }

    return vehicleIconMapElement
  }

  render() {
    const {
      historyTimeSeries,
      currentHistoryTime,
      trackingDataForHistoryTime,
    } = this.props

    if (isPlayBackLoading({ historyTimeSeries, currentHistoryTime })) {
      return (
        <div className="history-loading">
          <Loading showText />
        </div>
      )
    }

    return trackingDataForHistoryTime.map((data) => (
      <div key={data.vehicle_id}>{this.renderVehicleIcon(data)}</div>
    ))
  }
}

HistoryViewMarkers.propTypes = {
  historyTimeSeries: PropTypes.shape({
    first: PropTypes.number,
    last: PropTypes.number,
    size: PropTypes.number,
  }),
  currentHistoryTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconSelected: PropTypes.arrayOf(PropTypes.number),
  iconPinned: PropTypes.number,
  setIconPinned: PropTypes.func.isRequired,
  vehicleList: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  trackingDataForHistoryTime: PropTypes.arrayOf(
    PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
      vehicle_id: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      user_id: PropTypes.number,
      vcr_submission_id: PropTypes.number,
    }),
  ).isRequired,
}

HistoryViewMarkers.defaultProps = {
  historyTimeSeries: {
    first: 0,
    last: 0,
    size: 0,
  },
  currentHistoryTime: null,
  iconSelected: null,
  iconPinned: undefined,
}

export default HistoryViewMarkers
