import styled from 'styled-components/macro'

export const FormContainer = styled.div`
  margin-bottom: 15px;
`

export const EmptyContainer = styled.div`
  padding: 40px 0;
  height: 100%;
`

export const OverflowContainer = styled.div`
  overflow-y: auto;
  height: 100%;

  ${(props) =>
    props.notes &&
    `
    height: calc(100% - 55px);
  `}
`

export const LoadingContainer = styled.div`
  height: ${(props) => (props.fullHeight ? '100%' : 'fit-content')};
  width: 100%;
  display: flex;
`
