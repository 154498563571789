import { connect } from 'react-redux'
import FormPage1 from './FormPage1'

import {
  loadEmployees,
  loadSupervisors,
} from '../../../../actions/upsertAuditActions'
import { getYardTimeZone } from '../../../../selectors'

const mapStateToProps = (state) => ({
  yardId: state.general.selectedYard,
  employees: state.upsertAudit.employees,
  supervisors: state.upsertAudit.supervisors,
  timezone: getYardTimeZone(state),
})

const mapDispatchToProps = {
  loadEmployees,
  loadSupervisors,
}

export default connect(mapStateToProps, mapDispatchToProps)(FormPage1)
