import React from 'react'
import PropTypes from 'prop-types'
import AlertMessage from '../../containers/AlertMessage'

const Root = ({ children }) => (
  <>
    {children}
    <AlertMessage />
  </>
)

Root.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

Root.defaultProps = {
  children: null,
}

export default Root
