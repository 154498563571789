import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import { isNil } from 'ramda'
import NewToolTip from '../NewToolTip'
import Loading from '../Loading'
import Blank from '../Blank'

import Row from './Row'

import { searchObjectArray, getScrollEnd } from '../../utils'
import { tableDisplayLimitStart } from '../../config'

import * as S from './styles'

function scrollTop() {
  const tabContentElement = document.querySelector('.table-body')
  if (tabContentElement) {
    tabContentElement.scrollTop = -tabContentElement.scrollHeight
  }
}

const NewTable = ({
  rows,
  headers,
  searchString,
  sortOrder,
  sortedHeader,
  onSortedHeaderChange,
  isLoading,
  className,
  hasCascadeAnimation,
  onScrollEnd,
  initialDisplayLimit,
  onDisplayOrderUpdate,
  enableDragAndDrop,
  blankProps,
}) => {
  const [displayLimit, setDisplayLimit] = useState(
    initialDisplayLimit || tableDisplayLimitStart,
  )
  const [sortableRows, setSortableRows] = useState()
  const [animationOff, setAnimationOff] = useState(false)

  let filteredRows = _.isNil(sortableRows) ? rows : sortableRows

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  useEffect(() => {
    setTimeout(() => {
      setAnimationOff(true)
    }, 1500)
  }, [])

  useEffect(() => {
    if (_.isNil(initialDisplayLimit)) {
      scrollTop()
      setDisplayLimit(tableDisplayLimitStart)
    }
  }, [rows, initialDisplayLimit])

  function resolveScrollEnd() {
    onScrollEnd()
    setDisplayLimit(
      displayLimit + (initialDisplayLimit || tableDisplayLimitStart) * 2,
    )
  }

  function handleScroll(event) {
    return getScrollEnd(event) ? resolveScrollEnd() : null
  }

  function handleHeaderClick(index) {
    scrollTop()
    onSortedHeaderChange(index)
  }

  if (searchString) {
    filteredRows = searchObjectArray({ searchString, tableRows: filteredRows })
  }

  const headerContent = (header, index) => (
    <S.Cell
      key={header.key}
      length={header.length}
      onClick={() => (_.isEmpty(header.text) ? {} : handleHeaderClick(index))}
      className={!header.text && 'pointerless'}
      highlight={sortedHeader === index}
    >
      {header.text}
      {filteredRows &&
        sortedHeader === index &&
        (sortOrder === 'asc' ? (
          <S.OrderIcon>&darr;</S.OrderIcon>
        ) : (
          <S.OrderIcon>&uarr;</S.OrderIcon>
        ))}
    </S.Cell>
  )

  const RowContent = (row) => {
    const rowProps = {
      row,
      rows,
      headers,
      hasCascadeAnimation,
      animationOff,
    }

    return <Row {...rowProps} />
  }

  const renderLoading = () => (
    <S.LoadingWrapper>
      <Loading />
    </S.LoadingWrapper>
  )

  const renderTableBody = () => (
    <>
      {filteredRows.slice(0, displayLimit).map((row, index) =>
        !isNil(row.toolTipDescription) ? (
          <NewToolTip
            key={index}
            title={row.toolTipTitle}
            description={row.toolTipDescription}
            titleIcon={row.toolTipTitleIcon}
            action={row.toolTipAction}
            theme={row.toolTipTheme}
          >
            <div>{RowContent(row)}</div>
          </NewToolTip>
        ) : (
          RowContent(row)
        ),
      )}
    </>
  )

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = filteredRows.map((i) => i.id).indexOf(active.id)
      const newIndex = filteredRows.map((i) => i.id).indexOf(over.id)

      const { name, startDate } = filteredRows[oldIndex]

      const sortable = arrayMove(filteredRows, oldIndex, newIndex)

      const rowsToUpdate = sortable.map((item, index) => ({
        id: item.id,
        display_order: index,
      }))

      setSortableRows(sortable)
      onDisplayOrderUpdate({
        rowsToUpdate,
        name,
        startDate,
      })
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={filteredRows}
        disabled={!enableDragAndDrop}
        strategy={verticalListSortingStrategy}
      >
        <S.Container className={className}>
          {!isLoading && _.isEmpty(filteredRows) ? (
            <Blank {...blankProps} />
          ) : (
            <>
              <S.Header columnsLength={headers.length}>
                {headers.map((header, index) =>
                  header.toolTipTitle || header.toolTipDescription ? (
                    <NewToolTip
                      key={header.key}
                      title={header.toolTipTitle}
                      description={header.toolTipDescription}
                      action={header.toolTipAction}
                    >
                      {headerContent(header, index)}
                    </NewToolTip>
                  ) : (
                    headerContent(header, index)
                  ),
                )}
              </S.Header>
              <S.Body
                className="table-body"
                onScroll={(event) => handleScroll(event)}
              >
                {renderTableBody()}
                {isLoading && renderLoading()}
              </S.Body>
            </>
          )}
        </S.Container>
      </SortableContext>
    </DndContext>
  )
}

NewTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      toolTipTitle: PropTypes.string,
      toolTipDescription: PropTypes.string,
      toolTipAction: PropTypes.string,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      render: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
      ]),
      toolTipTitle: PropTypes.string,
      toolTipDescription: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      length: PropTypes.number,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  searchString: PropTypes.string,
  sortOrder: PropTypes.string,
  sortedHeader: PropTypes.number,
  onSortedHeaderChange: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  hasCascadeAnimation: PropTypes.bool,
  onScrollEnd: PropTypes.func,
  onDisplayOrderUpdate: PropTypes.func,
  initialDisplayLimit: PropTypes.number,
  enableDragAndDrop: PropTypes.bool,
  blankProps: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    action: PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func,
    }),
  }),
}

NewTable.defaultProps = {
  searchString: null,
  sortOrder: 'desc',
  sortedHeader: 0,
  onSortedHeaderChange: () => null,
  hasCascadeAnimation: true,
  onScrollEnd: () => null,
  onDisplayOrderUpdate: () => null,
  initialDisplayLimit: null,
  enableDragAndDrop: false,
  blankProps: {
    type: 'search',
  },
}

export default NewTable
