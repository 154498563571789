import { manageVcrsTypes } from '../../actions/manageVCRsActions'

const initialState = {
  loading: false,
  value: {},
}

const vcrData = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVcrsTypes.VCR_REQUESTED:
      return {
        ...state,
        loading: true,
        value: {},
      }
    case manageVcrsTypes.VCR_LOADED:
      return {
        ...state,
        loading: false,
        value: payload,
      }

    case manageVcrsTypes.VCR_FAILED:
      return initialState
    default:
      return state
  }
}

export default vcrData
