import React from 'react'
import PropTypes from 'prop-types'

const TextInput = (props) => <input className="textInput" {...props} />

TextInput.propTypes = {
  placeholder: PropTypes.string,
}

TextInput.defaultProps = {
  placeholder: '',
}

export default TextInput
