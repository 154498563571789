import PropTypes from 'prop-types'
import moment from 'moment'

export const chartItemType = {
  loading: PropTypes.bool,
  icon: PropTypes.string,
  title: PropTypes.string,
  averageLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      bins: PropTypes.arrayOf(
        PropTypes.shape({
          yValue: PropTypes.number,
        }),
      ),
    }),
  ),
  startTime: PropTypes.instanceOf(moment),
  endTime: PropTypes.instanceOf(moment),
}
