import styled from 'styled-components/macro'
import { sidebarWidth } from '../../config'

export const Container = styled.div`
  display: flex;
  position: relative;
  background: var(--lighter-neutral-99);
`

export const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${sidebarWidth}px);
  overflow: hidden;
  @media (max-width: 1023px) {
    width: 100%;
  }
`
