import { isEmpty } from 'ramda'
import styled, { css } from 'styled-components'

const ellipsis = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
`

export const Container = styled.div`
  width: 100%;
  padding: 10px;
  background: var(--lighter-neutral-99);
  @media (max-width: 1024px) {
    overflow: auto;
    min-width: 100%;
    width: fit-content;
  }

  ${(props) =>
    props.fullHeight &&
    `
    max-height: calc(100% - 50px);
  `}
`

export const Header = styled.div`
  background-color: var(--lighter-neutral-92);
  display: flex;
  justify-content: space-between;
`

export const Cell = styled.div`
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;

  span {
    ${ellipsis}

    > div {
      ${ellipsis}
    }
  }

  :not(:last-of-type) {
    border-right: 2.5px solid var(--base-neutral);
  }

  @media (max-width: 1024px) {
    min-width: 200px;
  }
`

export const Text = styled.span`
  padding: 15px 5px;
  display: block;
  font-family: var(--font-regular);

  ${(props) => props.strong && `font-family: var(--font-bold);`}
`

export const Row = styled.div`
  width: 100%;
  display: flex;

  :not(:last-of-type) {
    border-bottom: 1px solid;
  }

  :nth-child(even) {
    background: var(--lighter-neutral-96);
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: overlay;
  max-height: 250px;
  flex: 1;

  ${(props) =>
    props.fullHeight &&
    `
    max-height: calc(100% - 45px);
    @media (max-width: 1024px) {
      overflow: unset;
      min-width: 100%;
      width: fit-content;
    }
  `}
`

export const LoadingContainer = styled.div`
  width: 100%;
  text-align: center;
  height: 200px;
  display: grid;
  background: var(--lighter-neutral-99);
`

export const Input = styled.input`
  padding: 15px 5px;
  display: block;
  font-family: var(--font-regular);
  width: 100%;
  border: none;
  outline-color: var(--base-blue);
  border-radius: 0;
  text-align: center;

  ${(props) => props.strong && `font-family: var(--font-bold);`}

  background: ${(props) =>
    isEmpty(props.value)
      ? `var(--lighter-red-83-transparent);`
      : `var(--base-blue-transparent);`}

  ::placeholder {
    text-align: center;
    color: var(--lighter-red-83);
  }
`
