import { pipe, map, keys, filter } from 'ramda'
import { roleIdToName } from '../../config'
import getPermissionLevel from '../utils/getPermissionLevel'

export default function getRolesWithLowerPermissionLevel({ loggedUser }) {
  const loggedPermissionLevel = getPermissionLevel(loggedUser.role_id)
  return pipe(
    keys,
    map(Number),
    filter((roleId) => getPermissionLevel(roleId) > loggedPermissionLevel),
  )(roleIdToName)
}
