import { connect } from 'react-redux'
import VCR from './VCR'
import {
  loadVCR,
  resetHeaderReturnOptions,
} from '../../actions/manageVCRsActions'
import { loadVehicles } from '../../actions/manageVehiclesActions'
import { parseIntOrUndefined } from '../../utils'
import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state) => {
  const timezone = getYardTimeZone(state)

  return {
    vcrData: state.manageVCRs.vcrData,
    vehicleList: state.manageVehicles.vehicleList,
    yardId: parseIntOrUndefined(state.general.selectedYard),
    headerReturnOptions: state.manageVCRs.headerReturnOptions,
    timezone,
  }
}

const mapDispatchToProps = {
  loadVCR,
  loadVehicles,
  resetHeaderReturnOptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(VCR)
