import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'

import { ToggleContainer, Blank, Loading } from '../../components'

import { getShortName } from '../../utils'

import * as S from './styles'

const BillingComments = ({
  loadBillingComments,
  timezone,
  selectedYard,
  billingComments,
  billingTable,
  date,
  templateId,
}) => {
  const isLoading = _.get(billingComments, 'isLoading')
  const loadedComments = _.get(billingComments, 'value.comments', [])
  const orderedComments = _.orderBy(
    loadedComments,
    (comment) => comment.updated_at,
    'desc',
  )
  const hasComments = _.size(loadedComments) > 0

  useEffect(() => {
    loadBillingComments({ yard: selectedYard, date, templateId })
  }, [selectedYard, loadBillingComments, date, billingTable, templateId])

  function renderContent() {
    if (isLoading) {
      return (
        <S.EmptyContainer>
          <Loading />
        </S.EmptyContainer>
      )
    }

    if (!hasComments) {
      return (
        <S.EmptyContainer>
          <Blank small subtitle="No comments to show" />
        </S.EmptyContainer>
      )
    }

    return orderedComments.map((comment, index) => {
      const displayName = _.get(comment, 'billing_item_display_name')
      const value = _.defaultTo(_.get(comment, 'value'), '').toLocaleString(
        'en-US',
      )
      const description = _.get(comment, 'message')
      const firstName = _.get(comment, 'user.first_name')
      const lastName = _.get(comment, 'user.last_name')
      const updatedAt = _.get(comment, 'updated_at')
      const createdAt = _.get(comment, 'date')

      return (
        <S.Container key={index}>
          <S.Row>
            <div>
              <S.Text>{displayName}</S.Text>
              <S.Date calendar>
                {moment.tz(createdAt, timezone).format('MM/DD')}
              </S.Date>
            </div>
            <S.Text right>{value}</S.Text>
          </S.Row>

          <S.Comment>
            <span>{description}</span>
          </S.Comment>

          <S.Row>
            <S.Date>
              {`Last updated by ${getShortName({
                firstName,
                lastName,
              })} on ${moment
                .tz(updatedAt, timezone)
                .format('MMM Do [at] HH:mm')}`}
            </S.Date>
          </S.Row>
        </S.Container>
      )
    })
  }

  return <ToggleContainer title="Comments">{renderContent()}</ToggleContainer>
}

BillingComments.propTypes = {
  loadBillingComments: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  selectedYard: PropTypes.number.isRequired,
  billingComments: PropTypes.shape({
    value: PropTypes.shape({
      comments: PropTypes.array,
    }),
    isLoading: PropTypes.bool,
  }).isRequired,
  date: PropTypes.string.isRequired,
  billingTable: PropTypes.shape({
    value: PropTypes.shape({
      end_date: PropTypes.string,
      last_updated: PropTypes.shape({
        updated_at: PropTypes.string,
        user: PropTypes.shape({
          first_name: PropTypes.string,
          id: PropTypes.number,
          last_name: PropTypes.string,
        }),
      }),
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          billing_cycle: PropTypes.string,
          code: PropTypes.string,
          columns: PropTypes.arrayOf(
            PropTypes.shape({
              billing_template_item_id: PropTypes.number,
              comment: PropTypes.any,
              created_at: PropTypes.string,
              date: PropTypes.string,
              deleted_at: PropTypes.any,
              id: PropTypes.number,
              invoice_id: PropTypes.number,
              updated_at: PropTypes.string,
              user_id: PropTypes.any,
              value: PropTypes.any,
            }),
          ),
          customer_number: PropTypes.string,
          customer_yard_id: PropTypes.any,
          default_value: PropTypes.any,
          description: PropTypes.string,
          display_name: PropTypes.string,
          display_order: PropTypes.number,
          filling_type: PropTypes.string,
          formula_function: PropTypes.any,
          formula_options: PropTypes.any,
          group: PropTypes.string,
          id: PropTypes.number,
          is_comment_required: PropTypes.bool,
          is_daily: PropTypes.bool,
          po: PropTypes.any,
          price_uom: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
      start_date: PropTypes.string,
      week_dates: PropTypes.arrayOf(PropTypes.string),
    }),
    isLoading: PropTypes.bool,
    billing_items: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        display_name: PropTypes.string,
        display_order: PropTypes.number,
        group: PropTypes.string,
        is_comment_required: PropTypes.bool,
        is_daily: PropTypes.bool,
        price_uom: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }).isRequired,
  templateId: PropTypes.string,
}

BillingComments.defaultProps = {
  templateId: null,
}

export default BillingComments
