import Promise from 'bluebird'
import clientApi from './utils/clientApi'
import parseKeysToSnakeCase from '../parsers/parseKeysToSnakeCase'

const getAll = ({ yardId }) =>
  clientApi({
    method: 'get',
    url: `/vehicles/yards/${yardId}`,
  }).then(({ data }) =>
    data.vehicles.map((vehicle) => ({
      ...vehicle,
      vehicle_type_name: vehicle.VehicleType.name,
    })),
  )

const getOne = (vehicleId) =>
  clientApi({
    method: 'get',
    url: `/vehicles/show/${vehicleId}`,
  }).then(({ data }) => data)

const changeStatus = ({ vehicleId, newStatus }) =>
  clientApi({
    method: 'put',
    url: `/vehicles/${vehicleId}/status`,
    data: {
      status: newStatus,
    },
  }).then((res) => res)

const createComment = (vehicleId, comment) =>
  clientApi({
    method: 'post',
    url: `/vehicles/${vehicleId}/comments`,
    data: {
      comment,
    },
  }).then((res) => res)

const deleteVehicle = ({ vehicleId }) => {
  const getRequestOptions = () => ({
    method: 'delete',
    url: `/vehicles/${vehicleId}`,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const save = ({ vehicleId, formData }) => {
  const getRequestOptions = () => {
    const body = { ...formData }
    const data = parseKeysToSnakeCase(body)

    return {
      method: 'put',
      url: `/vehicles/${vehicleId}`,
      data,
    }
  }

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const upsertVehicle = ({ formData }) => {
  const getRequestOptions = () => {
    const body = { ...formData }
    const data = parseKeysToSnakeCase(body)

    return {
      method: 'post',
      url: `/vehicles`,
      data,
    }
  }

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const vehicles = {
  getAll,
  getOne,
  changeStatus,
  createComment,
  save,
  upsertVehicle,
  deleteVehicle,
}

export default vehicles
