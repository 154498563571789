import React from 'react'
import PropTypes from 'prop-types'
import CardItem from '../CardItem'
import { cardItemType } from '../CardItem/propTypes'

const CardGroup = ({ list }) => (
  <div className="cardGroup">
    {list.map((item) => (
      <CardItem key={item.title} {...item} />
    ))}
  </div>
)

CardGroup.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(cardItemType)).isRequired,
}

export default CardGroup
