import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { ComposedChart, Bar, Tooltip, Legend, ReferenceLine } from 'recharts'

import calendarIcon from '../../../images/icons/calendar-blue.svg'
import appPropTypes from '../../../appPropTypes'

import { SIDEBAR_WIDTH, SUB_HEIGHT } from '../../../config'
import { Loading, EnhancedBarChart } from '../../../components'

class MetricsChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chartWidth: window.innerWidth - SIDEBAR_WIDTH,
      chartHeight:
        window.innerWidth <= 1240 || window.innerHeight <= 800
          ? window.innerHeight - 500
          : window.innerHeight - SUB_HEIGHT,
    }
  }

  componentDidMount() {
    this.loadSummaryData()
  }

  componentDidUpdate(prevProps) {
    const { selectedYard, selectedDate } = this.props
    if (
      !prevProps.selectedDate.isSame(selectedDate) ||
      prevProps.selectedYard !== selectedYard
    ) {
      this.loadSummaryData()
    }
  }

  loadSummaryData = () => {
    const {
      timezone,
      selectedYard,
      selectedDate,
      loadYardShiftsSummary,
      loadActiveTimeSummary,
    } = this.props

    const endTime = moment.tz(selectedDate, timezone).endOf('day').valueOf()
    loadActiveTimeSummary(selectedYard, endTime, timezone)
    loadYardShiftsSummary(selectedYard, endTime, timezone)
  }

  toggleBarOpacity = (bar) => {
    const allSvgPaths = document.querySelectorAll('path')
    const hoveredCells = Array.from(allSvgPaths).filter(
      (path) => parseFloat(path.getAttribute('x')) === bar.x,
    )
    hoveredCells.forEach((elm) => elm.classList.toggle('opaque-bar'))
  }

  formatMonthData = ({
    yardShiftsSummary,
    activeTimeSummary,
    liftsCountSummary,
  }) =>
    yardShiftsSummary.value.map(({ time: hoursPaid }, index) => ({
      hoursPaid: Math.floor(hoursPaid || 0),
      activeTime: Math.floor(
        activeTimeSummary.value[index] ? activeTimeSummary.value[index].y : 0,
      ),
      activeRatio:
        hoursPaid && activeTimeSummary.value[index]
          ? Number((activeTimeSummary.value[index].y / hoursPaid).toFixed(2))
          : 0,
      lpmh:
        liftsCountSummary.value[index] > 0 && hoursPaid > 0
          ? Number((liftsCountSummary.value[index] / hoursPaid).toFixed(2))
          : 0,
    }))

  changeSelectedDate = (bar, index) => {
    const { setYardSummarySelectedDate, selectedDate } = this.props
    const newDateToSelect = moment(selectedDate).subtract(29 - index, 'days')
    setYardSummarySelectedDate(newDateToSelect)
  }

  renderCustomToolTip = ({ active, payload, label }) => {
    const { selectedDate } = this.props
    if (active) {
      const payloadValue = payload[0].payload
      return (
        <div className="custom-tooltip">
          <div className="activeDate">
            <img src={calendarIcon} alt="Calendar Icon" />
            <span>
              {`${moment(selectedDate)
                .subtract(29 - label, 'd')
                .format('MMMM Do')}`}
            </span>
          </div>
          <hr />
          <div className="row">
            <div className="extraMetrics">
              <p className="hp">HP</p>
              <span className="extraMetric hp-m">{payloadValue.hoursPaid}</span>
              <p className="at">AT</p>
              <span className="extraMetric at-m">
                {payloadValue.activeTime}
              </span>
              <p className="ar">AR</p>
              <span className="extraMetric ar-m">
                {payloadValue.activeRatio}
              </span>
              <p className="lpmh">LPMH </p>
              <span className="extraMetric lpmh-m"> {payloadValue.lpmh}</span>
            </div>
          </div>
        </div>
      )
    }

    return null
  }

  renderChart = () => {
    const {
      yardShiftsSummary,
      activeTimeSummary,
      liftsCountSummary,
    } = this.props
    const { chartHeight, chartWidth } = this.state
    const loading =
      yardShiftsSummary.loading ||
      activeTimeSummary.loading ||
      liftsCountSummary.loading
    if (loading) {
      return <Loading />
    }

    const legendStyle = {
      width:
        window.innerWidth <= 1240 || window.innerHeight <= 800
          ? '220px'
          : '250px',
      height:
        window.innerWidth <= 1240 || window.innerHeight <= 800
          ? '40px'
          : '46px',
      top: '10px',
    }

    const formattedMonthData = this.formatMonthData({
      yardShiftsSummary,
      activeTimeSummary,
      liftsCountSummary,
    })

    const liftsAverage = 3

    return (
      <EnhancedBarChart highlight>
        <ComposedChart
          width={chartWidth}
          height={chartHeight}
          data={formattedMonthData}
          margin={{
            top: 30,
            right: 10,
            left: 10,
            bottom: 30,
          }}
        >
          <Tooltip
            dataKey="name"
            offset={20}
            payload={formattedMonthData}
            content={this.renderCustomToolTip}
          />
          <Legend
            verticalAlign="top"
            height={36}
            align="right"
            wrapperStyle={legendStyle}
          />
          <Bar
            dataKey="lpmh"
            name="LPMH"
            stackId="a"
            fill="var(--darker-green-33)"
            background={{ fill: '#F8FAFD' }}
            onMouseEnter={this.toggleBarOpacity}
            onMouseLeave={this.toggleBarOpacity}
            onClick={this.changeSelectedDate}
          />
          <ReferenceLine
            y={liftsAverage}
            label={{
              value: `PAR ${liftsAverage}`,
              position: 'insideBottomLeft',
              fontSize: 28,
            }}
            strokeWidth={2}
            stroke="var(--darker-neutral-45)"
            strokeDasharray="3 3"
          />
        </ComposedChart>
      </EnhancedBarChart>
    )
  }

  render() {
    const { selectedDate } = this.props

    return (
      <div className="summary__chart">
        <p className="chart__label">LPMH</p>
        {this.renderChart()}
        <div className="chartAxis__label flex-row">
          <div className="flex-row">
            Start
            <div className="label__date">
              {moment(selectedDate).subtract(29, 'd').format('MMM. DD. YY')}
            </div>
          </div>
          <div className="flex-row">
            Finish
            <div className="label__date">
              {moment(selectedDate).format('MMM. DD. YY')}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

MetricsChart.propTypes = {
  timezone: PropTypes.string.isRequired,
  yardShiftsSummary: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  activeTimeSummary: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  selectedDate: PropTypes.instanceOf(moment).isRequired,
  loadYardShiftsSummary: PropTypes.func.isRequired,
  loadActiveTimeSummary: PropTypes.func.isRequired,
  selectedYard: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  setYardSummarySelectedDate: PropTypes.func.isRequired,
  liftsCountSummary: appPropTypes.asyncOf(PropTypes.arrayOf(PropTypes.number))
    .isRequired,
}

export default MetricsChart
