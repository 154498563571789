import React, { Component } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import _ from 'lodash'
import flagType from './propTypes'

import { NewMetricCardGroup, NewMetricsTable } from '../../components'
import MetricsChart from './MetricsChart'
import { history } from '../../utils'
import { getTimespan, beginningOfDay } from '../../utils/momentExtensions'

import iconLpmh from '../../images/icons/icon-lpmh.svg'
import iconMpmh from '../../images/icons/icon-mpmh.svg'
import iconTotalLifts from '../../images/icons/icon-total-lifts.svg'
import iconTotalMoves from '../../images/icons/icon-total-moves.svg'
import iconPeopleWorked from '../../images/icons/icon-people-worked.svg'
import iconHoursPaid from '../../images/icons/icon-hours-paid.svg'

import './YardSummary.css'

const metricsMetadata = [
  {
    label: 'lpmh',
    icon: iconLpmh,
    title: 'LPMH',
    description: 'Lifts per man hour using a crane',
    isDecimal: true,
  },
  {
    label: 'totalLifts',
    icon: iconTotalLifts,
    title: 'TOTAL LIFTS',
    description: 'Amount of lifts completed by all employees',
  },
  {
    label: 'mpmh',
    icon: iconMpmh,
    title: 'MPMH',
    description: 'Moves per man hour using a Hostler',
    isDecimal: true,
  },
  {
    label: 'totalMoves',
    icon: iconTotalMoves,
    title: 'TOTAL MOVES',
    description: 'Amount of moves completed by all employees',
  },
  {
    label: 'peopleWorked',
    icon: iconPeopleWorked,
    title: 'PEOPLE',
    description: 'Employees that worked',
  },
  {
    label: 'hoursPaid',
    icon: iconHoursPaid,
    title: 'HOURS PAID',
    description: 'Pay for the total hours that employees worked',
  },
]

class YardSummary extends Component {
  componentDidMount() {
    const {
      selectedYard,
      setYardSummarySelectedDate,
      date,
      timezone,
    } = this.props

    const parsedUrlDate = date
      ? moment.tz(date, timezone).startOf('day')
      : moment.tz(timezone).subtract(1, 'days').startOf('day')

    setYardSummarySelectedDate(parsedUrlDate)

    if (selectedYard !== '-') {
      this.loadDailyData()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectedYard,
      selectedDate,
      timezone,
      setYardSummarySelectedDate,
      date,
    } = this.props

    if (
      selectedYard !== prevProps.selectedYard ||
      !selectedDate.isSame(prevProps.selectedDate)
    ) {
      const startOfDay = beginningOfDay(selectedDate, timezone)
      history.changePathVariable({
        name: 'date',
        value: startOfDay.format('YYYY-MM-DD'),
      })
      this.loadDailyData()
    }

    if (!selectedDate.isSame(date)) {
      const startOfDay = beginningOfDay(date, timezone)
      setYardSummarySelectedDate(startOfDay)
    }
  }

  handleParsedRows = (rows) =>
    rows.map((row, index) => ({
      label: (
        <div style={{ display: 'flex', alignContent: 'center' }}>
          <img
            className="metric-label"
            src={metricsMetadata[index].icon}
            alt="Metric Label"
          />
          {metricsMetadata[index].title}
        </div>
      ),
      ...row,
    }))

  loadDailyData() {
    const {
      selectedYard,
      timezone,
      selectedDate,
      loadFlagsByYard,
      loadMetrics,
      loadMovesByDay,
    } = this.props

    const { startTime, endTime } = getTimespan({
      selectedDate,
      timezone,
      numberOfDays: 1,
    })
    const yardId = Number(selectedYard)

    const currentDate = startTime.format('YYYY-MM-DD')

    loadMetrics({ yardId: selectedYard, step: 3, date: startTime })
    loadFlagsByYard({ yardId, startTime, endTime })
    loadMovesByDay({ yardId, date: currentDate })
  }

  parseCardList() {
    const { liftsCount, movesCount, yardShifts, yardMetrics } = this.props

    const rows = _.get(yardMetrics, 'value.rows', [])

    const { peopleWorked, total: hoursPaid } = yardShifts

    const calculatedMetrics = {
      lpmh: hoursPaid === 0 ? 0 : (liftsCount.value / hoursPaid).toFixed(2),
      mpmh: hoursPaid === 0 ? 0 : (movesCount.value / hoursPaid).toFixed(2),
      totalLifts: liftsCount.value,
      totalMoves: movesCount.value,
      hoursPaid,
      peopleWorked,
    }

    return metricsMetadata.map((metric) => ({
      value: rows.length ? calculatedMetrics[metric.label] : 0,
      ...metric,
    }))
  }

  render() {
    const { yardMetrics } = this.props

    const columns = _.get(yardMetrics, 'value.columns', [])
    const rows = _.get(yardMetrics, 'value.rows', [])

    const parsedRows = this.handleParsedRows(rows)
    const parsedCardList = this.parseCardList()

    const tableTooltip = {
      title: 'Table',
      description: `Table showing ${parsedRows.length} metrics at 3 hour intervals over a 24 hour period`,
    }

    return (
      <>
        <NewMetricCardGroup list={parsedCardList} />
        <NewMetricsTable
          columns={columns}
          rows={parsedRows}
          isLoading={yardMetrics.isLoading}
          tooltip={tableTooltip}
        />
        <MetricsChart />
      </>
    )
  }
}

YardSummary.propTypes = {
  selectedYard: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  selectedDate: PropTypes.instanceOf(moment).isRequired,
  setYardSummarySelectedDate: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  loadFlagsByYard: PropTypes.func.isRequired,
  yardFlags: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(flagType).isRequired,
  }).isRequired,
  date: PropTypes.string.isRequired,
  loadMetrics: PropTypes.func.isRequired,
  yardMetrics: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  liftsCount: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  movesCount: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  yardShifts: PropTypes.shape({
    peopleWorked: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  loadMovesByDay: PropTypes.func.isRequired,
}

export default (YardSummary, 'stats')
