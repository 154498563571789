import _ from 'lodash'
import moment from 'moment-timezone'

import getEditablePeriod from './getEditablePeriod'

import { engineHoursEditablePeriod } from '../../../config'

import calendarIcon from '../../../images/icons/icon_calendar_white.svg'

function getTimestamp({ engineHours, timezone }) {
  const parsedTimestamp = _.chain(engineHours)
    .get('value.last_updated')
    .defaultTo({})
    .value()
  const { startDate, endDate } = getEditablePeriod({
    date: moment.tz(timezone),
    timezone,
  })

  const showCurrentMonth = moment
    .tz(timezone)
    .isSameOrBefore(
      moment()
        .tz(timezone)
        .startOf('month')
        .add(engineHoursEditablePeriod.end, 'days'),
    )

  const monthsToSubtract = showCurrentMonth ? 1 : 0
  const dateFormat = 'MMMM Do'

  const periodStart = moment
    .tz(startDate, timezone)
    .subtract(monthsToSubtract, 'month')
    .format(dateFormat)

  const periodEnd = moment
    .tz(endDate, timezone)
    .subtract(monthsToSubtract, 'month')
    .format(dateFormat)

  return {
    ...parsedTimestamp,
    timezone,
    advice: {
      icon: calendarIcon,
      text: `EOM Engine Hours opens on ${periodStart} and close on ${periodEnd}`,
    },
  }
}

export default getTimestamp
