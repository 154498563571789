import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { FormItem } from '../../../../components'
import appPropTypes from '../../../../appPropTypes'
import parseAuditEnumTypeListToOption from '../../../../parsers/parseAuditEnumTypeListToOption'
import { pickOrUndefined } from '../../../../utils'

const coneTestOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
  { value: null, label: 'No test' },
]

class FormPage2 extends Component {
  componentDidMount() {
    const {
      loadAuditFuntionTypes,
      savedValues,
      loadAuditViolationTypes,
    } = this.props

    loadAuditFuntionTypes()

    const auditFunctionTypeId = savedValues.auditFunction
    if (auditFunctionTypeId) {
      loadAuditViolationTypes({ auditFunctionTypeId })
    }
  }

  handleAuditFunctionChange = (auditFunctionTypeId, setFieldValue) => {
    const { loadAuditViolationTypes } = this.props
    setFieldValue('violations', [])
    loadAuditViolationTypes({ auditFunctionTypeId })
  }

  render() {
    const {
      savedValues,
      renderButtons,
      onSubmit,
      auditFunctionTypes,
      auditViolationTypes,
    } = this.props

    const isAuditFunctionTypesLoading = auditFunctionTypes.loading
    const isAuditViolationTypesLoading = auditViolationTypes.loading

    return (
      <Formik
        initialValues={pickOrUndefined(savedValues, [
          'auditFunction',
          'violations',
          'coneTest',
        ])}
        validate={(values) => {
          const errors = {}
          if (!values.auditFunction) {
            errors.auditFunction = 'Required'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values)
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <Field
              type="select"
              name="auditFunction"
              component={FormItem}
              label="Audit function"
              placeholder="Select an audit function"
              options={parseAuditEnumTypeListToOption(auditFunctionTypes.value)}
              isDisabled={isAuditFunctionTypesLoading}
              isLoading={isAuditFunctionTypesLoading}
              onChange={(functionId) =>
                this.handleAuditFunctionChange(functionId, setFieldValue)
              }
            />
            <Field
              type="select"
              name="violations"
              component={FormItem}
              label="Violations found"
              placeholder="Select violations"
              options={parseAuditEnumTypeListToOption(
                auditViolationTypes.value,
              )}
              isLoading={isAuditViolationTypesLoading}
              isDisabled={
                isAuditViolationTypesLoading ||
                auditViolationTypes.value.length === 0
              }
              isMulti
            />
            <Field
              type="select"
              name="coneTest"
              component={FormItem}
              label="Cone test passed"
              placeholder="Select an option"
              options={coneTestOptions}
            />
            {renderButtons({ isSubmitting })}
          </Form>
        )}
      </Formik>
    )
  }
}

FormPage2.propTypes = {
  savedValues: PropTypes.object.isRequired,
  renderButtons: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loadAuditFuntionTypes: PropTypes.func.isRequired,
  loadAuditViolationTypes: PropTypes.func.isRequired,
  auditFunctionTypes: appPropTypes.asyncAuditEnumList.isRequired,
  auditViolationTypes: appPropTypes.asyncAuditEnumList.isRequired,
}

export default FormPage2
