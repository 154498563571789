import styled from 'styled-components/macro'
import iconArrowLeft from '../../images/icons/icon_arrow_left_light.svg'
import iconSearch from '../../images/icons/icon_search_light.svg'

import { sidebarWidth } from '../../config'

export const Container = styled.header`
  display: block;
  width: calc(100vw - ${sidebarWidth}px);
  padding: 12px 15px;
  background: var(--darker-blue-17);
  @media (max-width: 1023px) {
    width: 100%;
  }
`

export const Content = styled.div`
  display: flex;
  min-height: 56px;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  @media (max-width: 1023px) {
    flex-wrap: wrap;
    gap: 15px;
    height: 100%;
    min-height: unset;
  }
`

export const Item = styled.div`
  ${(props) =>
    props.explanation &&
    `
    color: var(--base-neutral);
    width: 70%;
    min-width: 70%;
    font-size: 14px;
  `}
`

export const Title = styled.h1`
  font-family: 'ArticulatCF-Bold';
  font-size: 30px;
  color: var(--lighter-neutral-99);
  margin: 0;
`

export const Return = styled.button`
  font-family: 'ArticulatCF-Medium';
  background: transparent;
  color: var(--lighter-neutral-92);
  border: none;
  font-size: 15px;
  padding-left: 18px;
  background: url(${iconArrowLeft}) no-repeat;
  background-position: left;
`

export const TitleAction = styled.button`
  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-color: transparent;
  height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 50%;
  border: none;
  margin-left: 0;
  background-size: cover;
  display: block;
  transition: 0.1s all;

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}
`

export const InnerContainer = styled.div`
  flex: unset !important;
  &:empty {
    display: none;
  }
  @media (max-width: 1023px) {
    padding-right: 65px;
  }
  ${(props) =>
    props.flex &&
    `
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        flex-wrap: wrap;
    }
  `}

  ${(props) =>
    props.right &&
    `
    gap: 20px;
    @media (max-width: 1024px) {
      overflow: auto;
    }
  `}

  ${(props) => props.main && `flex: 1`}
  ${(props) =>
    props.fullWidth &&
    `
    width: 100%;
    height: auto;
    gap: 10px 15px;
    padding-right: 10px !important;
    @media (min-width: 1024px) {
      flex-wrap: wrap;
    }
  }
  `}
`

export const Search = styled.input`
  font-size: 16px;
  background-color: var(--darker-blue-29);
  box-shadow: var(--box-shadow);
  background-image: url(${iconSearch});
  background-repeat: no-repeat;
  background-position: center right 10px;
  padding: 0 30px 0 15px;
  height: 48px;
  border: none;
  width: 170px;
  color: var(--lighter-neutral-99);

  ::placeholder {
    color: var(--base-neutral);
  }
`
