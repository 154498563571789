import React from 'react'
import PropTypes from 'prop-types'

import { isNil } from 'ramda'
import * as S from './styles'
import {
  HEADERS,
  VIEW_MODE,
  NO_TITLE_ACTION,
  INNER_TABS_OPTIONS as items,
} from './constants'

import {
  NewHeader,
  NewContainer,
  NewTabs,
  Loading,
  EditBillingTable,
} from '../../components'

import { useGetBillingItemsQuery } from '../../services/billing'
import { history } from '../../utils'

import addIcon from '../../images/icons/icon_add_dark.svg'
import editIcon from '../../images/icons/icon_edit.svg'

const BillingElements = ({
  returnDate,
  UrlSubMenu,
  selectedYard,
  timezone,
}) => {
  const { data = {}, isLoading } = useGetBillingItemsQuery()
  const { grouped_billing_items: GroupedBillingItems = {} } = data
  const {
    service,
    metric,
    charge,
    productivity_saving: prodSaving,
  } = GroupedBillingItems

  const baseUrl = `/yard/${selectedYard}/billing/items`
  const returnDateUrl = `return_date=${returnDate}`
  const returnUrl = `/yard/${selectedYard}/billing/templates?${returnDateUrl}`
  const activeItem = isNil(UrlSubMenu) ? 'service' : UrlSubMenu

  const createBillingElementUrl = `/create/${activeItem}`

  const titleAction = [
    {
      key: 0,
      icon: addIcon,
      tooltip: {
        title: `Add new ${activeItem} item`,
        action: `Click to create a new item on ${activeItem}`,
      },
      onClick: () =>
        history.push(`${baseUrl}${createBillingElementUrl}?${returnDateUrl}`),
    },
  ]

  const headerProps = {
    title: 'General Billing Elements',
    returnText: 'Back to Billing Template Versions',
    returnUrl,
    titleAction: NO_TITLE_ACTION.includes(UrlSubMenu) ? [] : titleAction,
  }

  const innerTabsProps = {
    items,
    activeItem,
    onChange: (value) => history.push(`${baseUrl}/${value}?${returnDateUrl}`),
  }

  const sharedValues = {
    timezone,
    isLoading,
  }

  function handleEditElement(elementId) {
    history.push(`${baseUrl}/edit/${activeItem}/${elementId}?${returnDateUrl}`)
  }

  function addActionColumn(rows) {
    if (!rows) {
      return []
    }

    return rows.map((row) => {
      const newRow = { ...row }
      newRow.actions = (
        <S.Action onClick={() => handleEditElement(row.id)}>
          <img alt="edit icon" src={editIcon} />
        </S.Action>
      )

      return newRow
    })
  }

  const serviceValues = {
    headers: HEADERS.SERVICE,
    rows: addActionColumn(service),
    ...sharedValues,
  }

  const metricValues = {
    headers: HEADERS.METRIC,
    rows: addActionColumn(metric),
    ...sharedValues,
  }

  const chargeValues = {
    headers: HEADERS.CHARGE,
    rows: addActionColumn(charge),
    ...sharedValues,
  }

  const prodSavingsValues = {
    headers: HEADERS.PROD_SAVING,
    rows: prodSaving,
    ...sharedValues,
  }

  function renderEditBillingTable(values) {
    return <EditBillingTable {...values} fullHeight />
  }

  function sortAlphabetical(list) {
    const parsedList = list

    parsedList.rows = [...parsedList.rows].sort((a, b) =>
      a.default_display_name
        .toLowerCase()
        .localeCompare(b.default_display_name.toLowerCase()),
    )

    return parsedList
  }

  function renderTable() {
    switch (UrlSubMenu) {
      case VIEW_MODE.SERVICE:
        return renderEditBillingTable(sortAlphabetical(serviceValues))
      case VIEW_MODE.METRIC:
        return renderEditBillingTable(sortAlphabetical(metricValues))
      case VIEW_MODE.CHARGE:
        return renderEditBillingTable(sortAlphabetical(chargeValues))
      case VIEW_MODE.PROD_SAVING:
        return renderEditBillingTable(sortAlphabetical(prodSavingsValues))

      default:
        return null
    }
  }

  function renderContent() {
    if (isLoading) {
      return (
        <S.LoadingContainer>
          <Loading />
        </S.LoadingContainer>
      )
    }

    return (
      <>
        <NewTabs {...innerTabsProps} />
        {renderTable()}
      </>
    )
  }

  return (
    <>
      <NewHeader {...headerProps} />
      <NewContainer>{renderContent()}</NewContainer>
    </>
  )
}

BillingElements.propTypes = {
  returnDate: PropTypes.string.isRequired,
  selectedYard: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  UrlSubMenu: PropTypes.string,
}

BillingElements.defaultProps = {
  UrlSubMenu: 'service',
}

export default BillingElements
