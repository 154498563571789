import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Formik, Form } from 'formik'
import Promise from 'bluebird'

import * as S from './styles'

import PasswordStrength from '../PasswordStrength'

const initialValues = {
  password: '',
  passwordConfirm: '',
}

const ChangePassword = ({
  selectedUser,
  resetUserPassword,
  handleCloseModal,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={({ password }, { setSubmitting }) => {
      setSubmitting(true)

      Promise.resolve(resetUserPassword(_.get(selectedUser, 'id'), password))
        .then(handleCloseModal())
        .finally(() => {
          setSubmitting(false)
        })
    }}
  >
    {({ isSubmitting, values: { password } }) => (
      <Form>
        <br />
        <PasswordStrength
          password={password}
          username={_.get(selectedUser, 'username')}
          firstName={_.get(selectedUser, 'first_name')}
          lastName={_.get(selectedUser, 'last_name')}
          titleType="label"
          validationType={
            _.get(selectedUser, 'Role.name') === 'DRIVER'
              ? 'numeric'
              : 'strength'
          }
        />
        <S.ButtonWrapper>
          <S.StyledButton
            className="cancelBtn"
            text="Cancel"
            onClick={handleCloseModal}
          />
          <S.StyledButton
            className="confirmBtn"
            type="submit"
            text="Save"
            isDisabled={isSubmitting}
          />
        </S.ButtonWrapper>
      </Form>
    )}
  </Formik>
)

ChangePassword.propTypes = {
  resetUserPassword: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    Role: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
}

export default ChangePassword
