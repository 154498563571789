import { groupBy, head, map, pipe, prop, unnest, values } from 'ramda'
import { toolkitApi } from '.'

function addFront(response) {
  const front = {
    billingItemById: pipe(
      values,
      unnest,
      groupBy(prop('id')),
      map(head),
    )(response.meta.billing_items_by_type),
  }

  return {
    ...response,
    front,
  }
}

const billingApi = toolkitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBillingTemplates: builder.query({
      query: ({ yardId }) => ({
        url: 'billing_templates',
        params: {
          yard_id: yardId,
          show_deleted: true,
        },
      }),
    }),
    getTemplateDetails: builder.query({
      query: ({ templateId }) => ({
        url: `billing_templates/${templateId}`,
      }),
      transformResponse: addFront,
    }),
    updateTemplateDetails: builder.mutation({
      query: ({ body, templateId }) => ({
        url: `billing_templates/${templateId}`,
        method: 'PUT',
        body,
      }),
    }),
    getBillingItems: builder.query({
      query: () => ({
        url: 'billing_items',
      }),
    }),
    updateBillingTemplateDisplayOrder: builder.mutation({
      query: (body) => ({
        url: 'billing_templates',
        method: 'POST',
        body,
      }),
    }),
    getTemplateDates: builder.query({
      query: ({ templateId }) => ({
        url: `billing_templates/${templateId}/valid_start_dates`,
      }),
      transformResponse: (response) => response.valid_start_dates,
    }),
    getAffectedInvoices: builder.query({
      query: ({ templateId, startDate }) => ({
        url: `billing_templates/${templateId}/affected_invoices`,
        params: {
          start_date: startDate,
        },
      }),
      transformResponse: (response) => response.affeced_invoices,
    }),
    addBillingElement: builder.mutation({
      query: ({ body }) => ({
        url: `billing_items`,
        method: 'POST',
        body,
      }),
    }),
    getBillingElement: builder.query({
      query: ({ billingElementId }) => ({
        url: `billing_items/${billingElementId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.billing_item,
    }),
    updateBillingElement: builder.mutation({
      query: ({ body, billingElementId }) => ({
        url: `billing_items/${billingElementId}`,
        method: 'PUT',
        body,
      }),
    }),
    getEmptyBillingTemplate: builder.query({
      query: () => ({
        url: `billing_templates/empty`,
        method: 'GET',
      }),
      transformResponse: addFront,
    }),
    getValidDatesForEmptyTemplate: builder.mutation({
      query: ({ body }) => ({
        url: `billing_templates/get_valid_start_dates`,
        method: 'POST',
        body,
      }),
      transformResponse: (response) => response.valid_start_dates,
    }),
  }),
})

export const {
  useGetBillingTemplatesQuery,
  useGetTemplateDetailsQuery,
  useGetBillingItemsQuery,
  useUpdateBillingTemplateDisplayOrderMutation,
  useGetTemplateDatesQuery,
  useGetAffectedInvoicesQuery,
  useUpdateTemplateDetailsMutation,
  useAddBillingElementMutation,
  useGetBillingElementQuery,
  useUpdateBillingElementMutation,
  useGetEmptyBillingTemplateQuery,
  useGetValidDatesForEmptyTemplateMutation,
} = billingApi
