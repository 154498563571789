import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import Autosuggest from 'react-autosuggest'
import _ from 'lodash'
import {
  Button,
  SelectButtonGroup,
  DatePicker,
  NewContainer,
  NewHeader,
} from '../../components'
import { getTimespan, endOfDay } from '../../utils/momentExtensions'
import { firstLetterToUpperCase, history } from '../../utils'
import searchIcon from '../../images/icons/search_icon_blue.svg'

const dateFilters = [
  { label: 'Past week', value: 'WEEK' },
  { label: 'Past month', value: 'MONTH' },
  { label: 'Past year', value: 'YEAR' },
]

const workerFilters = [
  { label: 'Auditor', value: 'AUDITOR' },
  { label: 'Supervisor', value: 'SUPERVISOR' },
  { label: 'Employee', value: 'EMPLOYEE' },
]

const dateOptions = {
  WEEK: 7,
  MONTH: 30,
  YEAR: 365,
}

const getSuggestionValue = (suggestion) => suggestion.name

class SearchAudit extends Component {
  static propTypes = {
    timezone: PropTypes.string.isRequired,
    loadWorkerList: PropTypes.func.isRequired,
    yardId: PropTypes.number.isRequired,
    workerList: PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.object),
      loading: PropTypes.bool,
    }).isRequired,
    createdAtStart: PropTypes.number.isRequired,
    createdAtEnd: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      workerNameValue: '',
      workerType: 'AUDITOR',
      selectedUserId: null,
      userSuggestions: [],
      datePeriod: dateFilters[0].value,
      startDate: null,
      endDate: null,
    }

    this.loadWorkerList()
  }

  componentDidMount() {
    const { datePeriod } = this.state
    const { createdAtStart, createdAtEnd, timezone } = this.props

    if (!_.isNil(createdAtStart) && !_.isNil(createdAtEnd)) {
      this.setState({
        startDate: moment.tz(createdAtStart, timezone),
        endDate: moment.tz(createdAtEnd, timezone),
      })
    } else {
      this.handleDateFilterChange(datePeriod)
    }
  }

  getUserSuggestions(value) {
    const {
      workerList: { value: workerListValue },
    } = this.props

    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    const re = new RegExp(inputValue)

    return inputLength === 0
      ? []
      : workerListValue.filter(
          (worker) => worker.name.toLowerCase().search(re) !== -1,
        )
  }

  renderUserSuggestion = (suggestion) => <div>{suggestion.name}</div>

  onChange = (event, { newValue }) => {
    this.setState({
      workerNameValue: newValue,
    })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      userSuggestions: this.getUserSuggestions(value),
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      userSuggestions: [],
    })
  }

  onSuggestionSelected = (event, { suggestion: { id } }) => {
    this.setState({
      selectedUserId: id,
    })
  }

  handleDateFilterChange = (value) => {
    const { timezone } = this.props

    const { startTime, endTime } = getTimespan({
      selectedDate: moment(),
      timezone,
      numberOfDays: dateOptions[value],
      includeToday: true,
    })

    this.setState({ startDate: startTime, endDate: endTime })
  }

  handleDateChange = (key) => (date) => {
    const { timezone } = this.props

    if (!_.isNil(date)) {
      this.setState({
        [key]: moment(date).tz(timezone),
      })
    }
  }

  handleWorkerFilterChange = (typeValue) => {
    this.changeWorkerTypeAction(typeValue)
    this.setState({
      workerType: typeValue,
      userSuggestions: [],
    })
  }

  handleSubmit = () => {
    const { yardId } = this.props

    const { selectedUserId, workerType, startDate, endDate } = this.state

    let userQuery = null
    if (selectedUserId) {
      userQuery = {
        worker_id: selectedUserId,
        worker_type: workerType,
      }
    }

    history.push({
      pathname: `/yard/${yardId}/audits`,
      query: {
        created_at_start: startDate.valueOf(),
        created_at_end: endDate.valueOf(),
        ...userQuery,
      },
    })
  }

  changeWorkerTypeAction(typeValue) {
    const { loadWorkerList, yardId } = this.props

    loadWorkerList({ yardId, workerType: typeValue })
  }

  loadWorkerList() {
    const { yardId, loadWorkerList } = this.props
    const { workerType } = this.state

    loadWorkerList({
      yardId,
      workerType,
    })
  }

  render() {
    const { timezone, yardId } = this.props
    const {
      workerNameValue,
      workerType,
      userSuggestions,
      startDate,
      endDate,
    } = this.state

    const maxDate = endOfDay(moment(), timezone)

    const inputProps = {
      placeholder: `Enter ${firstLetterToUpperCase(workerType)} name`,
      value: workerNameValue,
      onChange: this.onChange,
    }

    return (
      <>
        <NewHeader
          title="Search audits"
          returnText="Back to audits"
          returnUrl={`/yard/${yardId}/audits`}
        />
        <NewContainer>
          <div className="searchAudit-container">
            <div className="searchAudit-content">
              <div className="searchAudit-title">
                <img alt="Search icon" src={searchIcon} /> Search report
              </div>
              <div className="searchAudit-date-filter">
                <SelectButtonGroup
                  label="Set date"
                  options={dateFilters}
                  onChange={this.handleDateFilterChange}
                />
                <div className="searchAudit-date-input">
                  <span>
                    <DatePicker
                      type="white"
                      timezone={timezone}
                      selected={startDate}
                      onChange={this.handleDateChange('startDate')}
                      maxDate={maxDate}
                    />
                  </span>
                  <span>
                    <DatePicker
                      type="white"
                      timezone={timezone}
                      selected={endDate}
                      onChange={this.handleDateChange('endDate')}
                      maxDate={maxDate}
                    />
                  </span>
                </div>
              </div>
              <div className="searchReport-role-filter">
                <SelectButtonGroup
                  label="Search by"
                  options={workerFilters}
                  onChange={this.handleWorkerFilterChange}
                />
                <Autosuggest
                  suggestions={userSuggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  onSuggestionSelected={this.onSuggestionSelected}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={this.renderUserSuggestion}
                  inputProps={inputProps}
                />
              </div>
              <div className="btn-holder">
                <Button
                  className="searchReport-search-btn"
                  text="Search"
                  onClick={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        </NewContainer>
      </>
    )
  }
}

export default SearchAudit
