import { isNil } from 'lodash'
import Promise from 'bluebird'
import clientApi from './utils/clientApi'

const getAll = ({
  yardId,
  limit,
  offset,
  vcrSortOption,
  userId,
  startTime,
  endTime,
}) => {
  const validate = () => {
    if (isNil(userId) && isNil(yardId)) {
      throw new Error('Missing userId or yardId')
    }
  }

  const getRequestOptions = () => {
    if (userId) {
      return {
        method: 'get',
        url: `/vcrs/users/${userId}`,
        params: {
          sort: 'ASC',
          limit: 1,
          startTime: startTime.valueOf(),
          endTime: endTime.valueOf(),
        },
      }
    }

    const sortOption =
      vcrSortOption === 'all' ? undefined : vcrSortOption === 'needs repair'

    return {
      method: 'get',
      url: '/vcrs/search',
      params: {
        yard_id: yardId,
        sortDirection: 'DESC',
        limit,
        offset,
        issue: sortOption,
      },
    }
  }

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const getOne = (vcrId) =>
  clientApi({
    method: 'get',
    url: `/vcrs/${vcrId}`,
  }).then(({ data }) => data.vcr)

const list = ({
  yardId,
  vehicleId,
  limit,
  offset,
  submittedAtStart,
  submittedAtEnd,
  status,
  userId,
}) => {
  const params = {
    limit,
    offset,
    vehicle_id: vehicleId,
    submitted_at_end: submittedAtEnd,
    submitted_at_start: submittedAtStart,
    user_id: userId,
    yard_id: yardId,
  }

  if (status !== 'all') {
    params.status = status
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: '/vcrs',
    params,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const vcrs = {
  getAll,
  getOne,
  list,
}

export default vcrs
