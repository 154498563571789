import moment from 'moment-timezone'
import _ from 'lodash'

import { getAuditsDefaultQuery } from '.'

export default function getQueryDate({
  createdAtStart,
  createdAtEnd,
  metabaseDate,
  timezone,
}) {
  if (!_.isNil(metabaseDate)) {
    if (_.includes(metabaseDate, 'past')) {
      const metabaseDateParsed = metabaseDate.match(/[a-z]+([0-9]+)([a-z]+)/)
      return {
        startDate: moment()
          .tz(timezone)
          .subtract(metabaseDateParsed[1], metabaseDateParsed[2])
          .startOf('month'),
        endDate: moment().tz(timezone),
      }
    }
  }

  if (_.isNil(createdAtStart) || _.isNil(createdAtEnd)) {
    const {
      created_at_start: startDate,
      created_at_end: endDate,
    } = getAuditsDefaultQuery(timezone)

    return { startDate, endDate }
  }

  const startDate = moment.tz(createdAtStart, timezone)
  const endDate = moment.tz(createdAtEnd, timezone)

  return { startDate, endDate }
}
