import styled from 'styled-components'

export const ToggleContainer = styled.div`
  padding: 10px 20px 0;
  background-color: var(--lighter-neutral-96);
`

export const Button = styled.button`
  border-radius: var(--border-radius);
  border: none;
  background: var(--base-blue);
  color: var(--lighter-neutral-99);
  padding: 8px 12px;
  transition: all ease 0.2s;

  :hover {
    background: var(--darker-blue-29);
  }
`

export const Action = styled.button`
  border: none;
  height: 30px;
  width: 30px;
  background: var(--base-blue);
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.2s all ease;

  :hover {
    background: var(--darker-blue-29);
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: .1;
    pointer-events: none;
  `}
`

export const Image = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;

  @supports (-moz-transform: translate(0, 0)) {
    height: unset;
    width: 80%;
  }
`
