import { map, pipe, join, pluck } from 'ramda'

function prepareCsv({ headerMap, body }) {
  const parsedHeader = pipe(pluck('text'), join(','))(headerMap)

  const parseRow = (row) =>
    pipe(
      map(({ field }) => row[field]),
      join(','),
    )(headerMap)

  const parsedBody = pipe(map(parseRow), join('\n'))(body)

  return `${parsedHeader}\n${parsedBody}`
}

export default prepareCsv
