import queryString from 'query-string'
import { toolkitApi } from '.'

const vehiclesApi = toolkitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllVehicles: builder.query({
      query: () => ({
        url: 'vehicles',
        params: queryString.stringify({
          include_model: ['Yard', 'DeviceSensor'],
        }),
      }),
    }),
  }),
})

export const { useGetAllVehiclesQuery } = vehiclesApi
