import styled from 'styled-components'

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`

export const Action = styled.button`
  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-color: transparent;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: none;
  margin-left: 15px;
  background-size: cover;
  display: block;
  transition: 0.1s all;
  margin: 0 auto;

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}
`
