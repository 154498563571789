import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Loading = ({ infinite, showText }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    className={cx('loading', { infinite })}
    viewBox="0 0 370 570"
    style={{ enableBackground: 'new 0 0 370 570' }}
    xmlSpace="preserve"
  >
    <polygon
      className="st0 with_opacity"
      points="153.4,66.3 242.3,14.1 326,64.3 242.3,119.8 "
    />
    <polygon
      className="st1 no_opacity"
      points="242.3,119.8 326,64.3 326,176.4 242.3,225.8 "
    />
    <polygon
      className="st2 no_opacity"
      points="43.3,131.9 127.4,82.2 211.1,132.4 127.9,182.6 "
    />
    <polygon
      className="st3 with_opacity"
      points="43.3,131.9 127.9,182.6 127.9,287.4 43.3,236.9 "
    />
    <polygon
      className="st4 no_opacity"
      points="153.2,197.9 241.3,250.9 241.3,356 153.2,303 "
    />
    <polygon
      className="st5 with_opacity"
      points="241.3,356 326.7,305 326.7,201.2 241.3,250.9 "
    />
    {showText ? (
      <text className="loading-text" x="5" y="470">
        Loading...
      </text>
    ) : (
      ''
    )}
  </svg>
)

Loading.propTypes = {
  infinite: PropTypes.bool,
  showText: PropTypes.bool,
}

Loading.defaultProps = {
  infinite: true,
  showText: false,
}

export default Loading
