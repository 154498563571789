import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Promise from 'bluebird'
import Button from '../Button'

class Modal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    confirmButtonText: PropTypes.string,
    confirmButtonType: PropTypes.oneOf(['button', 'submit']),
    isLoading: PropTypes.bool,
    onConfirm: PropTypes.func,
  }

  static defaultProps = {
    confirmButtonType: 'button',
    confirmButtonText: 'Confirm',
    onConfirm: undefined,
    isLoading: undefined,
  }

  state = {
    isOnConfirmLoading: false,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = ({ code }) => {
    const { onClose } = this.props

    if (code === 'Escape') {
      onClose()
    }
  }

  handleConfirm = () => {
    const { onConfirm, onClose } = this.props
    if (onConfirm !== undefined) {
      this.setState({
        isOnConfirmLoading: true,
      })
      Promise.resolve()
        .then(onConfirm)
        .then(onClose)
        .finally(() => {
          this.setState({ isOnConfirmLoading: false })
        })
    }
  }

  render() {
    const {
      visible,
      onClose,
      children,
      title,
      confirmButtonType,
      confirmButtonText,
      isLoading,
    } = this.props

    const containerClass = cx('modal-container', {
      'modal-containerHidden': !visible,
    })

    const panelClass = visible ? 'modal-panel' : 'modal-panelHidden'

    return (
      <div>
        <div className={containerClass}>
          <div className={panelClass}>
            <header>{title}</header>
            <section>{children}</section>
            <footer>
              <Button className="cancelBtn" text="Cancel" onClick={onClose} />
              <Button
                type={confirmButtonType}
                className="confirmBtn"
                text={isLoading ? 'Loading ...' : confirmButtonText}
                onClick={this.handleConfirm}
                isDisabled={isLoading}
              />
            </footer>
          </div>
          <div
            role="button"
            className="modal-mask"
            hidden={!visible}
            onClick={onClose}
          />
        </div>
      </div>
    )
  }
}

export default Modal
