import PropTypes from 'prop-types'

const metricCardTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  isDecimal: PropTypes.bool,
  format: PropTypes.string,
}

export default metricCardTypes
