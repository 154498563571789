import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'

import Button from '../Button'

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      eventId: null,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  handleReportFeedbackClick = () => {
    const { eventId } = this.state
    Sentry.showReportDialog({ eventId })
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    const { children } = this.props
    const { hasError, eventId } = this.state
    if (hasError && !_.isNil(eventId)) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
          }}
        >
          <div>
            <span role="img" aria-label="Ladybug">
              🐞
            </span>
            <br />
            Sorry, but it looks like something went wrong.
            <br />
            {
              "But don't worry, we've already logged the error and been notified."
            }
            <br />
            {"We'll be working on the fix for you soon!"}
            <br />
            <br />
            <Button href="/" onClick={this.handleReportFeedbackClick}>
              Report Feedback
            </Button>
            <br />
            <br />
            <Button>
              <a href="/" style={{ color: 'white' }}>
                Return to Homepage
              </a>
            </Button>
          </div>
        </div>
      )
    }
    return children
  }
}
