import React, { useEffect } from 'react'
import _ from 'lodash'

import {
  Blank,
  VehicleDetailsListContainer,
  VehicleDetailsSingleNote,
  VehicleDetailsSingleVcr,
  VehicleDetailsSingleVwr,
} from '../../components'

import * as S from './styles'

const VehicleDetailsEvents = ({
  loadEvents,
  vehicleId,
  eventList,
  timezone,
  yardId,
  setHeaderReturnOptions,
}) => {
  useEffect(() => {
    loadEvents({
      vehicleId,
      limit: 10,
      offset: { comment: 0, vcr: 0, vwr: 0 },
    })
  }, [loadEvents, vehicleId])

  const listValue = _.get(eventList, 'value', [])
  const isLoading = _.get(eventList, 'isLoading')

  const events = listValue.map((value) => {
    const tab = _.get(value, 'type')
    const dataToRender = _.get(value, 'data')

    const props = {
      [tab]: dataToRender,
      timezone,
      yardId,
      setHeaderReturnOptions,
      vehicleId,
    }

    const renderListItemByType = {
      vcr: <VehicleDetailsSingleVcr {...props} />,
      vwr: <VehicleDetailsSingleVwr {...props} />,
      comment: <VehicleDetailsSingleNote {...props} />,
    }[tab]

    return renderListItemByType
  })

  function handleScrollEnd() {
    const nextOffset = _.get(eventList, 'nextOffset')

    return _.isNil(nextOffset)
      ? null
      : loadEvents({
          vehicleId,
          limit: 10,
          offset: nextOffset,
          isLazyLoading: true,
        })
  }

  function render() {
    if (_.isEmpty(listValue) && !isLoading) {
      return (
        <S.EmptyContainer>
          <Blank
            title="There are no history for this vehicle yet"
            type="search"
          />
        </S.EmptyContainer>
      )
    }

    return (
      <VehicleDetailsListContainer
        isLoading={isLoading}
        handleScrollEnd={handleScrollEnd}
      >
        {events}
      </VehicleDetailsListContainer>
    )
  }

  return render()
}

export default VehicleDetailsEvents
