import { trackingTypes } from '../../actions/trackingActions'

const initialState = null

const iconPinned = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.ICON_PINNED:
      return payload
    case trackingTypes.ICON_PINNED_RESET:
      return initialState
    default:
      return state
  }
}

export default iconPinned
