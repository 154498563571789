import { pipe, isNil, filter, propEq, identity } from 'ramda'

function applyFilter({ idFromUrl, field }) {
  return filter((row) => {
    if (idFromUrl === 'ALL') {
      return true
    }

    const rowValue = row[field]

    if (idFromUrl === 'OTHER') {
      return isNil(rowValue)
    }

    if (isNil(rowValue)) {
      return false
    }

    return String(rowValue.id) === String(idFromUrl)
  })
}

export default function getFilteredRows({
  rows,
  company,
  railroad,
  showActiveYards,
  activeTab,
}) {
  return pipe(
    applyFilter({ idFromUrl: company, field: 'company' }),
    applyFilter({ idFromUrl: railroad, field: 'railroad' }),
    activeTab === 'yards'
      ? filter(propEq('isActive', showActiveYards))
      : identity,
  )(rows)
}
