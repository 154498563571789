import { manageVehiclesTypes } from '../../actions/manageVehiclesActions'

const initialState = {
  loading: false,
  value: '',
}

const postVehicleCommentState = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVehiclesTypes.POST_COMMENT_REQUESTED:
      return {
        ...state,
        loading: true,
        value: '',
      }

    case manageVehiclesTypes.POST_COMMENT_LOADED:
      return {
        ...state,
        loading: false,
        value: payload,
      }

    case manageVehiclesTypes.POST_COMMENT_FAILED:
      return initialState

    default:
      return state
  }
}

export default postVehicleCommentState
