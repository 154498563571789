import _ from 'lodash'

export default function formatCellValue({ value, fractionDigits }) {
  if (_.isFinite(value)) {
    return _.isNil(fractionDigits)
      ? value.toLocaleString('en-US')
      : value.toLocaleString('en-US', { minimumFractionDigits: fractionDigits })
  }

  return value
}
