import api from '../api'
import { createTypes } from './utils'

export const deviceStatusTypes = createTypes('deviceStatus', [
  { value: 'STATUS', isAsync: true },
  { value: 'SESSIONS', isAsync: true },
])

export const loadDeviceStatuses = (yardId) => (dispatch) => {
  dispatch({ type: deviceStatusTypes.STATUS_REQUESTED })

  if (yardId === 'none') {
    dispatch({
      type: deviceStatusTypes.STATUS_LOADED,
      payload: [],
    })
  }
  api.devices
    .getAll({ yardId })
    .then((response) => {
      dispatch({
        type: deviceStatusTypes.STATUS_LOADED,
        payload: response,
      })
    })
    .catch(() => {
      dispatch({
        type: deviceStatusTypes.STATUS_FAILED,
      })
    })
}

export const loadDeviceSessions = (yardId) => (dispatch) => {
  dispatch({ type: deviceStatusTypes.SESSIONS_REQUESTED })

  api.devices
    .getSessions(yardId)
    .then((response) => {
      dispatch({
        type: deviceStatusTypes.SESSIONS_LOADED,
        payload: response,
      })
    })
    .catch(() => dispatch({ type: deviceStatusTypes.SESSIONS_FAILED }))
}
