import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Formik, Form, Field } from 'formik'
import _ from 'lodash'
import { __, pipe, prop, isNil, filter, includes } from 'ramda'
import { FormItem, Button, NewContainer, NewHeader } from '../../components'
import { roleIdToName, roleNameToId } from '../../config'
import {
  canChangeRole,
  canChangeAllowedYards,
  canCreate,
  canChange,
  getRolesWithLowerPermissionLevel,
} from '../../permissions/user'
import { pathDefaultTo } from '../../utils/ramdaExtensions'
import PasswordStrength from '../PasswordStrength'

const objectToOption = (obj) =>
  _.map(obj, (roleName, key) => ({
    value: Number(key),
    label: _.chain(roleName).toLower().startCase().value(),
  }))

const allRoleOptionList = objectToOption(roleIdToName)

function getRoleOptionList({ loggedUser, isRoleDisabled }) {
  if (isRoleDisabled) {
    return allRoleOptionList
  }

  const adminId = roleNameToId.ADMIN
  const corporateBillerId = roleNameToId.CORPORATE_BILLER

  if (loggedUser.role_id === corporateBillerId) {
    return filter(({ value }) => value !== adminId)(allRoleOptionList)
  }

  return filter(
    pipe(
      prop('value'),
      includes(__, getRolesWithLowerPermissionLevel({ loggedUser })),
    ),
  )(allRoleOptionList)
}

function getIsViewOnly({ path, loggedUser, targetUser, isCreate }) {
  if (path.includes('view')) {
    return true
  }

  if (isCreate) {
    return !canCreate({ loggedUser, targetUser })
  }

  return !canChange({ loggedUser, targetUser })
}

class CreateUser extends Component {
  static propTypes = {
    userIdParam: PropTypes.number,
    loadUser: PropTypes.func.isRequired,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      username: PropTypes.string.isRequired,
      tosId: PropTypes.string,
      email: PropTypes.string,
      roleId: PropTypes.number,
      yardId: PropTypes.number,
      allowedYardIds: PropTypes.arrayOf(PropTypes.number),
    }),
    saveUser: PropTypes.func.isRequired,
    showAlert: PropTypes.func.isRequired,
    selectedYard: PropTypes.number.isRequired,
    resetUser: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    yardList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    loggedUser: PropTypes.object.isRequired,
  }

  static defaultProps = {
    user: {
      username: '',
    },
    userIdParam: null,
  }

  componentDidMount() {
    const { loadUser, userIdParam } = this.props
    if (!isNil(userIdParam)) {
      loadUser(userIdParam)
    }
  }

  componentWillUnmount() {
    const { resetUser } = this.props
    resetUser()
  }

  validateFields = (values) => {
    const errors = {}
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    const requiredMessage = 'Required field'
    const emailFreeRoles = [
      roleNameToId.DRIVER,
      roleNameToId.MECHANIC,
      roleNameToId.SUPERVISOR,
    ]

    if (_.isNil(values.roleId)) {
      errors.roleId = requiredMessage
    }
    if (_.isNil(values.yardId)) {
      errors.yardId = requiredMessage
    }
    if (_.isEmpty(values.firstName)) {
      errors.firstName = requiredMessage
    }
    if (_.isEmpty(values.lastName)) {
      errors.lastName = requiredMessage
    }
    if (_.isEmpty(values.username)) {
      errors.username = requiredMessage
    }
    if (
      _.isEmpty(values.email) &&
      !emailFreeRoles.includes(Number(values.roleId))
    ) {
      errors.email = requiredMessage
    }
    if (!_.isEmpty(values.email) && !emailPattern.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    return errors
  }

  render() {
    const {
      user,
      saveUser,
      userIdParam,
      showAlert,
      selectedYard,
      path,
      yardList,
      loggedUser,
    } = this.props
    const yardId = user.yardId || selectedYard
    const userId = userIdParam
    const isCreate = isNil(userIdParam)
    const yardListOptions = yardList.map((yard) => ({
      value: yard.id,
      label: `${yard.code} - ${yard.name}`,
    }))

    const targetUser = {
      ...user,
      id: userIdParam,
      role_id: user.roleId,
    }

    const isViewOnly = getIsViewOnly({ path, loggedUser, targetUser, isCreate })

    const isRoleDisabled =
      isViewOnly || !canChangeRole({ loggedUser, targetUser })

    const rolesOptionList = getRoleOptionList({
      loggedUser,
      targetUser,
      isRoleDisabled,
    })

    const newUserValues = {
      ...user,
      password: null,
      passwordConfirm: null,
    }

    const existingUserValues = {
      ...user,
      allowedYardIds: _.defaultTo(user.allowedYardIds, []).map(
        (yard) => yard.id,
      ),
    }

    const initialValues = isCreate ? newUserValues : existingUserValues

    return (
      <>
        <NewHeader
          title="Create new user"
          returnText="Back to user list"
          returnUrl="/users"
        />
        <NewContainer>
          <div className="createUser-container">
            <div className="createUser-content">
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validate={this.validateFields}
                onSubmit={(values, { setSubmitting }) => {
                  const formData = _.chain(values)
                    .omit('passwordConfirm')
                    .mapValues((value) => {
                      if (typeof value === 'string') {
                        return value.trim()
                      }
                      return value
                    })
                    .value()

                  saveUser({
                    formData,
                    userId,
                    yardId,
                  })
                    .then(() => {
                      showAlert({
                        type: 'success',
                        message: 'User saved successfully',
                      })
                      browserHistory.push('/users')
                      setSubmitting(false)
                    })
                    .catch(({ response }) => {
                      const message = pathDefaultTo(
                        ['data', 'errors', 0, 'message'],
                        'Failed to save user',
                      )(response)
                      showAlert({
                        type: 'failure',
                        message,
                      })
                      setSubmitting(false)
                    })
                }}
              >
                {({ isSubmitting, values }) => (
                  <Form>
                    <Field
                      type="select"
                      name="roleId"
                      label="Role"
                      component={FormItem}
                      options={rolesOptionList}
                      isDisabled={isRoleDisabled}
                    />
                    <Field
                      type="select"
                      name="yardId"
                      label="Primary Yard"
                      component={FormItem}
                      options={yardListOptions}
                      isDisabled={isViewOnly}
                    />
                    <Field
                      type="select"
                      name="allowedYardIds"
                      label="Allowed Yards"
                      component={FormItem}
                      options={yardListOptions}
                      isMulti
                      isDisabled={
                        isViewOnly || !canChangeAllowedYards({ loggedUser })
                      }
                    />
                    <Field
                      type="text"
                      name="firstName"
                      label="First name"
                      component={FormItem}
                      disabled={isViewOnly}
                    />
                    <Field
                      type="text"
                      name="lastName"
                      label="Last name"
                      component={FormItem}
                      disabled={isViewOnly}
                    />
                    <Field
                      type="text"
                      name="username"
                      label="Username (Employee ID)"
                      component={FormItem}
                      disabled={isViewOnly}
                    />
                    <Field
                      type="text"
                      name="tosId"
                      label="TOS ID"
                      component={FormItem}
                      disabled={isViewOnly}
                    />
                    <Field
                      type="email"
                      name="email"
                      label="Email"
                      component={FormItem}
                      disabled={isViewOnly}
                    />
                    {isCreate && (
                      <PasswordStrength
                        password={values.password}
                        username={values.username}
                        firstName={values.firstName}
                        lastName={values.lastName}
                        titleType="label"
                        validationType={
                          Number(values.roleId) === roleNameToId.DRIVER
                            ? 'numeric'
                            : 'strength'
                        }
                      />
                    )}
                    {!isViewOnly && (
                      <Button
                        type="submit"
                        text={isSubmitting ? 'Loading...' : 'Save'}
                        disabled={isSubmitting}
                      />
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </NewContainer>
      </>
    )
  }
}

export default CreateUser
