import PropTypes from 'prop-types'
import moment from 'moment'

export default {
  auditFunction: PropTypes.string.isRequired,
  auditedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]).isRequired,
  auditor: PropTypes.string.isRequired,
  comment: PropTypes.string,
  coneTestPassed: PropTypes.bool,
  correctiveActions: PropTypes.arrayOf(PropTypes.string),
  createdAt: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]).isRequired,
  employee: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  supervisor: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  updatedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]).isRequired,
  violations: PropTypes.arrayOf(PropTypes.string),
  yardCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}
