import { billingTypes } from '../../actions/billingActions'

const initialState = {
  value: [],
  isLoading: false,
}

const billingInvoices = (state = initialState, { type, payload }) => {
  switch (type) {
    case billingTypes.BILLING_INVOICES_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case billingTypes.BILLING_INVOICES_LOADED:
      return {
        ...state,
        value: payload.invoices,
        nextOffset: payload.nextOffset,
        total: payload.total,
        isLoading: false,
      }
    case billingTypes.BILLING_INVOICES_LAZY_LOADED:
      return {
        ...state,
        value: state.value.concat(payload.invoices),
        nextOffset: payload.nextOffset,
        total: payload.total,
        isLoading: false,
      }
    case billingTypes.BILLING_INVOICES_FAILED:
      return initialState

    default:
      return state
  }
}

export default billingInvoices
