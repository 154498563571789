import React from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import {
  map,
  head,
  pipe,
  uniq,
  times,
  sortBy,
  always,
  toUpper,
  reverse,
} from 'ramda'

import * as S from '../styles'

import { NewToolTip } from '../../../components'
import { billingPastDuePeriod, billingInvoicesPerRow } from '../../../config'

import editIcon from '../../../images/icons/icon_edit.svg'

const pathAnalyticsSafety = '/analytics/safety'
const pathBilling = `/billing/date/${moment().format('YYYY-MM-DD')}`

function parseRecentInvoices({ recentInvoices, timezone }) {
  return pipe(
    reverse,
    map((invoice) => {
      const isPastDue = moment
        .tz(invoice.end_date, timezone)
        .isBefore(moment.tz(timezone).subtract(billingPastDuePeriod, 'days'))

      const period = `${moment
        .tz(invoice.start_date, timezone)
        .format('MMM Do')} - ${moment
        .tz(invoice.end_date, timezone)
        .format('MMM Do')}`

      return {
        ...invoice,
        period,
        status:
          invoice.status === 'draft' && isPastDue ? 'past_due' : invoice.status,
      }
    }),
  )(recentInvoices)
}

function getOrderCharacter(invoice) {
  switch (invoice.status) {
    case 'past_due':
      return 'f'
    case 'draft':
      return 'e'
    case 'accrual':
      return 'd'
    case 'final':
      return 'c'
    default:
      return 'b'
  }
}

function getOrderPrefix(orderString) {
  return pipe(uniq, sortBy(String), reverse, head, toUpper)(orderString)
}

const recentInvoicesLengthLimit = 5

const arrayWithA = times(always('a'))

function getOrderString(parsedRecentInvoices) {
  if (_.isEmpty(parsedRecentInvoices)) {
    return arrayWithA(recentInvoicesLengthLimit)
  }

  return [
    ...arrayWithA(recentInvoicesLengthLimit - parsedRecentInvoices.length),
    ...map(getOrderCharacter)(parsedRecentInvoices),
  ].join('')
}

function getBillingStatusOrder({ parsedRecentInvoices }) {
  const orderString = getOrderString(parsedRecentInvoices)
  return [getOrderPrefix(orderString), ...orderString].join('')
}

function billingTooltipDescription(recentInvoices) {
  return (
    <S.TooltipDescription>
      <S.TooltipDescriptionTitle>Billing Cycles</S.TooltipDescriptionTitle>
      {recentInvoices.map((invoice) => (
        <S.Flex key={invoice.id}>
          <S.BillingCycleMiniBadge status={invoice.status} /> {invoice.period}
        </S.Flex>
      ))}

      <S.Flex>
        <S.Border />
      </S.Flex>

      <S.TooltipDescriptionTitle>Statuses</S.TooltipDescriptionTitle>
      <S.Flex>
        <S.BillingCycleMiniBadge status="final" /> Submitted as final
      </S.Flex>
      <S.Flex>
        <S.BillingCycleMiniBadge status="accrual" /> Submitted as accrual
      </S.Flex>
      <S.Flex>
        <S.BillingCycleMiniBadge status="past_due" /> Past due (at least 4
        business days)
      </S.Flex>
      <S.Flex>
        <S.BillingCycleMiniBadge status="draft" /> In Progress
      </S.Flex>
    </S.TooltipDescription>
  )
}

function getAuditViolationsPercentage(metric) {
  const {
    audit_with_violations_count: violationsCount,
    audit_count: totalAudits,
  } = metric

  return totalAudits > 0 ? Math.round((100 * violationsCount) / totalAudits) : 0
}

function renderAuditsCell({ metric, auditViolationsPercentage }) {
  const {
    audit_count: auditCount,
    audit_with_violations_count: violationsCount,
  } = metric
  return (
    <S.BadgeContainer>
      <S.AuditsBadge auditPercentage={auditViolationsPercentage} />
      {`${violationsCount}/${auditCount} (${auditViolationsPercentage}%)`}
    </S.BadgeContainer>
  )
}

function renderMissingBillingDaysCell(missingBillingDays) {
  return (
    <S.BadgeContainer>
      <S.BillingDaysBadge missingBillingDays={missingBillingDays} />
      {missingBillingDays}
    </S.BadgeContainer>
  )
}

function renderBillingStatusCell({ parsedRecentInvoices }) {
  const invoiceListSize = _.size(parsedRecentInvoices)

  const billingRecentInvoicesWithBlanks = [
    ..._.times(billingInvoicesPerRow - invoiceListSize, _.constant({})),
    ...parsedRecentInvoices,
  ]

  return (
    <NewToolTip
      title="BILLING STATUS"
      titleIcon={editIcon}
      placement="left"
      description={billingTooltipDescription(parsedRecentInvoices)}
    >
      <S.BadgeContainer>
        {billingRecentInvoicesWithBlanks.map((invoice) => (
          <S.BillingStatusBadge key={invoice.id} status={invoice.status} />
        ))}
      </S.BadgeContainer>
    </NewToolTip>
  )
}

function renderEngineHoursCell({ hasMissingEngineHours, engineHoursMonth }) {
  return (
    <S.BadgeContainer>
      <S.EngineHoursBadge hasMissingEngineHours={hasMissingEngineHours} />
      {engineHoursMonth}
    </S.BadgeContainer>
  )
}

export default function getAllYardsRows({
  allYards,
  handleClickCell,
  handleClickRow,
  renderActionMenu,
  hasCrudYardWriteAccess,
}) {
  return allYards.map((metric) => {
    const { timezone } = metric

    const yardId = _.get(metric, 'yard_id')
    const auditViolationsPercentage = getAuditViolationsPercentage(metric)

    const hasMissingEngineHours = _.defaultTo(
      _.get(metric, 'has_missing_engine_hours'),
      '',
    )
    const engineHoursMonth = moment
      .tz(timezone)
      .subtract(1, 'month')
      .format('MMM')

    const missingBillingDays = _.defaultTo(
      _.get(metric, 'missing_billing_days'),
      '',
    )

    const recentInvoices = _.chain(metric)
      .get('recent_invoices')
      .defaultTo([])
      .slice(1)
      .value()

    const parsedRecentInvoices = parseRecentInvoices({
      recentInvoices,
      timezone,
    })

    const columns = {
      0: {
        value: `${metric.yard_name} - ${metric.yard_code}`,
        onClick: (event) =>
          handleClickCell({
            event,
            yardId,
            path: pathBilling,
          }),
      },
      1: {
        value: auditViolationsPercentage,
        render: renderAuditsCell({ metric, auditViolationsPercentage }),
        onClick: (event) =>
          handleClickCell({
            event,
            yardId,
            path: pathAnalyticsSafety,
          }),
      },
      2: {
        value: hasMissingEngineHours,
        render: renderEngineHoursCell({
          hasMissingEngineHours,
          engineHoursMonth,
        }),
        onClick: (event) =>
          handleClickCell({
            event,
            yardId,
            path: {
              pathname: `/yard/${metric.yard_id}/vehicles`,
              query: { view_mode: 'engine-hours' },
            },
          }),
      },
      3: {
        value: missingBillingDays,
        render: renderMissingBillingDaysCell(missingBillingDays),
        onClick: (event) =>
          handleClickCell({ event, yardId, path: pathBilling }),
      },
      4: {
        value: getBillingStatusOrder({ parsedRecentInvoices }),
        render: renderBillingStatusCell({ parsedRecentInvoices }),
        onClick: (event) =>
          handleClickCell({
            event,
            yardId,
            path: pathBilling,
          }),
      },
    }

    if (hasCrudYardWriteAccess) {
      columns[5] = {
        value: '',
        render: renderActionMenu({
          yardId,
        }),
      }
    }

    return {
      columns,
      onClick: () => handleClickRow(metric.yard_id),
      id: metric.yard_code,
      railroad: metric.yard_billing_customer,
      company: metric.yard_billing_company,
      isActive: metric.yard_is_active,
    }
  })
}
