import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NewToolTip from '../NewToolTip'
import './Table.css'

class TableRow extends Component {
  preventClickEffect = (e) => {
    e.preventDefault()
  }

  render() {
    const { colNum, fieldList, rowData, title, rowId } = this.props

    const className = title === '/vcrs/' ? 'vcrStatus' : ''
    const style = {
      animationDuration: `${1 + rowId * 0.2}s`,
      animationName: 'tableRowFadein',
    }

    const row = () => (
      <div className={`tableRow flex-row ${className}`} style={style}>
        {fieldList.map((element) =>
          element === 'status' && title !== '/vcrs/' ? (
            <div
              key={element}
              role="button"
              tabIndex="0"
              className={`preventClick tableColumn${colNum}`}
              onClick={this.preventClickEffect}
              onKeyDown={this.preventClickEffect}
            >
              {rowData[element]}
            </div>
          ) : (
            <div className={`tableColumn${colNum}`} key={element}>
              {rowData[element]}
            </div>
          ),
        )}
      </div>
    )

    return (
      <>
        {rowData.tooltip_title ? (
          <NewToolTip
            title={rowData.tooltip_title}
            description={rowData.tooltip_description}
          >
            {row()}
          </NewToolTip>
        ) : (
          row()
        )}
      </>
    )
  }
}

TableRow.propTypes = {
  colNum: PropTypes.number,
  fieldList: PropTypes.arrayOf(PropTypes.string),
  rowData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.element,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
    ]),
  ),
  title: PropTypes.string,
  rowId: PropTypes.number,
}

TableRow.defaultProps = {
  colNum: 0,
  fieldList: [],
  rowData: {},
  title: '',
  rowId: 0,
}

export default TableRow
