import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  margin: 0 0 15px;

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
`

export default Container
