/* eslint-disable react/prop-types */
import React from 'react'

const Notes = ({ fill }) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.61895 6.09375C4.32713 6.11009 4.05328 6.23995 3.85596 6.45558C3.65865 6.67121 3.55354 6.95548 3.5631 7.24761C3.57266 7.53973 3.69614 7.81651 3.90713 8.01877C4.11812 8.22104 4.39988 8.33272 4.69214 8.32993C4.71554 8.32993 4.73954 8.32993 4.76294 8.32993C5.05666 8.30742 5.32968 8.17006 5.5228 7.94762C5.71593 7.72518 5.81361 7.43559 5.79467 7.14162C5.77572 6.84764 5.64168 6.57298 5.42159 6.37717C5.20151 6.18136 4.91313 6.08017 4.61895 6.09555V6.09375Z"
      fill={fill || '#000'}
    />
    <path
      d="M7.72468 6.09375C7.43271 6.11002 7.15871 6.23995 6.96133 6.45572C6.76394 6.67149 6.65886 6.95595 6.66859 7.24822C6.67831 7.54049 6.80207 7.81734 7.01335 8.01951C7.22464 8.22168 7.50667 8.3331 7.79908 8.32993C7.82248 8.32993 7.84589 8.32993 7.86989 8.32993C8.16361 8.30742 8.43662 8.17006 8.62975 7.94762C8.82288 7.72518 8.92055 7.43559 8.9016 7.14162C8.88266 6.84764 8.74861 6.57298 8.52853 6.37717C8.30845 6.18136 8.02007 6.08017 7.72589 6.09555L7.72468 6.09375Z"
      fill={fill || '#000'}
    />
    <path
      d="M11.2115 2H4.38716C3.81032 2.00071 3.23927 2.11503 2.70661 2.33643C2.17395 2.55783 1.69012 2.88198 1.28273 3.29037C0.875349 3.69876 0.552391 4.18339 0.3323 4.71659C0.112208 5.24979 -0.000706244 5.82112 3.32357e-06 6.39796V13.6663C3.18871e-05 13.7222 0.0141128 13.7772 0.0409519 13.8263C0.0677911 13.8753 0.106528 13.9168 0.153602 13.947C0.200676 13.9772 0.254577 13.995 0.310352 13.9989C0.366128 14.0028 0.421988 13.9926 0.472799 13.9693L4.11236 12.2749C4.39143 12.1459 4.6953 12.0793 5.00276 12.0799H11.2127C11.7897 12.0792 12.361 11.9648 12.8938 11.7432C13.4266 11.5217 13.9106 11.1973 14.318 10.7887C14.7254 10.38 15.0483 9.89513 15.2682 9.36165C15.4882 8.82818 15.6009 8.25659 15.5999 7.67955V6.39796C15.6 5.23316 15.138 4.11588 14.3153 3.29135C13.4926 2.46681 12.3763 2.00238 11.2115 2V2ZM1.64579 11.8783C1.61928 11.8906 1.5901 11.896 1.56094 11.8941C1.53179 11.8921 1.5036 11.8828 1.47897 11.8671C1.45434 11.8514 1.43406 11.8297 1.42001 11.8041C1.40597 11.7785 1.3986 11.7497 1.39859 11.7205V6.39796C1.39954 5.60491 1.71446 4.8445 2.2745 4.283C2.83453 3.7215 3.59412 3.4046 4.38716 3.40159H11.2115C12.005 3.40397 12.7652 3.72059 13.3257 4.28216C13.8863 4.84373 14.2015 5.6045 14.2025 6.39796V7.67775C14.2033 8.07112 14.1265 8.4608 13.9766 8.8245C13.8268 9.1882 13.6067 9.51881 13.3289 9.79741C13.0512 10.076 12.7213 10.2972 12.3581 10.4482C11.9949 10.5993 11.6055 10.6772 11.2121 10.6777H4.69556C4.38771 10.6776 4.08358 10.745 3.80457 10.8751L1.64579 11.8783Z"
      fill={fill || '#000'}
    />
    <path
      d="M12.0189 7.28349C12.0327 7.06022 11.9792 6.83795 11.8651 6.64551C11.751 6.45307 11.5818 6.29935 11.3793 6.20429C11.1768 6.10923 10.9504 6.07722 10.7295 6.11241C10.5086 6.1476 10.3033 6.24836 10.1404 6.40164C9.97746 6.55491 9.86435 6.75363 9.81573 6.97198C9.76712 7.19033 9.78525 7.41825 9.86777 7.62617C9.95029 7.83409 10.0934 8.01242 10.2785 8.13801C10.4636 8.2636 10.6822 8.33066 10.9059 8.33049C10.9293 8.33049 10.9527 8.33049 10.9767 8.33049C11.248 8.31248 11.5035 8.19626 11.6953 8.00356C11.8871 7.81086 12.0021 7.55487 12.0189 7.28349Z"
      fill={fill || '#000'}
    />
  </svg>
)

export default Notes
