import { connect } from 'react-redux'
import VehicleDetailsVwr from './VehicleDetailsVwr'

import { loadVwrs } from '../../actions/manageVehiclesActions'

import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state) => {
  const timezone = getYardTimeZone(state)

  return {
    vwrList: state.manageVehicles.vwrList,
    timezone,
  }
}

const mapDispatchToProps = {
  loadVwrs,
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetailsVwr)
