import humanizeDuration from 'humanize-duration'

const baseConfig = {
  units: ['h', 'm'],
  round: true,
  serialComma: false,
}

const humanizeDefault = humanizeDuration.humanizer({
  ...baseConfig,
  conjunction: ' and ',
})

const shortEnglishHumanizer = humanizeDuration.humanizer({
  ...baseConfig,
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
  delimiter: ' ',
  spacer: '',
})

const getHumanizeDuration = (value, { isShort = false } = {}) => {
  const options = {}

  if (value < 30000) {
    options.units = ['h', 'm', 's']
  }

  if (isShort) {
    return shortEnglishHumanizer(value, options)
  }

  return humanizeDefault(value, options)
}

export default getHumanizeDuration
