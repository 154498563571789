import { manageVehiclesTypes } from '../../actions/manageVehiclesActions'

const initialState = {
  value: [],
  count: 0,
  limit: 0,
  nextOffset: 0,
  isLoading: false,
}

const vcrList = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVehiclesTypes.VCRS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }

    case manageVehiclesTypes.VCRS_LOADED:
      return {
        ...state,
        isLoading: false,
        value: payload.vcrs,
        count: payload.count,
        limit: payload.limit,
        nextOffset: payload.nextOffset,
      }

    case manageVehiclesTypes.VCRS_LAZY_LOADED:
      return {
        ...state,
        isLoading: false,
        value: state.value.concat(payload.vcrs),
        count: payload.count,
        limit: payload.limit,
        nextOffset: payload.nextOffset,
      }

    case manageVehiclesTypes.VCRS_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}

export default vcrList
