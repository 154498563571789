import React from 'react'
import { Marker } from '@react-google-maps/api'
import _ from 'lodash'
import PropTypes from 'prop-types'

import hostlerGreen from '../../images/vehicle_icons/Hostler/Hostler_Green.png'
import hostlerRed from '../../images/vehicle_icons/Hostler/Hostler_Red.png'
import hostlerOrange from '../../images/vehicle_icons/Hostler/Hostler_Orange.png'
import hostlerBlue from '../../images/vehicle_icons/Hostler/Hostler_Blue.png'
import hostlerGrey from '../../images/vehicle_icons/Hostler/Hostler_Grey.png'
import hostlerBlack from '../../images/vehicle_icons/Hostler/Hostler_Black.png'
import hostlerGreenOp from '../../images/vehicle_icons/Hostler/Hostler_Green_Op.png'
import hostlerRedOp from '../../images/vehicle_icons/Hostler/Hostler_Red_Op.png'
import hostlerOrangeOp from '../../images/vehicle_icons/Hostler/Hostler_Orange_Op.png'
import hostlerBlueOp from '../../images/vehicle_icons/Hostler/Hostler_Blue_Op.png'
import hostlerGreyOp from '../../images/vehicle_icons/Hostler/Hostler_Grey_Op.png'
import hostlerBlackOp from '../../images/vehicle_icons/Hostler/Hostler_Black_Op.png'
import craneGreen from '../../images/vehicle_icons/Crane/Crane_Green.png'
import craneRed from '../../images/vehicle_icons/Crane/Crane_Red.png'
import craneOrange from '../../images/vehicle_icons/Crane/Crane_Orange.png'
import craneBlue from '../../images/vehicle_icons/Crane/Crane_Blue.png'
import craneGrey from '../../images/vehicle_icons/Crane/Crane_Grey.png'
import craneBlack from '../../images/vehicle_icons/Crane/Crane_Black.png'
import craneGreenOp from '../../images/vehicle_icons/Crane/Crane_Green_Op.png'
import craneRedOp from '../../images/vehicle_icons/Crane/Crane_Red_Op.png'
import craneOrangeOp from '../../images/vehicle_icons/Crane/Crane_Orange_Op.png'
import craneBlueOp from '../../images/vehicle_icons/Crane/Crane_Blue_Op.png'
import craneGreyOp from '../../images/vehicle_icons/Crane/Crane_Grey_Op.png'
import craneBlackOp from '../../images/vehicle_icons/Crane/Crane_Black_Op.png'
import forkliftGreen from '../../images/vehicle_icons/Forklift/Forklift_Green.png'
import forkliftRed from '../../images/vehicle_icons/Forklift/Forklift_Red.png'
import forkliftOrange from '../../images/vehicle_icons/Forklift/Forklift_Orange.png'
import forkliftBlue from '../../images/vehicle_icons/Forklift/Forklift_Blue.png'
import forkliftGrey from '../../images/vehicle_icons/Forklift/Forklift_Grey.png'
import forkliftBlack from '../../images/vehicle_icons/Forklift/Forklift_Black.png'
import forkliftGreenOp from '../../images/vehicle_icons/Forklift/Forklift_Green_Op.png'
import forkliftRedOp from '../../images/vehicle_icons/Forklift/Forklift_Red_Op.png'
import forkliftOrangeOp from '../../images/vehicle_icons/Forklift/Forklift_Orange_Op.png'
import forkliftBlueOp from '../../images/vehicle_icons/Forklift/Forklift_Blue_Op.png'
import forkliftGreyOp from '../../images/vehicle_icons/Forklift/Forklift_Grey_Op.png'
import forkliftBlackOp from '../../images/vehicle_icons/Forklift/Forklift_Black_Op.png'
import pickupTruckGreen from '../../images/vehicle_icons/PickupTruck/PickupTruck_Green.png'
import pickupTruckRed from '../../images/vehicle_icons/PickupTruck/PickupTruck_Red.png'
import pickupTruckOrange from '../../images/vehicle_icons/PickupTruck/PickupTruck_Orange.png'
import pickupTruckBlue from '../../images/vehicle_icons/PickupTruck/PickupTruck_Blue.png'
import pickupTruckGrey from '../../images/vehicle_icons/PickupTruck/PickupTruck_Grey.png'
import pickupTruckBlack from '../../images/vehicle_icons/PickupTruck/PickupTruck_Black.png'
import pickupTruckGreenOp from '../../images/vehicle_icons/PickupTruck/PickupTruck_Green_Op.png'
import pickupTruckRedOp from '../../images/vehicle_icons/PickupTruck/PickupTruck_Red_Op.png'
import pickupTruckOrangeOp from '../../images/vehicle_icons/PickupTruck/PickupTruck_Orange_Op.png'
import pickupTruckBlueOp from '../../images/vehicle_icons/PickupTruck/PickupTruck_Blue_Op.png'
import pickupTruckGreyOp from '../../images/vehicle_icons/PickupTruck/PickupTruck_Grey_Op.png'
import pickupTruckBlackOp from '../../images/vehicle_icons/PickupTruck/PickupTruck_Black_Op.png'
import sideloaderGreen from '../../images/vehicle_icons/Sideloader/Sideloader_Green.png'
import sideloaderRed from '../../images/vehicle_icons/Sideloader/Sideloader_Red.png'
import sideloaderOrange from '../../images/vehicle_icons/Sideloader/Sideloader_Orange.png'
import sideloaderBlue from '../../images/vehicle_icons/Sideloader/Sideloader_Blue.png'
import sideloaderGrey from '../../images/vehicle_icons/Sideloader/Sideloader_Grey.png'
import sideloaderBlack from '../../images/vehicle_icons/Sideloader/Sideloader_Black.png'
import sideloaderGreenOp from '../../images/vehicle_icons/Sideloader/Sideloader_Green_Op.png'
import sideloaderRedOp from '../../images/vehicle_icons/Sideloader/Sideloader_Red_Op.png'
import sideloaderOrangeOp from '../../images/vehicle_icons/Sideloader/Sideloader_Orange_Op.png'
import sideloaderBlueOp from '../../images/vehicle_icons/Sideloader/Sideloader_Blue_Op.png'
import sideloaderGreyOp from '../../images/vehicle_icons/Sideloader/Sideloader_Grey_Op.png'
import sideloaderBlackOp from '../../images/vehicle_icons/Sideloader/Sideloader_Black_Op.png'
import trainGreen from '../../images/vehicle_icons/Train/Train_Green.png'
import trainRed from '../../images/vehicle_icons/Train/Train_Red.png'
import trainOrange from '../../images/vehicle_icons/Train/Train_Orange.png'
import trainBlue from '../../images/vehicle_icons/Train/Train_Blue.png'
import trainGrey from '../../images/vehicle_icons/Train/Train_Grey.png'
import trainBlack from '../../images/vehicle_icons/Train/Train_Black.png'
import trainGreenOp from '../../images/vehicle_icons/Train/Train_Green_Op.png'
import trainRedOp from '../../images/vehicle_icons/Train/Train_Red_Op.png'
import trainOrangeOp from '../../images/vehicle_icons/Train/Train_Orange_Op.png'
import trainBlueOp from '../../images/vehicle_icons/Train/Train_Blue_Op.png'
import trainGreyOp from '../../images/vehicle_icons/Train/Train_Grey_Op.png'
import trainBlackOp from '../../images/vehicle_icons/Train/Train_Black_Op.png'
import manager from '../../images/vehicle_icons/Manager/Manager.png'
import managerOp from '../../images/vehicle_icons/Manager/Manager_Op.png'

const vehicleImages = {
  hostler: {
    green: {
      normal: hostlerGreen,
      opaque: hostlerGreenOp,
    },
    red: {
      normal: hostlerRed,
      opaque: hostlerRedOp,
    },
    orange: {
      normal: hostlerOrange,
      opaque: hostlerOrangeOp,
    },
    royalblue: {
      normal: hostlerBlue,
      opaque: hostlerBlueOp,
    },
    black: {
      normal: hostlerBlack,
      opaque: hostlerBlackOp,
    },
    grey: {
      normal: hostlerGrey,
      opaque: hostlerGreyOp,
    },
  },
  crane: {
    green: {
      normal: craneGreen,
      opaque: craneGreenOp,
    },
    red: {
      normal: craneRed,
      opaque: craneRedOp,
    },
    orange: {
      normal: craneOrange,
      opaque: craneOrangeOp,
    },
    royalblue: {
      normal: craneBlue,
      opaque: craneBlueOp,
    },
    black: {
      normal: craneBlack,
      opaque: craneBlackOp,
    },
    grey: {
      normal: craneGrey,
      opaque: craneGreyOp,
    },
  },
  forklift: {
    green: {
      normal: forkliftGreen,
      opaque: forkliftGreenOp,
    },
    red: {
      normal: forkliftRed,
      opaque: forkliftRedOp,
    },
    orange: {
      normal: forkliftOrange,
      opaque: forkliftOrangeOp,
    },
    royalblue: {
      normal: forkliftBlue,
      opaque: forkliftBlueOp,
    },
    black: {
      normal: forkliftBlack,
      opaque: forkliftBlackOp,
    },
    grey: {
      normal: forkliftGrey,
      opaque: forkliftGreyOp,
    },
  },
  pickupTruck: {
    green: {
      normal: pickupTruckGreen,
      opaque: pickupTruckGreenOp,
    },
    red: {
      normal: pickupTruckRed,
      opaque: pickupTruckRedOp,
    },
    orange: {
      normal: pickupTruckOrange,
      opaque: pickupTruckOrangeOp,
    },
    royalblue: {
      normal: pickupTruckBlue,
      opaque: pickupTruckBlueOp,
    },
    black: {
      normal: pickupTruckBlack,
      opaque: pickupTruckBlackOp,
    },
    grey: {
      normal: pickupTruckGrey,
      opaque: pickupTruckGreyOp,
    },
  },
  lift: {
    green: {
      normal: sideloaderGreen,
      opaque: sideloaderGreenOp,
    },
    red: {
      normal: sideloaderRed,
      opaque: sideloaderRedOp,
    },
    orange: {
      normal: sideloaderOrange,
      opaque: sideloaderOrangeOp,
    },
    royalblue: {
      normal: sideloaderBlue,
      opaque: sideloaderBlueOp,
    },
    black: {
      normal: sideloaderBlack,
      opaque: sideloaderBlackOp,
    },
    grey: {
      normal: sideloaderGrey,
      opaque: sideloaderGreyOp,
    },
  },
  train: {
    green: {
      normal: trainGreen,
      opaque: trainGreenOp,
    },
    red: {
      normal: trainRed,
      opaque: trainRedOp,
    },
    orange: {
      normal: trainOrange,
      opaque: trainOrangeOp,
    },
    royalblue: {
      normal: trainBlue,
      opaque: trainBlueOp,
    },
    black: {
      normal: trainBlack,
      opaque: trainBlackOp,
    },
    grey: {
      normal: trainGrey,
      opaque: trainGreyOp,
    },
  },
  manager: {
    normal: manager,
    opaque: managerOp,
  },
}

const VehicleIconMap = ({
  vehicleTypeName,
  currentColor,
  vehicleData,
  lostCommunication,
  onIconClick,
}) => {
  const position = { lat: vehicleData.latitude, lng: vehicleData.longitude }
  const opacity = lostCommunication ? 'opaque' : 'normal'

  const options =
    vehicleTypeName === 'Manager'
      ? [vehicleTypeName.toLowerCase(), opacity]
      : [vehicleTypeName.toLowerCase(), currentColor, opacity]

  const vehicleImage = _.get(vehicleImages, options, '')

  switch (vehicleTypeName) {
    case 'Crane':
      return (
        <Marker
          title={vehicleData.Vehicle.vehicle_name}
          position={position}
          icon={{
            url: vehicleImage,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(4, 9.5),
            size: new window.google.maps.Size(9, 19),
            scaledSize: new window.google.maps.Size(9, 19),
          }}
          onClick={() => onIconClick(vehicleData)}
        />
      )
    default:
      return (
        <Marker
          title={vehicleData.Vehicle.vehicle_name}
          position={position}
          icon={{
            url: vehicleImage,
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(9, 9),
            size: new window.google.maps.Size(19, 19),
            scaledSize: new window.google.maps.Size(19, 19),
          }}
          onClick={() => onIconClick(vehicleData)}
        />
      )
  }
}

VehicleIconMap.propTypes = {
  vehicleTypeName: PropTypes.string.isRequired,
  currentColor: PropTypes.string.isRequired,
  lostCommunication: PropTypes.bool.isRequired,
  onIconClick: PropTypes.func.isRequired,
  vehicleData: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    Vehicle: PropTypes.shape({
      vehicle_name: PropTypes.string,
    }),
  }).isRequired,
}

export default VehicleIconMap
