import { connect } from 'react-redux'
import ManageVcrsContainer from './ManageVcrsContainer'
import { setVCRsSearch } from '../../actions/manageVCRsActions'

const mapStateToProps = (state, ownProps) => ({
  query: ownProps.location.query,
  filter: state.manageVCRs.vcrSearchFilter,
  pathname: ownProps.location.pathname,
})

const mapDispatchToProps = { setVCRsSearch }

export default connect(mapStateToProps, mapDispatchToProps)(ManageVcrsContainer)
