import { pick } from 'lodash'
import floorTimestamp from './floorTimestamp'

const pickPlaybackData = (datapoint) =>
  pick(datapoint, [
    'Vehicle',
    'device_id',
    'device_imei',
    'fixmillis',
    'id',
    'latitude',
    'longitude',
    'status',
    'user_id',
    'vcr_submission_id',
    'vehicle_id',
    'yard_id',
  ])

const formatHistoryData = (
  data,
  startTime,
  endTime,
  historyResolution,
  limit,
) => {
  // Getting ids from data and setting up to keep track of idx of data for each id
  const formattedData = {}
  const ids = Object.keys(data).map((id) => parseInt(id, 10))
  const currentIdxById = {}
  for (let i = 0; i < ids.length; i += 1) {
    const id = ids[i]
    currentIdxById[id] = 0
  }

  // Generating time series for time range
  const timeSeries = []
  let currentTime = floorTimestamp(startTime)
  const endTimeCondition = floorTimestamp(endTime)
  while (currentTime < endTimeCondition) {
    timeSeries.push(currentTime)
    currentTime += historyResolution
  }

  // Iterating over time series and inserting data where available
  try {
    for (let timeIdx = 0; timeIdx < timeSeries.length; timeIdx += 1) {
      const currentTimestamp = timeSeries[timeIdx]
      const dataPacket = {}

      for (let idIdx = 0; idIdx < ids.length; idIdx += 1) {
        const id = ids[idIdx]
        const allDataForId = data[id]
        const datapoint = data[id][currentIdxById[id]]
        const dataTimestamp = floorTimestamp(parseInt(datapoint.fixmillis, 10))

        let prevDatapoint = {}
        let prevDataTimestamp = 0
        if (currentIdxById[id] - 1 >= 0) {
          prevDatapoint = data[id][currentIdxById[id] - 1]
          prevDataTimestamp = floorTimestamp(
            parseInt(prevDatapoint.fixmillis, 10),
          )
        }

        if (dataTimestamp === currentTimestamp) {
          dataPacket[id] = pickPlaybackData(datapoint)
          if (currentIdxById[id] + 1 < allDataForId.length) {
            currentIdxById[id] += 1
          }
        } else if (prevDataTimestamp === 0) {
          dataPacket[id] = pickPlaybackData(datapoint)
        } else if (
          currentTimestamp - limit < prevDataTimestamp &&
          prevDataTimestamp < currentTimestamp
        ) {
          dataPacket[id] = pickPlaybackData(prevDatapoint)
        } else {
          dataPacket[id] = null
        }
      }
      formattedData[currentTimestamp] = dataPacket
    }
  } catch (err) {
    // FIXME Why didn't the fixmillis undefined error throw until I used a try catch?
    // Even though it was a critical error that ended execution
    // We can uncomment next line when trying to fix this issue
    // console.log(err)
  }

  const timeSeriesObject = {
    first: timeSeries[0],
    last: timeSeries[timeSeries.length - 1],
    size: timeSeries.length,
  }

  return { timeSeriesObject, formattedData }
}

export default formatHistoryData
