import _ from 'lodash'
import moment from 'moment-timezone'
import api from '../api'
import { createTypes, onlyLatestRequest } from './utils'
import parseFlagsFromServer from '../parsers/parseFlagsFromServer'
import parseMetricsFromServer from '../parsers/parseMetricsFromServer'

const getStartTime = (endTime, timezone, numberOfDays) =>
  moment.tz(endTime, timezone).subtract(numberOfDays, 'days').valueOf()

export const yardSummaryTypes = createTypes('yardSummary', [
  'SELECTED_DATE_SET',
  { value: 'NOTES', isAsync: true },
  { value: 'CREATE_NOTE', isAsync: true },
  { value: 'TAGS', isAsync: true },
  { value: 'YARD_TAGS', isAsync: true },
  { value: 'SELECT_TAG', isAsync: true },
  { value: 'DESELECT_TAG', isAsync: true },
  { value: 'ACTIVE_TIME_FROM_SESSIONS', isAsync: true },
  { value: 'ACTIVE_TIME_SUMMARY', isAsync: true },
  { value: 'YARD_SHIFTS_SUMMARY', isAsync: true },
  { value: 'LIFTS_COUNT', isAsync: true },
  { value: 'LIFTS_SUMMARY', isAsync: true },
  { value: 'YARD_FLAGS', isAsync: true },
  { value: 'YARD_METRICS', isAsync: true },
  { value: 'MOVES_COUNT', isAsync: true },
])

export const setYardSummarySelectedDate = (date) => ({
  type: yardSummaryTypes.SELECTED_DATE_SET,
  payload: date ? date.format('YYYY-MM-DD') : Date.now(),
})

const getLatestYardNotes = onlyLatestRequest(api.notes.getAll)

export const loadYardNotes = ({ yardId, date }) => (dispatch) => {
  dispatch({ type: yardSummaryTypes.NOTES_REQUESTED })

  return getLatestYardNotes({ yardId, date })
    .then((notes) => {
      dispatch({
        type: yardSummaryTypes.NOTES_LOADED,
        payload: {
          notes,
        },
      })
    })
    .catch(() => {
      dispatch({ type: yardSummaryTypes.NOTES_FAILED })
    })
}

export const createYardNote = ({ yardId, date, message }) => (
  dispatch,
  getState,
) => {
  const user = getState().general.userData

  dispatch({ type: yardSummaryTypes.CREATE_NOTE_REQUESTED })

  return api.notes
    .create({
      yardId,
      date,
      message,
      user,
    })
    .then((note) => {
      dispatch({
        type: yardSummaryTypes.CREATE_NOTE_LOADED,
        payload: {
          note,
        },
      })
    })
    .catch(() => {
      dispatch({ type: yardSummaryTypes.CREATE_NOTE_FAILED })
    })
}

const getLatestTags = onlyLatestRequest(api.tags.getAll)

export const loadTags = () => (dispatch) => {
  dispatch({ type: yardSummaryTypes.TAGS_REQUESTED })

  return getLatestTags({})
    .then((tags) => {
      dispatch({
        type: yardSummaryTypes.TAGS_LOADED,
        payload: {
          tags,
        },
      })
    })
    .catch(() => {
      dispatch({ type: yardSummaryTypes.TAGS_FAILED })
    })
}

const getLatestYardTags = onlyLatestRequest(api.tags.getAll)

export const loadYardTags = ({ yardId, date }) => (dispatch) => {
  dispatch({ type: yardSummaryTypes.YARD_TAGS_REQUESTED })

  return getLatestYardTags({ yardId, date })
    .then((data) => {
      const yardTags = data.map(({ tag_id: id }) => id)
      dispatch({
        type: yardSummaryTypes.YARD_TAGS_LOADED,
        payload: {
          yardTags,
        },
      })
    })
    .catch(() => {
      dispatch({ type: yardSummaryTypes.YARD_TAGS_FAILED })
    })
}

const selectTag = ({ yardId, date, id }) => (dispatch) => {
  dispatch({
    type: yardSummaryTypes.SELECT_TAG_REQUESTED,
    payload: { id },
  })

  return api.tags
    .selectTag({ yardId, date, id })
    .then(() => {
      dispatch({ type: yardSummaryTypes.SELECT_TAG_LOADED })
    })
    .catch(() => {
      dispatch({
        type: yardSummaryTypes.SELECT_TAG_FAILED,
        payload: { id },
      })
    })
}

const deselectTag = ({ yardId, date, id }) => (dispatch) => {
  dispatch({
    type: yardSummaryTypes.DESELECT_TAG_REQUESTED,
    payload: { id },
  })

  return api.tags
    .deselectTag({ yardId, date, id })
    .then(() => {
      dispatch({ type: yardSummaryTypes.DESELECT_TAG_LOADED })
    })
    .catch(() => {
      dispatch({
        type: yardSummaryTypes.DESELECT_TAG_FAILED,
        payload: { id },
      })
    })
}

export const toggleTag = ({ yardId, date, tag }) => (dispatch) => {
  const { id, selected } = tag
  if (selected) {
    return dispatch(deselectTag({ yardId, date, id }))
  }

  return dispatch(selectTag({ yardId, date, id }))
}

const getLastestActiveTimeSummary = onlyLatestRequest(
  api.sessions.getActiveTime,
)

const fetchSummary = ({ yardId, endTime, numberOfDays, timezone }) =>
  getLastestActiveTimeSummary({
    yardId,
    startTime: getStartTime(endTime, timezone, numberOfDays),
    endTime,
    bins: numberOfDays,
  }).then((data) =>
    data.bins.map((item, idx) => ({
      x: idx + 1,
      y: moment.duration(item.total).asHours(),
    })),
  )

export const loadActiveTimeSummary = (yardId, endTime, timezone) => (
  dispatch,
) => {
  dispatch({ type: yardSummaryTypes.ACTIVE_TIME_SUMMARY_REQUESTED })

  return fetchSummary({
    yardId,
    endTime,
    numberOfDays: 30,
    timezone,
  })
    .then((summary) => {
      dispatch({
        type: yardSummaryTypes.ACTIVE_TIME_SUMMARY_LOADED,
        payload: summary,
      })
    })
    .catch(() => {
      dispatch({ type: yardSummaryTypes.ACTIVE_TIME_SUMMARY_FAILED })
    })
}

const getLastestShiftsSummary = onlyLatestRequest(api.shifts.getByDay)

const fetchYardSummary = ({ yardId, date, numberOfDays }) =>
  getLastestShiftsSummary({
    yardId,
    date,
    numberOfDays,
  }).then((data) =>
    data.by_day.map(({ count, time }) => ({
      count,
      time: Math.floor(moment.duration(time).asHours()),
    })),
  )

export const loadYardShiftsSummary = (yardId, endTime, timezone) => (
  dispatch,
) => {
  dispatch({ type: yardSummaryTypes.YARD_SHIFTS_SUMMARY_REQUESTED })

  return fetchYardSummary({
    yardId,
    date: moment.tz(endTime, timezone).format('YYYY-MM-DD'),
    numberOfDays: 30,
  })
    .then((summary) => {
      dispatch({
        type: yardSummaryTypes.YARD_SHIFTS_SUMMARY_LOADED,
        payload: summary,
      })
    })
    .catch(() => {
      dispatch({ type: yardSummaryTypes.YARD_SHIFTS_SUMMARY_FAILED })
    })
}

const getLastestFlags = onlyLatestRequest(api.flags.getAll)

export const loadFlagsByYard = ({ yardId, startTime, endTime }) => (
  dispatch,
) => {
  dispatch({ type: yardSummaryTypes.YARD_FLAGS_REQUESTED })

  return getLastestFlags({
    yardId,
    startTime: startTime.valueOf(),
    endTime: endTime.valueOf(),
    type: ['FLAG', 'LIVE_FLAG'],
    isGoodFlag: false,
    name: 'idleTime',
  })
    .then((data) => {
      const parsedFlags = parseFlagsFromServer({ data, showOwnerName: true })

      dispatch({
        type: yardSummaryTypes.YARD_FLAGS_LOADED,
        payload: { flags: parsedFlags },
      })
    })
    .catch(() => {
      dispatch({ type: yardSummaryTypes.YARD_FLAGS_FAILED })
    })
}

const getLatestMetrics = onlyLatestRequest(api.metrics.getAll)

export const loadMetrics = ({ yardId, step, date }) => (dispatch) => {
  dispatch({ type: yardSummaryTypes.YARD_METRICS_REQUESTED })

  return getLatestMetrics({
    yardId,
    step,
    date: date.format('YYYY-MM-DD'),
  })
    .then((data) => {
      const parsedMetrics = parseMetricsFromServer({ data })

      dispatch({
        type: yardSummaryTypes.YARD_METRICS_LOADED,
        payload: { metrics: parsedMetrics },
      })
    })
    .catch(() => {
      dispatch({ type: yardSummaryTypes.YARD_METRICS_FAILED })
    })
}

const getLatestMovesByDay = onlyLatestRequest(api.moves.getByDay)

export const loadMovesByDay = ({ yardId, date, numberOfDays = 30 }) => (
  dispatch,
) => {
  dispatch({ type: yardSummaryTypes.LIFTS_COUNT_REQUESTED })
  dispatch({ type: yardSummaryTypes.MOVES_COUNT_REQUESTED })
  dispatch({ type: yardSummaryTypes.LIFTS_SUMMARY_REQUESTED })

  return getLatestMovesByDay({
    date,
    yardId,
    numberOfDays,
  })
    .then(({ by_day: byDay }) => {
      const liftsCountSummary = byDay.map(({ lpmhCount }) => lpmhCount)
      const last = _.last(byDay)
      const liftsCount = _.get(last, 'lpmhCount', 0)
      const movesCount = _.get(last, 'mpmhCount', 0)

      dispatch({
        type: yardSummaryTypes.LIFTS_SUMMARY_LOADED,
        payload: liftsCountSummary,
      })
      dispatch({
        type: yardSummaryTypes.LIFTS_COUNT_LOADED,
        payload: { count: liftsCount },
      })
      dispatch({
        type: yardSummaryTypes.MOVES_COUNT_LOADED,
        payload: { count: movesCount },
      })
    })
    .catch(() => {
      dispatch({ type: yardSummaryTypes.MOVES_COUNT_FAILED })
      dispatch({ type: yardSummaryTypes.LIFTS_SUMMARY_FAILED })
      dispatch({ type: yardSummaryTypes.LIFTS_COUNT_FAILED })
    })
}
