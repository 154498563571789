import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '../../../components/Tabs'
import { tabPropType } from '../../../components/Tabs/Tab/propTypes'
import './VcrVehicleTabs.css'

const VcrVehicleTabs = ({ tabs, tabsContent }) => (
  <div className="vcrVehicleTabs">
    <Tabs>
      <div className="vcrVehicleTabs__header">
        <div className="vcrVehicleTabs__tabs">
          <Tabs.Header tabs={tabs} />
        </div>
      </div>
      <div className="vcrVehiclesTabs__body">
        <Tabs.Body>
          {tabsContent.map((tabContent, index) => (
            <Tabs.Item key={tabs[index].title}>{tabContent}</Tabs.Item>
          ))}
        </Tabs.Body>
      </div>
    </Tabs>
  </div>
)

VcrVehicleTabs.propTypes = {
  tabs: tabPropType.isRequired,
  tabsContent: PropTypes.arrayOf(PropTypes.node).isRequired,
}

export default VcrVehicleTabs
