import _ from 'lodash'

function getEngineHoursTableRows({ rows }) {
  return _.chain(rows)
    .values()
    .map((row, index) => {
      const firstCompletedValue = _.chain(row)
        .get('values')
        .find((cell) => !_.isNil(cell))
        .get('value')
        .value()

      const lastCompletedValue = _.chain(row)
        .get('values')
        .findLast((cell) => !_.isNil(cell))
        .get('value')
        .value()

      const yearToDate =
        _.isNil(lastCompletedValue) || _.isNil(firstCompletedValue)
          ? null
          : lastCompletedValue - firstCompletedValue

      return {
        key: index,
        label: _.get(row, 'vehicle.vehicle_name', 'N/A'),
        vehicle_id: _.get(row, 'vehicle.id', 'N/A'),
        last_vcr: _.get(row, 'vcr_submission.engine_hours', 'N/A'),
        status: _.get(row, 'vehicle.status', null),
        year_to_date: _.isNil(yearToDate) ? '-' : yearToDate,
        engineHoursValues: row.values.map(
          (value) => value || { value: null, comment: null },
        ),
      }
    })
    .value()
}

export default getEngineHoursTableRows
