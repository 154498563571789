import steps from '../images/issues/steps.svg'
import handrail from '../images/issues/handrail.svg'
import catwalks from '../images/issues/catwalks.svg'
import airlines from '../images/issues/airlines.svg'
import gladhands from '../images/issues/gladhands.svg'
import doorLatches from '../images/issues/door_latches.svg'
import housekeeping from '../images/issues/housekeeping.svg'
import seat from '../images/issues/seat.svg'
import window from '../images/issues/window.svg'
import mirror from '../images/issues/mirror.svg'
import gauges from '../images/issues/gauges.svg'
import wipers from '../images/issues/wipers.svg'
import defrostFan from '../images/issues/defrost_fan.svg'
import extinguisher from '../images/issues/extinguisher.svg'
import ebrake from '../images/issues/ebrake.svg'
import dashLights from '../images/issues/dash_lights.svg'
import footPedals from '../images/issues/foot_pedals.svg'
import computer from '../images/issues/computer.svg'
import radio from '../images/issues/radio.svg'
import strobe from '../images/issues/strobe.svg'
import headLights from '../images/issues/head_lights.svg'
import exhaust from '../images/issues/exhaust.svg'

import tires from '../images/issues/tires.svg'
import mudFlaps from '../images/issues/mud_flaps.svg'
import fender from '../images/issues/fender.svg'
import tailLights from '../images/issues/tail_lights.svg'
import hydraulicTank from '../images/issues/hydraulic_tank.svg'
import fluidLevel from '../images/issues/fluid_level.svg'
import fluidTank from '../images/issues/fluid_tank.svg'

import seatAdjust from '../images/issues/seat_adjust.svg'
import horn from '../images/issues/horn.svg'
import fifthLever from '../images/issues/fifth_lever.svg'
import fifthWheel from '../images/issues/fifth_wheel.svg'
import lowAir from '../images/issues/low_air.svg'
import seatBelt from '../images/issues/seat_belt.svg'
import gear from '../images/issues/gear.svg'
import backupAlarm from '../images/issues/backup_alarm.svg'
import sterring from '../images/issues/sterring.svg'
import brakes from '../images/issues/brakes.svg'

import defaultIcon from '../images/issues/default_icon.svg'

export default {
  0: steps,
  1: handrail,
  2: catwalks,
  3: airlines,
  4: gladhands,
  5: doorLatches,
  6: housekeeping,
  7: seat,
  8: window,
  9: mirror,
  10: gauges,
  11: wipers,
  12: defrostFan,
  13: extinguisher,
  14: ebrake,
  15: dashLights,
  16: footPedals,
  17: computer,
  18: radio,
  19: strobe,
  20: headLights,
  21: exhaust,
  22: null, // air tank missing
  23: tires,
  24: mudFlaps,
  25: fender,
  26: tailLights,
  27: hydraulicTank,
  28: fluidLevel,
  29: fluidTank,
  30: null, // Any Leaking Fluids missing
  31: seatAdjust,
  32: horn,
  33: fifthLever,
  34: fifthWheel,
  35: lowAir,
  36: seatBelt,
  37: gear,
  38: backupAlarm,
  39: sterring,
  40: brakes,
  41: null, // iDrive missing
  42: null, // iDrive missing
  43: null, // iDrive missing
  44: null, // iDrive missing
  45: null, // iDrive missing
  46: null, // other missing
  defaultIcon,
}
