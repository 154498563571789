import { trackingTypes } from '../../actions/trackingActions'

const initialState = {
  loading: false,
  value: null,
}

const lastActivity = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.LAST_ACTIVITY_REQUESTED:
      return {
        ...state,
        ...initialState,
        loading: true,
      }

    case trackingTypes.LAST_ACTIVITY_LOADED:
      if (payload.deviceId === payload.infoBoxDeviceId) {
        return {
          ...state,
          ...initialState,
          value: payload.data,
        }
      }
      return state

    case trackingTypes.LAST_ACTIVITY_FAILED:
    case trackingTypes.LAST_ACTIVITY_CLEARED:
      return initialState

    default:
      return state
  }
}

export default lastActivity
