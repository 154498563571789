import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import appPropTypes from '../../../appPropTypes'
import ChartGroup from './ChartGroup'
import trophyIcon from '../../../images/icons/trophy.svg'
import timeIcon from '../../../images/icons/time-yellow.svg'
import flagIcon from '../../../images/icons/flag-red.svg'
import boxIcon from '../../../images/icons/box-green.svg'

class WorkerCharts extends Component {
  static propTypes = {
    startTime: PropTypes.instanceOf(moment).isRequired,
    endTime: PropTypes.instanceOf(moment).isRequired,
    userId: PropTypes.number.isRequired,
    yardId: PropTypes.number.isRequired,
    loadVehicleTimeMetrics: PropTypes.func.isRequired,
    vehicleTimeMetrics: appPropTypes.asyncOf({
      bins: PropTypes.arrayOf({
        yValue: PropTypes.number.isRequired,
      }),
      average: PropTypes.number.isRequired,
    }).isRequired,
    loadBoxCount: PropTypes.func.isRequired,
    boxCount: appPropTypes.asyncOf({
      bins: PropTypes.arrayOf({
        yValue: PropTypes.number.isRequired,
      }),
      average: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
    }).isRequired,
    flagsCount: appPropTypes.asyncOf({
      bins: PropTypes.arrayOf({
        yValue: PropTypes.number.isRequired,
      }),
      average: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
    }).isRequired,
    selectedDate: PropTypes.instanceOf(moment).isRequired,
  }

  componentDidMount() {
    this.loadChartsData()
  }

  componentDidUpdate(prevProps) {
    const { startTime, endTime } = this.props

    if (
      !prevProps.startTime.isSame(startTime) ||
      !prevProps.endTime.isSame(endTime)
    ) {
      this.loadChartsData()
    }
  }

  loadChartsData() {
    const {
      userId,
      yardId,
      startTime,
      endTime,
      loadVehicleTimeMetrics,
      loadBoxCount,
    } = this.props

    loadBoxCount({
      userId,
      yardId,
      startTime,
      endTime,
    })
    loadVehicleTimeMetrics({ userId, startTime, endTime })
  }

  render() {
    const {
      startTime,
      endTime,
      vehicleTimeMetrics,
      boxCount,
      flagsCount,
      selectedDate,
    } = this.props

    return (
      <div className="workerCharts">
        <div className="workerCharts-header">
          <div className="workerCharts-header-title">
            <img src={trophyIcon} alt="trophy" />
            <h1>Individual Worker Metrics</h1>
          </div>
          <span>Past 30 days</span>
        </div>
        <div className="workerCharts-content">
          <ChartGroup
            selectedDate={selectedDate}
            list={[
              {
                icon: boxIcon,
                title: 'BOX COUNT',
                startTime,
                endTime,
                loading: boxCount.loading,
                data: boxCount.value.bins,
                lineValue: boxCount.value.average,
                lineType: 'AVG',
              },
              {
                icon: timeIcon,
                title: 'EQUIPMENT OR VEHICLE TIME',
                startTime,
                endTime,
                loading: vehicleTimeMetrics.loading,
                data: vehicleTimeMetrics.value.bins,
                lineValue: vehicleTimeMetrics.value.average,
                lineType: 'AVG',
                unit: '%',
              },
              {
                icon: flagIcon,
                title: 'FLAGS COUNT',
                startTime,
                endTime,
                loading: flagsCount.loading,
                data: flagsCount.value.bins,
                lineValue: flagsCount.value.average,
                lineType: 'AVG',
                unit: '',
              },
            ]}
          />
        </div>
      </div>
    )
  }
}

export default WorkerCharts
