import React, { useState } from 'react'
import PropTypes from 'prop-types'

import iconArrow from '../../images/icons/icon_arrow.svg'

import * as S from './styles'

const ToggleContainer = ({ title, children, onScroll }) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <S.Container>
      <S.Header>
        <S.Title>{title}</S.Title>
        <S.Toggle onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? 'Hide' : 'Show'}
          <S.Icon src={iconArrow} isOpen={isOpen} />
        </S.Toggle>
      </S.Header>

      <S.Content onScroll={onScroll} isOpen={isOpen}>
        {children}
      </S.Content>
    </S.Container>
  )
}

ToggleContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onScroll: PropTypes.func.isRequired,
}

export default ToggleContainer
