import { connect } from 'react-redux'
import UpsertBillingElementType from './UpsertBillingElementType'

import { showAlert, showErrorAlert } from '../../actions/alertMessageActions'
import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state, ownProps) => ({
  timezone: getYardTimeZone(state),
  yardId: ownProps.params.yard_id,
  elementType: ownProps.params.billing_element_type,
  billingElementId: ownProps.params.billing_element_id,
  returnDate: ownProps.location.query.return_date,
  selectedYard: state.general.selectedYard,
})

const mapDispatchToProps = {
  showAlert,
  showErrorAlert,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpsertBillingElementType)
