import React, { Component } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment-timezone'
import flagType from './propTypes'
import { history } from '../../utils'
import { NewContainer, NewHeader } from '../../components'
import { beginningOfDay, getTimespan } from '../../utils/momentExtensions'
import MetricsCards from './MetricsCards'
import WorkerCharts from './WorkerCharts'
import SelectedDayCard from '../../components/SelectedDayCard'

const numberOfDays = 30

class WorkerProfile extends Component {
  static propTypes = {
    userId: PropTypes.number.isRequired,
    params: PropTypes.shape({
      start_date: PropTypes.string.isRequired,
    }).isRequired,
    selectedDate: PropTypes.instanceOf(moment).isRequired,
    timezone: PropTypes.string.isRequired,
    setSelectedDate: PropTypes.func.isRequired,
    flags: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      value: PropTypes.arrayOf(flagType).isRequired,
    }).isRequired,
    loadFlagsByUser: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    user: PropTypes.shape({
      shortName: PropTypes.string.isRequired,
    }).isRequired,
    yardId: PropTypes.number.isRequired,
    loadFlagsCountByUser: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      userId,
      loadUser,
      setSelectedDate,
      params: { start_date: startDateParam },
    } = this.props

    const startDate = moment(startDateParam)
    setSelectedDate(startDate)

    loadUser(userId)
    this.loadFlags()
    this.loadFlagsCount()
  }

  componentDidUpdate(prevProps) {
    const { userId, selectedDate, yardId } = this.props

    if (
      userId !== prevProps.userId ||
      !selectedDate.isSame(prevProps.selectedDate)
    ) {
      this.loadFlags()
      this.loadFlagsCount()
    }

    if (prevProps.yardId !== yardId) {
      history.push('/users')
    }
  }

  handleDateChange = ({ startDate }) => {
    const { setSelectedDate, timezone } = this.props
    const startOfDay = beginningOfDay(startDate, timezone)
    setSelectedDate(startOfDay)
    history.changePathVariable({ name: 'date', value: startOfDay.format() })
  }

  loadFlagsCount() {
    const { userId, selectedDate, loadFlagsCountByUser, timezone } = this.props

    const { startTime, endTime } = getTimespan({
      selectedDate,
      timezone,
      numberOfDays,
    })

    loadFlagsCountByUser({
      userId,
      startTime,
      endTime,
    })
  }

  loadFlags() {
    const { userId, timezone, selectedDate, loadFlagsByUser } = this.props

    const startTime = beginningOfDay(selectedDate, timezone)
    const endTime = moment(startTime).add(1, 'days').subtract(1, 'milliseconds')

    loadFlagsByUser({ userId, startTime, endTime })
  }

  render() {
    const { user, userId, yardId, selectedDate, timezone, flags } = this.props

    const { startTime, endTime } = getTimespan({
      selectedDate,
      timezone,
      numberOfDays,
    })
    const date = {
      timezone,
      startDate: selectedDate,
      onChange: this.handleDateChange,
    }

    return (
      <>
        <NewHeader
          title={`${user.shortName} past 30 days`}
          returnText="Workers list"
          returnUrl={`/yard/${yardId}/users`}
          date={date}
        />
        <NewContainer className="workerProfile">
          <hr className="pageRuler" />
          <div className="workerProfile-subtitle">
            ** Values below are for a 30 day average
          </div>
          <div className="workerProfile-content">
            <MetricsCards numberOfDays={numberOfDays} />
            <div className="workerProfile-content-columns">
              <WorkerCharts
                startTime={startTime}
                endTime={endTime}
                userId={userId}
                yardId={yardId}
                selectedDate={selectedDate}
              />
              <SelectedDayCard
                selectedDate={selectedDate}
                timezone={timezone}
                flags={flags}
                flagsTitle="Worker Flags"
                isReadOnly
              />
            </div>
          </div>
        </NewContainer>
      </>
    )
  }
}

export default WorkerProfile
