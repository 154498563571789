import React, { Component, Fragment } from 'react'
import cx from 'classnames'
import { cardItemType } from './propTypes'
import Loading from '../Loading'
import NewToolTip from '../NewToolTip'

class CardItem extends Component {
  renderItem() {
    const { loading, icon, iconBackground, title, value } = this.props

    if (loading) {
      return <Loading />
    }

    const className = cx('cardItem-icon', iconBackground)

    return (
      <Fragment>
        {icon && (
          <div className={className}>
            <img src={icon} alt="icon" />
          </div>
        )}
        <div className="cardItem-content">
          {title !== undefined && <div className="cardItem-title">{title}</div>}
          {value !== undefined && <div className="cardItem-value">{value}</div>}
        </div>
      </Fragment>
    )
  }

  render() {
    const { title, tooltipDescription } = this.props

    return (
      <NewToolTip title={title} description={tooltipDescription}>
        <div className="cardItem">{this.renderItem()}</div>
      </NewToolTip>
    )
  }
}

CardItem.propTypes = cardItemType

export default CardItem
