import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { Na, Gallery } from '../../components'

import VehicleStatusSelector from '../ManageVehicles/VehicleStatusSelector'

import { getDateTime } from '../../utils/momentExtensions'
import { getShortName } from '../../utils'

import * as S from './styles'

import iconBatteryWithFullCharge from '../../images/assets/battery.svg'
import iconBatteryWithLowCharge from '../../images/assets/low_battery.svg'

const generalInfoTableHeaders = {
  lastcomms: 'LAST COMMS',
  full_name: 'NAME',
  name: 'TYPE',
  engine_hours: 'E.H',
  version: 'S.VERSION',
  battery: 'BATTERY',
  status: 'STATUS',
}

const VehicleDetailsInfo = ({
  deviceData,
  vehicleData,
  timezone,
  hasWriteAccess,
}) => {
  const vehicleInfo = _.get(vehicleData, 'vehicle')

  const renderBatteryIcon = ({ deviceDataItem }) => {
    if (_.isNil(deviceDataItem)) {
      return <Na />
    }

    const batteryValue = _.get(deviceDataItem, 'battery')
    const batteryIcon =
      batteryValue < 0 ? iconBatteryWithLowCharge : iconBatteryWithFullCharge

    return (
      <>
        <S.Battery src={batteryIcon} />
        {batteryValue >= 0 && `${batteryValue}%`}
      </>
    )
  }

  function getGeneralInfoTableContent() {
    const deviceDataItem = _.get(deviceData, 'value', []).filter(
      (item) => item.vehicle_id === vehicleInfo.id,
    )[0]

    const firstName = _.get(vehicleInfo, 'first_name')
    const lastName = _.get(vehicleInfo, 'last_name')
    const lastComms = _.get(deviceDataItem, 'lastcomms')

    return {
      ...vehicleInfo,
      lastcomms: deviceDataItem ? getDateTime(lastComms, timezone) : <Na />,
      full_name: firstName ? getShortName({ firstName, lastName }) : <Na />,
      version: _.get(deviceDataItem, 'version', <Na />),
      battery: renderBatteryIcon({ deviceDataItem }),
      status: (
        <VehicleStatusSelector
          data={vehicleInfo}
          isDisabled={!hasWriteAccess}
        />
      ),
    }
  }

  return (
    <S.GeneralInfoTable>
      <S.InfoTable>
        {Object.keys(generalInfoTableHeaders).map((item) => (
          <div key={item}>
            <S.Header>{generalInfoTableHeaders[item]}</S.Header>
            <S.Content>{getGeneralInfoTableContent()[item]}</S.Content>
          </div>
        ))}
      </S.InfoTable>

      <S.Gallery>
        <Gallery
          data={{
            touches: _.get(vehicleInfo, 'vcr_submission_touches'),
            vehicleType_id: _.get(vehicleInfo, 'vehicle_type_id'),
          }}
        />
      </S.Gallery>
    </S.GeneralInfoTable>
  )
}

VehicleDetailsInfo.propTypes = {
  deviceData: PropTypes.shape({
    loading: PropTypes.bool,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        vehicle_id: PropTypes.number,
      }),
    ),
  }).isRequired,
  vehicleData: PropTypes.shape({
    vehicle: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  timezone: PropTypes.string.isRequired,
  hasWriteAccess: PropTypes.bool.isRequired,
}

export default VehicleDetailsInfo
