import styled from 'styled-components/macro'
import { sidebarWidth, headerHeight } from '../../config'

export const Container = styled.section`
  transition: all 0.6s;
  display: flex;
  flex-direction: column;
  height: calc(100% - ${headerHeight}px);
  width: calc(100vw - ${sidebarWidth}px);
  padding: 15px;
  background-color: var(--lighter-neutral-96);
  @media (max-width: 1023px) {
    width: 100%;
    overflow: auto;
  }
`

export default Container
