export const TABLE_HEADERS = [
  { key: 0, text: 'Template Name' },
  { key: 1, text: 'Start Date' },
  { key: 2, text: 'End Date' },
  { key: 3, text: 'Last Modified' },
  { key: 4, text: 'Creator' },
  { key: 5, text: 'Deleted At' },
  { key: 6, text: 'Billing Info' },
  { key: 7, text: 'Actions' },
]
