import { generalTypes } from '../actions/generalActions'

const general = (
  state = {
    authenticated: null,
    loginError: false,
    userData: {},
    yardList: [],
    allYardList: [],
    selectedYard: '-',
    isSidebarCollapsed: false,
  },
  action,
) => {
  switch (action.type) {
    case generalTypes.LOGIN_SUCCEEDED:
      localStorage.setItem('token', action.payload)
      return {
        ...state,
        authenticated: true,
        loginError: null,
      }

    case generalTypes.LOGIN_FAILED:
      return {
        ...state,
        authenticated: false,
      }

    case generalTypes.LOGIN_REQUEST_ERROR:
      return {
        ...state,
        loginError: action.payload.message,
      }

    case generalTypes.DATA_LOADED:
      return {
        ...state,
        userData: action.payload,
      }

    case generalTypes.YARD_LIST_LOADED: {
      const { yardListForUser, allYardList } = action.payload
      return {
        ...state,
        yardList: yardListForUser,
        allYardList,
      }
    }

    case generalTypes.YARD_LIST_RESET: {
      return {
        ...state,
        yardList: [],
      }
    }

    case generalTypes.YARD_SELECTED: {
      const selectedYard = action.payload

      return {
        ...state,
        selectedYard,
      }
    }

    case generalTypes.COLLAPSE_TOGGLED:
      return {
        ...state,
        isSidebarCollapsed: !state.isSidebarCollapsed,
      }

    default:
      return state
  }
}

export default general
