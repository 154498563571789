import { isEmpty } from 'ramda'

export default function scrollBottom(elementId) {
  if (isEmpty(elementId)) {
    return null
  }

  return setTimeout(() => {
    document
      .getElementById(elementId)
      .scroll(0, document.getElementById(elementId).scrollHeight)
  }, 100)
}
