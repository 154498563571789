import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Visibility from 'visibilityjs'
import { Polygon } from '@react-google-maps/api'
import moment from 'moment-timezone'
import _ from 'lodash'

import { history } from '../../utils'
import { showZones } from '../../config'
import createInterval from '../../utils/createInterval'
import appPropTypes from '../../appPropTypes'

import { Toggle } from '../../components'
import Map from '../Map'
import DevicesPanel from './DevicesPanel'
import PlayBackControls from './PlayBackControls'
import LiveViewMarkers from './LiveViewMarkers'
import HistoryViewMarkers from './HistoryViewMarkers'

import './Tracking.css'

class Tracking extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0,
      width: 0,
    }
  }

  // FIXME stop using componentWillMount
  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillMount() {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    })
  }

  componentDidMount() {
    const {
      selectedYard,
      loadUsers,
      getZones,
      loadDeviceSessions,
      urlViewMode,
    } = this.props

    this.setViewModeFromUrl()

    this.trackingInterval = createInterval()
    this.liveFlagsInterval = createInterval()

    if (urlViewMode === 'live') {
      loadUsers(selectedYard).then(() => {
        this.setTracking()
      })

      this.watchLiveFlags(selectedYard)
      getZones(selectedYard)
      loadDeviceSessions(selectedYard)
    }

    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentDidUpdate(prevProps) {
    const {
      selectedYard,
      loadUsers,
      viewMode,
      trackingDataForHistoryTime,
      setIconPinned,
      userId,
      vehicleId,
      urlViewMode,
      loadDeviceSessions,
      getZones,
    } = this.props
    const { trackingInterval, liveFlagsInterval } = this

    if (prevProps.selectedYard !== selectedYard) {
      loadUsers(selectedYard).then(() => {
        if (viewMode === 'live' && selectedYard !== '-') {
          this.setTracking()
          this.watchLiveFlags(selectedYard)
          getZones(selectedYard)
          loadDeviceSessions(selectedYard)
        }
      })
    }

    if (prevProps.viewMode === 'history' && viewMode === 'live') {
      this.setTracking()
      this.watchLiveFlags(selectedYard)
    }

    if (viewMode !== 'live' && viewMode !== prevProps.viewMode) {
      trackingInterval.clear()
      liveFlagsInterval.clear()
    }

    if (urlViewMode !== prevProps.urlViewMode) {
      this.setViewModeFromUrl()
    }

    if (
      (!Number.isNaN(userId) || !Number.isNaN(vehicleId)) &&
      prevProps.trackingDataForHistoryTime !== trackingDataForHistoryTime
    ) {
      const positionData = this.getPinnedPositionData()

      const newIconPinned = !_.isNil(positionData)
        ? positionData.vehicle_id
        : null
      setIconPinned(newIconPinned)
    }
  }

  componentWillUnmount() {
    const { trackingInterval, liveFlagsInterval } = this

    const { resetTracking, clearHistory, setHistoryPlay } = this.props

    resetTracking()
    clearHistory()
    setHistoryPlay(false)
    trackingInterval.kill()
    liveFlagsInterval.kill()

    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  setViewModeFromUrl() {
    const {
      setViewMode,
      time,
      urlViewMode,
      setCurrentHistoryTime,
      setHistoryRange,
    } = this.props

    if (urlViewMode === 'history') {
      setViewMode(false)
      if (Number.isNaN(time)) {
        this.setDefaultSlider()
      } else {
        const min = moment(time).subtract(2, 'hours').valueOf()
        const max = _.min([moment(time).add(2, 'hours').valueOf(), Date.now()])
        setCurrentHistoryTime(time)
        setHistoryRange({ min, max })
      }
    } else {
      setViewMode(true)
    }
  }

  setDefaultSlider() {
    const { setCurrentHistoryTime, setHistoryRange } = this.props

    const min = moment().subtract(3, 'days').valueOf()
    const max = Date.now()
    const time = (min + max) / 2
    setCurrentHistoryTime(time)
    setHistoryRange({ min, max })

    return { time }
  }

  getPinnedPositionData() {
    const {
      trackingDataForHistoryTime,
      userId,
      vehicleId,
      iconPinned,
    } = this.props
    const filteredPositionData = trackingDataForHistoryTime.filter(
      (data) =>
        data.user_id === userId ||
        data.vehicle_id === vehicleId ||
        data.vehicle_id === iconPinned,
    )

    if (filteredPositionData.length > 1) {
      return _.find(
        filteredPositionData,
        (data) => data.vehicle_id === iconPinned,
      )
    }

    return filteredPositionData[0]
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    })
  }

  setTracking = () => {
    const { loadTracking, loadVehicles, selectedYard, setTracking } = this.props
    const { trackingInterval } = this

    loadVehicles(selectedYard)
    loadTracking(selectedYard)

    trackingInterval.set(1000, () => {
      if (Visibility.state() === 'visible') {
        loadTracking(selectedYard)
      } else {
        setTracking([])
      }
    })
  }

  formatZonesData = (value) =>
    value.map((item) =>
      item.map((element) => ({
        lat: element[1],
        lng: element[0],
      })),
    )

  changeToggle = (toggle) => {
    const { setViewMode } = this.props
    setViewMode(toggle)
    const { time } = this.setDefaultSlider()

    const updatedLocation = history.getUpdatedLocation({
      name: 'mode',
      value: toggle ? 'live' : 'history',
    })
    const { pathname } = updatedLocation

    const withoutTimePath = history.removeTail({ name: 'mode', pathname })
    const newPath = toggle ? withoutTimePath : `${withoutTimePath}/time/${time}`

    history.push(newPath)
  }

  watchLiveFlags(yardId) {
    const { loadLiveFlags } = this.props
    const { liveFlagsInterval } = this

    const getStartTime = () => moment().subtract(8, 'hours').valueOf()

    loadLiveFlags({ yardId, startTime: getStartTime() })

    liveFlagsInterval.set(60000 * 5, () => {
      loadLiveFlags({ yardId, startTime: getStartTime() })
    })
  }

  renderZones = () => {
    if (!showZones) {
      return null
    }
    const {
      zones: { value: zonesValue },
      selectedYard,
    } = this.props

    const zonesByYard = zonesValue.filter(
      (zone) => zone.yard_id === selectedYard,
    )
    const option = {
      fillColor: '#1B4EFF',
      fillOpacity: 0.3,
      strokeColor: '#1B4EFF',
      strokeOpacity: 1,
      strokeWeight: 1,
    }

    return zonesByYard.map((item) => (
      <Polygon
        key={item.id}
        paths={this.formatZonesData(item.polygon.coordinates)}
        options={option}
      />
    ))
  }

  render() {
    const { viewMode, liveFlags } = this.props

    return (
      <div className="trackingPageWrapper">
        <Map>
          {this.renderZones()}
          {viewMode === 'live' ? <LiveViewMarkers /> : <HistoryViewMarkers />}
        </Map>

        <Toggle
          leftText="Live"
          rightText="Playback"
          value={viewMode === 'history'}
          onChange={this.changeToggle}
          toolTipTitle="Live or previous events on the map"
          tooltipDescription="To see live events, click on “live,” to see previous events, click on “playback”"
        />
        <div className="boxes-container">
          <DevicesPanel flags={liveFlags} />
        </div>
        <PlayBackControls />
      </div>
    )
  }
}

Tracking.propTypes = {
  setViewMode: PropTypes.func,
  selectedYard: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loadUsers: PropTypes.func,
  viewMode: PropTypes.string,
  loadTracking: PropTypes.func,
  setCurrentHistoryTime: PropTypes.func.isRequired,
  getZones: PropTypes.func.isRequired,
  zones: PropTypes.shape({
    value: PropTypes.array,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  loadVehicles: PropTypes.func.isRequired,
  loadLiveFlags: PropTypes.func.isRequired,
  liveFlags: appPropTypes.asyncOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        isGood: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        ownerType: PropTypes.string.isRequired,
      }),
    ),
  ).isRequired,
  userId: PropTypes.number.isRequired,
  vehicleId: PropTypes.number.isRequired,
  time: PropTypes.number.isRequired,
  urlViewMode: PropTypes.string.isRequired,
  trackingDataForHistoryTime: PropTypes.arrayOf(
    PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
      vehicle_id: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      user_id: PropTypes.number,
      vcr_submission_id: PropTypes.number,
    }),
  ).isRequired,
  setIconPinned: PropTypes.func.isRequired,
  iconPinned: PropTypes.number,
  setHistoryRange: PropTypes.func.isRequired,
  setTracking: PropTypes.func.isRequired,
  loadDeviceSessions: PropTypes.func.isRequired,
  resetTracking: PropTypes.func.isRequired,
  clearHistory: PropTypes.func.isRequired,
  setHistoryPlay: PropTypes.func.isRequired,
}

Tracking.defaultProps = {
  setViewMode: () => undefined,
  selectedYard: 0,
  loadUsers: () => undefined,
  viewMode: '',
  loadTracking: () => undefined,
  iconPinned: undefined,
}

export default Tracking
