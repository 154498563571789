import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'
import NewToolTip from '../NewToolTip'
import defaultRangeOptions from './defaultRangeOptions'

import IconDate from '../../images/icons/icon_calendar.svg'
import IconDateWhite from '../../images/icons/icon_calendar_white.svg'
import dateIcon from '../../images/tooltip/date.svg'

import * as S from './styles'

const monthPickerMinDate = '2000-01-01'

function getIsNextButtonClickDisabled({
  timezone,
  range,
  startDate,
  endDate,
  maxStartDate,
  maxEndDate,
  currentDate,
}) {
  if (range === 'month') {
    const isSameMonth = moment
      .tz(startDate, timezone)
      .isSame(currentDate, 'month')
    return moment
      .tz(startDate, timezone)
      .add(1, isSameMonth ? range : 'day')
      .isAfter(currentDate)
  }

  if (_.isNil(endDate)) {
    return moment.tz(startDate, timezone).add(1, range).isAfter(maxStartDate)
  }

  return moment.tz(endDate, timezone).add(1, range).isAfter(maxEndDate)
}

const NewDatePicker = ({
  timezone,
  startDate,
  endDate,
  onChange,
  untilYesterday,
  selectedRange,
  rangeOptions,
  readOnly,
  monthPicker,
  currentDate,
  darkMode,
  yearPicker,
}) => {
  const options = _.isEmpty(rangeOptions) ? defaultRangeOptions : rangeOptions

  const [selectValue, setSelectValue] = useState(
    selectedRange ? _.find(options, { range: selectedRange }) : _.head(options),
  )

  const range = monthPicker ? 'month' : selectValue.range

  const maxEndDate = untilYesterday
    ? moment.tz(timezone).subtract(1, 'day').endOf('day')
    : moment.tz(timezone).endOf('day')

  const maxStartDate = moment
    .tz(_.isNil(endDate) ? maxEndDate : endDate, timezone)
    .startOf('day')

  const isNextButtonClickDisabled = getIsNextButtonClickDisabled({
    timezone,
    range,
    startDate,
    endDate,
    maxStartDate,
    maxEndDate,
    currentDate,
  })

  const handleChangeEndDate = (date) => {
    onChange({ startDate, endDate: date })
  }

  const handleChangeStartDate = (date) => {
    onChange({ startDate: date, endDate })
  }

  const handleLeftButtonClick = () => {
    onChange({
      startDate: moment
        .tz(startDate, timezone)
        .subtract(1, range)
        .startOf('day'),
      endDate: moment.tz(endDate, timezone).subtract(1, range).endOf('day'),
    })
  }

  const handleRightButtonClick = () => {
    const nextStartDate = moment
      .tz(startDate, timezone)
      .add(1, yearPicker ? 'year' : range)
      .startOf('day')
    if (
      range === 'month' &&
      moment.tz(nextStartDate, timezone).add(1, 'day').isAfter(currentDate)
    ) {
      onChange({
        startDate: currentDate,
        endDate: moment.tz(endDate, timezone).add(1, range).endOf('day'),
      })
    } else {
      onChange({
        startDate: nextStartDate,
        endDate: moment.tz(endDate, timezone).add(1, range).endOf('day'),
      })
    }
  }

  const handleRangeChange = (option) => {
    onChange({
      startDate: moment
        .tz(maxEndDate, timezone)
        .subtract(1, option.range)
        .add(1, 'day')
        .startOf('day'),
      endDate: moment.tz(maxEndDate, timezone),
      range: option.range,
    })
    setSelectValue(option)
  }

  const getDateFormat = () => {
    if (yearPicker) {
      return 'yyyy'
    }

    if (monthPicker) {
      return 'MMMM/yyyy'
    }

    return 'MM/dd/yyyy'
  }

  const todayButton = untilYesterday
    ? `Reset to today – ${moment().tz(timezone).format('MM/DD/YYYY')}`
    : undefined

  const popperModifiers = {
    preventOverflow: {
      enabled: true,
      escapeWithReference: false,
      boundariesElement: 'viewport',
    },
  }

  return (
    <S.Container readOnly={readOnly}>
      {!readOnly && (
        <NewToolTip
          title="Datepicker control"
          titleIcon={dateIcon}
          action="Go back for the given date range"
        >
          <S.LeftButton isDarkMode={darkMode} onClick={handleLeftButtonClick} />
        </NewToolTip>
      )}

      <S.DatePickerWrapper isDarkMode={darkMode}>
        <S.DatePrefix>{endDate ? 'From:' : 'Date:'}</S.DatePrefix>
        <S.StyledReactDatePickerForMoment
          timezone={timezone}
          minDate={moment.tz(monthPickerMinDate, timezone)}
          maxDate={currentDate || maxStartDate}
          selected={startDate}
          onChange={handleChangeStartDate}
          todayButton={todayButton}
          popperModifiers={popperModifiers}
          dateFormat={getDateFormat()}
          showMonthYearPicker={monthPicker}
          isDarkMode={darkMode}
          showYearPicker={yearPicker}
        />
        <S.DateIcon src={darkMode ? IconDateWhite : IconDate} />
      </S.DatePickerWrapper>

      {endDate && endDate.isValid() && (
        <S.DatePickerWrapper isDarkMode={darkMode}>
          <S.DatePrefix>To:</S.DatePrefix>
          <S.StyledReactDatePickerForMoment
            timezone={timezone}
            maxDate={maxEndDate}
            minDate={startDate}
            selected={endDate}
            onChange={handleChangeEndDate}
            todayButton={todayButton}
            popperModifiers={popperModifiers}
            isDarkMode={darkMode}
          />
          <S.DateIcon src={darkMode ? IconDateWhite : IconDate} />
        </S.DatePickerWrapper>
      )}

      {!readOnly && (endDate || selectedRange || !_.isEmpty(rangeOptions)) && (
        <S.StyledSelect
          isDarkMode={darkMode}
          options={options}
          value={selectValue}
          onChange={handleRangeChange}
          clearable={false}
          searchable={false}
        />
      )}

      {!readOnly && (
        <NewToolTip
          title="Datepicker control"
          titleIcon={dateIcon}
          action="Go forward for the given date range"
        >
          <S.RightButton
            onClick={handleRightButtonClick}
            disabled={isNextButtonClickDisabled}
            isDarkMode={darkMode}
          />
        </NewToolTip>
      )}
    </S.Container>
  )
}

NewDatePicker.propTypes = {
  timezone: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment),
  onChange: PropTypes.func,
  untilYesterday: PropTypes.bool,
  selectedRange: PropTypes.string,
  readOnly: PropTypes.bool,
  rangeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      range: PropTypes.string,
    }),
  ),
  monthPicker: PropTypes.bool,
  currentDate: PropTypes.instanceOf(moment),
  darkMode: PropTypes.bool,
  yearPicker: PropTypes.bool,
}

NewDatePicker.defaultProps = {
  onChange: _.noop,
  untilYesterday: false,
  selectedRange: null,
  readOnly: false,
  endDate: null,
  rangeOptions: [],
  monthPicker: false,
  currentDate: null,
  darkMode: false,
  yearPicker: false,
}

export default NewDatePicker
