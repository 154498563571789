import styled from 'styled-components/macro'
import Button from '../Button'
import { animationShake, modalFadeIn } from '../../css/sharedStyles'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: none;
  transition: 0.2s all ease;

  ${(props) =>
    props.visible &&
    `
    z-index: 1000;
    display: block;
  `}
`

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Modal = styled.div`
  background-color: #fff;
  border-radius: var(--border-radius);
  width: 463px;
  opacity: 0;
  animation: ${modalFadeIn} 0.4s ease forwards;

  ${(props) =>
    props.$hasTable &&
    `
    width: 60vw;
  `}
`

export const Header = styled.div`
  width: 100%;
  background: var(--lighter-neutral-96);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const Content = styled.div`
  height: 80%;
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  font-size: 18px;
  line-height: 1.4;
  font-family: 'ArticulatCF-Medium';
  color: var(--darker-neutral-25);
`

export const Icon = styled.img`
  display: block;
  margin: 0;
`

export const ButtonWrapper = styled.div`
  display: grid;
  ${(props) =>
    `grid-template-columns: repeat(${props.$controlOptions}, 150px)`};
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 15px;
  justify-content: flex-end;

  ${(props) =>
    props.$hasTable &&
    `
    margin: 15px 0 0 auto;
  `}
`

export const StyledButton = styled(Button)`
  display: block;
  border: none;
  background-color: var(--base-blue);
  padding: 12px 0;
  font-size: 16px;
  border-radius: var(--border-radius);
  color: var(--lighter-neutral-99);
  animation: ${animationShake} 25s ease infinite;
  animation-delay: 60s;
  transition: 0.1s all;
  font-family: 'ArticulatCF-DemiBold';
  margin: 0;
  width: auto;

  :hover {
    background-color: var(--darker-blue-43);
  }

  ${(props) =>
    props.isDisabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}

  &.cancelBtn {
    background-color: var(--lighter-neutral-99);
    color: var(--base-blue);
    border: 1px solid var(--base-blue);
    animation: none;

    :hover {
      ${(props) =>
        props.warning
          ? `
            background-color: var(--base-red);
            color: var(--lighter-neutral-99);
            border: 1px solid var(--base-red);
            `
          : `
            background-color: var(--lighter-neutral-85);
        `}
    }
  }
`

export const Title = styled.p`
  margin: 15px 0 20px;
  font-size: 26px;
  color: var(--darker-neutral-25);
  font-family: 'ArticulatCF-DemiBold';
`

export const IconTitle = styled.p`
  margin: 0;
  background: white;
  padding: 5px 15px;
  border-radius: 8px;
`
