import { connect } from 'react-redux'
import { path } from 'ramda'
import UpsertVehicle from './UpsertVehicle'
import {
  loadVehicle,
  resetVehicle,
  saveVehicle,
  createVehicle,
} from '../../actions/manageVehiclesActions'

import { showAlert } from '../../actions/alertMessageActions'
import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state, ownProps) => ({
  vehicleIdParam: ownProps.params.vehicle_id,
  vehicleData: state.manageVehicles.vehicleData,
  timezone: getYardTimeZone(state),
  yardList: state.general.yardList,
  selectedYard: state.general.selectedYard,
  allYardList: state.general.allYardList,
  userRole: path(['Role', 'name'])(state.general.userData),
})

const mapDispatchToProps = {
  loadVehicle,
  resetVehicle,
  saveVehicle,
  showAlert,
  createVehicle,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpsertVehicle)
