import React from 'react'
import PropTypes from 'prop-types'

import Loading from '../Loading'

import { getScrollEnd } from '../../utils'
import * as S from './styles'

const VehicleDetailsListContainer = ({
  children,
  handleScrollEnd,
  isLoading,
}) => {
  function handleScroll(event) {
    return getScrollEnd(event) ? handleScrollEnd() : null
  }

  return (
    <S.OverflowContainer
      onScroll={(event) => handleScroll(event)}
      id="tabContent"
    >
      {children}

      {isLoading && (
        <S.LoadingContainer>
          <Loading />
        </S.LoadingContainer>
      )}
    </S.OverflowContainer>
  )
}

VehicleDetailsListContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  handleScrollEnd: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default VehicleDetailsListContainer
