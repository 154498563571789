import { connect } from 'react-redux'
import AllYards from './AllYards'
import { setSortOrder, setSortedHeader } from '../../actions/yardListActions'
import { resetTracking } from '../../actions/trackingActions'
import { selectYard } from '../../actions/generalActions'
import { loadVcrIssues } from '../../actions/manageVCRsActions'
import { getYardTimeZone } from '../../selectors'
import { showAlert, showErrorAlert } from '../../actions/alertMessageActions'

const mapStateToProps = (state, ownProps) => {
  const timezone = getYardTimeZone(state)
  return {
    timezone,
    selectedYard: state.general.selectedYard,
    viewMode: ownProps.location.query.view_mode,
    company: ownProps.location.query.company,
    railroad: ownProps.location.query.railroad,
    tableSortOption: state.yardList.tableSortOption,
    tableSortHeader: state.yardList.tableSortHeader,
    loggedUser: state.general.userData,
  }
}

const mapDispatchToProps = {
  selectYard,
  resetTracking,
  loadVcrIssues,
  setSortOrder,
  setSortedHeader,
  showAlert,
  showErrorAlert,
}

export default connect(mapStateToProps, mapDispatchToProps)(AllYards)
