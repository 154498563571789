import { pick } from 'lodash'
import clientApi from './utils/clientApi'

const getAll = ({ yardId, name, startTime, endTime }) =>
  clientApi({
    method: 'get',
    url: '/metrics/live_flags',
    params: {
      yard_id: yardId,
      name,
      startTime,
      endTime,
    },
  }).then(({ data: { live_flags: liveFlags } }) =>
    liveFlags.map((flag) =>
      pick(flag, [
        'description',
        'started_at',
        'isGoodFlag',
        'owner_type',
        'owner_name',
        'owner_id',
        'vehicle_id',
        'user_id',
      ]),
    ),
  )

const liveFlags = {
  getAll,
}

export default liveFlags
