import { saveAs } from 'file-saver'
import prepareCsv from './prepareCsv'

function handleDownloadCsvFile({ headerMap, body, date, name }) {
  const content = prepareCsv({ headerMap, body })

  const file = new File([content], `${name}_${date}.csv`, {
    type: 'text/csv;charset=utf-8;',
  })

  saveAs(file)
}

export default handleDownloadCsvFile
