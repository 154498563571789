import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SingleButton from './SingleButton'

class SelectButtonGroup extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedValue: props.options[0].value,
    }
  }

  handleChange = (value) => {
    const { onChange } = this.props

    this.setState({
      selectedValue: value,
    })

    onChange(value)
  }

  render() {
    const { label, options } = this.props
    const { selectedValue } = this.state

    return (
      <div className="selectButtonGroup-container">
        <div className="selectButtonGroup-content">
          <div className="selectButtonGroup-filter">
            <span>{label}</span>
            {options.map((filter) => (
              <SingleButton
                {...filter}
                isSelected={filter.value === selectedValue}
                onClick={this.handleChange}
                key={filter.label}
              />
            ))}
          </div>
          <div className="selectButtonGroup-input"></div>
        </div>
      </div>
    )
  }
}

export default SelectButtonGroup
