import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Autosuggest from 'react-autosuggest'
import _ from 'lodash'
import {
  Button,
  SelectButtonGroup,
  DatePicker,
  NewContainer,
  NewHeader,
} from '../../components'
import {
  getTimespan,
  endOfDay,
  beginningOfDay,
} from '../../utils/momentExtensions'
import { firstLetterToUpperCase, history } from '../../utils'
import searchIcon from '../../images/icons/search_icon_blue.svg'

const dateFilters = [
  { label: 'Past week', value: 'WEEK' },
  { label: 'Past month', value: 'MONTH' },
  { label: 'Past year', value: 'YEAR' },
]

const searchByTypes = [
  { label: 'None', value: 'NONE' },
  { label: 'Vehicle', value: 'VEHICLE' },
  { label: 'Creator', value: 'CREATOR' },
]

const dateOptions = {
  WEEK: 7,
  MONTH: 30,
  YEAR: 365,
}

const getSuggestionValue = (suggestion) => suggestion.name

class SearchVCR extends Component {
  static propTypes = {
    timezone: PropTypes.string.isRequired,
    loadCreatorList: PropTypes.func.isRequired,
    loadVehicleList: PropTypes.func.isRequired,
    yardId: PropTypes.number.isRequired,
    creatorList: PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.object),
      loading: PropTypes.bool,
    }).isRequired,
    vehicleList: PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.object),
      loading: PropTypes.bool,
    }).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      searchByTypeId: null,
      searchByTypeValue: '',
      searchByTypeSelected: 'NONE',
      isSearchByDisabled: true,
      userSuggestions: [],
      datePeriod: dateFilters[0].value,
      startDate: null,
      endDate: null,
      lists: {},
    }

    this.loadLists()
  }

  async loadLists() {
    const { yardId, loadCreatorList, loadVehicleList } = this.props

    await loadCreatorList({ yardId })
    await loadVehicleList({ yardId })

    this.setState({
      lists: {
        VEHICLE: this.props.vehicleList.value,
        CREATOR: this.props.creatorList.value,
      },
    })
  }

  componentDidMount() {
    const { datePeriod } = this.state
    this.handleDateFilterChange(datePeriod)
  }

  getUserSuggestions(value) {
    const { lists, searchByTypeSelected } = this.state
    const listValue = lists[searchByTypeSelected]
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    const re = new RegExp(inputValue)

    return inputLength === 0
      ? []
      : listValue.filter((item) => item.name.toLowerCase().search(re) !== -1)
  }

  renderUserSuggestion = (suggestion) => <div>{suggestion.name}</div>

  onChange = (event, { newValue }) => {
    this.setState({
      searchByTypeValue: newValue,
      searchByTypeId: null,
    })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ userSuggestions: this.getUserSuggestions(value) })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      userSuggestions: [],
    })
  }

  onSuggestionSelected = (event, { suggestion }) => {
    const { id } = suggestion

    this.setState({
      searchByTypeId: id,
    })
  }

  handleDateFilterChange = (value) => {
    const { timezone } = this.props

    const { startTime, endTime } = getTimespan({
      selectedDate: moment(),
      timezone,
      numberOfDays: dateOptions[value],
      includeToday: true,
    })

    this.setState({ startDate: startTime, endDate: endTime })
  }

  handleDateChange = (key) => (date) => {
    const { timezone } = this.props

    if (!_.isNil(date)) {
      this.setState({
        [key]: moment(date).tz(timezone),
      })
    }
  }

  handleSearchByFilterChange = (typeValue) => {
    this.setState({
      searchByTypeId: null,
      searchByTypeSelected: typeValue,
      searchByTypeValue: '',
      userSuggestions: [],
      isSearchByDisabled: typeValue === 'NONE',
    })
  }

  searchVCR = () => {
    const { yardId, timezone } = this.props
    const {
      searchByTypeId,
      searchByTypeSelected,
      startDate,
      endDate,
      isSearchByDisabled,
    } = this.state

    if (_.isNil(searchByTypeId) && !isSearchByDisabled) return

    const searchQueryParam = {
      VEHICLE: 'vehicle_id',
      CREATOR: 'user_id',
    }

    const query = { page: 0 }

    if (!_.isNil(searchByTypeId)) {
      query[searchQueryParam[searchByTypeSelected]] = searchByTypeId
    }

    query.submitted_at_start = beginningOfDay(startDate, timezone).format()
    query.submitted_at_end = endOfDay(endDate, timezone).format()

    history.push({
      pathname: `/yard/${yardId}/vcrs`,
      query,
    })
  }

  render() {
    const { timezone } = this.props
    const {
      searchByTypeValue,
      isSearchByDisabled,
      searchByTypeId,
      userSuggestions,
      startDate,
      endDate,
      searchByTypeSelected,
    } = this.state

    const maxDate = endOfDay(moment(), timezone)
    const placeholder =
      searchByTypeSelected !== 'NONE'
        ? `Enter ${firstLetterToUpperCase(searchByTypeSelected)} name`
        : ''

    const inputProps = {
      placeholder,
      value: searchByTypeValue,
      onChange: this.onChange,
      disabled: isSearchByDisabled,
    }

    return (
      <>
        <NewHeader
          title="Search VCR's"
          returnText="Back to VCR's"
          returnUrl="/vcrs"
        />
        <NewContainer>
          <div className="searchVCR-container">
            <div className="searchVCR-content">
              <div className="searchVCR-title">
                <img alt="Search icon" src={searchIcon} /> Search report
              </div>
              <div className="searchVCR-date-filter">
                <SelectButtonGroup
                  label="Set date"
                  options={dateFilters}
                  onChange={this.handleDateFilterChange}
                />
                <div className="searchVCR-date-input">
                  <span>
                    <DatePicker
                      type="white"
                      timezone={timezone}
                      selected={startDate}
                      onChange={this.handleDateChange('startDate')}
                      maxDate={maxDate}
                    />
                  </span>
                  <span>
                    <DatePicker
                      type="white"
                      timezone={timezone}
                      selected={endDate}
                      onChange={this.handleDateChange('endDate')}
                      maxDate={maxDate}
                    />
                  </span>
                </div>
              </div>
              <div className="searchReport-role-filter">
                <SelectButtonGroup
                  label="Search by"
                  options={searchByTypes}
                  onChange={this.handleSearchByFilterChange}
                />
                <Autosuggest
                  suggestions={userSuggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  onSuggestionSelected={this.onSuggestionSelected}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={this.renderUserSuggestion}
                  inputProps={inputProps}
                />
                {_.isNil(searchByTypeId) && !isSearchByDisabled && (
                  <div className="searchVCR-alert-message">
                    Please type and select name or number from drop down list
                  </div>
                )}
              </div>
              <div className="btn-holder">
                <Button
                  className="searchReport-search-btn"
                  text="Search"
                  onClick={this.searchVCR}
                />
              </div>
            </div>
          </div>
        </NewContainer>
      </>
    )
  }
}

export default SearchVCR
