import Promise from 'bluebird'
import clientApi from './utils/clientApi'
import parseAuditFromServer from '../parsers/parseAuditFromServer'
import parseKeysToSnakeCase from '../parsers/parseKeysToSnakeCase'
import { parseIntOrUndefined } from '../utils'

const getAll = ({
  yardId,
  startDate,
  endDate,
  offset,
  limit,
  employeeId,
  auditorId,
  supervisorId,
}) =>
  clientApi({
    method: 'get',
    url: '/audits',
    params: {
      yard_id: yardId,
      audited_at_start: startDate && startDate.valueOf(),
      audited_at_end: endDate && endDate.valueOf(),
      offset,
      limit,
      employee_id: employeeId,
      auditor_id: auditorId,
      supervisor_id: supervisorId,
    },
  }).then(({ data }) => ({
    ...data,
    audits: data.audits.map(parseAuditFromServer),
  }))

const save = ({ auditValues, auditId }) => {
  const getRequestOptions = () => {
    const data = parseKeysToSnakeCase(auditValues)
    if (!parseIntOrUndefined(auditId)) {
      return {
        method: 'post',
        url: '/audits',
        data,
      }
    }

    return {
      method: 'put',
      url: `/audits/${auditId}`,
      data,
    }
  }

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const getOne = (auditId) =>
  clientApi({
    method: 'get',
    url: `/audits/${auditId}`,
  }).then(({ data }) => data)

const audits = {
  getAll,
  save,
  getOne,
}

export default audits
