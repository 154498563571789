import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Input.css'

class Input extends Component {
  handleInputChange = (event) => {
    const { onChange } = this.props
    onChange(event.target.value)
  }

  render() {
    const { label, type, className, autoFocus } = this.props
    return (
      <div className={`input ${className}`}>
        <div className="input__label">{label}</div>
        <input
          className="input__text"
          type={type}
          autoFocus={autoFocus}
          onChange={this.handleInputChange}
        />
      </div>
    )
  }
}

Input.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
}

Input.defaultProps = {
  onChange: () => undefined,
  label: '',
  type: '',
  className: '',
  autoFocus: false,
}

export default Input
