import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './TouchOverlay.css'

class TouchOverlay extends Component {
  componentDidMount() {
    this.updateCanvas()
    this.canvasClear()
    this.drawTouches()
    window.addEventListener('resize', this.updateCanvas)
  }

  componentDidUpdate(prevProps) {
    const { isSidebarCollapsed } = this.props
    if (!prevProps.isSidebarCollapsed && isSidebarCollapsed) {
      setTimeout(this.updateCanvas)
    }

    this.updateCanvas()
    this.canvasClear()
    this.drawTouches()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCanvas)
  }

  updateCanvas = () => {
    const { canvas } = this
    const siblingImage = canvas.parentNode.firstChild
    canvas.width = siblingImage.width
    canvas.height = siblingImage.height
    this.drawTouches()
  }

  drawCircle = (x, y) => {
    const { canvas } = this
    const ctx = canvas.getContext('2d')
    const scaledX = canvas.width * x
    const scaledY = canvas.height * y
    const circleDiameter = canvas.width / 16
    ctx.beginPath()
    ctx.arc(scaledX, scaledY, circleDiameter, 0, 2 * Math.PI)
    ctx.fillStyle = 'rgba(255, 0, 0, 0.4)'
    ctx.strokeStyle = 'rgba(255, 0, 0, 0.4)'
    ctx.fill()
    ctx.stroke()
  }

  drawTouches = () => {
    const { touches } = this.props

    touches.forEach((touch) => {
      this.drawCircle(touch.prop_x, touch.prop_y)
    })
  }

  canvasClear = () => {
    const { canvas } = this
    const ctx = canvas.getContext('2d')

    ctx.clearRect(0, 0, canvas.width, canvas.height)
  }

  render() {
    const { children } = this.props
    return (
      <div className="overlayContainer flex-row">
        {children}
        <canvas
          ref={(c) => {
            this.canvas = c
          }}
          id="canvasOverlay"
        />
      </div>
    )
  }
}

TouchOverlay.propTypes = {
  touches: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isSidebarCollapsed: PropTypes.bool.isRequired,
}

TouchOverlay.defaultProps = {
  touches: [],
  children: null,
}

export default TouchOverlay
