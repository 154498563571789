import { connect } from 'react-redux'
import VehicleDetailsNotes from './VehicleDetailsNotes'

import {
  postVehicleComment,
  loadComments,
} from '../../actions/manageVehiclesActions'

import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state) => {
  const timezone = getYardTimeZone(state)

  return {
    userData: state.general.userData,
    commentList: state.manageVehicles.commentList,
    timezone,
  }
}

const mapDispatchToProps = {
  postVehicleComment,
  loadComments,
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetailsNotes)
