import { connect } from 'react-redux'
import Login from './Login'
import { login, verifyToken } from '../../actions/generalActions'

const mapStateToProps = (state) => ({
  loginError: state.general.loginError,
})

const mapDispatchToProps = {
  login,
  verifyToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
