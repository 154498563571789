import { billingTypes } from '../../actions/billingActions'

const initialState = {
  value: {},
  isLoading: false,
}

const billingComments = (state = initialState, { type, payload }) => {
  switch (type) {
    case billingTypes.BILLING_COMMENTS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case billingTypes.BILLING_COMMENTS_LOADED:
      return {
        ...state,
        isLoading: false,
        value: payload,
      }
    case billingTypes.BILLING_COMMENTS_FAILED:
      return initialState

    default:
      return state
  }
}

export default billingComments
