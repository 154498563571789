import styled from 'styled-components/macro'

export const OverflowContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  padding-bottom: 55px;
`

export const LoadingContainer = styled.div`
  height: 'fit-content';
  width: 100%;
  display: flex;
`
