import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import issueIcon from '../../images/assets/issue_blue.svg'

import './IssueHeader.css'

const IssueHeader = ({ updatedAt, timezone }) => (
  <div className="issue__header flex-row">
    <p className="issueLabel flex-row">
      <img src={issueIcon} alt="issue" /> Issues
    </p>
    <div className="issueDate flex-row">
      Updated
      <p>{moment.tz(updatedAt, timezone).format('HH:mm')}</p>
    </div>
  </div>
)

IssueHeader.propTypes = {
  updatedAt: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
}

export default IssueHeader
