import { deviceStatusTypes } from '../../actions/deviceStatusActions'

const initialState = {
  loading: false,
  value: [],
}

const deviceSessions = (state = initialState, { type, payload }) => {
  switch (type) {
    case deviceStatusTypes.SESSIONS_REQUESTED:
      return {
        ...state,
        loading: true,
      }

    case deviceStatusTypes.SESSIONS_LOADED:
      return {
        ...state,
        loading: false,
        value: payload,
      }

    case deviceStatusTypes.SESSIONS_FAILED:
      return initialState
    default:
      return state
  }
}

export default deviceSessions
