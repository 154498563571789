import { combineReducers } from '@reduxjs/toolkit'

import userList from './userList'
import revokeTokenState from './revokeTokenState'
import metricsIntervalOption from './metricsIntervalOption'
import userMetrics from './userMetrics'
import tableSortOption from './tableSortOption'
import tableSortHeader from './tableSortHeader'

const manageUsers = combineReducers({
  userList,
  revokeTokenState,
  metricsIntervalOption,
  userMetrics,
  tableSortOption,
  tableSortHeader,
})

export default manageUsers
