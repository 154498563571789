import styled from 'styled-components/macro'
import { animationLiftUp, setDelay } from '../../css/sharedStyles'
import { tableDisplayLimitStart } from '../../config'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media (max-width: 1023px) {
    width: 100%;
    overflow-x: auto;
  }
`

export const Header = styled.div`
  background-color: var(--lighter-neutral-92);
  color: var(--darker-neutral-25);
  height: auto;
  cursor: default;
  text-transform: uppercase;
  display: grid;
  padding: 15px 10px;
  @media (max-width: 1023px) {
    width: 200%;
    overflow: auto;
  }
  ${(props) =>
    props.columnsLength &&
    `grid-template-columns: repeat(${props.columnsLength}, 1fr); gap: 10px;`}

  & span {
    cursor: pointer;
    font-size: 0.9rem;
    transition: 0.15s all;

    :hover {
      color: var(--base-blue);
    }

    &.pointerless {
      pointer-events: none;
    }
  }
`

export const Body = styled.div`
  margin-top: 5px;
  height: calc(100% - 60px);
  overflow-y: auto;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (max-width: 1023px) {
    width: 200%;
  }
`

export const Row = styled.div`
  position: relative;
  background-color: var(--lighter-neutral-99);
  /* box-shadow: var(--box-shadow); */
  padding: 15px 10px;
  display: grid;

  &.animated {
    opacity: 0;
    animation: ${animationLiftUp} 0.5s ease forwards;
    ${setDelay(tableDisplayLimitStart)}
  }

  :hover {
    background-color: var(--lighter-neutral-92);
  }

  ${(props) =>
    props.columnsLength &&
    `grid-template-columns: repeat(${props.columnsLength}, 1fr); gap: 10px;`}

  ${(props) => props.onClick && 'cursor: pointer;'}

  ${(props) =>
    props.length > 10 &&
    `
    padding: 15px 0 10px 15px;
  `}

  ${(props) =>
    props.highlight &&
    `
    background-color: var(--lighter-red-94);

    :hover {
      background-color: var(--lighter-red-89);
    }
  `}
`

export const Cell = styled.div`
  width: 100%;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;

  :hover {
    ${(props) =>
      props.onClick &&
      `
      color: var(--darker-blue-43);
    `}
  }

  ${(props) =>
    props.highlight &&
    `
    color: var(--base-blue);
  `}

  ${(props) =>
    props.textOnly &&
    `
    overflow: hidden;
  `}
`

export const OrderIcon = styled.span`
  display: inline-block;
  max-width: 80%;
  margin-left: 2px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  height: 100%;
`

export const CellContent = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`
