const identify = (data) => {
  if (window.heap) {
    const { user } = data
    window.heap.identify(user.username)
    window.heap.addUserProperties({
      Name: `${user.first_name} ${user.last_name}`,
      'Yard ID': user.yard_id,
      'Role ID': user.role_id,
    })
  }
}

const heap = {
  identify,
}

export default heap
