import _ from 'lodash'

function getTimestamp({ billingTable, timezone }) {
  const lastUpdated = _.chain(billingTable)
    .get('value.last_updated', {})
    .defaultTo({})
    .value()

  return {
    user: lastUpdated.user,
    updated_at: lastUpdated.value_updated_at,
    timezone,
  }
}

export default getTimestamp
