import { yardListTypes } from '../../actions/yardListActions'

const initialState = 1

const tableSortHeader = (state = initialState, { type, payload }) => {
  switch (type) {
    case yardListTypes.SORT_HEADER_CHANGED:
      return payload

    default:
      return state
  }
}

export default tableSortHeader
