import React, { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { prop, sortBy, includes } from 'ramda'

import { AUDITS_WRITE_ROLES } from '../../permissions/config'

import {
  CardGroup,
  PaginationBar,
  NewContainer,
  NewHeader,
  AuditsTable,
  Blank,
} from '../../components'

import addIcon from '../../images/icons/icon_add_dark.svg'
import auditIcon from '../../images/icons/audit_icon.svg'
import warningIcon from '../../images/icons/warning_icon.svg'
import violationPercentIcon from '../../images/icons/violation_percent_icon.svg'
import searchIcon from '../../images/icons/icon_search_dark.svg'
import tooltipIconAdd from '../../images/tooltip/add.svg'
import tooltipIconSearch from '../../images/tooltip/search.svg'
import iconDownload from '../../images/icons/icon_export_dark.svg'
import clearIcon from '../../images/icons/icon_clear_dark.svg'

import { history, getQueryDate, getAuditsDefaultQuery } from '../../utils'

export const Audits = ({
  yardId,
  timezone,
  audits,
  createdAtStart,
  createdAtEnd,
  metabaseDate,
  loadAudits,
  workerId,
  workerType,
  resetSelectedUser,
  userRole,
}) => {
  const [height, setHeight] = useState(window.innerHeight)
  const [page, setPage] = useState(0)
  const limit = Math.round(height / 110) // this can be improved
  const offset = parseInt(page, 10) * limit

  const hasWriteAccess = includes(userRole, AUDITS_WRITE_ROLES)

  useEffect(() => setPage(0), [yardId])

  const getFilterOptions = useCallback(() => {
    const { startDate, endDate } = getQueryDate({
      createdAtStart,
      createdAtEnd,
      metabaseDate,
      timezone,
    })

    const filterOptions = {
      yardId,
      startDate: moment.tz(startDate, timezone).startOf('day'),
      endDate: moment.tz(endDate, timezone).endOf('day'),
      offset,
      limit,
    }

    if (!_.isNil(metabaseDate)) {
      history.push(
        `/yard/${yardId}/audits?created_at_start=${filterOptions.startDate.valueOf()}&created_at_end=${filterOptions.endDate.valueOf()}&worker_id=${workerId}&worker_type=${workerType}`,
      )
    }

    return filterOptions
  }, [
    createdAtEnd,
    createdAtStart,
    metabaseDate,
    limit,
    offset,
    timezone,
    yardId,
    workerType,
    workerId,
  ])

  useEffect(() => {
    loadAudits({
      filterOptions: getFilterOptions(),
      workerId,
      workerType,
    })

    return () => resetSelectedUser()
  }, [
    loadAudits,
    workerId,
    workerType,
    getFilterOptions,
    page,
    height,
    resetSelectedUser,
  ])

  useEffect(() => {
    function updateTableRows() {
      setHeight(window.innerHeight)
    }
    const updateTableRowsDebounced = _.debounce(updateTableRows, 250)
    window.addEventListener('resize', updateTableRowsDebounced)

    return () => window.removeEventListener('resize', updateTableRowsDebounced)
  })

  const { startDate, endDate } = getQueryDate({
    createdAtStart,
    createdAtEnd,
    timezone,
  })

  const { total, withViolations } = audits.count

  const violationRate =
    total > 0 ? Math.round((withViolations / total) * 100) : '-'

  const date = {
    timezone,
    startDate: moment.tz(startDate, timezone),
    endDate: moment.tz(endDate, timezone),
    readOnly: true,
  }

  function resetFilter() {
    const {
      created_at_start: start,
      created_at_end: end,
    } = getAuditsDefaultQuery(timezone)

    history.push(
      `/yard/${yardId}/audits?created_at_end=${end}&created_at_start=${start}`,
    )
  }

  const titleActionList = [
    {
      key: 0,
      icon: searchIcon,
      onClick: () =>
        history.push({
          pathname: `/yard/${yardId}/audits/search`,
          query: {
            created_at_start: startDate.valueOf(),
            created_at_end: endDate.valueOf(),
          },
        }),
      tooltip: {
        titleIcon: tooltipIconSearch,
        title: 'Search audits',
        action: 'Click to search audits',
      },
    },
    {
      key: 2,
      icon: iconDownload,
      onClick: () =>
        history.push({
          pathname: `/yard/${yardId}/audits/print`,
          query: {
            created_at_start: createdAtStart,
            created_at_end: createdAtEnd,
            worker_id: workerId,
            worker_type: workerType,
          },
        }),
      disabled:
        audits.loading ||
        (!audits.loading && _.isEmpty(audits.value)) ||
        total > 100,
      tooltip: {
        title: 'PDF Preview & Download',
        action: 'Click to preview and download the PDF',
      },
    },
    {
      key: 3,
      icon: clearIcon,
      onClick: resetFilter,
      tooltip: {
        title: 'Clear Search',
        description: 'Resets the filter to the initial state',
        action: 'Click to clear search criteria',
      },
    },
  ]
  if (hasWriteAccess) {
    titleActionList.push({
      key: 1,
      icon: addIcon,
      onClick: () => history.push(`/yard/${yardId}/audits/create`),
      tooltip: {
        titleIcon: tooltipIconAdd,
        title: 'Create audit',
        action: 'Click to create a new audit',
      },
    })
  }
  const titleAction = sortBy(prop('key'))(titleActionList)

  const headerProps = {
    title: 'Audits',
    date,
    titleAction,
    explanation:
      total > 100
        ? 'Only 100 audits or less can be shown in the PDF preview'
        : '',
  }

  return (
    <>
      <NewHeader {...headerProps} />
      <NewContainer className="safetyReports">
        <div className="safetyReports-content" id="auditList">
          <CardGroup
            list={[
              {
                loading: false,
                icon: auditIcon,
                title: 'NUMBER OF AUDITS',
                value: total,
              },
              {
                loading: false,
                icon: warningIcon,
                title: 'AUDITS WITH VIOLATIONS',
                value: withViolations,
              },
              {
                loading: false,
                icon: violationPercentIcon,
                title: '% OF AUDIT WITH VIOLATIONS',
                value: `${violationRate}%`,
              },
            ]}
          />
          {_.isEmpty(audits.value) && !audits.loading ? (
            <Blank type="search" />
          ) : (
            <>
              <AuditsTable audits={audits} timezone={timezone} />
              <PaginationBar
                pagination={page}
                onPagination={(pageNumber) => setPage(pageNumber)}
                total={total}
                limit={limit}
              />
            </>
          )}
        </div>
      </NewContainer>
    </>
  )
}

Audits.propTypes = {
  yardId: PropTypes.number.isRequired,
  createdAtStart: PropTypes.number.isRequired,
  createdAtEnd: PropTypes.number.isRequired,
  metabaseDate: PropTypes.string,
  workerId: PropTypes.number,
  workerType: PropTypes.string,
  timezone: PropTypes.string.isRequired,
  loadAudits: PropTypes.func.isRequired,
  audits: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    count: PropTypes.shape({
      total: PropTypes.number.isRequired,
      withViolations: PropTypes.number.isRequired,
    }),
    value: PropTypes.arrayOf(
      PropTypes.shape({
        yardCode: PropTypes.string.isRequired,
        auditor: PropTypes.string.isRequired,
        supervisor: PropTypes.string.isRequired,
        employee: PropTypes.string.isRequired,
        violations: PropTypes.arrayOf(PropTypes.string).isRequired,
        correctiveActions: PropTypes.arrayOf(PropTypes.string).isRequired,
        createdAt: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  resetSelectedUser: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
}

Audits.defaultProps = {
  workerId: null,
  workerType: null,
  metabaseDate: null,
}

export default Audits
