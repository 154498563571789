import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import _ from 'lodash'
import { includes } from 'ramda'

import { NewTable, Na, AllowedYardsCell } from '../../components'
import { useGetPeopleQuery } from '../../services/allYards'
import { history } from '../../utils'

import { HAS_ACCESS_TO_ALL_YARDS_ROLES, roleNameToId } from '../../config'
import { canChange } from '../../permissions/user'

const headers = [
  { key: 0, text: 'Home Yard', sortField: 'Yard.name' },
  { key: 1, text: 'Last', sortField: 'last_name' },
  { key: 2, text: 'First', sortField: 'first_name' },
  { key: 3, text: 'Role', sortField: 'Role.name' },
  { key: 4, text: 'TOS ID', sortField: 'tos_id' },
  { key: 5, text: 'Kronos ID', sortField: 'username' },
  { key: 6, text: 'Other Yards', sortField: 'AllowedYardCount' },
]

const initialSortDirection = 'asc'

const defaultQueryOptions = {
  limit: 30,
  offset: 0,
  include_model: ['AllowedYards', 'Yard', 'Role'],
  sort_direction: initialSortDirection,
  sort_field: _.head(headers).sortField,
}

function getSortDirection({ isSameHeader, isAscending }) {
  if (!isSameHeader) {
    return 'asc'
  }

  return isAscending ? 'desc' : 'asc'
}

const People = ({ searchString, loggedUser, showAlert }) => {
  const [smartSearchString, setSmartSearchString] = useState(searchString)
  const [queryOptions, setQueryOptions] = useState(defaultQueryOptions)
  const [nextOffset, setNextOffset] = useState(0)
  const [peopleList, setPeopleList] = useState([])
  const [sortField, setSortField] = useState(defaultQueryOptions.sort_field)
  const [sortedHeader, setSortedHeader] = useState(0)
  const [isAscending, setIsAscending] = useState(
    defaultQueryOptions.sort_direction === initialSortDirection,
  )

  const sortDirection = isAscending ? 'asc' : 'desc'

  const { data = {}, isFetching: isLoading } = useGetPeopleQuery(queryOptions)

  const debouncedSetSmartSearch = useDebouncedCallback(
    setSmartSearchString,
    600,
  )

  useEffect(() => {
    if (!_.isNil(data.users)) {
      setPeopleList((prev) => [...prev, ...data.users])
      setNextOffset(data.nextOffset)
    }
  }, [data.nextOffset, data.users])

  useEffect(() => {
    const isInitialFetching =
      _.get(data.query, 'offset') === defaultQueryOptions.offset

    if (isInitialFetching) {
      setPeopleList(data.users)
    }
  }, [data.query, data.users])

  useEffect(() => {
    debouncedSetSmartSearch(searchString)
  }, [debouncedSetSmartSearch, searchString])

  useEffect(() => {
    setPeopleList([])

    if (smartSearchString) {
      setQueryOptions({
        ...defaultQueryOptions,
        smart_search: smartSearchString,
      })
    } else {
      setQueryOptions({
        ...defaultQueryOptions,
      })
    }
  }, [smartSearchString])

  function handleScrollEnd() {
    if (!_.isNil(nextOffset)) {
      const options = {
        ...defaultQueryOptions,
        offset: nextOffset,
        sort_field: sortField,
        sort_direction: sortDirection,
      }

      if (smartSearchString) {
        options.smart_search = smartSearchString
      }

      setQueryOptions(options)
    }
  }

  function handleHeaderClick(index) {
    const { sortField: newSortField } = headers.find(
      (header) => header.key === index,
    )

    const isSameHeader = newSortField === sortField

    setPeopleList([])

    const options = {
      ...defaultQueryOptions,
      offset: 0,
      sort_field: newSortField,
      sort_direction: getSortDirection({ isSameHeader, isAscending }),
    }

    if (smartSearchString) {
      options.smart_search = smartSearchString
    }

    setQueryOptions(options)
    setIsAscending(isSameHeader ? !isAscending : true)
    setNextOffset(defaultQueryOptions.limit)
    setSortField(newSortField)
    setSortedHeader(index)
  }

  function getAvailableYards(person) {
    const personAllowedYards = _.get(person, 'AllowedYards')

    return [
      _.get(person, 'Yard.id'),
      ...personAllowedYards.map((yard) => yard.id),
    ]
  }

  function handleRowClick({
    workerRole,
    workerId,
    workerAllowedYards,
    workerPrimaryYard,
  }) {
    const loggedUserRole = _.get(loggedUser, 'Role.name')
    const loggedUserId = _.get(loggedUser, 'id')
    const loggedUserAvailableYards = getAvailableYards(loggedUser)

    const hasPermissionToAccessYard = loggedUserAvailableYards.some(
      (value) =>
        includes(value, workerAllowedYards) ||
        includes(loggedUserRole, HAS_ACCESS_TO_ALL_YARDS_ROLES),
    )

    if (!hasPermissionToAccessYard) {
      showAlert({
        type: 'failure',
        message: `You don't have permission to access PC ${workerPrimaryYard}.`,
      })
      return null
    }

    const isCurrentUserEnableToEdit = canChange({
      loggedUser: {
        id: loggedUserId,
        role_id: roleNameToId[loggedUserRole],
      },
      targetUser: {
        id: workerId,
        role_id: roleNameToId[workerRole],
      },
    })

    return isCurrentUserEnableToEdit
      ? history.push(`/yard/${workerPrimaryYard}/users/${workerId}`)
      : history.push(`/yard/${workerPrimaryYard}/users/view/${workerId}`)
  }

  const rows = peopleList.map((person) => {
    const workerAllowedYards = getAvailableYards(person)
    const workerPrimaryYard = _.get(person, 'Yard.id')
    const workerRole = _.get(person, 'Role.name')
    const workerId = person.id

    return {
      columns: {
        0: {
          value: _.defaultTo(_.get(person, 'Yard.name'), <Na />),
        },
        1: {
          value: person.last_name,
        },
        2: {
          value: person.first_name,
        },
        3: {
          value: _.defaultTo(_.get(person, 'Role.display_name'), <Na />),
        },
        4: {
          value: _.defaultTo(person.tos_id, <Na />),
        },
        5: {
          value: person.username,
        },
        6: {
          value: _.size(person.AllowedYards).toString(),
          render: <AllowedYardsCell allowedYards={person.AllowedYards} />,
        },
      },
      onClick: () =>
        handleRowClick({
          workerId,
          workerRole,
          workerAllowedYards,
          workerPrimaryYard,
        }),
    }
  })

  const tableProps = {
    rows,
    headers,
    isLoading,
    sortedHeader,
    sortOrder: sortDirection,
    initialDisplayLimit: defaultQueryOptions.limit,
    onScrollEnd: handleScrollEnd,
    onSortedHeaderChange: handleHeaderClick,
  }

  return <NewTable {...tableProps} />
}

People.propTypes = {
  searchString: PropTypes.string,
  selectedYard: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  loggedUser: PropTypes.string.isRequired,
  showAlert: PropTypes.func.isRequired,
}

People.defaultProps = {
  searchString: null,
}

export default People
