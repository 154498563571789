import { manageVcrsTypes } from '../../actions/manageVCRsActions'

const initialState = null

const vcrSortOption = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVcrsTypes.LIST_ORDERING_CHANGED:
      return payload

    default:
      return state
  }
}

export default vcrSortOption
