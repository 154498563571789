const createInterval = () => {
  let id = null
  let isDead = false

  return {
    set(interval, callback) {
      if (!isDead) {
        this.clear()
        id = setInterval(callback, interval)
      }
    },
    clear() {
      clearInterval(id)
      id = null
    },

    kill() {
      this.clear()
      isDead = true
    },
  }
}

export default createInterval
