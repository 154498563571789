import styled from 'styled-components/macro'

import iconComment from '../../images/icons/icon_comment_blue.svg'
import iconClock from '../../images/icons/icon_badge_clock.svg'
import iconCalendar from '../../images/icons/icon_calendar_grey.svg'

export const Container = styled.div`
  margin-bottom: 9px;
  display: grid;
  gap: 5px;
  padding: 5px 0;

  :not(:last-child) {
    border-bottom: 1px solid var(--lighter-neutral-92);
    padding-bottom: 12px;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  margin-right: 10px;

  ::-webkit-scrollbar {
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border: none;
    border-top: 2px solid var(--lighter-neutral-92);
  }
`

export const Badge = styled.div`
  width: fit-content;
  background-color: var(--lighter-neutral-96);
  padding: 2px 10px;
  border-radius: var(--border-radius);
  color: var(--darker-neutral-45);
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  :not(:last-of-type) {
    margin-right: 10px;
  }
`

export const Comment = styled.div`
  background-color: var(--lighter-neutral-96);
  width: 100%;
  background-image: url(${iconComment});
  background-repeat: no-repeat;
  background-position: center left 10px;
  padding: 5px 5px 5px 35px;
  border-radius: var(--border-radius);
  display: grid;
  align-items: center;
  height: 100%;
`

export const Date = styled.div`
  margin-left: auto;
  background-image: url(${iconClock});
  color: var(--darker-neutral-45);
  background-repeat: no-repeat;
  background-position: center left 5px;
  padding-left: 25px;
  text-transform: initial;
  font-size: 14px;

  ${(props) =>
    props.calendar &&
    `
    background-image: url(${iconCalendar});
    display: inline-block;
    margin-left: 2px;
  `}
`

export const Text = styled.span`
  font-family: 'ArticulatCF-Bold';
  color: var(--darker-neutral-25);

  ${(props) =>
    props.right &&
    `
    margin-left: auto;
  `}
`

export const EmptyContainer = styled.div`
  height: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
`
