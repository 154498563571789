import { workerProfileTypes } from '../../actions/workerProfileActions'

const initialState = {
  submitted_at: null,
}

const userFirstVcr = (state = initialState, { type, payload }) => {
  switch (type) {
    case workerProfileTypes.USER_FIRST_VCR_REQUESTED:
    case workerProfileTypes.USER_FIRST_VCR_FAILED:
      return initialState

    case workerProfileTypes.USER_FIRST_VCR_LOADED:
      return {
        ...state,
        submitted_at: payload ? payload.submitted_at : null,
      }

    default:
      return state
  }
}

export default userFirstVcr
