import { manageUsersTypes } from '../../actions/manageUsersActions'

const initialState = {
  isLoading: false,
  value: {},
}

const userMetrics = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageUsersTypes.LOAD_METRICS_BY_USER_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }

    case manageUsersTypes.LOAD_METRICS_BY_USER_LOADED: {
      return {
        ...state,
        isLoading: false,
        value: payload,
      }
    }

    case manageUsersTypes.LOAD_METRICS_BY_USER_FAILED: {
      return {
        ...state,
        isLoading: false,
      }
    }

    default:
      return state
  }
}

export default userMetrics
