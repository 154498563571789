import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { get } from 'lodash'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import { PersistGate } from 'redux-persist/lib/integration/react'
import { persistStore } from 'redux-persist'

import store from './store'
import { SENTRY_DSN, GOOGLE_ANALYTICS_MEASUREMENT_ID } from './config'
import registerServiceWorker from './registerServiceWorker'

import Main from './Main'

import { injectGoogleAnalytics } from './utils/googleAnalytics'

registerServiceWorker()

const persistor = persistStore(store)

if (SENTRY_DSN !== undefined) {
  Sentry.init({
    dsn: SENTRY_DSN,
    ignoreErrors: ['Failed to fetch', 'NetworkError', 'Network Error'],
    integrations: [
      new Integrations.ExtraErrorData(),
      new Integrations.Dedupe(),
    ],
    beforeSend(event) {
      const userAgent = get(event, ['request', 'headers', 'User-Agent'], '')
      const re = new RegExp('Cypress')
      const isCypress = userAgent.search(re) !== -1

      if (isCypress) {
        return null
      }

      return event
    },
  })
}

if (GOOGLE_ANALYTICS_MEASUREMENT_ID !== undefined) {
  injectGoogleAnalytics(GOOGLE_ANALYTICS_MEASUREMENT_ID)
}

const render = () => {
  ReactDOM.render(
    <Provider store={store} persistor={persistor}>
      <PersistGate loading={null} persistor={persistor}>
        <Main />
      </PersistGate>
    </Provider>,
    document.getElementById('root'),
  )
}

render()
