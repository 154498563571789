import React from 'react'
import PropTypes from 'prop-types'
import { size, isNil } from 'lodash'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import * as S from './styles'

const Row = ({ row, rows, headers, hasCascadeAnimation, animationOff }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: row.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <S.Row
      className={animationOff ? null : 'animated'}
      key={row.id}
      length={rows.length}
      hasCascadeAnimation={hasCascadeAnimation}
      columnsLength={size(row.columns)}
      onClick={row.onClick}
      highlight={row.highlight}
      ref={setNodeRef}
      style={animationOff ? style : null}
      {...attributes}
      {...listeners}
    >
      {headers.map((header) =>
        isNil(row.columns[header.key]) ? null : (
          <S.Cell key={header.key} onClick={row.columns[header.key].onClick}>
            {row.columns[header.key].render ||
              row.columns[header.key].value ||
              '-'}
          </S.Cell>
        ),
      )}
    </S.Row>
  )
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  hasCascadeAnimation: PropTypes.bool,
  animationOff: PropTypes.bool,
}

Row.defaultProps = {
  hasCascadeAnimation: true,
  animationOff: false,
}

export default Row
