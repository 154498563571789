import { yardSummaryTypes } from '../../actions/yardSummaryActions'

const initialState = {
  loading: false,
  value: 0,
}

const liftsCount = (state = initialState, { type, payload }) => {
  switch (type) {
    case yardSummaryTypes.LIFTS_COUNT_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case yardSummaryTypes.LIFTS_COUNT_LOADED:
      return {
        ...state,
        loading: false,
        value: payload.count,
      }
    case yardSummaryTypes.LIFTS_COUNT_FAILED:
      return initialState
    default:
      return state
  }
}

export default liftsCount
