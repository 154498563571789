import { searchVCRTypes } from '../../actions/searchVCRActions'

const initialState = {
  loading: false,
  value: [],
}

const creatorList = (state = initialState, { type, payload }) => {
  switch (type) {
    case searchVCRTypes.CREATOR_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case searchVCRTypes.CREATOR_LIST_LOADED:
      return {
        ...state,
        loading: false,
        value: payload.creatorList,
      }
    case searchVCRTypes.CREATOR_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    default:
      return state
  }
}

export default creatorList
