import moment from 'moment-timezone'

export default function getAuditsDefaultQuery(timezone) {
  const startDate = moment
    .tz(timezone)
    .subtract(6, 'days')
    .startOf('day')
    .valueOf()
  const endDate = moment.tz(timezone).endOf('day').valueOf()

  return {
    created_at_start: startDate,
    created_at_end: endDate,
  }
}
