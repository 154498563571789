import { useEffect } from 'react'
import { path, pluck, isNil, isEmpty, includes } from 'ramda'
import { history } from '../utils'

export default function useHasVehicleAccess({ vehicleData, yardList }) {
  useEffect(() => {
    const vehicleYardId = path(['vehicle', 'yard_id'])(vehicleData)
    const yardIdListUserHasAccess = pluck('id')(yardList)
    if (
      !isNil(vehicleYardId) &&
      !isEmpty(yardList) &&
      !includes(vehicleYardId, yardIdListUserHasAccess)
    ) {
      history.push('/vehicles')
    }
  }, [vehicleData, yardList])
}
