import { manageVehiclesTypes } from '../../actions/manageVehiclesActions'

const initialState = {
  value: {},
  isLoading: false,
  hasMissingValues: false,
}

const engineHours = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVehiclesTypes.ENGINE_HOURS_REQUESTED:
    case manageVehiclesTypes.ENGINE_HOURS_EDIT_REQUESTED:
    case manageVehiclesTypes.MISSING_ENGINE_HOURS_EDIT_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }

    case manageVehiclesTypes.ENGINE_HOURS_LOADED:
    case manageVehiclesTypes.ENGINE_HOURS_EDIT_LOADED:
      return {
        ...state,
        isLoading: false,
        value: payload,
      }

    case manageVehiclesTypes.MISSING_ENGINE_HOURS_EDIT_LOADED:
      return {
        ...state,
        hasMissingValues: payload,
      }

    case manageVehiclesTypes.ENGINE_HOURS_FAILED:
    case manageVehiclesTypes.ENGINE_HOURS_EDIT_FAILED:
    case manageVehiclesTypes.MISSING_ENGINE_HOURS_EDIT_FAILED:
      return initialState

    default:
      return state
  }
}

export default engineHours
