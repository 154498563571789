import { manageVcrsTypes } from '../../actions/manageVCRsActions'

const initialState = {
  issueRate: 0,
  issueCount: 0,
  cleanCount: 0,
}

const issueRate = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVcrsTypes.ISSUE_RATE_SET:
      return {
        ...state,
        issueRate: payload.issueRate,
        issueCount: payload.issueCount,
        cleanCount: payload.cleanCount,
      }

    default:
      return state
  }
}

export default issueRate
