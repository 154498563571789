import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { debounce } from 'lodash'
import Visibility from 'visibilityjs'
import CustomSlider from './CustomSlider'
import { bufferDuration } from '../../../config'
import createInterval from '../../../utils/createInterval'
import { SpeedButton } from '../../../components'
import dateImg from '../../../images/assets/date.svg'
import './PlayBackControls.css'

const speedOptions = ['1', '5', '10']

class PlayBackControls extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: true,
      speedValue: speedOptions[1],
      width: 0,
      height: 0,
    }
    this.loadSelectedPositionHistory = debounce(
      this.loadSelectedPositionHistory,
      500,
      {
        maxWait: 500,
      },
    )
  }

  // FIXME stop using componentWillMount
  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillMount() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  componentDidMount() {
    const { clearHistory } = this.props
    this.historyInterval = createInterval('history')
    clearHistory()
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentDidUpdate(prevProps) {
    const {
      userList: { value: userListValue },
      setHistoryPlay,
      viewMode,
      scrubbing,
      currentHistoryTime,
      historyRequestId,
      historyData,
      loadLiveFlags,
      selectedYard,
    } = this.props

    const { historyInterval } = this

    if (prevProps.userList.value !== userListValue) {
      setHistoryPlay(false)
      historyInterval.clear()
    }

    if (viewMode !== 'history') {
      setHistoryPlay(false)
      historyInterval.clear()
    }

    if (
      viewMode === 'history' &&
      scrubbing === false &&
      !historyData[currentHistoryTime] &&
      historyRequestId === ''
    ) {
      const historyParams = this.getHistoryParams()
      const { startTime, endTime } = historyParams
      this.loadSelectedPositionHistory(historyParams)
      loadLiveFlags({
        yardId: selectedYard,
        startTime,
        endTime,
      })
    }
  }

  componentWillUnmount() {
    const { historyInterval } = this
    historyInterval.kill()
  }

  getHistoryParams() {
    const { currentHistoryTime, selectedYard } = this.props
    const bufferTimeForward = this.toSimulationTime(bufferDuration)

    return {
      yardId: selectedYard,
      startTime: currentHistoryTime,
      endTime: currentHistoryTime + bufferTimeForward,
    }
  }

  getTick() {
    const { speedValue } = this.state
    return parseInt(speedValue, 10) * 1000
  }

  getHistoryTime = () => {
    const { currentHistoryTime } = this.props
    const timeStr = moment(currentHistoryTime).format('MMMM Do h:mm a')
    return timeStr
  }

  setSpeed = (speedValue) => {
    this.setState({ speedValue }, () => this.handlePlay())
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  loadSelectedPositionHistory = (params) => {
    const { loadSelectedPositionHistory } = this.props
    loadSelectedPositionHistory(params)
  }

  checkBufferCondition = (currentHistoryTime, historyTimeSeries) => {
    if (
      historyTimeSeries.size !== 0 &&
      moment
        .duration(this.toRealTime(historyTimeSeries.last - currentHistoryTime))
        .asSeconds() < 5
    ) {
      return true
    }
    return false
  }

  handlePlayClick = () => {
    const { speedValue } = this.state
    this.handlePlay(speedValue)
  }

  handlePauseClick = () => {
    this.handleStop()
  }

  toSimulationTime(realTime) {
    const tick = this.getTick()
    return (realTime * tick) / 1000
  }

  toRealTime(simulationTime) {
    const tick = this.getTick()
    return simulationTime / (tick / 1000)
  }

  handlePlay() {
    const { setCurrentHistoryTime, setHistoryPlay, bufferHistory } = this.props
    const { historyInterval } = this

    const tick = this.getTick()

    historyInterval.set(1000, () => {
      const {
        currentHistoryTime,
        historyRequestId,
        bufferHistoryRequestId,
        historyTimeSeries,
        historyViewMoment,
      } = this.props

      if (Visibility.state() !== 'visible') {
        return null
      }

      if (currentHistoryTime >= historyViewMoment) {
        setCurrentHistoryTime(historyViewMoment)
        setHistoryPlay(false)
        historyInterval.clear()
      } else {
        if (historyRequestId === '') {
          setCurrentHistoryTime(currentHistoryTime + tick)
        }
        if (
          this.checkBufferCondition(currentHistoryTime, historyTimeSeries) &&
          bufferHistoryRequestId === '' &&
          historyTimeSeries.size !== 0
        ) {
          bufferHistory(this.getHistoryParams())
        }
      }
      return null
    })

    setHistoryPlay(true)
  }

  handleStop() {
    const { setHistoryPlay } = this.props
    const { historyInterval } = this
    historyInterval.clear()
    setHistoryPlay(false)
  }

  renderFooter() {
    const {
      viewMode,
      historyPlay,
      historyTimeSeries,
      timezone,
      currentHistoryTime,
    } = this.props
    const { speedValue } = this.state

    return (
      <div className="playbackFooter flex-row">
        <div className="timePlayBtn__container flex-column">
          <div className="currentTimeLabel flex-row">
            <img src={dateImg} alt="date" />
            <p>
              {moment
                .tz(currentHistoryTime, timezone)
                .format('MMM Do HH:mm:ss')}
            </p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {historyPlay
              ? this.renderPauseButton()
              : this.renderPlayButton(viewMode === 'live')}
          </div>
        </div>
        <div
          className="flex-column"
          style={{
            width: '100%',
            alignItems: 'flex-end',
            marginLeft: '24px',
          }}
        >
          <div className="playbackControls__content">
            <CustomSlider />
          </div>
          <SpeedButton
            speedValue={speedValue}
            speedSteps={speedOptions}
            setSpeed={this.setSpeed}
            disabled={!historyTimeSeries.size}
          />
        </div>
      </div>
    )
  }

  renderPlayButton(disabled) {
    return (
      <button
        type="button"
        disabled={disabled}
        className="card__play flex-row"
        onClick={this.handlePlayClick}
      >
        <div className="play_icon">&#xf04b;</div>
        &nbsp;&nbsp;Play
      </button>
    )
  }

  renderPauseButton() {
    return (
      <button
        type="button"
        className="card__pause flex-row"
        onClick={this.handlePauseClick}
      >
        <div className="pause_icon">&#xf04c;</div>
        &nbsp;&nbsp;Pause
      </button>
    )
  }

  renderTab() {
    const { viewMode, timezone, currentHistoryTime } = this.props
    const { isOpen } = this.state
    return (
      <div
        className="playbackTab"
        style={{ width: isOpen ? '140px' : '260px' }}
        onClick={() => this.setState({ isOpen: !isOpen })}
      >
        {isOpen ? (
          <div className="flex-row">
            Hide &nbsp;
            <div style={{ fontFamily: 'Fontawesome webfont' }}>&#xf2d3;</div>
          </div>
        ) : (
          <div className="flex-row">
            {`${moment
              .tz(currentHistoryTime, timezone)
              .format('MMM Do HH:mm:ss')} ${
              viewMode === 'live' ? 'Live' : 'Playback'
            }`}
            &nbsp;
            <div style={{ fontFamily: 'Fontawesome webfont' }}>&#xf055;</div>
          </div>
        )}
      </div>
    )
  }

  render() {
    const { viewMode } = this.props
    const { isOpen } = this.state

    if (viewMode !== 'history') {
      return null
    }

    if (!isOpen) {
      return <div className="playbackTabWrapper">{this.renderTab()}</div>
    }

    return (
      <div className="card">
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              top: '-84px',
            }}
          >
            {this.renderTab()}
          </div>
          {this.renderFooter()}
        </div>
      </div>
    )
  }
}

PlayBackControls.propTypes = {
  historyPlay: PropTypes.bool,
  setHistoryPlay: PropTypes.func,
  setCurrentHistoryTime: PropTypes.func.isRequired,
  clearHistory: PropTypes.func,
  viewMode: PropTypes.string,
  userList: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  historyData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  historyTimeSeries: PropTypes.shape({
    first: PropTypes.number,
    last: PropTypes.number,
    size: PropTypes.number,
  }).isRequired,
  loadSelectedPositionHistory: PropTypes.func.isRequired,
  currentHistoryTime: PropTypes.number.isRequired,
  historyRequestId: PropTypes.string.isRequired,
  bufferHistoryRequestId: PropTypes.string.isRequired,
  bufferHistory: PropTypes.func.isRequired,
  scrubbing: PropTypes.bool,
  historyViewMoment: PropTypes.number.isRequired,
  vehicleList: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  timezone: PropTypes.string.isRequired,
  loadLiveFlags: PropTypes.func.isRequired,
  selectedYard: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}

PlayBackControls.defaultProps = {
  historyPlay: false,
  setHistoryPlay: () => undefined,
  clearHistory: () => undefined,
  viewMode: '',
  scrubbing: false,
}

export default PlayBackControls
