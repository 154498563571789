import React from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'

import { Na } from '../../../components'

import VehicleStatusSelector from '../../ManageVehicles/VehicleStatusSelector'

import { formatSessionStatus, history } from '../../../utils'
import { getDateTime } from '../../../utils/momentExtensions'

import infoIcon from '../../../images/tooltip/info.svg'
import batteryImage from '../../../images/assets/battery.svg'
import batteryChargeImage from '../../../images/assets/low_battery.svg'

import * as S from '../styles'

function getActivity(DeviceSensor) {
  const { status, lastcomms } = _.defaultTo(DeviceSensor, {})

  const lastCommsInDays = moment
    .duration(moment().diff(moment(lastcomms)))
    .asDays()

  if (lastCommsInDays > 2) {
    return 'offline'
  }

  return status
}

function renderActivityOrOffline(activity) {
  if (_.isEmpty(activity) || _.isNil(activity)) {
    return <Na />
  }

  if (activity === 'offline') {
    return <Na text="Offline" />
  }

  return formatSessionStatus(activity)
}

function renderBattery(value) {
  if (_.isNil(value)) {
    return <Na />
  }

  const batteryStateImage = value < 0 ? batteryChargeImage : batteryImage

  return (
    <>
      {value >= 0 && `${value}%`}
      <S.Battery src={batteryStateImage} alt="battery" />
    </>
  )
}

function tooltipDescription({ vehicleDeviceSensor, vehicle }) {
  return (
    <>
      <div>
        <b>Device: </b> {_.get(vehicleDeviceSensor, 'model', 'None')}
      </div>
      <div>
        <b>IMEI: </b> {_.get(vehicleDeviceSensor, 'device_imei', 'N/A')}
      </div>
      <div>
        <b>iDrive: </b> {_.get(vehicle, 'idrive_code') || 'N/A'}
      </div>
      <div>
        <b>Make: </b> {_.get(vehicle, 'make') || 'N/A'}
      </div>
      <div>
        <b>Model: </b> {_.get(vehicle, 'model') || 'N/A'}
      </div>
      <div>
        <b>Year: </b> {_.get(vehicle, 'year') || 'N/A'}
      </div>
    </>
  )
}

export default function getVehicleRows({
  vehicleList,
  timezone,
  hasWriteAccess,
}) {
  return vehicleList.map((vehicle) => {
    const vehicleDeviceSensor = _.get(vehicle, 'DeviceSensor')
    const vehicleYard = _.get(vehicle, 'Yard')

    const vehicleYardName = _.get(vehicleYard, 'name')

    const yardDescription = _.isNil(vehicleYardName) ? (
      <Na />
    ) : (
      `${vehicleYardName} - ${_.get(vehicleYard, 'code')}`
    )

    const activity = getActivity(vehicleDeviceSensor)
    const lastComms = vehicleDeviceSensor
      ? getDateTime(vehicleDeviceSensor.lastcomms, timezone)
      : null

    return {
      columns: {
        0: {
          value: _.defaultTo(lastComms, ''),
          render: _.defaultTo(lastComms, <Na />),
        },
        1: { value: _.defaultTo(vehicle.vehicle_name, '') },
        2: {
          value: _.defaultTo(yardDescription, ''),
        },
        3: {
          value: _.defaultTo(_.get(vehicleDeviceSensor, 'battery'), ''),
          render: renderBattery(_.get(vehicleDeviceSensor, 'battery')),
        },
        4: {
          value: _.defaultTo(activity, ''),
          render: renderActivityOrOffline(activity),
        },
        5: {
          value: _.defaultTo(_.get(vehicle, 'engine_hours'), ''),
          render: _.defaultTo(_.get(vehicle, 'engine_hours'), <Na />),
        },
        6: {
          value: _.defaultTo(_.get(vehicleDeviceSensor, 'version'), ''),
          render: _.defaultTo(_.get(vehicleDeviceSensor, 'version'), <Na />),
        },
        7: {
          value: getDateTime(_.get(vehicle, 'updated_at'), timezone),
          render: (
            <VehicleStatusSelector
              data={vehicle}
              isDisabled={!hasWriteAccess}
            />
          ),
        },
      },
      onClick: () => history.push(`/vehicles/details/${vehicle.id}`),
      toolTipTitle: 'Additional Vehicle Information',
      toolTipDescription: tooltipDescription({ vehicleDeviceSensor, vehicle }),
      toolTipTitleIcon: infoIcon,
      status: vehicle.status,
      id: vehicle.id,
    }
  })
}
