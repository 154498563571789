import { connect } from 'react-redux'
import BillingTemplateDetails from './BillingTemplateDetails'
import { getYardTimeZone } from '../../selectors'
import { showAlert, showErrorAlert } from '../../actions/alertMessageActions'
import {
  setUnsavedChanges,
  resetSliceData,
} from '../../features/billing/billingTemplateDetailsSlice'

const mapStateToProps = (state, ownProps) => {
  const timezone = getYardTimeZone(state)

  return {
    timezone,
    selectedYard: state.general.selectedYard,
    returnDate: ownProps.location.query.return_date,
    templateId: ownProps.params.template_id,
    UrlViewMode: ownProps.params.view_mode,
    UrlSubMenu: ownProps.params.sub_menu,
    unsavedTemplateChanges: state.billing.billingTemplateDetails,
    yardList: state.general.yardList,
  }
}

const mapDispatchToProps = {
  showAlert,
  showErrorAlert,
  setUnsavedChanges,
  resetSliceData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillingTemplateDetails)
