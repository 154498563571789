import { combineReducers } from '@reduxjs/toolkit'
import vehicleTimeMetrics from './vehicleTimeMetrics'
import user from './user'
import selectedDate from './selectedDate'
import userFirstVcr from './userFirstVCR'
import flags from './flags'
import flagsCount from './flagsCount'
import boxCount from './boxCount'

const workerProfile = combineReducers({
  vehicleTimeMetrics,
  user,
  selectedDate,
  userFirstVcr,
  flags,
  flagsCount,
  boxCount,
})

export default workerProfile
