import styled from 'styled-components/macro'

export const NavigationItem = styled.button`
  display: flex;
  align-items: center;
  padding: 0 20px;
  transition: 0.2s all;
  background: transparent;
  width: 100%;
  border: none;
  opacity: 0.6;

  :hover {
    background-color: var(--darker-blue-43);
    cursor: pointer;
  }

  ${(props) =>
    props.isActive &&
    `
    background-color: var(--darker-blue-29);
    pointer-events: none;
    position: relative;
    opacity: 1;

    ::before {
      content: '';
      width: 2px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
      background: var(--lighter-neutral-99);
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }
  `}

  ${(props) =>
    props.disabled &&
    `
    opacity: .3 !important;
    pointer-events: none;
  `}
`

export const Icon = styled.img`
  margin-right: 20px;
  width: 25px;
  height: 25px;

  ${(props) =>
    props.header &&
    `
    width: 30px;
    height: 30px;
    margin: 0;
  `}
`

export const Label = styled.p`
  pointer-events: none;
  font-size: 16px;
  margin: 1.9vh 0;
`
