import axios from 'axios'
import { browserHistory } from 'react-router'
import _ from 'lodash'
import queryString from 'query-string'
import Promise from 'bluebird'

import { apiUrl } from '../../config'
import store from '../../store'
import { logout } from '../../actions/generalActions'

const clientApi = axios.create({
  baseURL: apiUrl,
})

const isLoginVerifyUrl = (url) => /\/login\/verify$/.test(url)
const isLogoutUrl = (url) => /\/logout$/.test(url)
const shouldLogout = (url, status) => {
  if (status !== 401) {
    return false
  }

  return !isLogoutUrl(url) && !isLoginVerifyUrl(url)
}

clientApi.defaults.paramsSerializer = queryString.stringify
clientApi.defaults.headers.common.Platform = 'WEB'

clientApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      const configWithAuth = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      }
      return configWithAuth
    }
    return config
  },
  (error) => Promise.reject(error),
)

clientApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = _.get(error, 'response.status')
    const url = _.get(error, 'config.url')
    if (shouldLogout(url, status)) {
      store.dispatch(logout())
      browserHistory.push('/login')
    }

    return Promise.reject(error)
  },
)

export default clientApi
