import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from '../DatePicker'

class DatePickerInput extends Component {
  static propTypes = {
    setFieldValue: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.instanceOf(moment),
    type: PropTypes.string.isRequired,
    showTimeSelect: PropTypes.bool,
    timezone: PropTypes.string.isRequired,
  }

  static defaultProps = {
    value: undefined,
    placeholder: 'Select',
    showTimeSelect: false,
  }

  handleOnSelect = (value) => {
    const { setFieldValue, name } = this.props
    setFieldValue(name, value)
  }

  render() {
    const {
      value,
      type,
      placeholder,
      showTimeSelect,
      timezone,
      ...props
    } = this.props

    const timeOptions = showTimeSelect
      ? {
          showTimeSelect,
          timeFormat: 'HH:mm',
          timeIntervals: 15,
          dateFormat: 'MMMM dd, yyyy HH:mm',
        }
      : {}

    return (
      <div className="datePickerInput">
        <DatePicker
          type={type}
          selected={value ? moment(value) : null}
          showCurrentDate
          onChange={this.handleOnSelect}
          placeholderText={placeholder}
          timezone={timezone}
          {...timeOptions}
          {...props}
        />
      </div>
    )
  }
}

export default DatePickerInput
