import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'rc-tooltip'
import moment from 'moment'
import cx from 'classnames'
import Button from '../../Button'
import { MAX_PLAYBACK_DAYS } from '../../../config'
import flagType from '../propTypes'
import goodFlagIcon from '../../../images/icons/good-flag.svg'
import badFlagIcon from '../../../images/icons/bad-flag.svg'
import './Flag.css'

class Flag extends Component {
  getPlayable() {
    const {
      flag: { date },
      timezone,
      isLive,
    } = this.props

    return (
      isLive ||
      moment
        .duration(Date.now() - moment(date).tz(timezone).valueOf())
        .asDays() < MAX_PLAYBACK_DAYS
    )
  }

  renderButton() {
    const {
      flag: { date, ownerId, hasLoggedUser },
    } = this.props
    const isPlayable = this.getPlayable()
    const dateTimestamp = moment(date).valueOf()
    const userOrVehicle = hasLoggedUser ? 'user' : 'vehicle'
    const className = cx('flag-play', { disabled: !isPlayable })
    return (
      <Button
        className={className}
        small
        isDisabled={!isPlayable}
        text="&#xf04b;&nbsp;&nbsp; Play"
        link={`/tracking/mode/history/time/${dateTimestamp}/${userOrVehicle}/${ownerId}`}
      />
    )
  }

  renderTooltipWithButton() {
    const isPlayable = this.getPlayable()

    return (
      <Tooltip
        overlay="Flag older than 7 days"
        placement="top"
        trigger={isPlayable ? [] : ['hover']}
      >
        <div>{this.renderButton()}</div>
      </Tooltip>
    )
  }

  render() {
    const {
      flag: { isGood, ownerType, title, message, date },
      isLive,
      timezone,
    } = this.props

    const flagIcon = isGood ? goodFlagIcon : badFlagIcon

    return (
      <div className="flag">
        <div className="flag-content">
          <div className="flag-content-img">
            <img src={flagIcon} alt="flag" />
          </div>
          <div className="flag-content-text">
            {isLive && <h5>{ownerType.toUpperCase()}</h5>}
            <h3>{title}</h3>
            <p>{message}</p>
            {this.renderTooltipWithButton()}
          </div>
        </div>
        <span className="flag-time">
          {moment(date).tz(timezone).format('HH:mm')}
        </span>
      </div>
    )
  }
}

Flag.propTypes = {
  flag: PropTypes.shape(flagType).isRequired,
  timezone: PropTypes.string.isRequired,
  isLive: PropTypes.bool,
}

Flag.defaultProps = {
  isLive: false,
}

export default Flag
