import { trackingTypes } from '../../actions/trackingActions'

const initialState = {
  loading: false,
  value: [],
}

const zones = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.ZONES_LOADED:
      return {
        ...state,
        loading: false,
        value: payload,
      }

    default:
      return state
  }
}

export default zones
