import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiUrl } from '../config'

export default function getReducerBaseQuery() {
  return fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        headers.set('platform', 'WEB')
      }

      return headers
    },
  })
}
