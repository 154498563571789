import { connect } from 'react-redux'
import ForgotPassword from './ForgotPassword'
import { createPasswordToken } from '../../actions/forgotPasswordActions'
import { showAlert } from '../../actions/alertMessageActions'

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  createPasswordToken,
  showAlert,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
