import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ItemList from './ItemList'

class Menu extends Component {
  static propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onClick: PropTypes.func.isRequired,
    iconUrl: PropTypes.string,
  }

  static defaultProps = {
    iconUrl: null,
    title: null,
  }

  state = {
    isOpened: false,
  }

  handleButtonClick = () => {
    this.setState((state) => ({ isOpened: !state.isOpened }))
  }

  handleItemClick = ({ value }) => {
    const { onClick } = this.props
    this.setState({ isOpened: false })
    onClick(value)
  }

  handleClickOutside = () => {
    this.setState({
      isOpened: false,
    })
  }

  renderArrow() {
    const { isOpened } = this.state

    if (isOpened) {
      return <div className="down">&#xf077;</div>
    }

    return <div className="up">&#xf078;</div>
  }

  render() {
    const { isOpened } = this.state
    const { items, title, iconUrl } = this.props

    return (
      <div className="menu" onClick={(e) => e.stopPropagation()} role="button">
        <div>
          <button
            type="button"
            className="expandBtn"
            onMouseDown={this.handleButtonClick}
          >
            {iconUrl ? (
              <img src={iconUrl} alt="iconUrl" />
            ) : (
              <div className="arrow flex-row">
                <p>{title}&nbsp;&nbsp;</p>
                {this.renderArrow()}
              </div>
            )}
          </button>
          {isOpened ? (
            <ItemList
              items={items}
              onItemClick={this.handleItemClick}
              onClickOutside={this.handleClickOutside}
            />
          ) : null}
        </div>
      </div>
    )
  }
}

export default Menu
