import React from 'react'
import PropTypes from 'prop-types'
import './IssueTable.css'

const IssueTable = ({ issues, header }) => (
  <div className="issueTable">
    <div className="issueTable_header flex-row">
      <div className="flex-row">
        Item&nbsp;
        <p>{header}</p>
      </div>
      <div>OK</div>
      <div>B/O</div>
    </div>
    {issues.map((issue) => (
      <div className="issueTable_row flex-row" key={issue.id}>
        <div>{issue.name}</div>
        <div>{issue.state}</div>
        <div>{issue.comment}</div>
      </div>
    ))}
  </div>
)

IssueTable.propTypes = {
  issues: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  ).isRequired,
  header: PropTypes.string,
}

IssueTable.defaultProps = {
  header: '',
}

export default IssueTable
