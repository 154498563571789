import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'ramda'
import { Formik, Field } from 'formik'
import { browserHistory } from 'react-router'

import * as S from './styles'

import {
  validationSchema,
  unsavedChangesModalProps,
  confirmChangesModalProps,
  formFields,
  returnUrl,
  fieldsBlockedToUpdate,
} from './constants'

import {
  useGetCustomerQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
} from '../../services/customers'
import { NewContainer, NewHeader, NewModal } from '../../components'

const UpsertBillingCustomer = ({ customerId, showAlert, showErrorAlert }) => {
  const [skipLoadCustomer, setSkipLoadCustomer] = useState(true)
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)
  const [showCofirmChangesModal, setShowCofirmChangesModal] = useState(false)

  const hasCustomerToEdit = !isNil(customerId)

  const { data: customerData = {}, isLoading } = useGetCustomerQuery(
    { customerId },
    { skip: skipLoadCustomer },
  )

  const [createCustomer] = useAddCustomerMutation()
  const [updateCustomer] = useUpdateCustomerMutation()

  useEffect(() => {
    if (hasCustomerToEdit) {
      setSkipLoadCustomer(false)
    }
  }, [hasCustomerToEdit])

  const initialValues = hasCustomerToEdit
    ? customerData
    : validationSchema.cast()

  const headerProps = {
    title: hasCustomerToEdit
      ? `Edit ${customerData.name || ''}`
      : 'Create new customer',
    returnText: 'Back to billing',
    returnUrl,
  }

  const extraUnsavedModalProps = {
    onConfirm: () => setShowUnsavedChangesModal(false),
    onCancel: () => browserHistory.push(returnUrl),
    visible: showUnsavedChangesModal,
  }

  const unsavedModalProps = {
    ...unsavedChangesModalProps,
    ...extraUnsavedModalProps,
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (body) => {
        const valuesToUpdate = Object.keys(validationSchema.cast())

        const parsedValuesToUpdate = valuesToUpdate.reduce(
          (acc, value) =>
            fieldsBlockedToUpdate.includes(value)
              ? acc
              : { ...acc, [value]: body[value] },
          {},
        )

        return hasCustomerToEdit
          ? updateCustomer({ body: parsedValuesToUpdate, customerId })
              .unwrap()
              .then(() => {
                showAlert({
                  type: 'success',
                  message: 'Customer successfully updated',
                })

                return browserHistory.push(returnUrl)
              })
              .catch((error) =>
                showErrorAlert({
                  error,
                  defaultMessage: 'Failed to update customer',
                }),
              )
          : createCustomer({ body })
              .unwrap()
              .then(() => {
                showAlert({
                  type: 'success',
                  message: 'Customer successfully created',
                })

                return browserHistory.push(returnUrl)
              })
              .catch((error) =>
                showErrorAlert({
                  error,
                  defaultMessage: 'Failed to create customer',
                }),
              )
      }}
    >
      {({ isSubmitting, dirty, handleSubmit }) => {
        const extraConfirmModalProps = {
          onConfirm: handleSubmit,
          onCancel: () => setShowCofirmChangesModal(false),
          visible: showCofirmChangesModal,
          isSubmitting,
        }

        const confirmModalProps = {
          ...confirmChangesModalProps,
          ...extraConfirmModalProps,
        }

        if (dirty) {
          headerProps.returnAction = () => setShowUnsavedChangesModal(true)
        }

        return (
          <>
            <NewHeader {...headerProps} />
            <NewContainer>
              <S.Wrapper>
                <S.StyledForm>
                  <S.Content>
                    {formFields.map((fieldProps, index) => (
                      <Field key={index} {...fieldProps} disabled={isLoading} />
                    ))}
                  </S.Content>

                  <S.StyledButton
                    type="submit"
                    text="Save"
                    isDisabled={isSubmitting || isLoading}
                  />
                </S.StyledForm>
              </S.Wrapper>
            </NewContainer>
            <NewModal {...unsavedModalProps} />
            <NewModal {...confirmModalProps} />
          </>
        )
      }}
    </Formik>
  )
}

UpsertBillingCustomer.propTypes = {
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showAlert: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
}

UpsertBillingCustomer.defaultProps = {
  customerId: null,
}

export default UpsertBillingCustomer
