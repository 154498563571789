import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './VCRStatus.css'

import issueIcon from '../../images/assets/vcr_issue.svg'
import okIcon from '../../images/assets/no_issue.svg'

class VCRStatus extends Component {
  conditionalStyleSelect = (value) => {
    if (value === 'open') {
      return {
        color: 'var(--base-yellow)',
        background: 'var(--lighter-yellow-92)',
      }
    }
    if (value === 'closed') {
      return {
        color: 'var(--darker-green-33)',
        background: 'var(--lighter-green-88)',
      }
    }
    return ''
  }

  render() {
    const { status } = this.props
    const icon = status === 'open' ? issueIcon : okIcon
    return (
      <div
        className="vcrStatus__container"
        style={{ ...this.conditionalStyleSelect(status) }}
      >
        <img src={icon} alt="icon" />
        {status === 'open' ? 'Needs repair' : 'No issues'}
      </div>
    )
  }
}

VCRStatus.propTypes = {
  status: PropTypes.string.isRequired,
}

export default VCRStatus
