import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  box-shadow: var(--box-shadow);
  margin-bottom: 20px;
`

export const Header = styled.div`
  background: var(--lighter-neutral-92);
  color: var(--darker-neutral-25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  font-family: 'ArticulatCF-Medium';
`

export const Content = styled.div`
  display: none;
  overflow-y: auto;
  padding: 10px 10px 0;
  background: var(--lighter-neutral-99);
  font-family: 'ArticulatCF-Medium';

  ${(p) =>
    p.isOpen &&
    `
    display: block;
    height: fit-content;
    overflow-y: auto;
    max-height: 170px;
    min-height: 170px;
  `}
`

export const Title = styled.span`
  display: block;
  text-transform: uppercase;
  font-size: 15px;
  font-family: 'ArticulatCF-Bold';
  color: var(--darker-neutral-25);
`

export const Toggle = styled.button`
  border: none;
  background: transparent;
  color: var(--base-blue);
  font-size: 15px;
  transition: 0.2s all ease;

  :hover {
    background: var(--lighter-blue-96);
  }
`

export const Icon = styled.img`
  object-fit: contain;
  margin-left: 10px;
  transition: 0.2s all ease;
  transform: rotate(180deg);

  ${(p) => p.isOpen && `transform: rotate(0deg);`}
`
