import { upsertAuditTypes } from '../../actions/upsertAuditActions'

const initialState = {
  loading: false,
  value: [],
}

const supervisors = (state = initialState, { type, payload }) => {
  switch (type) {
    case upsertAuditTypes.SUPERVISORS_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case upsertAuditTypes.SUPERVISORS_LOADED:
      return {
        ...state,
        loading: false,
        value: payload.supervisors,
      }
    case upsertAuditTypes.SUPERVISORS_FAILED:
      return initialState
    default:
      return state
  }
}

export default supervisors
