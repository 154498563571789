import { connect } from 'react-redux'
import UpsertYard from './UpsertYard'

import { showAlert, showErrorAlert } from '../../actions/alertMessageActions'
import { getYardTimeZone } from '../../selectors'
import { loadYards } from '../../actions/generalActions'

const mapStateToProps = (state, ownProps) => ({
  timezone: getYardTimeZone(state),
  yardId: ownProps.params.yard_id,
})

const mapDispatchToProps = {
  showAlert,
  showErrorAlert,
  loadYards,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpsertYard)
