import { connect } from 'react-redux'
import BillingComments from './BillingComments'
import { loadBillingComments } from '../../actions/billingActions'
import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state) => ({
  timezone: getYardTimeZone(state),
  selectedYard: Number(state.general.selectedYard),
  billingComments: state.billing.billingComments,
  billingTable: state.billing.billingTable,
})

const mapDispatchToProps = {
  loadBillingComments,
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingComments)
