import styled from 'styled-components'
import iconClip from '../../../images/icons/icon_clip.svg'
import iconTrash from '../../../images/icons/icon_trash_red.svg'

export const Container = styled.div`
  height: calc(100% - 48px);
  display: flex;
  flex-direction: column;
`

export const Dropzone = styled.div`
  border: 2px dashed var(--base-neutral);
  align-content: center;
  width: 80%;
  display: grid;
  place-items: center;
  padding: 10px 0;
  margin: 10px auto;
  text-align: center;
  flex: 1;
`

export const Text = styled.p`
  margin: 0;
  margin-bottom: 10px;
  color: var(--base-neutral);
`

export const Button = styled.button`
  height: 32px;
  background: var(--base-blue);
  border-radius: 3px;
  border: none;
  color: var(--lighter-neutral-99);
  padding: 0 30px;
  width: 225px;

  ${(props) =>
    props.$upload &&
    `
    margin-top: 10px;
    background: var(--darker-green-33);
  `}

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: .3;
    margin-top: 10px;
    color: #000;
    background: var(--lighter-neutral-85);
  `}
`

export const Filezone = styled.div`
  border-top: 2px solid var(--base-neutral);
  overflow-y: auto;
  flex: 1;
`

export const File = styled.div`
  width: calc(100% - 20px);
  margin: 10px auto;
  padding: 10px 10px 10px 30px;
  text-align: center;
  background: url(${iconClip}) no-repeat center left 10px;
  background-size: 10px;
  background-color: var(--lighter-neutral-96);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;

  ${(props) =>
    props.$isQueued &&
    `
    opacity: .6;
  `}
`

export const FilenameWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  :hover {
    cursor: ${(props) => (props.$isDisabled ? 'default' : 'pointer')};
    ${(props) => (props.$isDisabled ? null : 'color: var(--base-blue);')}
  }
`

export const Filename = styled.p`
  margin: 0;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Remove = styled.button`
  background: url(${iconTrash}) no-repeat center;
  border: none;
  background-color: var(--lighter-neutral-99);
  width: 25px;
  height: 25px;
  background-size: 15px;
  border-radius: 50%;
  transition: 0.2s all ease;

  :hover {
    background-color: var(--lighter-neutral-85);
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}
`

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`

export const FileState = styled.span`
  text-transform: uppercase;
  opacity: 0.5;
`
