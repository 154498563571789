import { connect } from 'react-redux'
import { hide } from '../../actions/alertMessageActions'
import AlertMessage from './AlertMessage'

const mapStateToProps = (state) => ({
  type: state.alertMessage.type,
  message: state.alertMessage.message,
  hidden: state.alertMessage.hidden,
  timeout: state.alertMessage.timeout,
  fullWidthStyle: state.alertMessage.fullWidthStyle,
  redirectPath: state.alertMessage.redirectPath,
})

const mapDispatchToProps = {
  hide,
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage)
