import styled from 'styled-components/macro'
import iconDonwload from '../../images/icons/icon_download_loose.svg'
import iconClip from '../../images/icons/icon_clip_gray.svg'

export const Cycles = styled.div`
  margin-bottom: 9px;
  text-transform: capitalize;
  display: grid;
  padding: 2px 0;
  grid-template-columns: 0.62fr 1fr 0.3fr;
  grid-template-rows: 1fr;

  :not(:last-child) {
    border-bottom: 1px solid var(--lighter-neutral-92);
    padding-bottom: 12px;
  }
`

export const ButtonPeriod = styled.button`
  font-family: 'ArticulatCF-ExtraBold';
  border: none;
  background: transparent;
  transition: 0.2s ease all;
  padding: 0;
  cursor: pointer;

  :hover {
    color: var(--darker-blue-43);
  }

  :disabled {
    opacity: 0.7;
    pointer-events: none;
  }
`

export const BadgeContainer = styled.div`
  width: 120px;
  display: inline-block;
`

export const Button = styled.button`
  border-radius: var(--border-radius);
  font-size: 14px;
  border: none;
  color: var(--lighter-neutral-99);

  ${(p) =>
    p.isReadyToSubmit
      ? `
    background: var(--darker-green-33);
  `
      : `
    background: var(--base-neutral);
    pointer-events: none;
  `}

  ${(props) =>
    props.isPreview &&
    `
    background-image: url(${iconDonwload});
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--base-blue);
    background-size: 14px;
    pointer-events: auto;
    width: 30px;
  `}

  ${(props) =>
    props.$hasAttachment &&
    `
    background-image: url(${iconClip});
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--lighter-neutral-92);
    background-size: 16px;
    pointer-events: auto;
    width: 30px;
    opacity: 0;
    pointer-events: none;

    ${
      props.$isVisible &&
      `
      opacity: 1;
    `
    }
  `}

  :disabled {
    opacity: 0.7;
    pointer-events: none;
    background-color: var(--base-neutral);
  }
`

export const Flex = styled.div`
  display: flex;
  gap: 5px;
`

export const EmptyContainer = styled.div`
  height: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Period = styled.div`
  text-align: left;
`

export const TemplateInfo = styled.p`
  margin: 3px 0 0;
  color: var(--darker-neutral-45);
  opacity: 0.8;
  font-size: 14px;

  ${(props) =>
    props.light &&
    `
    color: var(--lighter-neutral-96);
    font-size: 16px;
  `}
`
