import { connect } from 'react-redux'
import VehicleStatusSelector from './VehicleStatusSelector'
import { editVehicleStatus } from '../../../actions/manageVehiclesActions'
import { getYardTimeZone } from '../../../selectors'

const mapStateToProps = (state) => ({
  selectedYard: state.general.selectedYard,
  vehicleData: state.manageVehicles.vehicleData,
  timezone: getYardTimeZone(state),
})

const mapDispatchToProps = (dispatch) => ({
  editVehicleStatus: (yardId, vehicleName, currentStatus, newStatus) => {
    dispatch(editVehicleStatus(yardId, vehicleName, currentStatus, newStatus))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleStatusSelector)
