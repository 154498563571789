import Promise from 'bluebird'
import _ from 'lodash'
import clientApi from './utils/clientApi'

const getAll = ({ yard, date }) => {
  const validate = () => {
    if (_.isNil(yard)) {
      throw new Error('Missing Yard ID')
    }
    if (_.isNil(date)) {
      throw new Error('Missing date')
    }
  }

  const params = {
    date,
    yard_id: yard,
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: '/engine_hours/summary',
    params,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const upsert = ({ rows, date }) => {
  const validate = () => {
    if (_.isNil(date)) {
      throw new Error('Missing date')
    }
    if (_.isNil(rows)) {
      throw new Error('Missing rows')
    }
  }

  const getRequestOptions = () => ({
    method: 'put',
    url: '/engine_hours/summary',
    data: { date, rows },
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const getMissingEngineHours = ({ yard }) => {
  const validate = () => {
    if (_.isNil(yard)) {
      throw new Error('Missing Yard ID')
    }
  }

  const params = {
    yard_id: yard,
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: '/engine_hours/has_missing_values',
    params,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const engineHours = {
  getAll,
  upsert,
  getMissingEngineHours,
}

export default engineHours
