import { searchAuditTypes } from '../../actions/searchAuditActions'

const initialState = {
  loading: false,
  value: [],
}

const workerList = (state = initialState, { type, payload }) => {
  switch (type) {
    case searchAuditTypes.WORKER_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case searchAuditTypes.WORKER_LIST_LOADED:
      return {
        ...state,
        loading: false,
        value: payload.workerList,
      }
    case searchAuditTypes.WORKER_LIST_FAILED:
      return initialState
    default:
      return state
  }
}

export default workerList
