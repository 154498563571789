import { trackingTypes } from '../../actions/trackingActions'

const initialState = 'workers'

const selectionType = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.SELECTION_TYPE_SET:
      return payload
    case trackingTypes.SELECTION_TYPE_RESET:
      return initialState
    default:
      return state
  }
}

export default selectionType
