import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import { browserHistory } from 'react-router'

import { isNil, omit } from 'ramda'
import * as S from './styles'
import {
  unsavedChangesModalProps,
  confirmChangesModalProps,
  TYPES_WITH_REQUIRED_COMMENT,
  FIELDS_TO_OMIT_FROM_UPDATE,
  IS_COMMENT_REQUIRED_DEFAULT_VALUE,
} from './constants'

import {
  getInitialFormFields,
  getInitialValuesToEdit,
  getValidationSchema,
} from './helpers'

import {
  useGetBillingElementQuery,
  useAddBillingElementMutation,
  useUpdateBillingElementMutation,
} from '../../services/billing'

import { NewContainer, NewHeader, NewModal, FormItem } from '../../components'

const UpsertBillingElementType = ({
  showAlert,
  showErrorAlert,
  elementType,
  returnDate,
  selectedYard,
  billingElementId,
}) => {
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)
  const [showCofirmChangesModal, setShowCofirmChangesModal] = useState(false)
  const [isCommentRequired, setIsCommentRequired] = useState()
  const [skipGetBillingElement, setSkipGetBillingElement] = useState(true)

  const {
    data: billingElementData = {},
    isLoading,
  } = useGetBillingElementQuery(
    { billingElementId },
    { skip: skipGetBillingElement },
  )

  const [submitBillingElement] = useAddBillingElementMutation()
  const [updateBillingElement] = useUpdateBillingElementMutation()

  useEffect(() => {
    if (!isNil(billingElementId) && !isLoading) {
      setSkipGetBillingElement(false)
    }
  }, [billingElementId, isLoading])

  const hasBillingElementToEdit = !isNil(billingElementId)

  const returnUrl = `yard/${selectedYard}/billing/items/${elementType}?return_date=${returnDate}`

  const formFields = getInitialFormFields(elementType).map((field) => ({
    ...field,
    component: FormItem,
  }))

  const validationSchema = getValidationSchema(formFields)

  const initialValues = hasBillingElementToEdit
    ? getInitialValuesToEdit(billingElementData)
    : { ...validationSchema.cast() }

  const parsedFormFields = formFields.map((field) => {
    if (field.name === 'is_comment_required') {
      const defaultValue = hasBillingElementToEdit
        ? initialValues.is_comment_required
        : IS_COMMENT_REQUIRED_DEFAULT_VALUE

      const fieldValue = isNil(isCommentRequired)
        ? defaultValue
        : isCommentRequired

      return {
        ...field,
        value: fieldValue,
        isChecked: fieldValue,
        onChange: () => setIsCommentRequired(!fieldValue),
        text: `${fieldValue ? 'Yes' : 'No'}`,
        isDisabled: hasBillingElementToEdit ? isLoading : false,
      }
    }

    return {
      ...field,
      isDisabled: hasBillingElementToEdit ? isLoading : false,
    }
  })

  const headerProps = {
    title: `${
      hasBillingElementToEdit ? 'Edit' : 'Create new'
    } ${elementType} item`,
    returnText: 'Back to billing element list',
    returnUrl,
  }

  const extraUnsavedModalProps = {
    onConfirm: () => setShowUnsavedChangesModal(false),
    onCancel: () => browserHistory.push(returnUrl),
    visible: showUnsavedChangesModal,
  }

  const unsavedModalProps = {
    ...unsavedChangesModalProps,
    ...extraUnsavedModalProps,
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const parsedValue = hasBillingElementToEdit
          ? { ...omit(FIELDS_TO_OMIT_FROM_UPDATE)(values) }
          : {
              ...values,
              type: elementType,
            }

        if (TYPES_WITH_REQUIRED_COMMENT.includes(elementType)) {
          parsedValue.is_comment_required = isNil(isCommentRequired)
            ? false
            : isCommentRequired
        }

        return hasBillingElementToEdit
          ? updateBillingElement({ body: parsedValue, billingElementId })
              .unwrap()
              .then(() => {
                showAlert({
                  type: 'success',
                  message: `${values.default_display_name} updated successfully`,
                })

                return browserHistory.push(returnUrl)
              })
              .catch((error) =>
                showErrorAlert({
                  error,
                  defaultMessage: `Failed to update ${values.default_display_name}`,
                }),
              )
          : submitBillingElement({ body: parsedValue })
              .unwrap()
              .then(() => {
                showAlert({
                  type: 'success',
                  message: 'New item created successfully',
                })

                return browserHistory.push(returnUrl)
              })
              .catch((error) =>
                showErrorAlert({
                  error,
                  defaultMessage: `Failed to create item for ${elementType}`,
                }),
              )
      }}
    >
      {({ isSubmitting, dirty, handleSubmit }) => {
        const extraConfirmModalProps = {
          onConfirm: handleSubmit,
          onCancel: () => setShowCofirmChangesModal(false),
          visible: showCofirmChangesModal,
          isSubmitting,
        }

        const confirmModalProps = {
          ...confirmChangesModalProps,
          ...extraConfirmModalProps,
        }

        if (dirty) {
          headerProps.returnAction = () => setShowUnsavedChangesModal(true)
        }

        return (
          <>
            <NewHeader {...headerProps} />
            <NewContainer>
              <S.Wrapper>
                <S.StyledForm>
                  <S.Content>
                    {parsedFormFields.map((fieldProps, index) => (
                      <Field
                        key={index}
                        {...fieldProps}
                        disabled={fieldProps.disabled}
                      />
                    ))}
                  </S.Content>

                  <S.StyledButton
                    type="submit"
                    text="Save"
                    isDisabled={isSubmitting}
                  />
                </S.StyledForm>
              </S.Wrapper>
            </NewContainer>
            <NewModal {...unsavedModalProps} />
            <NewModal {...confirmModalProps} />
          </>
        )
      }}
    </Formik>
  )
}

UpsertBillingElementType.propTypes = {
  showAlert: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
  elementType: PropTypes.oneOf(['service', 'metric', 'charge']).isRequired,
  selectedYard: PropTypes.string.isRequired,
  returnDate: PropTypes.string.isRequired,
  billingElementId: PropTypes.string,
}

UpsertBillingElementType.defaultProps = {
  billingElementId: undefined,
}

export default UpsertBillingElementType
