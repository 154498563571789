import users from './users'
import audits from './audits'
import auditFunctionTypes from './auditFunctionTypes'
import auditViolationTypes from './auditViolationTypes'
import auditCorrectiveActionTypes from './auditCorrectiveActionTypes'
import liveFlags from './liveFlags'
import flags from './flags'
import shifts from './shifts'
import sessions from './sessions'
import moves from './moves'
import devices from './devices'
import auth from './auth'
import yards from './yards'
import vcrs from './vcrs'
import vehicles from './vehicles'
import positionTracking from './positionTracking'
import positionHistory from './positionHistory'
import zones from './zones'
import notes from './notes'
import tags from './tags'
import notifications from './notifications'
import metrics from './metrics'
import passwordToken from './passwordToken'
import billingSummary from './billingSummary'
import billingWeeks from './billingWeeks'
import dashboard from './dashboard'
import engineHours from './engineHours'
import detectedMoves from './detectedMoves'
import billing from './billing'
import vwrs from './vwrs'
import vehicleComments from './vehicleComments'
import vehicleEvents from './vehicleEvents'
import invoices from './invoices'

const api = {
  users,
  audits,
  auditFunctionTypes,
  auditViolationTypes,
  auditCorrectiveActionTypes,
  liveFlags,
  flags,
  shifts,
  sessions,
  moves,
  devices,
  auth,
  yards,
  vcrs,
  vehicles,
  positionTracking,
  positionHistory,
  zones,
  notes,
  tags,
  notifications,
  metrics,
  passwordToken,
  billingSummary,
  billingWeeks,
  dashboard,
  engineHours,
  detectedMoves,
  billing,
  vwrs,
  vehicleComments,
  vehicleEvents,
  invoices,
}

export default api
