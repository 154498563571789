import { manageUsersTypes } from '../../actions/manageUsersActions'

const initialState = {
  isLoading: false,
  value: [],
  order: {
    column: 'LPMH',
    direction: 'desc',
  },
}

const userList = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageUsersTypes.USERS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }

    case manageUsersTypes.USERS_LOADED:
      return {
        ...state,
        isLoading: false,
        value: payload,
      }

    case manageUsersTypes.USERS_FAILED:
      return {
        ...state,
        isLoading: false,
        value: [],
      }

    case manageUsersTypes.DELETE_LOADED:
      return {
        ...state,
        value: state.value.filter((user) => user.id !== payload.userId),
      }

    case manageUsersTypes.USER_LIST_ORDER_SET:
      return {
        ...state,
        order: payload.order,
      }

    default:
      return state
  }
}

export default userList
