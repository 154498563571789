import React from 'react'
import PropTypes from 'prop-types'
import metricCardTypes from '../NewMetricCardItem/props'
import NewMetricCardItem from '../NewMetricCardItem'
import { Container } from './styles'

const NewMetricCardGroup = ({ list }) => (
  <Container>
    {list.map((item) => (
      <NewMetricCardItem key={item.title} {...item} />
    ))}
  </Container>
)

NewMetricCardGroup.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(metricCardTypes)).isRequired,
}

export default NewMetricCardGroup
