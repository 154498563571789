import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import NewToolTip from '../NewToolTip'
import Button from '../Button'
import './Toggle.css'

const Toggle = ({
  leftText,
  rightText,
  value,
  onChange,
  toolTipTitle,
  tooltipDescription,
}) => (
  <NewToolTip title={toolTipTitle} description={tooltipDescription}>
    <div className="toggleContainer">
      <Button
        className={cx('toggle', { active: !value })}
        text={leftText}
        small={window.innerWidth <= 1240 || window.innerHeight <= 800}
        onClick={() => onChange(true)}
      />
      <Button
        className={cx('toggle', { active: value })}
        text={rightText}
        small={window.innerWidth <= 1240 || window.innerHeight <= 800}
        onClick={() => onChange(false)}
      />
    </div>
  </NewToolTip>
)

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  leftText: PropTypes.string.isRequired,
  rightText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  toolTipTitle: PropTypes.string,
  tooltipDescription: PropTypes.string,
}

Toggle.defaultProps = {
  toolTipTitle: undefined,
  tooltipDescription: undefined,
}

export default Toggle
