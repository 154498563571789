import _ from 'lodash'
import { css, keyframes } from 'styled-components'

export const defaultCard = css`
  background: var(--lighter-neutral-99);
  box-shadow: var(--box-shadow);
  border-radius: var(--wrapper);
`

export const animationLiftUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const animationFloat = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
`

export const animationShake = keyframes`
  0%, 97% { transform: translateY(0); }
  97.5% { transform: translateY(-5px); }
  98% { transform: translateY(5px); }
  98.5% { transform: translateY(-5px); }
  99% { transform: translateY(5px); }
  99.5% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
`

export const modalFadeIn = keyframes`
  0% { opacity: 0; margin-top: 50px; }
  100% { opacity: 1; margin-top: 0px; }
`

const delayHelper = (index, length) =>
  `
    &:nth-child(${index + 1}) {
      animation-delay: ${index * 0.035}s;
      z-index: ${length - index};
    }
  `

export const setDelay = (length) =>
  _(length).times((index) => delayHelper(index, length))

export const alertAnimation = keyframes`
  0% {
      transform: translateY(100px);
  }
  5% {
    transform: translateY(-15px);
  }
  8% {
    transform: translateY(-5px);
  }
  12% {
    transform: translateY(-10px);
  }
  85% {
    transform: translateY(-10px);
    opacity: 1;
  }
  90% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(100px);
  }
`

export const invertedProgressBar = keyframes`
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
`
