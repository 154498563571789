import React, { useEffect } from 'react'

export default function VcrsRoot({ children, setVCRsSearch, ...props }) {
  useEffect(
    () => () => {
      setVCRsSearch({})
    },
    [setVCRsSearch],
  )

  return React.cloneElement(React.Children.only(children), props)
}
