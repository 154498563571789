import { __, map, pipe, path, find, curry, defaultTo } from 'ramda'

function multiplePathDefaultTo(pathList, defaultValue, valueObject) {
  return pipe(
    map(path(__, valueObject)),
    find((message) => typeof message === 'string'),
    defaultTo(defaultValue),
  )(pathList)
}

export default curry(multiplePathDefaultTo)
