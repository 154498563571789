import { isNil } from 'lodash'
import Promise from 'bluebird'
import clientApi from './utils/clientApi'

const getAll = ({ yardId, date }, options = {}) => {
  const validate = () => {
    if (yardId && isNil(date)) {
      throw new Error('Missing date')
    }
  }

  const getRequestOptions = () => {
    if (yardId) {
      return {
        method: 'get',
        url: `/yards/${yardId}/tags`,
        params: {
          date: date.format('MM/DD/YYYY'),
        },
      }
    }
    return {
      method: 'get',
      url: '/tags',
    }
  }

  const addOptions = (selectedOptions) => ({
    ...options,
    ...selectedOptions,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(addOptions)
    .then(clientApi)
    .then(formatResult)
}

const selectTag = ({ yardId, date, id }) =>
  clientApi({
    method: 'post',
    url: `/yards/${yardId}/tags/${id}`,
    data: {
      date: date.format('MM/DD/YYYY'),
    },
  }).then((res) => res)

const deselectTag = ({ yardId, date, id }) =>
  clientApi({
    method: 'delete',
    url: `/yards/${yardId}/tags/${id}`,
    data: {
      date: date.format('MM/DD/YYYY'),
    },
  }).then((res) => res)

const tags = {
  getAll,
  selectTag,
  deselectTag,
}

export default tags
