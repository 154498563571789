import styled from 'styled-components/macro'
import Toggle from 'react-toggle'
import CountUp from 'react-countup'

export const Content = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 16px;
  pointer-events: auto;

  background: var(--darker-blue-20);
  box-shadow: 0 5px 20px var(--box-shadow);
  border-bottom-left-radius: var(--wrapper);
  border-bottom-right-radius: var(--wrapper);
  height: ${(props) => (props.dynamicHeight ? 'calc(92vh - 175px)' : 'auto')};
`

export const YardMovesAverage = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  background-color: var(--darker-blue-17);
  cursor: default;
`

export const Badge = styled.div`
  font-family: ArticulatCF-Bold;
  font-size: 24px;
  background-color: var(--lighter-neutral-96);
  color: var(--lighter-neutral-99);
  border-radius: var(--border-radius);
  text-align: center;
  width: 100px;
  margin: 10px 0 5px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => props.boxMoves && `background: var(--darker-purple-29);`}
  ${(props) => props.turnAround && `background: var(--darker-blue-29);`}
`

export const Text = styled.span`
  color: var(--base-neutral);
  font-size: 14px;
  display: block;
  font-family: ArticulatCF-Medium;

  ${(props) =>
    props.bold &&
    `
    font-family: ArticulatCF-Bold;
    color: var(--lighter-neutral-99);
  `}
`

export const Footer = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .toggle_description {
    margin-left: 10px;
    color: var(--lighter-neutral-99);
    font-size: 0.9rem;
  }
`

export const StyledToggle = styled(Toggle)`
  margin-right: 15px;
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ToggleListButton = styled.button`
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  transition: 0.2s all ease;
  border-radius: var(--border-radius);
  margin-left: auto;

  :hover {
    background: var(--darker-blue-15);
  }
`

export const Icon = styled.img`
  margin-left: 10px;

  ${(props) =>
    props.tab &&
    `
    margin: 0 5px 0 0;
    object-fit: contain;
  `}

  ${(props) => props.flipIcon && `transform: rotate(180deg)`};

  ${(props) =>
    props.marginRight &&
    `
    margin-left: 0;
    margin-right: 10px;
    height: 20px;
  `}
`

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  background: var(--darker-blue-20);
  border-top-left-radius: var(--wrapper);
  border-top-right-radius: var(--wrapper);
  pointer-events: auto;
  cursor: pointer;
`

export const Tab = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  color: var(--lighter-neutral-96);
  width: 100%;
  height: 40px;
  background-color: var(--darker-blue-17);
  transition: 0.2s all ease;
  text-transform: capitalize;
  font-size: 14px;

  :nth-child(1) {
    border-top-left-radius: var(--wrapper);
  }

  :nth-child(2) {
    border-top-right-radius: var(--wrapper);
  }

  ${(props) =>
    props.active &&
    `
    opacity: 1;
    color: var(--lighter-neutral-99);
    transform: translateY(-8px);
    background-color: var(--darker-blue-20);
    border-top-left-radius: var(--wrapper);
    border-top-right-radius: var(--wrapper);
  `}
`

export const StyledCountUp = styled(CountUp)`
  background: var(--base-red);
  margin: 0;
  margin-left: 10px;
  border-radius: 20px;
  padding: 2px 10px;
  font-size: 14px;
`
