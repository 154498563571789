import { configureStore as createStore } from '@reduxjs/toolkit'

import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import rootReducer from './reducers'

import { toolkitApi } from './services'

export default function configureStore() {
  const store = createStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        immutableCheck: {
          ignoredPaths: ['general'],
        },
      }).concat(toolkitApi.middleware),
  })

  return store
}
