import moment from 'moment-timezone'

export const getDateByDays = (datetime) => moment(datetime).format('MM/DD/YYYY')

export const getTime = (datetime) => moment(datetime).format('HH:mm')

export const getDateTime = (datetime, timezone) =>
  moment.tz(datetime, timezone).format('MM/DD/YYYY HH:mm')

export const forceUtc = (date) =>
  moment.utc(date.format('YYYY-MM-DDTHH:mm:ss.SSS'))

export const beginningOfDay = (date, timezone) =>
  moment.tz(`${moment(date).format('YYYY-MM-DD')} 00:00:00`, timezone)

export const endOfDay = (date, timezone) =>
  moment.tz(`${moment(date).format('YYYY-MM-DD')} 23:59:59.999`, timezone)

export const getTimespan = ({
  selectedDate,
  timezone,
  numberOfDays,
  includeToday = true,
}) => {
  const numberOfDaysForEnd = includeToday ? 0 : 1
  const numberOfDaysForStart = numberOfDays + numberOfDaysForEnd - 1

  const startTime = moment
    .tz(selectedDate, timezone)
    .subtract(numberOfDaysForStart, 'days')
    .startOf('day')
  const endTime = moment
    .tz(selectedDate, timezone)
    .subtract(numberOfDaysForEnd, 'days')
    .endOf('day')

  return {
    startTime,
    endTime,
  }
}
