import _ from 'lodash'
import Promise from 'bluebird'
import { createTypes, onlyLatestRequest } from './utils'
import { forceUtc } from '../utils/momentExtensions'
import { getShortName } from '../utils'
import api from '../api'
import parseFlagsFromServer from '../parsers/parseFlagsFromServer'

export const workerProfileTypes = createTypes('workerProfile', [
  { value: 'VEHICLE_TIME_METRICS', isAsync: true },
  { value: 'USER', isAsync: true },
  { value: 'USER_FIRST_VCR', isAsync: true },
  { value: 'USER_FLAGS', isAsync: true },
  { value: 'USER_FLAGS_COUNT', isAsync: true },
  { value: 'BOX_COUNT', isAsync: true },
  { value: 'USER_QUICK_LOGIN_COUNT', isAsync: true },
  'SELECTED_DATE_SET',
  'ZOOM_LEVEL_SET',
  'MAP_CENTER_SET',
])

const getRatio = (numerator, denominator) => {
  if (denominator === 0) {
    return 0
  }

  return numerator / denominator
}

const meanForWorkedDays = (days) => {
  const workedDays = days.filter((value) => value !== 0)
  return workedDays.length === 0 ? 0 : _.mean(workedDays)
}

const getLatestVehicleTimeMetrics = {
  shifts: onlyLatestRequest(api.shifts.getAll),
  sessions: onlyLatestRequest(api.sessions.getAll),
}

export const loadVehicleTimeMetrics = ({ userId, startTime, endTime }) => (
  dispatch,
) => {
  dispatch({ type: workerProfileTypes.VEHICLE_TIME_METRICS_REQUESTED })

  const bins = 30

  return Promise.all([
    getLatestVehicleTimeMetrics.shifts({
      userId,
      startTime: forceUtc(startTime).valueOf(),
      endTime: forceUtc(endTime).valueOf(),
      bins,
    }),
    getLatestVehicleTimeMetrics.sessions({
      userId,
      startTime: startTime.valueOf(),
      endTime: endTime.valueOf(),
      bins,
      status: ['securement', 'parked', 'moving'],
    }),
  ])
    .then(([allShifts, allSessions]) => {
      const sessionsMetrics = {
        total: allSessions.total,
        bins: allSessions.bins.map((session) => session.total),
      }
      const shiftsMetrics = {
        total: allShifts.total,
        bins: allShifts.bins.map((shift) => shift.total),
      }

      const pairList = _.zip(sessionsMetrics.bins, shiftsMetrics.bins)

      const metricsRatio = pairList.map(([session, shift]) =>
        getRatio(session, shift),
      )
      const metricsRatioForChart = metricsRatio.map((ratio) => ({
        yValue: ratio,
      }))

      const average = meanForWorkedDays(metricsRatio)

      dispatch({
        type: workerProfileTypes.VEHICLE_TIME_METRICS_LOADED,
        payload: {
          bins: metricsRatioForChart,
          average: Number(average.toFixed(2)),
        },
      })
    })
    .catch(() =>
      dispatch({ type: workerProfileTypes.VEHICLE_TIME_METRICS_FAILED }),
    )
}

export const loadUser = (userId) => (dispatch) => {
  dispatch({ type: workerProfileTypes.USER_REQUESTED })

  api.users
    .getOne(userId)
    .then((userData) => {
      const { first_name: firstName, last_name: lastName } = userData
      const shortName = getShortName({ firstName, lastName })

      dispatch({
        type: workerProfileTypes.USER_LOADED,
        payload: {
          shortName,
        },
      })
    })
    .catch(() => {
      dispatch({ type: workerProfileTypes.USER_FAILED })
    })
}

export const setSelectedDate = (date) => ({
  type: workerProfileTypes.SELECTED_DATE_SET,
  payload: date ? date.format() : Date.now(),
})

export const setZoomLevel = (value) => ({
  type: workerProfileTypes.ZOOM_LEVEL_SET,
  payload: value,
})

export const setCenter = (value) => ({
  type: workerProfileTypes.MAP_CENTER_SET,
  payload: value,
})

const getLatestFlags = onlyLatestRequest(api.flags.getAll)

export const loadFlagsByUser = ({ userId, startTime, endTime }) => (
  dispatch,
) => {
  dispatch({ type: workerProfileTypes.USER_FLAGS_REQUESTED })

  const type = ['FLAG', 'LIVE_FLAG']
  getLatestFlags({
    userId,
    startTime,
    endTime,
    type,
    isGoodFlag: false,
    name: 'idleTime',
  })
    .then((data) => {
      const parsedFlags = parseFlagsFromServer({ data, showOwnerName: false })

      dispatch({
        type: workerProfileTypes.USER_FLAGS_LOADED,
        payload: { flags: parsedFlags },
      })
    })
    .catch(() => dispatch({ type: workerProfileTypes.USER_FLAGS_FAILED }))
}

const getLatestFlagsCount = onlyLatestRequest(api.flags.count)

export const loadFlagsCountByUser = ({ userId, startTime, endTime }) => (
  dispatch,
) => {
  dispatch({ type: workerProfileTypes.USER_FLAGS_COUNT_REQUESTED })

  const numberOfDays = 30

  getLatestFlagsCount({
    userId,
    isGoodFlag: false,
    startTime: startTime.valueOf(),
    endTime: endTime.valueOf(),
    bins: numberOfDays,
  })
    .then((data) => {
      const bins = data.bins.map((bin) => ({ yValue: bin.count }))
      const average = meanForWorkedDays(data.bins.map((bin) => bin.count))
      dispatch({
        type: workerProfileTypes.USER_FLAGS_COUNT_LOADED,
        payload: {
          average,
          bins,
        },
      })
    })
    .catch(() => dispatch({ type: workerProfileTypes.USER_FLAGS_COUNT_FAILED }))
}

const getLatestBoxCount = onlyLatestRequest(api.moves.getCount)

export const loadBoxCount = ({ userId, yardId, startTime, endTime }) => (
  dispatch,
) => {
  dispatch({ type: workerProfileTypes.BOX_COUNT_REQUESTED })

  const numberOfDays = 30

  return getLatestBoxCount({
    userId,
    yardId,
    completedAtStart: startTime.valueOf(),
    completedAtEnd: endTime.valueOf(),
    bins: numberOfDays,
  })
    .then((data) => {
      const bins = data.bins.map((bin) => ({ yValue: bin.count }))
      const average = meanForWorkedDays(data.bins.map((bin) => bin.count))
      dispatch({
        type: workerProfileTypes.BOX_COUNT_LOADED,
        payload: {
          bins,
          average,
        },
      })
    })
    .catch(() => dispatch({ type: workerProfileTypes.BOX_COUNT_FAILED }))
}
