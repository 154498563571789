import styled from 'styled-components/macro'

export const IFrame = styled.iframe`
  margin-top: 15px;
  width: 100%;
  height: 100%;
`

export const LoadingContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`

export const YardSummaryContainer = styled.div`
  padding: 20px 0;
  height: 100%;
  overflow-y: auto;
`
