import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledTable = styled.table`
  text-align: left;

  th,
  td {
    vertical-align: top;
    padding: 2px;
    font-size: 13px;
  }

  td:nth-child(1) {
    width: ${(props) => props.widthList[0]}px;
  }

  td:nth-child(2) {
    width: ${(props) => props.widthList[1]}px;
  }

  td:nth-child(3) {
    width: ${(props) => props.widthList[2]}px;
  }
`

function FunctionDescription({ text, functionList, widthList }) {
  return (
    <>
      <p>{text}</p>
      <StyledTable widthList={widthList}>
        <tr>
          <th>Formula function</th>
          <th>Description</th>
          <th>Options definition</th>
          <th>Options example</th>
        </tr>
        {functionList.map(({ name, description, options, example }) => (
          <tr key={name}>
            <td>{name}</td>
            <td>{description}</td>
            <td>{options}</td>
            <td>{example}</td>
          </tr>
        ))}
      </StyledTable>
    </>
  )
}

FunctionDescription.propTypes = {
  text: PropTypes.string.isRequired,
  functionList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      options: PropTypes.string.isRequired,
      example: PropTypes.string.isRequired,
    }),
  ).isRequired,
  widthList: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default FunctionDescription
