import React, { useEffect } from 'react'
import _ from 'lodash'

import {
  Blank,
  VehicleDetailsSingleVwr,
  VehicleDetailsListContainer,
} from '../../components'

import * as S from './styles'

const VehicleDetailsVwr = ({ loadVwrs, vehicleId, vwrList, timezone }) => {
  useEffect(() => {
    loadVwrs({
      vehicleId,
      limit: 10,
      offset: 0,
    })
  }, [loadVwrs, vehicleId])

  const listValue = _.get(vwrList, 'value', [])
  const isLoading = _.get(vwrList, 'isLoading')

  const vwrs = listValue.map((vwr) => {
    const key = _.get(vwr, 'id')
    const props = {
      vwr,
      timezone,
    }

    return <VehicleDetailsSingleVwr key={key} {...props} />
  })

  function handleScrollEnd() {
    const nextOffset = _.get(vwrList, 'nextOffset')

    return _.isNil(nextOffset)
      ? null
      : loadVwrs({
          vehicleId,
          limit: 10,
          offset: nextOffset,
          isLazyLoading: true,
        })
  }

  function render() {
    if (_.isEmpty(listValue) && !isLoading) {
      return (
        <S.EmptyContainer>
          <Blank title="There are no VWR's yet" type="search" />
        </S.EmptyContainer>
      )
    }

    return (
      <VehicleDetailsListContainer
        isLoading={isLoading}
        handleScrollEnd={handleScrollEnd}
      >
        {vwrs}
      </VehicleDetailsListContainer>
    )
  }

  return render()
}

export default VehicleDetailsVwr
