import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { FormItem } from '../../../../components'
import { pickOrUndefined } from '../../../../utils'

const auditTypeOptions = [
  { value: 'drone', label: 'Drone test' },
  { value: 'idrive', label: 'iDrive' },
  { value: 'physical', label: 'Physical' },
  { value: 'quality', label: 'Quality' },
]

const userListToOption = (userList) =>
  userList.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

class FormPage1 extends Component {
  componentDidMount() {
    const { loadEmployees, loadSupervisors, yardId } = this.props
    loadEmployees({ yardId })
    loadSupervisors({ yardId })
  }

  render() {
    const {
      savedValues,
      renderButtons,
      onSubmit,
      employees,
      supervisors,
      timezone,
    } = this.props

    const isEmployeesLoading = employees.loading

    return (
      <Formik
        initialValues={pickOrUndefined(savedValues, [
          'auditType',
          'employee',
          'supervisor',
          'auditedAt',
        ])}
        enableReinitialize
        validate={(values) => {
          const errors = {}
          if (!values.auditType) {
            errors.auditType = 'Required'
          }
          if (!values.employee) {
            errors.employee = 'Required'
          }
          if (!values.supervisor) {
            errors.supervisor = 'Required'
          }
          if (!values.auditedAt) {
            errors.auditedAt = 'Required'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values)
          setSubmitting(false)
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              type="select"
              name="auditType"
              component={FormItem}
              label="Audit type"
              placeholder="Select type"
              options={auditTypeOptions}
            />
            <Field
              type="select"
              name="employee"
              component={FormItem}
              label="Employee"
              placeholder="Select an employee"
              options={userListToOption(employees.value)}
              isDisabled={isEmployeesLoading}
              isLoading={isEmployeesLoading}
            />
            <Field
              type="select"
              name="supervisor"
              component={FormItem}
              label="Supervisor on duty"
              placeholder="Select a supervisor"
              options={userListToOption(supervisors.value)}
            />
            <Field
              type="datepicker"
              label="Audit date"
              name="auditedAt"
              showTimeSelect
              component={FormItem}
              placeholder="Select a date"
              timezone={timezone}
            />
            {renderButtons({ isSubmitting })}
          </Form>
        )}
      </Formik>
    )
  }
}

const userPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

const asyncUserListPropType = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  value: PropTypes.arrayOf(userPropType),
})

FormPage1.propTypes = {
  savedValues: PropTypes.object.isRequired,
  renderButtons: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  yardId: PropTypes.number.isRequired,
  loadEmployees: PropTypes.func.isRequired,
  loadSupervisors: PropTypes.func.isRequired,
  employees: asyncUserListPropType.isRequired,
  supervisors: asyncUserListPropType.isRequired,
  timezone: PropTypes.string.isRequired,
}

export default FormPage1
