import { connect } from 'react-redux'
import Tracking from './Tracking'
import {
  setViewMode,
  loadTracking,
  loadLiveFlags,
  getZones,
  changeSelectionType,
  setCurrentHistoryTime,
  setIconPinned,
  setHistoryRange,
  setTracking,
  resetTracking,
  clearHistory,
  setHistoryPlay,
} from '../../actions/trackingActions'
import { loadUsers } from '../../actions/manageUsersActions'
import { loadVehicles } from '../../actions/manageVehiclesActions'
import { loadDeviceSessions } from '../../actions/deviceStatusActions'

const mapStateToProps = (state, ownProps) => ({
  selectedYard: state.general.selectedYard,
  viewMode: state.tracking.viewMode,
  zones: state.tracking.zones,
  liveFlags: state.tracking.liveFlags,
  iconPinned: state.tracking.iconPinned,
  userId: parseInt(ownProps.params.user_id, 10),
  vehicleId: parseInt(ownProps.params.vehicle_id, 10),
  time: parseInt(ownProps.params.time, 10),
  urlViewMode: ownProps.params.url_view_mode,
  trackingDataForHistoryTime: state.playback.trackingDataForHistoryTime,
})

const mapDispatchToProps = {
  setViewMode,
  loadTracking,
  loadUsers,
  loadVehicles,
  loadLiveFlags,
  getZones,
  changeSelectionType,
  setCurrentHistoryTime,
  setIconPinned,
  setHistoryRange,
  setTracking,
  resetTracking,
  clearHistory,
  setHistoryPlay,
  loadDeviceSessions,
}

export default connect(mapStateToProps, mapDispatchToProps)(Tracking)
