export default function getRows({
  companies,
  renderActions,
  hasCrudYardWriteAccess,
}) {
  return companies.map((company) => {
    const columns = {
      0: {
        value: company.name,
      },
    }
    if (hasCrudYardWriteAccess) {
      columns[1] = {
        value: '',
        render: renderActions({ id: company.id }),
      }
    }

    return {
      columns,
    }
  })
}
