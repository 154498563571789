import { trackingTypes } from '../../actions/trackingActions'

const initialState = {
  loading: false,
  metrics: {},
}

const detectedMoves = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.DETECTED_MOVES_REQUESTED:
      return {
        ...state,
        loading: true,
      }

    case trackingTypes.DETECTED_MOVES_LOADED:
      return {
        ...state,
        loading: false,
        metrics: payload,
      }

    case trackingTypes.DETECTED_MOVES_FAILED:
      return initialState

    default:
      return state
  }
}

export default detectedMoves
