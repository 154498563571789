import React from 'react'
import moment from 'moment-timezone'
import { Na } from '../components'

export default function parseDateTime({ timestamp, timezone, shouldShowTime }) {
  const date = moment.tz(timestamp, timezone)

  if (!date.isValid()) {
    return <Na />
  }

  return date.format(shouldShowTime ? 'MM/DD/YYYY HH:mm' : 'MM/DD/YYYY')
}
