import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import _ from 'lodash'
import ManageVCRs from '../ManageVCRs'
import { parseIntOrUndefined } from '../../utils'

const defaultFilter = { page: 0, status: 'all' }

export default function ManageVcrsContainer({
  query,
  filter,
  setVCRsSearch,
  pathname,
}) {
  useEffect(() => {
    function changeFilter(newFilter) {
      browserHistory.push({
        pathname,
        query: newFilter,
      })

      setVCRsSearch(newFilter)
    }

    if (!_.isEmpty(query)) {
      changeFilter({ ...defaultFilter, ...query })
    }

    if (_.isEmpty(query) && !_.isEmpty(filter)) {
      changeFilter(filter)
    }

    if (_.isEmpty(query) && _.isEmpty(filter)) {
      changeFilter(defaultFilter)
    }

    // Don't use setInterval, setTimeout or similar.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (_.isNil(query.status) || _.isNil(query.page)) {
    return null
  }

  return (
    <ManageVCRs
      pathname={pathname}
      submittedAtStart={query.submitted_at_start}
      submittedAtEnd={query.submitted_at_end}
      status={query.status}
      userId={parseIntOrUndefined(query.user_id)}
      vehicleId={parseIntOrUndefined(query.vehicle_id)}
      page={parseIntOrUndefined(query.page)}
      defaultFilter={defaultFilter}
    />
  )
}

ManageVcrsContainer.propTypes = {
  query: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  setVCRsSearch: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
}
