import { trackingTypes } from '../../actions/trackingActions'

const initialState = {
  loading: false,
  value: null,
}

const infoBoxUser = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.INFO_BOX_USER_REQUESTED:
      return {
        ...state,
        loading: true,
        value: null,
      }

    case trackingTypes.INFO_BOX_USER_LOADED:
      return {
        ...state,
        loading: false,
        value: payload.user,
      }

    case trackingTypes.INFO_BOX_USER_FAILED:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default infoBoxUser
