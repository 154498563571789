import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Chart from '../Chart'
import { chartItemType } from './propTypes'

const ChartGroup = ({ list, selectedDate }) => (
  <React.Fragment>
    {list.map((item) => (
      <div className="workerCharts-chart" key={item.title}>
        <div className="workerCharts-chart-title">
          <img src={item.icon} alt="truck" />
          <h1>{item.title}</h1>
        </div>

        <Chart selectedDate={selectedDate} {...item} />
      </div>
    ))}
  </React.Fragment>
)

ChartGroup.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(chartItemType)).isRequired,
  selectedDate: PropTypes.instanceOf(moment).isRequired,
}

export default ChartGroup
