import { connect } from 'react-redux'
import BillingElements from './BillingElements'
import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state, ownProps) => {
  const timezone = getYardTimeZone(state)

  return {
    timezone,
    selectedYard: state.general.selectedYard,
    returnDate: ownProps.location.query.return_date,
    UrlSubMenu: ownProps.params.sub_menu,
  }
}

export default connect(mapStateToProps)(BillingElements)
