import { workerProfileTypes } from '../../actions/workerProfileActions'

const initialState = {
  loading: false,
  value: {
    bins: [],
    average: 0,
  },
}

const boxCount = (state = initialState, { type, payload }) => {
  switch (type) {
    case workerProfileTypes.BOX_COUNT_REQUESTED:
      return {
        ...state,
        loading: true,
        value: {
          bins: [],
          average: 0,
        },
      }

    case workerProfileTypes.BOX_COUNT_LOADED:
      return {
        ...state,
        loading: false,
        value: payload,
      }

    case workerProfileTypes.BOX_COUNT_FAILED:
      return initialState

    default:
      return state
  }
}

export default boxCount
