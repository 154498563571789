import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Promise from 'bluebird'
import _ from 'lodash'
import { Formik, Form } from 'formik'
import { Link } from 'react-router'

import Logo from '../../images/assets/new_logo.svg'
import PasswordStrength from '../PasswordStrength'

import * as S from './styles'

function PasswordReset({ resetUserPassword, loadUser, token, user }) {
  useEffect(() => {
    loadUser({ token })
  }, [loadUser, token])

  const onSubmit = (values) =>
    Promise.resolve({
      token,
      password: values.password,
    }).then(resetUserPassword)

  return (
    <S.Container>
      <Link to="/">
        <S.Logo src={Logo} />
      </Link>
      <S.Box>
        <S.Title>New Password</S.Title>
        <S.Subtitle>Please enter a new password</S.Subtitle>
        <Formik
          initialValues={{
            password: '',
            passwordConfirm: '',
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <PasswordStrength
                password={values.password}
                username={_.get(user, 'value.username', '')}
                firstName={_.get(user, 'value.first_name', '')}
                lastName={_.get(user, 'value.last_name', '')}
                fieldComponent={S.StyledFormItem}
                titleType="placeholder"
                passwordTitle="New password"
                confirmTitle="Confirm password"
              />
              <S.ActionWrapper>
                <S.StyledLink to="/">Cancel</S.StyledLink>
                <S.Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Saving' : 'Save'}
                </S.Button>
              </S.ActionWrapper>
            </Form>
          )}
        </Formik>
      </S.Box>
    </S.Container>
  )
}

PasswordReset.propTypes = {
  resetUserPassword: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  user: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    value: PropTypes.shape({
      username: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default PasswordReset
