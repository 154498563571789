import { yardListTypes } from '../../actions/yardListActions'

const initialState = {
  value: [],
  isLoading: false,
}

const allYardsMetrics = (state = initialState, { type, payload }) => {
  switch (type) {
    case yardListTypes.ALL_YARDS_METRICS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case yardListTypes.ALL_YARDS_METRICS_LOADED:
      return {
        ...state,
        isLoading: false,
        value: payload,
      }

    case yardListTypes.ALL_YARDS_METRICS_FAILED:
      return initialState

    default:
      return state
  }
}

export default allYardsMetrics
