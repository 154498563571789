import styled from 'styled-components/macro'

export const Container = styled.div`
  display: ${(props) =>
    props.screenX > 0 && props.screenY > 0 ? 'block' : 'none'};
  position: fixed;
  border-radius: var(--border-radius);
  width: 200px;
  height: fit-content;
  background: white;
  z-index: 1002;
  box-shadow: var(--box-shadow);
  left: ${(props) => `calc(${props.screenX}px + 30px)`};
  top: ${(props) => `calc(${props.screenY}px / 1.5)`};
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  width: 100%;
  background-color: var(--lighter-neutral-99);
  padding: 0 10px;
`

export const Subtitle = styled.span`
  display: block;
  font-size: 14px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: var(--base-red);
`

export const Content = styled.div`
  padding: 10px;
`

export const Icon = styled.img`
  height: 25px;
  width: 25px;
  padding: 4px;
  display: flex;
  background: var(--lighter-neutral-99);
  margin-right: 10px;
`

export const TextArea = styled.textarea`
  border: 1px solid var(--lighter-neutral-85);
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  height: 80px;
  resize: none;
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Button = styled.button`
  display: block;
  text-transform: capitalize;
  border: none;
  background-color: var(--base-blue);
  padding: 8px 0;
  border-radius: var(--border-radius);
  color: var(--lighter-neutral-99);
  transition: 0.2s all;
  font-family: 'ArticulatCF-DemiBold';
  width: 48%;

  :hover {
    background-color: var(--darker-blue-43);
  }

  ${(props) =>
    props.cancel &&
    `
    background-color: var(--lighter-neutral-99);
    color: var(--darker-neutral-45);
    animation: none;

    :hover {
      background-color: var(--lighter-neutral-85);
    }
  `}
`
