import React, { PureComponent } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import uniqueId from 'react-html-id'

import Dropdown from '../Dropdown'
import TextAreaInput from '../TextAreaInput'
import TextInput from '../TextInput'
import DatePickerInput from '../DatePickerInput'
import NewToggle from '../NewToggle'

import { matchNumericValueRegex, parseFraction } from '../../utils'

const getValue = (options) => {
  if (options === null) {
    return null
  }

  return Array.isArray(options)
    ? options.map((option) => option.value)
    : options.value
}

function getShouldTriggerOnChange(parsedValue) {
  if (typeof parsedValue !== 'string') {
    return true
  }

  return !parsedValue.endsWith('.')
}

class FormItem extends PureComponent {
  constructor(props) {
    super(props)

    uniqueId.enableUniqueIds(this)
  }

  renderInput(type, onChange, props) {
    const {
      form: { setFieldValue },
    } = this.props

    switch (type) {
      case 'select':
        return (
          <Dropdown
            {...props}
            onChange={(options) => {
              const value = getValue(options)
              setFieldValue(props.name, value)
              onChange(value)
            }}
          />
        )
      case 'textarea':
        return <TextAreaInput {...props} />
      case 'text':
      case 'password':
      case 'email':
        return <TextInput type={type} {...props} />
      case 'datepicker':
        return (
          <DatePickerInput
            type="white"
            setFieldValue={setFieldValue}
            {...props}
          />
        )
      case 'number':
      case 'tel':
        return (
          <TextInput
            type="tel"
            {...props}
            onKeyPress={matchNumericValueRegex}
            onChange={(event) => {
              const { value } = event.target
              const { fractionDigits } = props
              const parsedValue = _.isNil(fractionDigits)
                ? value
                : parseFraction({
                    value,
                    fractionDigits,
                  })

              setFieldValue(props.name, parsedValue)

              if (getShouldTriggerOnChange(parsedValue)) {
                onChange(parsedValue)
              }
            }}
          />
        )
      case 'bool':
        return (
          <NewToggle
            {...props}
            onChange={() => {
              const { value } = props
              setFieldValue(props.name, value)
              onChange(value)
            }}
          />
        )
      default:
        return null
    }
  }

  render() {
    const {
      field,
      form: { touched, errors },
      label,
      type,
      onChange,
      ...otherProps
    } = this.props

    const id = this.nextUniqueId()

    const inputProps = {
      ...field,
      ...otherProps,
      id,
    }

    const showError = errors[field.name] && touched[field.name]
    const errorLabel = errors[field.name]

    return (
      <div className="formItem">
        {label && (
          <label className="formItem-label" htmlFor={id}>
            {label}
          </label>
        )}
        <div className={showError ? 'inputItem errorItem' : 'inputItem'}>
          {this.renderInput(type, onChange, inputProps)}
        </div>
        {showError && <div className="formItem-error">{errorLabel}</div>}
      </div>
    )
  }
}

FormItem.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'select',
    'textarea',
    'password',
    'email',
    'datepicker',
  ]).isRequired,
  onChange: PropTypes.func,
}

FormItem.defaultProps = {
  label: undefined,
  onChange: () => {},
}

export default FormItem
