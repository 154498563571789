import React from 'react'

const iconOverview = ({ fill }) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0625 5.50667L14.1768 6.01974L8.77448 9.14748C8.45959 9.33495 7.96758 9.35468 7.69205 9.19681L2.15192 5.99014L1.18757 5.42774C0.912036 5.26987 0.941557 4.98374 1.26629 4.80614L7.55428 1.15546C7.86917 0.967994 8.36119 0.94826 8.63672 1.10613L15.1412 4.8752C15.4167 5.04294 15.3872 5.3192 15.0625 5.50667Z"
      stroke={fill || '#000'}
      strokeMiterlimit="10"
    />
    <path
      d="M15.0625 7.20384L8.77448 10.8446C8.45959 11.0321 7.96758 11.0518 7.69205 10.894L1.18757 7.1249C0.912036 6.96704 0.941557 6.6809 1.26629 6.5033L2.15192 5.99023L7.69205 9.19691C7.96758 9.35478 8.44975 9.33504 8.77448 9.14757L14.1768 6.01983L15.1412 6.58224C15.4167 6.7401 15.3872 7.01637 15.0625 7.20384Z"
      stroke={fill || '#000'}
      strokeMiterlimit="10"
    />
  </svg>
)

export default iconOverview
