import _ from 'lodash'

export default function getHistoryUserByVehicle({
  currentTrackingData,
  iconPinned,
  userListValue,
}) {
  const selectedVehicle = currentTrackingData.find(
    (trackingData) => iconPinned === trackingData.vehicle_id,
  )

  const userId = _.get(selectedVehicle, 'user_id')

  return userListValue.find((userItem) => userItem.id === userId)
}
