import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

class Tag extends Component {
  static propTypes = {
    tag: PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    }).isRequired,
    onToggle: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
  }

  toggle = () => {
    const { tag, onToggle, isReadOnly } = this.props

    if (!isReadOnly) {
      onToggle(tag)
    }
  }

  handleKeyDown = ({ key }) => {
    if (key === 'Enter' || key === ' ') {
      this.toggle()
    }
  }

  handleClick = () => {
    this.toggle()
  }

  render() {
    const {
      tag: { text, selected },
    } = this.props

    return (
      <span
        className={cx('tagsBox-tag', { selected })}
        onClick={this.handleClick}
        onKeyDown={this.handleKeyDown}
        role="button"
        tabIndex={0}
        data-analytics="click-tag"
      >
        {text}
      </span>
    )
  }
}

export default Tag
