import styled from 'styled-components/macro'
import { components } from 'react-select'

import OperationalBadge from '../../../images/assets/operational_badge.svg'
import UnderRepairBadge from '../../../images/assets/under_repair_badge.svg'
import InactiveBadge from '../../../images/assets/inactive_badge.svg'

function getBadge(value) {
  if (value === 'inactive') {
    return InactiveBadge
  }
  if (value === 'under_repair') {
    return UnderRepairBadge
  }
  return OperationalBadge
}

function getTextColor(value) {
  if (value === 'inactive') {
    return {
      color: 'var(--base-red) !important',
    }
  }
  if (value === 'under_repair') {
    return {
      color: 'var(--base-yellow) !important',
    }
  }
  return {
    color: 'var(--darker-green-33) !important',
  }
}

function getBackgroundColor(value) {
  if (value === 'inactive') {
    return {
      background: 'var(--lighter-red-94) !important',
    }
  }
  if (value === 'under_repair') {
    return {
      background: 'var(--lighter-yellow-94) !important',
    }
  }
  return {
    background: 'var(--lighter-green-88) !important',
  }
}

export const StyledOption = styled(components.Option)`
  ${(props) => getTextColor(props.value)}
  width: 95% !important;
  margin: 0 auto !important;

  &.react-select__option--is-selected {
    ${(props) => getBackgroundColor(props.value)}
  }
`

export const StyledSingleValue = styled(components.SingleValue)`
  ${(props) => getTextColor(props.value)}
`

export const StyledControl = styled(components.Control)`
  ${(props) => getBackgroundColor(props.value)}

  padding-left: 25px;
  background-image: url(${(props) => getBadge(props.value)}) !important;
  background-repeat: no-repeat !important;
  background-position: center left !important;

  .react-select__dropdown-indicator {
    ${(props) => getTextColor(props.value)}
  }
`
