import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import flagType from './propTypes'
import Flag from './Flag'
import Loading from '../Loading'
import Button from '../Button'
import turtleIcon from '../../images/icons/turtle.svg'

import './FlagsBox.css'

class FlagsBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isBoxHidden: true,
    }
  }

  handleToggleBox = () => {
    const { isBoxHidden } = this.state
    this.setState({ isBoxHidden: !isBoxHidden })
  }

  renderFlags() {
    const { flags, timezone, isLive } = this.props

    if (flags.loading) {
      return (
        <div className="flagsBox-loading">
          <Loading />
        </div>
      )
    }

    if (flags.value.length === 0) {
      return <div className="flagsBox-not-found">No flags today, yay!</div>
    }

    return flags.value.map((flag, index) => (
      /* eslint-disable-next-line react/no-array-index-key */
      <Fragment key={index}>
        <Flag isLive={isLive} flag={flag} timezone={timezone} />
        <hr />
      </Fragment>
    ))
  }

  renderBox() {
    const { style, expand } = this.props

    return (
      <div className={cx('flagsBox-container', { expand })} style={style}>
        <div className="flagsBox-flag-list">{this.renderFlags()}</div>
      </div>
    )
  }

  renderBadge = (counter) => {
    const { isBoxHidden } = this.state

    return counter && isBoxHidden ? (
      <span className="badge-icon">{counter}</span>
    ) : null
  }

  render() {
    const {
      flags: { value: flagsValue },
      isLive,
      theme,
    } = this.props
    const { isBoxHidden } = this.state

    return (
      <div className={cx('flagsBox', theme)}>
        {isBoxHidden && isLive ? null : this.renderBox()}
        {isLive && this.renderBadge(flagsValue.length)}
        {isLive && (
          <Button
            className="flagsBox-button"
            iconUrl={turtleIcon}
            onClick={this.handleToggleBox}
          />
        )}
      </div>
    )
  }
}

FlagsBox.propTypes = {
  timezone: PropTypes.string.isRequired,
  flags: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.shape(flagType)).isRequired,
  }).isRequired,
  theme: PropTypes.oneOf(['dark', 'light']),
  isLive: PropTypes.bool,
  style: PropTypes.shape({
    maxHeight: PropTypes.number,
    top: PropTypes.number,
  }),
  expand: PropTypes.bool,
}

FlagsBox.defaultProps = {
  theme: 'dark',
  isLive: false,
  style: null,
  expand: false,
}

export default FlagsBox
