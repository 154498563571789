import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { includes } from 'ramda'

import NavigationItem from './NavigationItem'

import { Blank } from '../../components'

import * as S from './styles'

import { usePrefetch } from '../../services'

import { history, getAuditsDefaultQuery } from '../../utils'

import {
  analyticsInitialMode,
  allYardsStartingDate,
  AUDITS_ROLES,
  BILLING_ROLES,
  ANALYTICS_ROLES,
  VEHICLES_ROLES,
  WORKERS_ROLES,
  YARD_MAP_ROLES,
} from '../../config'

import logoIcon from '../../images/assets/logo.svg'
import logoutIcon from '../../images/assets/logout.svg'
import vcrIcon from '../../images/assets/vcr.svg'
import vehiclesIcon from '../../images/assets/vehicle.svg'
import workersIcon from '../../images/assets/workers.svg'
import auditsIcon from '../../images/icons/audit_icon_white.svg'
import yardMapIcon from '../../images/assets/tracking.svg'
import yardListIcon from '../../images/icons/yardlist.svg'
import billingIcon from '../../images/icons/billing.svg'
import analyticsIcon from '../../images/icons/analytics.svg'

const NewSidebar = ({
  selectedYard,
  timezone,
  yardList,
  selectYard,
  logout,
  pathname,
  setVCRSortOption,
  setMetricsIntervalOption,
  getZones,
  userRole,
}) => {
  const [yardSelectorPlaceholder, setYardSelectorPlaceholder] = useState('')
  const [orderedYardList, setOrderedYardList] = useState([])
  const currentBasePathname = pathname.split('/')[3]

  const getAllYardsMetrics = usePrefetch('getAllYardsMetrics')

  /* START - ToggleButton TODO: ideally this toggle button would live in the header and pass isOpen prop to sidebar */
  const [isOpen, setIsOpen] = useState(false) // Initialize isOpen state
  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }
  /* END - ToggleButton TODO */

  useEffect(() => {
    if (yardList.length > 0) {
      const placeholderYard = yardList.find(
        (yard) => yard.id === parseInt(selectedYard, 10),
      )
      const orderedList = yardList.sort((a, b) => {
        const aName = a.name.toLowerCase()
        const bName = b.name.toLowerCase()

        return aName.localeCompare(bName)
      })

      setYardSelectorPlaceholder(
        `${placeholderYard.name} - ${placeholderYard.code}`,
      )
      setOrderedYardList(
        orderedList.map((item) => ({
          value: item.id,
          label: `${item.name} - ${item.code}`,
        })),
      )
    }
  }, [yardList, selectedYard])

  const handleNavigation = ({ path, query, action }) => {
    if (action === 'vcrs') {
      setVCRSortOption('all')
    } else if (action === 'people') {
      setMetricsIntervalOption('AVG_LAST_7_DAYS')
    }

    const relativePath = `/yard/${selectedYard}/${path}`
    history.push({ pathname: relativePath, query })
  }

  const handleChangeYard = ({ value, label }) => {
    setYardSelectorPlaceholder(label)
    getZones(value)
    selectYard(value)
  }

  const routes = [
    {
      pathname: 'vcrs',
      basePathname: 'vcrs',
      icon: vcrIcon,
      label: "VCR's",
      action: 'vcrs',
      disabled: !includes(userRole, VEHICLES_ROLES),
    },
    {
      pathname: 'vehicles',
      basePathname: 'vehicles',
      icon: vehiclesIcon,
      label: 'Vehicles',
      query: { view_mode: 'vehicles-list' },
      disabled: !includes(userRole, VEHICLES_ROLES),
    },
    {
      pathname: 'users',
      basePathname: 'users',
      icon: workersIcon,
      label: 'People',
      action: 'people',
      query: { view_mode: 'info' },
      disabled: !includes(userRole, WORKERS_ROLES),
    },
    {
      pathname: 'tracking/mode/live',
      basePathname: 'tracking',
      icon: yardMapIcon,
      label: 'Yard Map',
      disabled: !includes(userRole, YARD_MAP_ROLES),
    },
    {
      pathname: 'audits',
      basePathname: 'audits',
      icon: auditsIcon,
      label: 'Audits',
      query: getAuditsDefaultQuery(timezone),
      disabled: !includes(userRole, AUDITS_ROLES),
    },
    {
      pathname: `billing/date/${moment.tz(timezone).format('YYYY-MM-DD')}`,
      basePathname: 'billing',
      icon: billingIcon,
      label: 'Billing',
      disabled: !includes(userRole, BILLING_ROLES),
    },
    {
      pathname: `analytics/productivity/${analyticsInitialMode.productivity}`,
      basePathname: 'analytics',
      icon: analyticsIcon,
      label: 'Analytics',
      disabled: !includes(userRole, ANALYTICS_ROLES),
    },
  ]

  function renderAllYardsNavigationItem() {
    const navigationProps = {
      handleNavigation,
      currentBasePathname,
      routeBasePathname: 'allYards',
      routePathname: 'allYards',
      icon: yardListIcon,
      label: 'All Yards',
      routeQuery: { view_mode: 'yards' },
      onMouseEnter: () =>
        getAllYardsMetrics({ date: allYardsStartingDate }, { force: true }),
    }

    return <NavigationItem {...navigationProps} />
  }

  const NoOptionsMessage = (props) => (
    <Blank title="No results found" small {...props} />
  )

  return (
    <>
      {/* START - ToggleButton TODO: ideally this toggle button would live in the header and pass isOpen prop to sidebar */}
      <S.ToggleButton onClick={toggleSidebar}>
        <S.Toggle>
          <S.ToggleSpan isOpen={isOpen}></S.ToggleSpan>
          <S.ToggleSpan isOpen={isOpen}></S.ToggleSpan>
          <S.ToggleSpan isOpen={isOpen}></S.ToggleSpan>
          <S.ToggleSpan isOpen={isOpen}></S.ToggleSpan>
        </S.Toggle>
        <S.ToggleText>Menu</S.ToggleText>
      </S.ToggleButton>
      {/* END - ToggleButton TODO */}

      <S.Container isOpen={isOpen}>
        <S.Header>
          <S.Icon src={logoIcon} alt="Intrmodl" header />
          <S.StyledReactSelect
            className="react-select-container"
            classNamePrefix="react-select"
            components={{
              NoOptionsMessage,
            }}
            placeholder={yardSelectorPlaceholder}
            onChange={handleChangeYard}
            options={orderedYardList}
            length={orderedYardList.length}
          />
        </S.Header>

        <S.Content>
          {routes.map((route) => {
            const navigationProps = {
              handleNavigation,
              currentBasePathname,
              routeBasePathname: route.basePathname,
              routePathname: route.pathname,
              routeQuery: route.query,
              routeAction: route.action,
              icon: route.icon,
              label: route.label,
              disabled: route.disabled,
            }

            return <NavigationItem key={route.label} {...navigationProps} />
          })}
        </S.Content>

        {renderAllYardsNavigationItem()}

        <S.Logout onClick={logout}>
          <S.Icon src={logoutIcon} alt="Logout" />
          <S.Label>Log out</S.Label>
        </S.Logout>
      </S.Container>
    </>
  )
}

NewSidebar.propTypes = {
  selectedYard: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  logout: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  selectYard: PropTypes.func.isRequired,
  setVCRSortOption: PropTypes.func.isRequired,
  setMetricsIntervalOption: PropTypes.func.isRequired,
  getZones: PropTypes.func.isRequired,
  yardList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
  ),
  userRole: PropTypes.string,
}

NewSidebar.defaultProps = {
  selectedYard: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  yardList: [],
  userRole: undefined,
}

export default NewSidebar
