import styled from 'styled-components/macro'
import { alertAnimation, invertedProgressBar } from '../../css/sharedStyles'

function parseMillisecondsToSeconds(milliseconds) {
  return parseInt(milliseconds / 1000, 10)
}

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-content: center;
  width: 100%;
  z-index: 9999;
  max-width: 600px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.4s ease-in-out;
  pointer-events: none;

  ${(props) =>
    props.fullWidthStyle &&
    `
    left: 0px;
  `}
`

export const Message = styled.div`
  width: 800px;
  height: 90px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  font-family: 'ArticulatCF-Medium';
  font-size: 18px;
  bottom: 15px;
  position: absolute;
  width: 100%;
  transform: translateY(100px);
  opacity: 1;
  animation: ${alertAnimation}
    ${(props) => parseMillisecondsToSeconds(props.timeout)}s linear forwards;

  ${(props) =>
    props.type === 'success'
      ? `
        background-color: var(--base-green);
      `
      : `
        background-color: var(--base-red);
      `}
`

export const Icon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 20px;
`

export const ProgressBar = styled.div`
  height: 5px;
  width: 100%;
  background: ${(props) =>
    props.isSuccess ? 'var(--darker-green-33)' : 'var(--darker-red-35)'};
  position: absolute;
  bottom: 0;
  left: 0;
  animation: ${invertedProgressBar}
    ${(props) => parseMillisecondsToSeconds(props.timeout)}s ease-out forwards;
`
