import React from 'react'

const iconComment = ({ fill }) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.6209 4.09375C4.32908 4.11009 4.05523 4.23995 3.85792 4.45558C3.66061 4.67121 3.55549 4.95548 3.56505 5.24761C3.57462 5.53973 3.69809 5.81651 3.90909 6.01877C4.12008 6.22104 4.40183 6.33272 4.6941 6.32993C4.7175 6.32993 4.7415 6.32993 4.7649 6.32993C5.05862 6.30742 5.33163 6.17006 5.52476 5.94762C5.71789 5.72518 5.81557 5.43559 5.79662 5.14162C5.77767 4.84764 5.64363 4.57298 5.42355 4.37717C5.20346 4.18136 4.91508 4.08017 4.6209 4.09555V4.09375Z"
      fill={fill || '#1D79FF'}
    />
    <path
      d="M7.72664 4.09375C7.43466 4.11002 7.16066 4.23995 6.96328 4.45572C6.7659 4.67149 6.66082 4.95595 6.67054 5.24822C6.68027 5.54049 6.80402 5.81734 7.01531 6.01951C7.22659 6.22168 7.50862 6.3331 7.80103 6.32993C7.82443 6.32993 7.84784 6.32993 7.87184 6.32993C8.16556 6.30742 8.43857 6.17006 8.6317 5.94762C8.82483 5.72518 8.9225 5.43559 8.90356 5.14162C8.88461 4.84764 8.75056 4.57298 8.53048 4.37717C8.3104 4.18136 8.02203 4.08017 7.72785 4.09555L7.72664 4.09375Z"
      fill={fill || '#1D79FF'}
    />
    <path
      d="M11.2115 0H4.38716C3.81032 0.000708696 3.23927 0.115027 2.70661 0.336429C2.17395 0.557831 1.69012 0.88198 1.28273 1.29037C0.875349 1.69876 0.552391 2.18339 0.3323 2.71659C0.112208 3.24979 -0.000706244 3.82112 3.32357e-06 4.39796V11.6663C3.18871e-05 11.7222 0.0141128 11.7772 0.0409519 11.8263C0.0677911 11.8753 0.106528 11.9168 0.153602 11.947C0.200676 11.9772 0.254577 11.995 0.310352 11.9989C0.366128 12.0028 0.421988 11.9926 0.472799 11.9693L4.11236 10.2749C4.39143 10.1459 4.6953 10.0793 5.00276 10.0799H11.2127C11.7897 10.0792 12.361 9.9648 12.8938 9.74325C13.4266 9.52169 13.9106 9.19733 14.318 8.78869C14.7254 8.38004 15.0483 7.89513 15.2682 7.36165C15.4882 6.82818 15.6009 6.25659 15.5999 5.67955V4.39796C15.6 3.23316 15.138 2.11588 14.3153 1.29135C13.4926 0.46681 12.3763 0.00238394 11.2115 0V0ZM1.64579 9.87831C1.61928 9.8906 1.5901 9.89602 1.56094 9.89406C1.53179 9.89211 1.5036 9.88284 1.47897 9.86712C1.45434 9.85139 1.43406 9.82972 1.42001 9.8041C1.40597 9.77848 1.3986 9.74973 1.39859 9.72051V4.39796C1.39954 3.60491 1.71446 2.8445 2.2745 2.283C2.83453 1.7215 3.59412 1.4046 4.38716 1.40159H11.2115C12.005 1.40397 12.7652 1.72059 13.3257 2.28216C13.8863 2.84373 14.2015 3.6045 14.2025 4.39796V5.67775C14.2033 6.07112 14.1265 6.4608 13.9766 6.8245C13.8268 7.1882 13.6067 7.51881 13.3289 7.79741C13.0512 8.07602 12.7213 8.29716 12.3581 8.44821C11.9949 8.59926 11.6055 8.67725 11.2121 8.67772H4.69556C4.38771 8.67764 4.08358 8.74502 3.80457 8.87512L1.64579 9.87831Z"
      fill={fill || '#1D79FF'}
    />
    <path
      d="M12.0209 5.28362C12.0347 5.06034 11.9811 4.83808 11.8671 4.64564C11.753 4.4532 11.5837 4.29947 11.3812 4.20441C11.1787 4.10935 10.9524 4.07734 10.7314 4.11253C10.5105 4.14772 10.3053 4.24849 10.1423 4.40176C9.97941 4.55503 9.8663 4.75375 9.81769 4.9721C9.76907 5.19045 9.7872 5.41837 9.86972 5.62629C9.95224 5.83422 10.0953 6.01254 10.2805 6.13813C10.4656 6.26372 10.6842 6.33078 10.9079 6.33061C10.9313 6.33061 10.9547 6.33061 10.9787 6.33061C11.25 6.3126 11.5054 6.19638 11.6972 6.00368C11.889 5.81098 12.0041 5.55499 12.0209 5.28362Z"
      fill={fill || '#1D79FF'}
    />
  </svg>
)

export default iconComment
