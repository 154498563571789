import React, { useState, useEffect } from 'react'
import { prop, path, sortBy, isEmpty, includes, defaultTo } from 'ramda'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  VEHICLE_STATUS_WRITE_ROLES,
  CRUD_YARD_WRITE_ROLES,
} from '../../permissions/config'
import {
  getAllYardsHeaders,
  vehiclesHeaders,
  getAllYardsRows,
  getVehicleRows,
  getFilterArray,
  getFilterOptions,
  getFilteredRows,
} from './helpers'
import People from './People'
import * as S from './styles'

import {
  NewTable,
  NewContainer,
  NewHeader,
  NewTabs,
  NewModal,
  NewToolTip,
  NewToggle,
} from '../../components'
import { history } from '../../utils'
import { allYardsStartingDate } from '../../config'
import {
  useGetAllYardsMetricsQuery,
  useDeleteYardMutation,
} from '../../services/allYards'
import { useGetAllVehiclesQuery } from '../../services/vehicles'

import addIcon from '../../images/icons/icon_add_dark.svg'
import rmsIcon from '../../images/icons/icon_rms_logo.svg'
import customersIcon from '../../images/icons/icon_customer.svg'
import editIcon from '../../images/icons/icon_edit_big.svg'
import trashIcon from '../../images/icons/icon_trash_white.svg'

const tabOptions = [
  {
    key: 0,
    value: 'yards',
  },
  {
    key: 1,
    value: 'vehicles',
  },
  {
    key: 2,
    value: 'people',
  },
]

const AllYards = ({
  selectYard,
  resetTracking,
  loadVcrIssues,
  setSortOrder,
  setSortedHeader,
  tableSortOption,
  tableSortHeader,
  viewMode,
  selectedYard,
  timezone,
  company,
  railroad,
  loggedUser,
  showAlert,
  showErrorAlert,
}) => {
  const [searchString, setSearchString] = useState('')
  const [activeTab, setActiveTab] = useState(viewMode)
  const [showDeleteYardModal, setShowDeleteYardModal] = useState(false)
  const [yardToDelete, setYardToDelete] = useState()
  const [showActiveYards, setShowActiveYards] = useState(true)

  const userRole = path(['Role', 'name'])(loggedUser)
  const hasVehicleWriteAccess = includes(userRole, VEHICLE_STATUS_WRITE_ROLES)
  const hasCrudYardWriteAccess = includes(userRole, CRUD_YARD_WRITE_ROLES)

  const [deleteYard] = useDeleteYardMutation()

  const titleActionList = [
    {
      key: 1,
      icon: rmsIcon,
      tooltip: {
        title: 'Company List',
        action: 'Click to see the company list',
      },
      onClick: () => history.push('/allYards/companies'),
    },
    {
      key: 2,
      icon: customersIcon,
      tooltip: {
        title: 'Customer List',
        action: 'Click to see the customer list',
      },
      onClick: () => history.push('/allYards/customers'),
    },
  ]

  if (hasCrudYardWriteAccess) {
    titleActionList.push({
      key: 0,
      icon: addIcon,
      tooltip: {
        title: 'Add new yard',
        action: 'Click to create a new yard',
      },
      onClick: () => history.push('/allYards/create'),
    })
  }
  const titleAction = sortBy(prop('key'))(titleActionList)

  const {
    data: allYardsMetrics = [],
    isLoading: isLoadingAllYards,
    refetch: refetchAllYardsMetrics,
  } = useGetAllYardsMetricsQuery({
    date: allYardsStartingDate,
  })

  const companies = getFilterArray({
    list: allYardsMetrics,
    field: 'yard_billing_company',
  })
  const railroads = getFilterArray({
    list: allYardsMetrics,
    field: 'yard_billing_customer',
  })

  const {
    data: vehicleList,
    isLoading: isLoadingVehicles,
    refetch: refetchVehicleList,
  } = useGetAllVehiclesQuery(null, {
    skip: viewMode !== 'vehicles',
  })

  const isLoading = isLoadingAllYards || isLoadingVehicles

  useEffect(() => {
    setSearchString('')

    history.push({
      pathname: `/yard/${selectedYard}/allYards`,
      query: { view_mode: activeTab, company, railroad },
    })
  }, [activeTab, company, railroad, selectedYard, viewMode])

  useEffect(() => {
    if (!viewMode) {
      setActiveTab('yards')
    }

    if (viewMode === 'yards') {
      refetchAllYardsMetrics()
    }

    if (viewMode === 'vehicles') {
      refetchVehicleList()
    }
  }, [
    activeTab,
    viewMode,
    refetchAllYardsMetrics,
    refetchVehicleList,
    vehicleList,
  ])

  function handleClickRow(id) {
    if (_.isNil(id)) {
      return
    }

    selectYard(id)
    loadVcrIssues(id)
    resetTracking()

    history.push(`/yardSummary/date/${allYardsStartingDate}`)
  }

  function handleEditYard({ e, id }) {
    e.stopPropagation()

    if (_.isNil(id)) {
      return
    }

    history.push(`/allYards/create/${id}`)
  }

  async function handleDeleteYard() {
    if (_.isNil(yardToDelete)) {
      return
    }

    await deleteYard({ yardId: yardToDelete })
      .unwrap()
      .then(() => {
        setSearchString('')
        setYardToDelete(null)
        refetchAllYardsMetrics()

        return showAlert({
          type: 'success',
          message: 'Yard successfully deleted',
        })
      })
      .catch((error) =>
        showErrorAlert({
          error,
          defaultMessage: 'Failed to delete yard',
        }),
      )
  }

  function handleCloseModal() {
    setShowDeleteYardModal(false)
    setYardToDelete(null)
  }

  function handleClickCell({ event, yardId, path: cellPath }) {
    event.stopPropagation()

    if (_.isNil(yardId)) {
      return
    }

    selectYard(yardId)
    resetTracking()

    history.push(cellPath)
  }

  function renderActionMenu({ yardId }) {
    const actions = [
      {
        icon: editIcon,
        action: handleEditYard,
        toolTip: {
          title: 'Edit Yard',
          description: 'Click to change yard details',
        },
      },
      {
        icon: trashIcon,
        action: () => setShowDeleteYardModal(true),
        toolTip: {
          title: 'Delete yard',
          description: 'Click to remove this yard',
        },
      },
    ]

    return (
      <S.ActionContainer>
        {actions.map((userAction, index) => (
          <NewToolTip key={index} {...userAction.toolTip}>
            <S.Action
              icon={userAction.icon}
              onClick={(e) => {
                e.stopPropagation()
                setYardToDelete(yardId)
                userAction.action({ e, id: yardId })
              }}
            >
              <S.Image src={userAction.icon} />
            </S.Action>
          </NewToolTip>
        ))}
      </S.ActionContainer>
    )
  }

  const yardListRows = getAllYardsRows({
    allYards: allYardsMetrics,
    handleClickCell,
    handleClickRow,
    renderActionMenu,
    hasCrudYardWriteAccess,
  })

  const vehicleRows = getVehicleRows({
    vehicleList: _.defaultTo(_.get(vehicleList, 'vehicles'), []),
    timezone,
    hasWriteAccess: hasVehicleWriteAccess,
  })

  function getTableContent() {
    if (activeTab === 'yards') {
      return {
        rows: yardListRows,
        headers: getAllYardsHeaders({ hasCrudYardWriteAccess }),
      }
    }

    if (activeTab === 'vehicles') {
      return {
        rows: vehicleRows,
        headers: vehiclesHeaders,
      }
    }

    return { rows: [], headers: [] }
  }

  const { rows, headers } = getTableContent()

  const filteredRows = getFilteredRows({
    rows,
    company,
    railroad,
    showActiveYards,
    activeTab,
  })

  const sortedRows = _.orderBy(
    filteredRows,
    (row) => row.columns[tableSortHeader].value,
    [tableSortOption],
  )

  const handleSortedHeader = (headerIndex) => {
    setSortOrder(tableSortOption === 'desc' ? 'asc' : 'desc')
    setSortedHeader(headerIndex)
  }

  const search = {
    handleSearchQuery: (string) => setSearchString(string),
    placeholder: `Search ${activeTab}`,
    value: searchString,
  }

  const tabs = {
    items: tabOptions,
    activeItem: activeTab,
    onChange: (value) => setActiveTab(value),
  }

  const headerProps = {
    title: 'All Yards',
    titleAction,
    search,
    tabs,
  }

  const tableProps = {
    headers,
    isLoading,
    searchString,
    rows: sortedRows,
    sortOrder: tableSortOption,
    sortedHeader: tableSortHeader,
    onSortedHeaderChange: handleSortedHeader,
  }

  const companyFilterOptions = getFilterOptions({
    list: companies,
  })

  const companyTabs = {
    items: companyFilterOptions,
    activeItem: defaultTo('ALL')(company),
    onChange: (value) =>
      history.push({
        pathname: `/yard/${selectedYard}/allYards`,
        query: { view_mode: activeTab, company: value, railroad },
      }),
  }

  const railroadFilterOptions = getFilterOptions({
    list: railroads,
  })

  const railroadTabs = {
    items: railroadFilterOptions,
    activeItem: defaultTo('ALL')(railroad),
    onChange: (value) =>
      history.push({
        pathname: `/yard/${selectedYard}/allYards`,
        query: { view_mode: activeTab, railroad: value, company },
      }),
  }

  const peopleProps = {
    searchString,
    loggedUser,
    showAlert,
  }

  const deleteUserModalProps = {
    visible: showDeleteYardModal,
    onConfirm: handleDeleteYard,
    onClose: handleCloseModal,
    onCancel: handleCloseModal,
    title: 'Are you sure?',
    message: `Do you really want to delete this yard?`,
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    type: 'warning',
  }

  function handleToggleChange() {
    return setShowActiveYards(!showActiveYards)
  }

  return (
    <>
      <NewHeader {...headerProps} />

      <NewContainer>
        {activeTab === 'yards' && (
          <S.ToggleContainer>
            <NewToggle
              isChecked={showActiveYards}
              onChange={handleToggleChange}
              text={`Toggle to show ${
                showActiveYards ? 'INACTIVE' : 'ACTIVE'
              } yards only`}
            />
          </S.ToggleContainer>
        )}
        {activeTab === 'people' ? (
          <People {...peopleProps} />
        ) : (
          <>
            {activeTab === 'yards' &&
              (!isEmpty(railroadFilterOptions) ||
                !isEmpty(companyFilterOptions)) && (
                <S.SubMenu>
                  <NewTabs {...companyTabs} />
                  <NewTabs {...railroadTabs} />
                </S.SubMenu>
              )}
            <NewTable {...tableProps} />
          </>
        )}
      </NewContainer>
      <NewModal {...deleteUserModalProps} />
    </>
  )
}

AllYards.propTypes = {
  selectYard: PropTypes.func.isRequired,
  resetTracking: PropTypes.func.isRequired,
  loadVcrIssues: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  setSortedHeader: PropTypes.func.isRequired,
  tableSortOption: PropTypes.string.isRequired,
  tableSortHeader: PropTypes.number.isRequired,
  viewMode: PropTypes.oneOf(['yards', 'vehicles', 'people']).isRequired,
  selectedYard: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  company: PropTypes.string,
  railroad: PropTypes.string,
  loggedUser: PropTypes.object.isRequired,
  showAlert: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
}

AllYards.defaultProps = {
  company: 'ALL',
  railroad: 'ALL',
}

export default AllYards
