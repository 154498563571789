import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NewSidebar from '../NewSidebar'

import syncActionsBeforeStart from '../../syncActionsBeforeStart'

import * as S from './styles'

syncActionsBeforeStart()

class App extends Component {
  componentDidMount() {
    const { verifyToken } = this.props
    verifyToken()
  }

  render() {
    const { logout, children, pathname } = this.props

    return (
      <S.Container>
        <NewSidebar logout={logout} pathname={pathname} />
        <S.ChildrenContainer>{children}</S.ChildrenContainer>
      </S.Container>
    )
  }
}

App.propTypes = {
  verifyToken: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  pathname: PropTypes.string.isRequired,
}

App.defaultProps = {
  children: null,
}

export default App
