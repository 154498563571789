import styled, { css } from 'styled-components/macro'
import { defaultCard } from '../../css/sharedStyles'

const grid = css`
  display: grid;
  min-width: 100%;
  width: fit-content;
  grid-template-columns: 120px 120px repeat(
      ${(p) => p.length - 2},
      minmax(75px, 1fr)
    );
`

const sharedContainer = css`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div`
  ${defaultCard}
  overflow-y: auto;
  margin: 0 auto;
  height: fit-content;
  border: 1px solid var(--base-neutral);
  width: calc(100% - 40px);

  ${(p) =>
    p.editMode !== 'view' &&
    p.status !== 'locked' &&
    `border: 5px solid var(${p.editModeColor});`}
`

export const Body = styled.div`
  display: grid;
`

export const Row = styled.div`
  ${grid}

  &:nth-child(even) {
    background-color: var(--lighter-neutral-96);
  }
`

export const Cell = styled.div`
  font-size: 0.9rem;
  border-right: 1px solid var(--lighter-neutral-85);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: 'ArticulatCF-Medium';

  :nth-child(1) {
    color: ${(p) => p.isWarning && `var(--base-red)`};
    text-transform: uppercase;
    white-space: normal;
    font-family: 'ArticulatCF-Bold';
  }

  :nth-child(2),
  :nth-child(3) {
    font-family: 'ArticulatCF-Bold';
  }

  &:last-of-type {
    border-right: none;
  }

  ${(p) =>
    p.isClickEnabled &&
    `
    &:hover {
      cursor: pointer;
      border: 2px solid var(${p.editModeColor}) !important;
      opacity: 1;
    }
  `}

  ${(p) =>
    p.isDisabled &&
    `
    background-color: var(--lighter-neutral-96);
    opacity: .7;
    pointer-events: none;
  `}

  ${(p) => p.isValueMissing && `background: var(--lighter-red-83-transparent);`}

  ${(props) =>
    typeof props.headerKey === 'string' &&
    `
    cursor: pointer;
  `}
`

export const Header = styled.div`
  ${grid}
  background: var(--lighter-neutral-92);

  & span {
    color: var(--darker-neutral-45);
    font-size: 0.85rem;
    text-transform: uppercase;
  }

  ${Cell} {
    height: 55px;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  text-align: center;
  outline-color: var(--base-blue);

  ${(p) =>
    p.isValueMissing &&
    `
    outline-color: var(--base-red);
  `}

  ${(p) =>
    p.isCurrentEditing &&
    `
    background-color: var(${p.editModeColor}-transparent);
  `}

  ${(p) => p.hasWarningValue && `color: var(--base-red);`}
`

export const ClickableContainer = styled.div`
  ${sharedContainer}

  ${(p) =>
    p.currentEditing &&
    `
    background-color: var(${p.editModeColor}-transparent);
  `}
`

export const ObjectContainer = styled.div`
  ${sharedContainer}
`

export const BillingComment = styled.img`
  position: absolute;
  left: 6px;
  top: 6px;
  opacity: 0.7;
  transition: 0.2s all ease;
  object-fit: cover;
  width: 15px;

  :hover {
    cursor: pointer;
    opacity: 1;
  }
`

export const SortIcon = styled.span`
  display: inline-block;
  max-width: 80%;
  margin-left: 2px;
`
