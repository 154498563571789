import * as yup from 'yup'
import { pluck } from 'ramda'
import { getValidationSchema, getInitialFormFields } from './helpers'
import { FormItem } from '../../components'

const requiredMessage = 'Required'

const fields = [
  {
    name: 'name',
    validation: yup.string().required(requiredMessage),
    type: 'text',
    label: 'Template Name',
  },
  {
    name: 'description',
    validation: yup.string().nullable(),
    type: 'textarea',
    label: 'Template Notes',
    placeholder: 'Add notes here',
  },
]

export const returnPath = '/billing/templates'

export const validationSchema = getValidationSchema(fields)

export const formFields = getInitialFormFields(fields).map((field) => ({
  ...field,
  component: FormItem,
}))

export const unsavedChangesModalProps = {
  title: 'Do you really want to leave?',
  message: 'You have unsaved changes, do you want to leave without saving it?',
  confirmText: 'Keep editing',
  cancelText: 'Leave',
  type: 'warning',
  isMandatory: true,
}

export const confirmChangesModalProps = {
  title: 'Do you want to save?',
  message: 'Are you sure to submit those changes?',
  confirmText: 'Submit changes',
  cancelText: 'Review changes',
  type: 'warning',
  isMandatory: true,
}

export const FIELDS_TO_UPDATE = pluck('name')(fields)
