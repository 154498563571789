import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Blank from '../Blank'
import IconText from '../IconText'
import SendForm from '../SendForm/SendForm'

import { getDateByDays } from '../../utils/momentExtensions'

import Comment from '../../images/icons/comment_icon.svg'
import clockIcon from '../../images/assets/comment_clock.svg'
import mechanicIcon from '../../images/assets/comment_mechanic.svg'
import driverIcon from '../../images/assets/comment_driver.svg'
import managerIcon from '../../images/assets/comment_manager.svg'
import crownIcon from '../../images/assets/comment_crown.svg'

import './CommentBox.css'

const icons = {
  DRIVER: driverIcon,
  MANAGER: managerIcon,
  ADMIN: crownIcon,
  SUPER_ADMIN: crownIcon,
  MECHANIC: mechanicIcon,
  SUPERVISOR: crownIcon,
  UPPER_MANAGER: crownIcon,
  GATE_INSPECTOR: crownIcon,
}

const color = {
  DRIVER: '#972AEA',
  MANAGER: '#FE775C',
  ADMIN: '#fae059',
  SUPER_ADMIN: '#fae059',
  MECHANIC: '#00D5A2',
  SUPERVISOR: '#fae059',
  UPPER_MANAGER: '#fae059',
  GATE_INSPECTOR: '#fae059',
}

function hasNoComment({ comments }) {
  return _.chain(comments).filter('comment').isEmpty().value()
}

class CommentBox extends Component {
  componentDidUpdate(prevProps) {
    const { comments } = this.props
    if (!hasNoComment({ comments }) && prevProps.comments !== comments) {
      this.commentsRef.scrollTop = 0
    }
  }

  renderCommentInputForm() {
    const {
      sendComment,
      postCommentState: { loading: isLoading },
      isReadOnly,
    } = this.props

    if (isReadOnly) {
      return null
    }

    return <SendForm onSubmit={sendComment} loading={isLoading} />
  }

  render() {
    const { comments, title } = this.props

    /* eslint-disable no-return-assign */
    return (
      <div className="commentBoxContainer">
        {!_.isNil(title) && (
          <IconText
            className="commentBoxHeader"
            iconUrl={Comment}
            text={title}
          />
        )}
        <div ref={(ref) => (this.commentsRef = ref)} className="commentBox">
          {hasNoComment({ comments }) ? (
            <Blank title={`No ${title || 'content'} yet`} type="search" />
          ) : (
            comments
              .slice(0)
              .reverse()
              .map((element) => {
                const style = {
                  color: color[element.User.Role.name],
                  borderColor: color[element.User.Role.name],
                }
                return (
                  <div key={element.created_at}>
                    <div className="elmentComment">{element.comment}</div>
                    <div className="elementDetail flex-row">
                      <div className="elementLeft flex-row">
                        <div className="userName">
                          {`${element.User.first_name} ${element.User.last_name}`}
                        </div>
                        <div className="elementName" style={style}>
                          <img src={icons[element.User.Role.name]} alt="role" />
                          {_.chain(element.User.Role.name)
                            .toLower()
                            .startCase()
                            .value()}
                        </div>
                      </div>
                      <div className="elementDate">
                        <img
                          className="commentClock"
                          src={clockIcon}
                          alt="clock"
                        />
                        {getDateByDays(element.created_at)}
                      </div>
                    </div>
                  </div>
                )
              })
          )}
        </div>
        {this.renderCommentInputForm()}
      </div>
      /* eslint-enable no-return-assign */
    )
  }
}

CommentBox.propTypes = {
  title: PropTypes.string,
  postCommentState: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }),
  sendComment: PropTypes.func,
  comments: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ]),
    ),
  ),
  isReadOnly: PropTypes.bool,
}

CommentBox.defaultProps = {
  title: null,
  sendComment: () => undefined,
  comments: [],
  isReadOnly: false,
  postCommentState: {
    loading: false,
  },
}

export default CommentBox
