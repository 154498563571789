import { manageUsersTypes } from '../../actions/manageUsersActions'

const initialState = 'desc'

const tableSortOption = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageUsersTypes.LIST_ORDERING_CHANGED:
      return payload

    default:
      return state
  }
}

export default tableSortOption
