import React from 'react'
import PropTypes from 'prop-types'
import { issuePropType } from '../../containers/VCR/propTypes'

import * as IssuesIcons from './issues'

import './IssueList.css'

const IssueList = ({ issues }) => (
  <div className="issue__state flex-row">
    {!issues.length ? (
      <div className="noIssueReport flex-row">
        <p>&#xf058;</p>
        No issues reported
      </div>
    ) : (
      issues.map((item) => (
        <div className="issue__state-item flex-row" key={item.id}>
          <img
            className="issue__state-icon"
            src={
              !IssuesIcons.default[item.VcrTemplateIssue.ordinal]
                ? IssuesIcons.default.defaultIcon
                : IssuesIcons.default[item.VcrTemplateIssue.ordinal]
            }
            alt="issue icon"
          />
          {item.VcrTemplateIssue.VcrTemplateIssueName.description}
        </div>
      ))
    )}
  </div>
)

IssueList.propTypes = {
  issues: PropTypes.arrayOf(PropTypes.shape(issuePropType)).isRequired,
}

export default IssueList
