import * as Sentry from '@sentry/browser'
import Promise from 'bluebird'
import _ from 'lodash'
import api from '../api'
import { createTypes } from './utils'
import { history } from '../utils'
import { showAlert } from './alertMessageActions'

export const passwordResetTypes = createTypes('passwordReset', [
  { value: 'USER', isAsync: true },
])

const redirectToLogin = () =>
  Promise.resolve()
    .then(() => {
      const token = localStorage.getItem('token')
      if (!_.isNil(token)) {
        return api.auth.logout()
      }

      return undefined
    })
    .finally(() => {
      Sentry.configureScope((scope) => scope.setUser(null))
      localStorage.removeItem('token')
      history.push('/login')
    })

export const resetUserPassword = (params) => (dispatch) =>
  Promise.resolve(params)
    .then(api.passwordToken.resetUserPassword)
    .then(
      () => {
        dispatch(
          showAlert({
            type: 'success',
            message:
              'Your new password has been saved. Please wait to be redirected to the log in page',
            fullWidthStyle: true,
          }),
        )

        return redirectToLogin()
      },
      (error) => {
        const errorCode = _.get(error, 'response.data.error.code')

        const isTokenInvalid = ['INVALID_TOKEN', 'EXPIRED_TOKEN'].includes(
          errorCode,
        )
        const message = isTokenInvalid
          ? "This link isn't valid anymore."
          : 'Something went wrong. Please try again later.'

        dispatch(
          showAlert({
            type: 'failure',
            message,
            fullWidthStyle: true,
          }),
        )
      },
    )

export const loadUser = (params) => (dispatch) => {
  dispatch({ type: passwordResetTypes.USER_REQUESTED })

  return Promise.resolve(params)
    .then(api.passwordToken.getUser)
    .then(({ user }) => {
      dispatch({
        type: passwordResetTypes.USER_LOADED,
        payload: { user },
      })
    })
    .tapCatch(() => {
      dispatch({ type: passwordResetTypes.USER_FAILED })
    })
}
