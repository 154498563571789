import { roleNameToId } from '../../config'

const auditsWorkerOptions = {
  AUDITOR: {
    roleName: [
      'MECHANIC',
      'MANAGER',
      'UPPER_MANAGER',
      'SUPERVISOR',
      'ADMIN',
      'SUPER_ADMIN',
    ],
    apiId: 'auditorId',
  },
  SUPERVISOR: {
    roleName: [
      'SUPERVISOR',
      'MANAGER',
      'UPPER_MANAGER',
      'ADMIN',
      'SUPER_ADMIN',
    ],
    apiId: 'supervisorId',
  },
  EMPLOYEE: {
    roleName: Object.keys(roleNameToId),
    apiId: 'employeeId',
  },
}

export default auditsWorkerOptions
