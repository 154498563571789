import React, { useEffect } from 'react'
import { path, includes } from 'ramda'
import store from '../store'
import { history } from '../utils'
import { showAlert } from '../actions/alertMessageActions'
import { rolePermission, ADMIN_ROLES } from '../config'

function hasYardAccess(userData) {
  const { allowedYards } = userData
  const userRole = path(['Role', 'name'])(userData)

  if (includes(userRole, ADMIN_ROLES)) {
    return true
  }

  const paramYardId = Number(history.getVariable('yard'))
  return allowedYards.some((yard) => yard.id === paramYardId)
}

function hasRoleAccess(allowedRoles, userData) {
  const userRole = path(['Role', 'name'])(userData)
  return includes(userRole, allowedRoles)
}

function getHasAccess(allowedRoles, userData) {
  return hasRoleAccess(allowedRoles, userData) && hasYardAccess(userData)
}

function rejectAccess(userData) {
  store.dispatch(
    showAlert({
      type: 'failure',
      message: "You don't have access to this page.",
    }),
  )
  const { yard_id: ownYardId } = userData
  const userRole = path(['Role', 'name'])(userData)

  const { homeURL } = rolePermission[userRole]
  history.push(`/yard/${ownYardId}${homeURL}`)
}

const withAuthorization = (allowedRoles) => (WrappedComponent) => {
  const Authorization = (props) => {
    const { userData } = store.getState().general
    const hasAccess = getHasAccess(allowedRoles, userData)
    useEffect(() => {
      if (!hasAccess) {
        rejectAccess(userData)
      }
    }, [hasAccess, userData])

    if (hasAccess) {
      return <WrappedComponent {...props} />
    }

    return null
  }

  return Authorization
}

export default withAuthorization
