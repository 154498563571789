import moment from 'moment-timezone'
import { engineHoursEditablePeriod } from '../config'

function getStartOfEditablePeriod({ date, timezone }) {
  return moment
    .tz(date, timezone)
    .endOf('month')
    .add(engineHoursEditablePeriod.start, 'day')
    .startOf('day')
}

export default getStartOfEditablePeriod
