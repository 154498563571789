import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment-timezone'
import _ from 'lodash'
import ReactDatePickerForMoment from '../ReactDatePickerForMoment'

const DatePicker = ({ type, showCurrentDate, maxDate, ...props }) => {
  const className = cx('datePicker', type)

  const defaultMaxDate = showCurrentDate ? moment() : moment().subtract(1, 'd')
  const maxDateOrDefault = _.isNil(maxDate) ? defaultMaxDate : maxDate

  return (
    <div className={className}>
      <ReactDatePickerForMoment
        {...props}
        maxDate={maxDateOrDefault}
        todayButton={showCurrentDate ? 'Today' : null}
      />
    </div>
  )
}

DatePicker.propTypes = {
  ...ReactDatePickerForMoment.propTypes,
  dropdownMode: PropTypes.oneOf(['scroll', 'select']),
  type: PropTypes.oneOf(['white', 'black']).isRequired,
  showCurrentDate: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
}

DatePicker.defaultProps = {
  dropdownMode: 'scroll',
  showCurrentDate: false,
}

export default DatePicker
