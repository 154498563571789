import React, { useEffect } from 'react'
import _ from 'lodash'

import {
  Blank,
  SendForm,
  VehicleDetailsListContainer,
  VehicleDetailsSingleNote,
} from '../../components'

import * as S from './styles'

const VehicleDetailsNotes = ({
  userData,
  loadComments,
  vehicleId,
  commentList,
  postVehicleComment,
  timezone,
  hasWriteAccess,
}) => {
  useEffect(() => {
    loadComments({
      vehicleId,
      limit: 10,
      offset: 0,
    })
  }, [loadComments, vehicleId])

  const listValue = _.get(commentList, 'value', [])
  const isLoading = _.get(commentList, 'isLoading')

  const notes = listValue.map((comment) => {
    const key = _.get(comment, 'id')
    const props = {
      comment,
      timezone,
    }

    return <VehicleDetailsSingleNote key={key} {...props} />
  })

  function handleScrollEnd() {
    const nextOffset = _.get(commentList, 'nextOffset')

    return _.isNil(nextOffset)
      ? null
      : loadComments({
          vehicleId,
          limit: 10,
          offset: nextOffset,
          isLazyLoading: true,
        })
  }

  function submitComment(comment) {
    const commentData = {
      User: {
        first_name: _.get(userData, 'first_name'),
        last_name: _.get(userData, 'last_name'),
        Role: {
          name: _.get(userData, 'Role.name'),
        },
      },
      created_at: new Date().toISOString(),
      comment,
    }

    return postVehicleComment(vehicleId, commentData)
  }

  function renderSendForm() {
    if (!hasWriteAccess) {
      return null
    }

    return (
      <S.FormContainer>
        <SendForm onSubmit={submitComment} loading={isLoading} />
      </S.FormContainer>
    )
  }

  function renderList() {
    if (_.isEmpty(listValue) && !isLoading) {
      return (
        <S.EmptyContainer>
          <Blank title="There are no comments yet" type="search" />
        </S.EmptyContainer>
      )
    }

    return (
      <VehicleDetailsListContainer
        isLoading={isLoading}
        handleScrollEnd={handleScrollEnd}
      >
        {notes}
      </VehicleDetailsListContainer>
    )
  }

  function render() {
    return (
      <>
        {renderSendForm()}
        {renderList()}
      </>
    )
  }

  return render()
}

export default VehicleDetailsNotes
