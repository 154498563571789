import { combineReducers } from '@reduxjs/toolkit'
import billingTable from './billingTable'
import billingWeeks from './billingWeeks'
import billingInvoices from './billingInvoices'
import billingInvoiceDetails from './billingInvoiceDetails'
import billingMissingDateAlert from './billingMissingDateAlert'
import billingComments from './billingComments'

const billing = combineReducers({
  billingTable,
  billingWeeks,
  billingInvoices,
  billingInvoiceDetails,
  billingMissingDateAlert,
  billingComments,
})

export default billing
