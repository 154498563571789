import clientApi from './utils/clientApi'

const getAll = ({ yardId }) =>
  clientApi({
    method: 'get',
    url: `/position_tracking/yards/${yardId}`,
  }).then(({ data }) => data.vehicles)

const getSessions = (yardId) =>
  clientApi({
    method: 'get',
    url: '/sessions/latest',
    params: {
      yard_id: yardId,
    },
  }).then((response) => response.data)

const devices = {
  getAll,
  getSessions,
}

export default devices
