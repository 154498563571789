import styled from 'styled-components/macro'

import iconComment from '../../images/icons/icon_comment_blue.svg'

function getBackgroundColor(type) {
  return {
    vcr: `var(--lighter-neutral-92)`,
    vwr: `var(--lighter-green-93)`,
    note: `var(--lighter-blue-96)`,
  }[type]
}

function getHighlightBackgroundColor(type) {
  return {
    vcr: `var(--darker-neutral-45)`,
    vwr: `var(--base-green)`,
    note: `var(--base-blue)`,
  }[type]
}

export const Container = styled.div`
  padding: 20px;
  border-radius: var(--border-radius);
  position: relative;
  background-color: ${(props) => getBackgroundColor(props.type)};
  margin-bottom: 20px;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${(props) =>
    props.isClickable &&
    `
    cursor: pointer;
  `}

  ::before {
    content: '';
    position: absolute;
    transition: 0.2s all ease;
    left: 0;
    top: 0;
    width: 7px;
    height: 100%;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    background-color: ${(props) => getHighlightBackgroundColor(props.type)};
  }
`

export const Comment = styled.div`
  background-color: var(--lighter-neutral-99);
  background-image: url(${iconComment});
  background-repeat: no-repeat;
  background-position: center left 10px;
  width: 100%;
  padding: 2.5px 32px;
  text-transform: capitalize;
  border-radius: var(--border-radius);
  display: grid;
  align-items: center;

  ${(props) =>
    props.isEmpty &&
    `
    background: none;
    padding: 2.5px 0;
    color: var(--darker-neutral-45);
    text-transform: none;
  `}
`

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  margin-right: 10px;

  ::-webkit-scrollbar {
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border: none;
    border-top: 2px solid var(--lighter-neutral-92);
  }
`

export const Badge = styled.div`
  width: fit-content;
  background-color: var(--lighter-neutral-99);
  padding: 2px 10px;
  border-radius: var(--border-radius);
  color: var(--darker-neutral-45);
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  :not(:last-of-type) {
    margin-right: 10px;
  }

  ${(props) =>
    props.right &&
    `
    margin-left: auto;
  `}
`

export const Note = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

export const Icon = styled.img`
  margin-right: 8px;
  height: 12px;

  ${(props) =>
    props.large &&
    `
    height: 20px;
  `}
`

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const Text = styled.span`
  white-space: nowrap;
  margin-right: 10px;
`

export const ClickableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const TaskContainer = styled.div`
  background: var(--lighter-neutral-99);
  display: flex;
  border-radius: var(--border-radius);
  padding: 5px;
  margin-right: 10px;
  align-items: center;
  white-space: nowrap;
  font-family: 'ArticulatCF-DemiBold';
`

export const TaskDetail = styled.div`
  background: var(--lighter-neutral-92);
  padding: 4px 8px;
  font-family: 'ArticulatCF-Medium';

  :not(:last-child) {
    margin-right: 5px;
  }
`
