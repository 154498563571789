import { manageVehiclesTypes } from '../../actions/manageVehiclesActions'

const initialState = {
  value: [],
  nextOffset: 0,
  total: 0,
  isLoading: false,
}

const vcrList = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVehiclesTypes.VWRS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }

    case manageVehiclesTypes.VWRS_LOADED:
      return {
        ...state,
        value: payload.vwrs,
        nextOffset: payload.nextOffset,
        total: payload.total,
        isLoading: false,
      }

    case manageVehiclesTypes.VWRS_LAZY_LOADED:
      return {
        ...state,
        value: state.value.concat(payload.vwrs),
        nextOffset: payload.nextOffset,
        total: payload.total,
        isLoading: false,
      }

    case manageVehiclesTypes.VWRS_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}

export default vcrList
