import { trackingTypes } from '../../actions/trackingActions'

const initialState = {
  loading: false,
  value: [],
}

const liveFlags = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.LIVE_FLAGS_REQUESTED:
      return {
        ...state,
        loading: true,
        value: [],
      }

    case trackingTypes.LIVE_FLAGS_LOADED:
      return {
        ...state,
        loading: false,
        value: payload,
      }

    case trackingTypes.LIVE_FLAGS_FAILED:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default liveFlags
