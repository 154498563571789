import editIcon from '../../../images/icons/icon_edit_dark.svg'
import removeIcon from '../../../images/icons/icon_clear_dark.svg'
import tooltipEditIcon from '../../../images/tooltip/edit.svg'
import { history } from '../../../utils'

export default function getTitleAction({
  vehicleId,
  hasVehicleData,
  hasUpsertWriteAccess,
  hasYardChangeWriteAccess,
  setShowModalDeleteVehicle,
}) {
  if (!hasVehicleData) {
    return []
  }

  const titleActionList = []

  if (hasUpsertWriteAccess || hasYardChangeWriteAccess) {
    titleActionList.push({
      key: 0,
      icon: editIcon,
      onClick: () => history.push(`/vehicles/details/${vehicleId}/edit`),
      tooltip: {
        titleIcon: tooltipEditIcon,
        title: 'Edit vehicle',
        action: 'Click to edit this vehicle',
      },
    })
  }

  if (hasUpsertWriteAccess) {
    titleActionList.push({
      key: 1,
      icon: removeIcon,
      onClick: () => setShowModalDeleteVehicle(true),
      tooltip: {
        titleIcon: tooltipEditIcon,
        title: 'Delete vehicle',
        action: 'Click to remove this vehicle',
      },
    })
  }

  return titleActionList
}
