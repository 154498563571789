import styled from 'styled-components/macro'

export const Container = styled.div`
  display: ${(props) =>
    props.screenX > 0 && props.screenY > 0 ? 'block' : 'none'};
  position: absolute;
  border-radius: var(--border-radius);
  width: 200px;
  height: fit-content;
  background: var(--lighter-neutral-99);
  z-index: 1002;
  box-shadow: var(--box-shadow);
  transition: 0.2s all ease;
  left: ${(props) => `calc(${props.screenX}px + 30px)`};
  top: ${(props) => `calc(${props.screenY}px - 50px)`};
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid var(--base-neutral);
  font-family: 'ArticulatCF-Bold';
`

export const Content = styled.p`
  margin: 0;
  padding: 10px;
  text-transform: lowercase;
  display: block;

  ::first-letter {
    display: inline-block;
    text-transform: capitalize;
  }
`

export const Icon = styled.img`
  height: 25px;
  width: 25px;
  padding: 4px;
  display: flex;
  margin-right: 5px;
`
