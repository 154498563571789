import { billingTypes } from '../../actions/billingActions'

const initialState = {
  status: 'hide',
}

const billingMissingDateAlert = (state = initialState, { type, payload }) => {
  switch (type) {
    case billingTypes.BILLING_MISSING_DATE_ALERT:
      return {
        ...state,
        status: payload,
      }

    default:
      return state
  }
}

export default billingMissingDateAlert
