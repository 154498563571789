import vehiclesIcon from '../../../images/tooltip/vehicles.svg'
import infoIcon from '../../../images/tooltip/info.svg'

const headers = [
  {
    key: 0,
    toolTipTitle: 'Last Comms',
    toolTipTitleIcon: vehiclesIcon,
    toolTipDescription: 'Last time that data from this vehicle was received',
  },
  {
    key: 1,
    toolTipTitle: 'Vehicle',
    toolTipTitleIcon: infoIcon,
    toolTipDescription: 'Name of the vehicle',
  },
  {
    key: 2,
    toolTipTitle: 'Yard',
    toolTipTitleIcon: infoIcon,
    toolTipDescription: 'Yard of the vehicle',
  },
  {
    key: 3,
    toolTipTitle: 'Battery',
    toolTipTitleIcon: infoIcon,
    toolTipDescription:
      'Indication of current battery level or if the device is charging',
  },
  {
    key: 4,
    toolTipTitle: 'Activity',
    toolTipTitleIcon: infoIcon,
    toolTipDescription: 'Current device activity status',
  },
  {
    key: 5,
    toolTipTitle: 'HR',
    toolTipTitleIcon: infoIcon,
    toolTipDescription: 'Current device activity status',
  },
  {
    key: 6,
    toolTipTitle: 'S. Version',
    toolTipTitleIcon: infoIcon,
    toolTipDescription: 'Version of the INTRMODL app',
  },
  {
    key: 7,
    toolTipTitle: 'Status',
    toolTipTitleIcon: infoIcon,
    toolTipDescription:
      'Status of a vehicle - Operational, Repairs Needed, or Out of Service. The date is the time of last status change or the last VCR',
    toolTipAction: 'Click to change the vehicle status',
  },
].map((header) => ({
  ...header,
  text: header.toolTipTitle.toUpperCase(),
  toolTipAction: header.toolTipAction || `Click to sort ${header.toolTipTitle}`,
  actionType: 'sort',
}))

export default headers
