import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'

class Step extends PureComponent {
  handleSubmit = (values) => {
    const { onNext, onRegisterStep, isLast, onSubmit } = this.props
    onRegisterStep(values)
    if (isLast) {
      onSubmit()
    } else {
      onNext()
    }
  }

  renderButtons = ({ isSubmitting: isSubmittingStep, isDisabled = false }) => {
    const { isFirst, isLast, isSubmitting, onPrevious } = this.props
    return (
      <div className="step-button-wrapper">
        {!isFirst && (
          <Button
            className="button-step-previous"
            text="Previous"
            onClick={onPrevious}
          />
        )}
        {isLast ? (
          <Button
            className="button-step-next"
            type="submit"
            text={isSubmitting ? 'Loading ...' : 'Submit'}
            isDisabled={isSubmitting}
          />
        ) : (
          <Button
            type="submit"
            className="button-step-next"
            text={isSubmittingStep ? 'Loading ...' : 'Next'}
            isDisabled={isDisabled || isSubmittingStep}
          />
        )}
      </div>
    )
  }

  render() {
    const { children, savedValues } = this.props

    return (
      <div>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            savedValues,
            renderButtons: this.renderButtons,
            onSubmit: this.handleSubmit,
          }),
        )}
      </div>
    )
  }
}

Step.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  savedValues: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onRegisterStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
}

export default Step
