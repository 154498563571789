import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router'
import { animationLiftUp } from '../../css/sharedStyles'
import { FormItem } from '../../components'

const animated = css`
  opacity: 0;
  animation: ${animationLiftUp} 0.5s ease forwards;
`

const defaultButton = css`
  width: 100%;
  border-radius: var(--border-radius);
  height: 52px;
  margin-bottom: 10px;
  border: none;
  color: var(--lighter-neutral-99) !important;
  transition: 0.1s all ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
`

export const Container = styled.div`
  background: var(--lighter-neutral-96);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const Box = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  background-color: var(--lighter-neutral-99);
  border-radius: var(--border-radius);
  padding: 30px;
  box-shadow: 0px 2px 2px 0px rgba(124, 142, 164, 0.25);
`

export const StyledFormItem = styled(FormItem)`
  ${animated}
  width: 100%;
  border: none;
  height: 48px;
  padding: 0 10px;
  margin-top: 10px;
  background-color: var(--lighter-neutral-96);
  outline: none;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  transition: 0.1s all ease;
  animation-delay: 0.4s;

  ::placeholder {
    color: var(--darker-neutral-45);
  }

  :focus {
    border: 2px solid var(--base-blue);
  }
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

export const Button = styled.button`
  ${defaultButton}
  ${animated}
  background: var(--darker-blue-43);
  animation-delay: 0.7s;

  :hover {
    background: var(--base-blue);
  }
`

export const StyledLink = styled(Link)`
  ${defaultButton}
  ${animated}
  background: var(--base-neutral);
  animation-delay: 0.6s;

  :hover {
    background: var(--darker-neutral-45);
  }
`

export const Logo = styled.img`
  position: absolute;
  left: 20px;
  top: 20px;
`

export const Title = styled.span`
  ${animated}
  font-size: 1.8rem;
  margin-bottom: 20px;
  display: block;
  animation-delay: 0.2s;
`

export const Subtitle = styled.span`
  ${animated}
  font-size: 1rem;
  animation-delay: 0.3s;
`
