import { connect } from 'react-redux'
import ManageVCRs from './ManageVCRs'
import {
  setVCRSortOption,
  setVCRsSearch,
  loadVcrList,
} from '../../actions/manageVCRsActions'
import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state) => {
  const timezone = getYardTimeZone(state)

  return {
    selectedYard: state.general.selectedYard,
    vcrList: state.manageVCRs.vcrList,
    vcrSortOption: state.manageVCRs.vcrSortOption,
    timezone,
  }
}

const mapDispatchToProps = {
  setVCRSortOption,
  setVCRsSearch,
  loadVcrList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageVCRs)
