import { trackingTypes } from '../../actions/trackingActions'

const initialState = {
  showNoComms: false,
}

const vehiclesViewModes = (state = initialState, { type }) => {
  switch (type) {
    case trackingTypes.TOGGLE_NO_COMMS:
      return { ...state, showNoComms: !state.showNoComms }
    default:
      return state
  }
}

export default vehiclesViewModes
