import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'
import { includes } from 'ramda'

import Blank from '../Blank'
import { YARD_MAP_WRITE_ROLES } from '../../permissions/config'

import iconIdle from '../../images/icons/icon_idle.svg'
import iconCheckMark from '../../images/icons/icon_vcr_checkmark.svg'
import iconMovingMetrics from '../../images/icons/icon_moving_metrics.svg'
import iconBoxMoves from '../../images/icons/icon_boxmoves.svg'
import iconMissingMetrics from '../../images/icons/icon_missing_metric.svg'

import {
  getShortName,
  humanizeDuration,
  getDetectedMoves,
  formatSessionStatus,
} from '../../utils'

import { usePrevious } from '../../hooks'

import * as S from './styles'

const DevicesPanelWorkerInfo = ({
  workerInfo,
  vcrSubmissionAt,
  timezone,
  userSessionMetrics,
  handleActiveTab,
  setInfoBoxDeviceId,
  detectedMoves,
  lastActivity,
  revokeToken,
  pingDriver,
  loadVcrSubmission,
  loadUserSessionMetrics,
  loadLastActivity,
  viewMode,
  userRole,
}) => {
  const userId = _.get(workerInfo, 'user_id')
  const deviceId = _.get(workerInfo, 'device_id')
  const vcrSubmissionId = _.get(workerInfo, 'vcr_submission_id')
  const iDrive = _.get(workerInfo, 'iDrive') || 'N/A'
  const detectedMovesInfo = getDetectedMoves({ userId, detectedMoves })
  const hasWriteAccess = includes(userRole, YARD_MAP_WRITE_ROLES)

  const isViewModeLive = viewMode === 'live'
  const isViewModePlayback = viewMode === 'history'

  const previousDeviceId = usePrevious(deviceId)

  if (!_.isNil(deviceId) && deviceId !== previousDeviceId) {
    setInfoBoxDeviceId(deviceId)

    if (isViewModeLive) {
      loadLastActivity(deviceId)

      if (!_.isNil(userId)) {
        loadUserSessionMetrics(deviceId, userId)
      }
    }

    if (!_.isNil(vcrSubmissionId)) {
      loadVcrSubmission(deviceId, vcrSubmissionId)
    }
  }

  if (_.isEmpty(workerInfo)) {
    const action = {
      text: 'Select a worker',
      onClick: () => handleActiveTab('workers'),
    }
    return (
      <S.Container isEmpty>
        <Blank small type="worker" action={action} />
      </S.Container>
    )
  }

  const vehicleName = _.get(workerInfo, 'Vehicle.vehicle_name')
  const status = _.get(workerInfo, 'status')
  const firstName = _.get(workerInfo, 'User.first_name')
  const lastName = _.get(workerInfo, 'User.last_name')

  const name = _.isNil(firstName || lastName)
    ? 'No driver'
    : getShortName({ firstName, lastName })

  const vcrSubmissionValue = _.get(vcrSubmissionAt, 'value')
    ? moment.tz(_.get(vcrSubmissionAt, 'value'), timezone).format('HH:mm')
    : 'N/A'

  const lastActivityStartTime = moment
    .tz(_.get(lastActivity, 'value.opened_at'), timezone)
    .format('HH:mm')

  const lastComms = _.get(workerInfo, 'fixmillis')
    ? moment.tz(_.get(workerInfo, 'lastcomms'), timezone).format('MM/DD HH:mm')
    : 'N/A'

  function getVcrDescription() {
    let vcrStatusText
    let vcrStatusTheme

    if (_.isNil(userId)) {
      vcrStatusText = 'No VCR'
      vcrStatusTheme = 'missing'

      return { vcrStatusText, vcrStatusTheme }
    }

    if (!_.isNil(userId) && _.isNil(vcrSubmissionId)) {
      vcrStatusText = 'Logged in, no VCR'
      vcrStatusTheme = 'idle'

      return { vcrStatusText, vcrStatusTheme }
    }

    if (
      !_.isNil(userId) &&
      !_.isNil(vcrSubmissionId) &&
      status === 'securement'
    ) {
      vcrStatusText = 'Securement'
      vcrStatusTheme = 'idle'

      return { vcrStatusText, vcrStatusTheme }
    }

    if (
      !_.isNil(userId) &&
      !_.isNil(vcrSubmissionId) &&
      status !== 'securement'
    ) {
      vcrStatusText = 'VCR submitted at'
      vcrStatusTheme = 'moving'

      return { vcrStatusText, vcrStatusTheme }
    }

    return { vcrStatusText, vcrStatusTheme }
  }

  function getLastActivityDescription() {
    let lastActivityTheme
    let lastActivityText

    if (_.isNil(userId)) {
      lastActivityTheme = 'missing'
      lastActivityText = 'Unoccupied'

      return { lastActivityTheme, lastActivityText }
    }

    if (_.isNil(vcrSubmissionId)) {
      lastActivityTheme = 'moving'
      lastActivityText = 'Logged in'

      return { lastActivityTheme, lastActivityText }
    }

    lastActivityText = formatSessionStatus(status)
    lastActivityTheme =
      lastActivityText.toLowerCase() === 'moving' ? 'moving' : 'idle'

    return { lastActivityTheme, lastActivityText }
  }

  function getIconByTheme({ theme }) {
    return {
      missing: iconMissingMetrics,
      moving: iconCheckMark,
      idle: iconIdle,
    }[theme]
  }

  function handleForceWorkerLogout() {
    revokeToken(userId)
    handleActiveTab('workers')
  }

  function handlePingDriver() {
    pingDriver({ userId })
  }

  function getSessionTimes() {
    const { value: userMetricsValue } = userSessionMetrics

    if (userMetricsValue === null || userId === null) {
      return {
        movingTotal: '-',
        idleTotal: '-',
      }
    }

    const parsedMetrics = _.chain(userMetricsValue)
      .pick(['movingTotal', 'idleTotal'])
      .mapValues((total) => humanizeDuration(total, { isShort: true }))
      .value()

    return parsedMetrics
  }

  const { idleTotal, movingTotal } = getSessionTimes()
  const { lastActivityTheme, lastActivityText } = getLastActivityDescription()
  const { vcrStatusText, vcrStatusTheme } = getVcrDescription()
  return (
    <>
      <S.Container header>
        <div>
          <S.Text title={name} bold driver big>
            {name}
          </S.Text>
          <S.Text big>{vehicleName}</S.Text>
        </div>
        <div>
          <S.Text small>IDRIVE</S.Text>
          <S.Text small>{iDrive}</S.Text>
        </div>
      </S.Container>

      <S.Container content>
        <S.Info theme={vcrStatusTheme}>
          <S.Image src={getIconByTheme({ theme: vcrStatusTheme })} />
          <S.Text>{vcrStatusText}</S.Text>
          <S.Metric>{vcrSubmissionValue}</S.Metric>
        </S.Info>

        <S.Info theme="comms" isPlayback={isViewModePlayback}>
          <S.Image src={iconMovingMetrics} alt="" />
          <div>
            <S.Text>Total Moving</S.Text>
            <S.Text>Total Idle</S.Text>
            <S.Text>Last Comms</S.Text>
          </div>
          {isViewModeLive ? (
            <div>
              <S.Metric>{movingTotal}</S.Metric>
              <S.Metric>{idleTotal}</S.Metric>
              <S.Metric>{lastComms}</S.Metric>
            </div>
          ) : (
            <div>
              <S.Metric>-</S.Metric>
              <S.Metric>-</S.Metric>
              <S.Metric>-</S.Metric>
            </div>
          )}
        </S.Info>

        <S.Info theme="boxmoves" isPlayback={isViewModePlayback}>
          <S.Image src={iconBoxMoves} alt="" />
          <div>
            <S.Text>Turn around time</S.Text>
            <S.Text>Box moves</S.Text>
          </div>
          {isViewModeLive ? (
            <div>
              <S.Metric>{_.get(detectedMovesInfo, 'turnAroundTime')}</S.Metric>
              <S.Metric>{_.get(detectedMovesInfo, 'boxMoves')}</S.Metric>
            </div>
          ) : (
            <div>
              <S.Metric>-</S.Metric>
              <S.Metric>-</S.Metric>
            </div>
          )}
        </S.Info>

        {isViewModeLive && (
          <S.Info theme={lastActivityTheme}>
            <S.Image src={getIconByTheme({ theme: lastActivityTheme })} />
            <S.Text>{`${lastActivityText} since`}</S.Text>
            <S.Metric>{lastActivityStartTime}</S.Metric>
          </S.Info>
        )}

        {!_.isNil(userId) && isViewModeLive && (
          <S.Footer>
            <S.Button
              onClick={handleForceWorkerLogout}
              disabled={!hasWriteAccess}
            >
              Force Logout
            </S.Button>
            <S.Button
              onClick={handlePingDriver}
              highlighted
              disabled={!hasWriteAccess}
            >
              Ping Driver
            </S.Button>
          </S.Footer>
        )}
      </S.Container>
    </>
  )
}

DevicesPanelWorkerInfo.propTypes = {
  workerInfo: PropTypes.shape({
    fixmillis: PropTypes.number,
    device_id: PropTypes.string,
    user_id: PropTypes.number,
    vcr_submission_id: PropTypes.number,
    lastcomms: PropTypes.string,
    status: PropTypes.string,
    User: {
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      user_id: PropTypes.number,
    },
    Vehicle: {
      id: PropTypes.number,
      vehicle_name: PropTypes.string,
    },
  }).isRequired,
  vcrSubmissionAt: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.string,
  }).isRequired,
  timezone: PropTypes.string.isRequired,
  userSessionMetrics: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.object,
  }).isRequired,
  handleActiveTab: PropTypes.func.isRequired,
  detectedMoves: PropTypes.shape({
    loading: PropTypes.bool,
    metrics: PropTypes.shape({
      yardTurnAroundTime: PropTypes.shape({
        turnAroundTimeAverage: PropTypes.number,
        movesCount: PropTypes.number,
      }),
      turnAroundTimeByUser: PropTypes.object,
    }),
  }).isRequired,
  lastActivity: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.object,
  }).isRequired,
  revokeToken: PropTypes.func.isRequired,
  pingDriver: PropTypes.func.isRequired,
  loadVcrSubmission: PropTypes.func.isRequired,
  loadUserSessionMetrics: PropTypes.func.isRequired,
  loadLastActivity: PropTypes.func.isRequired,
  setInfoBoxDeviceId: PropTypes.func.isRequired,
  viewMode: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
}

export default DevicesPanelWorkerInfo
