import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider/lib/Slider'
import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import './CustomSlider.css'

class CustomSlider extends Component {
  onBeforeChange = () => {
    const { scrubbingStart } = this.props
    scrubbingStart()
  }

  onSliderChange = (value) => {
    const { setCurrentHistoryTime } = this.props
    setCurrentHistoryTime(value)
  }

  onSliderAfterChange = (value) => {
    const { setCurrentHistoryTime, scrubbingEnd } = this.props
    scrubbingEnd()
    setCurrentHistoryTime(value)
  }

  render() {
    const { currentHistoryTime, min, max } = this.props

    return (
      <div className="customSlider flex-column">
        <Slider
          min={min}
          max={max}
          value={currentHistoryTime}
          onBeforeChange={this.onBeforeChange}
          onChange={this.onSliderChange}
          onAfterChange={this.onSliderAfterChange}
        />
      </div>
    )
  }
}

CustomSlider.propTypes = {
  scrubbingStart: PropTypes.func.isRequired,
  scrubbingEnd: PropTypes.func.isRequired,
  setCurrentHistoryTime: PropTypes.func.isRequired,
  currentHistoryTime: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
}

export default CustomSlider
