import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

import iconComment from '../../images/icons/icon_comment_state.svg'

const CommentDisplay = ({ screenX, screenY, title, value }) => (
  <S.Container screenX={screenX} screenY={screenY}>
    <S.Title>
      <S.Icon src={iconComment} />
      {title}
    </S.Title>
    <S.Content>{value}</S.Content>
  </S.Container>
)

CommentDisplay.propTypes = {
  screenX: PropTypes.number.isRequired,
  screenY: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CommentDisplay.defaultProps = {
  value: '',
}

export default CommentDisplay
