let lastPathname = null

const handleLeave = (prevState) => {
  lastPathname = prevState.location.pathname
}

const handleEnter = (nextState, replace, callback) => {
  const nextPathname = nextState.location.pathname
  if (lastPathname !== null && lastPathname.startsWith('/yard')) {
    const base = lastPathname.split('/').slice(0, 3).join('/')
    const nextPathnameWithBase = `${base}${nextPathname}`
    lastPathname = nextPathnameWithBase
    replace(nextPathnameWithBase)
  } else {
    lastPathname = '/'
    replace(lastPathname)
  }
  callback()
}

export { handleLeave, handleEnter }
