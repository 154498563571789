import { yardSummaryTypes } from '../../actions/yardSummaryActions'

const initialState = {
  value: {},
  isLoading: false,
}

const yardMetrics = (state = initialState, { type, payload }) => {
  switch (type) {
    case yardSummaryTypes.YARD_METRICS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case yardSummaryTypes.YARD_METRICS_LOADED:
      return {
        ...state,
        isLoading: false,
        value: payload.metrics,
      }

    case yardSummaryTypes.YARD_METRICS_FAILED:
      return initialState

    default:
      return state
  }
}

export default yardMetrics
