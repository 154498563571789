import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'

import iconAdmin from '../../images/icons/icon_badge_admin.svg'
import iconDriver from '../../images/icons/icon_badge_driver.svg'
import iconMechanic from '../../images/icons/icon_badge_mechanic.svg'
import iconSupervisor from '../../images/icons/icon_badge_supervisor.svg'
import iconManager from '../../images/icons/icon_badge_manager.svg'
import iconUpperManager from '../../images/icons/icon_badge_uppermanager.svg'
import iconGateInspector from '../../images/icons/icon_badge_gate_inspector.svg'

import iconClock from '../../images/icons/icon_badge_clock.svg'
import iconEngineHoursBW from '../../images/icons/icon_badge_engine_hours_bw.svg'

import vcrIssueIcon from '../../images/assets/vcr_issue.svg'
import vcrNoIssueIcon from '../../images/assets/no_issue.svg'

import vwrTaskIcon from '../../images/icons/icon_vwr_task_check.svg'

import { issuesList, history } from '../../utils'

import * as S from './styles'

function getIconByRole(role) {
  return {
    DRIVER: iconDriver,
    MANAGER: iconManager,
    ADMIN: iconAdmin,
    SUPER_ADMIN: iconAdmin,
    MECHANIC: iconMechanic,
    SUPERVISOR: iconSupervisor,
    UPPER_MANAGER: iconUpperManager,
    GATE_INSPECTOR: iconGateInspector,
  }[role]
}

const VehicleDetailsInfoRow = ({ type, vcr, note, vwr, isClickable }) => {
  const renderVCRs = () => {
    const {
      id,
      comment,
      status,
      issues,
      engineHours,
      date,
      user,
      role,
      timezone,
      setHeaderReturnOptions,
      yardId,
      vehicleId,
    } = vcr

    const statusIcon = status === 'open' ? vcrIssueIcon : vcrNoIssueIcon
    const issuesCount = _.size(issues)
    const hasVcrComment = !_.isNil(comment) && comment !== ''

    function handleClick() {
      const returnUrl = `/yard/${yardId}/vehicles/details/${vehicleId}`

      setHeaderReturnOptions({
        path: returnUrl,
        text: 'Back to vehicle details',
      })

      history.push(`/yard/${yardId}/vcrs/${id}`)
    }

    return (
      <S.ClickableContainer onClick={handleClick}>
        <S.Row>
          <S.Icon src={statusIcon} large />

          <S.Text>
            {issuesCount > 0 ? `${issuesCount} Issues` : 'No issues'}
          </S.Text>

          <S.BadgeContainer>
            {issuesCount > 0 &&
              issues.map((issue, index) => {
                const issueDescription = _.get(issue, 'description')
                const issueOrdinalIndex = _.get(issue, 'ordinal', null)

                const issueIcon = issueOrdinalIndex
                  ? issuesList[issueOrdinalIndex]
                  : issuesList.defaultIcon

                return (
                  <S.Badge key={index}>
                    <S.Icon src={issueIcon} />
                    {issueDescription}
                  </S.Badge>
                )
              })}
          </S.BadgeContainer>

          <S.Badge right>
            <S.Icon src={iconEngineHoursBW} />
            {engineHours}
          </S.Badge>
        </S.Row>

        <S.Comment isEmpty={!hasVcrComment}>
          {hasVcrComment ? comment : 'No comment to show'}
        </S.Comment>

        <S.Row>
          <S.Badge>{user}</S.Badge>

          <S.Badge>
            <S.Icon src={getIconByRole(role)} />
            {_.capitalize(role)}
          </S.Badge>

          <S.Badge right>
            <S.Icon src={iconClock} />
            {moment.tz(date, timezone).format('MM/DD/YYYY HH:mm')}
          </S.Badge>
        </S.Row>
      </S.ClickableContainer>
    )
  }

  const renderVWRs = () => {
    const { engineHours, comment, user, date, role, timezone, tasks = [] } = vwr

    const hasMultipleTasks = _.size(tasks) > 1
    const hasVwrComment = !_.isNil(comment) && comment !== ''

    return (
      <>
        <S.Row>
          <S.BadgeContainer>
            {tasks.map((task, index) => (
              <S.TaskContainer key={index}>
                <S.Icon src={vwrTaskIcon} large />

                <S.Text>
                  {hasMultipleTasks ? `Task ${index + 1}` : 'Task'}
                </S.Text>

                <S.TaskDetail>{task.action}</S.TaskDetail>
                <S.TaskDetail>{task.part}</S.TaskDetail>
                <S.TaskDetail>{task.reason}</S.TaskDetail>
              </S.TaskContainer>
            ))}
          </S.BadgeContainer>
        </S.Row>

        <S.Comment isEmpty={!hasVwrComment}>
          {hasVwrComment ? comment : 'No comment to show'}
        </S.Comment>

        <S.Row>
          <S.Badge>{user}</S.Badge>

          <S.Badge>
            <S.Icon src={getIconByRole(role)} />
            {_.capitalize(role)}
          </S.Badge>

          <S.Badge right>
            <S.Icon src={iconEngineHoursBW} />
            {engineHours}
          </S.Badge>

          <S.Badge>
            <S.Icon src={iconClock} />
            {moment.tz(date, timezone).format('MM/DD/YYYY HH:mm')}
          </S.Badge>
        </S.Row>
      </>
    )
  }

  const renderNotes = () => {
    const { comment, user, role, date, timezone } = note

    return (
      <>
        <S.Comment>{comment}</S.Comment>
        <S.Row>
          <S.Badge>{user}</S.Badge>
          <S.Badge>
            <S.Icon src={getIconByRole(role)} />
            {_.capitalize(role)}
          </S.Badge>
          <S.Badge right>
            <S.Icon src={iconClock} />
            {moment.tz(date, timezone).format('MM/DD/YYYY HH:mm')}
          </S.Badge>
        </S.Row>
      </>
    )
  }

  function renderContentByType() {
    return {
      note: renderNotes(),
      vcr: renderVCRs(),
      vwr: renderVWRs(),
    }[type]
  }

  return (
    <S.Container type={type} isClickable={isClickable}>
      {renderContentByType()}
    </S.Container>
  )
}

VehicleDetailsInfoRow.propTypes = {
  type: PropTypes.oneOf(['note', 'vcr', 'vwr', 'history']).isRequired,
  isClickable: PropTypes.bool,
  vcr: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    issues: PropTypes.arrayOf(
      PropTypes.shape({
        ordinal: PropTypes.number,
        description: PropTypes.string,
      }),
    ),
    engineHours: PropTypes.number,
    date: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    comment: PropTypes.string,
    setHeaderReturnOptions: PropTypes.func.isRequired,
    yardId: PropTypes.number.isRequired,
    vehicleId: PropTypes.number.isRequired,
  }),
  vwr: PropTypes.shape({
    engineHours: PropTypes.number,
    comment: PropTypes.string,
    user: PropTypes.string,
    date: PropTypes.string,
    role: PropTypes.string,
    timezone: PropTypes.string,
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.string,
        part: PropTypes.string,
        reason: PropTypes.string,
      }),
    ),
  }),
  note: PropTypes.shape({
    date: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    comment: PropTypes.string,
  }),
}

VehicleDetailsInfoRow.defaultProps = {
  isClickable: false,
  vcr: {},
  note: {},
  vwr: {},
}

export default VehicleDetailsInfoRow
