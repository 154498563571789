import { connect } from 'react-redux'
import Analytics from './Analytics'

import { getYard, getYardTimeZone } from '../../selectors'

const mapStateToProps = (state, ownProps) => ({
  timezone: getYardTimeZone(state),
  yardName: getYard(state).name,
  URLViewMode: ownProps.params.view_mode,
  URLSubmenu: ownProps.params.sub_menu,
})

export default connect(mapStateToProps)(Analytics)
