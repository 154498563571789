import React from 'react'
import PropTypes from 'prop-types'
import './IconText.css'

const IconText = ({ className, iconUrl, text }) => (
  <div className={`${className} iconText flex-row`}>
    <img className="iconText__img" src={iconUrl} alt="icon" />
    <div>{text}</div>
  </div>
)

IconText.propTypes = {
  iconUrl: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
}

IconText.defaultProps = {
  iconUrl: '',
  text: '',
  className: '',
}

export default IconText
