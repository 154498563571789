import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './styles'

const NewContainer = ({ className, children }) => (
  <Container className={className}>{children}</Container>
)

NewContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

NewContainer.defaultProps = {
  className: '',
  children: null,
}

export default NewContainer
