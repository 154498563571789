import { join, repeat } from 'ramda'

export default function parseFraction({
  value,
  fractionDigits,
  isDropdownOption,
}) {
  if (String(value) === '') {
    return ''
  }

  if (String(value) === '00') {
    return 0
  }

  const dotIndex = String(value).indexOf('.')

  if (isDropdownOption && dotIndex === -1) {
    return `${value}.${join('')(repeat('0', fractionDigits))}`
  }

  if (value.substr(0, dotIndex) === '00') {
    return 0 + value.substr(dotIndex, 3)
  }

  return dotIndex >= 0
    ? value.substr(0, dotIndex) + value.substr(dotIndex, fractionDigits + 1)
    : value
}
