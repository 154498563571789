import styled from 'styled-components/macro'
import Toggle from 'react-toggle'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Text = styled.span`
  font-size: 14px;
  display: block;
  font-family: 'ArticulatCF-Bold';

  ${(props) => props.hasLightText && 'color: var(--lighter-neutral-99);'}
`

export const StyledToggle = styled(Toggle)`
  margin-right: 15px;
`

export const Icon = styled.img`
  margin-left: 0;
  margin-right: 10px;
  height: 20px;
`
