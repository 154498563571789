import { combineReducers } from '@reduxjs/toolkit'
import allYardsMetrics from './allYardsMetrics'
import tableSortOption from './tableSortOption'
import tableSortHeader from './tableSortHeader'

const yardList = combineReducers({
  allYardsMetrics,
  tableSortOption,
  tableSortHeader,
})

export default yardList
