import { connect } from 'react-redux'
import App from './App'
import { verifyToken, logout } from '../../actions/generalActions'

const mapStateToProps = (state, ownProps) => ({
  pathname: ownProps.location.pathname,
})

const mapDispatchToProps = {
  verifyToken,
  logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
