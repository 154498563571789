import React from 'react'
import styled from 'styled-components'
import { isNil } from 'ramda'
import moment from 'moment-timezone'

import parseBillingCycle from './parseBillingCycle'

import { Na } from '../components'
import { getEditBillingTableHeaders } from '.'

const Button = styled.button`
  border: none;
  background-color: var(--base-blue);
  color: var(--lighter-neutral-99);
  padding: 4px 20px;
  margin-bottom: -10px;
  border-radius: 6px;

  :hover {
    background-color: var(--darker-blue-43);
  }
`

function parseIntervalEndpoint(value) {
  if (value === undefined) {
    return <Na />
  }

  if (value === null) {
    return 'Infinity'
  }

  return value
}

export default function formatBillingEditValues({
  value,
  isBillingCycle,
  timezone,
  header,
  row,
  handleOpenModal,
}) {
  const { isArray, isInterval, intervalOrigin, isDate, hasModalInfo } = header

  if (isNil(value)) {
    return null
  }

  if (isArray) {
    return value.join(', ')
  }

  if (isInterval) {
    return parseIntervalEndpoint(value[intervalOrigin])
  }

  if (isBillingCycle) {
    return parseBillingCycle(value)
  }

  if (hasModalInfo) {
    const { field } = header
    const content = {
      rows: row[field],
      headers: getEditBillingTableHeaders({ field }),
      timezone,
      field,
      iconTitle: `Interval from ${row.interval[0]} to ${row.interval[1]}`,
    }

    return <Button onClick={() => handleOpenModal(content)}>Details</Button>
  }

  const parsedValue = isDate ? moment.tz(value, timezone) : value

  return parsedValue
}
