import { workerProfileTypes } from '../../actions/workerProfileActions'

const initialState = null

const selectedDate = (state = initialState, { type, payload }) => {
  switch (type) {
    case workerProfileTypes.SELECTED_DATE_SET:
      return payload

    default:
      return state
  }
}

export default selectedDate
