import { isNil } from 'lodash'
import Promise from 'bluebird'
import clientApi from './utils/clientApi'

const getSummary = ({ yard, date }) => {
  const validate = () => {
    if (isNil(yard)) {
      throw new Error('Missing Yard ID')
    }
    if (isNil(date)) {
      throw new Error('Missing date')
    }
  }

  const params = {
    date,
    yard_id: yard,
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: '/billing_summary',
    params,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const upsertSummary = ({ dates, rows }) => {
  const validate = () => {
    if (isNil(dates)) {
      throw new Error('Missing dates')
    }
    if (isNil(rows)) {
      throw new Error('Missing rows')
    }
  }

  const getRequestOptions = () => ({
    method: 'put',
    url: '/billing_summary',
    data: { dates, rows },
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const submitSummary = ({ yard, date, billingCycle }) => {
  const validate = () => {
    if (isNil(yard)) {
      throw new Error('Missing Yard ID')
    }
    if (isNil(date)) {
      throw new Error('Missing date')
    }
  }

  const params = {
    date,
    yard_id: yard,
    billing_cycle: billingCycle,
  }

  const getRequestOptions = () => ({
    method: 'put',
    url: '/billing_summary/status',
    params,
    data: { status: 'locked' },
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const getFirstMissingDate = ({ yard }) => {
  const validate = () => {
    if (isNil(yard)) {
      throw new Error('Missing Yard ID')
    }
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: '/billing_summary/missing',
    params: {
      yard_id: yard,
    },
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const billingSummary = {
  getSummary,
  upsertSummary,
  submitSummary,
  getFirstMissingDate,
}

export default billingSummary
