import { connect } from 'react-redux'
import BillingTemplateEdit from './BillingTemplateEdit'
import { showAlert, showErrorAlert } from '../../actions/alertMessageActions'

const mapStateToProps = (state, ownProps) => ({
  selectedYard: state.general.selectedYard,
  templateId: ownProps.location.query.template_id,
  returnDate: ownProps.location.query.return_date,
})

const mapDispatchToProps = {
  showAlert,
  showErrorAlert,
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingTemplateEdit)
