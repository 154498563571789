import { connect } from 'react-redux'
import { path } from 'ramda'
import DevicesPanel from './DevicesPanel'
import {
  setIconSelected,
  setIconPinned,
  setInfoBoxDeviceId,
  addTooltip,
  removeTooltip,
  toggleNoComms,
  loadDetectedMoves,
  loadVcrSubmission,
  loadUserSessionMetrics,
  loadLastActivity,
  pingDriver,
  clearVcrSubmission,
  clearUserSessionMetrics,
  clearLastActivity,
} from '../../../actions/trackingActions'

import {
  loadVehicle,
  resetVehicle,
} from '../../../actions/manageVehiclesActions'

import { revokeToken } from '../../../actions/manageUsersActions'

import { getYardTimeZone } from '../../../selectors'

const mapStateToProps = (state) => ({
  timezone: getYardTimeZone(state),
  viewMode: state.tracking.viewMode,
  selectedYard: Number(state.general.selectedYard),
  iconSelected: state.tracking.iconSelected,
  iconPinned: state.tracking.iconPinned,
  deviceSessions: state.deviceStatus.deviceSessions,
  trackingData: state.tracking.trackingData,
  trackingDataForHistoryTime: state.playback.trackingDataForHistoryTime,
  selectedTooltips: state.tracking.selectedTooltips,
  showNoComms: state.tracking.vehiclesViewMode.showNoComms,
  detectedMoves: state.tracking.detectedMoves,
  currentHistoryTime: state.playback.currentHistoryTime,
  vcrSubmissionAt: state.tracking.vcrSubmissionAt,
  userSessionMetrics: state.tracking.userSessionMetrics,
  lastActivity: state.tracking.lastActivity,
  vehicleList: state.manageVehicles.vehicleList,
  userList: state.manageUsers.userList,
  vehicleData: state.manageVehicles.vehicleData,
  userRole: path(['Role', 'name'])(state.general.userData),
})

const mapDispatchToProps = {
  setIconSelected,
  setIconPinned,
  setInfoBoxDeviceId,
  addTooltip,
  removeTooltip,
  toggleNoComms,
  loadDetectedMoves,
  loadVcrSubmission,
  loadUserSessionMetrics,
  loadLastActivity,
  revokeToken,
  pingDriver,
  clearVcrSubmission,
  clearUserSessionMetrics,
  clearLastActivity,
  loadVehicle,
  resetVehicle,
}

export default connect(mapStateToProps, mapDispatchToProps)(DevicesPanel)
