import { historyResolution, reuseLastPointTimeLimit } from '../config'
import {
  formatHistoryData,
  floorTimestamp,
  addTrackingDataToHistoryTime,
} from '../utils'
import { trackingTypes } from '../actions/trackingActions'

const emptyHistoryTimeSeries = {
  first: 0,
  last: 0,
  size: 0,
}

const playback = (
  state = {
    scrubbing: false,
    currentHistoryTime: 0,
    historyRequestId: '',
    historyTimeSeries: emptyHistoryTimeSeries,
    historyData: {},
    trackingDataForHistoryTime: [],
    bufferHistoryRequestId: '',
  },
  action,
) => {
  switch (action.type) {
    case trackingTypes.SCRUBBING_START: {
      return {
        ...state,
        scrubbing: true,
      }
    }

    case trackingTypes.SCRUBBING_END: {
      return {
        ...state,
        scrubbing: false,
      }
    }

    case trackingTypes.CLEAR_HISTORY: {
      return addTrackingDataToHistoryTime({
        ...state,
        historyTimeSeries: emptyHistoryTimeSeries,
        historyData: {},
      })
    }

    case trackingTypes.HISTORY_TIME_SET: {
      const newState = {
        ...state,
        currentHistoryTime: floorTimestamp(action.payload),
      }
      if (state.scrubbing) {
        return newState
      }

      return addTrackingDataToHistoryTime(newState)
    }

    case trackingTypes.SET_HISTORY_REQUEST_ID:
      return {
        ...state,
        historyRequestId: action.payload,
      }

    case trackingTypes.HISTORY_LOADED: {
      const { requestId, startTime, endTime, data } = action.payload

      let newState
      if (requestId === state.historyRequestId) {
        const allHistoryData = formatHistoryData(
          data,
          startTime,
          endTime,
          historyResolution,
          reuseLastPointTimeLimit,
        )
        newState = {
          ...state,
          historyTimeSeries: allHistoryData.timeSeriesObject,
          historyData: allHistoryData.formattedData,
          historyRequestId: '',
          bufferHistoryRequestId: '',
        }
      } else {
        newState = {
          ...state,
          historyTimeSeries: emptyHistoryTimeSeries,
          historyData: {},
        }
      }

      return addTrackingDataToHistoryTime(newState)
    }

    case trackingTypes.SET_BUFFER_HISTORY_REQUEST_ID: {
      return {
        ...state,
        bufferHistoryRequestId: action.payload,
      }
    }

    case trackingTypes.BUFFER_HISTORY_LOADED: {
      const { requestId, startTime, endTime, data } = action.payload

      if (requestId === state.bufferHistoryRequestId) {
        const allHistoryData = formatHistoryData(
          data,
          startTime,
          endTime,
          historyResolution,
          reuseLastPointTimeLimit,
        )
        return {
          ...state,
          historyTimeSeries: allHistoryData.timeSeriesObject,
          historyData: allHistoryData.formattedData,
          bufferHistoryRequestId: '',
        }
      }

      return {
        ...state,
        historyTimeSeries: emptyHistoryTimeSeries,
        historyData: {},
      }
    }

    default:
      return state
  }
}

export default playback
