export default function getRows({
  customers,
  renderActions,
  hasCrudYardWriteAccess,
}) {
  return customers.map((customer) => {
    const columns = {
      0: {
        value: customer.name,
      },
    }
    if (hasCrudYardWriteAccess) {
      columns[1] = {
        value: '',
        render: renderActions({ id: customer.id }),
      }
    }

    return {
      columns,
    }
  })
}
