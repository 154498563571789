import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

class StepButton extends Component {
  handleClick = () => {
    const { direction, pagination, onClick } = this.props

    onClick(direction === 'next' ? pagination + 1 : pagination - 1)
  }

  isDisabled() {
    const { direction, pagination, total, listLength, limit } = this.props

    if (direction === 'previous') {
      return pagination === 0
    }

    if (total !== undefined) {
      const maxPagination = total > 0 ? Math.ceil(total / limit, 10) - 1 : 0
      return pagination === maxPagination
    }

    return listLength < limit
  }

  render() {
    const { direction } = this.props

    const disabled = this.isDisabled()

    const arrowChar = direction === 'next' ? faAngleRight : faAngleLeft

    return (
      <button
        className="iconFoo"
        type="button"
        disabled={disabled}
        onClick={this.handleClick}
      >
        <FontAwesomeIcon icon={arrowChar} />
      </button>
    )
  }
}

StepButton.propTypes = {
  direction: PropTypes.oneOf(['previous', 'next']).isRequired,
  pagination: PropTypes.number.isRequired,
  total: PropTypes.number,
  listLength: PropTypes.number,
  limit: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

StepButton.defaultProps = {
  total: undefined,
  listLength: undefined,
}

export default StepButton
