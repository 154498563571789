import React from 'react'
import PropTypes from 'prop-types'
import NewToolTip from '../NewToolTip'
import StepButton from './StepButton'

const PaginationBar = ({
  pagination,
  listLength,
  limit,
  isSidebarCollapsed,
  onPagination,
  total,
}) => {
  const style = isSidebarCollapsed ? { marginLeft: '100px' } : {}

  return (
    <NewToolTip title="Pagination" action="Click the arrows to change the page">
      <div className="pagination flex-row" style={style}>
        <StepButton
          direction="previous"
          pagination={pagination}
          total={total}
          listLength={listLength}
          limit={limit}
          onClick={onPagination}
        />
        <div>{pagination + 1}</div>
        <StepButton
          direction="next"
          pagination={pagination}
          total={total}
          listLength={listLength}
          limit={limit}
          onClick={onPagination}
        />
      </div>
    </NewToolTip>
  )
}

PaginationBar.propTypes = {
  pagination: PropTypes.number.isRequired,
  total: PropTypes.number,
  listLength: PropTypes.number,
  limit: PropTypes.number.isRequired,
  isSidebarCollapsed: PropTypes.bool,
  onPagination: PropTypes.func.isRequired,
}

PaginationBar.defaultProps = {
  isSidebarCollapsed: false,
  total: undefined,
  listLength: undefined,
}

export default PaginationBar
