import { connect } from 'react-redux'
import CustomSlider from './CustomSlider'
import {
  setCurrentHistoryTime,
  scrubbingStart,
  scrubbingEnd,
} from '../../../../actions/trackingActions'

const mapStateToProps = (state) => ({
  currentHistoryTime: state.playback.currentHistoryTime,
  min: state.tracking.historyRange.min,
  max: state.tracking.historyRange.max,
})

const mapDispatchToProps = {
  setCurrentHistoryTime,
  scrubbingStart,
  scrubbingEnd,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomSlider)
