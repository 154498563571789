import styled from 'styled-components/macro'
import { Form } from 'formik'
import { Button } from '../../components'

export const Wrapper = styled.div`
  display: grid;
  align-items: center;
  height: 100%;
`

export const StyledForm = styled(Form)`
  width: 50%;
  margin: 0 auto;

  @supports (-moz-transform: translate(0, 0)) {
    input {
      width: 100%;
    }
  }
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10%;
`

export const StyledButton = styled(Button)`
  margin: 0 auto;
  padding: 15px;
  width: 50%;
  margin-top: 50px;
`
