import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'

import { isNil } from 'ramda'
import Blank from '../Blank'

import { history, getShortName, splitUserName } from '../../utils'

import iconFlag from '../../images/icons/icon_flag.svg'

import * as S from './styles'

const DevicesPanelFlagsTable = ({ flags, timezone }) => {
  const flagsValue = _.get(flags, 'value', [])
  const isLoading = _.get(flags, 'loading')

  function handlePlaybackFlag({ flag }) {
    const { date, ownerId, hasLoggedUser } = flag

    const userOrVehicle = hasLoggedUser ? 'user' : 'vehicle'
    const dateTimestamp = moment(date).valueOf()

    return history.push(
      `/tracking/mode/history/time/${dateTimestamp}/${userOrVehicle}/${ownerId}`,
    )
  }

  return (
    <S.FlagsTable>
      {_.isEmpty(flagsValue) && !isLoading ? (
        <Blank small />
      ) : (
        flagsValue.map((flag) => {
          const { firstName, lastName } = splitUserName({ user: flag.title })
          const userName = isNil(lastName)
            ? 'N/A'
            : getShortName({ firstName, lastName })
          return (
            <S.FlagItem
              key={flag.ownerId}
              onClick={() => handlePlaybackFlag({ flag })}
            >
              <S.Icon src={iconFlag} />
              <div>
                <S.Title>{userName}</S.Title>
                <S.Message>{flag.message}</S.Message>
              </div>
              <S.Date>{moment.tz(flag.date, timezone).format('HH:mm')}</S.Date>
            </S.FlagItem>
          )
        })
      )}
    </S.FlagsTable>
  )
}

DevicesPanelFlagsTable.propTypes = {
  flags: PropTypes.shape({
    loading: PropTypes.bool,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string,
        date: PropTypes.string,
        title: PropTypes.string,
        isGood: PropTypes.bool,
        ownerType: PropTypes.string,
        ownerId: PropTypes.number,
        hasLoggedUser: PropTypes.bool,
      }),
    ),
  }).isRequired,
  timezone: PropTypes.string.isRequired,
}

export default DevicesPanelFlagsTable
