import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { pipe, pick, omit, isNil, prepend, defaultTo, path } from 'ramda'
import { Formik, Field } from 'formik'
import { browserHistory } from 'react-router'

import * as S from './styles'
import { getInitialValuesToEdit } from './helpers'
import {
  validationSchema,
  unsavedChangesModalProps,
  confirmChangesModalProps,
  formFields,
  returnUrl,
  fieldsBlockedToUpdate,
  noneOption,
  fields,
  IS_YARD_ACTIVE_DEFAULT_VALUE,
  IS_WEEK_FULL_DEFAULT_VALUE,
} from './constants'

import {
  useGetYardQuery,
  useGetTimezonesQuery,
  useAddYardMutation,
  useUpdateYardMutation,
} from '../../services/allYards'

import { useGetCompaniesQuery } from '../../services/companies'
import { useGetCustomersQuery } from '../../services/customers'

import { NewContainer, NewHeader, NewModal } from '../../components'

const UpsertYard = ({ yardId, showAlert, showErrorAlert, loadYards }) => {
  const [skipLoadYard, setSkipLoadYard] = useState(true)
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)
  const [showCofirmChangesModal, setShowCofirmChangesModal] = useState(false)
  const [isWeekFull, setIsWeekFull] = useState()
  const [isYardActive, setIsYardActive] = useState()

  const hasYardtoEdit = !isNil(yardId)

  const [submitYard] = useAddYardMutation()
  const [updateYard] = useUpdateYardMutation()

  const { data: yardData = {}, isLoading } = useGetYardQuery(
    { yardId },
    { skip: skipLoadYard },
  )

  const { data: companies = [] } = useGetCompaniesQuery()
  const { data: customers = [] } = useGetCustomersQuery()

  const initialValues = hasYardtoEdit
    ? getInitialValuesToEdit(yardData.yard)
    : { ...validationSchema.cast(), is_active: IS_YARD_ACTIVE_DEFAULT_VALUE }

  const {
    data: timezoneList = [],
    isLoading: isLoadingTimezoneList,
  } = useGetTimezonesQuery()

  function getToggleDefaultValue({ field, defaultValue }) {
    return hasYardtoEdit ? path([field.name], initialValues) : defaultValue
  }

  const fieldsWithTimezone = formFields.map((field) => {
    if (field.name === 'timezone') {
      return {
        ...field,
        options: _.get(timezoneList, 'timezones', []).map((zone) => ({
          value: zone,
          label: _.startCase(zone),
        })),
        isDisabled: isLoadingTimezoneList || hasYardtoEdit,
        isSearchable: false,
      }
    }

    return field
  })

  const parsedFields = fieldsWithTimezone.map((field) => {
    if (field.name === 'is_week_full') {
      const defaultValue = getToggleDefaultValue({
        field,
        defaultValue: IS_WEEK_FULL_DEFAULT_VALUE,
      })
      const fieldValue = isNil(isWeekFull) ? defaultValue : isWeekFull

      return {
        ...field,
        isChecked: fieldValue,
        value: fieldValue,
        onChange: () => setIsWeekFull(!fieldValue),
        text: `${fieldValue ? 'Yes' : 'No'}`,
        disabled: hasYardtoEdit,
      }
    }

    if (field.name === 'is_active') {
      const defaultValue = getToggleDefaultValue({
        field,
        defaultValue: IS_YARD_ACTIVE_DEFAULT_VALUE,
      })
      const fieldValue = isNil(isYardActive) ? defaultValue : isYardActive

      return {
        ...field,
        isChecked: fieldValue,
        value: fieldValue,
        onChange: () => setIsYardActive(!fieldValue),
        text: `${fieldValue ? 'Yes' : 'No'}`,
      }
    }

    if (field.name === 'week_start') {
      return {
        ...field,
        isDisabled: hasYardtoEdit,
      }
    }

    if (field.name === 'billing_company_id') {
      const parsedCompanies = prepend(noneOption)(companies)
      return {
        ...field,
        options: parsedCompanies.map((company) => ({
          value: company.id,
          label: company.name,
        })),
      }
    }

    if (field.name === 'billing_customer_id') {
      const parsedCustomers = prepend(noneOption)(customers)
      return {
        ...field,
        options: parsedCustomers.map((customer) => ({
          value: customer.id,
          label: customer.name,
        })),
      }
    }

    return field
  })

  useEffect(() => {
    if (hasYardtoEdit) {
      setSkipLoadYard(false)
    }
  }, [hasYardtoEdit])

  const headerProps = {
    title: hasYardtoEdit
      ? `Edit ${_.get(yardData, 'yard.name')}`
      : 'Create new yard',
    returnText: 'Back to yard list',
    returnUrl,
  }

  const extraUnsavedModalProps = {
    onConfirm: () => setShowUnsavedChangesModal(false),
    onCancel: () => browserHistory.push(returnUrl),
    visible: showUnsavedChangesModal,
  }

  const unsavedModalProps = {
    ...unsavedChangesModalProps,
    ...extraUnsavedModalProps,
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const parsedValues = {
          ...values,
          is_active: isNil(isYardActive)
            ? defaultTo(IS_YARD_ACTIVE_DEFAULT_VALUE)(initialValues.is_active)
            : isYardActive,

          is_week_full: isNil(isWeekFull)
            ? defaultTo(IS_WEEK_FULL_DEFAULT_VALUE)(initialValues.is_week_full)
            : isWeekFull,
        }

        const parsedValuesToUpdate = pipe(
          pick(fields),
          omit(fieldsBlockedToUpdate),
        )(parsedValues)

        return hasYardtoEdit
          ? updateYard({ body: parsedValuesToUpdate, yardId })
              .unwrap()
              .then(() => {
                showAlert({
                  type: 'success',
                  message: 'Yard updated successfully',
                })

                return browserHistory.push('/allYards')
              })
              .catch((error) =>
                showErrorAlert({
                  error,
                  defaultMessage: 'Failed to update yard',
                }),
              )
          : submitYard({ body: parsedValues })
              .unwrap()
              .then(() => {
                loadYards()

                showAlert({
                  type: 'success',
                  message: 'Yard created successfully',
                })

                return browserHistory.push('/allYards')
              })
              .catch((error) =>
                showErrorAlert({
                  error,
                  defaultMessage: 'Failed to create yard',
                }),
              )
      }}
    >
      {({ isSubmitting, dirty, handleSubmit }) => {
        const extraConfirmModalProps = {
          onConfirm: handleSubmit,
          onCancel: () => setShowCofirmChangesModal(false),
          visible: showCofirmChangesModal,
          isSubmitting,
        }

        const confirmModalProps = {
          ...confirmChangesModalProps,
          ...extraConfirmModalProps,
        }

        if (dirty) {
          headerProps.returnAction = () => setShowUnsavedChangesModal(true)
        }

        return (
          <>
            <NewHeader {...headerProps} />
            <NewContainer>
              <S.Wrapper>
                <S.StyledForm>
                  <S.Content>
                    {parsedFields.map((fieldProps, index) => (
                      <Field
                        key={index}
                        {...fieldProps}
                        disabled={fieldProps.disabled || isLoading}
                      />
                    ))}
                  </S.Content>

                  <S.StyledButton
                    type="submit"
                    text="Save"
                    isDisabled={isSubmitting || isLoading}
                  />
                </S.StyledForm>
              </S.Wrapper>
            </NewContainer>
            <NewModal {...unsavedModalProps} />
            <NewModal {...confirmModalProps} />
          </>
        )
      }}
    </Formik>
  )
}

UpsertYard.propTypes = {
  yardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timezone: PropTypes.string.isRequired,
  showAlert: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
  loadYards: PropTypes.func.isRequired,
}

UpsertYard.defaultProps = {
  yardId: null,
}

export default UpsertYard
