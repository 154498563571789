import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import VehicleDetailsInfoRow from '../VehicleDetailsInfoRow'

import { getShortName } from '../../utils'

const VehicleDetailsSingleVwr = ({ vwr, timezone }) => {
  const id = _.get(vwr, 'id')
  const firstName = _.get(vwr, 'User.first_name')
  const lastName = _.get(vwr, 'User.last_name')
  const role = _.get(vwr, 'User.Role.name')

  const props = {
    type: 'vwr',
    vwr: {
      engineHours: _.get(vwr, 'engine_hours'),
      comment: _.get(vwr, 'comment'),
      user: getShortName({ firstName, lastName }),
      date: _.get(vwr, 'updated_at'),
      tasks: _.get(vwr, 'tasks', []).map((task) => ({
        action: _.get(task, 'action.name'),
        part: _.get(task, 'part.name'),
        reason: _.get(task, 'reason.name'),
      })),
      status: _.get(vwr, 'status'),
      id,
      timezone,
      role,
    },
  }
  return <VehicleDetailsInfoRow {...props} />
}

VehicleDetailsSingleVwr.propTypes = {
  timezone: PropTypes.string.isRequired,
  vwr: PropTypes.shape({
    id: PropTypes.number,
    User: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      Role: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.shape({
          name: PropTypes.string,
        }),
        part: PropTypes.shape({
          name: PropTypes.string,
        }),
        reason: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
    updated_at: PropTypes.string,
    comment: PropTypes.string,
    engine_hours: PropTypes.number,
  }).isRequired,
}

export default VehicleDetailsSingleVwr
