import _ from 'lodash'
import moment from 'moment'

const camelCaseToText = (value) => {
  if (value.length === 0) {
    return value
  }

  const first = value.charAt(0).toUpperCase()

  if (value.length === 1) {
    return first
  }

  const rest = value.replace(/([A-Z])/g, ' $1').substr(1)

  return `${first}${rest}`
}

const parseFlagsFromServer = ({ data, showOwnerName }) => {
  const flags = data.flags.map((flag) =>
    _.pick(flag, [
      'name',
      'description',
      'isGoodFlag',
      'owner_name',
      'owner_type',
      'owner_id',
      'user_id',
      'started_at',
    ]),
  )

  const parsedFlags = flags.map((flag) => {
    const displayOwnerName = showOwnerName ? ` - ${flag.owner_name}` : ''

    return {
      message: flag.description,
      date: moment(flag.started_at).valueOf(),
      isGood: flag.isGoodFlag,
      title: `${camelCaseToText(flag.name)}${displayOwnerName}`,
      ownerType: flag.owner_type,
      ownerId: flag.owner_id,
      hasLoggedUser: flag.owner_id === flag.user_id,
    }
  })

  return parsedFlags
}

export default parseFlagsFromServer
