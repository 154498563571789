import { connect } from 'react-redux'
import YardSummary from './YardSummary'
import { getYardTimeZone } from '../../selectors'
import {
  setYardSummarySelectedDate,
  loadFlagsByYard,
  loadMetrics,
  loadMovesByDay,
} from '../../actions/yardSummaryActions'
import { createMomentParser } from '../../utils'

const parseMoment = createMomentParser()

const mapStateToProps = (state) => {
  const timezone = getYardTimeZone(state)

  return {
    timezone,
    selectedYard: state.general.selectedYard,
    vehicleTimeSummary: state.yardSummary.vehicleTimeSummary,
    selectedDate: parseMoment(state.yardSummary.selectedDate, timezone),
    yardFlags: state.yardSummary.yardFlags,
    yardMetrics: state.yardSummary.yardMetrics,
    yardShifts: state.yardSummary.yardShifts,
    liftsCount: state.yardSummary.liftsCount,
    movesCount: state.yardSummary.movesCount,
  }
}

const mapDispatchToProps = {
  setYardSummarySelectedDate,
  loadFlagsByYard,
  loadMetrics,
  loadMovesByDay,
}

export default connect(mapStateToProps, mapDispatchToProps)(YardSummary)
