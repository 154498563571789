import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { join, path, pipe, isNil, length, pluck, defaultTo } from 'ramda'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { startCase, isEmpty } from 'lodash'
import {
  parseCellValue,
  getOptionsByField,
  hasCompoundTiers,
  getEmptyModalRow,
} from './helpers'
import {
  BOOLEAN_OPTIONS,
  SELECTABLE_OPTIONS_READONLY_TABLES,
} from './constants'
import { getEditBillingTableHeaders, matchNumericValueRegex } from '../../utils'

import * as S from './styles'
import { Na, NewModal } from '../../components'
import CustomSelect from './CustomSelect'

const TemplateValuesRow = ({
  row,
  rows,
  rowIndex,
  headers,
  timezone,
  enableDragAndDrop,
  handleValueChange,
  hasHorizontalScroll,
  isEditMode,
  table,
  subMenu,
  meta,
  isProdSavingsValues,
  showRowsControls,
  handleAddOrRemoveRows,
  front,
  rawTemplateDetails,
  handleDeleteSelectableOptions,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const isMetrics = table === 'metrics'
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: row.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
  }

  const rowProps = {
    $isDragEnabled: enableDragAndDrop,
    ...attributes,
    ...listeners,
  }

  function renderCellContent({
    isEditable,
    isSelectable,
    isNumber,
    isDate,
    tabIndex,
    valueToRender,
    header,
  }) {
    if (isEditable) {
      return (
        <S.Input
          tabIndex={tabIndex}
          type={isNumber ? 'tel' : 'text'}
          onKeyPress={isNumber ? matchNumericValueRegex : null}
          placeholder={header.placeholder}
          value={valueToRender}
          onChange={({ target }) =>
            handleValueChange({
              rows,
              value: target.value,
              currentHeader: header,
              currentRow: row,
              table,
              isProdSavingsValues,
            })
          }
        />
      )
    }

    if (isDate) {
      const datepickerProps = {
        timezone,
        popperPlacement: 'auto',
        portalId: 'root-portal',
        selected: isEmpty(valueToRender) ? null : new Date(valueToRender),
        onChange: (date) => {
          const rawValue = isNil(date) ? moment() : moment(date)
          const value = rawValue.format('YYYY-MM-DD')

          return handleValueChange({
            rows,
            value,
            currentHeader: header,
            currentRow: row,
            table,
            isProdSavingsValues,
          })
        },
      }

      return <S.Datepicker {...datepickerProps} />
    }

    if (isSelectable || header.isBoolean) {
      const options = header.isBoolean
        ? BOOLEAN_OPTIONS
        : getOptionsByField({
            meta,
            front,
            header,
            rawTemplateDetails,
            valueToRender,
          })

      if (header.isIntercompanyYard && isEmpty(options)) {
        return <S.Text>{<Na />}</S.Text>
      }

      return (
        <CustomSelect
          isMulti={header.isArray}
          valueToRender={valueToRender}
          acceptNoSelection={!!header.acceptNoSelection}
          isSearchable={!!header.isSearchable}
          isDisabled={!isEditMode || header.isDisabled}
          options={options}
          classNamePrefix="react-select"
          placeholder={header.placeholder}
          menuPlacement="auto"
          menuPortalTarget={document.querySelector('body')}
          onChange={(data) => {
            const parsedValue = header.isArray
              ? pipe(pluck('value'), join(','))(data)
              : pipe(path(['value']), defaultTo(null))(data)

            return handleValueChange({
              rows,
              value: parsedValue,
              currentHeader: header,
              currentRow: row,
              table,
              billingItemDescription: path('description')(data),
            })
          }}
        />
      )
    }

    return <S.Text>{valueToRender}</S.Text>
  }

  function renderRowControls() {
    if (isMetrics) {
      return (
        <>
          {rowIndex + 1 === length(rows) && (
            <S.AddRow
              onClick={() =>
                handleAddOrRemoveRows({
                  rows,
                  table,
                  subMenu,
                  fromIndex: rowIndex + 1,
                  action: 'add-line',
                })
              }
            >
              <div>+</div>
            </S.AddRow>
          )}
          {length(rows) > 1 && (
            <S.RemoveRow
              onClick={() =>
                handleAddOrRemoveRows({
                  rows,
                  table,
                  subMenu,
                  fromIndex: rowIndex,
                  action: 'remove-line',
                })
              }
            >
              <div>-</div>
            </S.RemoveRow>
          )}
        </>
      )
    }

    return (
      <>
        <S.AddFirstRow
          onClick={() =>
            handleAddOrRemoveRows({
              rows,
              table,
              subMenu,
              fromIndex: rowIndex,
              action: `add-line`,
              isTiersInterval: headers.some((header) => header.isInterval),
              isTiersIntervalChildren: headers.some(
                (header) => header.isIntervalChild,
              ),
            })
          }
        >
          <div>+</div>
        </S.AddFirstRow>

        <S.AddRow
          onClick={() =>
            handleAddOrRemoveRows({
              rows,
              table,
              subMenu,
              fromIndex: rowIndex + 1,
              action: 'add-line',
              isTiersInterval: headers.some((header) => header.isInterval),
              isTiersIntervalChildren: headers.some(
                (header) => header.isIntervalChild,
              ),
            })
          }
        >
          <div>+</div>
        </S.AddRow>
        {length(rows) > 1 && (
          <S.RemoveRow
            onClick={() =>
              handleAddOrRemoveRows({
                headers,
                rows,
                table,
                subMenu,
                fromIndex: rowIndex,
                action: 'remove-line',
                isTiersInterval: headers.some((header) => header.isInterval),
                isTiersIntervalChildren: headers.some(
                  (header) => header.isIntervalChild,
                ),
              })
            }
          >
            <div>-</div>
          </S.RemoveRow>
        )}
      </>
    )
  }

  function handleShowHideModal() {
    return setIsModalVisible(!isModalVisible)
  }

  function getModalContent(field) {
    const modalHeaders = getEditBillingTableHeaders({
      field,
      tierOptions: {
        hasCompoundTiers: hasCompoundTiers(row),
        headers,
      },
    })

    return {
      headers: modalHeaders,
      rows: row[field],
      parentRow: row,
      parentRows: rows,
      table: field,
      timezone,
      field,
      isEditMode:
        isEditMode && !SELECTABLE_OPTIONS_READONLY_TABLES.includes(table),
      onValueChange: (value) => {
        const payload = {
          ...value,
          parentRow: row,
          parentRows: row[field],
        }

        return handleValueChange(payload)
      },
      handleAddOrRemoveRows: (value) => {
        const payload = {
          ...value,
          parentRow: row,
          parentRows: row[field],
        }

        handleAddOrRemoveRows(payload)
      },
      showRowsControls: 'tiers',
      rawTemplateDetails,
      subMenu,
      action: {
        text: `Create new ${startCase(field)}`,
        onClick: () => {
          const emptyModalRow = getEmptyModalRow({ table, modalHeaders })
          return handleValueChange({
            rows,
            value: [emptyModalRow],
            currentHeader: { field },
            currentRow: row,
            table,
          })
        },
      },
    }
  }

  const modalContent = getModalContent(
    table === 'tiers' ? table : 'selectable_options',
  )

  const modalProps = {
    visible: isModalVisible,
    onConfirm: handleShowHideModal,
    onClose: () => setIsModalVisible(false),
    onCancel:
      table === 'services' ? () => handleDeleteSelectableOptions(row) : null,
    confirmText: 'Close',
    cancelText: 'Remove table',
    type: modalContent.field,
    tableContent: modalContent,
    iconTitle: startCase(modalContent.field),
  }

  return (
    <>
      <S.Row
        ref={setNodeRef}
        style={style}
        $hasBeenMoved={row.has_been_moved}
        $isDragEnabled={enableDragAndDrop}
        $isEditMode={isEditMode}
        $showRowsControls={showRowsControls}
      >
        {enableDragAndDrop && <S.DragHandler {...rowProps} />}
        {headers.map((header, columnIndex) => {
          const { isSelectable } = header
          const isDate = header.isDate && isEditMode
          const isEditable = header.isEditable && isEditMode
          const isNumber = header.isNumber && isEditMode
          const tabIndex = `${table === 'selectable_options' ? 1 : 0}${String(
            columnIndex * rows.length + rowIndex + 1,
          )}`
          const valueToRender = parseCellValue({
            header,
            row,
            rowIndex,
            timezone,
            handleShowHideModal,
            isEditMode,
            table,
          })

          return (
            <S.Cell
              title={valueToRender}
              key={columnIndex}
              $width={hasHorizontalScroll ? header.width : null}
            >
              {renderCellContent({
                isEditable,
                isSelectable,
                isNumber,
                isDate,
                tabIndex,
                valueToRender,
                header,
              })}
            </S.Cell>
          )
        })}

        {renderRowControls()}
      </S.Row>
      <NewModal {...modalProps} />
    </>
  )
}

TemplateValuesRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  rows: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  handleAddOrRemoveRows: PropTypes.func.isRequired,
  enableDragAndDrop: PropTypes.bool.isRequired,
  hasHorizontalScroll: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isProdSavingsValues: PropTypes.bool.isRequired,
  table: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  showRowsControls: PropTypes.bool.isRequired,
  front: PropTypes.isRequired,
  rawTemplateDetails: PropTypes.object.isRequired,
  subMenu: PropTypes.string.isRequired,
  handleDeleteSelectableOptions: PropTypes.func,
}

TemplateValuesRow.defaultProps = {
  handleDeleteSelectableOptions: () => {},
}

export default TemplateValuesRow
