import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { isNil, includes } from 'ramda'

import {
  VEHICLE_STATUS_WRITE_ROLES,
  VEHICLE_UPSERT_WRITE_ROLES,
  VEHICLE_YARD_CHANGE_ROLES,
} from '../../permissions/config'

import {
  NewHeader,
  NewContainer,
  Blank,
  Loading,
  NewTabs,
  NewModal,
} from '../../components'

import VehicleDetailsInfo from '../VehicleDetailsInfo'
import VehicleDetailsNotes from '../VehicleDetailsNotes'
import VehicleDetailsVcr from '../VehicleDetailsVcr'
import VehicleDetailsVwr from '../VehicleDetailsVwr'
import VehicleDetailsEvents from '../VehicleDetailsEvents'

import { history } from '../../utils'
import { usePrevious, useHasVehicleAccess } from '../../hooks'

import * as S from './styles'

import { getTitleAction } from './helpers'

import iconInfo from '../../images/dynamicColor/iconInfo'
import iconOverview from '../../images/dynamicColor/iconOverview'
import iconNotes from '../../images/dynamicColor/iconNotes'
import iconVCR from '../../images/dynamicColor/iconVCR'
import iconVWR from '../../images/dynamicColor/iconVWR'

const VehicleDetails = ({
  vehicleData,
  loadVehicle,
  vehicleIdFromUrl,
  resetVehicle,
  yardId,
  deleteVehicle,
  yardList,
  userRole,
}) => {
  const [selectedTab, setSelectedTab] = useState('info')
  const [showModalDeleteVehicle, setShowModalDeleteVehicle] = useState(false)
  const hasStatusWriteAccess = includes(userRole, VEHICLE_STATUS_WRITE_ROLES)
  const hasUpsertWriteAccess = includes(userRole, VEHICLE_UPSERT_WRITE_ROLES)
  const hasYardChangeWriteAccess = includes(userRole, VEHICLE_YARD_CHANGE_ROLES)

  const tabsProps = {
    items: [
      { key: 0, text: 'Info', value: 'info', icon: iconInfo },
      { key: 1, text: 'Notes', value: 'notes', icon: iconNotes },
      { key: 2, text: "VCR's", value: 'vcrs', icon: iconVCR },
      {
        key: 3,
        text: "VWR's",
        value: 'vwrs',
        icon: iconVWR,
      },
      {
        key: 4,
        text: 'History',
        value: 'history',
        icon: iconOverview,
      },
    ],
    activeItem: selectedTab,
    onChange: (value) => setSelectedTab(value),
  }

  useHasVehicleAccess({ vehicleData, yardList })

  useEffect(() => {
    loadVehicle(vehicleIdFromUrl)
    return resetVehicle()
  }, [loadVehicle, resetVehicle, vehicleIdFromUrl])

  const previousYardId = usePrevious(yardId)

  useEffect(() => {
    if (!isNil(previousYardId) && yardId !== previousYardId) {
      history.push('/vehicles')
    }
  }, [previousYardId, yardId])

  useEffect(() => {
    const tabContentElement = document.querySelector('#tabContent')
    if (tabContentElement) {
      tabContentElement.scrollTop = -tabContentElement.scrollHeight
    }
  }, [selectedTab])

  const vehicleInfo = _.get(vehicleData, 'vehicle')

  const hasVehicleData = !_.isEmpty(vehicleInfo)
  const vehicleId = _.get(vehicleInfo, 'id')
  const vehicleName = _.get(vehicleInfo, 'vehicle_name')

  const headerProps = {
    title: hasVehicleData ? `Vehicle ${vehicleName}` : 'Loading',
    returnText: 'Vehicle list',
    returnUrl: '/vehicles',
    titleAction: getTitleAction({
      vehicleId,
      hasVehicleData,
      hasUpsertWriteAccess,
      hasYardChangeWriteAccess,
      setShowModalDeleteVehicle,
    }),
  }

  function renderTabContent() {
    if (!hasVehicleData) {
      return (
        <S.LoadingContainer>
          <Loading />
        </S.LoadingContainer>
      )
    }

    if (hasVehicleData && isNil(vehicleId)) {
      return <Blank />
    }

    const renderBySelectedTab = {
      info: <VehicleDetailsInfo hasWriteAccess={hasStatusWriteAccess} />,
      notes: (
        <VehicleDetailsNotes
          vehicleId={vehicleIdFromUrl}
          hasWriteAccess={hasStatusWriteAccess}
        />
      ),
      vcrs: <VehicleDetailsVcr vehicleId={vehicleIdFromUrl} />,
      vwrs: <VehicleDetailsVwr vehicleId={vehicleIdFromUrl} />,
      history: <VehicleDetailsEvents vehicleId={vehicleIdFromUrl} />,
    }[selectedTab]

    return renderBySelectedTab
  }

  function handleCloseModal() {
    setShowModalDeleteVehicle(false)
  }

  function handleDeleteVehicle() {
    deleteVehicle({ vehicleId: vehicleIdFromUrl })
    handleCloseModal()
    return null
  }

  const deleteVehicleModalProps = {
    visible: showModalDeleteVehicle,
    onConfirm: handleDeleteVehicle,
    onClose: handleCloseModal,
    onCancel: handleCloseModal,
    title: 'Do you really want to delete?',
    message: `You are attempting to delete vehicle ${vehicleName}. Would you like to remove it?`,
    type: 'warning',
    confirmText: 'Yes, delete',
    cancelText: 'Cancel',
  }

  return (
    <>
      <NewHeader {...headerProps} />
      <NewContainer>
        <NewTabs {...tabsProps} />
        <S.TabContent>{renderTabContent()}</S.TabContent>
      </NewContainer>
      {showModalDeleteVehicle && <NewModal {...deleteVehicleModalProps} />}
    </>
  )
}

VehicleDetails.propTypes = {
  yardId: PropTypes.number.isRequired,
  yardList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      code: PropTypes.number,
    }),
  ).isRequired,
  resetVehicle: PropTypes.func.isRequired,
  loadVehicle: PropTypes.func.isRequired,
  vehicleIdFromUrl: PropTypes.number.isRequired,
  vehicleData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
      PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.object,
          ]),
        ),
      ),
      PropTypes.object,
    ]),
  ).isRequired,
  deleteVehicle: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
}

export default VehicleDetails
