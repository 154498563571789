import * as yup from 'yup'
import { pluck } from 'ramda'

import { getValidationSchema, getInitialFormFields } from './helpers'

import { FormItem } from '../../components'

export const requiredMessage = 'Required'

export const returnUrl = '/allYards?company=ALL&railroad=ALL&view_mode=yards'

export const noneOption = { id: null, name: 'None' }

const weekStartOptions = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
]

const fieldParametersList = [
  {
    name: 'name',
    validation: yup.string().required(requiredMessage),
    type: 'text',
    label: 'Yard Name',
    placeholder: 'Enter yard name',
  },
  {
    name: 'code',
    validation: yup.number().required(requiredMessage),
    type: 'number',
    label: 'Profit Center',
    placeholder: 'Enter profit center code',
  },
  {
    name: 'timezone',
    validation: yup.string().required(requiredMessage),
    type: 'select',
    label: 'Yard Timezone',
    placeholder: 'Select yard timezone',
    options: [],
  },
  {
    name: 'latitude',
    validation: yup.number().required(requiredMessage),
    type: 'number',
    label: 'Latitude',
    placeholder: 'Enter yard latitude',
  },
  {
    name: 'longitude',
    validation: yup.number().required(requiredMessage),
    type: 'number',
    label: 'Longitude',
    placeholder: 'Enter yard latitude',
  },
  {
    name: 'week_start',
    validation: yup.number().required(requiredMessage),
    type: 'select',
    label: 'Week Start',
    placeholder: 'Select week start',
    options: weekStartOptions,
  },
  {
    name: 'billing_company_id',
    validation: yup.number().nullable(),
    type: 'select',
    label: 'Company',
    placeholder: 'Select company',
    options: [],
  },
  {
    name: 'billing_customer_id',
    validation: yup.number().nullable(),
    type: 'select',
    label: 'Customer',
    placeholder: 'Select customer',
    options: [],
  },
  {
    name: 'is_week_full',
    validation: yup.bool(),
    type: 'bool',
    label: 'Is Week Full?',
  },
  {
    name: 'is_active',
    validation: yup.bool().required(requiredMessage),
    type: 'bool',
    label: 'Is Yard Active?',
    value: true,
  },
]

export const fields = pluck('name')(fieldParametersList)

export const validationSchema = getValidationSchema(fieldParametersList)

export const formFields = getInitialFormFields(fieldParametersList).map(
  (field) => ({
    ...field,
    component: FormItem,
  }),
)

export const unsavedChangesModalProps = {
  title: 'Do you really want to leave?',
  message: 'You have unsaved changes, do you want to leave without saving it?',
  confirmText: 'Keep editing',
  cancelText: 'Leave',
  type: 'warning',
  isMandatory: true,
}

export const confirmChangesModalProps = {
  title: 'Do you want to save?',
  message: 'Are you sure to submit those changes?',
  confirmText: 'Submit changes',
  cancelText: 'Review changes',
  type: 'warning',
  isMandatory: true,
}

export const fieldsBlockedToUpdate = ['week_start', 'timezone', 'is_week_full']

export const IS_WEEK_FULL_DEFAULT_VALUE = false
export const IS_YARD_ACTIVE_DEFAULT_VALUE = true
