import React, { useEffect } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import {
  ToggleContainer,
  BillingBadge,
  Blank,
  NewToolTip,
} from '../../components'

import { getCycleHistory } from './helpers'
import { billingHistoryLimit } from '../../config'
import {
  history as route,
  getDownloadFilename,
  getYardCodeById,
  getScrollEnd,
  parseDateTime,
} from '../../utils'

import * as S from './styles'

const BillingCycleHistory = ({
  loadBillingInvoices,
  billingInvoices,
  isSubmissionEnabled,
  timezone,
  selectedYard,
  billingTable,
  urlDate,
  loadTableToExport,
  yardList,
  templateId,
}) => {
  useEffect(() => {
    loadBillingInvoices({
      yard: selectedYard,
      templateId,
      limit: billingHistoryLimit,
      offset: 0,
    })
  }, [templateId, loadBillingInvoices, selectedYard, billingTable])

  const history = getCycleHistory({
    historyPeriods: _.get(billingInvoices, 'value', []),
    timezone,
  })

  const isHistoryEmpty = _.isEmpty(history)

  function handleScrollEnd() {
    const nextOffset = _.get(billingInvoices, 'nextOffset')

    return _.isNil(nextOffset)
      ? null
      : loadBillingInvoices({
          yard: selectedYard,
          limit: billingHistoryLimit,
          offset: nextOffset,
          isLazyLoading: true,
          templateId,
        })
  }

  function handleScroll(event) {
    return getScrollEnd(event) ? handleScrollEnd() : null
  }

  function handleInvoiceToSubmit({ invoiceId }) {
    route.push({
      pathname: `/yard/${selectedYard}/billing/submit/${invoiceId}`,
      query: { return_url_date: urlDate },
    })
  }

  function handleFileDownload(invoice) {
    const yardCode = getYardCodeById({ yardList, yardId: selectedYard })
    const fileName = getDownloadFilename({ invoice, yardCode, timezone })

    return loadTableToExport({
      invoiceId: invoice.id,
      fileName: `${fileName}_DAILY`,
    })
  }

  function renderHistory() {
    if (isHistoryEmpty) {
      return (
        <S.EmptyContainer>
          <Blank small subtitle="No billing cycles to show" />
        </S.EmptyContainer>
      )
    }

    return history.map((item) => {
      const tooltip = {
        title: `Template: ${item.BillingTemplate.name}`,
        description: (() => (
          <>
            <S.TemplateInfo light>
              Start date:{' '}
              {parseDateTime({
                timestamp: item.BillingTemplate.start_date,
                timezone,
              })}
            </S.TemplateInfo>
            <S.TemplateInfo light>
              End date:{' '}
              {parseDateTime({
                timestamp: item.BillingTemplate.end_date,
                timezone,
              })}
            </S.TemplateInfo>
          </>
        ))(),
      }

      return (
        <S.Cycles key={item.id}>
          <BillingBadge
            text={item.status}
            amountToDisplay={item.amountToDisplay}
          />

          <NewToolTip {...tooltip}>
            <S.Period>
              <S.ButtonPeriod
                onClick={item.onClick}
                disabled={!isSubmissionEnabled}
              >
                {item.period}
              </S.ButtonPeriod>
              {` | ${item.year} | ${item.billing_cycle}`}
              <S.TemplateInfo>
                Template: {item.BillingTemplate.name}
              </S.TemplateInfo>
            </S.Period>
          </NewToolTip>

          <S.Flex>
            <S.Button $isVisible={item.attachments_size > 0} $hasAttachment />
            <S.Button onClick={() => handleFileDownload(item)} isPreview />
            <S.Button
              onClick={() => handleInvoiceToSubmit({ invoiceId: item.id })}
              isReadyToSubmit={
                (isSubmissionEnabled && item.isReadyToSubmit) ||
                (isSubmissionEnabled && item.isSubmitted)
              }
              disabled={!item.isReadyToSubmit}
            >
              {item.isSubmitted ? 'Details' : 'Submit'}
            </S.Button>
          </S.Flex>
        </S.Cycles>
      )
    })
  }

  return (
    <ToggleContainer
      onScroll={(event) => handleScroll(event)}
      title="Recent Billing Cycles"
    >
      {renderHistory()}
    </ToggleContainer>
  )
}

BillingCycleHistory.propTypes = {
  loadBillingInvoices: PropTypes.func.isRequired,
  billingInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      isLoading: PropTypes.bool,
      value: PropTypes.shape({
        invoices: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            billing_cycle: PropTypes.string,
            end_date: PropTypes.string,
            start_date: PropTypes.string,
            status: PropTypes.string,
          }),
        ),
      }),
    }),
  ).isRequired,
  isSubmissionEnabled: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  selectedYard: PropTypes.number.isRequired,
  billingTable: PropTypes.shape({
    value: PropTypes.shape({
      end_date: PropTypes.string,
      last_updated: PropTypes.shape({
        updated_at: PropTypes.string,
        user: PropTypes.shape({
          first_name: PropTypes.string,
          id: PropTypes.number,
          last_name: PropTypes.string,
        }),
      }),
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          billing_cycle: PropTypes.string,
          code: PropTypes.string,
          columns: PropTypes.arrayOf(
            PropTypes.shape({
              billing_template_item_id: PropTypes.number,
              comment: PropTypes.any,
              created_at: PropTypes.string,
              date: PropTypes.string,
              deleted_at: PropTypes.any,
              id: PropTypes.number,
              invoice_id: PropTypes.number,
              updated_at: PropTypes.string,
              user_id: PropTypes.any,
              value: PropTypes.any,
            }),
          ),
          customer_number: PropTypes.string,
          customer_yard_id: PropTypes.any,
          default_value: PropTypes.any,
          description: PropTypes.string,
          display_name: PropTypes.string,
          display_order: PropTypes.number,
          filling_type: PropTypes.string,
          formula_function: PropTypes.any,
          formula_options: PropTypes.any,
          group: PropTypes.string,
          id: PropTypes.number,
          is_comment_required: PropTypes.bool,
          is_daily: PropTypes.bool,
          po: PropTypes.any,
          price_uom: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
      start_date: PropTypes.string,
      week_dates: PropTypes.arrayOf(PropTypes.string),
    }),
    isLoading: PropTypes.bool,
    billing_items: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
        display_name: PropTypes.string,
        display_order: PropTypes.number,
        group: PropTypes.string,
        is_comment_required: PropTypes.bool,
        is_daily: PropTypes.bool,
        price_uom: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }).isRequired,
  urlDate: PropTypes.string.isRequired,
  loadTableToExport: PropTypes.func.isRequired,
  yardList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
  ).isRequired,
  templateId: PropTypes.string,
}

BillingCycleHistory.defaultProps = {
  templateId: null,
}

export default BillingCycleHistory
