import { yardSummaryTypes } from '../../actions/yardSummaryActions'

const toggleTag = (tags, id, selected) =>
  tags.map((tag) => {
    if (tag.id === id) {
      return { ...tag, selected }
    }

    return tag
  })

const initialState = {
  loading: false,
  value: [],
}

const tags = (state = initialState, { type, payload }) => {
  switch (type) {
    case yardSummaryTypes.TAGS_REQUESTED:
      return {
        ...state,
        loading: true,
      }

    case yardSummaryTypes.TAGS_LOADED:
      return {
        ...state,
        loading: false,
        value: payload.tags.map((tag) => ({
          ...tag,
          selected: false,
        })),
      }

    case yardSummaryTypes.TAGS_FAILED:
      return initialState

    case yardSummaryTypes.YARD_TAGS_LOADED: {
      const value = state.value.map((tag) => ({
        ...tag,
        selected: payload.yardTags.includes(tag.id),
      }))

      return { ...state, value }
    }

    case yardSummaryTypes.SELECT_TAG_REQUESTED:
      return {
        ...state,
        value: toggleTag(state.value, payload.id, true),
      }

    case yardSummaryTypes.SELECT_TAG_FAILED:
      return {
        ...state,
        value: toggleTag(state.value, payload.id, false),
      }

    case yardSummaryTypes.DESELECT_TAG_REQUESTED:
      return {
        ...state,
        value: toggleTag(state.value, payload.id, false),
      }

    case yardSummaryTypes.DESELECT_TAG_FAILED:
      return {
        ...state,
        value: toggleTag(state.value, payload.id, true),
      }

    default:
      return state
  }
}

export default tags
