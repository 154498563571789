import { connect } from 'react-redux'
import LiveViewMarkers from './LiveViewMarkers'

import {
  setIconSelected,
  setIconPinned,
} from '../../../actions/trackingActions'
import { loadDeviceSessions } from '../../../actions/deviceStatusActions'

const mapStateToProps = (state) => ({
  selectionType: state.tracking.selectionType,
  trackingData: state.tracking.trackingData,
  iconSelected: state.tracking.iconSelected,
  iconPinned: state.tracking.iconPinned,
  vehicleList: state.manageVehicles.vehicleList,
  deviceSessions: state.deviceStatus.deviceSessions,
  selectedTooltips: state.tracking.selectedTooltips,
  selectedYard: state.general.selectedYard,
  showNoComms: state.tracking.vehiclesViewMode.showNoComms,
})

const mapDispatchToProps = {
  setIconSelected,
  setIconPinned,
  loadDeviceSessions,
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveViewMarkers)
