import { trackingTypes } from '../../actions/trackingActions'

const initialState = {
  lat: 0,
  lng: 0,
}

const mapCenter = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.CENTER_MAP:
      return payload

    default:
      return state
  }
}

export default mapCenter
