import { isNil } from 'ramda'
import { EDIT_BILLING_TABLE_HEADERS } from '../config'

function getField({ field, tierOptions }) {
  if (isNil(tierOptions)) {
    return field
  }

  const isIntervalChild = tierOptions.headers[0].field !== 'billing_item_id'

  if (isIntervalChild) {
    return 'compound_tiers_children'
  }

  const { hasCompoundTiers } = tierOptions

  return hasCompoundTiers ? 'compound_tiers' : field
}

export default function getEditBillingTableHeaders({ field, tierOptions }) {
  return EDIT_BILLING_TABLE_HEADERS[getField({ field, tierOptions })]
}
