import React from 'react'
import queryString from 'query-string'
import moment from 'moment-timezone'
import shortid from 'shortid'
import {
  __,
  map,
  pipe,
  pick,
  prop,
  path,
  join,
  head,
  isNil,
  pluck,
  curry,
  sortBy,
  unnest,
  values,
  reject,
  toPairs,
  groupBy,
  addIndex,
  includes,
  defaultTo,
  complement,
  sort,
  prepend,
  omit,
  isEmpty,
} from 'ramda'
import _ from 'lodash'

import * as S from './styles'
import { trimIfString } from '../../utils/ramdaExtensions'
import { BillingBadge, NewToolTip, Na } from '../../components'

import {
  SERVICES_EDITABLE_TABLE_HEADERS,
  SERVICES_CONSTANT_TABLE_HEADERS,
  SERVICES_DERIVED_TABLE_HEADERS,
  SERVICES_SELECTABLE_TABLE_HEADERS,
  SERVICES_VIEW_MODE,
  CHARGES_VIEW_MODE,
  CHARGES_DERIVED_TABLE_HEADERS,
  CHARGES_EDITABLE_TABLE_HEADERS,
  PAGE_SECTION,
  VIEW_MODE_OPTIONS,
  FIELDS_TO_OMIT,
  PROD_SAVINGS_VALUES_NEW_ROW,
  METRICS_NEW_ROW,
  SERVICES_EDITABLE_NEW_ROW,
  SERVICES_SELECTABLE_NEW_ROW,
  SERVICES_CONSTANT_NEW_ROW,
  SERVICES_DERIVED_NEW_ROW,
  SELECTABLE_OPTIONS_READONLY_TABLES,
  CHARGES_EDITABLE_NEW_ROW,
  CHARGES_DERIVED_NEW_ROW,
  TIERS_NEW_ROW,
  TIERS_INTERVAL_NEW_ROW,
  TIERS_INTERVAL_CHILDREN_NEW_ROW,
  SERVICES_SELECTABLE_OPTIONS_NEW_ROW,
  COMPOUND_TIER_NEW_ROW,
  SINGLE_TIER_NEW_ROW,
} from './constants'

import { parseDateTime, parseBillingCycle } from '../../utils'

const sortByDisplayOrder = sortBy(({ display_order: displayOrder }) =>
  defaultTo(Number.MAX_SAFE_INTEGER, displayOrder),
)

function insertId(array) {
  return array.map((value, index) => ({
    id: index,
    ...value,
  }))
}

function getTiersGroupedByCode(data) {
  return pipe(groupBy(path(['billing_item', 'code'])), map(head))(data.tiers)
}

const addFromTiersConfig = (data) =>
  map((item) => {
    const tierConfigByCode = getTiersGroupedByCode(data)
    const tierConfig = tierConfigByCode[item.billing_item.code]

    return {
      ...item,
      tiers: prop('tiers')(tierConfig),
    }
  })

export function getProdSavingsTableRows(data, timezone) {
  if (isNil(data.productivity_savings)) {
    return []
  }

  const rows = insertId([data.productivity_savings])

  const valuesRows = pipe(
    insertId,
    map((item) => {
      if (item.date === 'Life of Contract') {
        return {
          ...item,
          date: moment.tz(timezone).format('YYYY-MM-DD'),
        }
      }

      return item
    }),
  )(data.productivity_savings.values)

  const parsedProdSavings = {
    ...head(rows),
    values: valuesRows,
  }

  return parsedProdSavings
}

export function getMetricsTableRows(data) {
  if (isNil(data.metrics)) {
    return []
  }

  return data.metrics
}

export function getServicesTableRows(data) {
  if (isNil(data.services)) {
    return {
      editable: [],
      constant: [],
      derived: [],
      selectable: [],
    }
  }

  return data.services
}

export function getChargesTableRows(data) {
  if (isNil(data.services)) {
    return {
      editable: [],
      derived: [],
    }
  }

  return data.charges
}

export function getAffectedInvoiceRow({ row, timezone }) {
  return {
    ...row,
    start_date: moment.tz(row.start_date, timezone).format('MM/DD/YYYY'),
    end_date: moment.tz(row.end_date, timezone).format('MM/DD/YYYY'),
    status: <BillingBadge text={row.status} />,
  }
}

function getRawOptions({ meta, front, header, rawTemplateDetails }) {
  const { metaPath, frontPath, getOptions } = header
  if (typeof getOptions === 'function') {
    return getOptions({ rawTemplateDetails })
  }
  if (!isNil(frontPath)) {
    return Array.isArray(frontPath) ? path(frontPath)(front) : front[frontPath]
  }

  return Array.isArray(metaPath) ? path(metaPath)(meta) : meta[metaPath]
}

export function getOptionsByField({
  rawTemplateDetails,
  meta,
  front,
  header,
  valueToRender,
}) {
  const {
    transformOptions,
    hasStartCase,
    isIntercompanyYard,
    hasEmptySelectableValue,
  } = header

  if (header.isDailyInput) {
    return [
      { value: 'true', label: 'Daily' },
      { value: 'false', label: 'Submission' },
    ]
  }

  const rawOptions = getRawOptions({ meta, front, header, rawTemplateDetails })

  const transformedOptions = !isNil(transformOptions)
    ? transformOptions({
        valueToRender,
        rawTemplateDetails,
        rawOptions,
      })
    : rawOptions

  const hasObjects = transformedOptions.some((item) => typeof item === 'object')

  const sortedOptions = sort(
    (a, b) => (hasObjects ? a.code.localeCompare(b.code) : a.localeCompare(b)),
    transformedOptions,
  )

  const parsedOptions = hasEmptySelectableValue
    ? pipe(
        map((item) => ({
          id: item,
          code: hasStartCase ? _.startCase(item) : item,
        })),
        prepend({ id: null, code: 'N/A', disabled: true }),
      )(sortedOptions)
    : sortedOptions

  return map((variable) => {
    if (header.isBillingCycle) {
      return {
        value: variable,
        label: parseBillingCycle(variable),
      }
    }

    if (isIntercompanyYard) {
      return {
        value: Number(variable.id),
        label: header.parseYardName(variable),
      }
    }

    if (hasObjects || hasEmptySelectableValue) {
      return {
        value: variable.id,
        label: variable.code,
        isFixed: variable.disabled,
        description: isNil(variable.data) ? null : variable.data.description,
      }
    }

    if (hasStartCase) {
      return {
        value: variable,
        label: _.startCase(variable),
      }
    }

    return {
      value: variable,
      label: variable,
    }
  })(parsedOptions)
}

export function getExportTableRows(data) {
  if (isNil(data.export_order_items) || isNil(data.tiers)) {
    return []
  }
  return pipe(
    sortBy(prop('export_order')),
    addFromTiersConfig(data),
  )(data.export_order_items)
}

export function getDisplayOrderTableRows(data) {
  if (isNil(data.display_order_items) || isNil(data.tiers)) {
    return []
  }

  return pipe(
    sortByDisplayOrder,
    addFromTiersConfig(data),
  )(data.display_order_items)
}

export function getTiersTableRows(data) {
  if (isNil(data.tiers)) {
    return []
  }

  return data.tiers
}

export function getTemplate(data) {
  if (isNil(data.template)) {
    return {}
  }

  return data.template
}

function parseBoolean(value) {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No'
  }

  return value
}

export function parseYardName(yard) {
  if (isNil(yard)) {
    return null
  }

  if (isNil(yard.id)) {
    return null
  }

  return `${yard.name} - ${yard.code}`
}

export function getTooltipProps(row) {
  const description = pipe(
    pick(['type', 'group', 'price_uom', 'description', 'is_comment_required']),
    toPairs,
    map(([key, value]) => (
      <div key={key}>- {`${_.startCase(key)} - ${parseBoolean(value)}`}</div>
    )),
  )(row.billing_item)

  return {
    title: `${row.billing_item.code} Details`,
    description,
  }
}

export function getServiceTableContent({ UrlSubMenu, data }) {
  const { constant, derived, editable, selectable } = getServicesTableRows(data)

  switch (UrlSubMenu) {
    case SERVICES_VIEW_MODE.EDITABLE:
      return {
        headers: SERVICES_EDITABLE_TABLE_HEADERS,
        rows: editable,
      }

    case SERVICES_VIEW_MODE.DERIVED:
      return {
        headers: SERVICES_DERIVED_TABLE_HEADERS,
        rows: derived,
      }

    case SERVICES_VIEW_MODE.CONSTANT:
      return {
        headers: SERVICES_CONSTANT_TABLE_HEADERS,
        rows: constant,
      }

    case SERVICES_VIEW_MODE.SELECTABLE:
      return {
        headers: SERVICES_SELECTABLE_TABLE_HEADERS,
        rows: selectable,
      }

    default:
      return null
  }
}

export function getChargesTableContent({ UrlSubMenu, data }) {
  const { derived, editable } = getChargesTableRows(data)

  switch (UrlSubMenu) {
    case CHARGES_VIEW_MODE.EDITABLE:
      return {
        headers: CHARGES_EDITABLE_TABLE_HEADERS,
        rows: editable,
      }

    case CHARGES_VIEW_MODE.DERIVED:
      return {
        headers: CHARGES_DERIVED_TABLE_HEADERS,
        rows: derived,
      }

    default:
      return null
  }
}

export function hasCompoundTiers(row) {
  return !isNil(row.compound_tier_variable)
}

export function getTitleTooltip({ template, timezone }) {
  return {
    title: 'Template details',
    description: (() => {
      const name = _.get(template, 'name')
      const deletedAt = _.get(template, 'deleted_at')
      const startDate = _.get(template, 'start_date')
      const endDate = _.get(template, 'end_date')

      return (
        <>
          <div>{`Name: ${name}`}</div>
          <div>{`Start date: ${parseDateTime(
            moment.tz(startDate, timezone),
          )}`}</div>
          <div>{`End date: ${parseDateTime(
            moment.tz(endDate, timezone),
          )}`}</div>
          {!isNil(deletedAt) && (
            <div>{`Deleted at: ${parseDateTime(
              moment.tz(deletedAt, timezone),
            )}`}</div>
          )}
        </>
      )
    })(),
  }
}

function getDate({ timestamp, timezone }) {
  if (timestamp === null) {
    return null
  }

  const date = moment.tz(timestamp, timezone)

  if (!date.isValid()) {
    return 'Life of Contract'
  }

  return date.format('MM/DD/YYYY')
}

function getPageSection(row) {
  if (row.is_daily) {
    return `${PAGE_SECTION.TABLE} and ${PAGE_SECTION.BILLABLE_SALESPARTS}`
  }

  if (row.filling_type === 'constant') {
    return PAGE_SECTION.BILLABLE_SALESPARTS
  }

  if (includes(row.filling_type, ['derived', 'productivity_saving'])) {
    return PAGE_SECTION.CALCULATED_VALUES
  }

  if (row.filling_type === 'editable') {
    return PAGE_SECTION.ADDITIONAL_BILLING_DETAILS
  }

  return 'Unkown'
}

export function parseCellValue({
  header,
  row,
  rowIndex,
  timezone,
  handleShowHideModal,
  isEditMode,
  table,
}) {
  const selectedPath = Array.isArray(header.field)
    ? header.field
    : [header.field]

  const rawValue = path(selectedPath)(row)

  if (header.hasSubmissionView) {
    return getPageSection(row)
  }

  if (header.isRowIndex) {
    return rowIndex + 1
  }

  if (isNil(rawValue) && !isEditMode) {
    return <Na />
  }

  if (isNil(rawValue) && isEditMode && !header.isEditable) {
    return header.isDate ? null : <Na />
  }

  if (header.isIntercompanyYard) {
    return isNil(rawValue) ? <Na /> : rawValue
  }

  if (header.isBoolean) {
    return rawValue
  }

  if (header.hasModalInfo) {
    if (
      SELECTABLE_OPTIONS_READONLY_TABLES.includes(table) &&
      isNil(row.selectable_options)
    ) {
      return <Na />
    }

    return <S.Button onClick={() => handleShowHideModal()}>Details</S.Button>
  }

  if (header.isRowIndex) {
    return rowIndex + 1
  }

  if (header.isDailyInput) {
    return String(rawValue)
  }

  if (typeof rawValue === 'boolean') {
    return rawValue ? 'Yes' : 'No'
  }

  if (typeof header.getTooltipProps === 'function' && !isEditMode) {
    const toolTipProps = header.getTooltipProps(row)

    return (
      <NewToolTip {...toolTipProps}>
        <div>{rawValue}</div>
      </NewToolTip>
    )
  }

  if (header.isBillingCycle && !isNil(rawValue)) {
    return Array.isArray(rawValue) ? head(rawValue) : rawValue
  }

  if (header.hasStartCase && !header.isSelectable) {
    if (isEditMode) {
      return isNil(rawValue) ? '' : _.startCase(rawValue)
    }

    return isNil(rawValue) ? <Na /> : _.startCase(rawValue)
  }

  if (header.isDate) {
    if (isNil(rawValue)) {
      return rawValue
    }

    return getDate({ timestamp: rawValue, timezone })
  }

  if (Array.isArray(rawValue)) {
    return join(', ')(rawValue)
  }

  return rawValue
}

export function getKeyValueByTable(table) {
  return {
    display_order_items: 'display_order',
    export_order_items: 'export_order',
    productivity_savings: 'productivity_savings',
    productivity_savings_values: 'productivity_savings.values',
    services: 'services',
    selectable_options: 'services',
  }[table]
}

export const sanitizeRowBeforeSubmit = map(
  pipe(trimIfString, (value) => (isNil(value) || value === '' ? null : value)),
)

export const getTabItems = ({
  hasDisplayOrderChanges,
  hasExportChanges,
  hasProdSavingsChanges,
  hasProdSavingsValuesChanges,
}) =>
  VIEW_MODE_OPTIONS.map((tab) => {
    const parsedTab = tab
    const hasUserChanges =
      (hasDisplayOrderChanges && tab.value === 'display_order_items') ||
      (hasExportChanges && tab.value === 'export') ||
      (hasProdSavingsChanges && tab.value === 'productivity_savings') ||
      (hasProdSavingsValuesChanges && tab.value === 'productivity_savings')

    parsedTab.notification = hasUserChanges
    return parsedTab
  })

export function getReturnUrl({ selectedYard, returnDate }) {
  const baseUrl = `/yard/${selectedYard}/billing/templates`
  const returnDateUrl = queryString.stringify({
    return_date: returnDate,
  })

  return `${baseUrl}?${returnDateUrl}`
}

export function getFieldsToOmit(hasProdSavingsLifeOfContractDate) {
  if (hasProdSavingsLifeOfContractDate) {
    const newFieldsToOmit = [...FIELDS_TO_OMIT, 'date']
    return newFieldsToOmit
  }

  return FIELDS_TO_OMIT
}

export function getSpreadProdSavingsValues(prodSavings) {
  if (_.isEmpty(prodSavings)) {
    return []
  }

  return prodSavings.values
}

export function getNewRowByTable({
  table,
  subMenu,
  rawTemplateDetails,
  isTiersIntervalChildren,
  isTiersInterval,
}) {
  if (table === 'productivity_savings') {
    return PROD_SAVINGS_VALUES_NEW_ROW
  }

  const id = `_${shortid.generate()}`

  if (isTiersIntervalChildren && table === 'tiers') {
    return {
      ...TIERS_INTERVAL_CHILDREN_NEW_ROW,
      id,
    }
  }

  if (isTiersInterval && table === 'tiers') {
    return {
      ...TIERS_INTERVAL_NEW_ROW,
      id,
    }
  }

  if (table === 'tiers') {
    return {
      ...TIERS_NEW_ROW,
      id,
    }
  }

  if (table === 'metrics') {
    return {
      ...METRICS_NEW_ROW,
      ...rawTemplateDetails.meta.default_new.metrics,
      id,
    }
  }

  if (table === 'services') {
    const newRowToDisplay = {
      editable: SERVICES_EDITABLE_NEW_ROW,
      selectable: SERVICES_SELECTABLE_NEW_ROW,
      constant: SERVICES_CONSTANT_NEW_ROW,
      derived: SERVICES_DERIVED_NEW_ROW,
    }[subMenu]

    return {
      ...newRowToDisplay,
      ...rawTemplateDetails.meta.default_new.services[subMenu],
      id,
    }
  }

  if (table === 'charges') {
    const newRowToDisplay = {
      editable: CHARGES_EDITABLE_NEW_ROW,
      derived: CHARGES_DERIVED_NEW_ROW,
    }[subMenu]

    return {
      ...newRowToDisplay,
      ...rawTemplateDetails.meta.default_new.charges[subMenu],
      id,
    }
  }

  return {}
}

function getBillingItemFromFront({ item, rawTemplateDetails }) {
  const emptyItem = {
    code: '-',
    type: '-',
    group: '-',
    price_uom: '-',
    description: '-',
    is_comment_required: false,
  }

  const result =
    isNil(item.billing_item_id) || isEmpty(item.billing_item_id)
      ? emptyItem
      : rawTemplateDetails.front.billingItemById[item.billing_item_id]

  return result
}

export const unnestValues = pipe(values, unnest)

const appendProdSavings = curry((rawTemplateDetails, allItems) => {
  if (!isNil(rawTemplateDetails.productivity_savings)) {
    const data = rawTemplateDetails.productivity_savings
    const prodSavings = {
      ...pick(['id', 'billing_item_id', 'billing_cycle'])(data),
      is_daily: false,
      filling_type: 'prod_sav',
      default_value: null,
      is_parent: true,
      display_name: 'Prod Savings',
      intercompany_yard: null,
    }
    return [...allItems, prodSavings]
  }

  return allItems
})
function parseDisplayOrderItems({ rawTemplateDetails }) {
  if (_.isEmpty(rawTemplateDetails)) {
    return []
  }

  const allItems = appendProdSavings(rawTemplateDetails)([
    ...unnestValues(rawTemplateDetails.services),
    ...unnestValues(rawTemplateDetails.charges),
    ...rawTemplateDetails.metrics,
  ])

  const itemById = pipe(groupBy(prop('id')), map(head))(allItems)

  const defaultDisplayOrderItem = {
    id: 0,
    display_order: null,
    is_daily: false,
    filling_type: '-',
    billing_cycle: '-',
    default_value: 0,
    is_parent: false,
    display_name: '-',
    export_comment: '-',
    intercompany_yard: null,
    billing_item: {
      code: '-',
      type: '-',
      group: '-',
      price_uom: '-',
      description: '-',
      is_comment_required: false,
    },
  }

  return map((displayOrderItem) => {
    const item = itemById[displayOrderItem.id]
    const displayOrderItemFields = pick([
      'id',
      'display_order',
      'has_been_moved',
    ])(displayOrderItem)

    if (isNil(item)) {
      return {
        ...defaultDisplayOrderItem,
        ...displayOrderItemFields,
      }
    }

    const billingItem = getBillingItemFromFront({ item, rawTemplateDetails })

    return {
      ...displayOrderItemFields,
      ...pick([
        'is_daily',
        'filling_type',
        'billing_cycle',
        'display_name',
        'intercompany_yard',
        'default_value',
        'is_parent',
        'export_comment',
        'selectable_options',
      ])(item),
      billing_item: billingItem,
    }
  })(rawTemplateDetails.display_order_items)
}

function parseExportOrderItems({ rawTemplateDetails }) {
  if (_.isEmpty(rawTemplateDetails)) {
    return []
  }

  const allItems = appendProdSavings(rawTemplateDetails)([
    ...unnestValues(rawTemplateDetails.services),
    ...unnestValues(rawTemplateDetails.charges),
  ])

  const itemById = pipe(groupBy(prop('id')), map(head))(allItems)

  return map((exportOrderItem) => {
    const item = itemById[exportOrderItem.id]

    const billingItem = getBillingItemFromFront({ item, rawTemplateDetails })

    return {
      ...pick([
        'id',
        'export_order',
        'customer_number',
        'invoice_group',
        'billing_address',
        'export_comment',
        'export_function',
        'export_function_options',
        'has_been_moved',
      ])(exportOrderItem),
      ...pick([
        'is_daily',
        'filling_type',
        'billing_cycle',
        'display_name',
        'intercompany_yard',
        'default_value',
        'is_parent',
        'selectable_options',
      ])(item),
      billing_item: billingItem,
    }
  })(rawTemplateDetails.export_order_items)
}

function parseFront({ rawTemplateDetails }) {
  if (_.isEmpty(rawTemplateDetails)) {
    return {
      billingItemById: {},
      driver_variables: [],
    }
  }

  const driverVariables = pipe(
    reject(pipe(prop('billing_item_id'), isNil)),
    map((metric) => {
      const billingItem =
        rawTemplateDetails.front.billingItemById[metric.billing_item_id]
      return billingItem.code
    }),
  )(rawTemplateDetails.metrics)

  return {
    ...rawTemplateDetails.front,
    driver_variables: driverVariables,
  }
}

// TODO fill export_comment from export_order_items in all used places,
// becasue it's the source of this information
export function parseRawTemplateDetails({ isLoading, rawTemplateDetails }) {
  if (isLoading) {
    return {}
  }

  const parsedDisplayOrderItems = parseDisplayOrderItems({ rawTemplateDetails })
  const parsedExportOrderItems = parseExportOrderItems({ rawTemplateDetails })
  const parsedFront = parseFront({ rawTemplateDetails })

  return {
    ...rawTemplateDetails,
    display_order_items: parsedDisplayOrderItems,
    export_order_items: parsedExportOrderItems,
    front: parsedFront,
  }
}

export function getDefaultValue({ options, valueToRender }) {
  if (typeof valueToRender === 'boolean') {
    return valueToRender
  }

  return valueToRender
    ? options.find((option) => option.value === valueToRender)
    : null
}

export const mapIndexed = addIndex(map)

const addIndexOrder = (fieldName) =>
  mapIndexed((item, index) => ({
    ...item,
    [fieldName]: index + 1,
  }))

export const addDisplayOrderIndex = addIndexOrder('display_order')

export const addExportOrderIndex = addIndexOrder('export_order')

export function appendNewExportOrderItem({ rawTemplateDetails, item }) {
  return addExportOrderIndex([
    ...rawTemplateDetails.export_order_items,
    {
      ...item,
      customer_number: null,
      invoice_group: null,
      billing_address: null,
      export_comment: null,
      export_function: null,
      export_function_options: null,
    },
  ])
}

export const hasDisplayOrder = pipe(prop('display_order'), complement(isNil))

export function rejectDisplayOrderItems({ shouldReject, rawTemplateDetails }) {
  return addDisplayOrderIndex([
    ...reject(shouldReject)(rawTemplateDetails.display_order_items),
    ...reject(hasDisplayOrder)(rawTemplateDetails.display_order_items),
  ])
}

export function rejectExportOrderItems({ shouldReject, rawTemplateDetails }) {
  return pipe(
    reject(shouldReject),
    sortBy(prop('export_order')),
    addDisplayOrderIndex,
  )(rawTemplateDetails.export_order_items)
}

export function shouldRejectItems(items) {
  return pipe(prop('id'), includes(__, pluck('id')(items)))
}

export function parseMetricsBeforeSubmission(metrics) {
  return metrics.map((item) =>
    sanitizeRowBeforeSubmit({
      ...pick([
        'id',
        'billing_item_id',
        'display_name',
        'billing_cycle',
        'is_daily',
        'default_value',
        'fraction_digits',
      ])(item),
    }),
  )
}

export function parseServicesBeforeSubmission(servicesData) {
  const editable = servicesData.editable.map((item) =>
    sanitizeRowBeforeSubmit({
      ...pick([
        'id',
        'billing_item_id',
        'display_name',
        'billing_cycle',
        'is_parent',
        'intercompany_yard_id',
        'is_chargeable',
        'allowed_values',
        'po',
      ])(item),
    }),
  )

  const constant = servicesData.constant.map((item) =>
    sanitizeRowBeforeSubmit({
      ...pick([
        'id',
        'billing_item_id',
        'display_name',
        'billing_cycle',
        'is_chargeable',
        'is_parent',
        'intercompany_yard_id',
        'default_value',
        'po',
      ])(item),
    }),
  )

  const derived = servicesData.derived.map((item) =>
    sanitizeRowBeforeSubmit({
      ...pick([
        'id',
        'billing_item_id',
        'display_name',
        'billing_cycle',
        'is_chargeable',
        'is_parent',
        'intercompany_yard_id',
        'po',
        'formula_function',
        'formula_options',
      ])(item),
    }),
  )

  const parsedSelectable = servicesData.selectable.map((item) => {
    const newItem = { ...item }

    if (Array.isArray(item.selectable_options)) {
      const parsedSelectableOptions = item.selectable_options.map((option) =>
        omit(['id', 'services'])(option),
      )

      newItem.selectable_options = parsedSelectableOptions
    }

    return newItem
  })

  const selectable = parsedSelectable.map((item) =>
    sanitizeRowBeforeSubmit({
      ...pick([
        'id',
        'billing_item_id',
        'display_name',
        'billing_cycle',
        'is_chargeable',
        'is_parent',
        'intercompany_yard_id',
        'po',
        'selectable_options',
      ])(item),
    }),
  )

  return {
    editable,
    constant,
    derived,
    selectable,
  }
}

export function parseChargesBeforeSubmission(chargesData) {
  const editable = chargesData.editable.map((item) =>
    sanitizeRowBeforeSubmit({
      ...pick([
        'id',
        'billing_item_id',
        'display_name',
        'billing_cycle',
        'is_chargeable',
        'is_parent',
        'intercompany_yard_id',
        'penalty_rate',
        'po',
      ])(item),
    }),
  )

  const derived = chargesData.derived.map((item) =>
    sanitizeRowBeforeSubmit({
      ...pick([
        'id',
        'billing_item_id',
        'display_name',
        'billing_cycle',
        'is_chargeable',
        'is_parent',
        'intercompany_yard_id',
        'po',
        'formula_function',
        'formula_options',
        'eia_region',
        'eia_period',
        'base_fuel_price',
        'contract_cpl_gpl',
        'change_tolerance',
      ])(item),
    }),
  )

  return {
    editable,
    derived,
  }
}

export function getEmptyModalRow({ table, modalHeaders }) {
  if (table === 'tiers') {
    const { uniqueTableName } = modalHeaders[0]

    return uniqueTableName === 'compound_tiers'
      ? COMPOUND_TIER_NEW_ROW
      : SINGLE_TIER_NEW_ROW
  }

  return { ...SERVICES_SELECTABLE_OPTIONS_NEW_ROW, id: `_${shortid()}` }
}

export function updateParentTierInterval({ parentRows, rows, newRows }) {
  const headCode = !isNil(head(rows)) ? head(rows).code : null

  return parentRows.map((parent) => {
    if (parent.tiers.some((tier) => tier.code === headCode)) {
      return { ...parent, tiers: newRows }
    }
    return parent
  })
}
