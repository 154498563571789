import hostlerLeft from '../images/vehicles/hostler_leftview.png'
import hostlerRight from '../images/vehicles/hostler_rightview.png'
import hostlerBack from '../images/vehicles/hostler_backview.png'
import hostlerFront from '../images/vehicles/hostler_frontview.png'

import forkliftLeft from '../images/vehicles/forklift_leftview.png'
import forkliftRight from '../images/vehicles/forklift_rightview.png'
import forkliftBack from '../images/vehicles/forklift_backview.png'
import forkliftFront from '../images/vehicles/forklift_frontview.png'

import craneLeft from '../images/vehicles/crane_leftview.png'
import craneRight from '../images/vehicles/crane_rightview.png'
import craneFront from '../images/vehicles/crane_frontview.png'

import sideloaderLeft from '../images/vehicles/sideloader_leftview.png'
import sideloaderRight from '../images/vehicles/sideloader_rightview.png'

import spreaderEnd from '../images/vehicles/spreader_end.png'
import spreaderSize from '../images/vehicles/spreader_size.png'

import trainLeft from '../images/vehicles/train_leftview.png'
import trainRight from '../images/vehicles/train_rightview.png'

import truckLeft from '../images/vehicles/truck_leftview.png'
import truckRight from '../images/vehicles/truck_rightview.png'
import truckBack from '../images/vehicles/truck_backview.png'
import truckFront from '../images/vehicles/truck_frontview.png'

const hostlerImages = [
  {
    label: 'Front View',
    image: hostlerFront,
  },
  {
    label: 'Left View',
    image: hostlerLeft,
  },
  {
    label: 'Back View',
    image: hostlerBack,
  },
  {
    label: 'Right View',
    image: hostlerRight,
  },
]
const forkliftImages = [
  {
    label: 'Front View',
    image: forkliftFront,
  },
  {
    label: 'Left View',
    image: forkliftLeft,
  },
  {
    label: 'Right View',
    image: forkliftRight,
  },
  {
    label: 'Back View',
    image: forkliftBack,
  },
]
const sideloaderImages = [
  {
    label: 'Left View',
    image: sideloaderLeft,
  },
  {
    label: 'Right View',
    image: sideloaderRight,
  },
]
const craneImages = [
  {
    label: 'Front View',
    image: craneFront,
  },
  {
    label: 'Left View',
    image: craneLeft,
  },
  {
    label: 'Right View',
    image: craneRight,
  },
  {
    label: 'Spreader View 1',
    image: spreaderEnd,
  },
  {
    label: 'Spreader View 2',
    image: spreaderSize,
  },
]
const trainImages = [
  {
    label: 'Left View',
    image: trainLeft,
  },
  {
    label: 'Right View',
    image: trainRight,
  },
]
const truckImages = [
  {
    label: 'Left View',
    image: truckLeft,
  },
  {
    label: 'Right View',
    image: truckRight,
  },
  {
    label: 'Back View',
    image: truckBack,
  },
  {
    label: 'Front View',
    image: truckFront,
  },
]

const allVehicleImages = {
  1: hostlerImages,
  2: forkliftImages,
  3: sideloaderImages,
  4: craneImages,
  5: hostlerImages,
  7: truckImages,
  8: trainImages,
}

export default allVehicleImages
