import React from 'react'
import { isNil } from 'ramda'
import * as S from './styles'
import editIcon from '../../images/icons/icon_edit_big.svg'
import { history, parseDateTime, getUserFullName } from '../../utils'

export function getTableRows({
  data,
  timezone,
  selectedYard,
  returnDate,
  showDeletedTemplates,
}) {
  if (isNil(data)) {
    return []
  }

  const filteredData = showDeletedTemplates
    ? data
    : data.filter((template) => isNil(template.deleted_at))

  return filteredData.map((row) => {
    const {
      id,
      name,
      description,
      start_date: start,
      end_date: end,
      updated_at: modified,
      deleted_at: deleted,
      User: user,
    } = row

    function navigateBack(e) {
      e.stopPropagation()

      return history.push({
        pathname: `/yard/${selectedYard}/billing/date/${returnDate}`,
        query: { template_id: id },
      })
    }

    function handleEditTemplate(e) {
      e.stopPropagation()

      return history.push({
        pathname: `/yard/${selectedYard}/billing/templates/edit`,
        query: { template_id: id, return_date: returnDate },
      })
    }

    function renderButton() {
      return (
        <S.Button onClick={(e) => navigateBack(e)}>Billing Cycles</S.Button>
      )
    }

    function renderActionMenu() {
      return (
        <S.Action onClick={handleEditTemplate}>
          <S.Image src={editIcon} />
        </S.Action>
      )
    }

    const startDate = parseDateTime({ timestamp: start, timezone })

    return {
      columns: {
        0: { value: name },
        1: { value: startDate },
        2: { value: parseDateTime({ timestamp: end, timezone }) },
        3: {
          value: parseDateTime({
            timestamp: modified,
            timezone,
            shouldShowTime: true,
          }),
        },
        4: { value: getUserFullName(user) },
        5: { value: parseDateTime({ timestamp: deleted, timezone }) },
        6: { value: null, render: renderButton() },
        7: { value: null, render: renderActionMenu(id) },
      },
      startDate,
      name,
      highlight: deleted,
      toolTipTitle: 'Note',
      toolTipDescription: description,
      onClick: () =>
        history.push({
          pathname: `/yard/${selectedYard}/billing/templates/details/${id}/services`,
          query: {
            return_date: returnDate,
          },
        }),
      id,
    }
  })
}
