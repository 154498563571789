import { manageVehiclesTypes } from '../../actions/manageVehiclesActions'

const initialState = {
  loading: false,
  value: [],
}

const vehicleDevices = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVehiclesTypes.VEHICLE_DEVICES_LOADED:
      return {
        ...state,
        loading: false,
        value: payload,
      }

    case manageVehiclesTypes.VEHICLE_DEVICES_REQUESTED:
      return {
        loading: true,
        value: [],
      }

    case manageVehiclesTypes.VEHICLE_DEVICES_FAILED:
      return initialState

    default:
      return state
  }
}

export default vehicleDevices
