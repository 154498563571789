import { browserHistory } from 'react-router'
import { dropRight } from 'lodash'

const enhancedMethods = {
  getUpdatedLocation({ name, value }) {
    const location = browserHistory.getCurrentLocation()
    const { pathname } = location
    const pathnameSplit = pathname.split('/')
    const variableIndex = pathnameSplit.indexOf(name)

    if (variableIndex !== -1) {
      pathnameSplit[variableIndex + 1] = value
      const newPathname = pathnameSplit.join('/')

      return { ...location, pathname: newPathname }
    }

    return null
  },

  changePathVariable({ name, value }) {
    const pathnameUpdated = this.getUpdatedLocation({ name, value })

    if (pathnameUpdated) {
      browserHistory.push(pathnameUpdated)
    }
  },

  removeTail({ name, pathname }) {
    const pathnameSplit = pathname.split('/')
    const index = pathnameSplit.indexOf(name)

    if (index !== -1) {
      return dropRight(pathnameSplit, pathnameSplit.length - index - 2).join(
        '/',
      )
    }

    return pathname
  },

  getVariable(name) {
    const { pathname } = browserHistory.getCurrentLocation()
    const pathnameSplit = pathname.split('/')
    const variableIndex = pathnameSplit.indexOf(name)

    if (variableIndex !== -1) {
      return pathnameSplit[variableIndex + 1]
    }

    return undefined
  },
}

const history = {
  ...browserHistory,
  ...enhancedMethods,
}

export default history
