import React from 'react'
import { map, pipe, isNil, reject, addIndex } from 'ramda'
import yardsIcon from '../../../images/tooltip/yards.svg'
import metricsIcon from '../../../images/tooltip/metrics.svg'
import auditsIcon from '../../../images/tooltip/audits.svg'

const auditsTooltipContent = (
  <>
    <p>Audits calculated from the past 7 days.</p>
    <p>
      The colors indicate the percentage of audits containing violations
      compared to the total audits completed. <b>GREEN</b> is less than or equal
      to 10%, <b>YELLOW</b> is 11% - 29%, and <b>RED</b> is more than or equal
      to 30%
    </p>
  </>
)

export default function getAllYardsHeaders({ hasCrudYardWriteAccess }) {
  const headers = [
    {
      toolTipTitle: 'Yard & PC Number',
      toolTipTitleIcon: yardsIcon,
      toolTipDescription: 'Name of yard and its PC number',
    },
    {
      toolTipTitle: 'Audits',
      toolTipTitleIcon: auditsIcon,
      toolTipDescription: auditsTooltipContent,
    },
    {
      toolTipTitle: 'EOM E.H.',
      toolTipTitleIcon: metricsIcon,
      toolTipDescription:
        'End of Month for Engine Hours for current selected month',
    },
    {
      toolTipTitle: 'Missing B. Days',
      toolTipTitleIcon: metricsIcon,
      toolTipDescription: 'Total days in a billing period with missing values',
    },
    {
      toolTipTitle: 'Billing Status',
      toolTipTitleIcon: metricsIcon,
      toolTipDescription:
        'The filter will sort by most or least past due billing cycles.',
    },
    hasCrudYardWriteAccess
      ? {
          toolTipTitle: 'Actions',
          toolTipTitleIcon: metricsIcon,
          toolTipDescription: 'Edit or delete yards',
        }
      : null,
  ]

  return pipe(
    reject(isNil),
    addIndex(map)((header, index) => ({
      ...header,
      key: index,
      text: header.toolTipTitle.toUpperCase(),
      toolTipAction: `Click to sort ${header.toolTipTitle}`,
      actionType: 'sort',
    })),
  )(headers)
}
