import shortid from 'shortid'
import _ from 'lodash'
import api from '../api'
import { createTypes, onlyLatestRequest } from './utils'
import { history, getShortName } from '../utils'
import { showAlert } from './alertMessageActions'
import { showZones } from '../config'

export const trackingTypes = createTypes('tracking', [
  'VIEW_MODE_SET',
  'SELECTION_TYPE_SET',
  'SELECTION_TYPE_RESET',
  'ICON_SELECTED',
  'ICON_SELECTED_RESET',
  'ICON_PINNED',
  'ICON_PINNED_RESET',
  'VEHICLES_LOADED',
  'IDS_SELECTED',
  'HISTORY_PLAY',
  'HISTORY_TIME_SET',
  'SCRUBBING_START',
  'SCRUBBING_END',
  'SLIDER_LOADING',
  'CLEAR_HISTORY',
  'SET_HISTORY_REQUEST_ID',
  'HISTORY_LOADED',
  'SET_BUFFER_HISTORY_REQUEST_ID',
  'BUFFER_HISTORY_LOADED',
  'HISTORY_VIEW_MOMENT',
  'MOBILE_VIEW_SET',
  'ZONES_LOADED',
  'ZOOM_LEVEL_SET',
  'CENTER_MAP',
  'SET_INFOBOX_DEVICE_ID',
  'RESET',
  'HISTORY_RANGE_SET',
  'TOOLTIP_ADD',
  'TOGGLE_NO_COMMS',
  'TOOLTIP_REMOVE',
  'VCR_SUBMISSION_CLEARED',
  'USER_SESSION_METRICS_CLEARED',
  'LAST_ACTIVITY_CLEARED',
  { value: 'VCR_SUBMISSION', isAsync: true },
  { value: 'USER_SESSION_METRICS', isAsync: true },
  { value: 'LAST_ACTIVITY', isAsync: true },
  { value: 'LIVE_FLAGS', isAsync: true },
  { value: 'INFO_BOX_USER', isAsync: true },
  { value: 'DETECTED_MOVES', isAsync: true },
])

export const setViewMode = (value) => ({
  type: trackingTypes.VIEW_MODE_SET,
  payload: value,
})

export const changeSelectionType = (option) => (dispatch) => {
  dispatch({
    type: trackingTypes.SELECTION_TYPE_SET,
    payload: option,
  })
  dispatch({ type: trackingTypes.ICON_SELECTED_RESET })
  dispatch({ type: trackingTypes.ICON_PINNED_RESET })
}

export const setIconSelected = (value) => ({
  type: trackingTypes.ICON_SELECTED,
  payload: value,
})

export const setIconPinned = (value) => ({
  type: trackingTypes.ICON_PINNED,
  payload: value,
})

/* Load live tracking */
export const setTracking = (vehicles) => ({
  type: trackingTypes.VEHICLES_LOADED,
  payload: vehicles,
})

export const loadTracking = (yardId) => (dispatch) => {
  api.positionTracking.getLive(yardId).then((vehicles) => {
    dispatch(setTracking(vehicles))
  })
}

/* Load tracking history */
export const setHistoryPlay = (value) => ({
  type: trackingTypes.HISTORY_PLAY,
  payload: value,
})

export const setCurrentHistoryTime = (currentHistoryTime) => (
  dispatch,
  getState,
) => {
  const { scrubbing } = getState().playback
  const { historyPlay } = getState().tracking
  if (!scrubbing && !historyPlay) {
    history.changePathVariable({
      name: 'time',
      value: currentHistoryTime,
    })
  }

  dispatch({
    type: trackingTypes.HISTORY_TIME_SET,
    payload: currentHistoryTime,
  })
}

export const scrubbingStart = () => ({
  type: trackingTypes.SCRUBBING_START,
})

export const scrubbingEnd = () => ({
  type: trackingTypes.SCRUBBING_END,
})

export const clearHistory = () => ({
  type: trackingTypes.CLEAR_HISTORY,
})

const setHistoryRequestId = (requestId) => ({
  type: trackingTypes.SET_HISTORY_REQUEST_ID,
  payload: requestId,
})

const historyLoaded = (requestId, startTime, endTime, data) => ({
  type: trackingTypes.HISTORY_LOADED,
  payload: {
    requestId,
    startTime,
    endTime,
    data,
  },
})

export const loadSelectedPositionHistory = ({ yardId, startTime, endTime }) => (
  dispatch,
) => {
  dispatch({
    type: trackingTypes.SLIDER_LOADING,
    payload: true,
  })
  const requestId = shortid.generate()
  dispatch(setHistoryRequestId(requestId))
  api.positionHistory
    .getAll({ yardId, startTime, endTime })
    .then((response) => {
      dispatch(historyLoaded(requestId, startTime, endTime, response.data))
      dispatch({
        type: trackingTypes.SLIDER_LOADING,
        payload: false,
      })
    })
    .catch(() => {
      dispatch({
        type: trackingTypes.SLIDER_LOADING,
        payload: false,
      })
    })
}

export const bufferHistory = ({ yardId, startTime, endTime }) => (dispatch) => {
  const requestId = shortid.generate()
  dispatch({
    type: trackingTypes.SET_BUFFER_HISTORY_REQUEST_ID,
    payload: requestId,
  })
  api.positionHistory
    .getAll({ yardId, startTime, endTime })
    .then(({ data }) => {
      dispatch({
        type: trackingTypes.BUFFER_HISTORY_LOADED,
        payload: {
          requestId,
          startTime,
          endTime,
          data,
        },
      })
    })
}

export const getZones = (yardId) => (dispatch) => {
  if (showZones) {
    api.zones.getAll({ yardId }).then((data) => {
      dispatch({
        type: trackingTypes.ZONES_LOADED,
        payload: data,
      })
    })
  }
}

export const setZoomLevel = (value) => ({
  type: trackingTypes.ZOOM_LEVEL_SET,
  payload: value,
})

export const setCenter = (value) => ({
  type: trackingTypes.CENTER_MAP,
  payload: value,
})

export const setInfoBoxDeviceId = (deviceId) => ({
  type: trackingTypes.SET_INFOBOX_DEVICE_ID,
  payload: deviceId,
})

export const loadVcrSubmission = (deviceId, vcrSubmissionId) => (
  dispatch,
  getState,
) => {
  dispatch({ type: trackingTypes.VCR_SUBMISSION_REQUESTED })

  const { infoBoxDeviceId } = getState().tracking

  return api.vcrs
    .getOne(vcrSubmissionId)
    .then((vcr) => {
      dispatch({
        type: trackingTypes.VCR_SUBMISSION_LOADED,
        payload: {
          deviceId,
          data: vcr.submitted_at,
          infoBoxDeviceId,
        },
      })
    })
    .catch((err) => {
      dispatch({
        type: trackingTypes.VCR_SUBMISSION_FAILED,
        payload: err,
      })
    })
}

export const clearVcrSubmission = () => ({
  type: trackingTypes.VCR_SUBMISSION_CLEARED,
})

const getLatestUserSessionMetrics = onlyLatestRequest(api.sessions.getCurrent)

export const loadUserSessionMetrics = (deviceId, userId) => (
  dispatch,
  getState,
) => {
  dispatch({ type: trackingTypes.USER_SESSION_METRICS_REQUESTED })
  const { infoBoxDeviceId } = getState().tracking

  return getLatestUserSessionMetrics(userId)
    .then((data) => {
      dispatch({
        type: trackingTypes.USER_SESSION_METRICS_LOADED,
        payload: {
          deviceId,
          data,
          infoBoxDeviceId,
        },
      })
    })
    .catch((err) => {
      dispatch({
        type: trackingTypes.USER_SESSION_METRICS_FAILED,
        payload: err,
      })
    })
}

export const clearUserSessionMetrics = () => ({
  type: trackingTypes.USER_SESSION_METRICS_CLEARED,
})

const getLatestLastActivity = onlyLatestRequest(api.sessions.getLastActivity)

export const loadLastActivity = (deviceId) => (dispatch, getState) => {
  dispatch({ type: trackingTypes.LAST_ACTIVITY_REQUESTED })

  const { infoBoxDeviceId } = getState().tracking

  return getLatestLastActivity(deviceId)
    .then((data) => {
      dispatch({
        type: trackingTypes.LAST_ACTIVITY_LOADED,
        payload: {
          deviceId,
          data,
          infoBoxDeviceId,
        },
      })
    })
    .catch((err) => {
      dispatch({
        type: trackingTypes.LAST_ACTIVITY_FAILED,
        payload: err,
      })
    })
}

export const clearLastActivity = () => ({
  type: trackingTypes.LAST_ACTIVITY_CLEARED,
})

export const resetTracking = () => (dispatch) => {
  dispatch({ type: trackingTypes.SELECTION_TYPE_RESET })
  dispatch({ type: trackingTypes.ICON_SELECTED_RESET })
  dispatch({ type: trackingTypes.ICON_PINNED_RESET })
}

export const setHistoryRange = ({ min, max }) => ({
  type: trackingTypes.HISTORY_RANGE_SET,
  payload: {
    min,
    max,
  },
})

export const loadLiveFlags = ({ yardId, startTime, endTime }) => (dispatch) => {
  dispatch({ type: trackingTypes.LIVE_FLAGS_REQUESTED })

  return api.liveFlags
    .getAll({
      yardId,
      startTime,
      endTime,
      name: 'idleTime',
    })
    .then((flags) => {
      const mappedFlags = flags.map((flag) => ({
        message: flag.description,
        date: flag.started_at,
        isGood: flag.isGoodFlag,
        title: flag.owner_name,
        ownerType: flag.owner_type,
        ownerId: flag.owner_id,
        hasLoggedUser: flag.owner_id === flag.user_id,
      }))
      dispatch({
        type: trackingTypes.LIVE_FLAGS_LOADED,
        payload: mappedFlags,
      })
    })
    .catch(() => {
      dispatch({ type: trackingTypes.LIVE_FLAGS_FAILED })
    })
}

export const addTooltip = (vehicleId) => ({
  type: trackingTypes.TOOLTIP_ADD,
  payload: { vehicleId },
})

export const removeTooltip = (vehicleId) => ({
  type: trackingTypes.TOOLTIP_REMOVE,
  payload: { vehicleId },
})

export const pingDriver = ({ userId }) => (dispatch) =>
  api.notifications
    .send({ userId })
    .then(() => {
      dispatch(
        showAlert({
          type: 'success',
          message: 'Pinged Driver',
        }),
      )
    })
    .catch((data) => {
      dispatch(
        showAlert({
          type: 'failure',
          message: _.capitalize(data.message),
        }),
      )
    })

const addYardCode = (getState, user) => {
  const yardList = _.get(getState(), 'general.yardList', [])
  const userYard = yardList.find((yard) => yard.id === user.yardId)

  return {
    ...user,
    yardCode: _.get(userYard, 'code'),
  }
}

export const loadOrSetInfoBoxUser = ({ userId, user }) => (
  dispatch,
  getState,
) => {
  if (!_.isNil(user)) {
    dispatch({
      type: trackingTypes.INFO_BOX_USER_LOADED,
      payload: { user: addYardCode(getState, user) },
    })
  } else {
    dispatch({ type: trackingTypes.INFO_BOX_USER_REQUESTED })

    api.users
      .getOne(userId)
      .then((loadedUser) => {
        dispatch({
          type: trackingTypes.INFO_BOX_USER_LOADED,
          payload: {
            user: addYardCode(getState, {
              name: getShortName({
                firstName: loadedUser.first_name,
                lastName: loadedUser.last_name,
              }),
              role: loadedUser.Role.display_name,
              yardId: loadedUser.yard_id,
            }),
          },
        })
      })
      .catch(() => {
        dispatch({ type: trackingTypes.INFO_BOX_USER_FAILED })
      })
  }
}

export const toggleNoComms = () => ({
  type: trackingTypes.TOGGLE_NO_COMMS,
})

const getLatestDetectedMoves = onlyLatestRequest(
  api.detectedMoves.getTurnAroundTime,
)

export const loadDetectedMoves = ({ yard, startDate, endDate }) => (
  dispatch,
) => {
  dispatch({ type: trackingTypes.DETECTED_MOVES_REQUESTED })

  getLatestDetectedMoves({ yard, startDate, endDate })
    .then((payload) => {
      dispatch({
        type: trackingTypes.DETECTED_MOVES_LOADED,
        payload,
      })
    })
    .catch(() => {
      dispatch({ type: trackingTypes.DETECTED_MOVES_FAILED })
    })
}
