import clientApi from './utils/clientApi'
import parseNote from '../parsers/parseNote'

const getAll = ({ yardId, date }, options = {}) =>
  clientApi({
    ...options,
    method: 'get',
    url: `/yards/${yardId}/notes`,
    params: {
      date: date.format('MM/DD/YYYY'),
    },
  }).then(({ data }) =>
    data.map((serverNote) => parseNote(serverNote, serverNote.User)),
  )

const create = ({ yardId, date, message, user }) =>
  clientApi({
    method: 'post',
    url: `/yards/${yardId}/notes`,
    data: {
      date: date.format('MM/DD/YYYY'),
      note: message,
    },
  }).then(({ data }) => parseNote(data, user))

const notes = {
  getAll,
  create,
}

export default notes
