import { manageVehiclesTypes } from '../../actions/manageVehiclesActions'

const initialState = {
  value: [],
  nextOffset: {
    comment: 0,
    vwr: 0,
    vcr: 0,
  },
  total: 0,
  isLoading: false,
}

const eventList = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVehiclesTypes.EVENTS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }

    case manageVehiclesTypes.EVENTS_LOADED:
      return {
        ...state,
        value: payload.list,
        nextOffset: payload.nextOffset,
        total: payload.total,
        isLoading: false,
      }

    case manageVehiclesTypes.EVENTS_LAZY_LOADED:
      return {
        ...state,
        value: state.value.concat(payload.list),
        nextOffset: payload.nextOffset,
        total: payload.total,
        isLoading: false,
      }

    case manageVehiclesTypes.EVENTS_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}

export default eventList
