import React from 'react'
import PropTypes from 'prop-types'
import { path, isNil, prepend, map, pipe, split, trim, includes } from 'ramda'
import { CustomSelectContainer } from './styles'

export const NULL_OPTION = {
  value: '###NULL###',
  label: 'None',
}

function parseOption(option) {
  if (path(['value'])(option) === NULL_OPTION.value) {
    return null
  }

  return option
}

function getDefaultValue({
  options,
  acceptNoSelection,
  valueToRender,
  isMulti,
  valueToRenderList,
}) {
  if (isMulti) {
    return options.filter((option) => includes(option.value, valueToRenderList))
  }

  const nullOption = acceptNoSelection ? NULL_OPTION : null

  if (!isNil(valueToRender)) {
    const defaultValue = options.find(
      (option) => option.value === valueToRender,
    )
    if (acceptNoSelection && isNil(defaultValue)) {
      return NULL_OPTION
    }
    return defaultValue
  }

  return nullOption
}

function CustomSelect({
  acceptNoSelection,
  onChange,
  options,
  valueToRender,
  isMulti,
  ...otherProps
}) {
  const allOptions = acceptNoSelection ? prepend(NULL_OPTION)(options) : options

  const valueToRenderList =
    typeof valueToRender === 'string'
      ? pipe(split(','), map(trim))(valueToRender)
      : valueToRender

  const defaultValue = getDefaultValue({
    options,
    acceptNoSelection,
    valueToRender,
    isMulti,
    valueToRenderList,
  })

  function handleChange(option) {
    const parsedOption = parseOption(option)
    onChange(parsedOption)
  }

  return (
    <CustomSelectContainer
      {...otherProps}
      onChange={handleChange}
      options={allOptions}
      defaultValue={defaultValue}
      isMulti={isMulti}
    />
  )
}

CustomSelect.propTypes = {
  acceptNoSelection: PropTypes.bool,
  valueToRender: PropTypes.any.isRequired,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
}

CustomSelect.defaultProps = {
  acceptNoSelection: false,
  isMulti: false,
}

export default CustomSelect
