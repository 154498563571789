import { trackingTypes } from '../../actions/trackingActions'

const initialState = {
  min: 0,
  max: 0,
}

const historyRange = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.HISTORY_RANGE_SET:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}

export default historyRange
