import { is, pipe, complement } from 'ramda'
import { multiplePathDefaultTo, defaultToIf } from '../utils/ramdaExtensions'
import { createTypes } from './utils'

export const alertMessageTypes = createTypes('alertMessage', ['HIDE', 'SHOW'])

export const hide = () => ({
  type: alertMessageTypes.HIDE,
})

export const showAlert = ({
  type,
  message,
  timeout,
  fullWidthStyle,
  redirectPath,
}) => ({
  type: alertMessageTypes.SHOW,
  payload: {
    type,
    message,
    timeout,
    fullWidthStyle,
    redirectPath,
  },
})

const errorPaths = [
  ['response', 'data', 'error', 'message'],
  ['response', 'data', 'errors', 0, 'message'],
  ['response', 'data'],
  ['error', 'data', 'error', 'message'],
  ['error', 'data', 'errors', 0, 'message'],
  ['error', 'data'],
  ['error', 'data', 'message'],
  ['data', 'message'],
  ['data'],
  ['data', 'errors', 0, 'message'],
]

export const showErrorAlert = ({
  error,
  defaultMessage,
  timeout,
  fullWidthStyle,
  redirectPath,
}) => {
  const message = pipe(
    multiplePathDefaultTo(errorPaths, defaultMessage),
    defaultToIf(complement(is(String)), defaultMessage),
  )(error)

  return {
    type: alertMessageTypes.SHOW,
    payload: {
      type: 'failure',
      message,
      timeout,
      fullWidthStyle,
      redirectPath,
    },
  }
}
