import api from '../api'
import { createTypes, auditsWorkerOptions } from './utils'

export const safetyReportsTypes = createTypes('safetyReports', [
  { value: 'AUDITS', isAsync: true },
  'DATES_CHANGED',
  'SELECTED_USER_SET',
  'SELECTED_USER_RESET',
])

export const resetSelectedUser = () => ({
  type: safetyReportsTypes.SELECTED_USER_RESET,
})

export const loadAudits = ({ workerId, workerType, filterOptions }) => (
  dispatch,
) => {
  dispatch({ type: safetyReportsTypes.AUDITS_REQUESTED })

  if (workerId) {
    api.users.getOne(workerId).then((user) =>
      dispatch({
        type: safetyReportsTypes.SELECTED_USER_SET,
        payload: { user },
      }),
    )
  }

  const filter = {
    workerId,
    workerType,
    ...filterOptions,
  }

  if (workerId) {
    const { apiId } = auditsWorkerOptions[workerType]
    filter[apiId] = workerId
  }

  return api.audits
    .getAll(filter)
    .then((payload) => {
      dispatch({
        type: safetyReportsTypes.AUDITS_LOADED,
        payload,
      })
    })
    .catch(() => {
      dispatch({ type: safetyReportsTypes.AUDITS_FAILED })
    })
}
