import { connect } from 'react-redux'
import { path } from 'ramda'
import NewSidebar from './NewSidebar'

import { getYardTimeZone } from '../../selectors'
import { selectYard } from '../../actions/generalActions'
import { setVCRSortOption } from '../../actions/manageVCRsActions'
import { setMetricsIntervalOption } from '../../actions/manageUsersActions'
import { getZones } from '../../actions/trackingActions'

const mapStateToProps = (state) => ({
  selectedYard: state.general.selectedYard,
  timezone: getYardTimeZone(state),
  yardList: state.general.yardList,
  userRole: path(['Role', 'name'])(state.general.userData),
})

const mapDispatchToProps = {
  selectYard,
  setVCRSortOption,
  setMetricsIntervalOption,
  getZones,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewSidebar)
