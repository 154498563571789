import styled from 'styled-components/macro'
import Select from 'react-select'

export const Container = styled.div`
  width: 100%;
  display: block;
`

export const StyledSelect = styled(Select)`
  .react-select__control {
    border: 1px solid var(--lighter-neutral-92);
    font-family: 'ArticulatCF-Medium';
    color: var(--base-neutral);
    box-shadow: none;
  }

  .react-select__indicator-separator {
    display: none;
  }
`
