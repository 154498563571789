import React from 'react'
import PropTypes from 'prop-types'
import './InputForm.css'

const InputForm = ({ title, content }) => (
  <div className="input-container">
    <div className="inputForm__title">{title}</div>
    <div className="inputForm__input">{content}</div>
  </div>
)

InputForm.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

InputForm.defaultProps = {
  content: null,
}

export default InputForm
