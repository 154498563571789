import styled, { css } from 'styled-components/macro'
import Select from 'react-select'
import { animationLiftUp, setDelay } from '../../css/sharedStyles'
import { sidebarWidth } from '../../config'

const navigationItemsCount = 8

export const Container = styled.div`
  width: ${sidebarWidth}px;
  background-color: var(--darker-blue-20);
  color: var(--lighter-neutral-99);
  display: grid;
  grid-template-rows: 80px calc(${navigationItemsCount} * 7.5vh) 7.5vh 1fr;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 1023px) {
    position: absolute;
    height: 100%;
    z-index: 100;
    transform: translateX(-${sidebarWidth}px);

    ${(props) =>
      props.isOpen &&
      `
        transform: translateX(0); /* Show the sidebar when isOpen is true */
    `}
  }
`

export const ToggleButton = styled.button`
  position: absolute;
  top: 12px;
  right: 15px;
  height: 48px;
  width: 48px;
  background-color: var(--darker-blue-29);
  box-shadow: var(--box-shadow);
  border: none;
  cursor: pointer;
  line-height: 1;
  z-index: 10;
  transition: all 0.3s ease;

  &:focus {
    outline: auto;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

export const Toggle = styled.div`
  position: relative;
  margin: 0 auto;
  width: 30px;
  height: 18px;
`
export const ToggleText = styled.span`
  display: block;
  position: relative;
  top: 4px;
  color: var(--lighter-neutral-99);
  font-size: 15px;
`
export const ToggleSpan = styled.div`
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  opacity: 1;
  background: white;
  transition: all 0.3s ease;

  &:nth-child(1) {
    top: 0;
  }
  &:nth-child(2),
  &:nth-child(3) {
    top: 8px;
  }
  &:nth-child(4) {
    top: 16px;
  }

  ${(props) =>
    props.isOpen &&
    `
        &:nth-child(1){ top: 8px; width: 0; left: 50%; }
        &:nth-child(2){	transform: rotate(45deg); }
        &:nth-child(3){ transform: rotate(-45deg); }
        &:nth-child(4){ top: 8px; width: 0; left: 50%; }
    `}
`

export const Header = styled.div`
  background-color: var(--darker-blue-20);
  display: flex;
  align-items: center;
  padding: 0 10px 0 15px;
`

export const Icon = styled.img`
  margin-right: 20px;
  width: 25px;
  height: 25px;

  ${(props) =>
    props.header &&
    `
    width: 30px;
    height: 30px;
    margin: 0;
  `}
`

export const Label = styled.p`
  pointer-events: none;
  font-size: 16px;
  margin: 1.9vh 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  position: relative;
  background-color: var(--darker-blue-17);

  ::-webkit-scrollbar {
    width: 10px;
    border-radius: none !important;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--lighter-neutral-96);
    border: 3px solid var(--darker-blue-17);
  }
`

export const Logout = styled.button`
  background-color: var(--darker-blue-29);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: auto auto 0;
  width: 85%;
  padding: 0 15%;
  transition: 0.2s all;
  margin-bottom: 20px;

  :hover {
    background-color: var(--base-red);
  }
`

export const Chevron = styled.span`
  cursor: pointer;
`

export const EmptyList = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const EmptyTitle = styled.p`
  font-size: 18px;
  margin: 0;
`

export const EmptyDescription = styled.p`
  opacity: 0.5;
`

export const StyledReactSelect = styled(Select)`
  width: 85%;
  margin-left: 5px;

  .react-select__control {
    background: transparent;
    border: none;
    box-shadow: none;
    height: 48px;
  }

  .react-select__placeholder,
  .react-select__indicator,
  .react-select__input-container,
  .react-select__single-value {
    color: var(--lighter-neutral-99);
    opacity: 0.8;
    cursor: text;
  }

  .react-select__indicator:hover {
    color: var(--lighter-neutral-99);
    cursor: pointer;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu {
    background: var(--darker-blue-17);
    box-shadow: none;
    height: calc(${navigationItemsCount} * 7.5vh);
    top: 54px;
    left: -50px;
    width: 255px;
    z-index: 999;
  }

  .react-select__menu-list {
    max-height: none;
    height: 100%;

    ::-webkit-scrollbar {
      width: 10px;
      border-radius: none !important;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--lighter-neutral-96);
      border: 3px solid var(--darker-blue-17);
    }
  }

  .react-select__option {
    margin: 10px 0;
    padding: 0 20px;
    height: 48px;
    background: var(--darker-blue-17);
    color: var(--lighter-neutral-99);
    display: flex;
    align-items: center;

    &--is-focused {
      background: var(--darker-blue-43);
    }

    ${(props) =>
      props.length &&
      css`
        opacity: 0;
        animation: ${animationLiftUp} 0.2s ease forwards;
        ${setDelay(15)}
      `}
  }
`
