import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './SearchForm.css'

class SearchForm extends Component {
  handleChange = (e) => {
    const { handleSearchQuery } = this.props
    handleSearchQuery(e.target.value)
  }

  render() {
    const { placeholder } = this.props

    return (
      <div className="searchForm flex-row">
        <input
          type="search"
          autoComplete="off"
          onChange={this.handleChange}
          placeholder={placeholder}
        />
        <div className="searchIcon">&#xf002;</div>
      </div>
    )
  }
}

SearchForm.propTypes = {
  handleSearchQuery: PropTypes.func,
  placeholder: PropTypes.string,
}

SearchForm.defaultProps = {
  handleSearchQuery: () => undefined,
  placeholder: '',
}

export default SearchForm
