import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'

import {
  NewToolTip,
  DevicesPanelWorkersTable,
  DevicesPanelFlagsTable,
  DevicesPanelWorkerInfo,
  NewToggle,
} from '../../../components'

import iconBoxMoves from '../../../images/icons/icon_box_moves.svg'
import iconTurnAround from '../../../images/icons/icon_turn_around.svg'
import iconArrow from '../../../images/icons/icon_arrow.svg'
import iconFlags from '../../../images/icons/icon_turtle.svg'
import iconWorkers from '../../../images/icons/icon_workers_list.svg'
import iconWorkerInfo from '../../../images/icons/icon_workers.svg'
import iconOffline from '../../../images/icons/icon_offline_cloud.svg'

import { getShortName, getDetectedMoves } from '../../../utils'
import { detectedMovesPeriod, detectedMovesInterval } from '../../../config'

import { getVehicleStatus, getHistoryUserByVehicle } from '../helpers'

import * as S from './styles'

import 'react-toggle/style.css'
import '../../../css/toggle.css'

const DevicesPanel = ({
  showNoComms,
  loadDetectedMoves,
  loadVcrSubmission,
  loadUserSessionMetrics,
  loadLastActivity,
  detectedMoves,
  selectedYard,
  timezone,
  iconPinned,
  viewMode,
  toggleNoComms,
  trackingData: { value: trackingDataValue },
  deviceSessions: { value: deviceSessionsValue },
  trackingDataForHistoryTime,
  setIconSelected,
  setInfoBoxDeviceId,
  addTooltip,
  removeTooltip,
  selectedTooltips,
  setIconPinned,
  flags,
  vcrSubmissionAt,
  userSessionMetrics,
  lastActivity,
  pingDriver,
  clearVcrSubmission,
  clearUserSessionMetrics,
  clearLastActivity,
  revokeToken,
  vehicleList: { value: vehicleListValue },
  userList: { value: userListValue },
  loadVehicle,
  resetVehicle,
  vehicleData,
  userRole,
}) => {
  const [isDeviceListVisible, setisDeviceListVisible] = useState(true)
  const [activeTab, setActiveTab] = useState('workers')

  const currentTrackingData =
    viewMode === 'live' ? trackingDataValue : trackingDataForHistoryTime

  const { turnAroundTime, boxMoves } = getDetectedMoves({
    detectedMoves,
    isYard: true,
  })
  const flagsLength = _.get(flags, 'value', []).length

  useEffect(() => {
    function refreshDetectedMoves() {
      return loadDetectedMoves({
        yard: selectedYard,
        startDate: moment
          .tz(timezone)
          .subtract(detectedMovesPeriod.value, detectedMovesPeriod.range)
          .toISOString(),
        endDate: moment.tz(timezone).toISOString(),
      })
    }

    refreshDetectedMoves()

    const interval = setInterval(refreshDetectedMoves, detectedMovesInterval)

    return () => clearInterval(interval)
  }, [loadDetectedMoves, selectedYard, timezone])

  useEffect(() => {
    if (viewMode !== 'live') {
      setActiveTab('workers')
    }
  }, [viewMode])

  useEffect(() => {
    setInfoBoxDeviceId(null)
    if (iconPinned !== null) {
      setActiveTab('infobox')
    }
  }, [iconPinned, setInfoBoxDeviceId])

  useEffect(() => {
    const vehicleId = Number(iconPinned)

    if (vehicleId !== 0) {
      loadVehicle(vehicleId)
    } else {
      resetVehicle()
    }
  }, [loadVehicle, resetVehicle, iconPinned])

  function getDeviceData() {
    return viewMode === 'live'
      ? currentTrackingData
          .map(({ Vehicle, User }) => {
            const vehicleId = _.get(Vehicle, 'id')
            const vehicleName = _.get(Vehicle, 'vehicle_name', '')

            const userId = _.get(User, 'id')
            const firstName = _.get(User, 'first_name', '')
            const lastName = _.get(User, 'last_name', '')

            const session = deviceSessionsValue.find(
              (sessionValue) => sessionValue.vehicle_id === vehicleId,
            )

            const zoneType = _.get(session, 'Zone.type')
            const userShortName = userId
              ? getShortName({ firstName, lastName })
              : ''
            const deviceStatus =
              getVehicleStatus({ session, userId, zoneType }) || null

            return {
              vehicleId,
              vehicleName,
              userId,
              userShortName,
              deviceStatus,
            }
          })
          .filter((formattedData) => !_.isNil(formattedData.vehicleId))
      : currentTrackingData.map((item) => {
          const user = userListValue.find(
            (userItem) => userItem.id === item.user_id,
          )

          return {
            vehicleId: item.vehicle_id,
            vehicleName: _.chain(vehicleListValue)
              .find((vehicle) => vehicle.id === item.vehicle_id)
              .get('vehicle_name', '-')
              .value(),
            iDrive: _.chain(vehicleListValue)
              .find((vehicle) => vehicle.id === item.vehicle_id)
              .get('idrive_code', '-')
              .value(),
            userId: item.user_id,
            userShortName: _.isNil(user)
              ? '-'
              : getShortName({
                  firstName: _.get(user, 'first_name'),
                  lastName: _.get(user, 'last_name'),
                }),
            deviceStatus: _.isNil(item.user_id) ? 'missing-driver' : 'online',
          }
        })
  }

  function getOfflineVehiclesPercent() {
    const deviceData = getDeviceData()
    const offlineVehicles = deviceData.filter(
      (vehicle) => vehicle.deviceStatus === 'offline',
    )

    const deviceDataSize = _.size(deviceData)
    const offlineVehiclesSize = _.size(offlineVehicles)

    return deviceDataSize > 0
      ? Math.round((100 * offlineVehiclesSize) / deviceDataSize)
      : 0
  }

  function setIconPinnedToggle(vehicleId) {
    if (!_.isNil(vehicleId)) {
      setIconPinned(iconPinned !== vehicleId ? vehicleId : null)
    }
  }

  function handleDeviceClick(event, vehicleIdClicked) {
    event.preventDefault()

    if (_.isNil(vehicleIdClicked)) {
      return
    }

    if (event.ctrlKey || event.metaKey) {
      const isSelected = selectedTooltips.some(
        (vehicleId) => vehicleId === vehicleIdClicked,
      )

      if (isSelected) {
        removeTooltip(vehicleIdClicked)
      } else {
        addTooltip(vehicleIdClicked)
      }
    } else {
      setIconPinnedToggle(vehicleIdClicked)
      setActiveTab('infobox')
    }
  }

  function tooltipFooterDescription() {
    return (
      <i>
        *This metric is <b>not</b> yet official
      </i>
    )
  }

  function handleDeviceHover(vehicleId) {
    setIconSelected(vehicleId)
  }
  function handleToggleDeviceList() {
    setisDeviceListVisible(!isDeviceListVisible)
  }

  function handleActiveTab(tabName) {
    if (tabName === 'workers') {
      setIconPinned(null)
      setIconSelected(null)
      setInfoBoxDeviceId(null)

      clearVcrSubmission()
      clearUserSessionMetrics()
      clearLastActivity()
    }

    setActiveTab(tabName)
  }

  const devicesPanelWorkersTableProps = {
    deviceData: getDeviceData(),
    showOfflineVehicles: showNoComms,
    selectedVehicle: iconPinned,
    detectedMoves,
    viewMode,
    handleDeviceClick,
    handleDeviceHover,
  }

  const workersTabContent = () => (
    <>
      {viewMode === 'live' && (
        <S.YardMovesAverage>
          <NewToolTip
            title="Box moves"
            description="From the most recent 60mins, the average amount of box moves completed within 1hr by an individual, detected using a tablet."
            footer={tooltipFooterDescription()}
            titleIcon={iconBoxMoves}
          >
            <div>
              <S.Text bold>Box moves</S.Text>
              <S.Badge boxMoves>
                {detectedMoves.loading ? '-' : boxMoves}
              </S.Badge>
            </div>
          </NewToolTip>

          <NewToolTip
            title="Turn around time"
            description="From the most recent 60mins, the average time within 1hr to complete 1 box move, detected using a tablet."
            footer={tooltipFooterDescription()}
            titleIcon={iconTurnAround}
          >
            <div>
              <S.Text bold>Turn around time</S.Text>
              <S.Badge turnAround>
                {detectedMoves.loading ? '-' : turnAroundTime}
              </S.Badge>
            </div>
          </NewToolTip>
        </S.YardMovesAverage>
      )}
      <DevicesPanelWorkersTable {...devicesPanelWorkersTableProps} />
    </>
  )

  const workerInfo = iconPinned
    ? currentTrackingData.find(
        (trackingData) => iconPinned === trackingData.vehicle_id,
      )
    : {}

  const hasWorkerInfo = !_.isNil(workerInfo)

  if (hasWorkerInfo) {
    workerInfo.iDrive = _.get(vehicleData, 'vehicle.idrive_code')
  }

  if (viewMode === 'history' && !_.isNil(iconPinned)) {
    const user = getHistoryUserByVehicle({
      currentTrackingData,
      iconPinned,
      userListValue,
    })

    if (hasWorkerInfo) {
      workerInfo.User = user
    }
  }

  const devicesPanelWorkerInfoProps = {
    workerInfo,
    vcrSubmissionAt,
    userSessionMetrics,
    timezone,
    detectedMoves,
    lastActivity,
    pingDriver,
    handleActiveTab,
    setInfoBoxDeviceId,
    loadVcrSubmission,
    loadUserSessionMetrics,
    loadLastActivity,
    revokeToken,
    viewMode,
    userRole,
  }

  function renderTabContent() {
    return {
      workers: workersTabContent(),
      flags: <DevicesPanelFlagsTable flags={flags} timezone={timezone} />,
      infobox: <DevicesPanelWorkerInfo {...devicesPanelWorkerInfoProps} />,
    }[activeTab]
  }

  const tabs = [
    {
      name: 'workers',
      icon: iconWorkers,
      isDisabled: false,
      displayName: 'Workers',
    },
    {
      name: 'infobox',
      icon: iconWorkerInfo,
      isDisabled: false,
      displayName: 'Worker Info',
    },
    {
      name: 'flags',
      icon: iconFlags,
      isDisabled: viewMode !== 'live',
      displayName: 'Flags',
    },
  ]

  return (
    <>
      <S.TabsWrapper>
        {tabs.map(
          (tab) =>
            !tab.isDisabled && (
              <S.Tab
                key={tab.name}
                onClick={() => handleActiveTab(tab.name)}
                active={activeTab === tab.name}
                disabled={tab.isDisabled}
              >
                <S.Icon src={tab.icon} tab /> {tab.displayName}
                {tab.name === 'flags' && flagsLength > 0 && (
                  <S.StyledCountUp end={flagsLength || 0} />
                )}
              </S.Tab>
            ),
        )}
      </S.TabsWrapper>

      <S.Content dynamicHeight={isDeviceListVisible}>
        {isDeviceListVisible && renderTabContent()}

        <S.Footer>
          {viewMode === 'live' && (
            <NewToggle
              isChecked={showNoComms}
              onChange={toggleNoComms}
              icon={iconOffline}
              text={`Offline Vehicles ${getOfflineVehiclesPercent()}%`}
              lightText
            />
          )}

          <S.ToggleListButton onClick={handleToggleDeviceList}>
            <S.Text bold>{isDeviceListVisible ? 'Hide' : 'Show'}</S.Text>
            <S.Icon src={iconArrow} flipIcon={!isDeviceListVisible} />
          </S.ToggleListButton>
        </S.Footer>
      </S.Content>
    </>
  )
}

const historyDataPropType = PropTypes.shape({
  user_id: PropTypes.number,
  vehicle_id: PropTypes.number,
  status: PropTypes.string,
})

DevicesPanel.propTypes = {
  iconPinned: PropTypes.number,
  deviceSessions: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  setIconSelected: PropTypes.func.isRequired,
  setIconPinned: PropTypes.func.isRequired,
  trackingData: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(historyDataPropType).isRequired,
  }).isRequired,
  trackingDataForHistoryTime: PropTypes.arrayOf(historyDataPropType).isRequired,
  viewMode: PropTypes.string.isRequired,
  addTooltip: PropTypes.func.isRequired,
  removeTooltip: PropTypes.func.isRequired,
  selectedTooltips: PropTypes.arrayOf(PropTypes.number).isRequired,
  toggleNoComms: PropTypes.func.isRequired,
  showNoComms: PropTypes.bool.isRequired,
  loadDetectedMoves: PropTypes.func.isRequired,
  detectedMoves: PropTypes.shape({
    loading: PropTypes.bool,
    metrics: PropTypes.shape({
      yardTurnAroundTime: PropTypes.shape({
        turnAroundTimeAverage: PropTypes.number,
        movesCount: PropTypes.number,
      }),
      turnAroundTimeByUser: PropTypes.object,
    }),
  }).isRequired,
  selectedYard: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  flags: PropTypes.shape({
    loading: PropTypes.bool,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string,
        date: PropTypes.string,
        title: PropTypes.string,
        isGood: PropTypes.bool,
        ownerType: PropTypes.string,
        ownerId: PropTypes.number,
        hasLoggedUser: PropTypes.bool,
      }),
    ),
  }).isRequired,
  vcrSubmissionAt: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.string,
  }),
  userSessionMetrics: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.object,
  }),
  lastActivity: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.object,
  }),
  revokeToken: PropTypes.func.isRequired,
  pingDriver: PropTypes.func.isRequired,
  clearVcrSubmission: PropTypes.func.isRequired,
  clearUserSessionMetrics: PropTypes.func.isRequired,
  clearLastActivity: PropTypes.func.isRequired,
  loadVcrSubmission: PropTypes.func.isRequired,
  loadUserSessionMetrics: PropTypes.func.isRequired,
  loadLastActivity: PropTypes.func.isRequired,
  setInfoBoxDeviceId: PropTypes.func.isRequired,
  userList: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  vehicleList: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  loadVehicle: PropTypes.func.isRequired,
  resetVehicle: PropTypes.func.isRequired,
  vehicleData: PropTypes.shape({
    idrive_code: PropTypes.number,
  }).isRequired,
  userRole: PropTypes.string.isRequired,
}

DevicesPanel.defaultProps = {
  iconPinned: undefined,
  vcrSubmissionAt: {},
  userSessionMetrics: {},
  lastActivity: {},
}

export default DevicesPanel
