import React, { Component } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import './SpeedButton.css'

class SpeedButton extends Component {
  handleStepClick = (e) => {
    const { setSpeed } = this.props
    const step = e.target.value
    setSpeed(step)
  }

  render() {
    const { speedValue, disabled, speedSteps } = this.props

    return (
      <div className="container flex-row">
        <div className="buttonContainer flex-row">
          {speedSteps.map((value) => {
            const className = cx('speedButton', {
              active: speedValue === value,
            })
            return (
              <button
                key={value}
                type="button"
                className={className}
                onClick={this.handleStepClick}
                value={value}
                disabled={disabled}
              >
                {value}x
              </button>
            )
          })}
        </div>
      </div>
    )
  }
}

SpeedButton.propTypes = {
  setSpeed: PropTypes.func,
  speedValue: PropTypes.string,
  disabled: PropTypes.bool,
  speedSteps: PropTypes.arrayOf(PropTypes.string),
}

SpeedButton.defaultProps = {
  setSpeed: () => undefined,
  speedValue: '',
  disabled: false,
  speedSteps: [],
}

export default SpeedButton
