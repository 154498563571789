import queryString from 'query-string'
import { toolkitApi } from '.'

const allYardsApi = toolkitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllYardsMetrics: builder.query({
      query: ({ date }) => ({
        url: 'metrics/yards/list',
        params: {
          date,
          range_type: 'day',
        },
      }),
    }),
    getPeople: builder.query({
      query: ({ offset, limit, ...rest }) => ({
        url: 'users/pagination',
        params: queryString.stringify({
          offset,
          limit,
          ...rest,
        }),
      }),
    }),
    getYard: builder.query({
      query: ({ yardId }) => ({
        url: `yards/${yardId}`,
      }),
    }),
    addYard: builder.mutation({
      query: ({ body }) => ({
        url: 'yards',
        method: 'POST',
        body,
      }),
    }),
    updateYard: builder.mutation({
      query: ({ body, yardId }) => ({
        url: `yards/${yardId}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteYard: builder.mutation({
      query: ({ yardId }) => ({
        url: `yards/${yardId}`,
        method: 'DELETE',
      }),
    }),
    getTimezones: builder.query({
      query: () => ({
        url: 'yards/timezones',
      }),
    }),
  }),
})

export const {
  useGetAllYardsMetricsQuery,
  useGetPeopleQuery,
  useGetYardQuery,
  useAddYardMutation,
  useUpdateYardMutation,
  useDeleteYardMutation,
  useGetTimezonesQuery,
} = allYardsApi
