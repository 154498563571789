import _ from 'lodash'
import moment from 'moment-timezone'

function getTabs({ billingWeeks, billingMode, viewMode, timezone }) {
  const isDisabled = (startDate) =>
    billingMode !== viewMode ||
    moment.tz(startDate, timezone).isAfter(moment.tz(timezone), 'day')

  return _.get(billingWeeks, 'value.weeks', []).map((week, index) => ({
    key: index,
    text: `Week ${index + 1}`,
    value: index,
    notification: week.count > 0,
    disabled: isDisabled(week.startDate),
    startDate: week.startDate,
    endDate: week.endDate,
  }))
}

export default getTabs
