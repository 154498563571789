import _ from 'lodash'
import { yardSummaryTypes } from '../../actions/yardSummaryActions'

const initialState = {
  peopleWorked: 0,
  total: 0,
}

const yardShifts = (state = initialState, { type, payload }) => {
  switch (type) {
    case yardSummaryTypes.YARD_SHIFTS_SUMMARY_LOADED: {
      const last = _.last(payload)
      return {
        peopleWorked: _.get(last, 'count', 0),
        total: _.get(last, 'time', 0),
      }
    }

    case yardSummaryTypes.YARD_SHIFTS_SUMMARY_REQUESTED:
    case yardSummaryTypes.YARD_SHIFTS_SUMMARY_FAILED:
      return initialState
    default:
      return state
  }
}

export default yardShifts
