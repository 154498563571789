import { connect } from 'react-redux'
import SearchAudit from './SearchAudit'
import { getYardTimeZone } from '../../selectors'
import { loadWorkerList } from '../../actions/searchAuditActions'
import { parseIntOrUndefined } from '../../utils'

import './SearchAudit.css'

const mapStateToProps = (state, ownProps) => ({
  yardId: Number(ownProps.params.yardId),
  timezone: getYardTimeZone(state),
  workerList: state.safetyReports.workerList,
  createdAtStart: parseIntOrUndefined(ownProps.location.query.created_at_start),
  createdAtEnd: parseIntOrUndefined(ownProps.location.query.created_at_end),
})

const mapDispatchToProps = {
  loadWorkerList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchAudit)
