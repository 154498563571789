import React, { useState, useEffect } from 'react'
import {
  gt,
  lt,
  map,
  any,
  omit,
  pipe,
  prop,
  take,
  isNil,
  chain,
  pluck,
  unnest,
  values,
  sortBy,
  update,
  groupBy,
  isEmpty,
  addIndex,
  includes,
} from 'ramda'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import {
  VEHICLE_STATUS_WRITE_ROLES,
  ENGINE_HOURS_ROLES,
} from '../../permissions/config'
import {
  NewContainer,
  NewHeader,
  NewTabs,
  NewModal,
  NewToolTip,
} from '../../components'

import EngineHours from './containers/EngineHours'
import VehiclesList from './containers/VehiclesList'

import { getTimestamp, getEditableMonth, getTitleAction } from './helpers'
import { history } from '../../utils'
import {
  pathDefaultTo,
  consecutiveDifference,
} from '../../utils/ramdaExtensions'
import {
  engineHoursEditablePeriod,
  engineHoursMonthlyLimit,
  CAN_CHANGE_ENGINE_HOURS_ANY_TIME_ROLES,
} from '../../config'

import * as S from './styles'

import tooltipRefreshIcon from '../../images/tooltip/refresh.svg'
import maintenanceIcon from '../../images/tooltip/maintenance.svg'

const colorByEditMode = {
  view: '',
  value: '--base-blue',
  comment: '--base-green',
}

const vehicleListFilterOptions = [
  {
    key: 0,
    text: 'All',
    value: 'all',
  },
  {
    key: 1,
    text: 'Out of Service',
    value: 'inactive',
  },
  {
    key: 2,
    text: 'Repairs Needed',
    value: 'under_repair',
  },
  {
    key: 3,
    text: 'Operational',
    value: 'operational',
  },
]

const DATE_FORMAT = 'YYYY-MM-DD'

const ManageVehicles = ({
  engineHours,
  loadEngineHours,
  upsertEngineHours,
  timezone,
  selectedYard,
  loadMissingEngineHours,
  query,
  userRole,
  ...vehiclesListProps
}) => {
  const [editMode, setEditMode] = useState('view')
  const [vehiclesFilter, setVehiclesFilter] = useState('all')
  const [search, setSearch] = useState()
  const [updatedEngineHours, setUpdatedEngineHours] = useState([])
  const [showCancelEditModal, setShowCancelEditModal] = useState(false)
  const [showMissingValuesModal, setShowMissingValuesModal] = useState(false)
  const [showWarningValuesModal, setShowWarningValuesModal] = useState(false)
  const [autocompletedEngineHours, setAutocompletedEngineHours] = useState(null)

  const DEFAULT_START_DATE = moment.tz(timezone).startOf('year').format('yyyy')

  const viewMode = pathDefaultTo(['view_mode'], 'vehicles-list')(query)
  const startDate = pathDefaultTo(['date'], DEFAULT_START_DATE)(query)
  const editableMonth = getEditableMonth({ timezone })
  const editableColumn = editableMonth.format('M') - 1

  const canChangeAnyTime = includes(
    userRole,
    CAN_CHANGE_ENGINE_HOURS_ANY_TIME_ROLES,
  )

  const hasStatusWriteAccess = includes(userRole, VEHICLE_STATUS_WRITE_ROLES)
  const hasEngineHoursWriteAccess = includes(userRole, ENGINE_HOURS_ROLES)

  const rows = canChangeAnyTime
    ? chain(({ vehicle_id: vehicleId, engineHoursValues }) =>
        pipe(
          take(editableColumn + 1),
          addIndex(map)(({ value }, index) => ({
            value: isEmpty(value) || isNil(value) ? null : value,
            vehicle_id: vehicleId,
            date: moment
              .tz(editableMonth, timezone)
              .month(index)
              .format(DATE_FORMAT),
            index,
          })),
        )(engineHoursValues),
      )(updatedEngineHours)
    : updatedEngineHours.map((row) => ({
        value: row.engineHoursValues[editableColumn].value,
        vehicle_id: row.vehicle_id,
      }))

  const isAutocompleteDisabled = pipe(
    pathDefaultTo(['value', 'rows'], []),
    any((row) => !isNil(row.values[editableColumn])),
  )(engineHours)

  useEffect(() => {
    setEditMode('view')
  }, [viewMode, selectedYard])

  function handleLoadVehicleDevices() {
    const { loadVehicles, loadVehicleDevices } = vehiclesListProps
    loadVehicles(selectedYard)
    loadVehicleDevices(selectedYard)
  }

  function handleSaveDraft() {
    return upsertEngineHours({
      date: editableMonth.format(DATE_FORMAT),
      rows: map(omit(['index']))(rows),
      updateEditMode: setEditMode,
    })
  }

  function handleSaveDraftSkipWarning() {
    return handleSaveDraft()
  }

  function handleSaveDraftWithWarning() {
    if (canChangeAnyTime) {
      const matrix = pipe(
        groupBy(prop('vehicle_id')),
        map(pipe(sortBy(prop('index')), pluck('value'))),
        values,
      )(rows)
      const list = unnest(matrix)

      const hasMissingValues = any(isNil)(list)
      if (hasMissingValues) {
        return setShowMissingValuesModal(true)
      }

      const differencesList = pipe(map(consecutiveDifference), unnest)(matrix)
      const hasValuesSmallerThanPrevious = any(gt(0))(differencesList)
      if (hasValuesSmallerThanPrevious) {
        return setShowWarningValuesModal(true)
      }
      const hasBigDifferences = any(lt(engineHoursMonthlyLimit))(
        differencesList,
      )

      if (hasBigDifferences) {
        return setShowWarningValuesModal(true)
      }
    } else {
      if (isEmpty(rows) || rows.some((item) => !item.value)) {
        return setShowMissingValuesModal(true)
      }

      const valuesToCompare = updatedEngineHours.map(
        (row) =>
          row.engineHoursValues
            .filter((item) => !isNil(item.value))
            .reverse()[1],
      )

      const hasValuesSmallerThanPrevious = rows.some(
        (row, index) =>
          row.value < pathDefaultTo([index, 'value'], 0)(valuesToCompare),
      )

      const hasBigDifferences = rows
        .map((row, index) =>
          Math.abs(
            row.value - pathDefaultTo([index, 'value'], 0)(valuesToCompare),
          ),
        )
        .some((item) => item > engineHoursMonthlyLimit)

      if (hasValuesSmallerThanPrevious || hasBigDifferences) {
        return setShowWarningValuesModal(true)
      }
    }

    return handleSaveDraft()
  }

  function handleCancelEditModal() {
    setShowCancelEditModal(false)
    setEditMode('view')
    setAutocompletedEngineHours(null)
  }

  function handleAutocomplete() {
    setVehiclesFilter('all')
    setAutocompletedEngineHours({
      ...engineHours,
      value: {
        ...engineHours.value,
        rows: map((row) => {
          const rowValues = pathDefaultTo(['values'], [])(row)
          const vcrEngineHours = pathDefaultTo(
            ['vcr_submission', 'engine_hours'],
            0,
          )(row)

          return {
            ...row,
            values: update(editableColumn, { value: vcrEngineHours })(
              rowValues,
            ),
          }
        })(engineHours.value.rows),
      },
    })
  }

  const handleSearch = {
    handleSearchQuery: setSearch,
    placeholder: 'Search vehicle',
  }

  const titleAction = getTitleAction({
    viewMode,
    timezone,
    setEditMode,
    canChangeAnyTime,
    handleLoadVehicleDevices,
    hasEngineHoursWriteAccess,
  })

  const viewModeOptions = [
    {
      key: 0,
      text: 'Vehicle List',
      value: 'vehicles-list',
    },
    {
      key: 1,
      text: 'Engine Hours',
      value: 'engine-hours',
      notification: pathDefaultTo(['hasMissingValues'], false)(engineHours),
      tooltip: {
        titleIcon: tooltipRefreshIcon,
        title: 'EOM Engine Hours',
        action: 'Click to view',
        description: `All engine hours for a month must be filled in by a manager by the ${engineHoursEditablePeriod.end}th day of the following month. Ex. hours for June must be filled by July ${engineHoursEditablePeriod.end}th.`,
      },
    },
  ]

  const viewModeTabs = {
    items: viewModeOptions,
    activeItem: viewMode,
    onChange: (value) =>
      history.push({
        pathname: `/yard/${selectedYard}/vehicles`,
        query: { view_mode: value },
      }),
  }

  const vehicleListFilterTabs = {
    items: vehicleListFilterOptions,
    activeItem: vehiclesFilter,
    onChange: (value) => setVehiclesFilter(value),
    tooltip: {
      titleIcon: maintenanceIcon,
      title: 'Sorting by maintenance status',
      action: 'Click on tabs to sort the vehicles',
      actionType: 'sort',
    },
  }

  const cancelEditModal = {
    visible: showCancelEditModal,
    onConfirm: handleSaveDraftWithWarning,
    onClose: () => setShowCancelEditModal(false),
    onCancel: handleCancelEditModal,
    title: 'Do you want to save?',
    message:
      'You are attempting to cancel without saving your changes. Would you like to save them?',
    confirmText: 'Save changes',
    cancelText: 'Cancel changes',
  }

  const missingValuesModal = {
    visible: showMissingValuesModal,
    onConfirm: () => setShowMissingValuesModal(false),
    onClose: () => setShowMissingValuesModal(false),
    title: 'Please enter ALL hours.',
    message: 'You have missing values to fill in all of your engine hours.',
    type: 'warning',
    confirmText: 'Keep editing',
  }

  const warningValuesModal = {
    visible: showWarningValuesModal,
    onConfirm: handleSaveDraftSkipWarning,
    onClose: () => setShowWarningValuesModal(false),
    onCancel: () => setShowWarningValuesModal(false),
    title: 'Are you sure to proceed?',
    message: 'You have incorrect values in some engine hours.',
    type: 'warning',
    cancelText: 'Review values',
    confirmText: 'Submit',
  }

  const headerProps = {
    title: 'Vehicles',
    returnText: editMode !== 'view' ? 'Cancel edit' : '',
    returnAction: () => setShowCancelEditModal(true),
    timestamp:
      viewMode === 'engine-hours'
        ? getTimestamp({ engineHours, timezone })
        : {},
    search: handleSearch,
    titleAction,
  }

  if (viewMode === 'engine-hours') {
    const dateProps = {
      timezone,
      startDate: moment.tz(startDate, timezone),
      yearPicker: true,
      onChange: (date) =>
        history.push({
          pathname: `/yard/${selectedYard}/vehicles`,
          query: {
            view_mode: 'engine-hours',
            date: date.startDate.format('yyyy'),
          },
        }),
    }

    headerProps.date = dateProps
  }

  return (
    <>
      <NewHeader {...headerProps} />

      <NewContainer className="vehicles">
        <S.SubMenu>
          <NewTabs {...viewModeTabs} />

          <S.FlexContainer>
            <NewTabs {...vehicleListFilterTabs} />
            {editMode !== 'view' && (
              <>
                <NewToolTip
                  title="Last VCR autocomplete"
                  action="Click to apply"
                  description="Autocomplete cells for the active month using the last VCR values."
                >
                  <S.Button
                    type="button"
                    onClick={handleAutocomplete}
                    disabled={
                      isAutocompleteDisabled || !isNil(autocompletedEngineHours)
                    }
                    secondary
                  >
                    Autocomplete
                  </S.Button>
                </NewToolTip>
                <S.Button
                  type="button"
                  onClick={handleSaveDraftWithWarning}
                  editColor={colorByEditMode[editMode]}
                  editState
                >
                  Save
                </S.Button>
              </>
            )}
          </S.FlexContainer>
        </S.SubMenu>

        {viewMode === 'vehicles-list' && (
          <VehiclesList
            search={search}
            timezone={timezone}
            vehicleSort={vehiclesFilter}
            selectedYard={selectedYard}
            hasStatusWriteAccess={hasStatusWriteAccess}
            {...vehiclesListProps}
          />
        )}

        {viewMode === 'engine-hours' && (
          <EngineHours
            search={search}
            data={
              isNil(autocompletedEngineHours)
                ? engineHours
                : autocompletedEngineHours
            }
            timezone={timezone}
            yard={selectedYard}
            viewMode={viewMode}
            editMode={editMode}
            updateEngineHours={setUpdatedEngineHours}
            loadEngineHours={loadEngineHours}
            loadMissingEngineHours={loadMissingEngineHours}
            vehicleSort={vehiclesFilter}
            handleAutocomplete={handleAutocomplete}
            canChangeAnyTime={canChangeAnyTime}
            date={startDate}
          />
        )}

        <NewModal {...cancelEditModal} />
        <NewModal {...missingValuesModal} />
        <NewModal {...warningValuesModal} />
      </NewContainer>
    </>
  )
}

ManageVehicles.propTypes = {
  engineHours: PropTypes.shape({
    isLoading: PropTypes.bool,
    value: PropTypes.shape({
      last_updated: PropTypes.shape({
        user: PropTypes.shape({
          id: PropTypes.number,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
        }),
        updated_at: PropTypes.string,
      }),
      months: PropTypes.arrayOf(PropTypes.string),
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          values: PropTypes.arrayOf(
            PropTypes.shape({
              value: PropTypes.number,
              comment: PropTypes.string,
            }),
          ),
          vcr_submission: PropTypes.shape({
            submitted_at: PropTypes.string,
            engine_hours: PropTypes.number,
          }),
          vehicle: PropTypes.shape({
            id: PropTypes.number,
            vehicle_name: PropTypes.string,
          }),
        }),
      ),
    }),
    hasMissingValues: PropTypes.bool,
  }).isRequired,
  loadEngineHours: PropTypes.func.isRequired,
  upsertEngineHours: PropTypes.func.isRequired,
  loadMissingEngineHours: PropTypes.func.isRequired,
  selectedYard: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  timezone: PropTypes.string.isRequired,
  query: PropTypes.shape({
    view_mode: PropTypes.string,
  }).isRequired,
  userRole: PropTypes.string.isRequired,
}

export default ManageVehicles
