import { connect } from 'react-redux'
import Vehicle from './Vehicle'

import {
  loadVehicle,
  editVehicleStatus,
  postVehicleComment,
  loadVcrList,
  resetVehicle,
} from '../../actions/manageVehiclesActions'

import { getYardTimeZone } from '../../selectors'
import { parseIntOrUndefined } from '../../utils'

const mapStateToProps = (state) => {
  const timezone = getYardTimeZone(state)

  return {
    userData: state.general.userData,
    vehicleData: state.manageVehicles.vehicleData,
    postVehicleCommentState: state.manageVehicles.postVehicleCommentState,
    yardId: parseIntOrUndefined(state.general.selectedYard),
    deviceData: state.deviceStatus.deviceData,
    vcrList: state.manageVehicles.vcrList,
    timezone,
  }
}

const mapDispatchToProps = {
  loadVehicle,
  editVehicleStatus,
  postVehicleComment,
  loadVcrList,
  resetVehicle,
}

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle)
