import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const billingTemplates = createSlice({
  name: 'billingTemplateDetailsSlice',
  initialState,
  reducers: {
    setUnsavedChanges: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetSliceData: () => initialState,
  },
})

export const { setUnsavedChanges, resetSliceData } = billingTemplates.actions
export default billingTemplates.reducer
