import api from '../api'
import { createTypes } from './utils'
import parseUserFromServer from '../parsers/parseUserFromServer'

export const searchVCRTypes = createTypes('searchVCR', [
  { value: 'CREATOR_LIST', isAsync: true },
  { value: 'VEHICLE_LIST', isAsync: true },
])

export const loadCreatorList = ({ yardId }) => (dispatch) => {
  dispatch({ type: searchVCRTypes.CREATOR_LIST_REQUESTED })

  return api.users
    .getAll({
      yardId,
    })
    .then((data) => {
      const creatorList = data.users.map(parseUserFromServer)
      dispatch({
        type: searchVCRTypes.CREATOR_LIST_LOADED,
        payload: { creatorList },
      })
    })
    .catch(() => {
      dispatch({ type: searchVCRTypes.CREATOR_LIST_FAILED })
    })
}

export const loadVehicleList = ({ yardId }) => (dispatch) => {
  dispatch({ type: searchVCRTypes.VEHICLE_LIST_REQUESTED })

  return api.vehicles
    .getAll({
      yardId,
    })
    .then((data) => {
      const vehicleList = data.map((vehicle) => ({
        ...vehicle,
        name: vehicle.vehicle_name,
      }))

      dispatch({
        type: searchVCRTypes.VEHICLE_LIST_LOADED,
        payload: { vehicleList },
      })
    })
    .catch((error) => {
      dispatch({
        type: searchVCRTypes.VEHICLE_LIST_FAILED,
        payload: error,
      })
    })
}
