import React from 'react'
import PropTypes from 'prop-types'

const Panel = ({ icon, title, children }) => (
  <div className="panel">
    <div className="panel-header">
      {icon && (
        <div className="panel-icon">
          <img src={icon} alt="icon" />
        </div>
      )}
      {title && <div className="panel-title">{title}</div>}
    </div>
    <hr />
    <div className="panel-content">{children}</div>
  </div>
)

Panel.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
}

Panel.defaultProps = {
  icon: null,
  title: null,
  children: null,
}

export default Panel
