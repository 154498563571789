import { connect } from 'react-redux'
import FormPage3 from './FormPage3'

import { loadAuditCorrectiveActionTypes } from '../../../../actions/upsertAuditActions'

const mapStateToProps = (state) => ({
  auditCorrectiveActionTypes: state.upsertAudit.auditCorrectiveActionTypes,
})

const mapDispatchToProps = {
  loadAuditCorrectiveActionTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(FormPage3)
