import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import NewTable from '../NewTable'

import iconComment from '../../images/icons/icon_comment_state.svg'

import { history, getShortName, splitUserName } from '../../utils'

import * as S from './styles'

const headers = [
  {
    key: 0,
    text: 'AUDITED AT',
    toolTipTitle: 'Audited at',
    toolTipDescription: 'Date and time of audit creation',
  },
  {
    key: 1,
    text: 'AUDITOR',
    toolTipTitle: 'Auditor',
    toolTipDescription: 'Person who completed an audit',
  },
  {
    key: 2,
    text: 'SUPERVISOR',
    toolTipTitle: 'Supervisor',
    toolTipDescription: 'Supervisor of an employee who was audited',
  },
  {
    key: 3,
    text: 'EMPLOYEE',
    toolTipTitle: 'Employee',
    toolTipDescription: 'Employee who was audited',
  },
  {
    key: 4,
    text: 'VIOLATIONS',
    toolTipTitle: 'Violations',
    toolTipDescription: 'Violations that an auditor found during the audit',
  },
  {
    key: 5,
    text: 'CORRECTIVE ACTIONS',
    toolTipTitle: 'Corrective actions',
    toolTipDescription: 'Actions took based on violations',
  },
]

const AuditsTable = ({ audits, timezone }) => {
  const rows = audits.value.map((audit) => {
    const url = `/audits/${audit.id}`

    const violations = audit.violations.join(', ').replace(/\//g, ' / ')
    const correctiveActions = audit.correctiveActions
      .join(', ')
      .replace(/\//g, ' / ')

    const parsedRow = {
      columns: {
        0: {
          value: _.defaultTo(audit.auditedAt, ''),
          render: moment
            .tz(audit.auditedAt, timezone)
            .format('HH:mm MM/DD/YYYY'),
        },
        1: {
          value: getShortName(splitUserName({ user: audit.auditor })),
        },
        2: {
          value: getShortName(splitUserName({ user: audit.supervisor })),
        },
        3: {
          value: getShortName(splitUserName({ user: audit.employee })),
        },
        4: {
          value: _.defaultTo(violations, ''),
          render: (
            <S.EllipsisContainer title={violations}>
              {violations}
            </S.EllipsisContainer>
          ),
        },
        5: {
          value: _.defaultTo(correctiveActions, ''),
          render: (
            <S.EllipsisContainer title={correctiveActions}>
              {correctiveActions}
            </S.EllipsisContainer>
          ),
        },
      },
      onClick: () => history.push(url),
    }

    if (audit.comment) {
      parsedRow.toolTipTitle = 'Comment'
      parsedRow.toolTipDescription = audit.comment
      parsedRow.toolTipTitleIcon = iconComment
      parsedRow.toolTipTheme = 'light'
    }

    return parsedRow
  })

  return <NewTable headers={headers} rows={rows} isLoading={audits.loading} />
}

AuditsTable.propTypes = {
  timezone: PropTypes.string.isRequired,
  audits: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        yardCode: PropTypes.string.isRequired,
        auditor: PropTypes.string.isRequired,
        supervisor: PropTypes.string.isRequired,
        employee: PropTypes.string.isRequired,
        violations: PropTypes.arrayOf(PropTypes.string).isRequired,
        correctiveActions: PropTypes.arrayOf(PropTypes.string).isRequired,
        auditedAt: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
}

export default AuditsTable
