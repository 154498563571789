import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import NewToolTip from '../NewToolTip'

import * as S from './styles'

const Tabs = ({ items, activeItem, onChange, darkMode, isEditMode }) => {
  const [active, setActive] = useState(activeItem)

  useEffect(() => {
    setActive(activeItem)
  }, [activeItem])

  function handleClick(value) {
    setActive(value)
    onChange(value)
  }

  function getFillValue(isActive) {
    if (isActive) {
      return darkMode ? 'var(--lighter-neutral-99)' : 'var(--base-blue)'
    }

    return darkMode ? 'var(--base-neutral)' : 'var(--darker-neutral-45)'
  }

  function renderTabItem(item) {
    const Icon = _.get(item, 'icon')
    const isActive = item.value === active
    const isInactive = isEditMode && !item.editable

    return (
      <S.TabItem
        key={item.value}
        disabled={item.disabled || isInactive}
        active={isActive}
        onClick={() => handleClick(item.value)}
        isDarkMode={darkMode}
      >
        {item.notification && <S.Notification />}
        {!_.isNil(Icon) && (
          <S.IconWrapper>
            <Icon fill={getFillValue(isActive)} />
          </S.IconWrapper>
        )}
        {item.text || item.value} {item.redDot && <S.RedDot />}
      </S.TabItem>
    )
  }

  return (
    <S.Container>
      {items.map((item) => {
        if (item.tooltip) {
          return (
            <NewToolTip key={item.value} {...item.tooltip}>
              {renderTabItem(item)}
            </NewToolTip>
          )
        }

        return renderTabItem(item)
      })}
    </S.Container>
  )
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
      disabled: PropTypes.bool,
      notification: PropTypes.bool,
      tooltip: PropTypes.shape({
        actionType: PropTypes.string,
        titleIcon: PropTypes.string,
        title: PropTypes.string,
        action: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
  ),
  activeItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  darkMode: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isEditMode: PropTypes.bool,
}

Tabs.defaultProps = {
  items: [],
  activeItem: null,
  onChange: () => {},
  darkMode: false,
  icon: null,
  isEditMode: false,
}

export default Tabs
