import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import appPropTypes from '../../../appPropTypes'
import { CardGroup } from '../../../components'
import timeIcon from '../../../images/icons/time-yellow.svg'
import boxIcon from '../../../images/icons/box-green.svg'
import flagIcon from '../../../images/icons/flag-red.svg'

const MetricsCards = ({ flagsCount, vehicleTimeMetrics, boxCount }) => (
  <CardGroup
    list={[
      {
        loading: false,
        icon: boxIcon,
        iconBackground: 'green-circle-bg',
        title: 'BOX COUNT',
        tooltipDescription: 'Total boxes moved',
        value: `${boxCount.loading ? '-' : _.round(boxCount.value.average)}`,
      },
      {
        loading: false,
        icon: timeIcon,
        iconBackground: 'yellow-circle-bg',
        title: 'VEHICLE TIME',
        tooltipDescription:
          'Percentage of time the worker was active in a vehicle',
        value: `${
          vehicleTimeMetrics.loading
            ? '-'
            : _.round(vehicleTimeMetrics.value.average * 100)
        }%`,
      },
      {
        loading: false,
        icon: flagIcon,
        iconBackground: 'red-circle-bg',
        title: 'FLAGS COUNT',
        tooltipDescription: 'Total idle flags',
        value: `${
          flagsCount.loading ? '-' : _.round(flagsCount.value.average)
        }`,
      },
    ]}
  />
)

MetricsCards.propTypes = {
  flagsCount: appPropTypes.asyncOf(PropTypes.number).isRequired,
  vehicleTimeMetrics: appPropTypes.asyncOf({
    bins: PropTypes.arrayOf({
      yValue: PropTypes.number.isRequired,
    }),
    average: PropTypes.number.isRequired,
  }).isRequired,
  boxCount: appPropTypes.asyncOf({
    bins: PropTypes.arrayOf({
      yValue: PropTypes.number.isRequired,
    }),
    total: PropTypes.number.isRequired,
    average: PropTypes.number.isRequired,
  }).isRequired,
}

export default MetricsCards
