import _ from 'lodash'
import clientApi from './utils/clientApi'
import parseKeysToSnakeCase from '../parsers/parseKeysToSnakeCase'

/**
 * Gets the box count for the whole yard or a user
 * from the yard.
 *
 * @param {!Object} params
 * @param {!Number} params.yard_id
 * @param {!Number} params.completed_at_start
 * @param {!Number} params.completed_at_end
 * @param {Number} [params.user_id]
 * @param {String[]} [params.type]
 * @param {Number} [params.bins]
 */
const getCount = (params, options = {}) => {
  const nonNullParams = _.omitBy(params, _.isNil(params))
  const parsedParams = parseKeysToSnakeCase(nonNullParams)

  return clientApi({
    ...options,
    method: 'get',
    url: '/moves/count',
    params: parsedParams,
  }).then(({ data }) => data)
}

/**
 * Gets all the moves from a yard from a specific date
 * or a range (date - numberOfDays)
 *
 * @param {!Number} yard_id
 * @param {!String} date
 * @param {!Number} number_of_days
 */
const getByDay = (params, options = {}) => {
  const parsedParams = parseKeysToSnakeCase(params)

  return clientApi({
    ...options,
    method: 'get',
    url: '/moves/aggregated_by_day',
    params: parsedParams,
  }).then(({ data }) => data)
}

const moves = {
  getCount,
  getByDay,
}

export default moves
