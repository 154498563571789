import { connect } from 'react-redux'
import { path } from 'ramda'
import SafetyReports from './SafetyReports'
import './SafetyReports.css'

import { getYardTimeZone } from '../../selectors'

import { parseIntOrUndefined } from '../../utils'

import {
  loadAudits,
  resetSelectedUser,
} from '../../actions/safetyReportsActions'

const mapStateToProps = (state, ownProps) => ({
  timezone: getYardTimeZone(state),
  audits: state.safetyReports.audits,
  yardId: Number(ownProps.params.yardId),
  createdAtStart: parseIntOrUndefined(ownProps.location.query.created_at_start),
  createdAtEnd: parseIntOrUndefined(ownProps.location.query.created_at_end),
  metabaseDate: ownProps.location.query.metabase_date,
  workerId: Number(ownProps.location.query.worker_id),
  workerType: ownProps.location.query.worker_type,
  userRole: path(['Role', 'name'])(state.general.userData),
})

const mapDispatchToProps = {
  loadAudits,
  resetSelectedUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(SafetyReports)
