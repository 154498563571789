import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as S from './styles'
import { formatCellValue } from '../../utils'

function SummaryItem({ item, children }) {
  const tiers = _.get(item, 'tiers', [])

  const list = tiers.map((tier) => {
    const formattedTierValue = formatCellValue({
      value: _.get(tier, 'value', ''),
    })

    return (
      <S.Tier key={item.code}>
        <S.Row>
          <S.Text>{tier.code}</S.Text>
          <S.Text>{formattedTierValue}</S.Text>
        </S.Row>
      </S.Tier>
    )
  })

  return (
    <S.Details hasChildren={tiers.length > 0} key={item.code} open>
      <>{children}</>
      <>{list}</>
    </S.Details>
  )
}

SummaryItem.propTypes = {
  item: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

SummaryItem.defaultProps = {}

export default SummaryItem
