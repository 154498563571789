import { safetyReportsTypes } from '../../actions/safetyReportsActions'

const initialState = {
  loading: false,
  value: [],
  count: {
    total: 0,
    withViolations: 0,
  },
}

const audits = (state = initialState, { type, payload }) => {
  switch (type) {
    case safetyReportsTypes.AUDITS_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case safetyReportsTypes.AUDITS_LOADED:
      return {
        ...state,
        loading: false,
        value: payload.audits,
        count: payload.count,
      }
    case safetyReportsTypes.AUDITS_FAILED:
      return initialState
    default:
      return state
  }
}

export default audits
