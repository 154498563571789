const issues = [
  {
    id: 1,
    vehicleTypeId: 1,
    name: 'Steps',
    state: null,
    comment: null,
  },
  {
    id: 2,
    vehicleTypeId: 1,
    name: 'Handrails',
    state: null,
    comment: null,
  },
  {
    id: 3,
    vehicleTypeId: 1,
    name: 'Catwalks',
    state: null,
    comment: null,
  },
  {
    id: 4,
    vehicleTypeId: 1,
    name: 'Airlines',
    state: null,
    comment: null,
  },
  {
    id: 5,
    vehicleTypeId: 1,
    name: 'Glad Hands',
    state: null,
    comment: null,
  },
  {
    id: 6,
    vehicleTypeId: 1,
    name: 'Door / Latches',
    state: null,
    comment: null,
  },
  {
    id: 7,
    vehicleTypeId: 1,
    name: 'Housekeeping',
    state: null,
    comment: null,
  },
  {
    id: 8,
    vehicleTypeId: 1,
    name: 'Seat Condition',
    state: null,
    comment: null,
  },
  {
    id: 9,
    vehicleTypeId: 1,
    name: 'Windows',
    state: null,
    comment: null,
  },
  {
    id: 10,
    vehicleTypeId: 1,
    name: 'Mirrors',
    state: null,
    comment: null,
  },
  {
    id: 11,
    vehicleTypeId: 1,
    name: 'Gauges',
    state: null,
    comment: null,
  },
  {
    id: 12,
    vehicleTypeId: 1,
    name: 'Wipers',
    state: null,
    comment: null,
  },
  {
    id: 13,
    vehicleTypeId: 1,
    name: 'Defrost Fan',
    state: null,
    comment: null,
  },
  {
    id: 14,
    vehicleTypeId: 1,
    name: 'Extinguisher',
    state: null,
    comment: null,
  },
  {
    id: 15,
    vehicleTypeId: 1,
    name: 'E-Brake',
    state: null,
    comment: null,
  },
  {
    id: 16,
    vehicleTypeId: 1,
    name: 'Dash Lights',
    state: null,
    comment: null,
  },
  {
    id: 17,
    vehicleTypeId: 1,
    name: 'Foot Pedals',
    state: null,
    comment: null,
  },
  {
    id: 18,
    vehicleTypeId: 1,
    name: 'VMU / Computer',
    state: null,
    comment: null,
  },
  {
    id: 19,
    vehicleTypeId: 1,
    name: 'Radio',
    state: null,
    comment: null,
  },
  {
    id: 20,
    vehicleTypeId: 1,
    name: 'Strobe / Spot Lights',
    state: null,
    comment: null,
  },
  {
    id: 21,
    vehicleTypeId: 1,
    name: 'Head Lights',
    state: null,
    comment: null,
  },
  {
    id: 22,
    vehicleTypeId: 1,
    name: 'Exhaust Stack',
    state: null,
    comment: null,
  },
  {
    id: 23,
    vehicleTypeId: 1,
    name: 'Air Tank (drain)',
    state: null,
    comment: null,
  },
  {
    id: 24,
    vehicleTypeId: 1,
    name: 'Tires / Rims / Lugs',
    state: null,
    comment: null,
  },
  {
    id: 25,
    vehicleTypeId: 1,
    name: 'Mud Flaps',
    state: null,
    comment: null,
  },
  {
    id: 26,
    vehicleTypeId: 1,
    name: 'Fenders',
    state: null,
    comment: null,
  },
  {
    id: 27,
    vehicleTypeId: 1,
    name: 'Tail Lights',
    state: null,
    comment: null,
  },
  {
    id: 28,
    vehicleTypeId: 1,
    name: 'Hydraulic Tank',
    state: null,
    comment: null,
  },
  {
    id: 29,
    vehicleTypeId: 1,
    name: 'Hydraulic Fluid Level',
    state: null,
    comment: null,
  },
  {
    id: 30,
    vehicleTypeId: 1,
    name: 'Fuel Tank / Strap',
    state: null,
    comment: null,
  },
  {
    id: 31,
    vehicleTypeId: 1,
    name: 'Any Leaking Fluids',
    state: null,
    comment: null,
  },
  {
    id: 32,
    vehicleTypeId: 1,
    name: 'Seat Adjustment',
    state: null,
    comment: null,
  },
  {
    id: 33,
    vehicleTypeId: 1,
    name: 'Air Horn',
    state: null,
    comment: null,
  },
  {
    id: 34,
    vehicleTypeId: 1,
    name: '5th Wheel Lever',
    state: null,
    comment: null,
  },
  {
    id: 35,
    vehicleTypeId: 1,
    name: '5th Wheel Operation',
    state: null,
    comment: null,
  },
  {
    id: 36,
    vehicleTypeId: 1,
    name: 'Low Air Indicator',
    state: null,
    comment: null,
  },
  {
    id: 37,
    vehicleTypeId: 1,
    name: 'Seat Belt Operation',
    state: null,
    comment: null,
  },
  {
    id: 38,
    vehicleTypeId: 1,
    name: 'Gear Shifter',
    state: null,
    comment: null,
  },
  {
    id: 39,
    vehicleTypeId: 1,
    name: 'Backup Alarm',
    state: null,
    comment: null,
  },
  {
    id: 40,
    vehicleTypeId: 1,
    name: 'Steering',
    state: null,
    comment: null,
  },
  {
    id: 41,
    vehicleTypeId: 1,
    name: 'Brakes',
    state: null,
    comment: null,
  },
  {
    id: 42,
    vehicleTypeId: 1,
    name: 'iDrive - Camera Position',
    state: null,
    comment: null,
  },
  {
    id: 43,
    vehicleTypeId: 1,
    name: 'iDrive - Camera Plugged In',
    state: null,
    comment: null,
  },
  {
    id: 44,
    vehicleTypeId: 1,
    name: 'iDrive - Camera LEDs On',
    state: null,
    comment: null,
  },
  {
    id: 45,
    vehicleTypeId: 1,
    name: 'iDrive - Panic Button',
    state: null,
    comment: null,
  },
  {
    id: 46,
    vehicleTypeId: 1,
    name: 'iDrive - No Apparent Tampering w/ System',
    state: null,
    comment: null,
  },
  {
    id: 47,
    vehicleTypeId: 1,
    name: 'Other (note in comments)',
    state: null,
    comment: null,
  },
  {
    id: 48,
    vehicleTypeId: 2,
    name: 'Other (note in comments)',
    state: null,
    comment: null,
  },
  {
    id: 49,
    vehicleTypeId: 2,
    name: 'Tires / Wheels',
    state: null,
    comment: null,
  },
  {
    id: 50,
    vehicleTypeId: 2,
    name: 'Mirrors',
    state: null,
    comment: null,
  },
  {
    id: 51,
    vehicleTypeId: 2,
    name: 'Tanks',
    state: null,
    comment: null,
  },
  {
    id: 52,
    vehicleTypeId: 2,
    name: 'Chains',
    state: null,
    comment: null,
  },
  {
    id: 53,
    vehicleTypeId: 2,
    name: 'Lights',
    state: null,
    comment: null,
  },
  {
    id: 54,
    vehicleTypeId: 2,
    name: 'Forks',
    state: null,
    comment: null,
  },
  {
    id: 55,
    vehicleTypeId: 2,
    name: 'Hydraulics',
    state: null,
    comment: null,
  },
  {
    id: 56,
    vehicleTypeId: 2,
    name: 'Leaks',
    state: null,
    comment: null,
  },
  {
    id: 57,
    vehicleTypeId: 2,
    name: 'Exhaust',
    state: null,
    comment: null,
  },
  {
    id: 58,
    vehicleTypeId: 2,
    name: 'Mast / Clamps',
    state: null,
    comment: null,
  },
  {
    id: 59,
    vehicleTypeId: 2,
    name: 'Seat',
    state: null,
    comment: null,
  },
  {
    id: 60,
    vehicleTypeId: 2,
    name: 'Seat Belt',
    state: null,
    comment: null,
  },
  {
    id: 61,
    vehicleTypeId: 2,
    name: 'Pedals',
    state: null,
    comment: null,
  },
  {
    id: 62,
    vehicleTypeId: 2,
    name: 'Controls',
    state: null,
    comment: null,
  },
  {
    id: 63,
    vehicleTypeId: 2,
    name: 'Extinguisher',
    state: null,
    comment: null,
  },
  {
    id: 64,
    vehicleTypeId: 2,
    name: 'Chocks Present',
    state: null,
    comment: null,
  },
  {
    id: 65,
    vehicleTypeId: 2,
    name: 'Strobes',
    state: null,
    comment: null,
  },
  {
    id: 66,
    vehicleTypeId: 2,
    name: 'Flood Lights',
    state: null,
    comment: null,
  },
  {
    id: 67,
    vehicleTypeId: 2,
    name: 'Gauges / Alarms',
    state: null,
    comment: null,
  },
  {
    id: 68,
    vehicleTypeId: 2,
    name: 'Radio',
    state: null,
    comment: null,
  },
  {
    id: 69,
    vehicleTypeId: 2,
    name: 'Hoist Up',
    state: null,
    comment: null,
  },
  {
    id: 70,
    vehicleTypeId: 2,
    name: 'Hoist Down',
    state: null,
    comment: null,
  },
  {
    id: 71,
    vehicleTypeId: 2,
    name: 'Tilt Back',
    state: null,
    comment: null,
  },
  {
    id: 72,
    vehicleTypeId: 2,
    name: 'Tilt Forward',
    state: null,
    comment: null,
  },
  {
    id: 73,
    vehicleTypeId: 2,
    name: 'Ext Forks',
    state: null,
    comment: null,
  },
  {
    id: 74,
    vehicleTypeId: 2,
    name: 'Shrink Forks',
    state: null,
    comment: null,
  },
  {
    id: 75,
    vehicleTypeId: 2,
    name: 'Reverse Alarm',
    state: null,
    comment: null,
  },
  {
    id: 76,
    vehicleTypeId: 2,
    name: 'Brakes',
    state: null,
    comment: null,
  },
  {
    id: 77,
    vehicleTypeId: 2,
    name: 'Horn',
    state: null,
    comment: null,
  },
  {
    id: 78,
    vehicleTypeId: 3,
    name: 'Other (note in comments)',
    state: null,
    comment: null,
  },
  {
    id: 79,
    vehicleTypeId: 3,
    name: 'Cab / Doors',
    state: null,
    comment: null,
  },
  {
    id: 80,
    vehicleTypeId: 3,
    name: 'Seat / Safety Belt',
    state: null,
    comment: null,
  },
  {
    id: 81,
    vehicleTypeId: 3,
    name: 'Fire Ext. / Glass',
    state: null,
    comment: null,
  },
  {
    id: 82,
    vehicleTypeId: 3,
    name: 'Travel Alarm / Safety Beacon',
    state: null,
    comment: null,
  },
  {
    id: 83,
    vehicleTypeId: 3,
    name: 'Traveling',
    state: null,
    comment: null,
  },
  {
    id: 84,
    vehicleTypeId: 3,
    name: 'Camera / Radio / Computer',
    state: null,
    comment: null,
  },
  {
    id: 85,
    vehicleTypeId: 3,
    name: 'Hand Rails / Steps / Ladders',
    state: null,
    comment: null,
  },
  {
    id: 86,
    vehicleTypeId: 3,
    name: 'Wipers / Gauges ',
    state: null,
    comment: null,
  },
  {
    id: 87,
    vehicleTypeId: 3,
    name: 'Heater / AC / Defroster Fan',
    state: null,
    comment: null,
  },
  {
    id: 88,
    vehicleTypeId: 3,
    name: 'Brakes / Brake Head',
    state: null,
    comment: null,
  },
  {
    id: 89,
    vehicleTypeId: 3,
    name: 'Steering / Motor',
    state: null,
    comment: null,
  },
  {
    id: 90,
    vehicleTypeId: 3,
    name: 'Traversing / Motor ',
    state: null,
    comment: null,
  },
  {
    id: 91,
    vehicleTypeId: 3,
    name: 'Drive Axle / Motor',
    state: null,
    comment: null,
  },
  {
    id: 92,
    vehicleTypeId: 3,
    name: 'Cylinder (Identify)',
    state: null,
    comment: null,
  },
  {
    id: 93,
    vehicleTypeId: 3,
    name: 'Lights / Travel / Work',
    state: null,
    comment: null,
  },
  {
    id: 94,
    vehicleTypeId: 3,
    name: 'Chains / Cables',
    state: null,
    comment: null,
  },
  {
    id: 95,
    vehicleTypeId: 3,
    name: 'Controls',
    state: null,
    comment: null,
  },
  {
    id: 96,
    vehicleTypeId: 3,
    name: 'Engine / Transmission',
    state: null,
    comment: null,
  },
  {
    id: 97,
    vehicleTypeId: 3,
    name: 'Tire / Guards (LF / RF / LR / RR)',
    state: null,
    comment: null,
  },
  {
    id: 98,
    vehicleTypeId: 3,
    name: 'Boom',
    state: null,
    comment: null,
  },
  {
    id: 99,
    vehicleTypeId: 3,
    name: 'Mast',
    state: null,
    comment: null,
  },
  {
    id: 100,
    vehicleTypeId: 3,
    name: 'Hoist Beams',
    state: null,
    comment: null,
  },
  {
    id: 101,
    vehicleTypeId: 3,
    name: 'Oil Leaks',
    state: null,
    comment: null,
  },
  {
    id: 102,
    vehicleTypeId: 3,
    name: 'Fuel Leaks',
    state: null,
    comment: null,
  },
  {
    id: 103,
    vehicleTypeId: 3,
    name: 'Coolant Leaks',
    state: null,
    comment: null,
  },
  {
    id: 104,
    vehicleTypeId: 3,
    name: 'Hydraulic leaks',
    state: null,
    comment: null,
  },
  {
    id: 105,
    vehicleTypeId: 3,
    name: 'Electrical',
    state: null,
    comment: null,
  },
  {
    id: 106,
    vehicleTypeId: 3,
    name: 'Spreader',
    state: null,
    comment: null,
  },
  {
    id: 107,
    vehicleTypeId: 3,
    name: 'Twist Locks',
    state: null,
    comment: null,
  },
  {
    id: 108,
    vehicleTypeId: 3,
    name: 'Wide Picks',
    state: null,
    comment: null,
  },
  {
    id: 109,
    vehicleTypeId: 3,
    name: 'ELME System',
    state: null,
    comment: null,
  },
  {
    id: 110,
    vehicleTypeId: 4,
    name: 'Other (note in comments)',
    state: null,
    comment: null,
  },
  {
    id: 111,
    vehicleTypeId: 4,
    name: 'Ignition & Shutdown ',
    state: null,
    comment: null,
  },
  {
    id: 112,
    vehicleTypeId: 4,
    name: 'Spreader Operation',
    state: null,
    comment: null,
  },
  {
    id: 113,
    vehicleTypeId: 4,
    name: 'Hoist Operation',
    state: null,
    comment: null,
  },
  {
    id: 114,
    vehicleTypeId: 4,
    name: 'Legs',
    state: null,
    comment: null,
  },
  {
    id: 115,
    vehicleTypeId: 4,
    name: 'Steering',
    state: null,
    comment: null,
  },
  {
    id: 116,
    vehicleTypeId: 4,
    name: 'Brakes',
    state: null,
    comment: null,
  },
  {
    id: 117,
    vehicleTypeId: 4,
    name: 'Hoses (Leaks)',
    state: null,
    comment: null,
  },
  {
    id: 118,
    vehicleTypeId: 4,
    name: 'Hoist Cables / Chains',
    state: null,
    comment: null,
  },
  {
    id: 119,
    vehicleTypeId: 4,
    name: 'Vibration / Unusual Noise',
    state: null,
    comment: null,
  },
  {
    id: 120,
    vehicleTypeId: 4,
    name: 'Backup Alarm / Flashers',
    state: null,
    comment: null,
  },
  {
    id: 121,
    vehicleTypeId: 4,
    name: 'Lights',
    state: null,
    comment: null,
  },
  {
    id: 122,
    vehicleTypeId: 4,
    name: 'Steps / Ladders / Handrails',
    state: null,
    comment: null,
  },
  {
    id: 123,
    vehicleTypeId: 4,
    name: 'Door / Latches',
    state: null,
    comment: null,
  },
  {
    id: 124,
    vehicleTypeId: 4,
    name: 'Seat',
    state: null,
    comment: null,
  },
  {
    id: 125,
    vehicleTypeId: 4,
    name: 'Seat Belt',
    state: null,
    comment: null,
  },
  {
    id: 126,
    vehicleTypeId: 4,
    name: 'Mirrors',
    state: null,
    comment: null,
  },
  {
    id: 127,
    vehicleTypeId: 4,
    name: 'Windows & Wipers',
    state: null,
    comment: null,
  },
  {
    id: 128,
    vehicleTypeId: 4,
    name: 'Radio',
    state: null,
    comment: null,
  },
  {
    id: 129,
    vehicleTypeId: 4,
    name: 'Heater / AC',
    state: null,
    comment: null,
  },
  {
    id: 130,
    vehicleTypeId: 4,
    name: 'Fire Extinguisher',
    state: null,
    comment: null,
  },
  {
    id: 131,
    vehicleTypeId: 4,
    name: 'Housekeeping',
    state: null,
    comment: null,
  },
  {
    id: 132,
    vehicleTypeId: 4,
    name: 'Suspension',
    state: null,
    comment: null,
  },
  {
    id: 133,
    vehicleTypeId: 4,
    name: 'Tires LF',
    state: null,
    comment: null,
  },
  {
    id: 134,
    vehicleTypeId: 4,
    name: 'Tires LFI',
    state: null,
    comment: null,
  },
  {
    id: 135,
    vehicleTypeId: 4,
    name: 'Tires LFO',
    state: null,
    comment: null,
  },
  {
    id: 136,
    vehicleTypeId: 4,
    name: 'Tires LR',
    state: null,
    comment: null,
  },
  {
    id: 137,
    vehicleTypeId: 4,
    name: 'Tires RF',
    state: null,
    comment: null,
  },
  {
    id: 138,
    vehicleTypeId: 4,
    name: 'Tires RFI',
    state: null,
    comment: null,
  },
  {
    id: 139,
    vehicleTypeId: 4,
    name: 'Tires RFO',
    state: null,
    comment: null,
  },
  {
    id: 140,
    vehicleTypeId: 4,
    name: 'Tires RR',
    state: null,
    comment: null,
  },
  {
    id: 141,
    vehicleTypeId: 4,
    name: 'Rims / Lug Nuts',
    state: null,
    comment: null,
  },
  {
    id: 142,
    vehicleTypeId: 4,
    name: 'Check Gauges',
    state: null,
    comment: null,
  },
  {
    id: 143,
    vehicleTypeId: 4,
    name: 'Hydraulic Fluid Level',
    state: null,
    comment: null,
  },
  {
    id: 144,
    vehicleTypeId: 4,
    name: 'Water Level',
    state: null,
    comment: null,
  },
  {
    id: 145,
    vehicleTypeId: 4,
    name: 'Engine Oil Level',
    state: null,
    comment: null,
  },
  {
    id: 146,
    vehicleTypeId: 4,
    name: 'Transmission Level',
    state: null,
    comment: null,
  },
  {
    id: 147,
    vehicleTypeId: 4,
    name: 'Temperature',
    state: null,
    comment: null,
  },
  {
    id: 148,
    vehicleTypeId: 4,
    name: 'Fluid Leaks',
    state: null,
    comment: null,
  },
  {
    id: 149,
    vehicleTypeId: 1,
    name: 'Exhaust leaks',
    state: null,
    comment: null,
  },
  {
    id: 150,
    vehicleTypeId: 3,
    name: 'Hoisting / Motor',
    state: null,
    comment: null,
  },
  {
    id: 151,
    vehicleTypeId: 4,
    name: 'Fuel Level',
    state: null,
    comment: null,
  },
  ...[
    { id: 185, vehicleTypeId: 1 },
    { id: 186, vehicleTypeId: 2 },
    { id: 187, vehicleTypeId: 3 },
    { id: 188, vehicleTypeId: 4 },
    { id: 189, vehicleTypeId: 7 },
  ].map(({ id, vehicleTypeId }) => ({
    id,
    vehicleTypeId,
    name: 'Ignition Key',
    state: null,
    comment: null,
  })),
]

export default issues
