import React, { useEffect } from 'react'
import _ from 'lodash'

import {
  Blank,
  VehicleDetailsSingleVcr,
  VehicleDetailsListContainer,
} from '../../components'

import * as S from './styles'

const VehicleDetailsVcr = ({
  loadVcrList,
  vehicleId,
  vcrList,
  timezone,
  yardId,
  setHeaderReturnOptions,
}) => {
  useEffect(() => {
    loadVcrList({
      vehicleId,
      limit: 10,
      offset: 0,
    })
  }, [loadVcrList, vehicleId])

  const listValue = _.get(vcrList, 'value', [])
  const isLoading = _.get(vcrList, 'isLoading')

  const vcrs = listValue.map((vcr) => {
    const key = _.get(vcr, 'id')
    const props = {
      vcr,
      timezone,
      yardId,
      setHeaderReturnOptions,
      vehicleId,
    }

    return <VehicleDetailsSingleVcr key={key} {...props} />
  })

  function handleScrollEnd() {
    const nextOffset = _.get(vcrList, 'nextOffset')

    return _.isNil(nextOffset)
      ? null
      : loadVcrList({
          vehicleId,
          limit: 10,
          offset: nextOffset,
          isLazyLoading: true,
        })
  }

  function render() {
    if (_.isEmpty(listValue) && !isLoading) {
      return (
        <S.EmptyContainer>
          <Blank title="There are no VCR's yet" type="search" />
        </S.EmptyContainer>
      )
    }

    return (
      <VehicleDetailsListContainer
        isLoading={isLoading}
        handleScrollEnd={handleScrollEnd}
      >
        {vcrs}
      </VehicleDetailsListContainer>
    )
  }

  return render()
}

export default VehicleDetailsVcr
