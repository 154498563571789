import { passwordResetTypes } from '../../actions/passwordResetActions'

const initialState = {
  isLoading: false,
  value: null,
}

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case passwordResetTypes.USER_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case passwordResetTypes.USER_LOADED:
      return {
        ...state,
        isLoading: false,
        value: payload.user,
      }

    case passwordResetTypes.RESET_USER_PASSWORD_FAILED:
      return initialState

    default:
      return state
  }
}

export default user
