import styled from 'styled-components/macro'

const colorByText = {
  draft: 'var(--base-neutral)',
  final: 'var(--darker-green-33)',
  accrual: 'var(--base-yellow)',
}

export const Badge = styled.span`
  display: inline-block;
  border-radius: 12px;
  text-transform: uppercase;
  width: fit-content;
  padding: 2px 10px;
  font-size: 12.5px;
  color: var(--lighter-neutral-99);
  margin-right: 10px;
  font-family: 'ArticulatCF-Bold';
  align-items: center;
  display: flex;

  background: ${(p) => colorByText[p.text]};
`
