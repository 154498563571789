import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import moment from 'moment-timezone'
import {
  __,
  map,
  any,
  path,
  omit,
  pipe,
  find,
  prop,
  last,
  trim,
  pick,
  head,
  isNil,
  pluck,
  split,
  chain,
  insert,
  remove,
  reject,
  propEq,
  values,
  either,
  filter,
  isEmpty,
  groupBy,
  flatten,
  mergeAll,
  complement,
  includes,
  defaultTo,
  assocPath,
} from 'ramda'
import { Formik, Field } from 'formik'
import { useSelector } from 'react-redux'
import { browserHistory } from 'react-router'

import shortid from 'shortid'
import { startCase } from 'lodash'
import * as S from './styles'

import TableTemplateValues from './TableTemplateValues'

import {
  PROD_SAVINGS_TABLE_HEADERS,
  PROD_SAVINGS_VALUES_TABLE_HEADERS,
  SERVICES_INNER_TABS_OPTIONS,
  VIEW_MODE,
  CHARGES_INNER_TABS_OPTIONS,
  METRICS_TABLE_HEADERS,
  EXPORT_TABLE_HEADERS,
  DISPLAY_ORDER_TABLE_HEADERS,
  TIERS_TABLE_HEADERS,
  EDITABLE_VIEWS,
  AFFECTED_INVOICES_HEADERS,
  PROD_SAVINGS_NEW_TABLE,
  VIEWS_WITH_CSV_EXPORT,
  VIEW_MODE_OPTIONS,
} from './constants'

import {
  getProdSavingsTableRows,
  getMetricsTableRows,
  getServiceTableContent,
  getChargesTableContent,
  getExportTableRows,
  getDisplayOrderTableRows,
  getTiersTableRows,
  getTemplate,
  getTitleTooltip,
  getKeyValueByTable,
  sanitizeRowBeforeSubmit,
  // getTabItems,
  getAffectedInvoiceRow,
  getServicesTableRows,
  getChargesTableRows,
  getReturnUrl,
  getFieldsToOmit,
  getSpreadProdSavingsValues,
  getNewRowByTable,
  parseRawTemplateDetails,
  unnestValues,
  appendNewExportOrderItem,
  mapIndexed,
  addDisplayOrderIndex,
  addExportOrderIndex,
  hasDisplayOrder,
  rejectDisplayOrderItems,
  rejectExportOrderItems,
  shouldRejectItems,
  parseMetricsBeforeSubmission,
  parseServicesBeforeSubmission,
  parseChargesBeforeSubmission,
  updateParentTierInterval,
} from './helpers'

import {
  NewHeader,
  NewContainer,
  NewTabs,
  NewModal,
  FormItem,
  BillingBadge,
  RemoveTable,
} from '../../components'

import TemplateValuesRow from './TemplateValuesRow'

import {
  useGetTemplateDetailsQuery,
  useUpdateBillingTemplateDisplayOrderMutation,
  useGetTemplateDatesQuery,
  useGetAffectedInvoicesQuery,
  useGetEmptyBillingTemplateQuery,
  useGetValidDatesForEmptyTemplateMutation,
} from '../../services/billing'

import { usePrevious } from '../../hooks'
import {
  history,
  getYardCodeById,
  parseEmptyToNull,
  handleDownloadCsvFile,
} from '../../utils'

import editicon from '../../images/icons/icon_edit_dark.svg'
import iconDownload from '../../images/icons/icon_export_dark.svg'
import scrollBottom from '../../utils/scrollBottom'

const BillingTemplateDetails = ({
  selectedYard,
  timezone,
  returnDate,
  templateId,
  UrlViewMode,
  UrlSubMenu,
  showErrorAlert,
  showAlert,
  setUnsavedChanges,
  resetSliceData,
  yardList,
}) => {
  const [showCancelEditModal, setShowCancelEditModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showDeleteProdSavingsModal, setShowDeleteProdSavingsModal] = useState(
    false,
  )
  const [showRemoveServicesTable, setShowRemoveServicesTable] = useState(false)
  const [showDeleteMetricsModal, setShowDeleteMetricsModal] = useState(false)
  const [showDeleteTiersModal, setShowDeleteTiersModal] = useState(false)
  const [showDeleteChargesModal, setShowDeleteChargesModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [enableSaveButton, setEnableSaveButton] = useState()
  const [creatingNewTemplate, setCreatingNewTemplate] = useState(false)
  const [skipGetAffectedInvoices, setSkipGetAffectedInvoices] = useState(true)
  const [dateValue, setDateValue] = useState()
  const [nameValue, setNameValue] = useState()
  const [noteValue, setNoteValue] = useState()
  const [hasProdSavingsBeenDeleted, setHasProdSavingsBeenDeleted] = useState(
    false,
  )
  const [datesForNewTemplate, setDatesForNewTemplate] = useState([])

  const returnUrl = getReturnUrl({ selectedYard, returnDate })
  const returnDateUrl = queryString.stringify({
    return_date: returnDate,
  })
  const previousYardId = usePrevious(selectedYard)
  const unsavedChanges = useSelector((state) => state.billingTemplateDetails)
  const isNewTemplate = isNil(templateId)

  const [onDisplayOrderUpdate] = useUpdateBillingTemplateDisplayOrderMutation()

  const { data = {}, isLoading } = useGetTemplateDetailsQuery(
    { templateId },
    {
      skip: isNewTemplate,
    },
  )

  const { data: emptyTemplate = {} } = useGetEmptyBillingTemplateQuery(
    {},
    { skip: !isNewTemplate },
  )

  const [getValidDates] = useGetValidDatesForEmptyTemplateMutation()

  const { data: templateDates = [] } = useGetTemplateDatesQuery(
    { templateId },
    {
      skip: isNewTemplate,
    },
  )
  const { data: affectedInvoices = [] } = useGetAffectedInvoicesQuery(
    { templateId, startDate: dateValue },
    { skip: skipGetAffectedInvoices },
  )

  const rawTemplateDetails = isEmpty(unsavedChanges) ? data : unsavedChanges

  const templateDetails = parseRawTemplateDetails({
    isLoading,
    rawTemplateDetails,
  })

  useEffect(() => {
    resetSliceData()
  }, [selectedYard, resetSliceData])

  useEffect(() => {
    if (isEditMode && !isEmpty(data)) {
      setUnsavedChanges(data)
    }
  }, [data, isEditMode, setUnsavedChanges])

  useEffect(() => {
    if (isEditMode && isNewTemplate) {
      setUnsavedChanges(emptyTemplate)
    }
  }, [emptyTemplate, isEditMode, isNewTemplate, setUnsavedChanges])

  useEffect(() => {
    if (!isNil(previousYardId) && previousYardId !== selectedYard) {
      history.push(returnUrl)
    }
  }, [previousYardId, returnUrl, selectedYard])

  const servicesData = getServicesTableRows(templateDetails)
  const prodSavings = getProdSavingsTableRows(templateDetails, timezone)
  const metrics = getMetricsTableRows(templateDetails)
  const exports = getExportTableRows(templateDetails)
  const displayOrder = getDisplayOrderTableRows(templateDetails)
  const tiers = getTiersTableRows(templateDetails)
  const template = getTemplate(templateDetails)
  const chargesData = getChargesTableRows(templateDetails)

  function handleSortUpdate(payload) {
    setUnsavedChanges({ [UrlViewMode]: payload })
  }

  async function handleLoadDatesForEmptyTemplate() {
    const body = {
      yard_id: selectedYard,
      metrics_data: parseMetricsBeforeSubmission(rawTemplateDetails.metrics),
      services_data: parseServicesBeforeSubmission(rawTemplateDetails.services),
      charges_data: parseChargesBeforeSubmission(rawTemplateDetails.charges),
    }

    const response = await getValidDates({ body }).then((res) => res.data)

    if (isNil(response)) {
      return [moment.tz(timezone).startOf('day').format('YYYY-MM-DD')]
    }

    return response
  }

  async function handleOpenConfirmModal() {
    setShowConfirmModal(true)

    if (isNewTemplate) {
      const newTemplateDates = await handleLoadDatesForEmptyTemplate()
      if (!isNil(newTemplateDates)) {
        setDatesForNewTemplate(newTemplateDates)
      }
    }
  }

  const convertCommaStringToArray = pipe(split(','), map(trim))

  const prodSavingsContractEffectiveType = prop('effective')(prodSavings)

  const hasProdSavingsLifeOfContractDate =
    prodSavingsContractEffectiveType === 'Life of Contract'

  const parsedProdSavingValuesHeaders = PROD_SAVINGS_VALUES_TABLE_HEADERS.filter(
    (header) => !(hasProdSavingsLifeOfContractDate && header.field === 'date'),
  )

  const isSaveButtonEnabled = hasProdSavingsBeenDeleted
    ? true
    : isEditMode &&
      enableSaveButton &&
      !isLoading &&
      !pipe(
        map(omit(getFieldsToOmit(hasProdSavingsLifeOfContractDate))),
        chain(values),
        any(either(isNil, isEmpty)),
      )(flatten([prodSavings, ...getSpreadProdSavingsValues(prodSavings)]))

  const displayOrderTableValues = {
    headers: DISPLAY_ORDER_TABLE_HEADERS,
    rows: displayOrder,
    isLoading,
    timezone,
    enableDragAndDrop: isEditMode,
    onSortUpdate: handleSortUpdate,
    setEnableSaveButton,
    creatingNewTemplate,
    table: UrlViewMode,
    hasHorizontalScroll: true,
    isEditMode,
  }

  function renderSaveButton() {
    const isDisabled =
      !isSaveButtonEnabled ||
      (isNewTemplate && isEmpty(rawTemplateDetails.export_order_items))

    return isEditMode ? (
      <S.SaveButton
        disabled={isDisabled}
        type="button"
        onClick={handleOpenConfirmModal}
      >
        Save changes
      </S.SaveButton>
    ) : null
  }

  function handleExitEditStateModal() {
    setShowCancelEditModal(false)
    setIsEditMode(false)
    setHasProdSavingsBeenDeleted(false)
    resetSliceData()
  }

  function handleDeleteProdSavings() {
    const prodSavingsTable = unsavedChanges.productivity_savings
    const rejectProdSavings = reject(propEq('id', prodSavingsTable.id))
    setUnsavedChanges({
      productivity_savings: null,
      display_order_items: pipe(
        rejectProdSavings,
        addDisplayOrderIndex,
      )(rawTemplateDetails.display_order_items),
      export_order_items: pipe(
        rejectProdSavings,
        addExportOrderIndex,
      )(rawTemplateDetails.export_order_items),
    })
    setShowDeleteProdSavingsModal(false)
    setHasProdSavingsBeenDeleted(true)
    setEnableSaveButton(true)
  }

  function handleDeleteServicesTables() {
    const servicesToRemove = unsavedChanges.services[UrlSubMenu]
    const servicesTable = { ...unsavedChanges.services, [UrlSubMenu]: [] }
    const shouldReject = shouldRejectItems(servicesToRemove)
    const newDisplayOrderItems = rejectDisplayOrderItems({
      shouldReject,
      rawTemplateDetails,
    })
    const newExportOrderItems = rejectExportOrderItems({
      shouldReject,
      rawTemplateDetails,
    })
    setUnsavedChanges({
      services: servicesTable,
      display_order_items: newDisplayOrderItems,
      export_order_items: newExportOrderItems,
    })
    setEnableSaveButton(true)
  }

  function handleDeleteCharges() {
    const chargesToRemove = unsavedChanges.charges[UrlSubMenu]
    const chargesTable = { ...unsavedChanges.charges, [UrlSubMenu]: [] }
    const shouldReject = shouldRejectItems(chargesToRemove)
    const newDisplayOrderItems = rejectDisplayOrderItems({
      shouldReject,
      rawTemplateDetails,
    })
    const newExportOrderItems = rejectExportOrderItems({
      shouldReject,
      rawTemplateDetails,
    })
    setUnsavedChanges({
      charges: chargesTable,
      display_order_items: newDisplayOrderItems,
      export_order_items: newExportOrderItems,
    })
    setEnableSaveButton(true)
  }

  function handleDeleteMetrics() {
    const shouldReject = shouldRejectItems(unsavedChanges.metrics)
    const newDisplayOrderItems = rejectDisplayOrderItems({
      shouldReject,
      rawTemplateDetails,
    })
    setUnsavedChanges({
      metrics: [],
      display_order_items: newDisplayOrderItems,
    })
    return setEnableSaveButton(true)
  }

  function handleDeleteTiers() {
    setUnsavedChanges({
      tiers: [],
    })
    return setEnableSaveButton(true)
  }

  function handleProdSavingsDownload() {
    const date = moment.tz(timezone).format('MM-DD-YYYY')

    const isLifeOfContract = prodSavings.effective === 'Life of Contract'
    handleDownloadCsvFile({
      headerMap: isLifeOfContract
        ? reject(propEq('field', 'date'))(PROD_SAVINGS_VALUES_TABLE_HEADERS)
        : PROD_SAVINGS_VALUES_TABLE_HEADERS,
      body: prodSavings.values,
      date,
      name: UrlViewMode,
    })
  }

  function handleChangeSelectableOptions({ parentRow, newRows }) {
    return setUnsavedChanges({
      services: {
        ...templateDetails.services,
        selectable: templateDetails.services.selectable.map((row) => {
          if (row.id === parentRow.id) {
            return {
              ...row,
              selectable_options: newRows,
            }
          }

          return row
        }),
      },
    })
  }

  function handleDeleteSelectableOptions(row) {
    handleChangeSelectableOptions({ parentRow: row, newRows: [] })
  }

  function hideCsvDownload() {
    if (UrlViewMode === 'productivity_savings') {
      return (
        hasProdSavingsBeenDeleted ||
        isNil(prodSavings.values) ||
        pipe(
          chain(values),
          any((item) => isNil(item) || item === ''),
        )(prodSavings.values)
      )
    }

    return !VIEWS_WITH_CSV_EXPORT.includes(UrlViewMode)
  }

  const titleAction = EDITABLE_VIEWS.includes(UrlViewMode)
    ? [
        {
          key: 0,
          icon: editicon,
          onClick: () => setIsEditMode(true),
          disabled: isEditMode,
          tooltip: {
            title: 'Edit Billing Template',
            action: 'Click to edit this billing template',
          },
        },
        {
          key: 1,
          icon: iconDownload,
          isHidden: hideCsvDownload(),
          onClick: handleProdSavingsDownload,
          tooltip: {
            title: 'Download values',
            action: 'Click to download',
          },
        },
      ]
    : []

  const headerProps = {
    title: isEditMode ? 'Editing' : 'Template details',
    titleTooltip: isEmpty(template)
      ? null
      : getTitleTooltip({ template, timezone }),
    returnText: isEditMode ? 'Cancel edit' : 'Back to Billing Templates',
    returnAction: isEditMode
      ? () => setShowCancelEditModal(true)
      : () => history.push(returnUrl),
    titleAction,
    renderAction: renderSaveButton,
    tabs: {
      items: VIEW_MODE_OPTIONS,
      activeItem: UrlViewMode,
      isEditMode,
      onChange: (value) =>
        browserHistory.push({
          pathname: `/billing/templates/${
            isNewTemplate ? 'new' : `details/${templateId}`
          }/${value}?return_date=${returnDate}`,
        }),
    },
  }

  const deleteModalCommonProps = {
    title: `Do you want to delete ${startCase(
      UrlViewMode === 'services' ? UrlSubMenu : UrlViewMode,
    )} values?`,
    message: 'Once deleted, data cannot be retrivied',
    type: 'alert',
    confirmText: 'Yes, delete',
    cancelText: 'Cancel',
  }

  const deleteProdSavingsModalProps = {
    visible: showDeleteProdSavingsModal,
    onConfirm: handleDeleteProdSavings,
    onClose: () => setShowDeleteProdSavingsModal(false),
    onCancel: () => setShowDeleteProdSavingsModal(false),
    ...deleteModalCommonProps,
  }

  const deleteServicesModalProps = {
    visible: showRemoveServicesTable,
    onConfirm: handleDeleteServicesTables,
    onClose: () => setShowRemoveServicesTable(false),
    onCancel: () => setShowRemoveServicesTable(false),
    ...deleteModalCommonProps,
  }

  const deleteMetricsModalProps = {
    visible: showDeleteMetricsModal,
    onConfirm: handleDeleteMetrics,
    onClose: () => setShowDeleteMetricsModal(false),
    onCancel: () => setShowDeleteMetricsModal(false),
    ...deleteModalCommonProps,
  }

  const deleteTiersModalProps = {
    visible: showDeleteTiersModal,
    onConfirm: handleDeleteTiers,
    onClose: () => setShowDeleteTiersModal(false),
    onCancel: () => setShowDeleteTiersModal(false),
    ...deleteModalCommonProps,
  }

  const deleteChargesModalProps = {
    visible: showDeleteChargesModal,
    onConfirm: handleDeleteCharges,
    onClose: () => setShowDeleteChargesModal(false),
    onCancel: () => setShowDeleteChargesModal(false),
    ...deleteModalCommonProps,
  }

  const cancelEditModalProps = {
    visible: showCancelEditModal,
    onConfirm: () => setShowCancelEditModal(false),
    onClose: () => handleExitEditStateModal,
    onCancel: handleExitEditStateModal,
    title: 'Do you want to save?',
    message:
      'You are attempting to cancel without saving your changes. Would you like to save them?',
    type: 'alert',
    confirmText: 'Keep editing',
    cancelText: 'Cancel changes',
  }

  function updateTiersInterval({ parentRow, newRows }) {
    return tiers.map((tier) => {
      const matchWithId = !isNil(tier.id) && tier.id === parentRow.id
      const matchWithBillingId =
        tier.billing_item_id === parentRow.billing_item_id

      if (matchWithId || matchWithBillingId) {
        return { ...tier, tiers: newRows }
      }

      return tier
    })
  }

  function handleAddOrRemoveRows({
    headers,
    rows,
    action,
    table,
    subMenu,
    fromIndex,
    parentRow,
    parentRows,
    isTiersInterval,
    isTiersIntervalChildren,
  }) {
    const isProdSavings = table === 'productivity_savings'

    if (action === 'add-new-prod-savings-table') {
      const prodSavingBillingItemId = path([
        'meta',
        'billing_items_by_type',
        'productivity_saving',
        0,
        'id',
      ])(rawTemplateDetails)

      const prodSavingsTable = {
        ...PROD_SAVINGS_NEW_TABLE,
        billing_item_id: prodSavingBillingItemId,
        id: `_${shortid()}`,
      }

      const newDisplayOrderItems = addDisplayOrderIndex([
        ...rawTemplateDetails.display_order_items,
        pick(['id'])(prodSavingsTable),
      ])

      const newExportOrderItems = appendNewExportOrderItem({
        rawTemplateDetails,
        item: pick(['id'])(prodSavingsTable),
      })

      return setUnsavedChanges({
        [table]: prodSavingsTable,
        display_order_items: newDisplayOrderItems,
        export_order_items: newExportOrderItems,
      })
    }

    if (action === 'add-line') {
      const newRow = getNewRowByTable({
        table,
        subMenu,
        rawTemplateDetails,
        isTiersInterval,
        isTiersIntervalChildren,
      })

      if (isProdSavings) {
        const parsedRows = rows.map((row) =>
          hasProdSavingsLifeOfContractDate ? { ...row, date: null } : row,
        )

        const newRows = pipe(
          insert(fromIndex, newRow),
          mapIndexed((item, index) => ({ ...item, id: index })),
        )(parsedRows)

        return setUnsavedChanges({
          [table]: { ...prodSavings, values: newRows },
        })
      }

      if (isTiersIntervalChildren) {
        const newRows = insert(fromIndex, newRow)(rows)

        const updatedParentTierInterval = updateParentTierInterval({
          parentRows,
          rows,
          newRows,
        })

        const newTiersWithUpdateInterval = updateTiersInterval({
          parentRow,
          newRows: updatedParentTierInterval,
        })

        setUnsavedChanges({
          [table]: newTiersWithUpdateInterval,
        })

        return scrollBottom('selectableOptions')
      }

      if (isTiersInterval) {
        const newRows = insert(fromIndex, newRow)(parentRow[table])
        const newTiersWithUpdateInterval = updateTiersInterval({
          parentRow,
          newRows,
        })

        setUnsavedChanges({
          [table]: newTiersWithUpdateInterval,
        })

        return scrollBottom('selectableOptions')
      }

      if (table === 'selectable_options') {
        const newRows = pipe(
          insert(fromIndex, newRow),
          mapIndexed((item) => ({
            ...item,
            id: defaultTo(`_${shortid()}`)(item.id),
          })),
        )(parentRow[table])

        handleChangeSelectableOptions({ parentRow, newRows })
        scrollBottom('selectableOptions')

        return null
      }

      if (includes(table, ['tiers'])) {
        const newRows = insert(fromIndex, newRow)(rows)

        const newDisplayOrderItems = addDisplayOrderIndex([
          ...filter(hasDisplayOrder)(rawTemplateDetails.display_order_items),
          newRow,
          ...reject(hasDisplayOrder)(rawTemplateDetails.display_order_items),
        ])

        setUnsavedChanges({
          [table]: newRows,
          display_order_items: newDisplayOrderItems,
        })
      }

      if (includes(table, ['metrics', 'services', 'charges'])) {
        const rowsToAdd = isEmpty(rows)
          ? [newRow]
          : insert(fromIndex, newRow)(rows)

        const newDisplayOrderItems = addDisplayOrderIndex([
          ...filter(hasDisplayOrder)(rawTemplateDetails.display_order_items),
          newRow,
          ...reject(hasDisplayOrder)(rawTemplateDetails.display_order_items),
        ])

        const tableChanges =
          table === 'metrics'
            ? rowsToAdd
            : {
                ...templateDetails[table],
                [UrlSubMenu]: rowsToAdd,
              }

        setUnsavedChanges({
          [table]: tableChanges,
          display_order_items: newDisplayOrderItems,
        })

        // scrollBottom('templateDetailsBody')
      }

      return null
    }

    if (action === 'remove-line') {
      const removedRow = rows[fromIndex]
      const newRows = remove(fromIndex, 1, rows)

      setEnableSaveButton(true)

      if (table === 'selectable_options') {
        handleChangeSelectableOptions({ parentRow, newRows })
      }

      if (includes(table, ['metrics', 'services', 'charges'])) {
        const isRemovedDisplayOrderItem = propEq('id', removedRow.id)
        const newDisplayOrderItems = rejectDisplayOrderItems({
          shouldReject: either(
            isRemovedDisplayOrderItem,
            complement(hasDisplayOrder),
          ),
          rawTemplateDetails,
        })

        const newExportOrderItems = includes(table, ['services', 'charges'])
          ? rejectExportOrderItems({
              shouldReject: propEq('id', removedRow.id),
              rawTemplateDetails,
            })
          : rawTemplateDetails.export_order_items

        const tableChanges =
          table === 'metrics'
            ? newRows
            : {
                ...templateDetails[table],
                [UrlSubMenu]: newRows,
              }

        return setUnsavedChanges({
          [table]: tableChanges,
          display_order_items: newDisplayOrderItems,
          export_order_items: newExportOrderItems,
        })
      }

      if (table === 'tiers') {
        const { uniqueTableName } = headers[0]

        if (includes(uniqueTableName, ['tiers_items', 'compound_tiers'])) {
          const newTiers = map((tierItem) =>
            tierItem.billing_item_id === parentRow.billing_item_id
              ? {
                  ...parentRow,
                  tiers: newRows,
                }
              : tierItem,
          )(rawTemplateDetails.tiers)
          return setUnsavedChanges({
            [table]: newTiers,
          })
        }

        if (uniqueTableName === 'compound_tiers_children') {
          const newTiers = map((tierItem) =>
            tierItem.billing_item_id === parentRow.billing_item_id
              ? {
                  ...parentRow,
                  tiers: updateParentTierInterval({
                    parentRows,
                    rows,
                    newRows,
                  }),
                }
              : tierItem,
          )(rawTemplateDetails.tiers)
          return setUnsavedChanges({
            [table]: newTiers,
          })
        }
      }

      return setUnsavedChanges({
        [table]: isProdSavings ? { ...prodSavings, values: newRows } : newRows,
      })
    }

    return null
  }

  function handleValueChange({
    rows,
    value,
    currentHeader,
    currentRow,
    table,
    isProdSavingsValues,
    parentRow,
    parentRows,
    billingItemDescription,
  }) {
    const payload = rows.map((row, index) => {
      const isSingleTier = currentHeader.uniqueTableName === 'tiers_items'

      const isTiersIntervalChildUpdateWithId =
        currentHeader.isIntervalChild &&
        table === 'tiers' &&
        !isNil(currentRow.id) &&
        row.id === currentRow.id

      const isTiersIntervalChildUpdateWithCode =
        currentHeader.isIntervalChild &&
        table === 'tiers' &&
        isNil(currentRow.id) &&
        head(row.interval) === head(currentRow.interval)

      const isTiersIntervalUpdateWithId =
        currentHeader.isInterval &&
        table === 'tiers' &&
        !isNil(currentRow.id) &&
        row.id === currentRow.id

      const isTiersIntervalUpdateWithCode =
        (currentHeader.isInterval || isSingleTier) &&
        table === 'tiers' &&
        isNil(currentRow.id) &&
        head(row.interval) === head(currentRow.interval)

      const isCommonUpdate =
        !isNil(row.id) && !isNil(currentRow.id) && row.id === currentRow.id

      const isTiersUpdateWithId =
        table === 'tiers' &&
        !currentHeader.isInterval &&
        !currentHeader.isIntervalChild &&
        !isNil(currentRow.id) &&
        row.id === currentRow.id

      const isTiersUpdateWithCode =
        !isSingleTier &&
        table === 'tiers' &&
        !currentHeader.isInterval &&
        !currentHeader.isIntervalChild &&
        isNil(currentRow.id) &&
        row.billing_item_id === currentRow.billing_item_id

      const hasSameTiersInterval =
        table === 'tiers'
          ? rows.some((r) => {
              if (isNil(r.interval)) {
                return false
              }

              return String(head(r.interval)) === value
            })
          : false

      if (
        (isTiersIntervalUpdateWithId || isTiersIntervalUpdateWithCode) &&
        hasSameTiersInterval
      ) {
        return row
      }

      if (
        isTiersIntervalChildUpdateWithId ||
        isTiersIntervalChildUpdateWithCode ||
        isTiersIntervalUpdateWithId ||
        isTiersIntervalUpdateWithCode ||
        isCommonUpdate ||
        isTiersUpdateWithId ||
        isTiersUpdateWithCode
      ) {
        const isBillingItemUpdate = currentHeader.field === 'billing_item_id'

        const commonReturn = assocPath(
          Array.isArray(currentHeader.field)
            ? currentHeader.field
            : [currentHeader.field],
          currentHeader.isArray ? convertCommaStringToArray(value) : value,
          {
            ...currentRow,
          },
        )

        if (isBillingItemUpdate) {
          return {
            ...commonReturn,
            [getKeyValueByTable(table)]: index,
            display_name: billingItemDescription,
          }
        }

        if (isCommonUpdate) {
          return {
            ...commonReturn,
            [getKeyValueByTable(table)]: index,
          }
        }

        return commonReturn
      }

      return row
    })

    if (table === 'productivity_savings') {
      if (isProdSavingsValues) {
        setUnsavedChanges({ [table]: { ...prodSavings, values: payload } })
        return setEnableSaveButton(true)
      }

      setUnsavedChanges({ [table]: head(payload) })
      return setEnableSaveButton(true)
    }

    if (includes(table, ['services', 'charges'])) {
      const allRows = unnestValues(rawTemplateDetails[table])
      const rowById = pipe(groupBy(prop('id')), map(head))(allRows)
      const updatedIsChargeableRow = find(
        (item) => item.is_chargeable !== rowById[item.id].is_chargeable,
      )(payload)

      let newExportOrderItems = rawTemplateDetails.export_order_items
      if (!isNil(updatedIsChargeableRow)) {
        if (updatedIsChargeableRow.is_chargeable) {
          newExportOrderItems = appendNewExportOrderItem({
            rawTemplateDetails,
            item: updatedIsChargeableRow,
          })
        } else {
          newExportOrderItems = reject(propEq('id', updatedIsChargeableRow.id))(
            rawTemplateDetails.export_order_items,
          )
        }
      }

      setUnsavedChanges({
        [table]: { ...templateDetails[table], [UrlSubMenu]: payload },
        export_order_items: newExportOrderItems,
      })
      return setEnableSaveButton(true)
    }

    if (table === 'selectable_options') {
      setUnsavedChanges({
        services: {
          ...templateDetails.services,
          selectable: templateDetails.services.selectable.map((row) => {
            if (row.id === parentRow.id) {
              return {
                ...row,
                selectable_options: payload,
              }
            }

            return row
          }),
        },
      })
      return setEnableSaveButton(true)
    }

    if (table === 'tiers') {
      if (currentHeader.isTierRoot) {
        if (currentHeader.field === 'compound_tier_variable') {
          const newPayload = map((payloadRow) => {
            if (payloadRow.billing_item_id === currentRow.billing_item_id) {
              return {
                ...payloadRow,

                tiers: [],
              }
            }

            return payloadRow
          })(payload)

          setUnsavedChanges({ [table]: newPayload })
          return setEnableSaveButton(true)
        }

        setUnsavedChanges({ [table]: payload })
        return setEnableSaveButton(true)
      }

      if (parentRow === undefined) {
        setUnsavedChanges({ [table]: payload })
        return setEnableSaveButton(true)
      }

      const updatedTier = currentHeader.isIntervalChild
        ? parentRows.map((parent) => {
            if (parent.tiers.some((tier) => tier.code === currentRow.code)) {
              return { ...parent, tiers: payload }
            }
            return parent
          })
        : payload

      const updatedTiers = templateDetails.tiers.map((row) => {
        if (row.billing_item_id === parentRow.billing_item_id) {
          return {
            ...row,
            tiers: updatedTier,
          }
        }

        return row
      })

      setUnsavedChanges({ tiers: updatedTiers })
      return setEnableSaveButton(true)
    }

    setUnsavedChanges({ [table]: payload })
    return setEnableSaveButton(true)
  }

  function handleAddNewProdSavingsTable() {
    const props = {
      rows: [],
      rowIndex: 0,
      action: 'add-new-prod-savings-table',
    }

    handleAddOrRemoveRows({ ...props, table: 'productivity_savings' })
    setHasProdSavingsBeenDeleted(false)
  }

  function handleAddNewServicesTable() {
    const props = {
      rows: [],
      rowIndex: 0,
      action: 'add-line',
      subMenu: UrlSubMenu,
    }

    handleAddOrRemoveRows({ ...props, table: 'services' })
  }

  function handleAddNewMetricsTable() {
    const props = {
      rows: [],
      rowIndex: 0,
      action: 'add-line',
    }

    handleAddOrRemoveRows({ ...props, table: 'metrics' })
  }

  function handleAddNewTiersTable() {
    const props = {
      rows: [],
      rowIndex: 0,
      action: 'add-line',
    }

    handleAddOrRemoveRows({ ...props, table: 'tiers' })
  }

  function handleAddNewChargesTable() {
    const props = {
      rows: [],
      rowIndex: 0,
      action: 'add-line',
      subMenu: UrlSubMenu,
    }

    handleAddOrRemoveRows({ ...props, table: 'charges' })
  }

  async function handleSaveChanges() {
    setShowConfirmModal(false)

    if (['export_order_items', 'displayer_order_items'].includes(UrlViewMode)) {
      document.querySelector('#templateDetails').scrollLeft = 0
    }

    const requiredFields = pipe(
      filter(prop('isRequired')),
      pluck('field'),
    )(EXPORT_TABLE_HEADERS)

    const newDisplayOrderData = displayOrder.map((item, index) =>
      sanitizeRowBeforeSubmit({
        id: item.id,
        display_order: index,
      }),
    )

    const newIfsExportData = exports.map((item, index) =>
      sanitizeRowBeforeSubmit({
        ...pick([
          'id',
          'invoice_group',
          'customer_number',
          'billing_address',
          'export_comment',
          'export_function',
          'export_function_options',
        ])(item),
        export_order: index,
      }),
    )

    const newProdSavingsData = parseEmptyToNull(
      mergeAll(
        [prodSavings].map((item) =>
          sanitizeRowBeforeSubmit(
            pick([
              'driver_variables',
              'target_variables',
              'effective',
              'formula_name',
            ])(item),
          ),
        ),
      ),
    )

    if (!isNil(newProdSavingsData)) {
      const newProdSavingsValuesData = prodSavings.values.map((item) =>
        sanitizeRowBeforeSubmit(
          pick(['description', 'rate_code', 'driver_value', 'rate', 'date'])(
            item,
          ),
        ),
      )

      newProdSavingsData.values = newProdSavingsValuesData
    }

    const newMetricsData = parseMetricsBeforeSubmission(metrics)
    const newServicesData = parseServicesBeforeSubmission(servicesData)
    const newChargesData = parseChargesBeforeSubmission(chargesData)

    const newTiersData = tiers.map((item) =>
      sanitizeRowBeforeSubmit(omit(['billing_item'])(item)),
    )

    const hasMissingRequiredValues = pipe(
      chain(pluck(__, newIfsExportData)),
      either(any(isEmpty), any(isNil)),
    )(requiredFields)

    if (hasMissingRequiredValues) {
      return showErrorAlert({
        defaultMessage: 'Some required values are missing',
      })
    }

    if (
      Number(newIfsExportData.billing_address) ===
      Number(getYardCodeById({ yardList, yardId: selectedYard }))
    ) {
      return showErrorAlert({
        defaultMessage:
          'Billing address value must be different from PC number',
      })
    }

    setCreatingNewTemplate(true)

    const body = {
      name: nameValue,
      description: noteValue,
      start_date: dateValue,
      yard_id: selectedYard,
      display_order_data: newDisplayOrderData,
      export_order_data: newIfsExportData,
      prod_savings_data: hasProdSavingsBeenDeleted ? null : newProdSavingsData,
      metrics_data: newMetricsData,
      services_data: newServicesData,
      charges_data: newChargesData,
      tiers: isEmpty(newTiersData) ? null : newTiersData,
    }

    await onDisplayOrderUpdate(body)
      .unwrap()
      .then(() => {
        showAlert({
          type: 'success',
          message: `A new billing template was successfully created`,
        })

        resetSliceData()
        setCreatingNewTemplate(false)
        setEnableSaveButton(false)
        setIsEditMode(false)

        return history.push(returnUrl)
      })
      .catch((error) => {
        setCreatingNewTemplate(false)

        return showErrorAlert({
          error,
          defaultMessage: 'Failed to update display order',
        })
      })

    return null
  }

  const hasRequiredData = isEmpty(dateValue) || isEmpty(nameValue)

  const confirmModalProps = {
    visible: showConfirmModal,
    onConfirm: handleSaveChanges,
    onClose: () => setShowConfirmModal(false),
    onCancel: () => setShowConfirmModal(false),
    title: 'Confirm changes',
    message: 'Please complete the fields below:',
    type: 'date',
    confirmText: 'Save',
    cancelText: 'Cancel',
    hasRequiredData,
  }

  const sharedProps = {
    onValueChange: handleValueChange,
    handleAddOrRemoveRows,
    isLoading,
    timezone,
    setEnableSaveButton,
    creatingNewTemplate,
    isEditMode,
  }

  const tiersTableValues = {
    headers: TIERS_TABLE_HEADERS,
    rows: tiers,
    isLoading,
    table: UrlViewMode,
    showRowsControls: true,
    rawTemplateDetails,
    ...pick(['meta', 'front'])(templateDetails),
    ...sharedProps,
    action: isEditMode
      ? {
          text: `Add tiers values`,
          onClick: handleAddNewTiersTable,
        }
      : null,
  }

  const chargesTableValues = {
    hasInnerTabs: true,
    table: UrlViewMode,
    subMenu: UrlSubMenu,
    showRowsControls: true,
    hasHorizontalScroll: true,
    ...getChargesTableContent({ UrlSubMenu, data: templateDetails }),
    ...pick(['meta', 'front'])(templateDetails),
    ...sharedProps,
    rawTemplateDetails,
    action: isEditMode
      ? {
          text: `Add ${startCase(UrlSubMenu)} values`,
          onClick: handleAddNewChargesTable,
        }
      : null,
  }

  const servicesTableValues = {
    ...getServiceTableContent({ UrlSubMenu, data: templateDetails }),
    isLoading,
    timezone,
    creatingNewTemplate,
    hasInnerTabs: true,
    isEditMode,
    table: UrlViewMode,
    ...pick(['meta', 'front'])(templateDetails),
    subMenu: UrlSubMenu,
    onValueChange: handleValueChange,
    handleAddOrRemoveRows,
    handleDeleteSelectableOptions,
    showRowsControls: true,
    action: isEditMode
      ? {
          text: `Add ${startCase(UrlSubMenu)} values`,
          onClick: handleAddNewServicesTable,
        }
      : null,
  }

  const metricsTableValues = {
    headers: METRICS_TABLE_HEADERS,
    rows: metrics,
    isLoading,
    table: UrlViewMode,
    timezone,
    isEditMode,
    ...pick(['meta', 'front'])(templateDetails),
    rawTemplateDetails,
    onValueChange: handleValueChange,
    handleAddOrRemoveRows,
    showRowsControls: true,
    creatingNewTemplate,
    action: isEditMode
      ? {
          text: `Add metrics values`,
          onClick: handleAddNewMetricsTable,
        }
      : null,
  }

  function parseProdSavingsRows(isProdSavingsValues) {
    if (hasProdSavingsBeenDeleted || isEmpty(prodSavings)) {
      return []
    }

    if (isProdSavingsValues) {
      return prodSavings.values
    }

    return [prodSavings]
  }

  const prodSavingsTableProps = {
    headers: PROD_SAVINGS_TABLE_HEADERS,
    rows: parseProdSavingsRows(),
    table: UrlViewMode,
    ...pick(['meta', 'front'])(templateDetails),
    action: isEditMode
      ? {
          text: 'Add Productivity Savings',
          onClick: handleAddNewProdSavingsTable,
        }
      : null,
    ...sharedProps,
  }

  const prodSavingsTableValues = {
    headers: parsedProdSavingValuesHeaders,
    rows: parseProdSavingsRows(true),
    table: UrlViewMode,
    isProdSavingsValues: true,
    showRowsControls: true,
    ...sharedProps,
  }

  const exportTableValues = {
    headers: EXPORT_TABLE_HEADERS,
    rows: exports,
    enableDragAndDrop: isEditMode,
    onSortUpdate: handleSortUpdate,
    table: UrlViewMode,
    meta: templateDetails.meta,
    hasHorizontalScroll: true,
    ...sharedProps,
  }

  function renderProdSavings() {
    const showRemoveTable =
      isEditMode && !isEmpty(prodSavings) && !hasProdSavingsBeenDeleted
    const showValuesTable = !hasProdSavingsBeenDeleted && !isEmpty(prodSavings)
    const removeTableProps = {
      onClick: () => setShowDeleteProdSavingsModal(true),
      tableName: UrlViewMode,
    }

    return (
      <>
        {showRemoveTable && <RemoveTable {...removeTableProps} />}
        <S.DoubleTableContainer>
          <TableTemplateValues {...prodSavingsTableProps} />
          {showValuesTable && (
            <TableTemplateValues {...prodSavingsTableValues} />
          )}
        </S.DoubleTableContainer>
      </>
    )
  }

  function renderMetrics() {
    const showRemoveTable = isEditMode && !isEmpty(metricsTableValues.rows)
    const removeTableProps = {
      onClick: () => setShowDeleteMetricsModal(true),
      tableName: UrlViewMode,
    }

    return (
      <>
        {showRemoveTable && <RemoveTable {...removeTableProps} />}
        <TableTemplateValues {...metricsTableValues} />
      </>
    )
  }

  function renderTiers() {
    const showRemoveTable = isEditMode && !isEmpty(tiersTableValues.rows)
    const removeTableProps = {
      onClick: () => setShowDeleteTiersModal(true),
      tableName: UrlViewMode,
    }

    return (
      <>
        {showRemoveTable && <RemoveTable {...removeTableProps} />}
        <TableTemplateValues {...tiersTableValues} />
      </>
    )
  }

  function renderContent() {
    switch (UrlViewMode) {
      case VIEW_MODE.PROD_SAVINGS:
        return renderProdSavings()

      case VIEW_MODE.SERVICES:
        return <TableTemplateValues {...servicesTableValues} />

      case VIEW_MODE.CHARGES:
        return <TableTemplateValues {...chargesTableValues} />

      case VIEW_MODE.METRICS:
        return renderMetrics()

      case VIEW_MODE.EXPORT:
        return <TableTemplateValues {...exportTableValues} />

      case VIEW_MODE.DISPLAY_ORDER_ITEMS:
        return <TableTemplateValues {...displayOrderTableValues} />

      case VIEW_MODE.TIERS:
        return renderTiers()

      default:
        return null
    }
  }

  function getInnerTabsProps() {
    const items =
      UrlViewMode === VIEW_MODE.SERVICES
        ? SERVICES_INNER_TABS_OPTIONS
        : CHARGES_INNER_TABS_OPTIONS
    return {
      items,
      activeItem: UrlSubMenu,
      onChange: (value) =>
        history.push(
          `/yard/${selectedYard}/billing/templates/${
            isNewTemplate ? 'new' : `details/${templateId}`
          }/${UrlViewMode}/${value}?${returnDateUrl}`,
        ),
    }
  }

  function renderInnerTabs() {
    switch (UrlViewMode) {
      case VIEW_MODE.SERVICES: {
        const showRemoveTable = isEditMode && !isEmpty(servicesTableValues.rows)
        const removeTableProps = {
          onClick: () => setShowRemoveServicesTable(true),
          noMargin: true,
          tableName: UrlSubMenu,
        }

        return (
          <S.Flex $noGap>
            <NewTabs {...getInnerTabsProps()} />
            {showRemoveTable && <RemoveTable {...removeTableProps} />}
          </S.Flex>
        )
      }

      case VIEW_MODE.CHARGES: {
        const showRemoveTable = isEditMode && !isEmpty(chargesTableValues.rows)
        const removeTableProps = {
          onClick: () => setShowDeleteChargesModal(true),
          noMargin: true,
          tableName: UrlSubMenu,
        }

        return (
          <S.Flex $noGap>
            <NewTabs {...getInnerTabsProps()} />
            {showRemoveTable && <RemoveTable {...removeTableProps} />}
          </S.Flex>
        )
      }

      default:
        return null
    }
  }

  function handleFetchAffectedInvoices() {
    setSkipGetAffectedInvoices(false)
  }

  function renderAffectedInvoicesTable() {
    return (
      <>
        <span>The following invoices would be affected:</span>
        <S.Container $isAffectedValues>
          <S.Header>
            {AFFECTED_INVOICES_HEADERS.map((header, index) => (
              <S.Cell key={index}>
                <S.Text>{header.text}</S.Text>
              </S.Cell>
            ))}
          </S.Header>
          <S.Body>
            {affectedInvoices.map((row, rowIndex) => {
              const rowProps = {
                row: getAffectedInvoiceRow({ row, timezone }),
                rows: affectedInvoices,
                rowIndex,
                headers: AFFECTED_INVOICES_HEADERS,
                timezone,
              }

              return <TemplateValuesRow key={rowIndex} {...rowProps} />
            })}
          </S.Body>
        </S.Container>
        <S.Small>
          The status for all invoices would be changed to{' '}
          <BillingBadge text="draft" />{' '}
        </S.Small>
      </>
    )
  }

  const dates = isEmpty(datesForNewTemplate)
    ? templateDates
    : datesForNewTemplate

  const dateOptions = dates.map((date) => ({
    label: moment.tz(date, timezone).format('MM/DD/YYYY'),
    value: date,
  }))

  const today = moment.tz(timezone).startOf('day')
  const defaultDate = pipe(
    filter((dateString) => today.isSameOrAfter(dateString)),
    last,
  )(dates)

  return (
    <>
      <NewHeader {...headerProps} />
      <NewContainer>
        {renderInnerTabs()}
        {renderContent()}
      </NewContainer>
      <NewModal {...cancelEditModalProps} />
      <NewModal {...deleteProdSavingsModalProps} />
      <NewModal {...deleteServicesModalProps} />
      <NewModal {...deleteChargesModalProps} />
      <NewModal {...deleteMetricsModalProps} />
      <NewModal {...deleteTiersModalProps} />
      <NewModal {...confirmModalProps}>
        <Formik
          initialValues={{ templateDates: defaultDate, templateName: '' }}
          enableReinitialize
        >
          {({ values: formValues }) => {
            if (formValues.templateName !== nameValue) {
              setNameValue(formValues.templateName)
            }

            if (formValues.templateDates !== dateValue) {
              setDateValue(formValues.templateDates)
            }

            if (formValues.templateNote !== noteValue) {
              setNoteValue(formValues.templateNote)
            }

            return (
              <>
                <S.Flex>
                  <Field
                    type="text"
                    name="templateName"
                    placeholder="Add name"
                    component={FormItem}
                  />
                  <Field
                    type="select"
                    name="templateDates"
                    placeholder="Start date"
                    options={dateOptions}
                    component={FormItem}
                    onChange={handleFetchAffectedInvoices}
                  />
                </S.Flex>
                <Field
                  type="textarea"
                  name="templateNote"
                  placeholder="Add a note"
                  component={FormItem}
                />

                {!isEmpty(affectedInvoices) && renderAffectedInvoicesTable()}
              </>
            )
          }}
        </Formik>
      </NewModal>
    </>
  )
}

BillingTemplateDetails.propTypes = {
  selectedYard: PropTypes.number.isRequired,
  templateId: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  returnDate: PropTypes.string.isRequired,
  UrlViewMode: PropTypes.string.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  UrlSubMenu: PropTypes.string,
  setUnsavedChanges: PropTypes.func.isRequired,
  resetSliceData: PropTypes.func.isRequired,
  yardList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      code: PropTypes.number,
    }),
  ).isRequired,
}

BillingTemplateDetails.defaultProps = {
  UrlSubMenu: 'editable',
}

export default BillingTemplateDetails
