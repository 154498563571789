import { curry } from 'ramda'

function defaultToIf(condition, defaultValue, value) {
  if (condition(value)) {
    return defaultValue
  }

  return value
}

export default curry(defaultToIf)
