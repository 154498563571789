import * as yup from 'yup'

export const requiredMessage = 'Required'

export const fieldParameterListForMetric = [
  {
    name: 'default_display_name',
    validation: yup.string().required(requiredMessage),
    type: 'text',
    label: 'Display name',
    placeholder: 'Enter display name',
  },
  {
    name: 'code',
    validation: yup.string().required(requiredMessage),
    type: 'text',
    label: 'Billing Element',
    placeholder: 'Enter billing element code',
  },
]

export const fieldParameterListForService = [
  {
    name: 'default_display_name',
    validation: yup.string().required(requiredMessage),
    type: 'text',
    label: 'Display name',
    placeholder: 'Enter display name',
  },
  {
    name: 'code',
    validation: yup.string().required(requiredMessage),
    type: 'text',
    label: 'Billing Element',
    placeholder: 'Enter billing element code',
  },
  {
    name: 'description',
    validation: yup.string().required(requiredMessage),
    type: 'text',
    label: 'Description',
    placeholder: 'Enter description',
  },
  {
    name: 'price_uom',
    validation: yup.string().required(requiredMessage),
    type: 'select',
    label: 'Price UOM',
    placeholder: 'Enter price uom',
    options: [
      { value: 'MO', label: 'MO' },
      { value: 'EA', label: 'EA' },
      { value: 'HR', label: 'HR' },
    ],
  },
  {
    name: 'is_comment_required',
    validation: yup.bool(),
    type: 'bool',
    label: 'Comment Required',
    placeholder: 'Enter if comment is required',
  },
]

export const fieldParameterListForCharge = [
  {
    name: 'default_display_name',
    validation: yup.string().required(requiredMessage),
    type: 'text',
    label: 'Display name',
    placeholder: 'Enter display name',
  },
  {
    name: 'code',
    validation: yup.string().required(requiredMessage),
    type: 'text',
    label: 'Billing Element',
    placeholder: 'Enter billing element code',
  },
  {
    name: 'description',
    validation: yup.string().required(requiredMessage),
    type: 'text',
    label: 'Description',
    placeholder: 'Enter description',
  },
  {
    name: 'group',
    validation: yup.string().required(requiredMessage),
    type: 'select',
    label: 'Group',
    placeholder: 'Enter group code',
    options: [
      { value: 'FSC', label: 'FSC' },
      { value: 'PENALTY', label: 'PENALTY' },
    ],
  },
  {
    name: 'price_uom',
    validation: yup.string().required(requiredMessage),
    type: 'select',
    label: 'Price UOM',
    placeholder: 'Enter price uom',
    options: [
      { value: 'MO', label: 'MO' },
      { value: 'EA', label: 'EA' },
      { value: 'HR', label: 'HR' },
    ],
  },
  {
    name: 'is_comment_required',
    validation: yup.bool(),
    type: 'bool',
    label: 'Comment Required',
    placeholder: 'Enter if comment is required',
  },
]

export const unsavedChangesModalProps = {
  title: 'Do you really want to leave?',
  message: 'You have unsaved changes, do you want to leave without saving it?',
  confirmText: 'Keep editing',
  cancelText: 'Leave',
  type: 'warning',
  isMandatory: true,
}

export const confirmChangesModalProps = {
  title: 'Do you want to save?',
  message: 'Are you sure to submit those changes?',
  confirmText: 'Submit changes',
  cancelText: 'Review changes',
  type: 'warning',
  isMandatory: true,
}

export const TYPES_WITH_REQUIRED_COMMENT = ['service', 'charge']

export const FIELDS_TO_OMIT_FROM_UPDATE = [
  'id',
  'code',
  'type',
  'data',
  'deleted_at',
  'created_at',
  'updated_at',
]

export const IS_COMMENT_REQUIRED_DEFAULT_VALUE = false
