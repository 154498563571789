import React, { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  Loading,
  CommentManager,
  CommentDisplay,
  BillingTable,
  Blank,
} from '../../components'

import { parseFraction } from '../../utils'

import {
  viewMode,
  editValueAndCommentMode,
  editCommentMode,
  fractionDigits,
} from './constants'

import * as S from './styles'

const commentManagerInitialState = {
  screenX: 0,
  screenY: 0,
  column: 0,
  row: 0,
  value: '',
}

const commenDisplaytInitialState = {
  title: 'Comment',
  screenX: 0,
  screenY: 0,
}

const Table = ({
  isLoading,
  rows,
  columns,
  billingMode,
  tableTitle,
  colorByBillingMode,
  noTableToShow,
}) => {
  const [tableRows, setTableRows] = useState(rows)
  const [commentDisplayState, setCommentDisplayState] = useState(
    commenDisplaytInitialState,
  )
  const [commentManagerState, setCommentManagerState] = useState(
    commentManagerInitialState,
  )

  const status = 'unlocked'

  useEffect(() => {
    setCommentManagerState(commentManagerInitialState)
  }, [billingMode])

  useLayoutEffect(() => {
    setTableRows(rows)
  }, [rows])

  function handleChangeValue({
    target,
    rowIndex,
    columnIndex,
    isCommentRequired,
    isFloat,
  }) {
    const { value } = target

    const parsedValue = isFloat
      ? parseFraction({ value, fractionDigits })
      : parseInt(value, 10)

    const isNotNumber = _.isNaN(parsedValue)

    const modifiedRows = rows.map((singleRow, index) => {
      const currentRow = singleRow
      const yardServiceValue = currentRow.yardServiceValues[columnIndex]

      if (index === rowIndex) {
        yardServiceValue.value = isNotNumber ? '' : parsedValue
        yardServiceValue.currentEditing = true
      }

      if (
        !_.isNil(yardServiceValue) &&
        ((isCommentRequired && value === 0) || value === '') &&
        yardServiceValue.currentEditing
      ) {
        yardServiceValue.comment = null
      }

      return currentRow
    })

    setTableRows(modifiedRows)
  }

  function handleChangeComment() {
    const modifiedRows = rows.map((singleRow, index) => {
      const currentRow = singleRow

      if (index === commentManagerState.row) {
        currentRow.yardServiceValues[commentManagerState.column].comment =
          commentManagerState.value
        currentRow.yardServiceValues[
          commentManagerState.column
        ].currentEditing = true
      }

      return currentRow
    })

    setCommentManagerState(commentManagerInitialState)
    setTableRows(modifiedRows)
  }

  function handleShowCommentManager({ target, column, row, isRequired }) {
    const { left, top } = target.getBoundingClientRect()

    setCommentManagerState({
      screenX: left,
      screenY: top,
      column,
      row,
      value: _.get(rows, `[${row}].yardServiceValues[${column}].comment`, ''),
      subtitle: isRequired ? 'This is a client-facing comment' : null,
    })
  }

  function handleShowCommentDisplay({ event, columnIndex, rowIndex }) {
    const { target } = event
    const { left, top } = target.getBoundingClientRect()

    setCommentDisplayState({
      screenX: left,
      screenY: top,
      title: 'Comment',
      value: _.get(
        rows,
        `[${rowIndex}].yardServiceValues[${columnIndex}].comment`,
        '',
      ),
    })
  }

  const commentManagerProps = {
    title: 'Add comment',
    subtitle: commentManagerState.subtitle,
    screenX: commentManagerState.screenX,
    screenY: commentManagerState.screenY,
    value: commentManagerState.value,
    onChange: ({ target }) =>
      setCommentManagerState({
        ...commentManagerState,
        value: target.value,
      }),
    cancel: () => setCommentManagerState(commentManagerInitialState),
    submit: handleChangeComment,
  }

  const sorteredRows = tableRows.sort((a, b) => a.displayOrder - b.displayOrder)

  function renderBillingTable() {
    if (isLoading) {
      return <Loading />
    }

    if (noTableToShow) {
      return (
        <S.EmptyContainer>
          <Blank small subtitle="No billing items to show" />
        </S.EmptyContainer>
      )
    }

    return (
      <BillingTable
        fractionDigits={fractionDigits}
        columns={columns}
        rows={sorteredRows}
        isLoading={isLoading}
        editMode={billingMode}
        editModeColor={colorByBillingMode[billingMode]}
        tableTitle={tableTitle}
        status={status}
        handleClick={handleShowCommentManager}
        handleChange={handleChangeValue}
        handleMouseHover={handleShowCommentDisplay}
        handleMouseLeave={() =>
          setCommentDisplayState(commenDisplaytInitialState)
        }
        isEditModeActive={
          status === 'unlocked' &&
          billingMode !== viewMode &&
          billingMode === editValueAndCommentMode
        }
        isCommentModeEnabled={
          billingMode !== viewMode && billingMode === editCommentMode
        }
      />
    )
  }

  return (
    <>
      <S.ServiceGroup className={billingMode !== viewMode && 'edit-mode-table'}>
        {renderBillingTable()}
      </S.ServiceGroup>
      <CommentManager {...commentManagerProps} />
      <CommentDisplay {...commentDisplayState} />
    </>
  )
}

Table.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isFutureDate: PropTypes.bool,
      missingValues: PropTypes.bool,
      text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      billing_cycle: PropTypes.string,
      code: PropTypes.string,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          billing_template_item_id: PropTypes.number,
          comment: PropTypes.any,
          created_at: PropTypes.string,
          date: PropTypes.string,
          deleted_at: PropTypes.any,
          id: PropTypes.number,
          invoice_id: PropTypes.number,
          updated_at: PropTypes.string,
          user_id: PropTypes.any,
          value: PropTypes.any,
        }),
      ),
      customer_number: PropTypes.string,
      customer_yard_id: PropTypes.any,
      default_value: PropTypes.any,
      description: PropTypes.string,
      display_name: PropTypes.string,
      display_order: PropTypes.number,
      filling_type: PropTypes.string,
      formula_function: PropTypes.any,
      formula_options: PropTypes.any,
      group: PropTypes.string,
      id: PropTypes.number,
      is_comment_required: PropTypes.bool,
      is_daily: PropTypes.bool,
      po: PropTypes.any,
      price_uom: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  billingCycle: PropTypes.string.isRequired,
  billingMode: PropTypes.string.isRequired,
  tableTitle: PropTypes.string.isRequired,
  colorByBillingMode: PropTypes.shape({
    value: PropTypes.string,
    comment: PropTypes.string,
  }).isRequired,
  date: PropTypes.string.isRequired,
  yard: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  loadBillingTable: PropTypes.func.isRequired,
  loadBillingWeeks: PropTypes.func.isRequired,
  noTableToShow: PropTypes.bool.isRequired,
}

export default Table
