import { combineReducers } from '@reduxjs/toolkit'

import vcrList from './vcrList'
import issueRate from './issueRate'
import vcrData from './vcrData'
import vcrSortOption from './vcrSortOption'
import creatorList from './creatorList'
import vehicleList from './vehicleList'
import vcrSearchFilter from './vcrSearchFilter'
import headerReturnOptions from './headerReturnOptions'

const manageVCRs = combineReducers({
  vcrList,
  issueRate,
  vcrData,
  vcrSortOption,
  creatorList,
  vehicleList,
  vcrSearchFilter,
  headerReturnOptions,
})

export default manageVCRs
