import { connect } from 'react-redux'
import FormPage2 from './FormPage2'

import {
  loadAuditFuntionTypes,
  loadAuditViolationTypes,
} from '../../../../actions/upsertAuditActions'

const mapStateToProps = (state) => ({
  auditFunctionTypes: state.upsertAudit.auditFunctionTypes,
  auditViolationTypes: state.upsertAudit.auditViolationTypes,
})

const mapDispatchToProps = {
  loadAuditFuntionTypes,
  loadAuditViolationTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(FormPage2)
