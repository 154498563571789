import { connect } from 'react-redux'
import UpsertBillingCompany from './UpsertBillingCompany'

import { showAlert, showErrorAlert } from '../../actions/alertMessageActions'

const mapStateToProps = (state, ownProps) => ({
  companyId: ownProps.params.company_id,
})

const mapDispatchToProps = {
  showAlert,
  showErrorAlert,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpsertBillingCompany)
