import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNil } from 'ramda'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

import * as S from './styles'

import { getKeyValueByTable } from './helpers'
import { VIEWS_WITH_REQUIRED_ENABLED } from './constants'

import { Loading, Blank, NewToolTip } from '../../components'

import Row from './TemplateValuesRow'

const TableTemplateValues = ({
  headers,
  rows,
  parentRow,
  parentRows,
  isLoading,
  timezone,
  isProdSavingsValues,
  enableDragAndDrop,
  setEnableSaveButton,
  onSortUpdate,
  creatingNewTemplate,
  onValueChange,
  table,
  subMenu,
  ifsExportFunctions,
  hasHorizontalScroll,
  isEditMode,
  meta,
  action,
  hasInnerTabs,
  showRowsControls,
  handleAddOrRemoveRows,
  front,
  rawTemplateDetails,
  handleDeleteSelectableOptions,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const hasSelectableOptions = ['selectable_options', 'tiers'].includes(table)

  if (isLoading) {
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )
  }

  if (isEmpty(rows) || isNil(rows)) {
    return (
      <S.LoadingContainer>
        <Blank
          small
          title="This template seems empty"
          subtitle="There's no value to show"
          action={action}
        />
      </S.LoadingContainer>
    )
  }

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = rows.map((i) => i.id).indexOf(active.id)
      const newIndex = rows.map((i) => i.id).indexOf(over.id)

      const sortable = arrayMove(rows, oldIndex, newIndex)
      const rowsToUpdate = sortable.map((row, index) => ({
        ...row,
        has_been_moved: row.has_been_moved || index === newIndex,
        [getKeyValueByTable(table)]: index,
      }))
      onSortUpdate(rowsToUpdate)
      setEnableSaveButton(true)
    }
  }

  const dndContextProps = {
    sensors,
    collisionDetection: closestCenter,
    onDragEnd: handleDragEnd,
    modifiers: [restrictToVerticalAxis],
  }

  const sortableContextProps = {
    items: rows,
    disabled: !enableDragAndDrop,
    strategy: verticalListSortingStrategy,
  }

  return (
    <DndContext {...dndContextProps}>
      <SortableContext {...sortableContextProps}>
        <S.Container
          id="templateDetails"
          $templateValues={isProdSavingsValues}
          $isEditing={isEditMode}
          $hasHorizontalScroll={hasHorizontalScroll}
          $hasInnerTabs={hasInnerTabs}
          $isCreatingNewTemplate={creatingNewTemplate}
          $isSelectable={hasSelectableOptions}
        >
          <S.Header>
            {headers.map((header, index) => (
              <NewToolTip key={index} title={header.text} {...header.tooltip}>
                <S.Cell $width={hasHorizontalScroll ? header.width : null}>
                  <S.Text
                    title={header.text}
                    $isRequired={
                      VIEWS_WITH_REQUIRED_ENABLED.includes(table) &&
                      header.isRequired
                    }
                    strong
                  >
                    {header.text}
                  </S.Text>
                </S.Cell>
              </NewToolTip>
            ))}
          </S.Header>
          {creatingNewTemplate && (
            <S.LoadingContainer $fullSize={creatingNewTemplate}>
              <Loading />
              <p>Creating a new template</p>
            </S.LoadingContainer>
          )}
          <S.Body
            $isSelectable={hasSelectableOptions}
            id={
              hasSelectableOptions ? 'selectableOptions' : 'templateDetailsBody'
            }
          >
            {rows.map((row, rowIndex) => {
              const rowProps = {
                row,
                rows,
                parentRow,
                parentRows,
                rowIndex,
                headers,
                timezone,
                enableDragAndDrop,
                handleValueChange: onValueChange,
                ifsExportFunctions,
                hasHorizontalScroll,
                isEditMode,
                table,
                subMenu,
                meta,
                isProdSavingsValues,
                showRowsControls,
                handleAddOrRemoveRows,
                front,
                rawTemplateDetails,
                handleDeleteSelectableOptions,
              }

              const keyForTable = isNil(row.id)
                ? `index${rowIndex}`
                : `id${row.id}`

              const { uniqueTableName } = headers[0]

              const base = isNil(uniqueTableName)
                ? `table:${table}`
                : `uniqueTableName:${uniqueTableName}`

              const key = `${base}:${keyForTable}`

              return <Row key={key} {...rowProps} />
            })}
          </S.Body>
        </S.Container>
      </SortableContext>
    </DndContext>
  )
}

TableTemplateValues.propTypes = {
  headers: PropTypes.array,
  rows: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  isProdSavingsValues: PropTypes.bool,
  enableDragAndDrop: PropTypes.bool,
  creatingNewTemplate: PropTypes.bool,
  hasHorizontalScroll: PropTypes.bool,
  setEnableSaveButton: PropTypes.func,
  onSortUpdate: PropTypes.func,
  onValueChange: PropTypes.func,
  handleAddOrRemoveRows: PropTypes.func.isRequired,
  table: PropTypes.string,
  ifsExportFunctions: PropTypes.array,
  meta: PropTypes.object.isRequired,
  action: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  hasInnerTabs: PropTypes.bool,
  showRowsControls: PropTypes.bool,
  front: PropTypes.object,
  rawTemplateDetails: PropTypes.object,
  subMenu: PropTypes.string,
  handleDeleteSelectableOptions: PropTypes.func,
  parentRow: PropTypes.object,
  parentRows: PropTypes.array,
}

TableTemplateValues.defaultProps = {
  headers: [],
  rows: [],
  isProdSavingsValues: false,
  creatingNewTemplate: false,
  enableDragAndDrop: false,
  hasHorizontalScroll: false,
  setEnableSaveButton: null,
  onSortUpdate: () => {},
  onValueChange: () => {},
  table: null,
  ifsExportFunctions: [],
  action: null,
  hasInnerTabs: false,
  showRowsControls: false,
  front: {},
  rawTemplateDetails: {},
  subMenu: null,
  parentRow: {},
  parentRows: [],
  handleDeleteSelectableOptions: () => {},
}

export default TableTemplateValues
