import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import { browserHistory } from 'react-router'

import { pick } from 'ramda'
import * as S from './styles'

import {
  validationSchema,
  unsavedChangesModalProps,
  confirmChangesModalProps,
  formFields,
  returnPath,
  FIELDS_TO_UPDATE,
} from './constants'

import {
  useGetTemplateDetailsQuery,
  useUpdateTemplateDetailsMutation,
} from '../../services/billing'

import { NewContainer, NewHeader, NewModal } from '../../components'
import { history } from '../../utils'

const BillingTemplateEdit = ({
  showAlert,
  showErrorAlert,
  templateId,
  returnDate,
  selectedYard,
}) => {
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)
  const [showCofirmChangesModal, setShowCofirmChangesModal] = useState(false)

  const { data: templateData = {}, isLoading } = useGetTemplateDetailsQuery({
    templateId,
  })

  const [updateTemplate] = useUpdateTemplateDetailsMutation()

  const initialValues = isLoading
    ? validationSchema.cast()
    : pick(FIELDS_TO_UPDATE)(templateData.template)

  function handleBackToTemplateList() {
    return history.push({
      pathname: `/yard/${selectedYard}/billing/templates`,
      query: { return_date: returnDate },
    })
  }

  const headerProps = {
    title: 'Edit template',
    returnText: 'Back to template list',
    returnAction: handleBackToTemplateList,
  }

  const extraUnsavedModalProps = {
    onConfirm: () => setShowUnsavedChangesModal(false),
    onCancel: handleBackToTemplateList,
    visible: showUnsavedChangesModal,
  }

  const unsavedModalProps = {
    ...unsavedChangesModalProps,
    ...extraUnsavedModalProps,
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (body) =>
        updateTemplate({ body, templateId })
          .unwrap()
          .then(() => {
            showAlert({
              type: 'success',
              message: 'Template successfully updated',
            })

            return browserHistory.push(returnPath)
          })
          .catch((error) =>
            showErrorAlert({
              error,
              defaultMessage: 'Failed to update template',
            }),
          )
      }
    >
      {({ isSubmitting, dirty, handleSubmit }) => {
        const extraConfirmModalProps = {
          onConfirm: handleSubmit,
          onCancel: () => setShowCofirmChangesModal(false),
          visible: showCofirmChangesModal,
          isSubmitting,
        }

        const confirmModalProps = {
          ...confirmChangesModalProps,
          ...extraConfirmModalProps,
        }

        if (dirty) {
          headerProps.returnAction = () => setShowUnsavedChangesModal(true)
        }

        return (
          <>
            <NewHeader {...headerProps} />
            <NewContainer>
              <S.Wrapper>
                <S.StyledForm>
                  <S.Content>
                    {formFields.map((fieldProps, index) => (
                      <Field key={index} {...fieldProps} disabled={isLoading} />
                    ))}
                  </S.Content>

                  <S.StyledButton
                    type="submit"
                    text="Save"
                    isDisabled={isSubmitting || isLoading}
                  />
                </S.StyledForm>
              </S.Wrapper>
            </NewContainer>
            <NewModal {...unsavedModalProps} />
            <NewModal {...confirmModalProps} />
          </>
        )
      }}
    </Formik>
  )
}

BillingTemplateEdit.propTypes = {
  showAlert: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
  returnDate: PropTypes.string.isRequired,
  selectedYard: PropTypes.string.isRequired,
}

export default BillingTemplateEdit
