import React from 'react'
import PropTypes from 'prop-types'
import NewToolTip from '../NewToolTip'
import Loading from '../Loading'

import * as S from './styles'

const NewMetricsTable = ({ tooltip, columns, rows, isLoading }) => {
  const parsedColumns = [{ key: 'label', text: null }, ...columns]

  return (
    <NewToolTip {...tooltip}>
      <S.Container>
        {isLoading || !rows ? (
          <Loading />
        ) : (
          <>
            <S.Header length={parsedColumns.length}>
              {parsedColumns.map((column) => (
                <S.Cell key={column.key}>{column.text}</S.Cell>
              ))}
            </S.Header>

            <S.Body length={rows.length}>
              {rows.map((row) => (
                <S.Row length={parsedColumns.length} key={row.key}>
                  {parsedColumns.map((column) => (
                    <S.Cell key={column.key}>{row[column.key] || '-'}</S.Cell>
                  ))}
                </S.Row>
              ))}
            </S.Body>
          </>
        )}
      </S.Container>
    </NewToolTip>
  )
}

NewMetricsTable.propTypes = {
  columns: PropTypes.arrayOf(Object).isRequired,
  rows: PropTypes.arrayOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  tooltip: PropTypes.shape({
    actionType: PropTypes.string,
    titleIcon: PropTypes.string,
    title: PropTypes.string,
    action: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default NewMetricsTable
