import React from 'react'
import metricCardTypes from './props'
import NewToolTip from '../NewToolTip'
import { Container, IconWrapper, Icon, Title, StyledCountUp } from './styles'

const NewMetricCardItem = ({
  icon,
  title,
  value,
  isDecimal,
  format,
  description,
}) => (
  <NewToolTip title={title} description={description}>
    <Container>
      <IconWrapper>
        <Icon src={icon} alt="metric card icon" />
      </IconWrapper>
      <div>
        <Title>{title}</Title>
        <StyledCountUp
          end={value || 0}
          suffix={format}
          decimals={isDecimal ? 2 : 0}
          decimal="."
        />
      </div>
    </Container>
  </NewToolTip>
)

NewMetricCardItem.propTypes = metricCardTypes

NewMetricCardItem.defaultProps = {
  isDecimal: false,
  format: null,
}

export default NewMetricCardItem
