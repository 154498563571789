import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { update } from 'ramda'

import { EngineHoursTable, Blank, Loading } from '../../../components'

import {
  getEngineHoursTableRows,
  getEngineHoursTableColumns,
  getVehiclesByStatus,
} from '../helpers'

import * as S from '../styles'

const colorByEditMode = {
  view: '',
  value: '--base-blue',
  comment: '--base-green',
}

const EngineHours = ({
  data,
  yard,
  search,
  timezone,
  editMode,
  viewMode,
  updateEngineHours,
  loadEngineHours,
  loadMissingEngineHours,
  vehicleSort,
  canChangeAnyTime,
  date,
}) => {
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    loadEngineHours({
      yard,
      date: moment.tz(date, timezone).format('YYYY-MM-DD'),
    })

    loadMissingEngineHours({ yard })
  }, [
    viewMode,
    editMode,
    yard,
    timezone,
    loadEngineHours,
    loadMissingEngineHours,
    date,
  ])

  useEffect(() => {
    const dataColumns = _.get(data, 'value.months', [])
    const dataRows = _.get(data, 'value.rows', [])

    setRows(getEngineHoursTableRows({ rows: dataRows }))
    updateEngineHours(getEngineHoursTableRows({ rows: dataRows }))

    if (dataColumns.length > 0) {
      setColumns(
        getEngineHoursTableColumns({
          columns: dataColumns,
          rows: dataRows,
          timezone,
          canChangeAnyTime,
        }),
      )
    }
  }, [data, timezone, updateEngineHours, canChangeAnyTime])

  function handleChangeValue({ target, rowIndex, columnIndex }) {
    const { value } = target
    const parsedValue = parseInt(value, 10)
    const isNan = _.isNaN(parsedValue)

    const modifiedRows = rows.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          engineHoursValues: update(columnIndex, {
            ...row.engineHoursValues[columnIndex],
            value: isNan ? '' : parsedValue,
            isCurrentEditing: !isNan,
          })(row.engineHoursValues),
        }
      }

      return row
    })

    setRows(modifiedRows)
    updateEngineHours(modifiedRows)
  }

  if (_.isEmpty(rows)) {
    return <Blank />
  }

  const filteredRows = search
    ? rows.filter((vehicle) => vehicle.label.includes(search))
    : rows

  const parsedRows = getVehiclesByStatus({
    vehicles: filteredRows,
    vehicleSort,
  })

  if (_.isEmpty(parsedRows)) {
    return <Blank type="search" />
  }

  if (data.isLoading) {
    return <Loading />
  }

  return (
    <S.EngineHoursContainer>
      <EngineHoursTable
        columns={columns}
        rows={parsedRows}
        tableTitle="Vehicles"
        editMode={editMode}
        editModeColor={colorByEditMode[[editMode]]}
        isInputEnabled={editMode === 'value'}
        handleChange={handleChangeValue}
        isLoading={data.isLoading}
      />
    </S.EngineHoursContainer>
  )
}

EngineHours.propTypes = {
  data: PropTypes.shape({
    isLoading: PropTypes.bool,
    value: PropTypes.shape({
      last_updated: PropTypes.shape({
        user: PropTypes.shape({
          id: PropTypes.number,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
        }),
        updated_at: PropTypes.string,
      }),
      months: PropTypes.arrayOf(PropTypes.string),
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          values: PropTypes.arrayOf(
            PropTypes.shape({
              value: PropTypes.number,
              comment: PropTypes.string,
            }),
          ),
          vcr_submission: PropTypes.shape({
            submitted_at: PropTypes.string,
            engine_hours: PropTypes.number,
          }),
          vehicle: PropTypes.shape({
            id: PropTypes.number,
            vehicle_name: PropTypes.string,
          }),
        }),
      ),
    }),
  }).isRequired,
  timezone: PropTypes.string.isRequired,
  editMode: PropTypes.oneOf(['view', 'value']).isRequired,
  updateEngineHours: PropTypes.func.isRequired,
  loadEngineHours: PropTypes.func.isRequired,
  loadMissingEngineHours: PropTypes.func.isRequired,
  search: PropTypes.string,
  yard: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  viewMode: PropTypes.oneOf(['vehicles-list', 'engine-hours']).isRequired,
  vehicleSort: PropTypes.string.isRequired,
  canChangeAnyTime: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
}

EngineHours.defaultProps = {
  search: null,
}

export default EngineHours
