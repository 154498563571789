import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

import iconComment from '../../images/icons/icon_comment_state.svg'

const CommentManager = ({
  screenX,
  screenY,
  title,
  subtitle,
  value,
  onChange,
  cancel,
  submit,
}) => {
  const textAreaRef = useRef()

  useEffect(() => {
    if (textAreaRef.current) textAreaRef.current.focus()
  }, [screenX, screenY])

  return (
    <S.Container screenX={screenX} screenY={screenY}>
      <S.Title>
        <S.Icon src={iconComment} />
        {title}
      </S.Title>
      {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
      <S.Content>
        <S.TextArea
          value={value || ''}
          onChange={onChange}
          placeholder="Type here"
          maxLength="180"
          ref={textAreaRef}
        />
        <S.ActionWrapper>
          <S.Button onClick={cancel} cancel>
            Cancel
          </S.Button>
          <S.Button onClick={submit}>Comment</S.Button>
        </S.ActionWrapper>
      </S.Content>
    </S.Container>
  )
}

CommentManager.propTypes = {
  screenX: PropTypes.number.isRequired,
  screenY: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cancel: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

CommentManager.defaultProps = {
  value: '',
  subtitle: null,
}

export default CommentManager
