import { yardSummaryTypes } from '../../actions/yardSummaryActions'

const initialState = {
  total: 0,
}

const activeTimeFromSessions = (state = initialState, { type, payload }) => {
  switch (type) {
    case yardSummaryTypes.ACTIVE_TIME_FROM_SESSIONS_LOADED:
      return {
        total: payload.total,
      }

    case yardSummaryTypes.ACTIVE_TIME_FROM_SESSIONS_REQUESTED:
    case yardSummaryTypes.ACTIVE_TIME_FROM_SESSIONS_FAILED:
    default:
      return state
  }
}

export default activeTimeFromSessions
