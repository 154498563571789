import React, { useEffect } from 'react'

import { Router, IndexRedirect, Route, IndexRoute } from 'react-router'

import { path } from 'ramda'
import { history } from '../utils'

import {
  Login,
  App,
  ManageVcrsContainer,
  VCR,
  ManageVehicles,
  Tracking,
  VehicleDetails,
  WorkerProfile,
  PrintVCR,
  SafetyReports,
  SearchVCR,
  UpsertAudit,
  CreateUser,
  UpsertVehicle,
  SearchAudit,
  AllYards,
  ForgotPassword,
  PasswordReset,
  Billing,
  VcrsRoot,
  Analytics,
  BillingSubmit,
  PrintAudits,
  ManagePeople,
  BillingTemplateVersions,
  BillingTemplateDetails,
  BillingElements,
  UpsertYard,
  UpsertBillingCustomer,
  UpsertBillingCompany,
  ManageCompanies,
  ManageCustomers,
  BillingTemplateEdit,
  UpsertBillingElementType,
} from '../containers'
import withAuthorization from '../hocs/withAuthorization'

import { Root } from '../components'
import requireAnonymous from './middlewares/requireAnonymous'
import requireAuth from './middlewares/requireAuth'
import { handleLeave, handleEnter } from './middlewares/handleNonExistentRoute'
import {
  allRoles,
  rolePermission,
  ALL_YARDS_ROLES,
  AUDITS_ROLES,
  BILLING_ROLES,
  ANALYTICS_ROLES,
  VEHICLES_ROLES,
  WORKERS_ROLES,
  YARD_MAP_ROLES,
} from '../config'
import {
  AUDITS_WRITE_ROLES,
  CRUD_YARD_WRITE_ROLES,
} from '../permissions/config'
import store from '../store'

const withYardMapRoles = withAuthorization(YARD_MAP_ROLES)
const withVehiclesRoles = withAuthorization(VEHICLES_ROLES)
const withWorkersRoles = withAuthorization(WORKERS_ROLES)
const withAuditsRoles = withAuthorization(AUDITS_ROLES)
const withAuditsWriteRoles = withAuthorization(AUDITS_WRITE_ROLES)
const withAllYardsRoles = withAuthorization(ALL_YARDS_ROLES)
const withBillingRoles = withAuthorization(BILLING_ROLES)
const withCrudYardRoles = withAuthorization(CRUD_YARD_WRITE_ROLES)
const withAnalyticsRoles = withAuthorization(ANALYTICS_ROLES)
const allRolesWithAuthorization = withAuthorization(allRoles)

function RedirectIndexByRole() {
  const { userData } = store.getState().general

  const roleName = path(['Role', 'name'])(userData)
  const { yard_id: yardId } = userData

  const { homeURL } = rolePermission[roleName]

  useEffect(() => {
    history.push(`/yard/${yardId}${homeURL}`)
  }, [yardId, homeURL])

  return null
}

const routes = (
  <Router>
    <Route path="/" component={Root}>
      <IndexRedirect to="login" />
      <Route path="password-reset/:token" component={PasswordReset} />
      <Route path="/" onEnter={requireAnonymous}>
        <Route path="login" component={Login} />
        <Route path="forgot-password" component={ForgotPassword} />
      </Route>
      <Route path="/" onEnter={requireAuth}>
        <Route
          path="/yard/:yardId"
          component={allRolesWithAuthorization(App)}
          onLeave={handleLeave}
        >
          <IndexRoute component={RedirectIndexByRole} />
          <Route
            path="tracking(/mode/:url_view_mode(/time/:time(/user/:user_id)(/vehicle/:vehicle_id)))"
            component={withYardMapRoles(Tracking)}
          />
          <Route path="users" component={withWorkersRoles(ManagePeople)} />
          <Route
            path="users/:user_id/summary/date/:start_date"
            component={withWorkersRoles(WorkerProfile)}
          />
          <Route path="users/create" component={withWorkersRoles(CreateUser)} />
          <Route
            path="users/:user_id"
            component={withWorkersRoles(CreateUser)}
          />
          <Route
            path="users/view/:user_id"
            component={withWorkersRoles(CreateUser)}
          />
          <Route
            path="vehicles"
            component={withVehiclesRoles(ManageVehicles)}
          />
          <Route
            path="vehicles/details/:vehicle_id"
            component={withVehiclesRoles(VehicleDetails)}
          />
          <Route
            path="vehicles/details/:vehicle_id/edit"
            component={withVehiclesRoles(UpsertVehicle)}
          />
          <Route
            path="vehicles/create"
            component={withVehiclesRoles(UpsertVehicle)}
          />
          <Route path="vcrs" component={withVehiclesRoles(VcrsRoot)}>
            <IndexRoute component={ManageVcrsContainer} />
            <Route path="search" component={SearchVCR} />
            <Route path=":vcr_id" component={VCR} />
            <Route path=":vcr_id/print" component={PrintVCR} />
          </Route>
          <Route path="audits" component={withAuditsRoles(SafetyReports)} />
          <Route path="audits/print" component={withAuditsRoles(PrintAudits)} />
          <Route
            path="audits/search"
            component={withAuditsRoles(SearchAudit)}
          />
          <Route
            path="audits/create"
            component={withAuditsWriteRoles(UpsertAudit)}
          />
          <Route
            path="audits/:audit_id"
            component={withAuditsRoles(UpsertAudit)}
          />
          <Route path="allYards" component={AllYards} />
          <Route
            path="allYards(/date/:date)"
            component={withAllYardsRoles(AllYards)}
          />
          <Route
            path="allYards/create(/:yard_id)"
            component={withCrudYardRoles(UpsertYard)}
          />
          <Route
            path="allYards/companies"
            component={withAllYardsRoles(ManageCompanies)}
          />
          <Route
            path="allYards/companies/create(/:company_id)"
            component={withCrudYardRoles(UpsertBillingCompany)}
          />
          <Route
            path="allYards/customers"
            component={withAllYardsRoles(ManageCustomers)}
          />
          <Route
            path="allYards/customers/create(/:customer_id)"
            component={withCrudYardRoles(UpsertBillingCustomer)}
          />
          <Route
            path="billing(/date/:date)"
            component={withBillingRoles(Billing)}
          />
          <Route
            path="billing/submit/:invoice_id(/:sub_menu)"
            component={withBillingRoles(BillingSubmit)}
          />
          <Route
            path="billing/templates"
            component={withBillingRoles(BillingTemplateVersions)}
          />
          <Route
            path="billing/templates/edit"
            component={withBillingRoles(BillingTemplateEdit)}
          />
          <Route
            path="billing/templates/details/:template_id(/:view_mode(/:sub_menu))"
            component={withBillingRoles(BillingTemplateDetails)}
          />
          <Route
            path="billing/templates/new(/:view_mode(/:sub_menu))"
            component={withBillingRoles(BillingTemplateDetails)}
          />
          <Route
            path="billing/items(/:sub_menu)"
            component={withBillingRoles(BillingElements)}
          />
          <Route
            path="billing/items/create/:billing_element_type"
            component={withCrudYardRoles(UpsertBillingElementType)}
          />
          <Route
            path="billing/items/edit/:billing_element_type/:billing_element_id"
            component={withCrudYardRoles(UpsertBillingElementType)}
          />
          <Route
            path="analytics(/:view_mode(/:sub_menu))"
            component={withAnalyticsRoles(Analytics)}
          />
        </Route>
      </Route>
    </Route>
    <Route path="*" onEnter={handleEnter} />
  </Router>
)

export default routes
