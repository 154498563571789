import clientApi from './utils/clientApi'

const getAll = ({ yardId }) =>
  clientApi({
    method: 'get',
    url: '/zones',
    params: {
      yard_id: yardId,
    },
  }).then(({ data }) => data)

const zones = {
  getAll,
}

export default zones
