import React, { useEffect } from 'react'
import { GoogleMap, useLoadScript } from '@react-google-maps/api'
import PropTypes from 'prop-types'

import { GOOGLE_API_KEY } from '../../config'
import MapStyle from './JSON_map.json'
import './Map.css'

const mapContainerStyle = {
  height: '100vh',
  width: '100%',
}

const Map = ({
  yard,
  mapCenter,
  zoomLevel,
  setZoomLevel,
  setCenter,
  children,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100vh' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  })

  useEffect(() => {
    setCenter({
      lat: yard.latitude,
      lng: yard.longitude,
    })
  }, [isLoaded, yard.latitude, yard.longitude, setCenter])

  useEffect(() => {
    setZoomLevel(yard.zoom_level)
  }, [isLoaded, yard.zoom_level, setZoomLevel])

  const renderMap = () => {
    const defaultOptions = {
      styles: MapStyle,
      streetViewControl: false,
      gestureHandling: 'greedy',
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    }

    return (
      <GoogleMap
        center={mapCenter}
        zoom={zoomLevel}
        options={defaultOptions}
        mapContainerStyle={mapContainerStyle}
      >
        {children}
      </GoogleMap>
    )
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : null
}

Map.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  setZoomLevel: PropTypes.func.isRequired,
  setCenter: PropTypes.func.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  yard: PropTypes.shape({
    zoom_level: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }),
  mapCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
}

Map.defaultProps = {
  yard: undefined,
  children: null,
}

export default Map
