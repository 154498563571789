import { safetyReportsTypes } from '../../actions/safetyReportsActions'

const initialState = {
  value: {},
}

const worker = (state = initialState, { type, payload }) => {
  switch (type) {
    case safetyReportsTypes.SELECTED_USER_SET:
      return {
        ...state,
        value: payload.user,
      }
    case safetyReportsTypes.SELECTED_USER_RESET:
      return initialState
    default:
      return state
  }
}

export default worker
