import { manageVcrsTypes } from '../../actions/manageVCRsActions'

const initialState = {
  path: '/vcrs',
  text: "VCR's",
}

const headerReturnOptions = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVcrsTypes.HEADER_RETURN_OPTIONS_SET:
      return payload

    case manageVcrsTypes.HEADER_RETURN_OPTIONS_RESET:
      return initialState

    default:
      return state
  }
}

export default headerReturnOptions
