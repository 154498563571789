import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const NavigationItem = ({
  handleNavigation,
  currentBasePathname,
  routeBasePathname,
  routePathname,
  routeQuery,
  routeAction,
  icon,
  label,
  disabled,
  onMouseEnter,
}) => {
  const routeParams = {
    path: routePathname,
    query: routeQuery,
    action: routeAction,
  }

  return (
    <S.NavigationItem
      onClick={() => handleNavigation(routeParams)}
      onMouseEnter={onMouseEnter}
      isActive={currentBasePathname === routeBasePathname}
      disabled={disabled}
    >
      <S.Icon src={icon} />
      <S.Label>{label}</S.Label>
    </S.NavigationItem>
  )
}

NavigationItem.propTypes = {
  handleNavigation: PropTypes.func.isRequired,
  currentBasePathname: PropTypes.string.isRequired,
  routeBasePathname: PropTypes.string.isRequired,
  routePathname: PropTypes.string.isRequired,
  routeQuery: PropTypes.object,
  routeAction: PropTypes.string,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onMouseEnter: PropTypes.func,
}

NavigationItem.defaultProps = {
  routeQuery: {},
  routeAction: null,
  disabled: false,
  onMouseEnter: null,
}

export default NavigationItem
