import { Promise } from 'bluebird'
import * as yup from 'yup'

export function getInitialValuesToEdit(yardData) {
  return yardData
}

export function onFormSubmit({ values, timezone, hasYardtoEdit }) {
  Promise.resolve().then(() => ({ values, timezone, hasYardtoEdit }))
}

export function getValidationSchema(fields) {
  const validationSchema = yup
    .object()
    .shape(
      Object.assign(
        {},
        ...fields.map((item) => ({ [item.name]: item.validation })),
      ),
    )

  return validationSchema
}

export function getInitialFormFields(fields) {
  return fields.map((field) => {
    const { validation, ...rest } = field
    return { ...rest }
  })
}
