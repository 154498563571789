import store from '../../store'
import { verifyToken } from '../../actions/generalActions'
import { getBaseUrlByUser } from '../../utils'

const requireAuth = (nextState, replace, callback) => {
  const { authenticated } = store.getState().general
  const { pathname } = nextState.location

  if (!authenticated) {
    store
      .dispatch(verifyToken())
      .then(({ user }) => {
        if (pathname === '/') {
          replace(getBaseUrlByUser(user))
        }
        callback()
        return null
      })
      .catch(() => {
        replace('/login')
        callback()
        return null
      })
  } else {
    callback()
  }
}

export default requireAuth
