import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { isNil, includes } from 'ramda'
import * as yup from 'yup'
import Promise from 'bluebird'
import moment from 'moment-timezone'
import { browserHistory } from 'react-router'
import { Formik, Field } from 'formik'

import {
  VEHICLE_UPSERT_WRITE_ROLES,
  VEHICLE_YARD_CHANGE_ROLES,
} from '../../permissions/config'

import { FormItem, NewContainer, NewHeader, NewModal } from '../../components'

import {
  vehicleTypeIdNameMap,
  CAN_MOVE_VEHICLE_TO_ANY_YARD_ROLES,
} from '../../config'

import { useHasVehicleAccess } from '../../hooks'

import { isRequiredWhen } from '../../utils'

import * as S from './styles'

const vehicleTypeList = _.values(vehicleTypeIdNameMap).map((vehicleType) => ({
  value: vehicleType,
  label: vehicleType,
}))

const requireMessage = 'Required'

const UpsertVehicle = ({
  vehicleIdParam,
  loadVehicle,
  resetVehicle,
  saveVehicle,
  showAlert,
  vehicleData,
  timezone,
  yardList,
  allYardList,
  selectedYard,
  createVehicle,
  userRole,
}) => {
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)
  const [showCofirmChangesModal, setShowCofirmChangesModal] = useState(false)

  const hasVehicleToEdit = !isNil(vehicleIdParam)
  const vehicleInfo = _.get(vehicleData, 'vehicle')

  const hasVehicleData = !_.isEmpty(vehicleInfo)
  const vehicleName = _.get(vehicleInfo, 'vehicle_name')

  const hasUpsertWriteAccess = includes(userRole, VEHICLE_UPSERT_WRITE_ROLES)
  const hasYardChangeWriteAccess = includes(userRole, VEHICLE_YARD_CHANGE_ROLES)
  const userCanMoveVehicleToAnyYard = includes(
    userRole,
    CAN_MOVE_VEHICLE_TO_ANY_YARD_ROLES,
  )
  const parsedYardList = (userCanMoveVehicleToAnyYard
    ? allYardList
    : yardList
  ).map((yard) => ({
    value: yard.id,
    label: `${yard.name} | ${yard.code}`,
  }))

  useHasVehicleAccess({ vehicleData, yardList })

  useEffect(() => {
    if (hasVehicleToEdit) {
      loadVehicle(Number(vehicleIdParam))
    }

    return resetVehicle()
  }, [hasVehicleToEdit, loadVehicle, resetVehicle, vehicleIdParam])

  function parseVehicleDate(date) {
    if (isNil(date)) {
      return null
    }

    return moment.tz(date, timezone)
  }

  const isRequiredWhenHasUpsertWriteAccess = isRequiredWhen(
    requireMessage,
    hasUpsertWriteAccess,
  )
  const validationSchema = yup.object().shape({
    vehicleName: isRequiredWhenHasUpsertWriteAccess(yup.string()),
    yardId: isRequiredWhenHasUpsertWriteAccess(
      yup.number().integer().positive().nullable(),
    ),
    vehicleType: isRequiredWhenHasUpsertWriteAccess(yup.string().nullable()),
    year: isRequiredWhenHasUpsertWriteAccess(
      yup
        .number()
        .integer()
        .nullable()
        .moreThan(1500)
        .lessThan(Number(moment().add(5, 'years').format('YYYY'))),
    ),
    purchaseDate: isRequiredWhenHasUpsertWriteAccess(yup.date().nullable()),
    make: isRequiredWhenHasUpsertWriteAccess(yup.string().nullable()),
    model: isRequiredWhenHasUpsertWriteAccess(yup.string().nullable()),
    rebuiltDate: yup.date().nullable(),
  })

  const initialValues = hasVehicleToEdit
    ? {
        vehicleName: _.get(vehicleInfo, 'vehicle_name', ''),
        yardId: _.get(vehicleInfo, 'yard_id', selectedYard),
        vehicleType:
          vehicleTypeIdNameMap[_.get(vehicleInfo, 'vehicle_type_id')],
        year: _.get(vehicleInfo, 'year'),
        purchaseDate: parseVehicleDate(_.get(vehicleInfo, 'purchase_date')),
        make: _.get(vehicleInfo, 'make'),
        model: _.get(vehicleInfo, 'model'),
        idrive_code: _.get(vehicleInfo, 'idrive_code'),
        rebuiltDate: parseVehicleDate(_.get(vehicleInfo, 'rebuilt_date')),
      }
    : validationSchema.cast()

  const createVehicleHeaderProps = {
    title: 'Create vehicle',
    returnText: 'Back to vehicle list',
    returnUrl: '/vehicles',
  }

  const editVehicleHeaderProps = {
    title: hasVehicleData ? `Edit vehicle ${vehicleName}` : 'Loading',
    returnText: hasVehicleData ? `Back to ${vehicleName} details` : 'Loading',
    returnUrl: `/vehicles/details/${vehicleIdParam}`,
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values) =>
        Promise.resolve().then(() => {
          if (!showCofirmChangesModal) {
            return setShowCofirmChangesModal(true)
          }

          const formData = _.chain(values)
            .mapValues((value) => {
              if (typeof value === 'string') {
                return value.trim()
              }

              if (moment.isMoment(value)) {
                return value.format()
              }

              return value
            })
            .value()

          const promise = hasVehicleToEdit
            ? saveVehicle({ formData, vehicleId: vehicleIdParam })
            : createVehicle({ formData })

          return promise
            .then(() => {
              showAlert({
                type: 'success',
                message: hasVehicleToEdit
                  ? 'Vehicle updated successfully'
                  : 'Vehicle created successfully',
              })
              browserHistory.push(
                hasVehicleToEdit
                  ? `/vehicles/details/${vehicleIdParam}`
                  : '/vehicles',
              )
            })
            .catch(({ response }) => {
              showAlert({
                type: 'failure',
                message:
                  _.capitalize(_.get(response, 'data.errors[0].message')) ||
                  'Something unexpected happened',
              })
            })
        })
      }
    >
      {({ isSubmitting, dirty, handleSubmit }) => {
        const headerProps = hasVehicleToEdit
          ? editVehicleHeaderProps
          : createVehicleHeaderProps

        const returnUrl = _.get(headerProps, 'returnUrl')

        const confirmChangesModalProps = {
          title: 'Do you want to save?',
          message: 'Are you sure to submit those changes?',
          confirmText: 'Submit changes',
          cancelText: 'Review changes',
          type: 'warning',
          isMandatory: true,
          onConfirm: handleSubmit,
          onCancel: () => setShowCofirmChangesModal(false),
          visible: showCofirmChangesModal,
          isSubmitting,
        }

        const unsavedChangesModalProps = {
          title: 'Do you really want to leave?',
          message:
            'You have unsaved changes, do you want to leave without saving it?',
          confirmText: 'Keep editing',
          cancelText: 'Leave',
          type: 'warning',
          isMandatory: true,
          onConfirm: () => setShowUnsavedChangesModal(false),
          onCancel: () => browserHistory.push(returnUrl),
          visible: showUnsavedChangesModal,
        }

        if (dirty) {
          headerProps.returnAction = () => setShowUnsavedChangesModal(true)
        }

        const isSubmitEnabled =
          !isSubmitting &&
          (hasUpsertWriteAccess ||
            (hasVehicleToEdit && hasYardChangeWriteAccess))

        return (
          <>
            <NewHeader {...headerProps} />
            <NewContainer>
              <S.Wrapper>
                <S.StyledForm>
                  <S.Content>
                    <div>
                      <Field
                        type="text"
                        name="vehicleName"
                        label="Vehicle Name"
                        component={FormItem}
                        disabled={!hasUpsertWriteAccess}
                      />
                      <Field
                        type="select"
                        name="vehicleType"
                        label="Vehicle Type"
                        placeholder="Select vehicle Type"
                        options={vehicleTypeList}
                        component={FormItem}
                        isDisabled={!hasUpsertWriteAccess}
                      />
                      <Field
                        type="number"
                        name="year"
                        label="Year"
                        component={FormItem}
                        disabled={!hasUpsertWriteAccess}
                      />
                    </div>

                    <div>
                      <Field
                        type="datepicker"
                        name="purchaseDate"
                        label="Purchase Date"
                        component={FormItem}
                        timezone={timezone}
                        disabled={!hasUpsertWriteAccess}
                      />
                      <Field
                        type="text"
                        name="idrive_code"
                        label="iDrive"
                        component={FormItem}
                        disabled={!hasUpsertWriteAccess}
                      />
                      <Field
                        type="select"
                        name="yardId"
                        label="Yard"
                        placeholder="Select yard"
                        component={FormItem}
                        options={parsedYardList}
                        isDisabled={
                          hasVehicleToEdit
                            ? !hasYardChangeWriteAccess
                            : !hasUpsertWriteAccess
                        }
                      />
                    </div>

                    <div>
                      <Field
                        type="text"
                        name="make"
                        label="Make"
                        component={FormItem}
                        disabled={!hasUpsertWriteAccess}
                      />
                      <Field
                        type="text"
                        name="model"
                        label="Model"
                        component={FormItem}
                        disabled={!hasUpsertWriteAccess}
                      />
                      <Field
                        type="datepicker"
                        name="rebuiltDate"
                        label="Rebuilt Date (optional)"
                        component={FormItem}
                        timezone={timezone}
                        disabled={!hasUpsertWriteAccess}
                      />
                    </div>
                  </S.Content>

                  <S.StyledButton
                    type="submit"
                    text="Save"
                    isDisabled={!isSubmitEnabled}
                  />
                </S.StyledForm>
              </S.Wrapper>
            </NewContainer>
            <NewModal {...unsavedChangesModalProps} />
            <NewModal {...confirmChangesModalProps} />
          </>
        )
      }}
    </Formik>
  )
}

UpsertVehicle.propTypes = {
  vehicleIdParam: PropTypes.number,
  loadVehicle: PropTypes.func.isRequired,
  resetVehicle: PropTypes.func.isRequired,
  saveVehicle: PropTypes.func.isRequired,
  vehicleData: PropTypes.shape({
    vehicle_name: PropTypes.string.isRequired,
  }).isRequired,
  showAlert: PropTypes.func.isRequired,
  createVehicle: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  yardList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      code: PropTypes.number,
    }),
  ).isRequired,
  allYardList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      code: PropTypes.number,
    }),
  ).isRequired,
  selectedYard: PropTypes.number.isRequired,
  userRole: PropTypes.string,
}

UpsertVehicle.defaultProps = {
  vehicleIdParam: null,
  userRole: null,
}

export default UpsertVehicle
