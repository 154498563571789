import _ from 'lodash'
import Promise from 'bluebird'
import clientApi from './utils/clientApi'

const getAll = ({ limit, offset, vehicleId }) => {
  const validate = () => {
    if (_.isNil(limit)) {
      throw new Error('Missing limit')
    }
    if (_.isNil(offset)) {
      throw new Error('Missing offset')
    }
    if (_.isNil(vehicleId)) {
      throw new Error('Missing vehicleId')
    }
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: `/vehicles/${vehicleId}/comments`,
    params: {
      limit,
      offset,
    },
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const vehicleComments = {
  getAll,
}

export default vehicleComments
