import { isNil } from 'lodash'
import Promise from 'bluebird'
import clientApi from './utils/clientApi'

const getTurnAroundTime = ({ yard, startDate, endDate }) => {
  const validate = () => {
    if (isNil(yard)) {
      throw new Error('Missing Yard ID')
    }
    if (isNil(startDate)) {
      throw new Error('Missing start date')
    }

    if (isNil(endDate)) {
      throw new Error('Missing end date')
    }
  }

  const params = {
    yard_id: yard,
    start_date: startDate,
    end_date: endDate,
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: '/detected_moves/turn_around_time',
    params,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const detectedMoves = {
  getTurnAroundTime,
}

export default detectedMoves
