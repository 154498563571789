import React, { Component } from 'react'
import PropTypes from 'prop-types'
import onClickOutsideHOC from 'react-onclickoutside'

class ItemList extends Component {
  static propTypes = {
    onClickOutside: PropTypes.func.isRequired,
    onItemClick: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }

  handleClickOutside = () => {
    const { onClickOutside } = this.props
    onClickOutside()
  }

  render() {
    const { items, onItemClick } = this.props

    return (
      <div className="menu-container">
        <ul>
          {items.map((item) => (
            <li key={item.label} onClick={onItemClick.bind(null, item)}>
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default onClickOutsideHOC(ItemList)
