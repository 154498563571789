import {
  pipe,
  prop,
  isNil,
  pluck,
  reject,
  uniqBy,
  sortBy,
  prepend,
  append,
} from 'ramda'

export default function getFilterArray({ list, field }) {
  return pipe(
    pluck(field),
    uniqBy(prop('id')),
    reject(isNil),
    sortBy(prop('display_order')),
    prepend({
      id: 'ALL',
      name: 'All',
    }),
    append({
      id: 'OTHER',
      name: 'Other',
    }),
  )(list)
}
