import Promise from 'bluebird'
import api from '../api'
import { createTypes } from './utils'

export const forgotPasswordTypes = createTypes('forgotPassword', [
  { value: 'CREATE_PASSWORD_TOKEN', isAsync: true },
])

export const createPasswordToken = (params) => (dispatch) => {
  dispatch({ type: forgotPasswordTypes.CREATE_PASSWORD_TOKEN_REQUESTED })

  return Promise.resolve(params)
    .then(api.passwordToken.create)
    .then(() => {
      dispatch({ type: forgotPasswordTypes.CREATE_PASSWORD_TOKEN_LOADED })
    })
    .tapCatch(() => {
      dispatch({ type: forgotPasswordTypes.CREATE_PASSWORD_TOKEN_FAILED })
    })
}
