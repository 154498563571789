import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment'
import Tag from './Tag'
import { Loading } from '../../components'

class TagsBox extends Component {
  static propTypes = {
    tags: PropTypes.shape({
      value: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          text: PropTypes.string.isRequired,
          selected: PropTypes.bool.isRequired,
        }),
      ).isRequired,
      loading: PropTypes.bool.isRequired,
    }).isRequired,
    yardTags: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
    }).isRequired,
    yardId: PropTypes.number.isRequired,
    selectedDate: PropTypes.instanceOf(moment).isRequired,
    loadTags: PropTypes.func.isRequired,
    loadYardTags: PropTypes.func.isRequired,
    toggleTag: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool,
  }

  static defaultProps = {
    isReadOnly: false,
  }

  componentDidMount() {
    const { loadTags } = this.props
    loadTags()
    this.loadYardTags()
  }

  componentDidUpdate(prevProps) {
    const { yardId, selectedDate } = this.props

    if (
      prevProps.yardId !== yardId ||
      prevProps.selectedDate !== selectedDate
    ) {
      this.loadYardTags()
    }
  }

  loadYardTags = () => {
    const { yardId, loadYardTags, selectedDate } = this.props

    loadYardTags({ yardId, date: selectedDate })
  }

  handleTagToggle = (tag) => {
    const { toggleTag, yardId, selectedDate: date } = this.props
    toggleTag({ yardId, date, tag })
  }

  renderTagList() {
    const { tags, yardTags, isReadOnly } = this.props

    if (tags.loading || yardTags.loading) {
      return <Loading />
    }

    return tags.value.map((tag) => (
      <Tag
        key={tag.id}
        tag={tag}
        onToggle={this.handleTagToggle}
        isReadOnly={isReadOnly}
      />
    ))
  }

  render() {
    const { isReadOnly } = this.props

    return (
      <div className={cx('tagsBox', { isReadOnly })}>
        {this.renderTagList()}
      </div>
    )
  }
}

export default TagsBox
