import { connect } from 'react-redux'
import VehicleDetailsEvents from './VehicleDetailsEvents'

import { loadEvents } from '../../actions/manageVehiclesActions'
import { setHeaderReturnOptions } from '../../actions/manageVCRsActions'

import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state) => {
  const timezone = getYardTimeZone(state)

  return {
    eventList: state.manageVehicles.eventList,
    yardId: Number(state.general.selectedYard),
    timezone,
  }
}

const mapDispatchToProps = {
  loadEvents,
  setHeaderReturnOptions,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleDetailsEvents)
