import { combineReducers } from '@reduxjs/toolkit'
import audits from './audits'
import workerList from './workerList'
import worker from './worker'

const safetyReports = combineReducers({
  audits,
  workerList,
  worker,
})

export default safetyReports
