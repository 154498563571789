import { connect } from 'react-redux'
import PlayBackControls from './PlayBackControls'
import { getYardTimeZone } from '../../../selectors'
import {
  setHistoryPlay,
  setCurrentHistoryTime,
  clearHistory,
  loadSelectedPositionHistory,
  bufferHistory,
  loadLiveFlags,
} from '../../../actions/trackingActions'

const mapStateToProps = (state) => ({
  selectionType: state.tracking.selectionType,
  userList: state.manageUsers.userList,
  vehicleList: state.manageVehicles.vehicleList,
  viewMode: state.tracking.viewMode,
  scrubbing: state.playback.scrubbing,
  historyPlay: state.tracking.historyPlay,
  currentHistoryTime: state.playback.currentHistoryTime,
  historyRequestId: state.playback.historyRequestId,
  bufferHistoryRequestId: state.playback.bufferHistoryRequestId,
  historyTimeSeries: state.playback.historyTimeSeries,
  historyData: state.playback.historyData,
  historyViewMoment: state.tracking.historyViewMoment,
  isSidebarCollapsed: state.general.isSidebarCollapsed,
  timezone: getYardTimeZone(state),
  selectedYard: state.general.selectedYard,
})

const mapDispatchToProps = {
  setHistoryPlay,
  setCurrentHistoryTime,
  clearHistory,
  loadSelectedPositionHistory,
  bufferHistory,
  loadLiveFlags,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayBackControls)
