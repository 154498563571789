import { workerProfileTypes } from '../../actions/workerProfileActions'

const initialState = {
  shortName: null,
}

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case workerProfileTypes.USER_REQUESTED:
    case workerProfileTypes.USER_FAILED:
      return initialState

    case workerProfileTypes.USER_LOADED:
      return {
        ...state,
        shortName: payload.shortName,
      }

    default:
      return state
  }
}

export default user
