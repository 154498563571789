import clientApi from './utils/clientApi'

const getAll = () =>
  clientApi({
    method: 'get',
    url: `/audit_function_types`,
  }).then((response) => response.data.audit_function_types)

const users = {
  getAll,
}

export default users
