import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import appPropTypes from '../../appPropTypes'

import successIcon from '../../images/icons/icon-success.svg'
import failureIcon from '../../images/icons/icon-failure.svg'

import { history } from '../../utils'

import * as S from './styles'

const getTimeout = (timeout, message) => {
  if (_.isNil(timeout)) {
    return 2500 + (message.length * 1000) / 15
  }

  return timeout
}

const AlertMessage = ({
  type,
  message,
  fullWidthStyle,
  hidden,
  hide,
  timeout,
  redirectPath,
}) => {
  const isSuccess = type === 'success'
  const icon = isSuccess ? successIcon : failureIcon
  const millisecondsTimeout = getTimeout(timeout, message)

  if (hidden) {
    return null
  }

  setTimeout(() => {
    hide()
  }, millisecondsTimeout)

  if (!_.isNil(redirectPath)) {
    history.push(redirectPath)
  }

  return (
    <S.Container fullWidthStyle={fullWidthStyle}>
      <S.Message type={type} timeout={millisecondsTimeout}>
        {icon && <S.Icon src={icon} alt="Alert icon" />}
        {message}
        <S.ProgressBar timeout={millisecondsTimeout} isSuccess={isSuccess} />
      </S.Message>
    </S.Container>
  )
}

AlertMessage.propTypes = {
  type: appPropTypes.alertType.isRequired,
  message: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  timeout: PropTypes.number,
  fullWidthStyle: PropTypes.bool,
  redirectPath: PropTypes.string,
}

AlertMessage.defaultProps = {
  timeout: null,
  fullWidthStyle: false,
  redirectPath: null,
}

export default AlertMessage
