import React, { Component } from 'react'
import moment from 'moment'
import _ from 'lodash'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { ComposedChart, Bar, Legend, ReferenceLine, Tooltip } from 'recharts'
import { EnhancedBarChart, Loading } from '../../../../components'
import calendarGrayIcon from '../../../../images/icons/calendar-grey.svg'
import calendarIcon from '../../../../images/icons/calendar-blue.svg'

class Chart extends Component {
  static propTypes = {
    startTime: PropTypes.instanceOf(moment).isRequired,
    endTime: PropTypes.instanceOf(moment).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        bins: PropTypes.arrayOf(
          PropTypes.shape({
            yValue: PropTypes.number.isRequired,
          }),
        ),
      }),
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    lineValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lineType: PropTypes.string.isRequired,
    unit: PropTypes.string,
    selectedDate: PropTypes.instanceOf(moment).isRequired,
  }

  static defaultProps = {
    lineValue: 0,
    unit: '',
  }

  constructor(props) {
    super(props)
    this.state = {
      activeBar: null,
      width: 0,
      height: 0,
    }

    this.rootRef = React.createRef()
    this.resizeObserver = window.ResizeObserver
      ? new window.ResizeObserver(this.updateDimensions)
      : {
          observe: () => {
            this.updateDimensions()
          },
          unobserve: () => {},
        }
  }

  componentDidMount() {
    this.resizeObserver.observe(this.rootRef.current)
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    if (data.length !== 0 && !_.isEqual(prevProps.data, data)) {
      this.initActiveBar(data)
    }
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(this.rootRef.current)
  }

  updateDimensions = () => {
    const { offsetWidth, offsetHeight } = this.rootRef.current

    this.setState({
      width: offsetWidth,
      height: offsetHeight,
    })
  }

  toggleBarClass = ({ htmlNode, bar, cssClass }) => {
    const allSvgPaths = htmlNode.querySelectorAll('path')
    const hoveredCells = Array.from(allSvgPaths).find(
      (path) =>
        parseFloat(path.getAttribute('x')) === bar.x &&
        parseFloat(path.getAttribute('y')) === bar.y,
    )
    if (hoveredCells) {
      hoveredCells.classList.toggle(cssClass)
    }
  }

  toggleBarOpacity = (bar) => {
    this.toggleBarClass({ htmlNode: document, bar, cssClass: 'opaque-bar' })
  }

  highlightActiveBar = (node, bar) => {
    this.toggleBarClass({ htmlNode: node, bar, cssClass: 'chart-active-bar' })
  }

  removeAllHighlights = (nodeElement, bar) => {
    const allSvgPaths = nodeElement.querySelectorAll('path')
    const highlightedCells = Array.from(allSvgPaths).filter(
      (path) => parseFloat(path.getAttribute('x')) !== bar.x,
    )
    const classesToRemove = ['chart-active-bar', 'highlight-bar']
    highlightedCells.forEach((elm) => elm.classList.remove(...classesToRemove))
  }

  changeActiveBar = (bar) => {
    this.setState({ activeBar: bar.yValue || null })

    /* eslint-disable-next-line react/no-find-dom-node */
    const node = ReactDOM.findDOMNode(this)
    if (node instanceof HTMLElement) {
      this.removeAllHighlights(node, bar)
      this.highlightActiveBar(node, bar)
    }
  }

  renderCustomToolTip = ({ active, payload, label }) => {
    const { selectedDate, unit } = this.props

    if (active) {
      const [payloadValue] = payload
      const { activeBarValue } = this.parseUnits({
        unit,
        barValue: payloadValue.value,
      })

      return (
        <div className="custom-tooltip">
          <div className="activeDate">
            <img src={calendarIcon} alt="Calendar Icon" />
            <span>
              {`${moment(selectedDate)
                .subtract(29 - label, 'd')
                .format('ddd D MMM')}`}
            </span>
          </div>
          <hr />
          <div className="row">
            <div className="extraMetrics">
              <span className="extraMetric">
                {activeBarValue}
                {unit}
              </span>
            </div>
          </div>
        </div>
      )
    }

    return null
  }

  parseUnits = ({ unit, barValue, lineValue }) => {
    switch (unit) {
      case '%':
        return {
          activeBarValue: _.round(barValue * 100),
          averageLineValue: _.round(lineValue * 100, 2),
        }
      case 'min':
        return {
          activeBarValue: _.round(barValue / 1000 / 60),
          averageLineValue: _.round(lineValue / 1000 / 60),
        }
      default:
        return {
          activeBarValue: barValue || 0,
          averageLineValue: _.round(lineValue) || 0,
        }
    }
  }

  initActiveBar(data) {
    if (data) {
      const lastValue = _.last(data).yValue
      if (!_.isNil(lastValue)) {
        this.setState({
          activeBar: lastValue,
        })
      }
    }
  }

  renderChart() {
    const { data, lineValue, lineType, unit } = this.props
    const { width, height, activeBar } = this.state

    const { activeBarValue, averageLineValue } = this.parseUnits({
      unit,
      lineValue,
      barValue: activeBar,
    })

    return (
      <EnhancedBarChart highlight>
        <ComposedChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 20,
          }}
        >
          <Legend
            verticalAlign="top"
            align="right"
            wrapperStyle={{
              width: '220px',
              height: '5px',
              top: '-22px',
            }}
            payload={[{ value: `${activeBarValue}${unit}`, type: 'line' }]}
          />
          <Bar
            dataKey="yValue"
            fill="var(--darker-green-33)"
            background={{ fill: '#F8FAFD' }}
            onMouseEnter={this.toggleBarOpacity}
            onMouseLeave={this.toggleBarOpacity}
            onClick={this.changeActiveBar}
          />
          <ReferenceLine
            y={lineValue}
            label={{
              value: `${lineType} ${averageLineValue}${unit}`,
              position: 'insideBottomLeft',
              fontSize: 12,
              offset: 2,
            }}
            isFront
            strokeWidth={1}
            stroke="var(--darker-neutral-45)"
          />
          <Tooltip
            dataKey="yValue"
            offset={20}
            content={this.renderCustomToolTip}
          />
        </ComposedChart>
      </EnhancedBarChart>
    )
  }

  renderContent() {
    const { startTime, endTime, data, loading } = this.props

    if (loading || data.length < 1) {
      return <Loading />
    }

    return (
      <div>
        {this.renderChart()}
        <div className="chart-bottom-line">
          <div className="chart-bottom-line-start">
            <img src={calendarGrayIcon} alt="calendar-grey" />
            <span>{startTime.format('DD MMM')}</span>
          </div>
          <div className="chart-bottom-line-end">
            <img src={calendarGrayIcon} alt="calendar-grey" />
            <span>{moment(endTime).format('DD MMM')}</span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div ref={this.rootRef} className="chart-content">
        {this.renderContent()}
      </div>
    )
  }
}

export default Chart
