import clientApi from './utils/clientApi'

const getAll = () =>
  clientApi({
    method: 'get',
    url: '/audit_corrective_action_types',
  }).then((response) => response.data.audit_corrective_action_types)

const users = {
  getAll,
}

export default users
