import { trackingTypes } from '../../actions/trackingActions'

const initialState = null

const iconSelected = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.ICON_SELECTED:
      return payload
    case trackingTypes.ICON_SELECTED_RESET:
      return initialState
    default:
      return state
  }
}

export default iconSelected
