import { connect } from 'react-redux'
import HistoryViewMarkers from './HistoryViewMarkers'

import { setIconPinned } from '../../../actions/trackingActions'

const mapStateToProps = (state) => ({
  currentHistoryTime: state.playback.currentHistoryTime,
  historyTimeSeries: state.playback.historyTimeSeries,
  trackingDataForHistoryTime: state.playback.trackingDataForHistoryTime,
  iconSelected: state.tracking.iconSelected,
  iconPinned: state.tracking.iconPinned,
  vehicleList: state.manageVehicles.vehicleList,
})

const mapDispatchToProps = (dispatch) => ({
  setIconPinned: (value) => {
    dispatch(setIconPinned(value))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryViewMarkers)
