import api from '../api'
import { createTypes, auditsWorkerOptions } from './utils'
import parseUserFromServer from '../parsers/parseUserFromServer'

export const searchAuditTypes = createTypes('searchAudit', [
  { value: 'WORKER_LIST', isAsync: true },
  { value: 'AUDITS', isAsync: true },
])

export const loadWorkerList = ({ yardId, workerType }) => (dispatch) => {
  dispatch({ type: searchAuditTypes.WORKER_LIST_REQUESTED })

  const { roleName } = auditsWorkerOptions[workerType]

  return api.users
    .getAll({
      yardId,
      roleName,
    })
    .then((data) => {
      const workerList = data.users.map(parseUserFromServer)
      dispatch({
        type: searchAuditTypes.WORKER_LIST_LOADED,
        payload: { workerList },
      })
    })
    .catch(() => {
      dispatch({ type: searchAuditTypes.WORKER_LIST_FAILED })
    })
}
