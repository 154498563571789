import React from 'react'
import PropTypes from 'prop-types'
import Promise from 'bluebird'
import * as yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { browserHistory, Link } from 'react-router'

import Logo from '../../images/assets/new_logo.svg'

import * as S from './styles'

const emailSchema = yup.string().email()

const validationSchema = yup.object().shape({
  emailOrUsername: yup.string().required('Required'),
})

const ForgotPassword = ({ createPasswordToken, showAlert }) => {
  const onSubmit = (values) =>
    Promise.resolve()
      .then(() => {
        const fieldName = emailSchema.isValidSync(values.emailOrUsername)
          ? 'email'
          : 'username'

        return { [fieldName]: values.emailOrUsername }
      })
      .then(createPasswordToken)
      .then(() => {
        showAlert({
          type: 'success',
          message:
            'You should receive an email with your reset password link within 10 minutes',
          fullWidthStyle: true,
        })
        browserHistory.push('/login')
      })
      .catch(() => {
        showAlert({
          type: 'failure',
          message: 'Something went wrong. Please try again later.',
          fullWidthStyle: true,
        })
      })

  return (
    <S.Container>
      <Link to="/">
        <S.Logo src={Logo} />
      </Link>
      <S.Box>
        <S.Title>Forgotten Password</S.Title>
        <S.Subtitle>
          Please enter your Employee ID or RMS email and we’ll email you a link
          to reset your password.
        </S.Subtitle>
        <Formik
          initialValues={{
            emailOrUsername: '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                type="text"
                name="emailOrUsername"
                component={S.StyledFormItem}
                placeholder="ID or Email"
                autoFocus
              />
              <S.ActionWrapper>
                <S.StyledLink to="/">Cancel</S.StyledLink>
                <S.Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Loading' : 'Send'}
                </S.Button>
              </S.ActionWrapper>
            </Form>
          )}
        </Formik>
      </S.Box>
    </S.Container>
  )
}

ForgotPassword.propTypes = {
  createPasswordToken: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
}

export default ForgotPassword
