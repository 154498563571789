import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { map, pipe, prop, sortBy, includes, path } from 'ramda'
import moment from 'moment-timezone'
import { Formik, Field } from 'formik'
import { useDebouncedCallback } from 'use-debounce'

import {
  useGetAllAttachmentsQuery,
  useDeleteAttachmentMutation,
  useDownloadAttachmentMutation,
} from '../../services/attachments'

import {
  BILLING_WRITE_ROLES,
  BILLING_ROLLBACK_STATUS_FINAL_WRITE_ROLES,
} from '../../permissions/config'

import {
  FormItem,
  NewContainer,
  NewHeader,
  Blank,
  NewModal,
  BillingBadge,
  Loading,
  NewToolTip,
  NewTabs,
} from '../../components'
import Attachments from './Attachments'

import SummaryItem from './SummaryItem'

import { usePrevious } from '../../hooks'
import {
  history,
  formatCellValue,
  getYardCodeById,
  parseEmptyToNull,
  getDownloadFilename,
} from '../../utils'

import commentIcon from '../../images/icons/icon_comment_state.svg'
import iconArrow from '../../images/icons/icon_arrow.svg'
import iconDownload from '../../images/icons/icon_export_dark.svg'
import tooltipIconDownload from '../../images/tooltip/download.svg'

import * as S from './styles'

const derivedValuesDefaultHeadersWidth = 80
const numbersOnlyRegex = '[0-9]+([.,][0-9]+)?'

function getFuelPriceIdList({ fuelPriceCount, values }) {
  return _.times(fuelPriceCount).map((index) => values[`fuelPrice-${index}`])
}

function isRequiredField(key) {
  return !key.endsWith('-comment') && !key.startsWith('fuelPrice')
}

function getInputValue(item) {
  const inputValue =
    item.fillingType === 'selectable' ? item.selectedOption : item.value
  return _.isNil(inputValue) ? '' : inputValue
}

function parseFuelPriceToOption({ id, price, start_date: startDate, period }) {
  const dateFormat = period === 'monthly' ? 'MMM YYYY' : 'MM/DD/YYYY'
  return {
    value: id,
    label: `$${price} - ${moment.utc(startDate).format(dateFormat)}`,
  }
}

const defaultLoadedAttachments = []

const BillingSubmit = ({
  billingInvoiceDetails,
  timezone,
  selectedYard,
  loadBillingInvoiceDetails,
  resetBillingInvoiceDetails,
  invoiceId,
  submitInvoice,
  returnUrlDate,
  loadFileToExport,
  yardList,
  getBillingPreview,
  getInvoiceIntercompanyDetails,
  userRole,
  UrlSubMenu,
  showAlert,
  showErrorAlert,
}) => {
  const attachmentsCountLoaded = path(['value', 'invoice', 'attachments_size'])(
    billingInvoiceDetails,
  )

  const [selectedSubmitType, setSelectedSubmitType] = useState(null)
  const [submitButtonText, setSubmitButtonText] = useState('submit')
  const [isCommentVisible, setIsCommentVisible] = useState({})
  const [submitPayload, setSubmitPayload] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [attachmentsCount, setAttachmentsCount] = useState(0)
  const [attachments, setAttachments] = useState([])
  const [invoiceIntercompanyDetails, setInvoiceIntercompanyDetails] = useState(
    {},
  )

  const previousSelectedYard = usePrevious(selectedYard)

  useEffect(() => {
    setAttachmentsCount(attachmentsCountLoaded)
  }, [attachmentsCountLoaded])

  const hasWriteAccess = includes(userRole, BILLING_WRITE_ROLES)

  const {
    data: loadedAttachments = defaultLoadedAttachments,
    isLoading: isLoadingAttachments,
  } = useGetAllAttachmentsQuery({
    invoiceId,
  })

  const [deleteAttachment] = useDeleteAttachmentMutation()
  const [downloadAttachment] = useDownloadAttachmentMutation()

  useEffect(() => {
    setAttachments(loadedAttachments)
  }, [loadedAttachments])

  useEffect(() => {
    loadBillingInvoiceDetails({ invoiceId })
    return () => {
      resetBillingInvoiceDetails()
    }
  }, [invoiceId, loadBillingInvoiceDetails, resetBillingInvoiceDetails])

  useEffect(() => {
    if (
      !_.isNil(previousSelectedYard) &&
      previousSelectedYard !== selectedYard
    ) {
      history.push('/billing')
    }
  }, [selectedYard, previousSelectedYard])

  useEffect(() => {
    getInvoiceIntercompanyDetails({ invoiceId }).then(
      setInvoiceIntercompanyDetails,
    )
  }, [invoiceId, getInvoiceIntercompanyDetails])

  const { value: invoiceSummary, isLoading } = billingInvoiceDetails

  const {
    is_enabled: isSubmitAsFinalEnabled,
    invoices: intercompanyInvoicesList,
  } = invoiceIntercompanyDetails

  const intercompanyYards = _.chain(intercompanyInvoicesList)
    .defaultTo([])
    .map((invoice) => _.get(invoice, 'BillingTemplate.Yard.code'))
    .sortedUniq()
    .value()

  const isAlreadySubmitted = _.get(invoiceSummary, 'invoice.status') === 'final'

  const submitItems = _.get(invoiceSummary, 'on_submit_items', []).map(
    (item) => {
      const options = _.chain(item)
        .get('selectable_options')
        .defaultTo([])
        .map((option) => ({
          label: `${option.display_name}: ${option.value}`,
          value: option.code,
        }))
        .value()

      const selectedOption = _.get(
        item,
        'invoice_item_data.selected_option_code',
      )

      return {
        key: item.id,
        label: item.display_name,
        code: item.code,
        billingTemplateItemId: item.billing_template_item_id,
        comment: item.comment,
        value: item.value,
        fractionDigits: item.fraction_digits,
        defaultValue: item.default_value,
        fillingType: item.filling_type,
        options,
        selectedOption,
      }
    },
  )

  const invoice = _.get(invoiceSummary, 'invoice', {})

  const fuelPrices = _.get(invoiceSummary, 'fuel_price_items', [])

  const fuelPriceCount = _.size(fuelPrices)

  const invoicePeriod = `${moment
    .tz(invoice.start_date, timezone)
    .format('MMM Do')} - ${moment.tz(invoice.end_date, timezone).format('Do')}`

  const invoiceSummaryItems = _.get(
    invoiceSummary,
    'summary',
    [],
  ).filter((item) => ['constant', 'editable'].includes(item.filling_type))

  const compoundTierVariable = _.get(
    invoiceSummaryItems.find((item) => item.compound_tier_variable),
    'compound_tier_variable',
    null,
  )

  const compoundTier = _.get(invoiceSummary, 'summary', []).find(
    (item) => item.compound_tier_variable === compoundTierVariable,
  )

  const invoiceDerivedItems = _.get(invoiceSummary, 'summary', []).filter(
    (item) => item.filling_type === 'derived' && item.group !== 'FSC',
  )

  const invoiceProdSavingsItems = _.get(invoiceSummary, 'summary', []).filter(
    (item) => item.filling_type === 'prod_sav',
  )

  const invoiceFuelSavingsItems = _.get(invoiceSummary, 'summary', []).filter(
    (item) => item.filling_type === 'derived' && item.group === 'FSC',
  )

  const invoiceProductionSavingServices = _.get(
    invoiceSummary,
    'summary',
    [],
  ).find((item) => item.filling_type === 'prod_sav')

  const productionSavingsData = _.get(invoiceProductionSavingServices, 'data')
  const productionSavingsDetails = {
    description: _.get(productionSavingsData, 'description'),
    rate: _.get(productionSavingsData, 'rate'),
    driverVariables: _.get(productionSavingsData, 'driver_variables_items'),
    targetVariable: 'UNITS',
    targetVariableValue: _.get(
      productionSavingsData,
      'target_variables_items.values_summed',
    ),
  }

  const fuelSavingList = invoiceFuelSavingsItems.map((fuelSaving) => ({
    description: _.get(fuelSaving, 'description'),
    baseFuel: _.get(fuelSaving, 'data.base_fuel_price'),
    currentFuel: _.get(fuelSaving, 'data.current_fuel_price'),
    units: _.get(fuelSaving, 'data.units'),
    derivedValue: _.get(fuelSaving, 'data.derived_value'),
  }))

  const hasSummaryItems = !_.isEmpty(invoiceSummaryItems)
  const hasDerivedItems = !_.isEmpty(invoiceDerivedItems)
  const hasProdSavingsItems = !_.isEmpty(invoiceProdSavingsItems)
  const hasFuelSavingsItems = !_.isEmpty(invoiceFuelSavingsItems)

  const submitItemsInitialValues = _.chain(submitItems)
    .map((item) => [
      [item.code, getInputValue(item)],
      [`${item.code}-comment`, item.comment],
    ])
    .flatten()
    .fromPairs()
    .value()

  const fuelPriceInitialValues = _.chain(fuelPriceCount)
    .times()
    .map((item, index) => [
      `fuelPrice-${index}`,
      fuelPrices[index].fuel_price_id,
    ])
    .fromPairs()
    .value()

  const initialValues = {
    ...submitItemsInitialValues,
    ...fuelPriceInitialValues,
  }

  function handleSubmitInvoice(payload) {
    setIsModalVisible(false)
    submitInvoice(payload).then(() => {
      loadBillingInvoiceDetails({ invoiceId })
      setSelectedSubmitType(null)
    })
  }

  const modalProps = {
    visible: isModalVisible,
    onConfirm: () => handleSubmitInvoice(submitPayload),
    onCancel: () => setIsModalVisible(false),
    title: 'Are you want to submit Billing?',
    message: `Submitting can only be done 1 time, please double check all values for this Billing period.`,
    confirmText: 'Submit',
    cancelText: 'Review values',
    type: 'warning',
  }

  function handleBackButton() {
    history.push('/billing')
  }

  function handleSubmitType({ type }) {
    setSelectedSubmitType(type)
    setSubmitButtonText(`SUBMIT ${type}`)
  }

  function toggleComment({ id }) {
    return setIsCommentVisible((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  function handleSubmitItemsChange({
    values,
    parsedValue,
    code,
    fuelPriceId,
    fuelPriceIndex,
  }) {
    const items = submitItems.map((item) => {
      const isSelectable = item.fillingType === 'selectable'
      const value = parseEmptyToNull(
        code === item.code ? parsedValue : values[item.code],
      )

      return {
        billing_template_item_id: item.billingTemplateItemId,
        value: isSelectable ? null : value,
        selected_option_code: isSelectable ? value : null,
      }
    })

    const billingTemplateItemId = _.get(
      compoundTier,
      'billing_template_item_id',
    )

    const fuelPriceIdList = getFuelPriceIdList({ fuelPriceCount, values })
    fuelPriceIdList[fuelPriceIndex] = fuelPriceId

    getBillingPreview({
      submitItems: items,
      invoiceId,
      billingTemplateItemId,
      fuelPriceIdList,
    })
  }
  function handleFileDownload() {
    const yardCode = getYardCodeById({ yardList, yardId: selectedYard })
    const fileName = getDownloadFilename({ invoice, yardCode, timezone })

    return loadFileToExport({ invoiceId, fileName })
  }

  const titleProps = {
    title: isAlreadySubmitted ? 'Submission details' : 'Submit billing',
    returnText: 'Back',
    returnUrl: _.isNil(returnUrlDate)
      ? '/billing'
      : `/billing/date/${returnUrlDate}`,
    titleAction: [
      {
        key: 0,
        icon: iconDownload,
        tooltip: {
          titleIcon: tooltipIconDownload,
          title: 'Download CSV',
          action: 'Click to save the CSV',
        },
        onClick: handleFileDownload,
        disabled: _.get(invoice, 'status') === 'draft',
      },
    ],
  }

  const INNER_TABS_OPTIONS = [
    { text: 'Additional Billing', value: 'additional_billing' },
    { text: 'Attachments', value: 'attachments', redDot: attachmentsCount > 0 },
  ]

  const baseUrl = `/yard/${selectedYard}/billing/submit`
  const returnDateUrl = `return_url_date=${returnUrlDate}`
  const boxTitle = INNER_TABS_OPTIONS.find(
    (option) => option.value === UrlSubMenu,
  ).text

  const innerTabsProps = {
    items: INNER_TABS_OPTIONS,
    activeItem: UrlSubMenu,
    onChange: (value) =>
      history.push(`${baseUrl}/${invoiceId}/${value}?${returnDateUrl}`),
  }

  const debouncedhandleSubmitItemsChange = useDebouncedCallback(
    handleSubmitItemsChange,
    600,
  )

  return (
    <>
      <NewHeader {...titleProps} />
      <NewContainer>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validate={(values) => {
            const errors = _.transform(values, (result, value, key) => {
              if (_.isEmpty(_.trim(value)) && isRequiredField(key)) {
                const currentResult = result

                currentResult[key] = 'Required'
              }
            })

            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            const payload = {
              invoiceId,
              fuelPriceIdList: getFuelPriceIdList({ fuelPriceCount, values }),
              status: selectedSubmitType,
              items: submitItems.map((item) => {
                const isSelectable = item.fillingType === 'selectable'
                const value = isSelectable ? null : values[item.code]
                const selectedOption = isSelectable ? values[item.code] : null
                const commentValue = values[`${item.code}-comment`]

                return {
                  billing_template_item_id: item.billingTemplateItemId,
                  value: parseEmptyToNull(value),
                  comment: parseEmptyToNull(commentValue),
                  selected_option_code: parseEmptyToNull(selectedOption),
                }
              }),
            }

            setSubmitPayload(payload)

            if (selectedSubmitType === 'final') {
              setIsModalVisible(true)
            } else {
              handleSubmitInvoice(payload)
            }

            setSubmitting(false)
          }}
        >
          {({ isSubmitting, values, handleSubmit }) => {
            const hasMissingValues = _.chain(values)
              .toPairs()
              .filter(([key]) => isRequiredField(key))
              .fromPairs()
              .values()
              .some((value) => _.isNil(value) || value === '')
              .value()
            const intercompanyYardCodes = intercompanyYards.join(' & ')

            function renderItems(type) {
              if (isLoading) {
                return <Loading />
              }

              switch (type) {
                case 'summary':
                  return (
                    <>
                      <S.GrayArea verticalCenter fitContent>
                        <BillingBadge text={invoice.status} />
                        <S.Date>{invoicePeriod}</S.Date>
                        <S.Period>{invoice.billing_cycle}</S.Period>
                      </S.GrayArea>

                      <S.GrayArea>
                        <S.Row tableHeader>
                          <S.Text>BILLABLE SALESPARTS</S.Text>
                          <S.Text>TOTAL</S.Text>
                        </S.Row>

                        {!_.isEmpty(invoiceSummaryItems) &&
                          pipe(
                            sortBy(prop('display_order')),
                            map((item) => {
                              const formattedValue = formatCellValue({
                                value: _.get(item, 'value', ''),
                              })

                              return (
                                <SummaryItem
                                  key={`${item.code}.${item.display_order}`}
                                  item={item}
                                >
                                  <S.Row
                                    isCharge={item.type === 'charge'}
                                    as="summary"
                                  >
                                    <S.Text>{item.display_name}</S.Text>
                                    <S.Text>{formattedValue}</S.Text>
                                  </S.Row>
                                </SummaryItem>
                              )
                            }),
                          )(invoiceSummaryItems)}
                      </S.GrayArea>
                    </>
                  )

                case 'derived':
                  return (
                    <>
                      <S.GrayArea>
                        <S.Row tableHeader nowrap nopadding>
                          <S.Text large>Description</S.Text>
                          <S.Text
                            width={derivedValuesDefaultHeadersWidth}
                            gridEffect
                          >
                            QUANTITY
                          </S.Text>
                        </S.Row>
                        {pipe(
                          sortBy(prop('display_order')),
                          map((item) => {
                            const formattedValue = formatCellValue({
                              value: _.get(item, 'value', ''),
                            })

                            return (
                              <SummaryItem
                                key={`${item.code}.${item.display_order}`}
                                item={item}
                              >
                                <S.Row key={item.id} as="summary" nopadding>
                                  <S.Text title={item.description} large>
                                    {item.description}
                                  </S.Text>
                                  <S.Text
                                    width={derivedValuesDefaultHeadersWidth}
                                    gridEffect
                                  >
                                    {formattedValue}
                                  </S.Text>
                                </S.Row>
                              </SummaryItem>
                            )
                          }),
                        )(invoiceDerivedItems)}
                      </S.GrayArea>
                    </>
                  )

                case 'prod_savings': {
                  const driverVariables = _.get(
                    productionSavingsDetails,
                    'driverVariables',
                    [],
                  )

                  return (
                    <S.GrayArea>
                      <S.Row tableHeader nowrap nopadding>
                        <S.Text large>Description</S.Text>
                        {driverVariables.map((item, index) => (
                          <S.Text
                            key={index}
                            width={derivedValuesDefaultHeadersWidth}
                            title={item.variable}
                            gridEffect
                          >
                            {item.variable}
                          </S.Text>
                        ))}
                        <S.Text
                          width={derivedValuesDefaultHeadersWidth}
                          gridEffect
                        >
                          {productionSavingsDetails.targetVariable}
                        </S.Text>
                        <S.Text title="Rate" large gridEffect>
                          Derived Rate
                        </S.Text>
                      </S.Row>
                      <S.Row nowrap nopadding>
                        <S.Text
                          title={productionSavingsDetails.description}
                          large
                        >
                          {productionSavingsDetails.description}
                        </S.Text>
                        {driverVariables.map((item, index) => (
                          <S.Text
                            key={index}
                            width={derivedValuesDefaultHeadersWidth}
                            gridEffect
                          >
                            {formatCellValue({ value: item.value })}
                          </S.Text>
                        ))}
                        <S.Text
                          width={derivedValuesDefaultHeadersWidth}
                          gridEffect
                        >
                          {formatCellValue({
                            value: productionSavingsDetails.targetVariableValue,
                          }) || 'N/A'}
                        </S.Text>
                        <S.Text large gridEffect>
                          {productionSavingsDetails.rate}
                        </S.Text>
                      </S.Row>
                    </S.GrayArea>
                  )
                }
                case 'fuel_savings':
                  return (
                    <S.GrayArea>
                      <S.Row tableHeader nowrap nopadding>
                        <S.Text large>Fuel Surcharge</S.Text>
                        <S.Text
                          width={derivedValuesDefaultHeadersWidth}
                          gridEffect
                        >
                          Base
                        </S.Text>
                        <S.Text
                          width={derivedValuesDefaultHeadersWidth}
                          gridEffect
                        >
                          Current
                        </S.Text>
                        <S.Text
                          width={derivedValuesDefaultHeadersWidth}
                          title="Per lift Charge"
                          gridEffect
                        >
                          Per lift Charge
                        </S.Text>
                        <S.Text gridEffect large>
                          Units
                        </S.Text>
                      </S.Row>
                      {pipe(
                        sortBy(prop('display_order')),
                        map((fuelSaving) => (
                          <S.Row key={fuelSaving.id} nowrap nopadding>
                            <S.Text title={fuelSaving.description} large>
                              {fuelSaving.description}
                            </S.Text>
                            <S.Text
                              width={derivedValuesDefaultHeadersWidth}
                              gridEffect
                            >
                              {formatCellValue({
                                value: fuelSaving.baseFuel,
                                fractionDigits: 4,
                              }) || 'N/A'}
                            </S.Text>
                            <S.Text
                              width={derivedValuesDefaultHeadersWidth}
                              gridEffect
                            >
                              {formatCellValue({
                                value: fuelSaving.currentFuel,
                                fractionDigits: 3,
                              }) || 'N/A'}
                            </S.Text>
                            <S.Text
                              width={derivedValuesDefaultHeadersWidth}
                              gridEffect
                            >
                              {formatCellValue({
                                value: fuelSaving.derivedValue,
                                fractionDigits: 5,
                              }) || 'N/A'}
                            </S.Text>
                            <S.Text gridEffect large>
                              {fuelSaving.units}
                            </S.Text>
                          </S.Row>
                        )),
                      )(fuelSavingList)}
                    </S.GrayArea>
                  )
                default:
                  return null
              }
            }

            function renderSubmitItems() {
              if (_.isEmpty(submitItems)) {
                if (isLoading) {
                  return <Loading />
                }

                return (
                  <Blank
                    subtitle="No additional information needed for billing."
                    small
                  />
                )
              }

              function renderFormSingleValueItem({
                index,
                item,
                hasDefaultValue,
              }) {
                return (
                  <Field
                    type="number"
                    component={FormItem}
                    key={index}
                    name={item.code}
                    label={item.label}
                    pattern={numbersOnlyRegex}
                    placeholder="Insert value here"
                    disabled={isAlreadySubmitted || hasDefaultValue}
                    fractionDigits={item.fractionDigits}
                    onChange={(parsedValue) =>
                      debouncedhandleSubmitItemsChange({
                        values,
                        parsedValue,
                        code: item.code,
                      })
                    }
                  />
                )
              }

              function renderFormSelectableItem({ index, item }) {
                return (
                  <Field
                    type="select"
                    component={FormItem}
                    key={index}
                    name={item.code}
                    label="Selectable value"
                    options={item.options}
                    placeholder="Select an option"
                    menuPortalTarget={document.querySelector('body')}
                    disabled={isAlreadySubmitted}
                    onChange={(parsedValue) =>
                      debouncedhandleSubmitItemsChange({
                        values,
                        parsedValue,
                        code: item.code,
                      })
                    }
                  />
                )
              }

              return submitItems.map((item, index) => {
                const hasDefaultValue = !_.isNil(item.defaultValue)
                const formToRender =
                  item.fillingType === 'selectable'
                    ? renderFormSelectableItem({ index, item })
                    : renderFormSingleValueItem({
                        index,
                        item,
                        hasDefaultValue,
                      })

                return (
                  <>
                    {hasDefaultValue ? (
                      <NewToolTip title="Disregard feature not used yet.">
                        <S.FormItemWrapper>{formToRender}</S.FormItemWrapper>
                      </NewToolTip>
                    ) : (
                      formToRender
                    )}
                    <S.Toggle onClick={() => toggleComment({ id: item.key })}>
                      <S.Text>
                        {item.comment && (
                          <img
                            src={commentIcon}
                            alt="icon that represents comments"
                          />
                        )}
                      </S.Text>
                      <S.Text status>
                        {isCommentVisible[item.key]
                          ? `${item.comment ? 'Hide comments' : 'Hide'}`
                          : `${
                              item.comment ? 'Show comments' : 'Add comments'
                            }`}
                      </S.Text>
                      <S.IconArrow
                        src={iconArrow}
                        isOpen={isCommentVisible[item.key]}
                      />
                    </S.Toggle>
                    {isCommentVisible[item.key] && (
                      <Field
                        type="textarea"
                        component={FormItem}
                        rows={4}
                        name={`${item.code}-comment`}
                        placeholder="Write a comment here"
                        maxLength="180"
                        disabled={isAlreadySubmitted}
                      />
                    )}
                  </>
                )
              })
            }

            function renderFinalButton() {
              return (
                <div>
                  <S.Button
                    model="final"
                    type="button"
                    onClick={() => handleSubmitType({ type: 'final' })}
                    active={selectedSubmitType === 'final'}
                    disabled={
                      !hasWriteAccess ||
                      hasMissingValues ||
                      !isSubmitAsFinalEnabled
                    }
                  >
                    FINAL
                  </S.Button>
                </div>
              )
            }

            function renderFuelPriceList({ list, index }) {
              const parsedList = list.map(parseFuelPriceToOption)

              return (
                <Field
                  type="select"
                  component={FormItem}
                  options={parsedList}
                  name={`fuelPrice-${index}`}
                  placeholder="Select price and date"
                  isDisabled={isAlreadySubmitted}
                  menuPortalTarget={document.querySelector('body')}
                  onChange={(fuelPriceId) => {
                    handleSubmitItemsChange({
                      values,
                      fuelPriceId,
                      fuelPriceIndex: index,
                    })
                  }}
                />
              )
            }

            function renderFuelPrices() {
              if (fuelPriceCount === 0) {
                return <Blank title="No fuel prices to show" small />
              }

              return fuelPrices.map((item, index) =>
                renderFuelPriceList({
                  list: _.get(item, 'fuel_price_list'),
                  index,
                }),
              )
            }

            function renderBoxContent() {
              async function handleDeleteAttachment(filename) {
                await deleteAttachment({ invoiceId, filename }).unwrap()
              }

              function handleDownloadAttachment(filename) {
                downloadAttachment({ invoiceId, filename })
                  .unwrap()
                  .catch((error) =>
                    showErrorAlert({
                      error,
                      defaultMessage: `Can't download the attachment`,
                    }),
                  )
              }

              function handleAttachmentsChange(value) {
                setAttachmentsCount(value)
              }

              const attachmentsProps = {
                invoiceId,
                attachments,
                isLoadingAttachments,
                showAlert,
                hasWriteAccess,
                setAttachments,
                onDelete: handleDeleteAttachment,
                onClick: handleDownloadAttachment,
                onAttachmentsChange: handleAttachmentsChange,
              }

              const contentList = [
                {
                  tabName: 'additional_billing',
                  component: (
                    <S.Body withScroll>
                      <S.StyledForm isEmpty={_.isEmpty(submitItems)}>
                        {renderSubmitItems()}
                      </S.StyledForm>
                    </S.Body>
                  ),
                },
                {
                  tabName: 'attachments',
                  component: <Attachments {...attachmentsProps} />,
                },
              ]

              return map(({ tabName, component }) => (
                <div key={tabName} hidden={tabName !== UrlSubMenu}>
                  {component}
                </div>
              ))(contentList)
            }

            return (
              <S.Grid>
                <S.LeftGrid>
                  <S.Box>
                    <S.Header>BILLING ELEMENTS</S.Header>
                    <S.Body flexStart>
                      {hasSummaryItems ? (
                        renderItems('summary')
                      ) : (
                        <Blank small />
                      )}
                    </S.Body>
                  </S.Box>

                  <S.Box>
                    <S.Header>Calculated values</S.Header>
                    <S.Body
                      flexStart={
                        hasDerivedItems ||
                        hasProdSavingsItems ||
                        hasFuelSavingsItems
                      }
                    >
                      {hasDerivedItems && renderItems('derived')}
                      {hasProdSavingsItems && renderItems('prod_savings')}
                      {hasFuelSavingsItems && renderItems('fuel_savings')}
                      {!hasDerivedItems &&
                        !hasProdSavingsItems &&
                        !hasFuelSavingsItems && <Blank small />}
                    </S.Body>
                  </S.Box>
                </S.LeftGrid>

                <S.RightGrid>
                  <S.Menu>
                    <NewTabs {...innerTabsProps} />
                  </S.Menu>
                  <S.Box highlight={!isAlreadySubmitted}>
                    <S.Header>{boxTitle}</S.Header>
                    {renderBoxContent()}
                  </S.Box>

                  <S.Box $noScroll>
                    <S.Header>Fuel Price</S.Header>
                    <S.FuelPriceBody>{renderFuelPrices()}</S.FuelPriceBody>
                  </S.Box>

                  <S.Box highlight={!isAlreadySubmitted}>
                    <S.Header>Select accrual or final</S.Header>
                    <S.Body>
                      <S.ButtonWrapper>
                        <S.Button
                          model="accrual"
                          type="button"
                          onClick={() => handleSubmitType({ type: 'accrual' })}
                          active={selectedSubmitType === 'accrual'}
                          disabled={!hasWriteAccess || hasMissingValues}
                        >
                          ACCRUAL
                        </S.Button>
                        {isSubmitAsFinalEnabled ? (
                          renderFinalButton()
                        ) : (
                          <NewToolTip
                            title={`Intercompany relationship with PC ${intercompanyYardCodes}`}
                            description={`You will not be able to submit this as final until PC ${intercompanyYardCodes} has submitted billing as final.`}
                          >
                            {renderFinalButton()}
                          </NewToolTip>
                        )}
                      </S.ButtonWrapper>

                      <S.ButtonWrapper submit>
                        <S.Button
                          model="back"
                          type="button"
                          onClick={handleBackButton}
                        >
                          BACK
                        </S.Button>
                        <S.Button
                          model="submit"
                          type="button"
                          onClick={handleSubmit}
                          value={selectedSubmitType}
                          disabled={
                            !hasWriteAccess ||
                            isSubmitting ||
                            _.isNil(selectedSubmitType) ||
                            hasMissingValues ||
                            (invoice.status === 'final' &&
                              !includes(
                                userRole,
                                BILLING_ROLLBACK_STATUS_FINAL_WRITE_ROLES,
                              ))
                          }
                        >
                          {submitButtonText}
                        </S.Button>
                      </S.ButtonWrapper>
                    </S.Body>
                  </S.Box>
                </S.RightGrid>
              </S.Grid>
            )
          }}
        </Formik>
        <NewModal {...modalProps} />
      </NewContainer>
    </>
  )
}

BillingSubmit.propTypes = {
  billingInvoiceDetails: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    value: PropTypes.shape({
      invoice: PropTypes.shape({
        billing_cycle: PropTypes.string,
        billing_template_id: PropTypes.number,
        created_at: PropTypes.string,
        deleted_at: PropTypes.string,
        end_date: PropTypes.string,
        id: PropTypes.number,
        start_date: PropTypes.string,
        status: PropTypes.string,
        updated_at: PropTypes.string,
      }),
      on_submit_items: PropTypes.arrayOf(
        PropTypes.shape({
          billing_cycle: PropTypes.string,
          billing_template_item_id: PropTypes.number,
          code: PropTypes.string,
          comment: PropTypes.string,
          date: PropTypes.string,
          default_value: PropTypes.number,
          display_name: PropTypes.string,
          display_order: PropTypes.number,
          filling_type: PropTypes.string,
          id: PropTypes.number,
          is_daily: PropTypes.bool,
          type: PropTypes.string,
          value: PropTypes.number,
        }),
      ),
      summary: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          default_value: PropTypes.number,
          description: PropTypes.string,
          display_name: PropTypes.string,
          display_order: PropTypes.number,
          filling_type: PropTypes.string,
          is_daily: PropTypes.bool,
          type: PropTypes.string,
          value: PropTypes.number,
          tiers: PropTypes.arrayOf(
            PropTypes.shape({
              code: PropTypes.string,
              description: PropTypes.string,
              interval: PropTypes.arrayOf(PropTypes.number),
              value: PropTypes.number,
            }),
          ),
        }),
      ),
    }),
  }).isRequired,
  timezone: PropTypes.string.isRequired,
  selectedYard: PropTypes.number.isRequired,
  loadBillingInvoiceDetails: PropTypes.func.isRequired,
  resetBillingInvoiceDetails: PropTypes.func.isRequired,
  invoiceId: PropTypes.number.isRequired,
  submitInvoice: PropTypes.func.isRequired,
  getCompoundTier: PropTypes.func.isRequired,
  loadFileToExport: PropTypes.func.isRequired,
  getBillingPreview: PropTypes.func.isRequired,
  returnUrlDate: PropTypes.string,
  yardList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
  ).isRequired,
  getInvoiceIntercompanyDetails: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  UrlSubMenu: PropTypes.string,
  showAlert: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
}

BillingSubmit.defaultProps = {
  returnUrlDate: null,
  UrlSubMenu: 'additional_billing',
}

export default BillingSubmit
