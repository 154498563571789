import { combineReducers } from '@reduxjs/toolkit'
import viewMode from './viewMode'
import selectionType from './selectionType'
import iconPinned from './iconPinned'
import iconSelected from './iconSelected'
import trackingData from './trackingData'
import sliderLoading from './sliderLoading'
import historyPlay from './historyPlay'
import liveFlags from './liveFlags'
import zones from './zones'
import mapCenter from './mapCenter'
import lastActivity from './lastActivity'
import userSessionMetrics from './userSessionMetrics'
import vcrSubmissionAt from './vcrSubmissionAt'
import zoomLevel from './zoomLevel'
import historyRange from './historyRange'
import historyViewMoment from './historyViewMoment'
import infoBoxDeviceId from './infoBoxDeviceId'
import selectedTooltips from './selectedTooltips'
import infoBoxUser from './infoBoxUser'
import vehiclesViewMode from './vehiclesViewMode'
import detectedMoves from './detectedMoves'

const tracking = combineReducers({
  viewMode,
  selectionType,
  iconPinned,
  iconSelected,
  trackingData,
  sliderLoading,
  historyPlay,
  liveFlags,
  zones,
  mapCenter,
  lastActivity,
  userSessionMetrics,
  vcrSubmissionAt,
  zoomLevel,
  historyRange,
  historyViewMoment,
  infoBoxDeviceId,
  selectedTooltips,
  infoBoxUser,
  vehiclesViewMode,
  detectedMoves,
})

export default tracking
