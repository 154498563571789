import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Na = ({ className, style, text }) => {
  const customClass = cx('na_gray', { [className]: className })
  return (
    <span style={style} className={customClass}>
      {text}
    </span>
  )
}

Na.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
  text: PropTypes.string,
}

Na.defaultProps = {
  className: null,
  style: null,
  text: 'N/A',
}

export default Na
