import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { FormItem } from '../../../../components'
import appPropTypes from '../../../../appPropTypes'
import parseAuditEnumTypeListToOption from '../../../../parsers/parseAuditEnumTypeListToOption'
import { pickOrUndefined } from '../../../../utils'

class FormPage3 extends Component {
  componentDidMount() {
    const { loadAuditCorrectiveActionTypes } = this.props

    loadAuditCorrectiveActionTypes()
  }

  render() {
    const {
      savedValues,
      renderButtons,
      onSubmit,
      auditCorrectiveActionTypes,
    } = this.props

    const isAuditCorrectiveActionTypesLoading =
      auditCorrectiveActionTypes.loading

    return (
      <Formik
        initialValues={pickOrUndefined(savedValues, [
          'correctiveActions',
          'comment',
        ])}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values)
          setSubmitting(false)
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              type="select"
              name="correctiveActions"
              component={FormItem}
              label="Corrective actions"
              placeholder="Select corrective actions"
              options={parseAuditEnumTypeListToOption(
                auditCorrectiveActionTypes.value,
              )}
              isLoading={isAuditCorrectiveActionTypesLoading}
              isDisabled={isAuditCorrectiveActionTypesLoading}
              isMulti
            />
            <Field
              type="textarea"
              name="comment"
              component={FormItem}
              label="Comments"
              placeholder="Write a comment here"
              rows={6}
            />
            {renderButtons({ isSubmitting })}
          </Form>
        )}
      </Formik>
    )
  }
}

FormPage3.propTypes = {
  savedValues: PropTypes.object.isRequired,
  renderButtons: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loadAuditCorrectiveActionTypes: PropTypes.func.isRequired,
  auditCorrectiveActionTypes: appPropTypes.asyncAuditEnumList.isRequired,
}

export default FormPage3
