import { manageUsersTypes } from '../../actions/manageUsersActions'

const initialState = ''

const revokeTokenState = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageUsersTypes.REVOKE_TOKEN:
      return payload

    default:
      return state
  }
}

export default revokeTokenState
