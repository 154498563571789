import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Loading from '../Loading'

import * as S from './styles'

import { engineHoursMonthlyLimit } from '../../config'

const EngineHoursTable = ({
  columns,
  rows,
  isLoading,
  editMode,
  editModeColor,
  tableTitle,
  isInputEnabled,
  handleChange,
}) => {
  const [sortOrder, setSortOrder] = useState('desc')
  const [sortedHeader, setSortedHeader] = useState('label')

  const parsedColumns = [
    { key: 'label', text: tableTitle, isFutureDate: false },
    ...columns,
  ]

  if (isLoading) {
    return <Loading />
  }

  function getCellValueOrNull(cellContent) {
    const value = _.get(cellContent, 'value')
    if (_.isNil(value)) {
      return null
    }

    return value
  }

  function renderContent({
    cellContent,
    previousCellContent,
    index,
    column,
    tabIndex,
  }) {
    if (!_.isObject(cellContent)) {
      return cellContent
    }

    const cellContentValue = getCellValueOrNull(cellContent)
    const previousCellContentValue = getCellValueOrNull(previousCellContent)

    const hasWarningValue =
      column.key !== 0 &&
      column.isEditable &&
      !_.isNil(cellContentValue) &&
      !_.isNil(previousCellContentValue) &&
      (cellContentValue < previousCellContentValue ||
        cellContentValue > previousCellContentValue + engineHoursMonthlyLimit)

    if (isInputEnabled) {
      return (
        <S.Input
          value={cellContentValue}
          isCurrentEditing={cellContent.isCurrentEditing}
          tabIndex={`${column.key + 1}${tabIndex + 1}`}
          disabled={_.isObject(cellContent) && !column.isEditable}
          editModeColor={editModeColor}
          hasWarningValue={hasWarningValue}
          onChange={({ target }) =>
            handleChange({ target, rowIndex: index, columnIndex: column.key })
          }
        />
      )
    }

    return <S.ObjectContainer>{cellContent.value}</S.ObjectContainer>
  }

  function handleMissingValues({ cellContent, column }) {
    if (!_.isObject(cellContent)) {
      return false
    }

    return editMode === 'value' && !cellContent.value
      ? column.isEditable
      : !column.isFutureDate && !cellContent.value
  }

  function handleHeaderSort({ key }) {
    if (typeof key === 'string') {
      setSortedHeader(key)
      setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')
    }
  }

  function upArrowIcon() {
    return <S.SortIcon>&uarr;</S.SortIcon>
  }
  function downArrowIcon() {
    return <S.SortIcon>&darr;</S.SortIcon>
  }

  const sortedRows = _.orderBy(rows, (row) => row[sortedHeader], [sortOrder])

  return (
    <S.Container editMode={editMode} editModeColor={editModeColor}>
      <S.Header length={parsedColumns.length}>
        {parsedColumns.map((column) => (
          <S.Cell
            key={column.key}
            headerKey={column.key}
            onClick={() => handleHeaderSort({ key: column.key })}
          >
            {column.text}
            {column.key === sortedHeader &&
              (sortOrder === 'asc' ? upArrowIcon() : downArrowIcon())}
          </S.Cell>
        ))}
      </S.Header>

      <S.Body>
        {sortedRows.map((row, index) => (
          <S.Row key={row.key} length={parsedColumns.length}>
            {parsedColumns.map((column) => {
              const shouldRenderEngineHoursValues = _.isNumber(column.key)
              const cellContent = shouldRenderEngineHoursValues
                ? row.engineHoursValues[column.key]
                : row[column.key]
              const previousCellContent = shouldRenderEngineHoursValues
                ? row.engineHoursValues[column.key - 1]
                : {}

              return (
                <S.Cell
                  key={column.key}
                  editModeColor={editModeColor}
                  isDisabled={_.isObject(cellContent) && !column.isEditable}
                  isValueMissing={handleMissingValues({ cellContent, column })}
                >
                  {renderContent({
                    cellContent,
                    previousCellContent,
                    index: row.key,
                    tabIndex: index,
                    column,
                    row,
                  })}
                </S.Cell>
              )
            })}
          </S.Row>
        ))}
      </S.Body>
    </S.Container>
  )
}

EngineHoursTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isFutureDate: PropTypes.bool,
      isPastDate: PropTypes.bool,
      missingValues: PropTypes.bool,
      text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      commentIcon: PropTypes.string,
      label: PropTypes.string,
      engineHoursValues: PropTypes.arrayOf(
        PropTypes.shape({
          comment: PropTypes.string,
          date: PropTypes.string,
          status: PropTypes.string,
          updated_at: PropTypes.string,
          user_id: PropTypes.number,
          value: PropTypes.number,
          yard_service_id: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isInputEnabled: PropTypes.bool.isRequired,
  editMode: PropTypes.oneOf(['view', 'value']).isRequired,
  tableTitle: PropTypes.string.isRequired,
  editModeColor: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default EngineHoursTable
