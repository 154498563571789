import { manageVcrsTypes } from '../../actions/manageVCRsActions'

const initialState = {}

const vcrSearchFilter = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVcrsTypes.VCR_SEARCH_SET:
      return payload

    default:
      return state
  }
}

export default vcrSearchFilter
