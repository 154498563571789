import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { OverlayView } from '@react-google-maps/api'
import _ from 'lodash'
import Tooltip from 'rc-tooltip'

import { getShortName } from '../../../utils'
import checkBeaconState from '../utils/checkBeaconState'
import { VehicleIconMap } from '../../../components'

import './LiveViewMarkers.css'
import createInterval from '../../../utils/createInterval'
import deviceLostCommunication from '../utils/deviceLostCommunication'

const beaconColor = {
  red: '#fd397c',
  grey: '#3b415b',
  green: '#42EAAA',
  orange: '#F9D21E',
  royalblue: '#1B4EFF',
}

class LiveViewMarkers extends Component {
  componentDidMount() {
    const { setIconSelected } = this.props
    setIconSelected(null)
    this.sessionsInterval = createInterval()
    this.sessionsInterval.set(60 * 1000, () => {
      const { selectedYard, loadDeviceSessions } = this.props
      loadDeviceSessions(selectedYard)
    })
  }

  componentWillUnmount() {
    const { sessionsInterval } = this

    sessionsInterval.kill()
  }

  onIconClick = (element) => {
    const { setIconPinned } = this.props
    setIconPinned(element.vehicle_id)
  }

  getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  })

  getVehicleColor = (
    { status, user_id: userId, vcr_submission_id: vcrSubmissionId },
    isVehicleOffline,
  ) => {
    if (isVehicleOffline) {
      return 'black'
    }
    if (status === 'securement') {
      return 'grey'
    }
    if (status === 'on_break') {
      return 'royalblue'
    }
    if (userId) {
      return vcrSubmissionId ? 'green' : 'orange'
    }
    if (!vcrSubmissionId) {
      return 'red'
    }
    return ''
  }

  getSessionValue(vehicleId) {
    const {
      deviceSessions: { value: devSessions },
    } = this.props
    return devSessions.find((session) => session.vehicle_id === vehicleId)
  }

  getIsVehicleOffline(vehicleId) {
    const sessionValue = this.getSessionValue(vehicleId)
    return _.isNil(sessionValue)
  }

  checkBeaconState(element) {
    const { iconSelected, iconPinned } = this.props
    return checkBeaconState({
      id: element.vehicle_id,
      iconSelected,
      iconPinned,
    })
  }

  shouldRenderTooltip({ vehicle_id: elementVehicleId }) {
    const { selectedTooltips } = this.props

    return selectedTooltips.some((vehicleId) => vehicleId === elementVehicleId)
  }

  renderVehicleIcon({
    trackMapData,
    vehicleTypeName,
    vehicleColor,
    lostCommunication,
    shouldUseOverlay,
  }) {
    const position = { lat: trackMapData.latitude, lng: trackMapData.longitude }

    const vehicleIconMapElement = (
      <VehicleIconMap
        vehicleTypeName={vehicleTypeName}
        currentColor={vehicleColor}
        lostCommunication={lostCommunication}
        vehicleData={trackMapData}
        onIconClick={this.onIconClick}
      />
    )

    if (shouldUseOverlay) {
      return (
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={position}
          getPixelPositionOffset={this.getPixelPositionOffset}
        >
          <div tabIndex={0} role="button">
            {vehicleIconMapElement}
            <div
              className="dot_beacon"
              style={{ borderColor: beaconColor[vehicleColor] }}
            />
          </div>
        </OverlayView>
      )
    }

    return vehicleIconMapElement
  }

  renderVehicleIconWithTooltip({
    trackMapData,
    vehicleTypeName,
    vehicleColor,
    lostCommunication,
    shouldRenderBeacon,
    shouldRenderTooltip,
  }) {
    const shouldUseOverlay = shouldRenderBeacon || shouldRenderTooltip

    const vehicleIcon = this.renderVehicleIcon({
      trackMapData,
      vehicleTypeName,
      vehicleColor,
      lostCommunication,
      shouldUseOverlay,
    })

    if (shouldRenderTooltip) {
      const { User: user, Vehicle: vehicle } = trackMapData
      const tooltipTitle = user
        ? getShortName({ firstName: user.first_name, lastName: user.last_name })
        : vehicle.vehicle_name

      return (
        <Tooltip visible placement="left" overlay={<span>{tooltipTitle}</span>}>
          {vehicleIcon}
        </Tooltip>
      )
    }

    return vehicleIcon
  }

  renderVehicle(trackMapData) {
    const {
      vehicleList: { value: vehicleListValue },
      showNoComms,
    } = this.props

    const vehicle = vehicleListValue.find(
      (item) => item.id === trackMapData.vehicle_id,
    )
    const lostCommunication = deviceLostCommunication(trackMapData.fixmillis)
    const shouldHideVehicle = !showNoComms && lostCommunication

    if (!vehicle || shouldHideVehicle) {
      return null
    }

    const isVehicleOffline = this.getIsVehicleOffline(trackMapData.vehicle_id)
    const vehicleTypeName = vehicle.vehicle_type_name
    const vehicleColor = this.getVehicleColor(trackMapData, isVehicleOffline)

    const options = {
      trackMapData,
      vehicleTypeName,
      vehicleColor,
      lostCommunication,
    }

    return this.renderVehicleIconWithTooltip({
      ...options,
      shouldRenderBeacon: this.checkBeaconState(trackMapData),
      shouldRenderTooltip: this.shouldRenderTooltip(trackMapData),
    })
  }

  render() {
    const {
      trackingData: { value: trackingDataValue },
    } = this.props

    return trackingDataValue.map((trackMapData) => (
      <div key={trackMapData.device_imei}>
        {this.renderVehicle(trackMapData)}
      </div>
    ))
  }
}

LiveViewMarkers.propTypes = {
  setIconSelected: PropTypes.func.isRequired,
  setIconPinned: PropTypes.func.isRequired,
  trackingData: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  iconSelected: PropTypes.number,
  iconPinned: PropTypes.number,
  vehicleList: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  deviceSessions: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  selectedTooltips: PropTypes.arrayOf(PropTypes.number).isRequired,
  loadDeviceSessions: PropTypes.func.isRequired,
  selectedYard: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  showNoComms: PropTypes.bool.isRequired,
}

LiveViewMarkers.defaultProps = {
  iconSelected: null,
  iconPinned: undefined,
}

export default LiveViewMarkers
