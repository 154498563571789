import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'
import { browserHistory } from 'react-router'

import {
  VCRStatus,
  NewContainer,
  NewHeader,
  NewTable,
  Blank,
} from '../../components'

import { history, getScrollEnd } from '../../utils'

import * as S from './styles'

import maintenanceIcon from '../../images/tooltip/maintenance.svg'
import tooltipIconSearch from '../../images/tooltip/search.svg'
import searchIcon from '../../images/icons/icon_search_dark.svg'
import clearIcon from '../../images/icons/icon_clear_dark.svg'

const headers = [
  {
    key: 0,
    text: 'VEHICLES',
    toolTipTitle: 'Vehicles',
    toolTipDescription: 'Number of the vehicle',
  },
  {
    key: 1,
    text: 'CREATED BY',
    toolTipTitle: 'Create by',
    toolTipDescription: 'Person who created the VCR',
  },
  {
    key: 2,
    text: 'CREATED ON',
    toolTipTitle: 'Created on',
    toolTipDescription: 'Date that the VCR was created',
  },
  {
    key: 3,
    text: 'ENGINE HOURS',
    toolTipTitle: 'Engine hours',
    toolTipDescription: 'Last reported engine hours',
  },
  {
    key: 4,
    text: 'STATUS',
    toolTipTitle: 'Status',
    toolTipDescription: 'Status of a vehicle - Operational or Needs repair',
  },
]

const initialLimit = 20

const ManageVCRs = ({
  selectedYard,
  vcrSortOption,
  setVCRSortOption,
  vcrList,
  timezone,
  pathname,
  page,
  submittedAtStart,
  submittedAtEnd,
  status,
  userId,
  vehicleId,
  setVCRsSearch,
  defaultFilter,
  loadVcrList,
}) => {
  const isLoading = _.get(vcrList, 'isLoading')
  const hasCascadeAnimation = _.get(vcrList, 'nextOffset') <= initialLimit

  useEffect(() => {
    if (!_.isNil(vcrSortOption)) {
      loadVcrList({
        limit: initialLimit,
        offset: 0,
        yardId: selectedYard,
        vehicleId,
        submittedAtEnd,
        submittedAtStart,
        userId,
        vcrSortOption,
      })
    }
  }, [
    loadVcrList,
    selectedYard,
    submittedAtEnd,
    submittedAtStart,
    userId,
    vehicleId,
    vcrSortOption,
  ])

  useEffect(() => {
    const tabContentElement = document.querySelector('.table-body')
    if (tabContentElement) {
      tabContentElement.scrollTop = -tabContentElement.scrollHeight
    }
  }, [vcrSortOption])

  function handleScrollEnd() {
    const nextOffset = _.get(vcrList, 'nextOffset')

    return _.isNil(nextOffset)
      ? null
      : loadVcrList({
          vehicleId,
          limit: 10,
          offset: nextOffset,
          isLazyLoading: true,
          yardId: selectedYard,
          submittedAtEnd,
          submittedAtStart,
          userId,
          vcrSortOption,
        })
  }

  function handleScroll(event) {
    return getScrollEnd(event) ? handleScrollEnd() : null
  }

  const vcrListSortOptions = [
    {
      key: 0,
      text: 'All',
      value: 'all',
    },
    {
      key: 1,
      text: 'No issues',
      value: 'closed',
    },
    {
      key: 2,
      text: 'Needs repair',
      value: 'open',
    },
  ]

  const buildQueryString = useCallback(
    () =>
      _.omitBy(
        {
          submitted_at_start: submittedAtStart,
          submitted_at_end: submittedAtEnd,
          status,
          user_id: userId,
          vehicle_id: vehicleId,
        },
        _.isNil,
      ),
    [status, submittedAtEnd, submittedAtStart, userId, vehicleId],
  )

  const changeFilter = useCallback(
    (filter) => {
      setVCRsSearch(filter)

      browserHistory.push({
        pathname,
        query: filter,
      })
    },
    [pathname, setVCRsSearch],
  )

  useEffect(() => {
    setVCRSortOption(status)
  }, [page, status, setVCRSortOption])

  function handleClick(id) {
    if (_.isNil(id)) {
      return
    }

    history.push(`/vcrs/${id}`)
  }

  function handleSortButton(value) {
    setVCRSortOption(value)
    changeFilter({ ...buildQueryString(), status: value, page: 0 })
  }

  const filteredRows =
    status === 'all'
      ? _.get(vcrList, 'value', [])
      : _.get(vcrList, 'value', []).filter((vcr) => vcr.status === status)

  const rows = filteredRows.map((vcr) => ({
    columns: {
      0: {
        value: _.defaultTo(_.get(vcr, 'vehicle_name'), ''),
      },
      1: {
        value: _.isNil(_.get(vcr, 'User'))
          ? '-'
          : `${vcr.User.first_name} ${vcr.User.last_name}`,
      },
      2: {
        value: _.defaultTo(_.get(vcr, 'submitted_at'), ''),
        render: moment
          .tz(vcr.submitted_at, timezone)
          .format('MM/DD/YYYY HH:mm'),
      },
      3: {
        value: _.defaultTo(_.get(vcr, 'engine_hours'), ''),
      },
      4: {
        value: _.defaultTo(_.get(vcr, 'status'), ''),
        render: <VCRStatus status={vcr.status} />,
      },
    },
    onClick: () => handleClick(vcr.id),
    id: vcr.id,
  }))

  const vcrListSortTab = {
    items: vcrListSortOptions,
    activeItem: vcrSortOption,
    onChange: (value) => handleSortButton(value),
    tooltip: {
      titleIcon: maintenanceIcon,
      actionType: 'sort',
      title: 'Sorting by maintenance status',
      action: 'Click on tabs to sort the vehicles',
    },
  }

  const titleAction = [
    {
      key: 0,
      icon: searchIcon,
      onClick: () => history.push('/vcrs/search'),
      tooltip: {
        titleIcon: tooltipIconSearch,
        title: "Search VCR's",
        description:
          'Search by date range and VCR creator, vehicle number, or VCR status',
        action: "Click to search VCR's",
      },
    },
    {
      key: 1,
      icon: clearIcon,
      onClick: () => changeFilter(defaultFilter),
      tooltip: {
        title: 'Clear Search',
        description: 'Resets the filter to the initial state',
        action: 'Click to clear search criteria',
      },
    },
  ]

  function getFilteredLabel() {
    if (_.isNil(submittedAtStart) || _.isNil(submittedAtEnd)) {
      return {}
    }

    return {
      timezone,
      startDate: moment.tz(submittedAtStart, timezone),
      endDate: moment.tz(submittedAtEnd, timezone),
      readOnly: true,
    }
  }

  return (
    <>
      <NewHeader
        title="VCR's"
        date={getFilteredLabel()}
        tabs={vcrListSortTab}
        titleAction={titleAction}
      />
      <NewContainer className="vcrs">
        {rows.length === 0 && !isLoading ? (
          <Blank type="search" />
        ) : (
          <S.OverflowContainer onScroll={(event) => handleScroll(event)}>
            <NewTable
              headers={headers}
              rows={rows}
              hasCascadeAnimation={hasCascadeAnimation}
            />
          </S.OverflowContainer>
        )}
      </NewContainer>
    </>
  )
}

ManageVCRs.propTypes = {
  selectedYard: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setVCRSortOption: PropTypes.func.isRequired,
  vcrSortOption: PropTypes.string.isRequired,
  loadVcrList: PropTypes.func.isRequired,
  vcrList: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
    count: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    nextOffset: PropTypes.number.isRequired,
  }).isRequired,
  pathname: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  userId: PropTypes.number,
  vehicleId: PropTypes.number,
  status: PropTypes.string.isRequired,
  submittedAtEnd: PropTypes.string,
  submittedAtStart: PropTypes.string,
  setVCRsSearch: PropTypes.func.isRequired,
  defaultFilter: PropTypes.object.isRequired,
}

ManageVCRs.defaultProps = {
  selectedYard: 0,
  userId: undefined,
  vehicleId: undefined,
  submittedAtEnd: undefined,
  submittedAtStart: undefined,
}

export default ManageVCRs
