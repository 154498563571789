import { map, pipe, isNil, reject, addIndex } from 'ramda'

export default function getHeaders({ hasCrudYardWriteAccess }) {
  const headers = [
    {
      toolTipTitle: 'Name',
      toolTipDescription: 'Name of company',
    },
    hasCrudYardWriteAccess
      ? {
          toolTipTitle: 'Actions',
          toolTipDescription: 'Edit or delete companies',
        }
      : null,
  ]

  return pipe(
    reject(isNil),
    addIndex(map)((header, index) => ({
      ...header,
      key: index,
      text: header.toolTipTitle.toUpperCase(),
      toolTipAction: `Click to sort ${header.toolTipTitle}`,
      actionType: 'sort',
    })),
  )(headers)
}
