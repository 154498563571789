import { billingTypes } from '../../actions/billingActions'

const initialState = {
  value: {},
  isLoading: true,
}

const billingTable = (state = initialState, { type, payload }) => {
  switch (type) {
    case billingTypes.BILLING_TABLE_REQUESTED:
    case billingTypes.BILLING_TABLE_EDIT_REQUESTED:
    case billingTypes.BILLING_TABLE_SUBMIT_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case billingTypes.BILLING_TABLE_LOADED:
      return {
        ...state,
        isLoading: false,
        value: payload,
      }

    case billingTypes.BILLING_TABLE_EDIT_LOADED:
    case billingTypes.BILLING_TABLE_SUBMIT_LOADED:
      return {
        ...state,
        isLoading: false,
      }

    case billingTypes.BILLING_TABLE_FAILED:
    case billingTypes.BILLING_TABLE_EDIT_FAILED:
    case billingTypes.BILLING_TABLE_SUBMIT_FAILED:
      return initialState

    default:
      return state
  }
}

export default billingTable
