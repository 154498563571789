import { connect } from 'react-redux'
import PasswordReset from './PasswordReset'
import { resetUserPassword, loadUser } from '../../actions/passwordResetActions'

const mapStateToProps = (state, ownProps) => ({
  token: ownProps.params.token,
  user: state.passwordReset.user,
})

const mapDispatchToProps = {
  resetUserPassword,
  loadUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
