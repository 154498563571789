import { combineReducers } from '@reduxjs/toolkit'

import vehicleList from './vehicleList'
import vehicleData from './vehicleData'
import postVehicleCommentState from './postVehicleCommentState'
import vehicleDevices from './vehicleDevices'
import vcrList from './vcrList'
import tableSortOption from './tableSortOption'
import tableSortHeader from './tableSortHeader'
import engineHours from './engineHours'
import vwrList from './vwrList'
import commentList from './commentList'
import eventList from './eventList'

const manageVehicles = combineReducers({
  vehicleList,
  vehicleData,
  postVehicleCommentState,
  vehicleDevices,
  vcrList,
  tableSortOption,
  tableSortHeader,
  engineHours,
  vwrList,
  commentList,
  eventList,
})

export default manageVehicles
