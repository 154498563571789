import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

import sortIcon from '../../images/tooltip/sort.svg'
import clickIcon from '../../images/tooltip/click.svg'
import 'tippy.js/dist/tippy.css'

const NewToolTip = ({
  children,
  placement,
  title,
  titleIcon,
  description,
  footer,
  theme,
  action,
  actionType,
  maxWidth,
}) => (
  <S.Tippy
    placement={placement}
    arrow
    duration={0}
    delay={[300, 0]}
    theme={theme}
    maxWidth={maxWidth}
    content={
      <>
        <S.Wrapper>
          {titleIcon && <S.Icon src={titleIcon} alt="Tooltip title icon" />}
          <S.Title theme={theme}>{title}</S.Title>
        </S.Wrapper>
        {action && (
          <S.ActionWrapper>
            <S.Action>
              <S.Icon
                src={actionType === 'sort' ? sortIcon : clickIcon}
                alt="Tooltip action icon"
              />
              {action}
            </S.Action>
          </S.ActionWrapper>
        )}
        {description && (
          <S.Description theme={theme}>{description}</S.Description>
        )}
        {footer && <S.Footer theme={theme}>{footer}</S.Footer>}
      </>
    }
  >
    {children}
  </S.Tippy>
)

NewToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
  titleIcon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
  theme: PropTypes.oneOf(['dark', 'light']),
  maxWidth: PropTypes.number,
  action: PropTypes.string,
  actionType: PropTypes.oneOf(['sort', 'click']),
}

NewToolTip.defaultProps = {
  placement: 'top',
  titleIcon: null,
  title: null,
  description: null,
  footer: null,
  theme: 'dark',
  action: null,
  actionType: 'click',
  maxWidth: undefined,
}

export default NewToolTip
