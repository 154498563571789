import moment from 'moment-timezone'
import addIcon from '../../../images/icons/icon_add_dark.svg'
import editIcon from '../../../images/icons/icon_edit_dark.svg'
import refreshIcon from '../../../images/icons/icon_refresh_dark.svg'
import tooltipRefreshIcon from '../../../images/tooltip/refresh.svg'
import { engineHoursEditablePeriod } from '../../../config'
import { history } from '../../../utils'

export default function getTitleAction({
  viewMode,
  timezone,
  setEditMode,
  canChangeAnyTime,
  handleLoadVehicleDevices,
  hasEngineHoursWriteAccess,
}) {
  const isEditEnabled =
    canChangeAnyTime ||
    moment
      .tz(timezone)
      .isSameOrBefore(
        moment()
          .tz(timezone)
          .startOf('month')
          .add(engineHoursEditablePeriod.end, 'days'),
      )

  if (viewMode === 'vehicles-list') {
    const titleActionList = [
      {
        key: 0,
        icon: refreshIcon,
        onClick: () => handleLoadVehicleDevices(),
        tooltip: {
          titleIcon: tooltipRefreshIcon,
          title: 'Reload vehicles',
          action: 'Click to refresh the vehicles list',
        },
      },
    ]

    if (hasEngineHoursWriteAccess) {
      titleActionList.push({
        key: 1,
        icon: addIcon,
        onClick: () => history.push('/vehicles/create'),
        tooltip: {
          title: 'Add new vehicle',
          action: 'Click to add a new vehicle',
        },
      })
    }

    return titleActionList
  }

  if (hasEngineHoursWriteAccess) {
    return [
      {
        key: 1,
        icon: editIcon,
        onClick: () => setEditMode('value'),
        disabled: !isEditEnabled,
        tooltip: {
          titleIcon: tooltipRefreshIcon,
          title: 'Edit engine hours',
          action: 'Click to edit all vehicles’ engine hours',
          description:
            'Click to edit the numeric values for engine hours. Click on “Save” when you are done.',
        },
      },
    ]
  }

  return []
}
