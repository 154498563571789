import { connect } from 'react-redux'
import WorkerProfile from './WorkerProfile'
import {
  setSelectedDate,
  loadFlagsByUser,
  loadUser,
  loadFlagsCountByUser,
} from '../../actions/workerProfileActions'
import './WorkerProfile.css'

import { getYardTimeZone } from '../../selectors'
import { parseIntOrUndefined, createMomentParser } from '../../utils'

const parseMoment = createMomentParser()

const mapStateToProps = (state, ownProps) => {
  const timezone = getYardTimeZone(state)

  return {
    timezone,
    selectedDate: parseMoment(
      state.workerProfile.selectedDate || ownProps.params.start_date,
      timezone,
    ),
    userId: Number(ownProps.params.user_id),
    user: state.workerProfile.user,
    flags: state.workerProfile.flags,
    yardId: parseIntOrUndefined(state.general.selectedYard),
  }
}

const mapDispatchToProps = {
  setSelectedDate,
  loadFlagsByUser,
  loadUser,
  loadFlagsCountByUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkerProfile)
