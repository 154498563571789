import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TabContext from './TabContext'

class Header extends Component {
  static contextType = TabContext

  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  render() {
    const { children } = this.props
    const { selectedIndex } = this.context

    return React.Children.map(children, (child, index) => {
      if (index === selectedIndex) {
        return child
      }

      return null
    })
  }
}

export default Header
