import { connect } from 'react-redux'
import BillingTemplateVersions from './BillingTemplateVersions'
import { showAlert, showErrorAlert } from '../../actions/alertMessageActions'
import { getYardTimeZone } from '../../selectors'
import { setShowDeletedTemplates } from '../../features/billing/billingTemplateVersionsSlice'

const mapStateToProps = (state, ownProps) => {
  const timezone = getYardTimeZone(state)

  return {
    timezone,
    selectedYard: state.general.selectedYard,
    returnDate: ownProps.location.query.return_date,
  }
}

const mapDispatchToProps = {
  showAlert,
  showErrorAlert,
  setShowDeletedTemplates,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillingTemplateVersions)
