import { trackingTypes } from '../../actions/trackingActions'

const initialState = {
  loading: false,
  value: null,
}

const userSessionMetrics = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.USER_SESSION_METRICS_REQUESTED:
      return {
        ...state,
        ...initialState,
        loading: true,
      }

    case trackingTypes.USER_SESSION_METRICS_LOADED:
      if (payload.deviceId === payload.infoBoxDeviceId) {
        return {
          ...state,
          loading: false,
          value: payload.data,
        }
      }
      return state

    case trackingTypes.USER_SESSION_METRICS_FAILED:
    case trackingTypes.USER_SESSION_METRICS_CLEARED:
      return initialState

    default:
      return state
  }
}

export default userSessionMetrics
