import { workerProfileTypes } from '../../actions/workerProfileActions'

const initialState = {
  loading: false,
  value: {
    bins: [],
    average: 0,
  },
}

const flagsCount = (state = initialState, { type, payload }) => {
  switch (type) {
    case workerProfileTypes.USER_FLAGS_COUNT_REQUESTED:
      return {
        ...state,
        ...initialState,
        loading: true,
      }

    case workerProfileTypes.USER_FLAGS_COUNT_LOADED:
      return {
        ...state,
        loading: false,
        value: payload,
      }

    case workerProfileTypes.USER_FLAGS_COUNT_FAILED:
      return initialState

    default:
      return state
  }
}

export default flagsCount
