import { connect } from 'react-redux'
import PrintVCR from './PrintVCR'
import { loadVCR } from '../../actions/manageVCRsActions'

const mapStateToProps = (state) => ({
  vcrData: state.manageVCRs.vcrData,
  vehicleList: state.manageVehicles.vehicleList,
  isSidebarCollapsed: state.general.isSidebarCollapsed,
})

const mapDispatchToProps = (dispatch) => ({
  loadVCR: (vcrId) => {
    dispatch(loadVCR(vcrId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PrintVCR)
