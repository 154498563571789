import _ from 'lodash'
import moment from 'moment-timezone'
import { saveAs } from 'file-saver'
import api from '../api'
import { showAlert, showErrorAlert } from './alertMessageActions'
import { missingDaysLimit, billingMissingDateAlertRoles } from '../config'
import { history } from '../utils'
import { createTypes, onlyLatestRequest } from './utils'

export const billingTypes = createTypes('billing', [
  { value: 'BILLING_TABLE', isAsync: true },
  { value: 'BILLING_TABLE_EDIT', isAsync: true },
  { value: 'BILLING_TABLE_SUBMIT', isAsync: true },
  { value: 'BILLING_WEEKS', isAsync: true },
  { value: 'BILLING_INVOICES', isAsync: true, isLazy: true },
  { value: 'BILLING_INVOICE_DETAILS', isAsync: true },
  { value: 'BILLING_PREVIEW', isAsync: true },
  { value: 'BILLING_COMMENTS', isAsync: true },
  'BILLING_MISSING_DATE_ALERT',
  'BILLING_INVOICE_DETAILS_RESET',
])

const getLatestsBillingTable = onlyLatestRequest(api.billing.getTable)

export const loadBillingTable = ({ yard, date, templateId }) => (dispatch) => {
  dispatch({ type: billingTypes.BILLING_TABLE_REQUESTED })

  getLatestsBillingTable({ yard, date, templateId })
    .then((data) => {
      dispatch({
        type: billingTypes.BILLING_TABLE_LOADED,
        payload: data,
      })
    })
    .catch((error) => {
      dispatch({ type: billingTypes.BILLING_TABLE_FAILED })
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: 'Failed to load billing table',
        }),
      )
    })
}

export const upsertBillingTable = ({ rows, dates, yard }) => (dispatch) => {
  dispatch({ type: billingTypes.BILLING_TABLE_EDIT_REQUESTED })

  return api.billing
    .upsertTable({ dates, rows, yard })
    .then(() => {
      dispatch({ type: billingTypes.BILLING_TABLE_EDIT_LOADED })
      dispatch(
        showAlert({
          type: 'success',
          message: `Edits for services was saved successfully`,
        }),
      )
    })
    .tapCatch((error) => {
      dispatch({ type: billingTypes.BILLING_TABLE_EDIT_FAILED })
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: 'Failed to edit content for services',
        }),
      )
    })
}

export const submitInvoice = ({
  invoiceId,
  status,
  items,
  fuelPriceIdList,
}) => (dispatch) => {
  dispatch({ type: billingTypes.BILLING_TABLE_SUBMIT_REQUESTED })

  return api.billing
    .submitInvoice({ invoiceId, status, items, fuelPriceIdList })
    .then(() => {
      dispatch({ type: billingTypes.BILLING_TABLE_SUBMIT_LOADED })
      dispatch(
        showAlert({
          type: 'success',
          message: `Billing period submitted successfully`,
        }),
      )
    })
    .tapCatch((error) => {
      dispatch({ type: billingTypes.BILLING_TABLE_SUBMIT_FAILED })
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: 'Failed to submit billing period',
        }),
      )
    })
}

const getLatestsBillingWeeks = onlyLatestRequest(api.billingWeeks.getWeeks)

export const loadBillingWeeks = ({ yard, date }) => (dispatch) => {
  dispatch({ type: billingTypes.BILLING_WEEKS_REQUESTED })

  getLatestsBillingWeeks({ yard, date })
    .then((weeks) => {
      dispatch({
        type: billingTypes.BILLING_WEEKS_LOADED,
        payload: weeks,
      })
    })
    .catch((error) => {
      dispatch({ type: billingTypes.BILLING_WEEKS_FAILED })
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: 'Failed to load billing weeks',
        }),
      )
    })
}

export const setMissingDateAlert = ({ status }) => (dispatch) => {
  dispatch({
    type: billingTypes.BILLING_MISSING_DATE_ALERT,
    payload: status,
  })
}

export const getFirstMissingDate = ({ yard, timezone, userRole }) => (
  dispatch,
) => {
  api.billing
    .getFirstMissingDate({ yard })
    .then((response) => {
      const missingBillingDaysCount = _.get(response, 'missing_billing_days', 0)
      const firstMissingDate = _.get(
        response,
        'first_missing_date',
        moment.tz(timezone).toISOString(),
      )

      if (
        missingBillingDaysCount > missingDaysLimit &&
        billingMissingDateAlertRoles.includes(userRole)
      ) {
        history.changePathVariable({
          name: 'date',
          value: moment.tz(firstMissingDate, timezone).format('YYYY-MM-DD'),
        })
        dispatch(setMissingDateAlert({ status: 'show' }))
      }
    })
    .catch((error) => {
      dispatch(setMissingDateAlert({ status: 'hide' }))
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: 'Failed to load first missing date',
        }),
      )
    })
}

const getLatestsBillingInvoices = onlyLatestRequest(api.billing.getInvoices)

export const loadBillingInvoices = ({
  yard,
  limit,
  offset,
  isLazyLoading = false,
  templateId,
}) => (dispatch) => {
  dispatch({ type: billingTypes.BILLING_INVOICES_REQUESTED })

  getLatestsBillingInvoices({ yard, limit, offset, templateId })
    .then((invoices) => {
      if (isLazyLoading) {
        dispatch({
          type: billingTypes.BILLING_INVOICES_LAZY_LOADED,
          payload: invoices,
        })
      } else {
        dispatch({
          type: billingTypes.BILLING_INVOICES_LOADED,
          payload: invoices,
        })
      }
    })
    .catch((error) => {
      dispatch({ type: billingTypes.BILLING_INVOICES_FAILED })
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: 'Failed to load billing invoices',
        }),
      )
    })
}

const getLatestsBillingInvoiceDetails = onlyLatestRequest(
  api.billing.getInvoiceDetails,
)

export const loadBillingInvoiceDetails = ({ invoiceId }) => (dispatch) => {
  dispatch({ type: billingTypes.BILLING_INVOICE_DETAILS_REQUESTED })

  getLatestsBillingInvoiceDetails({ invoiceId })
    .then((summary) =>
      dispatch({
        type: billingTypes.BILLING_INVOICE_DETAILS_LOADED,
        payload: summary,
      }),
    )
    .catch((error) => {
      dispatch({ type: billingTypes.BILLING_INVOICE_DETAILS_FAILED })
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: 'Failed to load billing invoice details',
        }),
      )
    })
}

export const resetBillingInvoiceDetails = () => ({
  type: billingTypes.BILLING_INVOICE_DETAILS_RESET,
})

const getLatestsBillingComments = onlyLatestRequest(api.billing.getComments)

export const loadBillingComments = ({ yard, date, templateId }) => (
  dispatch,
) => {
  dispatch({ type: billingTypes.BILLING_COMMENTS_REQUESTED })

  getLatestsBillingComments({ yard, date, templateId })
    .then((comments) =>
      dispatch({
        type: billingTypes.BILLING_COMMENTS_LOADED,
        payload: comments,
      }),
    )
    .catch((error) => {
      dispatch({ type: billingTypes.BILLING_COMMENTS_FAILED })
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: 'Failed to load billing comments',
        }),
      )
    })
}

const getLatestInvoiceExportData = onlyLatestRequest(
  api.billing.getFileToExport,
)

export const loadFileToExport = ({ invoiceId, fileName }) => (dispatch) =>
  getLatestInvoiceExportData({ invoiceId })
    .then((blob) => {
      saveAs(blob, `${fileName}.xlsx`)
    })
    .catch((error) => {
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: 'Failed to load file to export',
        }),
      )
    })

export const getBillingPreview = ({
  submitItems,
  billingTemplateItemId,
  invoiceId,
  fuelPriceIdList,
}) => (dispatch) => {
  dispatch({ type: billingTypes.BILLING_PREVIEW_REQUESTED })

  return api.billing
    .submitPreview({ submitItems, invoiceId, fuelPriceIdList })
    .then((response) =>
      dispatch({
        type: billingTypes.BILLING_PREVIEW_LOADED,
        payload: { ...response, billingTemplateItemId },
      }),
    )
    .catch((error) => {
      dispatch({ type: billingTypes.BILLING_PREVIEW_FAILED })
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: 'Failed to load preview values',
        }),
      )
    })
}

const getLatestInvoiceIntercompanyDetails = onlyLatestRequest(
  api.billing.isSubmitAsFinalEnabled,
)

export const getInvoiceIntercompanyDetails = ({ invoiceId }) => (dispatch) =>
  getLatestInvoiceIntercompanyDetails({ invoiceId })
    .then((response) => response)
    .catch((error) => {
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: 'Failed to load intercompany informations',
        }),
      )
    })

const getLatestInvoiceTable = onlyLatestRequest(api.billing.getTableToExport)

export const loadTableToExport = ({ invoiceId, fileName }) => (dispatch) =>
  getLatestInvoiceTable({ invoiceId })
    .then((blob) => {
      saveAs(blob, `${fileName}.xlsx`)
    })
    .catch((error) => {
      dispatch(
        showErrorAlert({
          error,
          defaultMessage: 'Failed to load file to export',
        }),
      )
    })
