import { connect } from 'react-redux'
import TagsBox from './TagsBox'
import './TagsBox.css'

import {
  loadTags,
  loadYardTags,
  toggleTag,
} from '../../actions/yardSummaryActions'

const mapStateToProps = (state) => ({
  tags: state.yardSummary.tags,
  yardTags: state.yardSummary.yardTags,
  yardId: state.general.selectedYard,
})

const mapDispatchToProps = {
  loadTags,
  loadYardTags,
  toggleTag,
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsBox)
