import { yardSummaryTypes } from '../../actions/yardSummaryActions'

const initialState = {
  value: [],
  loading: false,
}

const flags = (state = initialState, { type, payload }) => {
  switch (type) {
    case yardSummaryTypes.YARD_FLAGS_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case yardSummaryTypes.YARD_FLAGS_LOADED:
      return {
        ...state,
        loading: false,
        value: payload.flags,
      }

    case yardSummaryTypes.YARD_FLAGS_FAILED:
      return initialState

    default:
      return state
  }
}

export default flags
