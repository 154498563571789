import { createSelector } from 'reselect'

const getYardTimeZone = createSelector(
  [(state) => state.general.yardList, (state) => state.general.selectedYard],
  (yardList, selectedYard) => {
    const timezone = yardList
      .filter((yard) => yard.id === selectedYard)
      .map((yard) => yard.timezone)[0]

    return timezone || 'UTC'
  },
)

export default getYardTimeZone
