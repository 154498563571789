import { yardSummaryTypes } from '../../actions/yardSummaryActions'

const initialState = {
  loading: false,
}

const yardTags = (state = initialState, { type }) => {
  switch (type) {
    case yardSummaryTypes.YARD_TAGS_REQUESTED:
      return {
        ...state,
        loading: true,
      }

    case yardSummaryTypes.YARD_TAGS_LOADED:
    case yardSummaryTypes.YARD_TAGS_FAILED:
      return initialState

    default:
      return state
  }
}

export default yardTags
