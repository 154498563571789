import styled from 'styled-components/macro'

import Blank from '../Blank'

import iconSearch from '../../images/icons/search_icon_blue.svg'
import iconChevron from '../../images/icons/icon_chevron.svg'

const colorByStatus = {
  offline: 'var(--base-neutral)',
  online: 'var(--base-green)',
  'missing-driver': 'var(--base-red)',
}

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;

  ::-webkit-scrollbar-thumb {
    background: var(--darker-neutral-45);
    border: 3px solid var(--darker-blue-17);
  }
`
export const Search = styled.input`
  background: transparent;
  color: var(--lighter-neutral-99);
  border: 1px solid var(--darker-neutral-45);
  border-radius: var(--border-radius);
  padding: 0 35px 0 15px;
  height: 45px;
  width: 100%;
  margin: 10px 0;
  font-size: 16px;
  background: url(${iconSearch}) no-repeat center right 15px;
  transition: 0.2s all ease;

  ::placeholder {
    color: var(--base-neutral);
  }

  :focus {
    outline: none;
    border: 1px solid var(--lighter-neutral-85);
  }
`
export const SortButton = styled.button`
  background: transparent;
  border: none;
  transition: 0.2s all ease;
  cursor: pointer;

  :hover {
    color: var(--lighter-neutral-99);
  }

  ::after {
    content: '';
    height: 10px;
    width: 30px;
    display: inline-block;
    transition: 0.2s all ease;

    background-image: url(${iconChevron});
    background-repeat: no-repeat;
    background-position: center;

    ${(p) => p.sortOrder === 'desc' && `transform: rotate(180deg);`}
  }
`
export const DeviceList = styled.div`
  overflow: auto;
  width: 100%;
  padding: 0 15px;
  background-color: var(--darker-blue-17);

  height: ${(p) =>
    p.viewMode === 'live' ? 'calc(90vh - 415px)' : 'calc(90vh - 335px)'};

  ::-webkit-scrollbar-thumb {
    background: var(--darker-neutral-45);
    border: 3px solid var(--darker-blue-17);
  }
`
export const DeviceDriver = styled.span`
  font-family: 'ArticulatCF-Bold';
`
export const DeviceVehicle = styled.span`
  font-family: 'ArticulatCF-Medium';
`
export const DeviceItem = styled.div`
  width: 100%;
  border: none;
  padding: 15px 0;
  display: grid;
  grid-template-columns: 20px 1fr 35px 45px;
  grid-gap: 5px;
  align-items: center;
  background: transparent;
  color: var(--base-neutral);

  :not(:last-of-type) {
    border-bottom: 1px solid var(--darker-neutral-45);
  }

  :hover {
    cursor: pointer;
    color: var(--lighter-neutral-99);
  }
`
export const StatusBadge = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;

  background: ${(p) => p.deviceStatus && `${colorByStatus[p.deviceStatus]}`};
`
export const TableActions = styled.div`
  font-family: 'ArticulatCF-Bold';
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 12px 15px;
  color: var(--base-neutral);
  cursor: default;
`
export const StyledBlank = styled(Blank)`
  padding: 22px 0;
`
export const DetectedMovesMetric = styled.div`
  text-align: center;
  border-radius: var(--border-radius);

  ${(p) => p.boxMoves && `background: var(--darker-purple-29)`};
  ${(p) => p.turnAround && `background: var(--darker-blue-29)`};
`
