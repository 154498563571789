import _ from 'lodash'
import humanizeDuration from './humanizeDuration'

export default function getDetectedMoves({ userId, detectedMoves, isYard }) {
  if (!isYard && _.isNil(userId)) {
    return {
      turnAroundTime: '-',
      boxMoves: '-',
    }
  }

  const objectPath = userId
    ? `metrics.turnAroundTimeByUser[${userId}]`
    : 'metrics.yardTurnAroundTime'

  const fallback = userId ? '-' : 'N/A'

  const userTurnAroundAverage = _.get(
    detectedMoves,
    `${objectPath}.turnAroundTimeAverage`,
  )

  const turnAroundTime = _.isNil(userTurnAroundAverage)
    ? fallback
    : humanizeDuration(userTurnAroundAverage, { isShort: true })

  const boxMoves = _.get(detectedMoves, `${objectPath}.movesCount`, fallback)

  return { turnAroundTime, boxMoves }
}
