import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import VehicleStatusSelector from '../VehicleStatusSelector'

import { getVehiclesByStatus } from '../helpers'

import { Na, NewTable } from '../../../components'

import { formatSessionStatus, history } from '../../../utils'
import { getDateTime } from '../../../utils/momentExtensions'

import batteryImage from '../../../images/assets/battery.svg'
import batteryChargeImage from '../../../images/assets/low_battery.svg'
import vehiclesIcon from '../../../images/tooltip/vehicles.svg'
import infoIcon from '../../../images/tooltip/info.svg'

import './VehiclesList.css'
import * as S from '../styles'

const headers = [
  {
    key: 0,
    text: 'LAST COMMS',
    toolTipTitle: 'Last Communication',
    toolTipTitleIcon: vehiclesIcon,
    toolTipDescription: 'Last time that data from this vehicle was received',
    toolTipAction: 'Click to sort the last communication data',
    actionType: 'sort',
  },
  {
    key: 1,
    text: 'VEHICLE',
    toolTipTitle: 'Vehicle',
    toolTipTitleIcon: infoIcon,
    toolTipDescription: 'Name of the vehicle',
    toolTipAction: 'Click to sort the vehicles',
    actionType: 'sort',
  },
  {
    key: 2,
    text: 'BATTERY',
    toolTipTitle: 'Battery',
    toolTipTitleIcon: infoIcon,
    toolTipDescription:
      'Indication of current battery level or if the device is charging',
    toolTipAction: 'Click to sort the battery data',
    actionType: 'sort',
  },
  {
    key: 3,
    text: 'ACTIVITY',
    toolTipTitle: 'Activity',
    toolTipTitleIcon: infoIcon,
    toolTipDescription: 'Current device activity status',
    toolTipAction: 'Click to sort devices activity',
    actionType: 'sort',
  },
  {
    key: 4,
    text: 'E.H',
    toolTipTitle: 'Engine Hours',
    toolTipTitleIcon: infoIcon,
    toolTipDescription: 'Current device activity status',
    toolTipAction: 'Click to sort the engine hours data',
    actionType: 'sort',
  },
  {
    key: 5,
    text: 'S.VERSION',
    toolTipTitle: 'Software Version',
    toolTipTitleIcon: infoIcon,
    toolTipDescription: 'Version of the INTRMODL app',
    toolTipAction: 'Click to sort the software version data',
    actionType: 'sort',
  },
  {
    key: 6,
    text: 'STATUS',
    toolTipTitle: 'Status',
    toolTipTitleIcon: infoIcon,
    toolTipDescription:
      'Status of a vehicle - Operational, Repairs Needed, or Out of Service. The date is the time of last status change or the last VCR',
    toolTipAction: 'Click to change the vehicle status',
    actionType: 'sort',
  },
]

const getActivity = (vehicleDevicesItem) => {
  if (_.isNil(vehicleDevicesItem)) {
    return null
  }

  const { status, lastcomms } = vehicleDevicesItem

  const lastCommsInDays = moment
    .duration(moment().diff(moment(lastcomms)))
    .asDays()

  if (lastCommsInDays > 2) {
    return 'offline'
  }
  return status
}

const renderActivityOrOffline = (activity) => {
  if (activity === null) {
    return <Na />
  }
  if (activity === 'offline') {
    return <Na text="Offline" />
  }
  return formatSessionStatus(activity)
}

class ManageVehicles extends Component {
  componentDidMount() {
    const { loadDeviceStatuses, selectedYard } = this.props

    loadDeviceStatuses(selectedYard)

    this.loadAllVehicleData()
  }

  componentDidUpdate(prevProps) {
    const { selectedYard } = this.props

    if (selectedYard !== prevProps.selectedYard) {
      this.loadAllVehicleData()
    }

    return true
  }

  renderBattery = (value) => {
    if (_.isNil(value)) {
      return <Na />
    }

    const batteryStateImage = value < 0 ? batteryChargeImage : batteryImage
    return (
      <>
        {value >= 0 && `${value}%`}
        <S.Battery src={batteryStateImage} alt="battery" />
      </>
    )
  }

  handleLoadVehicleDevices = () => {
    this.loadAllVehicleData()
  }

  tooltipDescription = (device, vehicle) => (
    <>
      <div>
        <b>Device: </b> {_.get(device, 'model', 'None')}
      </div>
      <div>
        <b>IMEI: </b> {_.get(device, 'device_imei', 'N/A')}
      </div>
      <div>
        <b>iDrive: </b> {_.get(vehicle, 'idrive_code') || 'N/A'}
      </div>
      <div>
        <b>Make: </b> {_.get(vehicle, 'make') || 'N/A'}
      </div>
      <div>
        <b>Model: </b> {_.get(vehicle, 'model') || 'N/A'}
      </div>
      <div>
        <b>Year: </b> {_.get(vehicle, 'year') || 'N/A'}
      </div>
    </>
  )

  loadAllVehicleData() {
    const { selectedYard, loadVehicles, loadVehicleDevices } = this.props
    loadVehicles(selectedYard)
    loadVehicleDevices(selectedYard)
  }

  render() {
    const {
      vehicleList: { value: vehicleListValue, loading },
      vehicleDevices,
      vehicleSort,
      setSortOrder,
      setSortedHeader,
      tableSortOption,
      tableSortHeader,
      search,
      timezone,
      hasStatusWriteAccess,
    } = this.props

    const rows = vehicleListValue.map((vehicle) => {
      const vehicleDevicesItem = vehicleDevices.value.find(
        (device) => device.vehicle_id === vehicle.id,
      )
      const activity = getActivity(vehicleDevicesItem)
      const lastComms = vehicleDevicesItem
        ? getDateTime(vehicleDevicesItem.lastcomms, timezone)
        : null

      return {
        columns: {
          0: {
            value: _.defaultTo(lastComms, ''),
            render: _.defaultTo(lastComms, <Na />),
          },
          1: { value: _.defaultTo(vehicle.vehicle_name, '') },
          2: {
            value: _.defaultTo(_.get(vehicleDevicesItem, 'battery'), ''),
            render: this.renderBattery(_.get(vehicleDevicesItem, 'battery')),
          },
          3: {
            value: _.defaultTo(activity, ''),
            render: renderActivityOrOffline(activity),
          },
          4: {
            value: _.defaultTo(_.get(vehicle, 'engine_hours'), ''),
            render: _.defaultTo(_.get(vehicle, 'engine_hours'), <Na />),
          },
          5: {
            value: _.defaultTo(_.get(vehicleDevicesItem, 'version'), ''),
            render: _.defaultTo(_.get(vehicleDevicesItem, 'version'), <Na />),
          },
          6: {
            value: getDateTime(_.get(vehicle, 'updated_at'), timezone),
            render: (
              <VehicleStatusSelector
                data={vehicle}
                isDisabled={!hasStatusWriteAccess}
              />
            ),
          },
        },
        onClick: () => history.push(`/vehicles/details/${vehicle.id}`),
        toolTipTitle: 'Additional Vehicle Information',
        toolTipDescription: this.tooltipDescription(
          vehicleDevicesItem,
          vehicle,
        ),
        toolTipTitleIcon: infoIcon,
        status: vehicle.status,
        id: vehicle.id,
      }
    })

    const sortedRows = _.orderBy(
      getVehiclesByStatus({ vehicles: rows, vehicleSort }),
      (row) => row.columns[tableSortHeader].value,
      [tableSortOption],
    )

    const handleSortedHeader = (headerIndex) => {
      setSortOrder(tableSortOption === 'desc' ? 'asc' : 'desc')
      setSortedHeader(headerIndex)
    }

    return (
      <NewTable
        headers={headers}
        rows={sortedRows}
        searchString={search}
        sortedHeader={tableSortHeader}
        onSortedHeaderChange={handleSortedHeader}
        sortOrder={tableSortOption}
        isLoading={loading || vehicleDevices.loading}
      />
    )
  }
}

ManageVehicles.propTypes = {
  loadVehicles: PropTypes.func,
  vehicleList: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
    order: PropTypes.shape({
      column: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  selectedYard: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loadVehicleDevices: PropTypes.func.isRequired,
  vehicleDevices: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  loadDeviceStatuses: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  setSortedHeader: PropTypes.func.isRequired,
  tableSortOption: PropTypes.string.isRequired,
  tableSortHeader: PropTypes.number.isRequired,
  vehicleSort: PropTypes.string.isRequired,
  search: PropTypes.string,
  timezone: PropTypes.string.isRequired,
  hasStatusWriteAccess: PropTypes.bool.isRequired,
}

ManageVehicles.defaultProps = {
  loadVehicles: () => undefined,
  selectedYard: 0,
  search: null,
}

export default ManageVehicles
