import _ from 'lodash'

export default function searchObjectArray({ searchString, tableRows }) {
  const loweredSearchString = _.lowerCase(searchString)

  return tableRows
    .filter((row) =>
      _.values(row.columns).filter((column) => {
        if (_.isNil(column)) {
          throw new Error("column can't be null or undefined")
        }

        return _.defaultTo(column.value, '')
          .toString()
          .toLowerCase()
          .includes(loweredSearchString)
      }).length > 0
        ? row
        : false,
    )
    .filter(Boolean)
}
