import { includes } from 'ramda'
import { roleIdToName } from '../../config'
import { PEOPLE_WRITE_ROLES } from '../config'
import hasPermissionLevelAccess from '../utils/hasPermissionLevelAccess'

export default function canCreate({ loggedUser, targetUser }) {
  return (
    includes(roleIdToName[loggedUser.role_id], PEOPLE_WRITE_ROLES) &&
    hasPermissionLevelAccess({ loggedUser, targetUser })
  )
}
