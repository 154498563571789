import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showDeletedTemplates: false,
}

const billingTemplateVersions = createSlice({
  name: 'billingTemplateVersionsSlice',
  initialState,
  reducers: {
    setShowDeletedTemplates: (state, action) => ({
      ...state,
      showDeletedTemplates: action.payload,
    }),
    resetSliceData: () => initialState,
  },
})

export const {
  setShowDeletedTemplates,
  resetSliceData,
} = billingTemplateVersions.actions
export default billingTemplateVersions.reducer
