import _ from 'lodash'
import { billingTypes } from '../../actions/billingActions'

const initialState = {
  value: {},
  isLoading: false,
}

function mergeTiersResponseIntoState({ payload, state }) {
  const value = _.get(state, 'value')
  const billingTemplateItemId = _.get(payload, 'billingTemplateItemId')
  const derivedServices = _.get(payload, 'deriveds', [])
  const prodSavingsServices = _.get(payload, 'prod_savings', [])
  const tiers = _.get(payload, 'tiers', [])

  const servicetoUpdate = _.get(value, 'summary').find(
    (item) => item.billing_template_item_id === billingTemplateItemId,
  )

  if (!_.isNil(servicetoUpdate)) {
    servicetoUpdate.tiers = tiers
  }

  const defaultServices = _.get(value, 'summary', []).filter(
    (item) =>
      item.filling_type !== 'derived' && item.filling_type !== 'prod_sav',
  )

  return {
    ...value,
    summary: [...defaultServices, ...derivedServices, ...prodSavingsServices],
  }
}

const billingInvoiceDetails = (state = initialState, { type, payload }) => {
  switch (type) {
    case billingTypes.BILLING_INVOICE_DETAILS_REQUESTED:
    case billingTypes.BILLING_PREVIEW_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }
    case billingTypes.BILLING_INVOICE_DETAILS_LOADED:
      return {
        ...state,
        isLoading: false,
        value: payload,
      }
    case billingTypes.BILLING_PREVIEW_LOADED:
      return {
        ...state,
        isLoading: false,
        value: mergeTiersResponseIntoState({ payload, state }),
      }
    case billingTypes.BILLING_INVOICE_DETAILS_FAILED:
    case billingTypes.BILLING_PREVIEW_FAILED:
    case billingTypes.BILLING_INVOICE_DETAILS_RESET:
      return initialState

    default:
      return state
  }
}

export default billingInvoiceDetails
