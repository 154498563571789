import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie'
import animationData from '../../images/lotties/intrmodl_logo.json'

import * as S from './styles'

const lottieDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const Login = ({ loginError, login }) => {
  const [employeeId, setEmployeeId] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    setEmployeeId('')
    setPassword('')
  }, [loginError])

  function handleSubmit(e) {
    e.preventDefault()
    login(employeeId, password)
  }

  return (
    <S.Container>
      <S.Box>
        <Lottie options={lottieDefaultOptions} width={230} height={125} />
        <S.Label>RMS Intermodal Productivity Tool</S.Label>
        <form onSubmit={handleSubmit}>
          <S.Input
            placeholder="Employee ID"
            value={employeeId}
            type="tel"
            onChange={({ target }) => setEmployeeId(target.value)}
            required
            autoFocus
          />
          <S.Input
            placeholder="Password"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            type="password"
            required
          />
          <S.Warning>{loginError}</S.Warning>
          <S.Button>Log in</S.Button>
          <S.StyledLink to="/forgot-password">
            Forgot your password?
          </S.StyledLink>
        </form>
      </S.Box>
    </S.Container>
  )
}

Login.propTypes = {
  loginError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  login: PropTypes.func,
}

Login.defaultProps = {
  loginError: null,
  login: () => undefined,
}

export default Login
