import clientApi from './utils/clientApi'

const getAll = ({ auditFunctionTypeId }) =>
  clientApi({
    method: 'get',
    url: `/audit_violation_types`,
    params: {
      audit_function_type_id: auditFunctionTypeId,
    },
  }).then((response) => response.data.audit_violation_types)

const users = {
  getAll,
}

export default users
