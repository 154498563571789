import isPlayBackLoading from './isPlayBackLoading'

const getTrackingDataForHistoryTime = ({
  historyData,
  currentHistoryTime,
  historyTimeSeries,
}) => {
  const currentDataForTime = historyData[currentHistoryTime]

  const vehicleIds = isPlayBackLoading({
    historyTimeSeries,
    currentHistoryTime,
  })
    ? []
    : Object.keys(currentDataForTime)

  const vehiclesAtCurrentTime = vehicleIds.map(
    (vehicleId) => currentDataForTime[vehicleId],
  )

  return vehiclesAtCurrentTime
}

const addTrackingDataToHistoryTime = (state) =>
  Object.assign(state, {
    trackingDataForHistoryTime: getTrackingDataForHistoryTime(state),
  })

export default addTrackingDataToHistoryTime
