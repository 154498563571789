import { toolkitApi } from '.'

const companiesApi = toolkitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: () => ({
        url: 'billing_companies',
      }),
      transformResponse: (response) => response.companies,
    }),
    getCompany: builder.query({
      query: ({ companyId }) => ({
        url: `billing_companies/${companyId}`,
      }),
      transformResponse: (response) => response.company,
    }),
    addCompany: builder.mutation({
      query: ({ body }) => ({
        url: 'billing_companies',
        method: 'POST',
        body,
      }),
    }),
    updateCompany: builder.mutation({
      query: ({ body, companyId }) => ({
        url: `billing_companies/${companyId}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCompany: builder.mutation({
      query: ({ companyId }) => ({
        url: `billing_companies/${companyId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useAddCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} = companiesApi
