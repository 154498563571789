import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import * as S from './styles'

const SortTab = ({
  sortKey,
  sortKeyList,
  handleSortButton,
  sortNotification,
  locked,
}) => (
  <S.Container>
    {sortKeyList.map((keyName, index) => {
      const notification = sortNotification.find(
        (notificationItem) =>
          notificationItem.label.toLowerCase() === keyName.toLowerCase(),
      )

      const hasNotification = !_.isNil(notification) && notification.value > 0

      return (
        <S.Button
          type="button"
          key={keyName}
          onClick={() => handleSortButton(keyName.toLowerCase())}
          active={keyName.toLowerCase() === sortKey.toLowerCase()}
          locked={locked.includes(index)}
        >
          {keyName}
          {hasNotification && !locked.includes(index) && <S.Notification />}
        </S.Button>
      )
    })}
  </S.Container>
)

SortTab.propTypes = {
  sortKey: PropTypes.string.isRequired,
  handleSortButton: PropTypes.func.isRequired,
  sortKeyList: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortNotification: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  locked: PropTypes.arrayOf(PropTypes.number),
}

SortTab.defaultProps = {
  sortNotification: [],
  locked: [],
}

export default SortTab
