import Promise from 'bluebird'
import clientApi from './utils/clientApi'

const login = ({ username, password }) =>
  clientApi({
    method: 'post',
    url: '/login',
    data: {
      username,
      password,
      platform: 'web',
    },
  }).then(({ data }) => data)

const verifyToken = () =>
  clientApi({
    method: 'get',
    url: '/login/verify',
  }).then(({ data }) => data)

const resetPassword = ({ userId, newPassword }) =>
  Promise.resolve({
    method: 'put',
    url: `/users/resetpassword/${userId}`,
    data: {
      newPassword,
    },
  })
    .then(clientApi)
    .then((res) => res)

const revokeToken = ({ userId }) =>
  clientApi({
    method: 'post',
    url: `/login/revoke/${userId}`,
  }).then((res) => res)

const logout = () =>
  clientApi({
    method: 'post',
    url: '/logout',
  }).then((res) => res)

const auth = {
  login,
  verifyToken,
  resetPassword,
  revokeToken,
  logout,
}

export default auth
