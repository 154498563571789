import { connect } from 'react-redux'
import { path } from 'ramda'
import Billing from './Billing'
import {
  loadBillingTable,
  upsertBillingTable,
  loadBillingWeeks,
  getFirstMissingDate,
  setMissingDateAlert,
} from '../../actions/billingActions'
import { getYardTimeZone } from '../../selectors'
import { showAlert } from '../../actions/alertMessageActions'

const mapStateToProps = (state, ownProps) => {
  const timezone = getYardTimeZone(state)
  return {
    timezone,
    selectedYard: state.general.selectedYard,
    billingTable: state.billing.billingTable,
    billingMissingDateAlert: state.billing.billingMissingDateAlert,
    billingWeeks: state.billing.billingWeeks,
    urlDate: ownProps.params.date,
    userRole: path(['Role', 'name'])(state.general.userData),
    templateId: ownProps.location.query.template_id,
  }
}

const mapDispatchToProps = {
  showAlert,
  loadBillingTable,
  upsertBillingTable,
  loadBillingWeeks,
  getFirstMissingDate,
  setMissingDateAlert,
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing)
