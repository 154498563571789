import { connect } from 'react-redux'
import Map from './Map'

import { setZoomLevel, setCenter } from '../../actions/trackingActions'

import { getYard } from '../../selectors'

const mapStateToProps = (state) => ({
  yard: getYard(state),
  zoomLevel: state.tracking.zoomLevel,
  mapCenter: state.tracking.mapCenter,
})

const mapDispatchToProps = {
  setZoomLevel,
  setCenter,
}

export default connect(mapStateToProps, mapDispatchToProps)(Map)
