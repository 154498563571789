import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { getDetectedMoves } from '../../utils'

import * as S from './styles'

const DevicesPanelWorkersTable = ({
  deviceData,
  handleDeviceHover,
  handleDeviceClick,
  showOfflineVehicles,
  detectedMoves,
  viewMode,
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [workerSortOrder, setWorkerSortOrder] = useState('desc')
  const [boxMovesSortOrder, setBoxMovesSortOrder] = useState('asc')
  const [selectedSortOption, setSelectedSortOption] = useState('workers')

  function handleSearch({ target }) {
    const { value } = target
    setSearchQuery(value)
  }

  function sortByMoves() {
    return deviceData.sort((a, b) => {
      const getMoves = (user) =>
        _.get(
          getDetectedMoves({ userId: user.userId, detectedMoves }),
          'boxMoves',
        )

      return boxMovesSortOrder === 'desc'
        ? getMoves(a) - getMoves(b)
        : getMoves(b) - getMoves(a)
    })
  }

  function sortByStatus({ status }) {
    return {
      online: 1,
      offline: 0,
      'missing-driver': -1,
    }[status]
  }

  function sortByAlphabeticalAndStatusValues() {
    return deviceData.sort((a, b) =>
      workerSortOrder === 'desc'
        ? sortByStatus({ status: b.deviceStatus }) -
            sortByStatus({ status: a.deviceStatus }) ||
          a.userShortName.localeCompare(b.userShortName)
        : sortByStatus({ status: b.deviceStatus }) -
            sortByStatus({ status: a.deviceStatus }) ||
          b.userShortName.localeCompare(a.userShortName),
    )
  }

  function handleDeviceSort() {
    setSelectedSortOption('workers')
    setWorkerSortOrder(workerSortOrder === 'desc' ? 'asc' : 'desc')
  }

  function handleMovesSort() {
    setSelectedSortOption('moves')
    setBoxMovesSortOrder(boxMovesSortOrder === 'desc' ? 'asc' : 'desc')
  }

  function deviceMatchSearchByKey({ device, key }) {
    return _.get(device, key, '')
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  }

  const orderedDevices = () =>
    ({
      workers: sortByAlphabeticalAndStatusValues(),
      moves: sortByMoves(),
    }[selectedSortOption])

  const availableDevices = showOfflineVehicles
    ? orderedDevices()
    : orderedDevices().filter((device) => device.deviceStatus !== 'offline')

  const filteredDevices = availableDevices.filter(
    (device) =>
      deviceMatchSearchByKey({ device, key: 'vehicleName' }) ||
      deviceMatchSearchByKey({ device, key: 'userShortName' }),
  )

  return (
    <S.Container>
      <S.Search
        placeholder="Search"
        type="search"
        onChange={handleSearch}
        value={searchQuery}
        data-analytics="vehicle-user-search-form"
      />

      <S.TableActions>
        <S.SortButton onClick={handleDeviceSort} sortOrder={workerSortOrder}>
          Workers A - Z
        </S.SortButton>
        {viewMode === 'live' && (
          <S.SortButton onClick={handleMovesSort} sortOrder={boxMovesSortOrder}>
            Box Count
          </S.SortButton>
        )}
      </S.TableActions>

      <S.DeviceList viewMode={viewMode}>
        {!filteredDevices.length && <S.StyledBlank small />}

        {filteredDevices.map(
          ({ userShortName, vehicleName, vehicleId, deviceStatus, userId }) => {
            const userDetectedMoves = getDetectedMoves({
              userId,
              detectedMoves,
            })

            return (
              (userShortName || vehicleName) && (
                <S.DeviceItem
                  key={vehicleId}
                  deviceStatus={deviceStatus}
                  onMouseEnter={() => handleDeviceHover(vehicleId)}
                  onFocus={() => handleDeviceHover(vehicleId)}
                  onMouseLeave={() => handleDeviceHover(null)}
                  onClick={(event) => handleDeviceClick(event, vehicleId)}
                >
                  <S.StatusBadge deviceStatus={deviceStatus} />
                  <div>
                    {userShortName && (
                      <S.DeviceDriver>{`${userShortName} | `}</S.DeviceDriver>
                    )}
                    <S.DeviceVehicle>{vehicleName}</S.DeviceVehicle>
                  </div>
                  {viewMode === 'live' && (
                    <>
                      <S.DetectedMovesMetric boxMoves>
                        {_.get(userDetectedMoves, 'boxMoves')}
                      </S.DetectedMovesMetric>
                      <S.DetectedMovesMetric turnAround>
                        {_.get(userDetectedMoves, 'turnAroundTime')}
                      </S.DetectedMovesMetric>
                    </>
                  )}
                </S.DeviceItem>
              )
            )
          },
        )}
      </S.DeviceList>
    </S.Container>
  )
}

DevicesPanelWorkersTable.propTypes = {
  showOfflineVehicles: PropTypes.bool,
  handleDeviceHover: PropTypes.func.isRequired,
  handleDeviceClick: PropTypes.func.isRequired,
  deviceData: PropTypes.arrayOf(
    PropTypes.shape({
      vehicleId: PropTypes.number,
      vehicleName: PropTypes.string,
      userId: PropTypes.number,
      userShortName: PropTypes.string,
      deviceStatus: PropTypes.oneOf(['online', 'offline', 'missing-driver']),
    }),
  ).isRequired,
  detectedMoves: PropTypes.shape({
    loading: PropTypes.bool,
    metrics: PropTypes.shape({
      yardTurnAroundTime: PropTypes.shape({
        turnAroundTimeAverage: PropTypes.number,
        movesCount: PropTypes.number,
      }),
      turnAroundTimeByUser: PropTypes.object,
    }),
  }),
  viewMode: PropTypes.oneOf(['live', 'history']).isRequired,
}

DevicesPanelWorkersTable.defaultProps = {
  showOfflineVehicles: false,
  detectedMoves: {},
}

export default DevicesPanelWorkersTable
