import { manageVehiclesTypes } from '../../actions/manageVehiclesActions'

const initialState = 'desc'

const tableSortOption = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVehiclesTypes.LIST_ORDERING_CHANGED:
      return payload

    default:
      return state
  }
}

export default tableSortOption
