import React from 'react'
import moment from 'moment-timezone'

import { getStartOfEditablePeriod } from '../../../utils'

import getHasSomeMissingValue from './getHasSomeMissingValue'
import getEditablePeriod from './getEditablePeriod'
import getHasSomeCompletedValue from './getHasSomeCompletedValue'

import iconWarning from '../../../images/icons/icon_warning_dark.svg'
import iconCheckmark from '../../../images/icons/icon_checkmark_dark.svg'
import iconPartial from '../../../images/icons/icon_warning_partial.svg'

import * as S from '../styles'

function getEngineHoursTableColumns({
  columns = [],
  rows,
  timezone,
  canChangeAnyTime,
}) {
  const parsedColumns = columns.map((column, index) => {
    const startOfColumnMonth = moment.tz(column, timezone).startOf('month')

    const { startDate, endDate } = getEditablePeriod({ date: column, timezone })

    const isFutureDate = moment
      .tz(timezone)
      .isBefore(
        getStartOfEditablePeriod({ date: startOfColumnMonth, timezone }),
      )

    const isAfterStart = moment.tz(timezone).isSameOrAfter(startDate)
    const isBeforeEnd = moment.tz(timezone).isBefore(endDate)
    const isEditable = isAfterStart && (canChangeAnyTime || isBeforeEnd)

    const missingValuesPayload = {
      rows,
      index,
    }

    const missingValues = getHasSomeMissingValue(missingValuesPayload)
    const hasPartialValues = getHasSomeCompletedValue(missingValuesPayload)

    const badgeText = (() => {
      if (isFutureDate) {
        return 'tbd'
      }

      if (!missingValues) {
        return 'done'
      }

      if (hasPartialValues) {
        return 'partial'
      }

      return 'empty'
    })()

    const getBadgeIconByStatus = ({ status }) =>
      ({
        tbd: null,
        done: iconCheckmark,
        empty: iconWarning,
        partial: iconPartial,
      }[status])

    return {
      key: index,
      isEditable,
      isFutureDate,
      missingValues,
      text: (
        <S.HeaderDay>
          <S.Badge status={badgeText}>
            {badgeText && (
              <S.Icon src={getBadgeIconByStatus({ status: badgeText })} />
            )}
            {badgeText}
          </S.Badge>
          <span>{startOfColumnMonth.format("'YY ")}</span>
          <b>{startOfColumnMonth.format('| MMM')}</b>
        </S.HeaderDay>
      ),
    }
  })

  parsedColumns.unshift(
    {
      key: 'last_vcr',
      text: 'LAST VCR',
      isFutureDate: false,
    },
    {
      key: 'year_to_date',
      text: 'YTD',
      isFutureDate: false,
    },
  )

  return parsedColumns
}

export default getEngineHoursTableColumns
