import { combineReducers } from '@reduxjs/toolkit'
import employees from './employees'
import supervisors from './supervisors'
import upsertAudit from './upsertAudit'
import auditFunctionTypes from './auditFunctionTypes'
import auditViolationTypes from './auditViolationTypes'
import auditCorrectiveActionTypes from './auditCorrectiveActionTypes'

const upsertAuditReducer = combineReducers({
  employees,
  supervisors,
  upsertAudit,
  auditFunctionTypes,
  auditViolationTypes,
  auditCorrectiveActionTypes,
})

export default upsertAuditReducer
