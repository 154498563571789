import PropTypes from 'prop-types'
import moment from 'moment-timezone'

function getDateBetween({ startDate, endDate, urlDate, timezone }) {
  return (
    moment.tz(startDate, timezone) <= moment.tz(urlDate, timezone) &&
    moment.tz(endDate, timezone) >= moment.tz(urlDate, timezone)
  )
}

PropTypes.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  urlDate: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
}

export default getDateBetween
