import _ from 'lodash'

/**
 * Replace underscore for space and
 * capitalize the first letter
 * @param {string} status - The session status
 * @returns {string} Formatted string
 */
const formatSessionStatus = (status) =>
  _.chain(status).replace('_', ' ').capitalize().value()

export default formatSessionStatus
