import { connect } from 'react-redux'
import CreateUser from './CreateUser'
import './CreateUser.css'
import { saveUser, loadUser, resetUser } from '../../actions/createUserActions'
import { showAlert } from '../../actions/alertMessageActions'
import { parseIntOrUndefined } from '../../utils'

const mapStateToProps = (state, ownProps) => ({
  userIdParam: parseIntOrUndefined(ownProps.params.user_id),
  user: state.createUser.user,
  selectedYard: Number(state.general.selectedYard),
  path: ownProps.route.path,
  yardList: state.general.yardList,
  loggedUser: state.general.userData,
})

const mapDispatchToProps = {
  loadUser,
  saveUser,
  showAlert,
  resetUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser)
