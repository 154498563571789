import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router'
import _ from 'lodash'
import uniqueId from 'react-html-id'

import {
  Gallery,
  CommentBox,
  IssueHeader,
  IssueList,
  Na,
  Loading,
  NewContainer,
  NewHeader,
  Blank,
} from '../../components'

import VehicleStatusSelector from '../ManageVehicles/VehicleStatusSelector'
import VcrVehicleTabs from './VcrVehicleTabs'

import { getDateByDays, getDateTime } from '../../utils/momentExtensions'
import { history, getUserFullName } from '../../utils'

import batteryImage from '../../images/assets/battery.svg'
import batteryChargeImage from '../../images/assets/low_battery.svg'
import editIcon from '../../images/icons/icon_edit_dark.svg'
import commentIconActive from '../../images/icons/comment.svg'
import commentIconInactive from '../../images/icons/comment-grey.svg'
import vcrIconActive from '../../images/icons/vcr_icon.svg'
import vcrIconInactive from '../../images/icons/vcr_grey.svg'
import vcrIssueIcon from '../../images/assets/vcr_issue.svg'
import vcrOkIcon from '../../images/assets/no_issue.svg'
import tooltipEditIcon from '../../images/tooltip/edit.svg'

import './Vehicle.css'

const rowHeader = {
  lastcomms: 'LAST COMMS.',
  full_name: 'NAME',
  name: 'TYPE',
  engine_hours: 'E.H',
  version: 'S.VERSION',
  battery: 'BATTERY',
  status: 'STATUS',
}

const vcrListHeaders = ['CREATED', 'CREATED BY', 'ENGINE HRS', 'ISSUES']

class Vehicle extends Component {
  static propTypes = {
    yardId: PropTypes.number.isRequired,
    loadVehicle: PropTypes.func,
    postVehicleCommentState: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.string),
    vehicleData: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.array,
        PropTypes.arrayOf(
          PropTypes.objectOf(
            PropTypes.oneOfType([
              PropTypes.number,
              PropTypes.string,
              PropTypes.object,
            ]),
          ),
        ),
        PropTypes.object,
      ]),
    ),
    userData: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
        PropTypes.object,
      ]),
    ),
    postVehicleComment: PropTypes.func,
    deviceData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      value: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    isSidebarCollapsed: PropTypes.bool.isRequired,
    loadVcrList: PropTypes.func.isRequired,
    vcrList: PropTypes.shape({
      isLoading: PropTypes.bool.isRequired,
      value: PropTypes.arrayOf(
        PropTypes.shape({
          User: PropTypes.shape({
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            role: PropTypes.string,
          }),
          submitted_at: PropTypes.string,
          engine_hours: PropTypes.number,
          issues: PropTypes.arrayOf(PropTypes.string),
          status: PropTypes.string,
        }),
      ).isRequired,
    }).isRequired,
    timezone: PropTypes.string.isRequired,
    resetVehicle: PropTypes.func.isRequired,
  }

  static defaultProps = {
    loadVehicle: () => undefined,
    postVehicleCommentState: '',
    params: {},
    vehicleData: {},
    userData: {},
    postVehicleComment: () => undefined,
  }

  constructor(props) {
    super(props)
    uniqueId.enableUniqueIds(this)
  }

  componentDidMount() {
    const {
      loadVehicle,
      params: { vehicle_id: vehicleId },
      loadVcrList,
    } = this.props

    loadVehicle(vehicleId)
    loadVcrList({ vehicleId, limit: 40, offset: 0 })
  }

  componentDidUpdate(prevProps) {
    const { yardId } = this.props
    if (prevProps.yardId !== yardId) {
      history.push('/vehicles')
    }
  }

  componentWillUnmount() {
    const { resetVehicle } = this.props
    resetVehicle()
  }

  renderLastComms = (time) => (
    <div>
      {getDateByDays(time)}
      <p>{moment(time).format('H:mm')}</p>
    </div>
  )

  renderBattery = (value) => {
    const batteryStateImage = value < 0 ? batteryChargeImage : batteryImage
    return (
      <p>
        <img src={batteryStateImage} alt="battery" />
        {value >= 0 && `${value}%`}
      </p>
    )
  }

  dataFormat = (data) => {
    const { deviceData, timezone } = this.props
    const deviceDataItem = deviceData.value.filter(
      (item) => item.vehicle_id === data.id,
    )[0]

    return {
      ...data,
      lastcomms: deviceDataItem ? (
        getDateTime(deviceDataItem.lastcomms, timezone)
      ) : (
        <Na />
      ),
      full_name: data.first_name ? (
        `${data.first_name} ${data.last_name}`
      ) : (
        <Na />
      ),
      version: deviceDataItem ? deviceDataItem.version : <Na />,
      battery: deviceDataItem ? (
        this.renderBattery(deviceDataItem.battery)
      ) : (
        <Na />
      ),
      status: <VehicleStatusSelector data={data} />,
    }
  }

  sendComment = (comment) => {
    const { userData, postVehicleComment, params } = this.props
    const commentData = {
      User: {
        first_name: userData.first_name,
        last_name: userData.last_name,
        Role: {
          name: userData.Role.name,
        },
      },
      created_at: new Date().toISOString(),
      comment,
    }

    return postVehicleComment(params.vehicle_id, commentData)
  }

  renderVcrList() {
    const { vcrList, timezone } = this.props

    if (vcrList.isLoading) {
      return <Loading />
    }

    if (!vcrList.isLoading && _.isEmpty(vcrList.value)) {
      return <Blank title="There are no VCR's yet" type="search" />
    }

    return (
      <div className="vcrList-container">
        <div className="vcrList-header">
          {vcrListHeaders.map((headerName) => (
            <div key={headerName}>{headerName}</div>
          ))}
        </div>
        <div className="vcrList-body">
          {vcrList.value.map((vcr) => (
            <Link to={`/vcrs/${vcr.id}`} key={vcr.id}>
              <div className="vcrList-row">
                <div className="vcrList-row-item">
                  {moment.tz(vcr.submitted_at, timezone).format('MM.DD.YY')}
                  <span>
                    {moment.tz(vcr.submitted_at, timezone).format('HH:mm')}
                  </span>
                </div>
                <div className="vcrList-row-item">
                  {`${getUserFullName(vcr.User)}`}
                  <span>{_.get(vcr, 'User.roleName', '')}</span>
                </div>
                <div className="vcrList-row-item">{vcr.engine_hours}</div>
                <div className="vcrList-row-item issue">
                  {vcr.issues.map((issue) => (
                    <span key={issue}>{issue}</span>
                  ))}
                </div>
                <div className="vcrList-row-item icon">
                  <img
                    src={vcr.status === 'open' ? vcrIssueIcon : vcrOkIcon}
                    alt="vcr-icon"
                  />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    )
  }

  render() {
    const {
      vehicleData,
      postVehicleCommentState,
      isSidebarCollapsed,
      timezone,
      params: { vehicle_id: vehicleId },
    } = this.props

    const vehicleIssues = vehicleData.vcr_submission_issues
      ? vehicleData.vcr_submission_issues
      : []

    const vehicleComments = vehicleData.vehicle_comments
      ? vehicleData.vehicle_comments
      : []

    const titleAction = [
      {
        key: 0,
        icon: editIcon,
        onClick: () => history.push(`/vehicles/details/${vehicleId}/edit`),
        tooltip: {
          titleIcon: tooltipEditIcon,
          title: 'Edit vehicle',
          action: 'Click to edit this vehicle',
        },
      },
    ]

    const hasVehicleData = !_.isEmpty(vehicleData)

    return (
      <>
        <NewHeader
          title={
            hasVehicleData ? `Vehicle ${vehicleData.vehicle_name}` : 'Loading'
          }
          returnText="Vehicle list"
          returnUrl="/vehicles"
          titleAction={titleAction}
        />
        <NewContainer>
          <>
            {!hasVehicleData && <Loading />}
            {hasVehicleData && _.get(vehicleData, 'id') === undefined && (
              <Blank />
            )}
            {hasVehicleData && (
              <div className="individualView vehicles flex-column">
                <div className="view__info-row flex-row">
                  {Object.keys(rowHeader).map((item) => (
                    <div className="row__item flex-column" key={item}>
                      <div className="row__header">{rowHeader[item]}</div>
                      <div className={`row__data ${item} flex-row`}>
                        {this.dataFormat(vehicleData)[item]}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="view__container flex-row">
                  <div className="galleryView">
                    <IssueHeader
                      updatedAt={vehicleData.submitted_at}
                      timezone={timezone}
                    />
                    <IssueList issues={vehicleIssues} />
                    <Gallery
                      data={{
                        touches: vehicleData.vcr_submission_touches,
                        vehicleType_id: vehicleData.vehicle_type_id,
                      }}
                      isSidebarCollapsed={isSidebarCollapsed}
                    />
                  </div>
                  <div className="vehicle__tabs">
                    <VcrVehicleTabs
                      tabs={[
                        {
                          title: "VCR's",
                          icon: {
                            active: vcrIconActive,
                            inactive: vcrIconInactive,
                          },
                        },
                        {
                          title: 'Vehicle Notes',
                          icon: {
                            active: commentIconActive,
                            inactive: commentIconInactive,
                          },
                        },
                      ]}
                      tabsContent={[
                        this.renderVcrList(),
                        <CommentBox
                          comments={vehicleComments}
                          postCommentState={postVehicleCommentState}
                          sendComment={this.sendComment}
                          key={vehicleData.vehicle_name}
                        />,
                      ]}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        </NewContainer>
      </>
    )
  }
}

export default Vehicle
