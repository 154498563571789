import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import VehicleDetailsInfoRow from '../VehicleDetailsInfoRow'

import { getShortName } from '../../utils'

const VehicleDetailsSingleVcr = ({
  vcr,
  timezone,
  yardId,
  setHeaderReturnOptions,
  vehicleId,
}) => {
  const id = _.get(vcr, 'id')
  const firstName = _.get(vcr, 'User.first_name')
  const lastName = _.get(vcr, 'User.last_name')
  const role = _.get(vcr, 'User.role')

  const props = {
    type: 'vcr',
    isClickable: true,
    vcr: {
      engineHours: _.get(vcr, 'engine_hours'),
      comment: _.get(vcr, 'comment'),
      user: getShortName({ firstName, lastName }),
      date: _.get(vcr, 'submitted_at'),
      issues: _.get(vcr, 'issues', []).map((issue) => ({
        description: issue,
      })),
      status: _.get(vcr, 'status'),
      id,
      timezone,
      role,
      yardId,
      setHeaderReturnOptions,
      vehicleId,
    },
  }
  return <VehicleDetailsInfoRow {...props} />
}

VehicleDetailsSingleVcr.propTypes = {
  timezone: PropTypes.string.isRequired,
  vcr: PropTypes.shape({
    User: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      role: PropTypes.string,
    }),
    submitted_at: PropTypes.string,
    engine_hours: PropTypes.number,
    issues: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
  }).isRequired,
  yardId: PropTypes.number.isRequired,
  vehicleId: PropTypes.number.isRequired,
  setHeaderReturnOptions: PropTypes.func.isRequired,
}

export default VehicleDetailsSingleVcr
