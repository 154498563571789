import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './styles'

const NewCard = ({ children, className }) => (
  <Container className={className}>{children}</Container>
)

NewCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

NewCard.defaultProps = {
  className: '',
  children: null,
}

export default NewCard
