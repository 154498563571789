import { createUserTypes } from '../../actions/createUserActions'

const initialState = {
  tosId: null,
  username: null,
  email: null,
  firstName: null,
  lastName: null,
  yardId: null,
  roleId: null,
}

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case createUserTypes.LOAD_USER_REQUESTED:
    case createUserTypes.LOAD_USER_FAILED:
    case createUserTypes.LOAD_USER_RESET:
      return initialState

    case createUserTypes.LOAD_USER_LOADED:
      return {
        ...state,
        tosId: payload.tos_id,
        username: payload.username,
        email: payload.email,
        firstName: payload.first_name,
        lastName: payload.last_name,
        yardId: payload.yard_id,
        roleId: payload.role_id,
        allowedYardIds: payload.AllowedYards,
      }

    default:
      return state
  }
}

export default user
