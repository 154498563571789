const createTypes = (namespace, types) =>
  types.reduce((generatedTypes, type) => {
    if (typeof type === 'string') {
      return {
        ...generatedTypes,
        [type]: `${namespace}/${type}`,
      }
    }

    if (typeof type === 'object') {
      const { isAsync = false, isLazy = false } = type

      const rawType = `${namespace}/${type.value}`

      if (!isAsync) {
        return {
          ...generatedTypes,
          [type.value]: rawType,
        }
      }

      const generatedAsyncTypes = ['REQUESTED', 'LOADED', 'FAILED'].reduce(
        (asyncTypes, effect) => ({
          ...asyncTypes,
          [`${type.value}_${effect}`]: `${rawType}_${effect}`,
        }),
        {},
      )

      if (isLazy) {
        generatedAsyncTypes[
          `${type.value}_LAZY_LOADED`
        ] = `${rawType}_LAZY_LOADED`
      }

      return {
        ...generatedTypes,
        ...generatedAsyncTypes,
      }
    }

    throw new Error('type must be string or object')
  }, {})

export default createTypes
