import moment from 'moment-timezone'
import { getStartOfEditablePeriod } from '../../../utils'
import { engineHoursEditablePeriod } from '../../../config'

function getEditablePeriod({ date, timezone }) {
  const endDate = moment
    .tz(date, timezone)
    .endOf('month')
    .add(engineHoursEditablePeriod.end, 'days')
  const startDate = getStartOfEditablePeriod({
    date: moment.tz(date, timezone),
    timezone,
  })

  return { startDate, endDate }
}

export default getEditablePeriod
