import styled from 'styled-components/macro'

export const TabContent = styled.div`
  background-color: var(--lighter-neutral-99);
  padding: 0 20px 5px;
  overflow-y: hidden;
  border-top: 20px solid var(--lighter-neutral-99);
  height: 100%;
`
export const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`
