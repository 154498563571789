import { connect } from 'react-redux'
import './UpsertAudit.css'
import UpsertAudit from './UpsertAudit'

import {
  upsertAudit,
  loadAudit,
  resetAudit,
} from '../../actions/upsertAuditActions'
import { showAlert } from '../../actions/alertMessageActions'
import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state, ownProps) => ({
  isSubmitting: state.upsertAudit.upsertAudit.loading,
  auditIdParam: Number(ownProps.params.audit_id),
  audit: state.upsertAudit.upsertAudit.audit,
  timezone: getYardTimeZone(state),
})

const mapDispatchToProps = {
  upsertAudit,
  showAlert,
  loadAudit,
  resetAudit,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpsertAudit)
