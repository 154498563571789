import store from '../../store'
import { verifyToken } from '../../actions/generalActions'
import { getBaseUrlByUser } from '../../utils'

const requireAnonymous = (nextState, replace, callback) => {
  const { authenticated } = store.getState().general

  if (authenticated === null) {
    store
      .dispatch(verifyToken())
      .then(({ user }) => {
        replace(getBaseUrlByUser(user))
        callback()
      })
      .catch(() => {
        callback()
      })
  } else {
    callback()
  }
}

export default requireAnonymous
