import { connect } from 'react-redux'
import VehicleDetailsInfo from './VehicleDetailsInfo'

import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state) => {
  const timezone = getYardTimeZone(state)

  return {
    vehicleData: state.manageVehicles.vehicleData,
    deviceData: state.deviceStatus.deviceData,
    timezone,
  }
}

export default connect(mapStateToProps)(VehicleDetailsInfo)
