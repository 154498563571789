import { upsertAuditTypes } from '../../actions/upsertAuditActions'

const initialState = {
  loading: false,
  value: [],
}

const auditCorrectiveActionTypes = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case upsertAuditTypes.CORRECTIVE_ACTION_TYPES_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case upsertAuditTypes.CORRECTIVE_ACTION_TYPES_LOADED:
      return {
        ...state,
        loading: false,
        value: payload.auditCorrectiveActionTypes,
      }
    case upsertAuditTypes.CORRECTIVE_ACTION_TYPES_FAILED:
      return initialState
    default:
      return state
  }
}

export default auditCorrectiveActionTypes
