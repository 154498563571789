import { trackingTypes } from '../../actions/trackingActions'

const initialState = false

const historyPlay = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.HISTORY_PLAY:
      return payload

    default:
      return state
  }
}

export default historyPlay
