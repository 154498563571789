import { yardSummaryTypes } from '../../actions/yardSummaryActions'

const initialState = {
  loading: false,
  value: [],
}

const liftsCountSummary = (state = initialState, { type, payload }) => {
  switch (type) {
    case yardSummaryTypes.LIFTS_SUMMARY_REQUESTED:
      return {
        ...state,
        loading: true,
        value: [],
      }
    case yardSummaryTypes.LIFTS_SUMMARY_LOADED:
      return {
        ...state,
        loading: false,
        value: payload,
      }

    case yardSummaryTypes.LIFTS_SUMMARY_FAILED:
      return initialState
    default:
      return state
  }
}

export default liftsCountSummary
