import { trackingTypes } from '../../actions/trackingActions'

const initialState = 'live'

const viewMode = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.VIEW_MODE_SET:
      return payload ? 'live' : 'history'
    default:
      return state
  }
}

export default viewMode
