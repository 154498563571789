import { trackingTypes } from '../../actions/trackingActions'

const initialState = null

const infoBoxDeviceId = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.SET_INFOBOX_DEVICE_ID:
      return payload

    default:
      return state
  }
}

export default infoBoxDeviceId
