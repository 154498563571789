import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { tabPropType } from '../Tab/propTypes'
import Tab from '../Tab'
import TabContext from '../TabContext'

class Header extends Component {
  static propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape(tabPropType)).isRequired,
  }

  static contextType = TabContext

  render() {
    const { tabs } = this.props
    const { selectedIndex, onChange } = this.context

    return (
      <div className="tabs-header">
        {tabs.map((tab, index) => (
          <Tab
            key={tab.title}
            {...tab}
            index={index}
            isActive={selectedIndex === index}
            onClick={onChange}
          />
        ))}
      </div>
    )
  }
}

export default Header
