import _ from 'lodash'
import { trackingTypes } from '../../actions/trackingActions'

const initialState = []

const selectedTooltips = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.TOOLTIP_ADD:
      return [...state, payload.vehicleId]

    case trackingTypes.TOOLTIP_REMOVE:
      return _.without(state, payload.vehicleId)

    case trackingTypes.VIEW_MODE_SET:
      return initialState

    default:
      return state
  }
}

export default selectedTooltips
