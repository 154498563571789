import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

class SingleButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    onClick: () => null,
  }

  handleClick = () => {
    const { onClick, value } = this.props
    onClick(value)
  }

  render() {
    const { label, isSelected } = this.props
    const className = cx('selectButtonGroup-tab', { isSelected })

    return (
      <div className={className} role="button" onClick={this.handleClick}>
        {label}
      </div>
    )
  }
}

export default SingleButton
