import React from 'react'
import PropTypes from 'prop-types'
import { startCase } from 'lodash'

import * as S from './styles'

const RemoveTable = ({ onClick, tableName, noMargin }) => (
  <S.Button onClick={onClick} $noMargin={noMargin}>
    Remove {startCase(tableName)}
  </S.Button>
)

RemoveTable.propTypes = {
  onClick: PropTypes.func.isRequired,
  tableName: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
}

RemoveTable.defaultProps = {
  noMargin: false,
}

export default RemoveTable
