import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const ProgressBar = ({ step, size }) => {
  const circles = [...Array(size).keys()].map((index) => (
    <span
      key={index}
      className={cx('progressBar-circle', { selected: index <= step })}
    />
  ))

  const bars = [...Array(size - 1).keys()].map((index) => (
    <span
      key={index}
      className={cx('progressBar-bar', { selected: index < step })}
      style={{ flex: 1 }}
    />
  ))

  return (
    <div className="progressBar">
      <div className="progressBar-circles">{circles}</div>
      <div className="progressBar-bars">{bars}</div>
    </div>
  )
}

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
}

export default ProgressBar
