import { Promise } from 'bluebird'
import * as yup from 'yup'
import { isNil } from 'ramda'

import {
  fieldParameterListForCharge,
  fieldParameterListForMetric,
  fieldParameterListForService,
} from './constants'

export function onFormSubmit({ values }) {
  Promise.resolve().then(() => ({ values }))
}

export function getValidationSchema(fields) {
  const validationSchema = yup
    .object()
    .shape(
      Object.assign(
        {},
        ...fields.map((item) => ({ [item.name]: item.validation })),
      ),
    )

  return validationSchema
}

export function getInitialFormFields(elementType) {
  if (isNil(elementType)) {
    return []
  }

  return {
    metric: fieldParameterListForMetric,
    service: fieldParameterListForService,
    charge: fieldParameterListForCharge,
  }[elementType]
}

export function getInitialValuesToEdit(billingElementData) {
  return {
    ...billingElementData,
    ...billingElementData.data,
  }
}
