import React from 'react'
import { Router, browserHistory } from 'react-router'
import { hot } from 'react-hot-loader'

import routes from './routes'

import ErrorBoundary from './components/ErrorBoundary'

import './css/normalize.css'
import './css/index.css'
import './css/tooltip.css'
import './css/fonts.css'
import './css/variables.css'
import './css/colors.css'

const Main = () => (
  <ErrorBoundary>
    <Router history={browserHistory} routes={routes} />
  </ErrorBoundary>
)

export default hot(module)(Main)
