import { connect } from 'react-redux'
import BillingCycleHistory from './BillingCycleHistory'
import {
  loadBillingInvoices,
  loadTableToExport,
} from '../../actions/billingActions'
import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state) => ({
  timezone: getYardTimeZone(state),
  selectedYard: Number(state.general.selectedYard),
  billingInvoices: state.billing.billingInvoices,
  billingTable: state.billing.billingTable,
  yardList: state.general.yardList,
})

const mapDispatchToProps = {
  loadBillingInvoices,
  loadTableToExport,
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingCycleHistory)
