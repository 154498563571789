import _ from 'lodash'
import { browserHistory } from 'react-router'
import api from '../api'
import parseUserFromServer from '../parsers/parseUserFromServer'
import parseAuditFromServer from '../parsers/parseAuditFromServer'
import { createTypes, auditsWorkerOptions } from './utils'
import { showAlert } from './alertMessageActions'

export const upsertAuditTypes = createTypes('audits', [
  { value: 'EMPLOYEES', isAsync: true },
  { value: 'SUPERVISORS', isAsync: true },
  { value: 'FUNCTION_TYPES', isAsync: true },
  { value: 'VIOLATION_TYPES', isAsync: true },
  { value: 'CORRECTIVE_ACTION_TYPES', isAsync: true },
  { value: 'CREATE', isAsync: true },
  { value: 'AUDIT', isAsync: true },
  'AUDIT_RESET',
])

const sortPeopleByLastName = (people) =>
  _.sortBy(people, ({ name }) => name.split(' ').reverse())

export const loadEmployees = ({ yardId }) => (dispatch) => {
  dispatch({ type: upsertAuditTypes.EMPLOYEES_REQUESTED })

  const { roleName } = auditsWorkerOptions.EMPLOYEE

  return api.users
    .getAll({
      yardId,
      roleName,
    })
    .then((data) => {
      const parsedEmployees = data.users.map(parseUserFromServer)
      const sortedEmployees = sortPeopleByLastName(parsedEmployees)
      dispatch({
        type: upsertAuditTypes.EMPLOYEES_LOADED,
        payload: { employees: sortedEmployees },
      })
    })
    .catch(() => {
      dispatch({ type: upsertAuditTypes.EMPLOYEES_FAILED })
    })
}

export const loadSupervisors = ({ yardId }) => (dispatch) => {
  dispatch({ type: upsertAuditTypes.SUPERVISORS_REQUESTED })

  const { roleName } = auditsWorkerOptions.SUPERVISOR

  return api.users
    .getAll({
      yardId,
      roleName,
    })
    .then((data) => {
      const parsedSupervisors = data.users.map(parseUserFromServer)
      const sortedSupervisors = sortPeopleByLastName(parsedSupervisors)
      dispatch({
        type: upsertAuditTypes.SUPERVISORS_LOADED,
        payload: { supervisors: sortedSupervisors },
      })
    })
    .catch(() => {
      dispatch({ type: upsertAuditTypes.SUPERVISORS_FAILED })
    })
}

export const loadAuditFuntionTypes = () => (dispatch) => {
  dispatch({ type: upsertAuditTypes.FUNCTION_TYPES_REQUESTED })

  return api.auditFunctionTypes
    .getAll()
    .then((auditFunctionTypes) => {
      dispatch({
        type: upsertAuditTypes.FUNCTION_TYPES_LOADED,
        payload: { auditFunctionTypes },
      })
    })
    .catch(() => {
      dispatch({ type: upsertAuditTypes.FUNCTION_TYPES_FAILED })
    })
}

export const loadAuditViolationTypes = ({ auditFunctionTypeId }) => (
  dispatch,
) => {
  dispatch({ type: upsertAuditTypes.VIOLATION_TYPES_REQUESTED })

  return api.auditViolationTypes
    .getAll({
      auditFunctionTypeId,
    })
    .then((auditViolationTypes) => {
      dispatch({
        type: upsertAuditTypes.VIOLATION_TYPES_LOADED,
        payload: { auditViolationTypes },
      })
    })
    .catch(() => {
      dispatch({ type: upsertAuditTypes.VIOLATION_TYPES_FAILED })
    })
}

export const loadAuditCorrectiveActionTypes = () => (dispatch) => {
  dispatch({ type: upsertAuditTypes.CORRECTIVE_ACTION_TYPES_REQUESTED })

  return api.auditCorrectiveActionTypes
    .getAll()
    .then((auditCorrectiveActionTypes) => {
      dispatch({
        type: upsertAuditTypes.CORRECTIVE_ACTION_TYPES_LOADED,
        payload: { auditCorrectiveActionTypes },
      })
    })
    .catch(() => {
      dispatch({ type: upsertAuditTypes.CORRECTIVE_ACTION_TYPES_FAILED })
    })
}

export const upsertAudit = ({ auditValues, auditId }) => (dispatch) => {
  dispatch({ type: upsertAuditTypes.CREATE_REQUESTED })

  api.audits
    .save({ auditValues, auditId })
    .then(() => {
      dispatch({ type: upsertAuditTypes.CREATE_LOADED })
      dispatch(
        showAlert({
          type: 'success',
          message: 'Audit saved successfully.',
        }),
      )
      browserHistory.push('/audits')
    })
    .catch(() => {
      dispatch({ type: upsertAuditTypes.CREATE_FAILED })
      dispatch(
        showAlert({
          type: 'failure',
          message: 'Failed to load audit.',
        }),
      )
    })
}

export const loadAudit = (auditId) => (dispatch) => {
  dispatch({ type: upsertAuditTypes.AUDIT_REQUESTED })

  return api.audits
    .getOne(auditId)
    .then((data) => {
      const audit = {
        ...parseAuditFromServer(data),
        ..._.pick(data, ['corrective_action_ids']),
        auditType: data.type,
        employee: data.employee_id,
        supervisor: data.supervisor_id,
        auditFunction: data.function_id,
        violations: data.violation_ids,
        coneTest: data.cone_test_passed,
        correctiveActions: data.corrective_action_ids,
      }
      dispatch({
        type: upsertAuditTypes.AUDIT_LOADED,
        payload: { audit },
      })
    })
    .catch(() => {
      dispatch({ type: upsertAuditTypes.AUDIT_FAILED })
      dispatch(
        showAlert({
          type: 'failure',
          message: 'Failed to load audit.',
        }),
      )
      browserHistory.push('/audits')
    })
}

export const resetAudit = () => (dispatch) =>
  dispatch({ type: upsertAuditTypes.AUDIT_RESET })
