import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import calendarGreyImage from '../../images/icons/calendar-grey.svg'
import flagBlueImage from '../../images/icons/flag-blue.svg'
import flagGreyImage from '../../images/icons/flag-grey.svg'
import commentBlueImage from '../../images/icons/comment.svg'
import commentGreyImage from '../../images/icons/comment-grey.svg'

import NotesBox from '../../containers/NotesBox'
import TagsBox from '../../containers/TagsBox'

import FlagsBox from '../FlagsBox'
import Tabs from '../Tabs'
import Blank from '../Blank'

import flagType from '../../containers/WorkerProfile/propTypes'

const SelectedDayCard = ({
  selectedDate,
  flags,
  timezone,
  flagsTitle,
  isReadOnly,
}) => {
  const flagsLength = _.get(flags, 'value.length', 0)

  const tabs = [
    {
      title: `${flagsTitle} (${flagsLength})`,
      icon: {
        active: flagBlueImage,
        inactive: flagGreyImage,
      },
    },
    {
      title: 'Yard Notes',
      icon: {
        active: commentBlueImage,
        inactive: commentGreyImage,
      },
    },
  ]

  return (
    <div className="selectedDayCard">
      <Tabs>
        <div className="selectedDayCard-header">
          <div className="selectedDayCard-header-date">
            <img src={calendarGreyImage} alt="calendar-grey" />
            <span>{selectedDate.format('MMM Do')}</span>
          </div>
          <div className="selectedDayCard-header-tabs">
            <Tabs.Header tabs={tabs} />
          </div>
        </div>
        {flagsLength === 0 ? (
          <Blank />
        ) : (
          <div className="selectedDayCard-content">
            <Tabs.Body>
              <Tabs.Item>
                <FlagsBox
                  timezone={timezone}
                  flags={flags}
                  theme="light"
                  expand
                />
              </Tabs.Item>
              <Tabs.Item>
                <NotesBox selectedDate={selectedDate} isReadOnly={isReadOnly} />
                <TagsBox selectedDate={selectedDate} isReadOnly={isReadOnly} />
              </Tabs.Item>
            </Tabs.Body>
          </div>
        )}
      </Tabs>
    </div>
  )
}

SelectedDayCard.propTypes = {
  selectedDate: PropTypes.instanceOf(moment).isRequired,
  flags: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(flagType).isRequired,
  }).isRequired,
  flagsTitle: PropTypes.string,
  timezone: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
}

SelectedDayCard.defaultProps = {
  flagsTitle: 'Flags',
  isReadOnly: false,
}

export default SelectedDayCard
