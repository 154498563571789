import _ from 'lodash'

export default function parseEmptyToNull(value) {
  if (_.isNil(value)) {
    return null
  }

  if (typeof value === 'object' && _.isEmpty(value)) {
    return null
  }

  return _.isEmpty(String(value)) ? null : value
}
