import { addActionColumnToHeaders } from './helpers'

export const VIEW_MODE = {
  SERVICE: 'service',
  CHARGE: 'charge',
  METRIC: 'metric',
  PROD_SAVING: 'productivity_saving',
}

export const INNER_TABS_OPTIONS = [
  {
    text: 'Service',
    value: 'service',
  },
  {
    text: 'Metric',
    value: 'metric',
  },
  {
    text: 'Charge',
    value: 'charge',
  },
  {
    text: 'Productivity Saving',
    value: 'productivity_saving',
  },
]

const headersForService = [
  {
    text: 'Display name',
    field: 'default_display_name',
  },
  {
    text: 'Billing Element',
    field: 'code',
  },
  {
    text: 'Description',
    field: ['data', 'description'],
  },
  {
    text: 'Price UOM',
    field: ['data', 'price_uom'],
  },
  {
    text: 'Comment Required',
    field: ['data', 'is_comment_required'],
  },
]

const headersForCharge = [
  ...headersForService,
  {
    text: 'Group',
    field: ['data', 'group'],
  },
]

const headersForMetricAndProdSavings = [
  {
    text: 'Display name',
    field: 'default_display_name',
  },
  {
    text: 'Billing Element',
    field: 'code',
  },
]

const SERVICE_HEADERS = addActionColumnToHeaders(headersForService)
const CHARGE_HEADERS = addActionColumnToHeaders(headersForCharge)
const METRIC_HEADERS = addActionColumnToHeaders(headersForMetricAndProdSavings)
const PROD_SAVINGS_HEADERS = headersForMetricAndProdSavings

export const HEADERS = {
  SERVICE: SERVICE_HEADERS,
  CHARGE: CHARGE_HEADERS,
  METRIC: METRIC_HEADERS,
  PROD_SAVING: PROD_SAVINGS_HEADERS,
}

export const NO_TITLE_ACTION = ['productivity_saving']
