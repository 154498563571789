import clientApi from './utils/clientApi'

const getAll = () =>
  clientApi({
    method: 'get',
    url: '/yards',
  }).then(({ data }) => data)

const yards = {
  getAll,
}

export default yards
