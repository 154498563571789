import { connect } from 'react-redux'
import VehicleDetailsVcr from './VehicleDetailsVcr'

import { loadVcrList } from '../../actions/manageVehiclesActions'
import { setHeaderReturnOptions } from '../../actions/manageVCRsActions'

import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state) => {
  const timezone = getYardTimeZone(state)

  return {
    vcrList: state.manageVehicles.vcrList,
    yardId: Number(state.general.selectedYard),
    timezone,
  }
}

const mapDispatchToProps = {
  loadVcrList,
  setHeaderReturnOptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetailsVcr)
