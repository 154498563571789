import { combineReducers } from '@reduxjs/toolkit'
import notes from './notes'
import tags from './tags'
import yardTags from './yardTags'
import createNote from './createNote'
import liftsCount from './liftsCount'
import selectedDate from './selectedDate'
import yardShifts from './yardShifts'
import activeTimeFromSessions from './activeTimeFromSessions'
import activeTimeSummary from './activeTimeSummary'
import yardShiftsSummary from './yardShiftsSummary'
import liftsCountSummary from './liftsCountSummary'
import yardFlags from './yardFlags'
import yardMetrics from './yardMetrics'
import movesCount from './movesCount'

const yardSummary = combineReducers({
  notes,
  tags,
  yardTags,
  createNote,
  liftsCount,
  selectedDate,
  yardShifts,
  activeTimeFromSessions,
  activeTimeSummary,
  yardShiftsSummary,
  liftsCountSummary,
  yardFlags,
  yardMetrics,
  movesCount,
})

export default yardSummary
