import clientApi from './utils/clientApi'

const getLive = (yardId) =>
  clientApi({
    method: 'get',
    url: `/position_tracking/yards/${yardId}`,
  }).then(({ data }) => data.vehicles)

const positionTracking = {
  getLive,
}

export default positionTracking
