import styled from 'styled-components/macro'

const infoPaddingValue = 7

function getMetricColorByTheme(theme) {
  return `
  background: var(--${theme}-text-background);
  color: var(--lighter-neutral-85);
  `
}

export const Text = styled.span`
  display: block;

  ${(p) =>
    p.bold &&
    `
    font-family: 'ArticulatCF-Bold';
  `}

  ${(p) =>
    p.big &&
    `
    font-size: 20px;
  `}

  ${(p) =>
    p.small &&
    `
    font-size: 14px;
  `}

  ${(p) =>
    p.driver &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 95%;
  `}
`

export const Image = styled.img`
  height: 30px;
  width: 30px;
  object-fit: contain;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(90vh - 310px);
  background-color: var(--darker-blue-17);
  padding: 15px 20px 0;
  font-family: 'ArticulatCF-Medium';
  position: relative;
  color: var(--lighter-neutral-99);
  cursor: default;

  ::-webkit-scrollbar-thumb {
    background: var(--darker-neutral-45);
    border: 3px solid var(--darker-blue-17);
  }

  ${(p) =>
    p.content &&
    `
      gap: 20px;
  `}

  ${(p) =>
    p.header &&
    `
    padding: 15px 20px;
    height: fit-content;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: center;
  `}

  ${(p) =>
    p.isEmpty &&
    `
    height: calc(90vh - 225px);
  `}
`

export const Metric = styled.span`
  width: 100%;
  display: block;
  text-align: center;
  height: fit-content;
  font-family: 'ArticulatCF-DemiBold';
  padding: ${infoPaddingValue}px 0;
`

export const Info = styled.div`
  display: grid;
  grid-template-columns: 45px 1fr 110px;
  align-items: stretch;

  ${(props) =>
    props.isPlayback &&
    `
    opacity: .4;
  `}

  ${Text} {
    padding: ${infoPaddingValue}px 0;
  }

  ${Image} {
    margin-top: ${infoPaddingValue / 2}px;
  }

  ${Metric} {
    ${(p) => getMetricColorByTheme(p.theme)}

    ${(p) =>
      (p.theme === 'boxmoves' || p.theme === 'comms') &&
      `
        text-align: left;
        padding-left: 10px;
      `};
  }
`

export const Footer = styled.div`
  border-top: 1px solid var(--darker-neutral-45);
  margin: auto 0 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
`

export const Button = styled.button`
  background: transparent;
  padding: 10px 0;
  width: 44%;
  border: 1px solid;
  border-radius: var(--border-radius);
  transition: 0.2s all ease;
  cursor: pointer;
  color: var(--base-neutral);

  :hover {
    color: var(--base-red);
  }

  ${(p) =>
    p.highlighted &&
    `
    color: var(--lighter-neutral-99);
    border: none;
    background-color: var(--darker-blue-43);

    :hover {
      color: var(--lighter-neutral-99);
      background-color: var(--base-blue);
    }
  `}
`
