import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { includes } from 'ramda'
import Promise from 'bluebird'
import api from '../api'
import { loadVehicles } from './manageVehiclesActions'
import { showAlert } from './alertMessageActions'
import { history, getBaseUrlByUser, heap } from '../utils'
import { createTypes } from './utils'
import { rolePermission, HAS_ACCESS_TO_ALL_YARDS_ROLES } from '../config'

export const generalTypes = createTypes('login', [
  'LOGIN_SUCCEEDED',
  'LOGIN_FAILED',
  'LOGIN_REQUEST_ERROR',
  'DATA_LOADED',
  'COLLAPSE_TOGGLED',
  'YARD_SELECTED',
  { value: 'YARD_LIST', isAsync: true },
  'YARD_LIST_RESET',
])

export const selectYard = (yardId) => (dispatch) => {
  dispatch({
    type: generalTypes.YARD_SELECTED,
    payload: yardId,
  })

  history.changePathVariable({ name: 'yard', value: yardId })
}

const getYardListForUser = (allYards, userData) => {
  if (!_.isEmpty(userData)) {
    const role = userData.Role.name
    if (includes(role, HAS_ACCESS_TO_ALL_YARDS_ROLES)) {
      return allYards
    }

    const { allowedYards } = userData
    return _.intersectionBy(allYards, allowedYards, 'id')
  }

  return allYards
}

export const loadYards = () => (dispatch, getState) =>
  Promise.resolve()
    .then(api.yards.getAll)
    .then((data) => {
      const { userData } = getState().general
      const yardListForUser = getYardListForUser(data.yards, userData)
      dispatch({
        type: generalTypes.YARD_LIST_LOADED,
        payload: {
          yardListForUser,
          allYardList: data.yards,
        },
      })
      return Promise.resolve()
    })
    .catch(() => {
      dispatch({
        type: generalTypes.YARD_LIST_FAILED,
      })
    })

const setSentry = (data, yardId) => {
  heap.identify(data)

  Sentry.configureScope((scope) => {
    const {
      user: {
        id,
        username,
        first_name: firstName,
        last_name: lastName,
        role_id: roleId,
      },
    } = data
    scope.setUser({
      id,
      username,
      'Yard ID': yardId,
      'Full Name': `${firstName} ${lastName}`,
      'Role ID': roleId,
    })
  })
}

const startUp = ({ token, data }) => (dispatch) => {
  const role = _.get(data, ['user', 'Role', 'name'])
  if (role === 'DRIVER' || role === 'GATE_INSPECTOR') {
    throw new Error('Access Denied')
  }

  const yardIdFromUrl = history.getVariable('yard')
  const yardId = _.isNil(yardIdFromUrl)
    ? data.user.yard_id
    : Number(yardIdFromUrl)

  setSentry(data, yardId)

  return Promise.resolve(
    dispatch({
      type: generalTypes.DATA_LOADED,
      payload: data.user,
    }),
  )
    .then(() =>
      dispatch({
        type: generalTypes.YARD_SELECTED,
        payload: yardId,
      }),
    )
    .then(() =>
      dispatch({
        type: generalTypes.LOGIN_SUCCEEDED,
        payload: token,
      }),
    )
    .then(() => dispatch(loadYards()))
    .then(() => dispatch(loadVehicles(yardId)))
}

export const login = (username, password) => (dispatch) =>
  Promise.resolve({ username, password })
    .then(api.auth.login)
    .tap((data) => {
      const { token } = data.user
      return dispatch(startUp({ token, data }))
    })
    .tapCatch((error) => {
      const errorMessage = _.get(error, 'response.data.errors[0].message')
      dispatch({
        type: generalTypes.LOGIN_FAILED,
      })
      dispatch({
        type: generalTypes.LOGIN_REQUEST_ERROR,
        payload: {
          message: _.isNil(errorMessage)
            ? 'Something went wrong.'
            : errorMessage,
        },
      })
    })
    .tap((data) => {
      const roleName = data.user.Role.name
      const { homeURL } = rolePermission[roleName]

      history.push(`${getBaseUrlByUser(data.user)}${homeURL}`)
    })

export const verifyToken = () => (dispatch) =>
  Promise.resolve()
    .then(api.auth.verifyToken)
    .tap((data) => {
      const token = localStorage.getItem('token')
      return dispatch(startUp({ token, data }))
    })
    .tapCatch(() => {
      dispatch({
        type: generalTypes.LOGIN_FAILED,
      })
    })

export const logout = () => (dispatch) =>
  Promise.resolve()
    .then(api.auth.logout)
    .tap(() => {
      dispatch({
        type: generalTypes.YARD_LIST_RESET,
      })
      Sentry.configureScope((scope) => scope.setUser(null))
      localStorage.removeItem('token')
      history.push('/login')
    })
    .tapCatch(() =>
      dispatch(
        showAlert({
          type: 'failure',
          message: "Couldn't log out. Please try again.",
        }),
      ),
    )

export const setSideBarCollapse = () => ({
  type: generalTypes.COLLAPSE_TOGGLED,
})
