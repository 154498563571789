import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'
import auditPropType from './propTypes'
import { Wizard, NewContainer, NewHeader } from '../../components'
import FormPage1 from './formPages/FormPage1'
import FormPage2 from './formPages/FormPage2'
import FormPage3 from './formPages/FormPage3'

class UpsertAudit extends Component {
  componentDidMount() {
    const { auditIdParam, loadAudit, timezone } = this.props

    if (!_.isNaN(auditIdParam)) {
      loadAudit(auditIdParam, timezone)
    }
  }

  componentWillUnmount() {
    const { resetAudit } = this.props
    resetAudit()
  }

  handleSubmit = ({
    comment,
    violations,
    correctiveActions,
    auditType: type,
    employee: employeeId,
    supervisor: supervisorId,
    auditFunction: auditFunctionTypeId,
    coneTest: coneTestPassed,
    auditedAt: auditedAtValue,
  }) => {
    const { upsertAudit, auditIdParam } = this.props

    const auditValues = {
      type,
      auditFunctionTypeId,
      employeeId,
      supervisorId,
      coneTestPassed,
      comment,
      violations,
      correctiveActions,
      auditedAt: auditedAtValue.valueOf(),
    }

    upsertAudit({ auditValues, auditId: auditIdParam })
  }

  render() {
    const { isSubmitting, audit, timezone } = this.props

    const auditUpdated = {
      ...audit,
      auditedAt: moment(audit.auditedAt).tz(timezone),
    }

    return (
      <>
        <NewHeader
          title="Create audit"
          returnText="Back to audits"
          returnUrl="/audits"
        />
        <NewContainer>
          <div className="upsertAudit-content">
            <Wizard
              onSubmit={this.handleSubmit}
              isSubmitting={isSubmitting}
              initialValues={auditUpdated}
            >
              <Wizard.Step key={1}>
                <FormPage1 />
              </Wizard.Step>
              <Wizard.Step key={2}>
                <FormPage2 />
              </Wizard.Step>
              <Wizard.Step key={3}>
                <FormPage3 />
              </Wizard.Step>
            </Wizard>
          </div>
        </NewContainer>
      </>
    )
  }
}

UpsertAudit.propTypes = {
  upsertAudit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  auditIdParam: PropTypes.number.isRequired,
  loadAudit: PropTypes.func.isRequired,
  resetAudit: PropTypes.func.isRequired,
  audit: auditPropType,
  timezone: PropTypes.string.isRequired,
}

UpsertAudit.defaultProps = {
  audit: {
    auditedAt: moment(),
  },
}

export default UpsertAudit
