import { connect } from 'react-redux'
import ManageCustomers from './ManageCustomers'

import { showAlert, showErrorAlert } from '../../actions/alertMessageActions'

const mapStateToProps = (state) => ({
  loggedUser: state.general.userData,
})

const mapDispatchToProps = {
  showAlert,
  showErrorAlert,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCustomers)
