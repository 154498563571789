import _ from 'lodash'

const pickOrUndefined = (obj, fields) => {
  const undefinedObj = _.fromPairs(_.zip(fields, []))
  const pickedObj = _.pick(obj, fields)
  return {
    ...undefinedObj,
    ...pickedObj,
  }
}

export default pickOrUndefined
