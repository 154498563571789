import { connect } from 'react-redux'
import PasswordStrength from './PasswordStrength'

const mapStateToProps = (state) => ({
  yardList: state.general.yardList,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordStrength)
