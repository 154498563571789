import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Promise from 'bluebird'
import { isNil, isEmpty } from 'ramda'

import TableTemplateValues from '../../containers/BillingTemplateDetails/TableTemplateValues'

import * as S from './styles'

import question from '../../images/icons/icon_question_no_bg.svg'
import warning from '../../images/icons/icon_issue.svg'
import time from '../../images/icons/icon_clock.svg'
import date from '../../images/icons/icon_days_behind.svg'
import selectable from '../../images/icons/icon_selectable.svg'
import tiers from '../../images/icons/icon_tiers.svg'
import billing from '../../images/icons/icon_billing_table.svg'
import alert from '../../images/icons/icon_alert.svg'

const typeIcon = {
  default: question,
  warning,
  time,
  date,
  selectable_options: selectable,
  tiers,
  billing,
  alert,
}

const Modal = ({
  confirmText,
  cancelText,
  message,
  onClose,
  onConfirm,
  onCancel,
  type,
  visible,
  isMandatory,
  title,
  children,
  hideControls,
  isSubmitting,
  tableContent,
  iconTitle,
  hasRequiredData,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const isConfirmLoading = isNil(isSubmitting) ? isLoading : isSubmitting
  const showTable = !isEmpty(tableContent)

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [onClose])

  function handleConfirm() {
    setIsLoading(true)
    Promise.resolve()
      .then(onConfirm)
      .then(onClose)
      .finally(() => {
        setIsLoading(false)
      })
  }

  function handleStopPropagation(e) {
    e.stopPropagation()
  }

  return (
    <S.Container visible={visible}>
      <S.Overlay onClick={!isMandatory ? onClose : undefined}>
        <S.Modal onClick={handleStopPropagation} $hasTable={showTable}>
          <S.Header>
            <S.Icon src={typeIcon[type]} />
            {iconTitle && <S.IconTitle>{iconTitle}</S.IconTitle>}
          </S.Header>
          <S.Content>
            {title && <S.Title>{title}</S.Title>}
            {showTable && <TableTemplateValues {...tableContent} />}
            {message}
            {children}
            {!hideControls && (
              <S.ButtonWrapper
                $hasTable={showTable}
                $controlOptions={onCancel ? 2 : 1}
              >
                {onCancel && (
                  <S.StyledButton
                    className="cancelBtn"
                    warning={type === 'warning'}
                    text={cancelText}
                    onClick={isNil(onCancel) ? onClose : onCancel}
                  />
                )}
                <S.StyledButton
                  className="confirmBtn"
                  text={isConfirmLoading ? 'Loading' : confirmText}
                  onClick={handleConfirm}
                  isDisabled={hasRequiredData || isLoading}
                />
              </S.ButtonWrapper>
            )}
          </S.Content>
        </S.Modal>
      </S.Overlay>
    </S.Container>
  )
}

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  message: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  type: PropTypes.string,
  isMandatory: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  hideControls: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  tableContent: PropTypes.shape({
    rows: PropTypes.array,
    headers: PropTypes.array,
  }),
  iconTitle: PropTypes.string,
  hasRequiredData: PropTypes.bool,
}

Modal.defaultProps = {
  confirmText: 'Submit',
  cancelText: 'Cancel',
  onCancel: undefined,
  type: 'default',
  isMandatory: false,
  title: null,
  children: null,
  hideControls: false,
  isSubmitting: undefined,
  tableContent: {},
  iconTitle: null,
  hasRequiredData: false,
}

export default Modal
