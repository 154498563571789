import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import ReactDatePicker from 'react-datepicker'
import _ from 'lodash'

function convertToDatePicker(date, timezone) {
  if (_.isNil(date)) {
    return undefined
  }
  if (!date.isValid()) {
    return undefined
  }
  const dateString = moment.tz(date, timezone).format('YYYY-MM-DDTHH:mm:ss.SSS')
  return moment(dateString).toDate()
}

function convertToOutside(date, timezone) {
  if (_.isNil(date)) {
    return undefined
  }
  const dateString = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS')
  return moment.tz(dateString, timezone)
}

function ReactDatePickerForMoment({
  onChange,
  timezone,
  minDate,
  maxDate,
  selected,
  setFieldValue,
  ...props
}) {
  function handleChange(value, event) {
    if (onChange !== undefined) {
      const fixedValue = convertToOutside(value, timezone)
      return setFieldValue !== undefined
        ? setFieldValue(props.name, fixedValue)
        : onChange(fixedValue, event)
    }

    return null
  }

  return (
    <div>
      <ReactDatePicker
        {...props}
        minDate={convertToDatePicker(minDate, timezone)}
        maxDate={convertToDatePicker(maxDate, timezone)}
        selected={convertToDatePicker(selected, timezone)}
        onChange={handleChange}
      />
    </div>
  )
}

ReactDatePickerForMoment.propTypes = {
  ...ReactDatePicker.propTypes,
  minDate: PropTypes.instanceOf(moment),
  maxDate: PropTypes.instanceOf(moment),
  selected: PropTypes.instanceOf(moment),
  timezone: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func,
}

ReactDatePickerForMoment.defaultProps = {
  minDate: undefined,
  maxDate: undefined,
  selected: undefined,
  setFieldValue: undefined,
}

export default ReactDatePickerForMoment
