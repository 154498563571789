import { toolkitApi } from '.'

const customersApi = toolkitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: () => ({
        url: 'billing_customers',
      }),
      transformResponse: (response) => response.customers,
    }),
    getCustomer: builder.query({
      query: ({ customerId }) => ({
        url: `billing_customers/${customerId}`,
      }),
      transformResponse: (response) => response.customer,
    }),
    addCustomer: builder.mutation({
      query: ({ body }) => ({
        url: 'billing_customers',
        method: 'POST',
        body,
      }),
    }),
    updateCustomer: builder.mutation({
      query: ({ body, customerId }) => ({
        url: `billing_customers/${customerId}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCustomer: builder.mutation({
      query: ({ customerId }) => ({
        url: `billing_customers/${customerId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetCustomersQuery,
  useGetCustomerQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
} = customersApi
