import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import NewToolTip from '../NewToolTip'
import './Table.css'

const getNextDirection = (order, column) => {
  if (order === null || order.column !== column) {
    return 'asc'
  }
  return order.direction === 'asc' ? 'desc' : 'asc'
}

class TableHeader extends Component {
  static propTypes = {
    colNum: PropTypes.number,
    colNames: PropTypes.arrayOf(PropTypes.string),
    isOrderable: PropTypes.bool,
    onSort: PropTypes.func,
    order: PropTypes.shape({
      column: PropTypes.string.isRequired,
      direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
    }),
    className: PropTypes.string,
    tooltipDefinitions: PropTypes.arrayOf(PropTypes.object),
  }

  static defaultProps = {
    colNum: 0,
    colNames: [],
    isOrderable: false,
    onSort: () => {},
    order: null,
    className: '',
    tooltipDefinitions: [],
  }

  getOrderedIcon(column) {
    const { order } = this.props

    if (order === null || order.column !== column) {
      return null
    }

    const className = 'tableColumnArrow'

    return order.direction === 'asc' ? (
      <span className={className}>&darr;</span>
    ) : (
      <span className={className}>&uarr;</span>
    )
  }

  handleClick(column) {
    const { isOrderable, onSort } = this.props
    const { order } = this.props

    if (isOrderable) {
      const direction = getNextDirection(order, column)
      onSort({
        column,
        direction,
      })
    }
  }

  renderTableHeader(colName, tooltipDefinitions, index) {
    return (
      <div className="tableHeader__box">
        <NewToolTip
          title={colName}
          action={
            tooltipDefinitions.length
              ? tooltipDefinitions[index].action
              : undefined
          }
          description={
            tooltipDefinitions.length
              ? tooltipDefinitions[index].description
              : undefined
          }
        >
          <span className="tableHeader__text">{colName}</span>
        </NewToolTip>
        {this.getOrderedIcon(colName)}
      </div>
    )
  }

  render() {
    const {
      colNum,
      colNames,
      isOrderable,
      tooltipDefinitions,
      className,
    } = this.props
    return (
      <div className={`tableHeader flex-row ${className}`}>
        {colNames.map((colName, index) => (
          <div
            className={cx(`tableColumn${colNum}`, 'tableColumn', {
              tableColumnOrderable: isOrderable,
            })}
            key={index}
            onClick={this.handleClick.bind(this, colName)}
            role="button"
          >
            {colName
              ? this.renderTableHeader(colName, tooltipDefinitions, index)
              : null}
          </div>
        ))}
      </div>
    )
  }
}

export default TableHeader
