import { connect } from 'react-redux'
import UpsertBillingCustomer from './UpsertBillingCustomer'

import { showAlert, showErrorAlert } from '../../actions/alertMessageActions'

const mapStateToProps = (state, ownProps) => ({
  customerId: ownProps.params.customer_id,
})

const mapDispatchToProps = {
  showAlert,
  showErrorAlert,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpsertBillingCustomer)
