import { yardSummaryTypes } from '../../actions/yardSummaryActions'

const initialState = {
  loading: false,
}

const createNote = (state = initialState, { type }) => {
  switch (type) {
    case yardSummaryTypes.CREATE_NOTE_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case yardSummaryTypes.CREATE_NOTE_LOADED:
    case yardSummaryTypes.CREATE_NOTE_FAILED:
      return initialState
    default:
      return state
  }
}

export default createNote
