import { isNil } from 'lodash'
import Promise from 'bluebird'
import clientApi from './utils/clientApi'
import { parseFuelPriceIds } from './utils/billing'

const getTable = ({ yard, date, templateId }) => {
  const validate = () => {
    if (isNil(yard)) {
      throw new Error('Missing Yard ID')
    }
    if (isNil(date)) {
      throw new Error('Missing date')
    }
  }

  const params = {
    date,
    yard_id: yard,
  }

  if (!isNil(templateId)) {
    params.billing_template_id = templateId
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: '/billing/table',
    params,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const getInvoices = ({ yard, limit, offset, templateId }) => {
  const validate = () => {
    if (isNil(yard)) {
      throw new Error('Missing Yard ID')
    }
    if (isNil(limit)) {
      throw new Error('Missing limit')
    }
  }

  const params = {
    yard_id: yard,
    limit,
    offset,
  }

  if (!isNil(templateId)) {
    params.billing_template_id = templateId
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: '/billing/invoices',
    params,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const getInvoiceDetails = ({ invoiceId }) => {
  const validate = () => {
    if (isNil(invoiceId)) {
      throw new Error('Missing invoice ID')
    }
  }

  const params = {
    invoice_id: invoiceId,
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: '/billing/before_submit',
    params,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const upsertTable = ({ dates, rows, yard }) => {
  const validate = () => {
    if (isNil(dates)) {
      throw new Error('Missing dates')
    }
    if (isNil(rows)) {
      throw new Error('Missing rows')
    }
    if (isNil(yard)) {
      throw new Error('Missing yard ID')
    }
  }

  const getRequestOptions = () => ({
    method: 'put',
    url: '/billing',
    data: {
      dates,
      rows,
      yard_id: yard,
    },
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const submitInvoice = ({ invoiceId, status, items, fuelPriceIdList }) => {
  const validate = () => {
    if (isNil(invoiceId)) {
      throw new Error('Missing invoice ID')
    }
    if (isNil(status)) {
      throw new Error('Missing status')
    }
  }

  const getRequestOptions = () => ({
    method: 'put',
    url: `/billing/invoice/${invoiceId}`,
    data: {
      status,
      items,
      fuel_price_ids: parseFuelPriceIds(fuelPriceIdList),
    },
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const getFirstMissingDate = ({ yard }) => {
  const validate = () => {
    if (isNil(yard)) {
      throw new Error('Missing Yard ID')
    }
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: '/billing/missing',
    params: {
      yard_id: yard,
    },
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const getComments = ({ yard, date, templateId }) => {
  const validate = () => {
    if (isNil(yard)) {
      throw new Error('Missing Yard ID')
    }
    if (isNil(date)) {
      throw new Error('Missing date')
    }
  }

  const params = {
    yard_id: yard,
    date,
  }

  if (!isNil(templateId)) {
    params.billing_template_id = templateId
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: '/billing/comments',
    params,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const getFileToExport = ({ invoiceId }) => {
  const validate = () => {
    if (isNil(invoiceId)) {
      throw new Error('Missing Invoice ID')
    }
  }

  const getRequestOptions = () => ({
    method: 'post',
    url: `/billing/invoices/${invoiceId}/export`,
    responseType: 'blob',
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const submitPreview = ({ submitItems, invoiceId, fuelPriceIdList }) => {
  const validate = () => {
    if (isNil(submitItems)) {
      throw new Error('Missing Submit Items')
    }
    if (isNil(invoiceId)) {
      throw new Error('Missing Invoice ID')
    }
  }

  const getRequestOptions = () => ({
    method: 'post',
    url: `/billing/invoice/${invoiceId}/preview`,
    data: {
      items: submitItems,
      fuel_price_ids: parseFuelPriceIds(fuelPriceIdList),
    },
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const isSubmitAsFinalEnabled = ({ invoiceId }) => {
  const validate = () => {
    if (isNil(invoiceId)) {
      throw new Error('Missing Invoice ID')
    }
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: `/billing/invoices/${invoiceId}/is_final_enabled`,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const getTableToExport = ({ invoiceId }) => {
  const validate = () => {
    if (isNil(invoiceId)) {
      throw new Error('Missing Invoice ID')
    }
  }

  const getRequestOptions = () => ({
    method: 'post',
    url: `/billing/invoices/${invoiceId}/export_table`,
    responseType: 'blob',
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const billingTable = {
  getTable,
  getInvoices,
  getInvoiceDetails,
  upsertTable,
  getFirstMissingDate,
  submitInvoice,
  getComments,
  getFileToExport,
  submitPreview,
  isSubmitAsFinalEnabled,
  getTableToExport,
}

export default billingTable
