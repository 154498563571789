import styled from 'styled-components'
import CountUp from 'react-countup'
import { defaultCard } from '../../css/sharedStyles'

export const Container = styled.div`
  ${defaultCard}
  display: flex;
  align-items: center;
  padding: 15px 0;
  height: fit-content;
  justify-content: center;
  width: -moz-available;
  width: -webkit-fill-available;

  :not(:last-of-type) {
    margin-right: 15px;
  }

  @media screen and (max-width: 1200px) {
    margin: 10px;
    flex: 0 0 30%;

    &:first-of-type,
    &:last-of-type {
      margin: 10px;
    }
  }
`

export const IconWrapper = styled.div`
  border-radius: 50%;
  height: 3vw;
  width: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`

export const Icon = styled.img`
  object-fit: contain;
`

export const Title = styled.p`
  margin: 0;
  color: var(--darker-neutral-45);
  font-size: 0.75vw;
  text-transform: uppercase;
  font-weight: normal;

  @media screen and (max-width: 1200px) {
    font-size: 1vw;
  }
`

export const StyledCountUp = styled(CountUp)`
  margin: 0;
  font-size: 2rem;
`
