import { yardSummaryTypes } from '../../actions/yardSummaryActions'

const initialState = {
  loading: false,
  value: [],
}

const activeTimeSummary = (state = initialState, { type, payload }) => {
  switch (type) {
    case yardSummaryTypes.ACTIVE_TIME_SUMMARY_REQUESTED:
      return {
        ...state,
        loading: true,
        value: [],
      }

    case yardSummaryTypes.ACTIVE_TIME_SUMMARY_LOADED:
      return {
        ...state,
        loading: false,
        value: payload,
      }

    case yardSummaryTypes.ACTIVE_TIME_SUMMARY_FAILED:
      return initialState
    default:
      return state
  }
}

export default activeTimeSummary
