import _ from 'lodash'
import commentIcon from '../../../images/icons/icon_comment_state.svg'

function getTableRows({ rows }) {
  return rows.map((row, index) => ({
    key: index,
    label: row.display_name,
    displayOrder: row.display_order,
    type: row.type,
    service_id: row.id,
    monthToDate: row.month_to_date || 0,
    periodToDate: row.period_to_date || 0,
    isCommentRequired: row.is_comment_required,
    isIntercompany: !_.isNil(_.get(row, 'intercompany_yard_id')),
    isParentYard: _.get(row, 'is_parent'),
    intercompanyYard: _.get(row, 'intercompany_yard'),
    commentIcon,
    filling_type: row.filling_type,
    yardServiceValues: _.get(row, 'columns', []),
    priceUom: _.get(row, 'price_uom'),
    allowedValues: _.get(row, 'allowed_values'),
    billingCycle: _.get(row, 'billing_cycle'),
  }))
}

export default getTableRows
