import styled from 'styled-components/macro'

export const Action = styled.button`
  border: none;
  height: 30px;
  width: 30px;
  background: var(--base-blue);
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.2s all ease;

  :hover {
    background: var(--darker-blue-29);
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: .1;
    pointer-events: none;
  `}
`

export const ActionContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const Image = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;

  @supports (-moz-transform: translate(0, 0)) {
    height: unset;
    width: 80%;
  }
`
