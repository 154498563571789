import { trackingTypes } from '../../actions/trackingActions'

const initialState = 16

const zoomLevel = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.ZOOM_LEVEL_SET:
      return payload

    default:
      return state
  }
}

export default zoomLevel
