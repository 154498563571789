import Promise from 'bluebird'
import { createTypes } from './utils'
import api from '../api'

export const manageVcrsTypes = createTypes('manageVcrs', [
  'ISSUE_RATE_SET',
  'LIST_ORDERING_CHANGED',
  'VCR_SEARCH_SET',
  'HEADER_RETURN_OPTIONS_SET',
  'HEADER_RETURN_OPTIONS_RESET',
  { value: 'LIST', isAsync: true, isLazy: true },
  { value: 'VCR', isAsync: true },
])

export const setVCRsSearch = (filter) => ({
  type: manageVcrsTypes.VCR_SEARCH_SET,
  payload: filter,
})

export const loadVcrIssues = (yardId, limit = 1) => (dispatch) =>
  api.vcrs.getAll({ yardId, limit }).then((data) => {
    const { issueRate, cleanCount, issueCount } = data
    dispatch({
      type: manageVcrsTypes.ISSUE_RATE_SET,
      payload: {
        issueRate,
        cleanCount,
        issueCount,
      },
    })
  })

export const loadVCR = (vcrId) => (dispatch) => {
  dispatch({
    type: manageVcrsTypes.VCR_REQUESTED,
  })
  api.vcrs
    .getOne(vcrId)
    .then((vcr) => {
      dispatch({
        type: manageVcrsTypes.VCR_LOADED,
        payload: vcr,
      })
    })
    .catch(() => {
      dispatch({
        type: manageVcrsTypes.VCR_FAILED,
      })
    })
}

export const setVCRSortOption = (value) => ({
  type: manageVcrsTypes.LIST_ORDERING_CHANGED,
  payload: value,
})

export const setHeaderReturnOptions = ({ path, text }) => ({
  type: manageVcrsTypes.HEADER_RETURN_OPTIONS_SET,
  payload: { path, text },
})

export const resetHeaderReturnOptions = () => ({
  type: manageVcrsTypes.HEADER_RETURN_OPTIONS_RESET,
})

export const loadVcrList = ({
  yardId,
  vcrSortOption,
  submittedAtEnd,
  submittedAtStart,
  userId,
  vehicleId,
  limit,
  offset,
  isLazyLoading = false,
}) => (dispatch) =>
  Promise.resolve({
    yardId,
    limit,
    offset,
    status: vcrSortOption,
    submittedAtEnd,
    submittedAtStart,
    userId,
    vehicleId,
  })
    .tap(() => dispatch({ type: manageVcrsTypes.LIST_REQUESTED }))
    .then(api.vcrs.list)
    .then((vcrs) => {
      if (isLazyLoading) {
        dispatch({
          type: manageVcrsTypes.LIST_LAZY_LOADED,
          payload: vcrs,
        })
      } else {
        dispatch({
          type: manageVcrsTypes.LIST_LOADED,
          payload: vcrs,
        })
      }
    })
    .tapCatch(() => dispatch({ type: manageVcrsTypes.LIST_FAILED }))
