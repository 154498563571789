import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SendForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { onSubmit } = this.props
    const { value } = this.state
    onSubmit(value).then(() => {
      this.setState({ value: '' })
    })
  }

  handleChange = (event) => {
    const { value } = event.target
    this.setState({ value })
  }

  render() {
    const { value } = this.state
    const { loading } = this.props

    const disabled = value === '' || loading

    return (
      <form className="sendForm" onSubmit={this.handleSubmit}>
        <input
          type="text"
          name="send"
          placeholder="Add new comment"
          value={value}
          onChange={this.handleChange}
          data-analytics="write-note"
        />
        <div className="sendForm-button">
          <button type="submit" disabled={disabled} />
        </div>
      </form>
    )
  }
}

SendForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default SendForm
