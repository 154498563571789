import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router'
import { animationLiftUp } from '../../css/sharedStyles'

const animated = css`
  opacity: 0;
  animation: ${animationLiftUp} 0.5s ease forwards;
`

export const Container = styled.div`
  background: var(--lighter-neutral-96);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Box = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  background-color: var(--lighter-neutral-99);
  border-radius: var(--border-radius);
  padding: 15px 30px;
  box-shadow: 0px 2px 2px 0px rgba(124, 142, 164, 0.25);
`

export const Input = styled.input`
  ${animated}
  width: 100%;
  border: none;
  height: 48px;
  padding: 0 10px;
  margin-bottom: 35px;
  background-color: var(--lighter-neutral-96);
  outline: none;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  transition: 0.1s all ease;

  ::placeholder {
    color: var(--darker-neutral-45);
  }

  :first-of-type {
    animation-delay: 0.3s;
  }

  :last-of-type {
    margin-bottom: 10px;
    animation-delay: 0.4s;
  }

  :focus {
    border: 2px solid var(--base-blue);
  }
`

export const Button = styled.button`
  ${animated}
  width: 100%;
  background: var(--darker-blue-43);
  border-radius: var(--border-radius);
  height: 52px;
  margin-bottom: 10px;
  border: none;
  color: var(--lighter-neutral-99);
  transition: 0.1s all ease;
  animation-delay: 0.6s;

  :hover {
    background: var(--base-blue);
  }
`

export const StyledLink = styled(Link)`
  ${animated}
  width: 100%;
  display: flex;
  justify-content: center;
  height: 30px;
  margin: 15px 0;
  color: var(--darker-neutral-45);
  transition: 0.2s all;
  animation-delay: 0.9s;

  :hover {
    color: var(--base-blue);
  }
`

export const Warning = styled.span`
  text-align: center;
  display: block;
  width: 100%;
  color: var(--base-red);
  margin-bottom: 10px;
  height: 35px;
`

export const Label = styled.span`
  ${animated}
  animation-delay: 2.3s;
  text-align: center;
  margin-bottom: 20px;
  color: var(--darker-neutral-45);
`
