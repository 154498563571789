import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Body from './Body'
import Item from './Item'
import TabContext from './TabContext'

class Tabs extends Component {
  static Header = Header

  static Body = Body

  static Item = Item

  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedIndex: 0,
      onChange: this.handleChange,
    }
  }

  handleChange = (index) => {
    this.setState({
      selectedIndex: index,
    })
  }

  render() {
    const { children } = this.props

    return (
      <TabContext.Provider value={this.state}>{children}</TabContext.Provider>
    )
  }
}

export default Tabs
