import clientApi from './utils/clientApi'

const get = (dashboardId, yardName) =>
  clientApi({
    method: 'get',
    url: `/dashboard/${dashboardId}`,
    params: {
      yard_name: yardName,
    },
  }).then(({ data }) => data)

const dashboard = {
  get,
}

export default dashboard
