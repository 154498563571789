import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import VehicleDetailsInfoRow from '../VehicleDetailsInfoRow'

import { getShortName } from '../../utils'

const VehicleDetailsSingleNote = ({ comment, timezone }) => {
  const id = _.get(comment, 'id')
  const firstName = _.get(comment, 'User.first_name')
  const lastName = _.get(comment, 'User.last_name')
  const role = _.get(comment, 'User.Role.name')

  const props = {
    type: 'note',
    note: {
      comment: _.get(comment, 'comment'),
      user: getShortName({ firstName, lastName }),
      date: _.get(comment, 'updated_at'),
      timezone,
      role,
      id,
    },
  }

  return <VehicleDetailsInfoRow {...props} />
}

VehicleDetailsSingleNote.propTypes = {
  timezone: PropTypes.string.isRequired,
  comment: PropTypes.shape({
    id: PropTypes.number,
    comment: PropTypes.string,
    updated_at: PropTypes.string,
    User: {
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      Role: {
        name: PropTypes.string,
      },
    },
  }).isRequired,
}

export default VehicleDetailsSingleNote
