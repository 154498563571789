import { connect } from 'react-redux'
import NotesBox from './NotesBox'
import './NotesBox.css'

import { getYardTimeZone } from '../../selectors'

import { loadYardNotes, createYardNote } from '../../actions/yardSummaryActions'

const mapStateToProps = (state) => ({
  notes: state.yardSummary.notes.value,
  loading: state.yardSummary.notes.loading,
  yardId: state.general.selectedYard,
  createNote: state.yardSummary.createNote,
  timezone: getYardTimeZone(state),
})

const mapDispatchToProps = {
  loadYardNotes,
  createYardNote,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesBox)
