import clientApi from './utils/clientApi'

const send = ({ userId }) =>
  clientApi({
    method: 'post',
    url: '/notifications',
    data: {
      title: 'test',
      message: 'is pinging you',
      receiver_id: userId,
    },
  }).then(({ data }) => data)

const notifications = {
  send,
}

export default notifications
