import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Loading from '../Loading'
import NewToolTip from '../NewToolTip'
import Dropdown from '../Dropdown'

import IconComment from '../../images/dynamicColor/iconComment'

import { formatCellValue, matchNumericValueRegex } from '../../utils'

import * as S from './styles'

import iconDelete from '../../images/icons/icon_delete.svg'
import parseFraction from '../../utils/parseFraction'

function getCommentFillColor(isRequired) {
  return isRequired ? 'var(--base-red)' : 'var(--base-blue)'
}

const intercompanyTooltipDescription = (
  <p>
    <b>BLUE</b> or <b>PURPLE</b> labels indicate a yard&apos;s dependence on
    another yard&apos;s billing.
  </p>
)

const BillingTable = ({
  columns,
  rows,
  isLoading,
  editMode,
  editModeColor,
  tableTitle,
  handleClick,
  isEditModeActive,
  isCommentModeEnabled,
  handleChange,
  handleMouseHover,
  handleMouseLeave,
  status,
  fractionDigits,
}) => {
  const firstMissingColumn = columns.find((column) => column.missingValues)
  const parsedColumns = [
    { key: 'label', text: tableTitle, isFutureDate: false },
    ...columns,
  ]

  if (isLoading) {
    return <Loading />
  }

  function handleAutoFocus(indexToCompare) {
    if (!_.isNil(firstMissingColumn)) {
      const columnKey = _.get(firstMissingColumn, 'key')
      const firstMissingValueRow = rows.find((row) =>
        _.isNil(_.get(row.yardServiceValues[columnKey], 'value')),
      )
      const rowKey = _.get(firstMissingValueRow, 'key')

      return (
        String(columnKey * rows.length + rowKey + 1) === String(indexToCompare)
      )
    }

    return false
  }

  function renderContent({ cellContent, index, column, row }) {
    const isFloat = _.get(row, 'priceUom') === 'HR'

    if (!_.isObject(cellContent)) {
      return (
        <S.Text isCommentRequired={row.isCommentRequired}>
          {isFloat && _.isNumber(cellContent)
            ? parseFloat(cellContent).toFixed(2)
            : formatCellValue({ value: cellContent })}
        </S.Text>
      )
    }

    const formattedCellValue = formatCellValue({ value: cellContent.value })
    const hasDropdownValues = !_.isEmpty(row.allowedValues)

    const isDisabled =
      column.isFutureDate || cellContent.invoice_status === 'final'

    const isCommentEmpty = _.isEmpty(cellContent.comment)
    const isCommentRequired = _.get(row, 'isCommentRequired')
    const tabIndex = String(column.key * rows.length + row.key + 1)

    const valueToDisplay = isFloat
      ? parseFloat(cellContent.value).toFixed(2)
      : formattedCellValue

    const renderDeleteButtonOnHover = () => (
      <S.HiddenBlock
        isHidden={
          _.isEmpty(String(cellContent.value)) || _.isNil(cellContent.value)
        }
      >
        <S.Icon
          onClick={
            _.isObject(cellContent) &&
            !_.isNil(cellContent.value) &&
            editMode !== 'view'
              ? () =>
                  handleChange({
                    target: { value: '' },
                    rowIndex: index,
                    columnIndex: column.key,
                    isCommentRequired,
                    isFloat,
                  })
              : undefined
          }
          horizontalAlignment="left"
        >
          <S.IconDelete src={iconDelete} />
        </S.Icon>
      </S.HiddenBlock>
    )

    const renderCommentIcon = () => (
      <S.Icon
        isEmpty={isCommentEmpty}
        onMouseOver={(event) =>
          !isCommentEmpty &&
          handleMouseHover({
            event,
            columnIndex: column.key,
            rowIndex: index,
          })
        }
        onClick={
          _.isObject(cellContent) &&
          !_.isNil(cellContent.value) &&
          editMode !== 'view'
            ? ({ target }) =>
                handleClick({
                  target,
                  column: column.key,
                  row: index,
                  isCommentRequired,
                })
            : undefined
        }
        onMouseLeave={handleMouseLeave}
        horizontalAlignment="right"
      >
        <IconComment fill={getCommentFillColor(isCommentRequired)} />
      </S.Icon>
    )

    const renderCommentButtonOnHover = () => (
      <S.HiddenBlock
        isHidden={Number(cellContent.value) === 0 && isCommentEmpty}
      >
        {renderCommentIcon()}
      </S.HiddenBlock>
    )

    if (isEditModeActive && hasDropdownValues) {
      const options = row.allowedValues.map((allowedValue) => {
        const parsedAllowedValue = isFloat
          ? parseFraction({
              value: allowedValue,
              fractionDigits,
              isDropdownOption: true,
            })
          : allowedValue

        return {
          value: parsedAllowedValue,
          label: parsedAllowedValue,
        }
      })

      const customControl = (props) => (
        <S.StyledControl
          currentEditing={cellContent.currentEditing}
          editModeColor={editModeColor}
          {...props}
        />
      )

      return (
        <S.ObjectContainer>
          <Dropdown
            value={cellContent.value}
            options={options}
            onChange={({ value }) =>
              handleChange({
                target: { value },
                rowIndex: index,
                columnIndex: column.key,
                isCommentRequired,
                isFloat,
              })
            }
            tabIndex={tabIndex}
            isDisabled={isDisabled}
            isSearchable={false}
            isClearable={false}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            components={{ Control: customControl, Placeholder: () => null }}
          />
          {renderCommentButtonOnHover()}
          {renderDeleteButtonOnHover()}
        </S.ObjectContainer>
      )
    }

    if (isEditModeActive) {
      return (
        <S.ObjectContainer>
          <S.Input
            type="tel"
            onKeyPress={matchNumericValueRegex}
            value={cellContent.value}
            currentEditing={cellContent.currentEditing}
            editModeColor={editModeColor}
            tabIndex={tabIndex}
            disabled={isDisabled}
            onChange={({ target }) =>
              handleChange({
                target,
                rowIndex: index,
                columnIndex: column.key,
                isCommentRequired,
                isFloat,
              })
            }
            autoFocus={handleAutoFocus(tabIndex)}
          />
          {renderCommentButtonOnHover()}
          {renderDeleteButtonOnHover()}
        </S.ObjectContainer>
      )
    }

    if (isCommentModeEnabled) {
      return (
        <S.ClickableContainer
          currentEditing={cellContent.currentEditing}
          editModeColor={editModeColor}
          onClick={
            _.isObject(cellContent) && cellContent.value
              ? ({ target }) =>
                  handleClick({ target, column: column.key, row: index })
              : undefined
          }
        >
          {cellContent.comment ? renderCommentIcon() : null}
          {formattedCellValue}
        </S.ClickableContainer>
      )
    }

    return (
      <S.ObjectContainer>
        {cellContent.comment ? renderCommentIcon() : null}
        {_.isNil(formattedCellValue) ? null : valueToDisplay}
      </S.ObjectContainer>
    )
  }

  return (
    <S.Container
      editMode={editMode}
      editModeColor={editModeColor}
      status={status}
    >
      <S.Header length={parsedColumns.length}>
        {parsedColumns.map((column, index) => {
          if (column.text === tableTitle) {
            return (
              <NewToolTip
                key={index}
                title="INTERCOMPANY RELATIONS COLOR LABELS"
                description={intercompanyTooltipDescription}
              >
                <S.Cell key={column.key}>{column.text}</S.Cell>
              </NewToolTip>
            )
          }
          return <S.Cell key={column.key}>{column.text}</S.Cell>
        })}
      </S.Header>

      <S.Body length={rows.length}>
        {rows.map((row, index) => (
          <S.Row key={row.key} length={parsedColumns.length}>
            {parsedColumns.map((column, columnIndex) => {
              const { isParentYard } = row
              const isMom =
                row.billingCycle === 'monthly_mom' &&
                column.isSecondBimonthlyPeriod
              const isServiceDescription = columnIndex === 0
              const shouldRenderServiceValues = _.isNumber(column.key)

              const cellContent = shouldRenderServiceValues
                ? row.yardServiceValues[column.key]
                : row[column.key]

              const hasValue = !_.isNil(_.get(cellContent, 'value'))

              const isServiceValue = _.isObject(cellContent)

              const isEmptyMomValue =
                isMom &&
                !isServiceDescription &&
                (_.isNil(cellContent) || (isServiceValue && !hasValue))

              const isCellBlockedByParentYard =
                _.get(
                  row.yardServiceValues[column.key],
                  'intercompany_invoice_status',
                ) === 'final'

              const isIntercompanyServiceDescription =
                row.isIntercompany && isServiceDescription

              const isValueMissing =
                isServiceValue && !hasValue && !column.isFutureDate

              const isDisabled =
                column.isFutureDate || isCellBlockedByParentYard

              const renderCell = () => (
                <S.Cell
                  key={column.key}
                  editModeColor={editModeColor}
                  isCommentModeEnabled={hasValue ? isCommentModeEnabled : false}
                  isParentYard={isParentYard}
                  isMomService={isEmptyMomValue}
                  isValueMissing={isValueMissing}
                  isDisabled={isDisabled}
                  isIntercompanyServiceDescription={
                    isIntercompanyServiceDescription
                  }
                >
                  {renderContent({ cellContent, index, column, row })}
                </S.Cell>
              )

              if (isIntercompanyServiceDescription) {
                const yardCode = _.get(row, 'intercompanyYard.code')

                return (
                  <NewToolTip
                    key={yardCode}
                    title={`Intercompany relationship with ${yardCode}`}
                    description={`Final submission at PC ${_.get(
                      row,
                      'intercompanyYard.code',
                    )} is dependent on quantities entered and submitted at this yard.`}
                  >
                    {renderCell()}
                  </NewToolTip>
                )
              }

              if (isCellBlockedByParentYard) {
                const relationshipYard = _.get(row, 'intercompanyYard.code')
                return (
                  <NewToolTip
                    key={`${index}-${relationshipYard}`}
                    title={`Intercompany relationship with PC ${relationshipYard}`}
                    description={`You cannot edit this service because the parent yard PC ${relationshipYard} has already submitted this period as final.`}
                  >
                    <S.CellWrapper>{renderCell()}</S.CellWrapper>
                  </NewToolTip>
                )
              }

              if (isEmptyMomValue) {
                return (
                  <NewToolTip
                    key={index}
                    title="Daily Monthly Middle-of-month VALUES"
                    description="Values are required for the first bimonthly billing cycle from the 1st to the 15th days of the month."
                  >
                    {renderCell()}
                  </NewToolTip>
                )
              }

              return renderCell()
            })}
          </S.Row>
        ))}
      </S.Body>
    </S.Container>
  )
}

BillingTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isFutureDate: PropTypes.bool,
      missingValues: PropTypes.bool,
      text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      commentIcon: PropTypes.string,
      label: PropTypes.string,
      monthToDate: PropTypes.number,
      periodToDate: PropTypes.number,
      service_id: PropTypes.number,
      yardServiceValues: PropTypes.arrayOf(
        PropTypes.shape({
          comment: PropTypes.string,
          date: PropTypes.string,
          status: PropTypes.string,
          updated_at: PropTypes.string,
          user_id: PropTypes.number,
          value: PropTypes.number,
          yard_service_id: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEditModeActive: PropTypes.bool.isRequired,
  isCommentModeEnabled: PropTypes.bool.isRequired,
  editMode: PropTypes.string.isRequired,
  tableTitle: PropTypes.string.isRequired,
  editModeColor: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleMouseHover: PropTypes.func.isRequired,
  handleMouseLeave: PropTypes.func.isRequired,
  status: PropTypes.oneOf(['locked', 'unlocked']).isRequired,
  fractionDigits: PropTypes.number,
}

BillingTable.defaultProps = {
  fractionDigits: 2,
}

export default BillingTable
