import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import './EnhancedBarChart.css'

class EnhanceChart extends Component {
  constructor(props) {
    super(props)

    this.container = React.createRef()
  }

  componentDidMount() {
    const { highlight } = this.props
    const { current } = this.container
    if (current) {
      setTimeout(() => {
        if (highlight && this.container.current) {
          const xPosition = this.getAttributeX(
            this.container.current,
            highlight,
          )
          this.highlightBar(this.container.current, xPosition)
        }
      }, 500)
    }
  }

  highlightBar = (htmlNode, xPosition) => {
    const allSvgPaths = htmlNode.querySelectorAll('path')
    const hoveredCells = Array.from(allSvgPaths).filter(
      (path) => parseFloat(path.getAttribute('x')) === parseFloat(xPosition),
    )
    hoveredCells.forEach((elm) => elm.classList.add('highlight-bar'))
  }

  getAttributeX = (htmlNode, highlight) => {
    const barLayer = htmlNode.querySelectorAll(
      '.recharts-layer.recharts-bar-rectangles',
    )[0]
    if (highlight && barLayer) {
      const lastIndex = _.findLastIndex(
        barLayer.children,
        (child) => child.nodeName === 'path',
      )
      const arr = Array.from(barLayer.children)
      const lastBar = arr[lastIndex]
      return lastBar.getAttribute('x')
    }
    return null
  }

  render() {
    const { children } = this.props

    return <div ref={this.container}>{children}</div>
  }
}

EnhanceChart.propTypes = {
  highlight: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
EnhanceChart.defaultProps = {
  highlight: null,
  children: null,
}

export default EnhanceChart
