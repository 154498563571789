import styled, { css } from 'styled-components'

import iconGreenArrow from '../../images/icons/icon_green_arrow.svg'
import iconRedArrow from '../../images/icons/icon_red_arrow.svg'

import iconDraft from '../../images/icons/icon_status_draft.svg'
import iconLateDraft from '../../images/icons/icon_status_late_draft.svg'
import iconAccrual from '../../images/icons/icon_status_accrual.svg'
import iconFinal from '../../images/icons/icon_status_final.svg'

const defaultBadgeStyle = css`
  display: flex;
  width: 23px;
  height: 23px;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
`

const greenBadge = css`
  background: url(${iconFinal});
`

const yellowBadge = css`
  background: url(${iconAccrual});
`

const redBadge = css`
  background: url(${iconLateDraft});
`

const grayBadge = css`
  background: url(${iconDraft});
`

function getEngineHoursBadge(hasMissingEngineHours) {
  return hasMissingEngineHours ? redBadge : greenBadge
}

function getAuditsBadge(auditPercentage) {
  if (auditPercentage >= 30) {
    return redBadge
  }

  if (auditPercentage > 10 && auditPercentage < 30) {
    return yellowBadge
  }

  return greenBadge
}

function getBillingStatusBadge(status) {
  if (status === 'draft') {
    return grayBadge
  }

  if (status === 'past_due') {
    return redBadge
  }

  if (status === 'accrual') {
    return yellowBadge
  }

  if (status === 'final') {
    return greenBadge
  }

  return 'border: 1px solid var(--lighter-neutral-85);'
}

function getBillingStatusColor(status) {
  if (status === 'draft') {
    return 'var(--base-neutral)'
  }

  if (status === 'past_due') {
    return 'var(--base-red)'
  }

  if (status === 'accrual') {
    return 'var(--base-yellow)'
  }

  return 'var(--base-green)'
}

function getBillingDaysBadge(missingBillingDays) {
  if (missingBillingDays > 4) {
    return redBadge
  }

  if (missingBillingDays > 0 && missingBillingDays <= 4) {
    return yellowBadge
  }

  return greenBadge
}

export const HeaderTitle = styled.div`
  font-size: 24px;
`

export const Content = styled.div`
  height: 100%;
`

export const TabsWrapper = styled.div`
  width: 250px;
`

export const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export const SearchWrapper = styled.div`
  display: flex;
`

export const LpmhBadge = styled.div`
  ${(props) =>
    props.isWeekGreaterThanMonth
      ? `
      background:
        var(--lighter-green-88)
        url(${iconGreenArrow});
    `
      : `
      background:
        var(--lighter-red-89)
        url(${iconRedArrow});
    `}

  ${defaultBadgeStyle}
`

export const AuditsBadge = styled.div`
  ${(props) => getAuditsBadge(props.auditPercentage)}
  ${defaultBadgeStyle}
`

export const EngineHoursBadge = styled.div`
  ${(props) => getEngineHoursBadge(props.hasMissingEngineHours)}
  ${defaultBadgeStyle}
`

export const BadgeContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`

export const BillingStatusBadge = styled.div`
  ${(props) => getBillingStatusBadge(props.status)}
  ${defaultBadgeStyle}
`

export const TooltipDescription = styled.div`
  display: flex;
  flex-direction: column;
`

export const BillingCycleMiniBadge = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${(props) => getBillingStatusColor(props.status)};
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 6px;
`

export const Border = styled.div`
  border-top: var(--tooltip-border);
  width: 100%;
  display: block;
  margin: 10px 0 10px;
`

export const TooltipDescriptionTitle = styled.p`
  font-family: var(--font-demibold);
  margin: 0 0 10px;
`

export const MissingBillingDays = styled.div`
  ${defaultBadgeStyle}
`

export const BillingDaysBadge = styled.div`
  ${(props) => getBillingDaysBadge(props.missingBillingDays)}
  ${defaultBadgeStyle}
`

export const Battery = styled.img`
  width: 54px;
  position: absolute;
  left: -6px;
`

export const SubMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 15px 40px;
  width: 100%;
  margin-bottom: 15px;
  flex-wrap: wrap;
`

export const ActionContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const Action = styled.button`
  border: none;
  height: 30px;
  width: 30px;
  background: var(--base-blue);
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.2s all ease;

  :hover {
    background: var(--darker-blue-29);
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: .1;
    pointer-events: none;
  `}
`

export const Image = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;

  @supports (-moz-transform: translate(0, 0)) {
    height: unset;
    width: 80%;
  }
`

export const ToggleContainer = styled.div`
  margin-bottom: 20px;
  background-color: var(--lighter-neutral-96);
`
