import styled, { css } from 'styled-components/macro'
import ReactTippy from '@tippyjs/react'

const contentStyle = css`
  display: block;
  font-family: 'ArticulatCF-Light';
  font-size: 16px;
  color: var(--tooltip-${(p) => p.theme}-theme-text);
  border-top: var(--tooltip-border);
  margin-top: 6px;
  padding-top: 6px;

  & b {
    font-family: 'ArticulatCF-Bold';
  }
`

export const Tippy = styled(ReactTippy)`
  background-color: var(--tooltip-${(p) => p.theme}-theme-bg);
  border-radius: var(--border-radius);
  padding: 6px;

  & .tippy-arrow {
    color: var(--tooltip-${(p) => p.theme}-theme-bg);
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const Icon = styled.img`
  height: 1rem;
  width: 1rem;
  object-fit: contain;
  margin-right: 10px;
`

export const Title = styled.span`
  font-family: 'ArticulatCF-Medium';
  font-size: 18px;
  display: block;
  color: var(--tooltip-${(p) => p.theme}-theme-text);
`

export const Description = styled.div`
  ${contentStyle}
`

export const Footer = styled.div`
  ${contentStyle}
`

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--darker-neutral-45);
  margin: 6px 0 6px 0;
  padding: 8px 0 0 0;
`

export const Action = styled.div`
  background: var(--base-blue);
  text-align: center;
  border-radius: 20px;
  padding: 5px 15px;
  color: var(--lighter-neutral-99);
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`
