import _ from 'lodash'
import getDateBetween from './getDateBetween'

function getActiveTabItem({ weekTabs, urlDate, timezone }) {
  const sortItem = _.find(weekTabs, ({ startDate, endDate }) =>
    getDateBetween({ startDate, endDate, urlDate, timezone }),
  )

  return _.get(sortItem, 'value')
}

export default getActiveTabItem
