import React, { Component } from 'react'
import PropTypes from 'prop-types'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from 'moment'
import _ from 'lodash'

import { NewHeader, NewContainer, Loading } from '../../components'
import TouchOverlay from '../../components/Gallery/TouchOverlay'
import InputForm from './InputForm/InputForm'
import IssueTable from './IssueTable/IssueTable'

import allVehicleImages from '../../utils/allVehicleImages'
import { vehicleTypeIdNameMap } from '../../config'

import issuesSnapshot from './constants'

import INTRMODLLogo from '../../images/assets/logo.svg'
import iconDownload from '../../images/icons/icon_export_dark.svg'
import tooltipIconDownload from '../../images/tooltip/download.svg'

import './PrintVCR.css'

class PrintVCR extends Component {
  componentDidMount() {
    const { loadVCR, params } = this.props
    loadVCR(params.vcr_id)
  }

  onPrintVCR = () => {
    const { params } = this.props
    const input = document.getElementById('printVCR')
    const pdf = new JsPDF('p', 'mm', 'a4')

    html2canvas(input, { scale: 1 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      pdf.addImage(imgData, 'PNG', 10, 5)
      pdf.save(`VCR_${params.vcr_id}.pdf`)
    })
  }

  getIssueState = (vcrValues, vehicleTypeId) => {
    const issuesIds = vcrValues.issues.map((issue) => issue.VcrTemplateIssue.id)
    const vehicleIssues = issuesSnapshot.filter(
      (issue) => issue.vehicleTypeId === vehicleTypeId,
    )
    return vehicleIssues.map((issue) => {
      if (issuesIds.indexOf(issue.id) > -1) {
        return {
          ...issue,
          comment: 'X',
        }
      }
      return { ...issue }
    })
  }

  renderTouchOverlay = (data, vehicleTypeId) => {
    const vehicleImages = allVehicleImages[vehicleTypeId]
    const touches = data.touches !== undefined ? data.touches : []
    const imageSize = vehicleTypeId === 4 ? '100px' : '160px'

    return vehicleImages.map(({ image }, index) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: imageSize,
          height: '100%',
        }}
        key={image}
      >
        <TouchOverlay
          touches={touches.filter((touch) => touch.image_id === index)}
        >
          <img
            className={`vehicleImageStyle img${index}`}
            src={vehicleImages[index].image}
            alt="vehicle"
            style={{
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              width: '100%',
              height: '100%',
            }}
          />
        </TouchOverlay>
      </div>
    ))
  }

  render() {
    const { vcrData, params } = this.props

    const vcrValues = _.get(vcrData, 'value')

    const hasVcrData = !_.isEmpty(vcrValues)

    const vehicleTypeId = _.get(vcrValues, 'vcr_template_id')

    const titleAction = [
      {
        key: 0,
        icon: iconDownload,
        onClick: () => this.onPrintVCR(),
        tooltip: {
          titleIcon: tooltipIconDownload,
          title: 'Save as PDF',
          action: 'Click to download this preview as PDF',
        },
      },
    ]

    return (
      <>
        <NewHeader
          title={hasVcrData ? `VCR ${params.vcr_id} PDF preview` : 'Loading'}
          returnText={`Back to VCR ${params.vcr_id} details`}
          returnUrl={`/vcrs/${params.vcr_id}`}
          titleAction={hasVcrData && titleAction}
        />
        {!hasVcrData ? (
          <Loading />
        ) : (
          <NewContainer>
            <div className="cardBox vcr_preview">
              <div
                className="printVCR"
                id="printVCR"
                style={{ width: '190mm', height: '287mm' }}
              >
                <div className="printVCR__title">
                  <div className="RMS_logo">RMS</div>
                  <p>{`${vehicleTypeIdNameMap[vehicleTypeId]} INSPECTION FORM`}</p>
                  <div className="INTRMODL_logo">
                    <img src={INTRMODLLogo} alt="logo" />
                    INTRMODL
                  </div>
                </div>
                <div className="first_row flex-row">
                  <InputForm title="VCR ID" content={params.vcr_id} />
                  <InputForm title="Unit #" content={vcrValues.vehicle_name} />
                  <InputForm
                    title="Driver"
                    content={`${vcrValues.User.first_name} ${vcrValues.User.last_name}`}
                  />
                </div>
                <div className="second_row flex-row">
                  <InputForm
                    title="Date"
                    content={moment(vcrValues.submitted_at).format(
                      'YYYY-MM-DD',
                    )}
                  />
                  <InputForm
                    title="Time"
                    content={moment(vcrValues.submitted_at).format('HH:mm')}
                  />
                  <InputForm
                    title="Engine hours"
                    content={vcrValues.engine_hours}
                  />
                </div>
                <div className="printVCR_issues-left-title">
                  Pre-Trip inspection
                </div>
                <div className="printVCR_issues flex-row">
                  <div className="printVCR_issues-column">
                    <IssueTable
                      issues={this.getIssueState(
                        vcrValues,
                        vehicleTypeId,
                      ).slice(0, 27)}
                    />
                  </div>
                  <div className="printVCR_issues-column">
                    <IssueTable
                      issues={this.getIssueState(
                        vcrValues,
                        vehicleTypeId,
                      ).slice(27, 99)}
                    />
                    <div className="vehicle_comment">
                      Note all dents, scratches or other damage on the diagrams
                      on the right and in the comments section below
                    </div>
                  </div>
                  <div
                    className="printVCR_issues-column"
                    style={{ marginRight: '0px' }}
                  >
                    <div className="vehicle_images">
                      {this.renderTouchOverlay(vcrValues, vehicleTypeId)}
                    </div>
                  </div>
                </div>
                <div className="vcr__comment">
                  <InputForm
                    title="Comments"
                    content={
                      vcrValues.comment === 'NULL' || vcrValues.comment === ''
                        ? 'No recorded comment by driver.'
                        : vcrValues.comment
                    }
                  />
                  <InputForm title="Mechanic comments" />
                </div>
                <div className="repairedDate flex-row">
                  <p>Date repaired</p>
                  <div className="repairedDate__input" />
                </div>
              </div>
            </div>
          </NewContainer>
        )}
      </>
    )
  }
}

PrintVCR.propTypes = {
  loadVCR: PropTypes.func.isRequired,
  vcrData: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
  }).isRequired,
  vehicleList: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  params: PropTypes.objectOf(PropTypes.string),
}

PrintVCR.defaultProps = {
  params: {},
}

export default PrintVCR
