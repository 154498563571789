import { manageVehiclesTypes } from '../../actions/manageVehiclesActions'

const initialState = {
  value: [],
  nextOffset: 0,
  total: 0,
  isLoading: false,
}

const commentList = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVehiclesTypes.COMMENTS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }

    case manageVehiclesTypes.COMMENTS_LOADED:
      return {
        ...state,
        value: payload.vehicle_comments,
        nextOffset: payload.nextOffset,
        total: payload.total,
        isLoading: false,
      }

    case manageVehiclesTypes.COMMENTS_LAZY_LOADED:
      return {
        ...state,
        value: state.value.concat(payload.vehicle_comments),
        nextOffset: payload.nextOffset,
        total: payload.total,
        isLoading: false,
      }

    case manageVehiclesTypes.COMMENTS_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}

export default commentList
