import _ from 'lodash'

export default function getVehicleStatus({ session, userId, zoneType }) {
  if (!session) {
    return 'offline' // user offline OR no session found
  }
  if (userId) {
    return 'online' // worker logged in a vehicle (no matter which zone or if they're moving)
  }
  if (_.trim(zoneType) !== 'hostler') {
    return 'missing-driver' // vehicle without logged user outside parking zone
  }

  return 'offline'
}
