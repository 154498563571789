import React from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import 'tippy.js/dist/svg-arrow.css'

const ToolTip = ({ title, message, icon, ...tippyProps }) => (
  <Tippy
    {...tippyProps}
    content={
      <div className="tooltip">
        {icon && <div className="tooltip-icon">{icon}</div>}
        <div className="tooltip-content">
          {title && <div className="tooltip-title">{title}</div>}
          {message && <div className="tooltip-message">{message}</div>}
        </div>
      </div>
    }
  />
)

ToolTip.propTypes = {
  ...Tippy.propTypes,
  title: PropTypes.string,
  message: PropTypes.string,
  icon: PropTypes.node,
}

ToolTip.defaultProps = {
  title: undefined,
  message: undefined,
  icon: undefined,
}

export default ToolTip
