import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  pointer-events: none;
  row-gap: 5px;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const Icon = styled.img`
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-right: 10px;
`

export const Text = styled.span`
  color: ${(props) =>
    props.color ? `var(${props.color})` : 'var(--base-neutral)'};
  font-size: 0.9em;
`
