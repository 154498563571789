import { yardSummaryTypes } from '../../actions/yardSummaryActions'

const initialState = {
  loading: false,
  value: [],
}

const notes = (state = initialState, { type, payload }) => {
  switch (type) {
    case yardSummaryTypes.NOTES_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case yardSummaryTypes.NOTES_LOADED:
      return {
        ...state,
        loading: false,
        value: payload.notes,
      }
    case yardSummaryTypes.NOTES_FAILED:
      return initialState
    case yardSummaryTypes.CREATE_NOTE_LOADED:
      return {
        ...state,
        value: [payload.note, ...state.value],
      }
    default:
      return state
  }
}

export default notes
