import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import nothingToSeeHere from '../../images/assets/nothing_to_see_here.svg'
import somethingWentWrong from '../../images/assets/something_went_wrong.svg'
import noResults from '../../images/assets/no_results.svg'

import * as S from './styles'

const containerImage = {
  default: nothingToSeeHere,
  error: somethingWentWrong,
  search: noResults,
}

const blankMessages = {
  default: { title: 'Nothing to see here', subtitle: 'This content is empty.' },
  error: {
    title: 'Whoops! Something went wrong',
    subtitle: 'We’ve been notified and we’ll be working on it soon.',
  },
  search: {
    title: "We didn't find any results for your selection",
    subtitle: 'This content is empty.',
  },
  worker: {
    title: 'No worker selected',
    subtitle: 'Select workers to see their metrics',
  },
}

const Blank = ({ title, subtitle, action, type, small, className }) => (
  <S.Container className={className} small={small}>
    <S.Title>{title || _.get(blankMessages[type], 'title')}</S.Title>
    <S.Subtitle>
      {subtitle || _.get(blankMessages[type], 'subtitle')}
    </S.Subtitle>
    {!_.isEmpty(action) && (
      <S.ActionWrapper>
        <S.Action onClick={action.onClick}>{action.text}</S.Action>
      </S.ActionWrapper>
    )}
    {!small && <S.Image src={containerImage[type]} />}
  </S.Container>
)

Blank.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string,
  }),
  type: PropTypes.string,
  small: PropTypes.bool,
  className: PropTypes.string.isRequired,
}

Blank.defaultProps = {
  title: null,
  subtitle: null,
  action: {},
  type: 'default',
  small: false,
}

export default Blank
