import { upsertAuditTypes } from '../../actions/upsertAuditActions'

const initialState = {
  loading: false,
  audit: {},
}

const upsertAudit = (state = initialState, { type, payload }) => {
  switch (type) {
    case upsertAuditTypes.CREATE_REQUESTED:
    case upsertAuditTypes.AUDIT_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case upsertAuditTypes.CREATE_LOADED:
      return {
        ...state,
        loading: false,
      }
    case upsertAuditTypes.AUDIT_LOADED:
      return {
        ...state,
        loading: false,
        audit: payload.audit,
      }
    case upsertAuditTypes.CREATE_FAILED:
    case upsertAuditTypes.AUDIT_FAILED:
    case upsertAuditTypes.AUDIT_RESET:
      return initialState
    default:
      return state
  }
}

export default upsertAudit
