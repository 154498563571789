import { isNil } from 'lodash'
import Promise from 'bluebird'
import clientApi from './utils/clientApi'

const count = (
  { userId, isGoodFlag, startTime, endTime, bins },
  options = {},
) =>
  clientApi({
    ...options,
    method: 'get',
    url: '/metrics/flags/aggregate',
    params: {
      aggregate: 'count',
      isGoodFlag,
      user_id: userId,
      started_at_start: startTime,
      started_at_end: endTime,
      bins,
    },
  }).then(({ data }) => data)

const avg = ({ userId, startTime, endTime, bins, name }) =>
  clientApi({
    method: 'get',
    url: '/metrics/flags/aggregate',
    params: {
      aggregate: ['avg', 'sum'],
      user_id: userId,
      started_at_start: startTime,
      started_at_end: endTime,
      bins,
      name,
    },
  }).then(({ data }) => data)

const getAll = (
  { yardId, startTime, endTime, userId, type, isGoodFlag, name },
  options = {},
) => {
  const validate = () => {
    if (isNil(userId) && isNil(yardId)) {
      throw new Error('Missing userId or yardId')
    }
  }

  const getRequestOptions = () => {
    const params = {
      startTime: startTime.valueOf(),
      endTime: endTime.valueOf(),
      user_id: userId,
      yard_id: yardId,
      type,
      isGoodFlag,
      flags: name,
    }

    return {
      ...options,
      method: 'get',
      url: '/metrics/flags',
      params,
    }
  }

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const flags = {
  count,
  avg,
  getAll,
}

export default flags
