import { isNil } from 'ramda'
import Promise from 'bluebird'
import { roleNameToId } from '../config'
import clientApi from './utils/clientApi'
import parseKeysToSnakeCase from '../parsers/parseKeysToSnakeCase'

const getAll = ({ yardId, roleName }) => {
  const validate = () => {
    if (isNil(yardId)) {
      throw new Error('Missing yardId')
    }
  }

  const getRequestOptions = () => {
    if (yardId && roleName) {
      const roles = Array.isArray(roleName) ? roleName : [roleName]
      const roleIds = roles.map((name) => roleNameToId[name])

      return {
        method: 'get',
        url: '/users',
        params: {
          yard_id: yardId,
          role_id: roleIds,
        },
      }
    }
    return {
      method: 'get',
      url: `/users/yards/${yardId}`,
      params: {
        include: ['AllowedYards', 'last_audited_at'],
      },
    }
  }

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const getOne = (userId) =>
  clientApi({
    method: 'get',
    url: `/users/${userId}`,
  }).then(({ data }) => data.user)

const save = ({ userId, formData }) => {
  const getRequestOptions = () => {
    const body = { ...formData }
    const data = parseKeysToSnakeCase(body)
    if (isNil(userId)) {
      return {
        method: 'post',
        url: '/users',
        data,
      }
    }

    return {
      method: 'put',
      url: `/users/${userId}`,
      data,
    }
  }

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const destroy = ({ userId }) =>
  clientApi({
    method: 'delete',
    url: `/users/${userId}`,
  })

const getMetrics = ({ yardId, startDate, endDate }) => {
  const getRequestOptions = () => ({
    method: 'get',
    url: '/metrics/users',
    params: {
      ...parseKeysToSnakeCase({ yardId, startDate, endDate }),
    },
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const users = {
  getAll,
  getOne,
  save,
  destroy,
  getMetrics,
}

export default users
