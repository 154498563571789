import { connect } from 'react-redux'
import { path } from 'ramda'
import BillingSubmit from './BillingSubmit'
import {
  submitInvoice,
  loadBillingInvoiceDetails,
  resetBillingInvoiceDetails,
  loadFileToExport,
  getBillingPreview,
  getInvoiceIntercompanyDetails,
} from '../../actions/billingActions'

import { showAlert, showErrorAlert } from '../../actions/alertMessageActions'

import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state, ownProps) => ({
  timezone: getYardTimeZone(state, ownProps),
  selectedYard: Number(ownProps.params.yardId),
  billingInvoiceDetails: state.billing.billingInvoiceDetails,
  invoiceId: ownProps.params.invoice_id,
  returnUrlDate: ownProps.location.query.return_url_date,
  yardList: state.general.yardList,
  userRole: path(['Role', 'name'])(state.general.userData),
  UrlSubMenu: ownProps.params.sub_menu,
})

const mapDispatchToProps = {
  showAlert,
  showErrorAlert,
  submitInvoice,
  loadBillingInvoiceDetails,
  resetBillingInvoiceDetails,
  loadFileToExport,
  getBillingPreview,
  getInvoiceIntercompanyDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingSubmit)
