import Promise from 'bluebird'
import { curry } from 'ramda'
import clientApi from './utils/clientApi'

const uploadFile = curry(async (invoiceId, onSucess, onError, file) => {
  const formData = new FormData()
  formData.append('file', file, file.name)
  formData.append('correctFileName', file.name)
  const url = `/billing/invoices/${invoiceId}/files`

  try {
    await clientApi.post(url, formData, {
      'content-type': 'multipart/form-data',
    })
    onSucess({ file })
  } catch (error) {
    onError({ file, error })
  }
})

async function uploadFileList({ invoiceId, files, onSucess, onError }) {
  return Promise.map(files, uploadFile(invoiceId, onSucess, onError), {
    concurrency: 3,
  })
}

const invoices = {
  uploadFileList,
}

export default invoices
