import { connect } from 'react-redux'
import { path } from 'ramda'
import VehicleDetails from './VehicleDetails'

import {
  loadVehicle,
  resetVehicle,
  deleteVehicle,
} from '../../actions/manageVehiclesActions'

import { parseIntOrUndefined } from '../../utils'

const mapStateToProps = (state, ownProps) => ({
  vehicleData: state.manageVehicles.vehicleData,
  yardId: parseIntOrUndefined(state.general.selectedYard),
  yardList: state.general.yardList,
  vehicleIdFromUrl: ownProps.params.vehicle_id,
  userRole: path(['Role', 'name'])(state.general.userData),
})

const mapDispatchToProps = {
  loadVehicle,
  resetVehicle,
  deleteVehicle,
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetails)
