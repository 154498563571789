import styled, { css } from 'styled-components'
import Select from 'react-select'
import ReactDatePickerForMoment from '../ReactDatePickerForMoment'
import { animationFloat } from '../../css/sharedStyles'
import IconArrowLeft from '../../images/icons/icon_arrow_left.svg'
import IconArrowRight from '../../images/icons/icon_arrow_right.svg'
import IconArrowLeftWhite from '../../images/icons/icon_arrow_left_white.svg'
import IconArrowRightWhite from '../../images/icons/icon_arrow_right_white.svg'

const controlStyle = css`
  border: none;
  margin: 5px;
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    animation: ${animationFloat} 0.8s ease infinite;
  }
`

export const Container = styled.div`
  display: flex;
  align-content: center;
  position: relative;
  z-index: 99;
  @media (max-width: 529px) {
    height: auto;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  ${(props) =>
    props.readOnly &&
    `
    pointer-events: none;
  `}
  ${(props) =>
    props.readOnly &&
    `
    height: 48px;
  `}
`

export const LeftButton = styled.button`
  ${controlStyle}
  ${(props) =>
    props.isDarkMode
      ? `
    background-color: var(--darker-blue-29);
    background-image: url(${IconArrowLeftWhite});
  `
      : `
    background-color: var(--lighter-neutral-99);
    background-image: url(${IconArrowLeft});
  `}
`

export const RightButton = styled.button`
  ${controlStyle}
  ${(props) =>
    props.isDarkMode
      ? `
    background-color: var(--darker-blue-29);
    background-image: url(${IconArrowRightWhite});
  `
      : `
    background-color: var(--lighter-neutral-99);
    background-image: url(${IconArrowRight});
  `};

  ${(props) => props.disabled && 'opacity: .5; pointer-events: none;'}
`

export const StyledSelect = styled(Select)`
  border: none;
  border-left: 1px solid
    ${(props) =>
      props.isDarkMode
        ? 'var(--darker-neutral-45)'
        : 'var(--lighter-neutral-85)'};
  font-size: 0.9rem;
  border-radius: 0;
  width: 155px;

  & .react-datepicker__input-container {
    display: flex;
  }

  & > .Select-control {
    height: 100%;
    cursor: pointer;
    ${(props) =>
      props.isDarkMode &&
      `
      background: var(--darker-blue-29) !important;
      transition: none !important;
    `}

    .Select-input:focus {
      background: transparent !important;
    }

    & .Select-value {
      display: flex;
      align-items: center;

      & span {
        ${(props) =>
          props.isDarkMode
            ? `color: var(--lighter-neutral-99) !important;`
            : `color: var(--base-blue) !important;`}

        &:hover {
          ${(props) =>
            props.isDarkMode
              ? `color: var(--lighter-neutral-99) !important;`
              : `color: var(--darker-blue-43) !important;`}
        }
      }
    }
  }

  & > .Select-menu-outer {
    padding: 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    ${(props) =>
      props.isDarkMode &&
      `
      background: var(--darker-blue-29) !important;
    `}

    & .Select-option {
      opacity: 0;
      animation: showsUp 0.1s ease forwards;

      ${(props) =>
        props.isDarkMode &&
        `
        background-color: transparent !important;
        color: var(--base-neutral);

        :hover {
          color: var(--lighter-neutral-99)
        }

        &.is-selected {
          background: var(--darker-blue-29) !important;
          color: var(--lighter-neutral-99) !important;
          pointer-events: none;
        }
      `}

      @keyframes showsUp {
        from {
          opacity: 0;
          transform: translateY(4px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }

      &:nth-child(1) {
        animation-delay: 0.1s;
      }
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.3s;
      }
      &:nth-child(4) {
        animation-delay: 0.4s;
      }
    }
  }
`

export const DatePickerWrapper = styled.div`
  display: flex;
  padding: 0 15px;
  align-items: center;
  ${(props) =>
    props.isDarkMode
      ? `
      background-color: var(--darker-blue-29);
      color: var(--lighter-neutral-99);
    `
      : `
      background-color: var(--lighter-neutral-99);
      color: var(--base-blue);
    `};

  .react-datepicker__input-container {
    display: flex;
  }
`

export const DateIcon = styled.img`
  pointer-events: none;
`

export const DatePrefix = styled.span`
  pointer-events: none;
  font-size: 0.9rem;
`

export const StyledReactDatePickerForMoment = styled(ReactDatePickerForMoment)`
  width: 100px !important;
  padding: 5px !important;
  text-align: center;
  position: relative;
  font-size: 0.9rem;
  transition: 0.2s all;

  ${(props) =>
    props.isDarkMode &&
    `
    background-color: var(--darker-blue-29);
  `}

  &:hover {
    cursor: pointer;
    ${(props) =>
      props.isDarkMode
        ? `
      color: var(--lighter-neutral-99) !important;
    `
        : `
      color: var(--darker-blue-43) !important;
    `}
  }

  ${(props) =>
    props.showMonthYearPicker &&
    `
      width: 130px !important;
    `}
`
