import localForage from 'localforage'
import { persistCombineReducers } from 'redux-persist'
import { routerReducer } from 'react-router-redux'

import { toolkitApi } from '../services'

import slices from '../features'

import general from './generalReducer'
import deviceStatus from './deviceStatusReducer'
import manageUsers from './manageUsersReducer'
import manageVehicles from './manageVehiclesReducer'
import manageVCRs from './manageVCRsReducer'
import playback from './playbackReducer'
import tracking from './trackingReducer'
import workerProfile from './workerProfileReducer'
import yardSummary from './yardSummaryReducer'
import upsertAudit from './upsertAuditReducer'
import alertMessage from './alertMessageReducer'
import safetyReports from './safetyReportsReducer'
import createUser from './createUserReducer'
import yardList from './yardListReducer'
import passwordReset from './passwordResetReducer'
import billing from './billingReducer'

const reducers = {
  general,
  deviceStatus,
  manageUsers,
  manageVehicles,
  manageVCRs,
  playback,
  tracking,
  workerProfile,
  yardSummary,
  upsertAudit,
  alertMessage,
  safetyReports,
  createUser,
  yardList,
  passwordReset,
  billing,
}

localForage.clear()

const persistConfig = {
  key: 'root',
  storage: localForage,
}

const reducer = persistCombineReducers(persistConfig, {
  ...reducers,
  ...slices,
  [toolkitApi.reducerPath]: toolkitApi.reducer,
  routing: routerReducer,
})

export default reducer
