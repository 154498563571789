import { manageVehiclesTypes } from '../../actions/manageVehiclesActions'

const initialState = {
  loading: false,
  value: [],
  order: {
    column: 'LAST COMMS',
    direction: 'asc',
  },
}

const vehicleList = (state = initialState, { type, payload }) => {
  switch (type) {
    case manageVehiclesTypes.LIST_REQUESTED:
      return {
        ...state,
        loading: true,
        value: [],
      }

    case manageVehiclesTypes.LIST_LOADED:
      return {
        ...state,
        loading: false,
        value: payload,
      }

    case manageVehiclesTypes.LIST_FAILED:
      return initialState

    case manageVehiclesTypes.VEHICLE_LIST_ORDER_SET:
      return {
        ...state,
        order: payload.order,
      }

    default:
      return state
  }
}

export default vehicleList
