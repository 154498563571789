import moment from 'moment'
import { map, pipe, assoc, values, invertObj, mergeLeft } from 'ramda'
import parseBoolean from './parsers/parseBoolean'

export const {
  REACT_APP_SERVER_LOCATION: SERVER_LOCATION,
  REACT_APP_SHOW_ZONES: SHOW_ZONES,
  REACT_APP_SENTRY_DSN: SENTRY_DSN,
  REACT_APP_GOOGLE_API_KEY: GOOGLE_API_KEY,
  REACT_APP_ENGINE_HOURS_EDITABLE_PERIOD_END: ENGINE_HOURS_EDITABLE_PERIOD_END,
  REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID: GOOGLE_ANALYTICS_MEASUREMENT_ID,
} = process.env

export const apiUrl = `${SERVER_LOCATION}/api/v1`
export const showZones = parseBoolean(SHOW_ZONES)
export const historyResolution = 1000
export const bufferDuration = moment.duration({ seconds: 10 }).asMilliseconds()
export const reuseLastPointTimeLimit = 1000 * 60
export const SIDEBAR_WIDTH = 290
export const SUB_HEIGHT = 600
export const MAX_PLAYBACK_DAYS = 7

export const debounceDelay = 500

export const roleIdToName = {
  1: 'DRIVER',
  2: 'MANAGER',
  3: 'ADMIN',
  4: 'MECHANIC',
  5: 'SUPERVISOR',
  6: 'UPPER_MANAGER',
  7: 'GATE_INSPECTOR',
  8: 'SUPER_ADMIN',
  9: 'CLERICAL',
  10: 'CORPORATE_BILLER',
}

export const roleNameToId = pipe(invertObj, map(Number))(roleIdToName)

export const ADMIN_ROLES = ['SUPER_ADMIN', 'ADMIN']
export const UPPER_ROLES = [...ADMIN_ROLES, 'UPPER_MANAGER']
export const MANAGER_ROLES = [...UPPER_ROLES, 'MANAGER']
export const VEHICLES_ROLES = [
  ...MANAGER_ROLES,
  'MECHANIC',
  'SUPERVISOR',
  'CLERICAL',
  'CORPORATE_BILLER',
]
export const WORKERS_ROLES = VEHICLES_ROLES
export const YARD_MAP_ROLES = VEHICLES_ROLES
export const ALL_YARDS_ROLES = [...UPPER_ROLES, 'CLERICAL', 'CORPORATE_BILLER']
export const AUDITS_ROLES = [...MANAGER_ROLES, 'MECHANIC', 'SUPERVISOR']
export const BILLING_ROLES = [...MANAGER_ROLES, 'CLERICAL', 'CORPORATE_BILLER']
export const ANALYTICS_ROLES = [
  ...MANAGER_ROLES,
  'CLERICAL',
  'CORPORATE_BILLER',
  'SUPERVISOR',
]
export const HAS_ACCESS_TO_ALL_YARDS_ROLES = ADMIN_ROLES
export const CAN_CHANGE_ENGINE_HOURS_ANY_TIME_ROLES = ADMIN_ROLES
export const CAN_MOVE_VEHICLE_TO_ANY_YARD_ROLES = [...MANAGER_ROLES, 'MECHANIC']

export const allRoles = values(roleIdToName)

export const vehicleTypeIdNameMap = {
  1: 'HOSTLER',
  2: 'FORKLIFT',
  3: 'SIDELOADER',
  4: 'CRANE',
  7: 'TRUCK',
  8: 'TRAIN',
}

export const moveTypes = [
  'MOVE',
  'CLEAR',
  'PLACE',
  'GROUND',
  'FLIP',
  'STACK',
  'LOAD',
]

const YARD_MAP_ROUTE = '/tracking/mode/live'
const VEHICLES_ROUTE = '/vehicles'
const ALL_YARDS_ROUTE = '/allYards?view_mode=yards'
const BILLING_ROUTE = '/billing'

export const rolePermission = {
  MANAGER: {
    homeURL: YARD_MAP_ROUTE,
  },
  MECHANIC: {
    homeURL: VEHICLES_ROUTE,
  },
  SUPERVISOR: {
    homeURL: YARD_MAP_ROUTE,
  },
  UPPER_MANAGER: {
    homeURL: ALL_YARDS_ROUTE,
  },
  ADMIN: {
    homeURL: ALL_YARDS_ROUTE,
  },
  SUPER_ADMIN: {
    homeURL: ALL_YARDS_ROUTE,
  },
  CLERICAL: {
    homeURL: BILLING_ROUTE,
  },
  CORPORATE_BILLER: {
    homeURL: ALL_YARDS_ROUTE,
  },
}

export const engineHoursMonthlyLimit = 600
export const engineHoursEditablePeriod = {
  start: 1,
  end: ENGINE_HOURS_EDITABLE_PERIOD_END,
}

export const missingDaysLimit = 3

export const detectedMovesInterval = 120000
export const detectedMovesPeriod = {
  value: 1,
  range: 'hour',
}

export const billingHistoryLimit = 6

export const sidebarWidth = 255
export const headerHeight = 80

export const analyticsInitialMode = {
  productivity: 'lpmh',
  vehicles: 'engine_hours',
}

export const analyticsDashboardNameToId = {
  safety: 2,
  engine_hours: 7,
  vcrs: 8,
  lpmh: 11,
  hours: 12,
  flags: 13,
  vwrs: 0,
}

export const billingPastDuePeriod = 4
export const billingInvoicesPerRow = 5
export const billingMissingDateAlertRoles = ['MANAGER']

export const allYardsStartingDate = moment()
  .subtract(1, 'week')
  .format('YYYY-MM-DD')

export const tableDisplayLimitStart = 20

export const TIERS_HEADERS = [
  {
    text: 'Code',
    field: 'code',
    isEditable: true,
  },
  {
    text: 'Description',
    field: 'description',
    isEditable: true,
  },
  {
    text: 'Export Comment',
    field: 'export_comment',
    isEditable: true,
  },
  {
    text: 'Interval From',
    field: ['interval', 0],
    isInterval: true,
    intervalOrigin: 0,
    isEditable: true,
  },
  {
    text: 'Interval To',
    field: ['interval', 1],
    isInterval: true,
    intervalOrigin: 1,
    isEditable: true,
  },
]

export const COMPOUND_TIERS_HEADERS = [
  {
    text: 'Interval From',
    field: ['interval', 0],
    isInterval: true,
    intervalOrigin: 0,
    isEditable: true,
  },
  {
    text: 'Interval To',
    field: ['interval', 1],
    isInterval: true,
    intervalOrigin: 1,
    isEditable: true,
  },
  {
    text: 'Tiers Values',
    field: 'tiers',
    hasModalInfo: true,
  },
]

const SELECTABLE_OPTIONS_HEADERS = [
  {
    text: 'Code',
    field: 'code',
    isEditable: true,
  },
  {
    text: 'Description',
    field: 'description',
    isEditable: true,
  },
  {
    text: 'Display Name',
    field: 'display_name',
    isEditable: true,
  },
  {
    text: 'Value',
    field: 'value',
    isEditable: true,
  },
]

export const EDIT_BILLING_TABLE_HEADERS = {
  tiers: map(assoc('uniqueTableName', 'tiers_items'))(TIERS_HEADERS),
  compound_tiers_children: map(
    mergeLeft({
      uniqueTableName: 'compound_tiers_children',
      isIntervalChild: true,
    }),
  )(TIERS_HEADERS),
  compound_tiers: map(assoc('uniqueTableName', 'compound_tiers'))(
    COMPOUND_TIERS_HEADERS,
  ),
  selectable_options: SELECTABLE_OPTIONS_HEADERS,
}

export const API_TAG_ATTACHMENTS = 'Attachments'
