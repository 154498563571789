import clientApi from './utils/clientApi'

const create = (params) =>
  clientApi({
    method: 'post',
    url: '/password_token',
    data: params,
  }).then(({ data }) => data)

const getUser = ({ token }) =>
  clientApi({
    method: 'get',
    url: `/password_token/${token}/user`,
  }).then(({ data }) => data)

const resetUserPassword = ({ token, password }) =>
  clientApi({
    method: 'put',
    url: `/password_token/${token}/user`,
    data: {
      password,
    },
  }).then(({ data }) => data)

const passwordToken = {
  create,
  getUser,
  resetUserPassword,
}

export default passwordToken
