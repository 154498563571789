import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import * as S from './styles'

import Na from '../Na'
import NewToolTip from '../NewToolTip'

function tooltipDescription(yardDetails) {
  return yardDetails.map((yard) => (
    <>
      {yard}
      <br />
    </>
  ))
}

const AllowedYardsCell = ({ allowedYards }) => {
  if (isEmpty(allowedYards)) {
    return <Na />
  }

  const codeList = allowedYards.map((yard) => yard.code).join(', ')
  const yardDetails = allowedYards.map((yard) => `${yard.code} - ${yard.name}`)

  return (
    <NewToolTip
      title="Other Yards"
      description={tooltipDescription(yardDetails)}
    >
      <S.Codes>{codeList}</S.Codes>
    </NewToolTip>
  )
}

AllowedYardsCell.propTypes = {
  allowedYards: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default AllowedYardsCell
