import Promise from 'bluebird'
import { createTypes } from './utils'
import api from '../api'

export const createUserTypes = createTypes('createUser', [
  { value: 'SAVE_USER', isAsync: true },
  { value: 'LOAD_USER', isAsync: true },
  'LOAD_USER_RESET',
])

export const saveUser = ({ formData, userId }) => (dispatch) => {
  dispatch({ type: createUserTypes.SAVE_USER_REQUESTED })
  const { roleId } = formData

  return Promise.resolve({
    formData: {
      ...formData,
      roleId: parseInt(roleId, 10),
    },
    userId,
  })
    .then(api.users.save)
    .tap(() => {
      dispatch({ type: createUserTypes.SAVE_USER_LOADED })
    })
    .tapCatch(() => {
      dispatch({ type: createUserTypes.SAVE_USER_FAILED })
    })
}

export const loadUser = (userId) => (dispatch) => {
  dispatch({ type: createUserTypes.LOAD_USER_REQUESTED })

  api.users
    .getOne(userId)
    .then((user) => {
      dispatch({
        type: createUserTypes.LOAD_USER_LOADED,
        payload: user,
      })
    })
    .catch(() => {
      dispatch({ type: createUserTypes.LOAD_USER_FAILED })
    })
}

export const resetUser = () => (dispatch) => {
  dispatch({ type: createUserTypes.LOAD_USER_RESET })
}
