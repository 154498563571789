import { browserHistory } from 'react-router'
import GA4React from 'ga-4-react'
import store from '../store'

const getGoogleAnalyticsEventFromPathName = (pathname) => {
  const matchResult = pathname.match(/\/yard\/\d\/(\w*)\/*/)
  if (matchResult === null) return null
  return matchResult[1].toLowerCase()
}

const sendGoogleAnalyticsEvent = (gaClient) => {
  let lastEvent = null

  return ({ pathname: pathName }) => {
    const { userData } = store.getState().general
    const currentEvent = getGoogleAnalyticsEventFromPathName(pathName)

    if (currentEvent !== null && currentEvent !== lastEvent) {
      lastEvent = currentEvent
      gaClient.gtag('event', `${currentEvent}_page_view`, {
        user_id: userData.id,
        username: userData.username,
        role_id: userData.role_id,
        role_name: userData.Role.name,
        yard_id: userData.Yard.id,
        yard_name: userData.Yard.name,
        yard_code: userData.Yard.code,
      })
    }
  }
}

export const injectGoogleAnalytics = (key) => {
  const ga4react = new GA4React(key)

  ga4react
    .initialize()
    .then((ga4) => browserHistory.listen(sendGoogleAnalyticsEvent(ga4)))
}
