import _ from 'lodash'
import moment from 'moment-timezone'

import { history } from '../../../utils'

export default function getCycleHistory({ historyPeriods, timezone }) {
  const parsedPeriods = historyPeriods.map((item) => {
    const status = _.get(item, 'status', '').toLowerCase()
    const filledAmount = _.get(item, 'filled_amount', 0)
    const totalAmount = _.get(item, 'total_amount', 0)

    return {
      ...item,
      period: `${moment
        .tz(item.start_date, timezone)
        .format('MMM Do')} - ${moment
        .tz(item.end_date, timezone)
        .format('Do')}`,
      year: moment.tz(item.start_date, timezone).year(),
      onClick: () =>
        history.changePathVariable({ name: 'date', value: item.start_date }),
      isReadyToSubmit: filledAmount === totalAmount,
      isSubmitted: status === 'final',
      amountToDisplay: `${filledAmount}/${totalAmount}`,
    }
  })

  return parsedPeriods
}
