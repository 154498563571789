import clientApi from './utils/clientApi'

const getAll = ({ yardId, startTime, endTime }) =>
  clientApi({
    method: 'get',
    url: '/position_history',
    params: {
      yard_id: yardId,
      startTime,
      endTime,
    },
  }).then((res) => res)

const positionHistory = {
  getAll,
}

export default positionHistory
