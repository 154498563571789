import { trackingTypes } from '../../actions/trackingActions'

const initialState = new Date().getTime()

const historyViewMoment = (state = initialState, { type, payload }) => {
  switch (type) {
    case trackingTypes.HISTORY_VIEW_MOMENT:
      return payload.dateTime

    default:
      return state
  }
}

export default historyViewMoment
