import { connect } from 'react-redux'
import SearchVCR from './SearchVCR'
import { getYardTimeZone } from '../../selectors'
import {
  loadCreatorList,
  loadVehicleList,
} from '../../actions/searchVCRActions'
import './SearchVCR.css'

const mapStateToProps = (state, ownProps) => ({
  yardId: Number(ownProps.params.yardId),
  timezone: getYardTimeZone(state),
  creatorList: state.manageVCRs.creatorList,
  vehicleList: state.manageVCRs.vehicleList,
})

const mapDispatchToProps = {
  loadCreatorList,
  loadVehicleList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchVCR)
