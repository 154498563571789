import { connect } from 'react-redux'
import MetricsChart from './MetricsChart'

import './MetricsChart.css'

import { getYardTimeZone } from '../../../selectors'
import { createMomentParser } from '../../../utils'

import {
  loadActiveTimeSummary,
  loadYardShiftsSummary,
  loadMovesByDay,
  setYardSummarySelectedDate,
} from '../../../actions/yardSummaryActions'

const parseMoment = createMomentParser()

const mapStateToProps = (state) => {
  const timezone = getYardTimeZone(state)

  return {
    timezone,
    yardShiftsSummary: state.yardSummary.yardShiftsSummary,
    activeTimeSummary: state.yardSummary.activeTimeSummary,
    selectedDate: parseMoment(state.yardSummary.selectedDate, timezone),
    selectedYard: state.general.selectedYard,
    liftsCountSummary: state.yardSummary.liftsCountSummary,
  }
}

const mapDispatchToProps = {
  loadYardShiftsSummary,
  loadActiveTimeSummary,
  loadMovesByDay,
  setYardSummarySelectedDate,
}

export default connect(mapStateToProps, mapDispatchToProps)(MetricsChart)
