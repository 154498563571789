import _ from 'lodash'

const parseMetricsFromServer = ({ data }) => {
  const {
    dateIntervals,
    lpmh,
    mpmh,
    totalLifts,
    totalMoves,
    shiftsCount,
    shiftsTime,
  } = data

  const columns = dateIntervals.map((interval, index) => ({
    key: index,
    text: interval.join(' - '),
  }))

  const rows = [
    lpmh.map((value) => _.round(value, 2)),
    totalLifts,
    mpmh.map((value) => _.round(value, 2)),
    totalMoves,
    shiftsCount,
    shiftsTime.map((time) => _.round(time / 3600000, 2)),
  ].map((row) => ({ ...row }))

  return {
    rows,
    columns,
  }
}

export default parseMetricsFromServer
