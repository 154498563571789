import _ from 'lodash'
import store from './store'
import { selectYard } from './actions/generalActions'
import history from './utils/history'

const syncActionsBeforeStart = () => {
  const yardId = history.getVariable('yard')
  if (!_.isNil(yardId)) {
    store.dispatch(selectYard(Number(yardId)))
  }
}

export default syncActionsBeforeStart
