import { getUserFullName } from '../utils'

const parseAuditFromServer = (serverData) => ({
  id: serverData.id,
  type: serverData.type,
  auditFunction: serverData.function,
  yardCode: serverData.Yard.code,
  auditor: getUserFullName(serverData.Auditor),
  employee: getUserFullName(serverData.Employee),
  supervisor: getUserFullName(serverData.Supervisor),
  violations: serverData.violations,
  correctiveActions: serverData.corrective_actions,
  comment: serverData.comment ? serverData.comment.trim() : null,
  coneTestPassed: serverData.cone_test_passed,
  auditedAt: serverData.audited_at,
  createdAt: serverData.created_at,
  updatedAt: serverData.updated_at,
})

export default parseAuditFromServer
