import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { touchPropType } from '../../containers/VCR/propTypes'
import TouchOverlay from './TouchOverlay'
import allVehicleImages from '../../utils/allVehicleImages'

import './Gallery.css'

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imagePage: 0,
      animateClearIntervalId: 0,
    }
  }

  // FIXME stop using componentWillMount
  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillMount() {
    const { data } = this.props
    this.animateToIssuedImage(data)
  }

  componentWillUnmount() {
    const { animateClearIntervalId } = this.state
    clearInterval(animateClearIntervalId)
  }

  onImageMove = (direction) => () => {
    const { data } = this.props
    const vehicleImagesLength = allVehicleImages[data.vehicleType_id].length
    let { imagePage } = this.state

    if (direction === 'back') {
      if (imagePage - 1 < 0) {
        imagePage = vehicleImagesLength - 1
      } else {
        imagePage -= 1
      }
    } else if (direction === 'forward') {
      if (imagePage + 1 > vehicleImagesLength - 1) {
        imagePage = 0
      } else {
        imagePage += 1
      }
    }

    this.setState({ imagePage })
  }

  onDotClick = (imagePage) => {
    this.setState({ imagePage })
  }

  animateToIssuedImage = (data) => {
    const touches = data.touches !== undefined ? data.touches : []

    if (!touches.length) {
      return undefined
    }

    const animateClearInterval = setInterval(() => {
      const { imagePage } = this.state
      const imageTouches = touches.filter(
        (touch) => touch.image_id === imagePage,
      )

      if (imageTouches.length === 0) {
        this.setState({ imagePage: imagePage + 1 })
        return undefined
      }
      clearInterval(animateClearInterval)
      return undefined
    }, 1000)
    this.setState({ animateClearIntervalId: animateClearInterval })
    return undefined
  }

  renderTouchOverlay = (data) => {
    const { imagePage } = this.state
    const vehicleImages = allVehicleImages[data.vehicleType_id]

    const touches = data.touches !== undefined ? data.touches : []
    const imageTouches = touches.filter((touch) => touch.image_id === imagePage)

    return (
      <TouchOverlay touches={imageTouches} {...this.props}>
        <img
          className={`vehicleImageStyle img${imagePage}`}
          src={vehicleImages[imagePage].image}
          alt="vehicle"
        />
      </TouchOverlay>
    )
  }

  renderDotButton = () => {
    const { data } = this.props
    const { imagePage } = this.state
    const vehicleImages = allVehicleImages[data.vehicleType_id]

    return vehicleImages.map((element, index) => (
      <button
        type="button"
        className={
          imagePage === index
            ? 'galleryDotButtonHighlighted'
            : 'galleryDotButton'
        }
        key={`${vehicleImages[imagePage].label.concat(index)}`}
        onClick={() => this.onDotClick(index)}
      />
    ))
  }

  render() {
    const { data } = this.props
    const { imagePage } = this.state
    const vehicleImages = allVehicleImages[data.vehicleType_id]
    const imageLabel = _.get(vehicleImages, [imagePage, 'label'], '')

    if (_.isNil(vehicleImages)) {
      return (
        <div className="galleryContainer empty">
          There&apos;s no image for this vehicle
        </div>
      )
    }

    return (
      <div className="galleryContainer flex-column">
        <div className="overlayWrapper">{this.renderTouchOverlay(data)}</div>
        <div className="viewMode_text">{imageLabel}</div>
        <div className="galleryButtonContainer flex-row">
          <button
            type="button"
            className="galleryButtons flex-row"
            onClick={this.onImageMove('back')}
          >
            &#xf053;
          </button>
          <div className="dotButtonContainer">{this.renderDotButton()}</div>
          <button
            type="button"
            className="galleryButtons flex-row"
            onClick={this.onImageMove('forward')}
          >
            &#xf054;
          </button>
        </div>
      </div>
    )
  }
}

Gallery.propTypes = {
  data: PropTypes.shape({
    vehicleType_id: PropTypes.number,
    touches: PropTypes.arrayOf(PropTypes.shape(touchPropType)),
  }),
}

Gallery.defaultProps = {
  data: {},
}

export default Gallery
