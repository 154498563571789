import { isNil } from 'lodash'
import Promise from 'bluebird'
import clientApi from './utils/clientApi'

const getWeeks = ({ yard, date }) => {
  const validate = () => {
    if (isNil(yard)) {
      throw new Error('Missing Yard ID')
    }
    if (isNil(date)) {
      throw new Error('Missing date')
    }
  }

  const params = {
    date,
    yard_id: yard,
  }

  const getRequestOptions = () => ({
    method: 'get',
    url: '/billing_weeks',
    params,
  })

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const billingWeeks = {
  getWeeks,
}

export default billingWeeks
