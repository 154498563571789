import { isNil } from 'lodash'
import Promise from 'bluebird'
import clientApi from './utils/clientApi'

const getAll = ({ yardId, step, date }, options = {}) => {
  const validate = () => {
    if (isNil(yardId)) {
      throw new Error('Missing userId or yardId')
    }
  }

  const getRequestOptions = () => {
    const params = {
      yard_id: yardId,
      step,
      date,
    }

    return {
      ...options,
      method: 'get',
      url: '/metrics/yard_summary',
      params,
    }
  }

  const formatResult = ({ data }) => data

  return Promise.resolve()
    .then(validate)
    .then(getRequestOptions)
    .then(clientApi)
    .then(formatResult)
}

const metrics = {
  getAll,
}

export default metrics
