import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, defaultTo, path } from 'ramda'

import * as S from './styles'

import { Blank, NewToolTip, Na, NewModal } from '..'

import { formatBillingEditValues } from '../../utils'

function parseCellValue({ header, row }) {
  const selectedPath = Array.isArray(header.field)
    ? header.field
    : [header.field]

  const rawValue = path(selectedPath)(row)

  if (typeof rawValue === 'boolean') {
    return rawValue ? 'Yes' : 'No'
  }

  return rawValue
}

const EditBillingTable = ({
  headers,
  rows,
  timezone,
  fullHeight,
  action,
  isEditMode,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState({})

  if (isEmpty(rows)) {
    return (
      <S.LoadingContainer>
        <Blank
          small
          title="No data found"
          subtitle="There's no value to show"
          action={action}
        />
      </S.LoadingContainer>
    )
  }

  function handleOpenModal(content) {
    setModalContent(content)
    return setIsModalVisible(true)
  }

  function handleCloseModal() {
    setModalContent({})
    return setIsModalVisible(false)
  }

  const modalProps = {
    visible: isModalVisible,
    onConfirm: handleCloseModal,
    onClose: handleCloseModal,
    confirmText: 'Close',
    cancelText: 'Review values',
    type: modalContent.field,
    tableContent: modalContent,
    iconTitle: modalContent.iconTitle,
  }

  return (
    <S.Container fullHeight={fullHeight}>
      <S.Header>
        {headers.map((header, index) => (
          <NewToolTip key={index} title={header.text}>
            <S.Cell>
              <S.Text title={header.text} strong>
                {header.text}
              </S.Text>
            </S.Cell>
          </NewToolTip>
        ))}
      </S.Header>
      <S.Body fullHeight={fullHeight}>
        {rows.map((row, rowIndex) => (
          <S.Row key={rowIndex}>
            {headers.map((header, columnIndex) => {
              const value = defaultTo(
                <Na />,
                parseCellValue({ header, row, rowIndex }),
              )

              const valueToRender = formatBillingEditValues({
                value,
                isBillingCycle: header.field === 'billing_cycle',
                timezone,
                header,
                row,
                handleOpenModal,
              })

              return (
                <S.Cell title={valueToRender} key={columnIndex}>
                  {isEditMode ? (
                    <S.Input value={valueToRender} />
                  ) : (
                    <S.Text>{valueToRender}</S.Text>
                  )}
                </S.Cell>
              )
            })}
          </S.Row>
        ))}
      </S.Body>
      <NewModal {...modalProps} />
    </S.Container>
  )
}

EditBillingTable.propTypes = {
  rows: PropTypes.array,
  headers: PropTypes.array,
  timezone: PropTypes.string.isRequired,
  fullHeight: PropTypes.bool,
  action: PropTypes.object,
  isEditMode: PropTypes.bool,
}

EditBillingTable.defaultProps = {
  headers: [],
  rows: [],
  fullHeight: false,
  isEditMode: false,
  action: {},
}

export default EditBillingTable
