import styled, { css } from 'styled-components/macro'
import { animationLiftUp } from '../../css/sharedStyles'

const sharedStyle = css`
  display: block;
  width: 100%;
  text-align: center;
  color: var(--darker-blue-20);
  margin: 5px 0;
  font-family: 'ArticulatCF-DemiBold';
  cursor: default;
`

const sharedAnimation = css`
  opacity: 0;
  animation: ${animationLiftUp} 0.2s ease forwards;
`

export const Title = styled.span`
  ${sharedStyle}
  ${sharedAnimation}
  animation-delay: .3s;
  font-size: 2rem;
`

export const Subtitle = styled.span`
  ${sharedStyle}
  ${sharedAnimation}
  animation-delay: .4s;
  font-size: 1rem;
  font-family: 'ArticulatCF-Medium';
`

export const ActionWrapper = styled.div`
  width: 100%;
  margin: 30px 0 0;
`

export const Action = styled.button`
  ${sharedStyle}
  ${sharedAnimation}
  animation-delay: .5s;
  text-transform: uppercase;
  width: fit-content;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  border: 1px solid (--darker-blue-20);
  margin: 0 auto;
  background: transparent;
  font-family: 'ArticulatCF-Bold';
  transition: 0.2s all ease;
  cursor: pointer;
`

export const Image = styled.img`
  ${sharedAnimation}
  animation-delay: .5s;
  width: 30%;
  margin: 30px auto 0;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  height: 100%;
  width: 100%;
  ${Title} {
    ${(p) =>
      p.small &&
      `
      font-size: 1.4rem;
      color: var(--base-neutral);
    `}
  }

  ${Subtitle} {
    ${(p) =>
      p.small &&
      `
      font-size: 1rem;
      color: var(--base-neutral);
      margin-bottom: 10px;
    `}
  }

  ${Action} {
    ${(p) =>
      p.small &&
      `
      color: var(--base-neutral);
      border: 1px solid;
      font-size: 14px;

      :hover {
        color: var(--lighter-neutral-85);
      }
    `}
  }
`
